import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import { autobind } from 'core-decorators'
import PerfectScrollbar from 'perfect-scrollbar'
import { formatMessage, FormattedMessage } from '../../translations'
import GenevaButton from '../../ui/components/GenevaButton'
import Icon from '../../shared/icons'
require('../styles.scss')

@observer
export default class ReviewCenterUserList extends Component {
  static propTypes = {
    reviewModel: PropTypes.object.isRequired,
    authStore: PropTypes.object.isRequired,
    reviewStore: PropTypes.object.isRequired,
    toggleUserList: PropTypes.func,
    addUserToChat: PropTypes.func,
  };

  constructor(props) {
    super(props)

    // need to have the initial list, to understand who to add at the end
    const currentgroup = []
    props.reviewModel.users.forEach((user) => {
      currentgroup.push(user.id)
    })

    this.state = {
      search: '',
      sort: 'A-Z',
      currentgroup,
    }
  }

  componentDidMount() {
    this.ps = new PerfectScrollbar('#rc-user-list-container')
  }

  @autobind
  handleSearchChange({ target }) {
    this.setState({
      search: target.value,
    })
  }

  @autobind
  handleSortChange({ target }) {
    if (this.state.sort !== target.value) {
      this.setState({
        sort: target.value,
      })
    }
  }

  @autobind
  handleUserAddRemove({ target }) {
    let { currentgroup } = this.state
    const checked = currentgroup.find(user => user === target.value)

    if (!checked) {
      currentgroup.push(target.value)
    }
    else {
      currentgroup = currentgroup.filter(id => id !== target.value)
    }
    this.setState({
      currentgroup,
    })
  }

  @autobind
  handleClose() {
    this.props.toggleUserList()
  }

  @autobind
  handleConfirm() {
    const { currentgroup } = this.state

    // TODO: route zu get all users public/review/users
    /* response:
    "data": [
    {
      "id": "5bc04f37530884003e56ff58",
      "firstname": "Atrivio",
      "lastname": "Admin"
    }
  ]
  */

    this.props.addUserToChat(currentgroup)
    this.props.toggleUserList()
  }

  renderUserList() {
    const { currentgroup, sort, search } = this.state
    const { reviewModel, reviewStore, authStore } = this.props

    return reviewStore.userList ? (
      reviewStore.userList
        .filter((user) => {
          // remove current user from the list
          if (authStore.user.id === user.id) {
            return false
          }

          return `${user.firstname} ${user.lastname}`
            .toLowerCase()
            .includes(search.toLowerCase())
        })
        .sort((first, second) => {
          const name1 = `${first.firstname} ${first.lastname}`
          const name2 = `${second.firstname} ${second.lastname}`

          if (sort === 'A-Z') {
            return name1.localeCompare(name2, 'de', {
              ignorePunctuation: true,
            })
          }

          // assumed Z-A
          return name2.localeCompare(name1, 'de', { ignorePunctuation: true })
        })
        .map((user) => {
          const name = `${user.firstname} ${user.lastname}`
          const checked = !!currentgroup.find(
            checkUser => checkUser === user.id
          )
          const isActive = !!reviewModel.users.find(
            activeUser => activeUser.id === user.id && activeUser.isActive
          )
          const isCurrentUser = user.id === authStore.user.id

          return (
            <span key={user.id}>
              <input
                disabled={isActive || isCurrentUser}
                onChange={this.handleUserAddRemove}
                id={user.id}
                type="checkbox"
                value={user.id}
                checked={checked}
              />
              <label
                htmlFor={user.id}
                className={classNames({ disabled: isActive || isCurrentUser })}
              >
                {name}
              </label>
            </span>
          )
        })
    ) : (
      <FormattedMessage id="review-center.users.error-loading" />
    )
  }

  render() {
    if (this.ps) {
      this.ps.update()
    }

    return (
      <div className="rc-user-list">
        <div className="rc-user-list-header">
          <FormattedMessage id="review-center.users.add-user" />
          <a onClick={this.handleClose}>
            <Icon name="ion-close" />
          </a>
        </div>
        <hr />
        <div className="rc-user-list-subheader">
          <input
            onChange={this.handleSearchChange}
            id="rc-search"
            type="search"
            className="form-control input-sm"
            value={this.state.search}
            onBlur={this.handleSearchChange}
            name="search"
            placeholder={formatMessage({ id: 'mm.search-placeholder' })}
          />
          <select
            id="rc-user-sort"
            value={this.state.sort}
            onChange={this.handleSortChange}
          >
            <option id="AZ" value="A-Z">
              A-Z
            </option>
            <option id="ZA" value="Z-A">
              Z-A
            </option>
          </select>
        </div>
        <div className="rc-people" id="rc-user-list-container">
          {this.renderUserList()}
        </div>
        <div className="rc-user-footer">
          <GenevaButton className="small button" onClick={this.handleConfirm}>
            <FormattedMessage id="review-center.users.close" />
          </GenevaButton>
        </div>
      </div>
    )
  }
}
