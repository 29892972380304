import { createConfirmation } from 'react-confirm'
import StylePickerDialog from '../components/Dialog/StylePickerDialog'

const stylePickerDialog = createConfirmation(StylePickerDialog)

export default function (confirmation, options = {}) {

  if (typeof confirmation !== 'string') {

    options = {
      modal: {
        ...confirmation.data,
      }
    }
    confirmation = ''

  }

  return stylePickerDialog({ confirmation, options })

}
