import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { autobind } from 'core-decorators'
import { confirmable } from 'react-confirm'
import classNames from 'classnames'
import PerfectScrollbar from 'perfect-scrollbar'
import { formatMessage, FormattedMessage } from '../../../translations'
import { Dialog } from '../../../shared/components/Dialog'
import Icon from '../../../shared/icons'
import { i18n } from '../../../shared/utils'
import { deepSet } from '../../../shared/obj'
import { connectToPluginHook } from '../../../shared/plugin'
import GenevaButton from '../../../ui/components/GenevaButton'

import GeneralSettingsForm from './GeneralSettingsForm'
import SEOSettingsForm from './SEOSettingsForm'


const css = typeof window === 'undefined' ? {} : require('../../styles.scss')


/**
* To add new tabs to the DialogComponent:
* - write a new component to render the new content
* - add the name and a reference to the new component in "tabs"
* - add an entry to the language files: e.g. "de.json"
* Then a button with the name is created and
* the content of the function is rendered onCLick.
*/
@confirmable
class PageSettings extends Component {

  static propTypes = {
    selectedTab: PropTypes.string,
    show: PropTypes.bool,            // indicates if the dialog is shown or not.
    proceed: PropTypes.func,         // call to close the dialog with promise resolved.
    cancel: PropTypes.func,          // call to close the dialog with promise rejected.
    dismiss: PropTypes.func,         // call to only close the dialog.
    confirmation: PropTypes.string,  // arguments of your confirm function
    options: PropTypes.object,        // arguments of your confirm function
    plugins: PropTypes.array
  }

  constructor(props) {
    super(props)
    const page = this.props.options.page

    this.tabs = [
      {
        name: 'general',
        component: GeneralSettingsForm,
        icon: 'ion-ios-cog-outline',
      },
      {
        name: 'seo',
        component: SEOSettingsForm,
        icon: 'ion-ios-glasses-outline',
      }
    ]
    this.state = {
      selectedTab: this.props.options.selectedTab,
      name: page.name,
      navigationName: page.navigationName || '',
      navigationArticleId: page.navigationArticleId || '',
      meta: {
        ...page.meta
      },
      pageType: page.type,
      publishedUrl: this.props.options.publishedUrl,
      originPublishedUrl: this.props.options.publishedUrl,
      showInSearch: !page.hideInSearch,
      showInNavigation: !page.hideNavigation,
      createFolder: page.createFolder,
      hideBreadcrumb: !!page.hideBreadcrumb,
      hideFromRobots: !!page.hideFromRobots,
      titleTag: page.title,
      metaDescription: i18n(page, 'description'),
      keywords: i18n(page, 'keywords'),
      robots: i18n(page, 'robots'),
      isValidated: true,
      modifiedInValidation: false,
      channel: this.props.options.channel
    }

    this.ps = null
    this.psLeft = null
  }

  @autobind
  getFocus() {
    return this.focus
  }

  @autobind
  setScrollBar() {
    this.ps = new PerfectScrollbar('#pageSettings-container', {
      suppressScrollX: true
    })

    this.psLeft = new PerfectScrollbar('#pageSettings-options-container', {
      suppressScrollX: true
    })
  }

  @autobind
  handleRef(el) {
    if (el !== null) {
      this.focus = el
    }
  }

  @autobind
  handleClickOptionElement(e) {
    e.preventDefault()

    this.setState({
      selectedTab: e.target.name
        || e.target.parentElement.name
        || e.target.parentElement.getAttribute('for')
    })
  }

  @autobind
  handleInputChange({ target: { id, value, type, checked } }) {

    if (type === 'checkbox' || type === 'radio') {
      value = checked
    }

    const data = deepSet({
      ...this.state
    }, id, value)

    this.setState(data)
    this.setState({
      modifiedInValidation: false
    })

    if (id === 'publishedUrl') {

      // No Validation required, if it matches the original value
      if (value === this.state.originPublishedUrl) {
        this.setState({
          valState: '',
          isValidated: true
        })
        return
      }

      // Invalidate filename / publishedUrl
      this.setState({
        valState: 'needValidation',
        isValidated: false
      })
    }
  }

  @autobind
  handleValidate(e) {
    e.preventDefault()

    if (!this.state.isValidated) {

      if (this.state.publishedUrl.length === 0) {
        this.setState({
          publishedUrl: this.state.originPublishedUrl,
          isValidated: true,
          valState: '',
        })
        return
      }
      // Remove spaces and return if string length < 2
      if (this.state.publishedUrl.replace(/\s/g, '').length < 2) {

        this.setState({
          valState: 'isInvalid'
        })
        return
      }

      const page = this.props.options.page.store

      // Set pending text
      this.setState({
        valState: 'isValidating'
      })

      page.validateUrl(this.state.publishedUrl)
        .then((result) => {

          const resultUrl = i18n(result.body, 'url')

          if (!result.body.isInUse) {
            if (this.state.publishedUrl !== resultUrl) {
              this.setState({
                modifiedInValidation: true
              })
            }
            this.setState({
              isValidated: true,
              valState: 'isValid',
              publishedUrl: resultUrl
            })
          }

          // if filename is already in use
          else {
            this.setState({
              valState: 'isInUse',
              publishedUrl: resultUrl
            })
          }
        })
        .catch(() => {
          console.log('Error in PageSettings - handleValidate')
        })
    }
  }

  @autobind
  handleCommit(e) {

    if (e && e.preventDefault) {
      e.preventDefault()
    }

    const baseData = {
      i18n: {
        title: this.state.titleTag,
        name: this.state.name,
        navigationName: this.state.navigationName,
        keywords: this.state.keywords,
        robots: this.state.robots,
        description: this.state.metaDescription,
        header: '',
        url: this.state.publishedUrl
      },
      meta: this.state.meta,
      showInSearch: this.state.showInSearch,
      showInNavigation: this.state.showInNavigation,
      createFolder: this.state.createFolder,
      hideBreadcrumb: this.state.hideBreadcrumb,
      hideFromRobots: this.state.hideFromRobots,
    }

    return this.props.proceed({
      data: {
        ...baseData,
        ...(this.props.plugins || [])
          .reduce((memo, { module }) => {
            return module.applyData(memo, this.state)
          }, baseData)
      },
    })
  }

  renderOptionElement(tabSpec) {

    const name = tabSpec.name
    const messageID = `page-settings-dialog.${name}`
    const selected = this.state.selectedTab === name

    return (
      <GenevaButton
        id={name}
        name={name}
        key={name}
        type="button"
        onClick={this.handleClickOptionElement}
        className={classNames({ selected },
          'plain button tab-button')}
      >
        <Icon name={tabSpec.icon} /><br />
        <FormattedMessage id={messageID} />
      </GenevaButton>
    )
  }

  @autobind
  renderFooter() {
    return (
      <div className="grid-content text-right">
        <GenevaButton
          type="button"
          className="small button"
          onClick={this.props.dismiss}
        >
          <FormattedMessage id="new-page-dialog.cancel" />
        </GenevaButton>
        <GenevaButton
          type="submit"
          className="small primary button"
          onClick={this.handleCommit}
          disabled={!this.state.isValidated}
        >
          <FormattedMessage id="new-page-dialog.confirm" />
        </GenevaButton>
      </div>
    )
  }

  render() {
    const {
      show,
      t
    } = this.props

    const { selectedTab, channel } = this.state
    const tabs = [
      ...this.tabs,
      ...(this.props.plugins || []).map(({ module }) => {

        if (!module.verify || module.verify(channel)) {
          return module
        }
        return null

      })
        .filter(n => n)

    ]

    const tabSpec = tabs.find(tab => tab.name === selectedTab)

    if (!tabSpec || !tabSpec.component) {
      throw new Error(`No tab for name "${selectedTab}"`)
    }

    if (this.ps) {
      this.ps.update()
    }

    if (this.psLeft) {
      this.psLeft.update()
    }

    const FormComponent = tabSpec.component
    return (
      <Dialog
        className={css.pageSettingsDialog}
        isOpen={show}
        onAfterOpen={this.setScrollBar}
        onRequestClose={this.handleRequestClose}
        overlayClassName={css.tableDialogOverlay}
        title={formatMessage('page-settings-dialog.title')}
        focusEl={this.getFocus}
        renderFooter={this.renderFooter}
      >
        <div
          id="pageSettings-options-container"
          className="grid-content setting-option"
        >
          {
            tabs.map(item => this.renderOptionElement(item))
          }
        </div>
        <div
          id="pageSettings-container"
          className="grid-block main-content"
        >
          <FormComponent
            {...this.props}
            {...this.state}
            handleRef={this.handleRef}
            onInputChange={this.handleInputChange}
            onValidate={this.handleValidate}
          />
        </div>
      </Dialog>
    )
  }
}

const PluginConnectedPageSettings = connectToPluginHook(
  PageSettings,
  { hook: 'pageSettings' }
)

export default PluginConnectedPageSettings
