import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { DropdownOpener } from '../../../shared/components/Dropdown'
import GenevaButton from '../GenevaButton'

// eslint-disable-next-line
export default class GenevaDropdownOpener extends Component {

  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.object, PropTypes.array, PropTypes.string
    ]),
  }

  render() {
    const { children } = this.props
    return (
      <DropdownOpener
        {...this.props}
        ButtonComponent={GenevaButton}
      >
        {children}
      </DropdownOpener>
    )
  }
}
