import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { isNumber } from 'lodash'

export default function connectResizatableToContext(Resizable, parentProps) {
  if (!parentProps.articlePlaceholders) {
    throw new Error(
      '`connectResizatableToContext` '
        + "requires an articlePlaceholders Object in it's "
        + 'parentProps'
    )
  }


  @observer
  class PMContextConnectedResizable extends Component {
    static propTypes = {
      pid: PropTypes.string.isRequired,
      value: PropTypes.array,
      style: PropTypes.object,
      children: PropTypes.node,
      env: PropTypes.object,
      className: PropTypes.string,
    };


    getDefaultValue() {
      return {
        width: 'auto',
        height: 'auto',
      }
    }

    validateValue(value) {
      if (
        value
        && Object.prototype.toString.call(value) === '[object Object]'
        && (value.width || value.height)
      ) {
        return value
      }
      return null
    }

    render() {
      const value
        = this.props.value
        || this.validateValue(
          parentProps.articlePlaceholders.get(this.props.pid) || null
        )
        || this.getDefaultValue()

      const { width, height } = value

      return (
        <div
          style={{
            ...this.props.style,
            width: isNumber(width) ? `${width}px` : 'auto',
            height: isNumber(height) ? `${height}px` : 'auto',
          }}
          className={this.props.className}
        >
          {this.props.children}
        </div>
      )
    }
  }

  // eslint-disable-next-line max-len
  PMContextConnectedResizable.TransformationInfo = function PMContextConnectedResizableTransformationInfo() {
    return null
  }

  return PMContextConnectedResizable
}
