import { isFinite, isObject } from 'lodash'

import { store as uiStore } from '../ui'
import i18n from './utils/i18n'
import { deepGet } from './obj'

import { GENEVA_CLASS_PREFIX } from './const'
import { compiler } from '../config'

// eslint-disable-next-line
export { i18n };

/**
 * Generates a guid.
 * @returns A pseudo-random guid.
 */
export function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1)
  }
  return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`
}

/**
 * Get's the available link styles.
 * @todo Should go into a `customer.js` file or similar.
 * @returns {Array} - An Array of all available link appearances for the
 * current customer.
 */
export function getAvailableLinkAppearance(config) {
  return deepGet(config, 'editor.buttonOptions.linkPrompt.appearances', []).map(
    appearance => ({
      className: appearance.className,
      name: i18n(appearance, 'name', uiStore.language),
    })
  )
}

export function getAvailableTableAppearance(config) {
  return deepGet(
    config,
    'editor.buttonOptions.insertTable.subCommands.changeAppearance.values',
    []
  ).map(appearance => ({
    value: appearance.value,
    name: i18n(appearance, 'name', uiStore.language),
    translatedName: i18n(appearance, 'name', uiStore.language),
  }))
}

export function getAvailableForeColors() {
  return global.GENEVA_CONFIG.editor.buttonOptions.foreColor || []
}

export function getAvailableFontStyles() {
  return global.GENEVA_CONFIG.editor.buttonOptions.fontStyle || []
}

export function getContentClass() {
  return global.GENEVA_CONFIG.contentClass
}

/**
 * Get's the user's name for given `id`.
 * @todo Should go into a `customer.js` file or similar.
 * @param {String|Number} id - The id of the user.
 * @returns {String} - The name of the user or "Unknown user" if no user goes
 * by this `id`.
 */
export function getUserNameById(id) {
  // TODO: translate "Unknown user"
  return global.GENEVA_CONFIG.users[id] || 'Unknown user'
}

/**
 * Get's the template's name for given `id`.
 * @todo Should go into a `customer.js` file or similar.
 * @returns {Array} - The name of the template or "Unknown template" if no
 * template goes by this `id`.
 */
export function getTemplateNameById(id) {
  const template = global.GENEVA_CONFIG.templates.find(
    templ => templ.id === id
  )

  // TODO: translate "Unknown template"
  return template ? template.name : 'Unknown template'
}

/**
 * Get's the widget template's name for given `id`.
 * @todo Should go into a `customer.js` file or similar.
 * @returns {Array} - The name of the template or "Unknown template" if no
 * template goes by this `id`.
 */
export function getWidgetTemplateNameById(id) {
  const template = global.GENEVA_CONFIG.widgetTemplates.find(
    templ => templ.id === id
  )

  // TODO: translate "Unknown template"
  return template ? template.name : 'Unknown template'
}

/**
 * Get's the pagetemplates
 */
export function getPageTemplates(channel) {
  const templates = global.GENEVA_CONFIG.pageTemplates
  if (!templates) {
    throw new Error('pageTemplates do not exist')
  }

  if (channel) {
    return templates[channel] || []
  }
  return templates
}

export function getPageTemplateNameById(id, channel) {
  const templates = getPageTemplates(channel)
  const template = templates.find(item => item.id === id)

  // TODO: translate "Unknown template"
  return template ? template.name : 'Unknown template'
}

/**
 *
 * @param {Array<Object | string>} - buttons
 * @returns {Array<Object | string>}
 */
export function filterCustomerAllowedButtons(buttons, editorConfig = null) {
  if (!editorConfig || !editorConfig.buttons || editorConfig.buttons === '*') {
    return buttons
  }

  let last = null

  buttons = buttons.filter((button) => {
    const name = button.cmd ? button.cmd : button
    // keep separators, but only if they were not immediately preceeded by
    // another separator
    const retval
      = name === 'separator'
        ? last !== 'separator'
        : editorConfig.buttons.indexOf(name) !== -1
    // only update last if the button is actually used. otherwise skip it
    last = retval ? name : last
    return retval
  })

  if (last === 'separator') {
    buttons.pop()
  }

  return buttons
}

export function versionizePath(path) {
  // bail out if renderer / node env
  if (
    typeof window === 'undefined'
    || (process
      && process.env
      && process.env.NODE_ENV
      && process.env.NODE_ENV === 'test')
  ) {
    return path
  }

  const cacheBusterMethod = compiler && compiler.cacheBusterMethod

  if (cacheBusterMethod === 'timestamp') {
    // Adjust the path with the current timestamp to prevent caching after update
    path = `${path}?t${new Date().getTime()}`
  }
  else {
    const version = deepGet(global.GENEVA_CONFIG, 'templateVersion')

    // bail out if there is no version to add
    if (!version) {
      return path
    }

    // Adjust the path with the current template version to prevent caching after update
    path = `${path}?v${version}`
  }

  return path
}

/**
 * Adds the given stylesheet url as new stylesheet to
 * the document.
 * @param {String} href - The stylesheet url
 */
export function addStyleSheet(href) {
  const fileref = document.createElement('link')
  fileref.setAttribute('rel', 'stylesheet')
  fileref.setAttribute('type', 'text/css')
  fileref.setAttribute('href', href)
  if (fileref !== undefined) {
    document.getElementsByTagName('head')[0].appendChild(fileref)
  }
}

/**
 * Removes the given stylesheet identified by given url from
 * the document.
 * @param {String} href - The stylesheet url
 */
export function removeStyleSheet(href) {
  const targetElement = 'link'
  const targetAttr = 'href'
  const allSuspects = document.getElementsByTagName(targetElement)
  let i
  for (i = allSuspects.length; i >= 0; i -= 1) {
    if (
      allSuspects[i]
      && allSuspects[i].getAttribute(targetAttr) !== null
      && allSuspects[i].getAttribute(targetAttr).indexOf(href) !== -1
    ) {
      allSuspects[i].parentNode.removeChild(allSuspects[i])
    }
  }
}

/**
 * Checks if the given stylesheet is already in the document.
 * @param {String} href - The stylesheet url
 */
export function isStyleSheetLoaded(href) {
  const targetElement = 'link'
  const targetAttr = 'href'
  const allSuspects = document.getElementsByTagName(targetElement)
  let i
  let suspect

  for (i = allSuspects.length - 1; i >= 0; i -= 1) {
    suspect = allSuspects[i]
    if (
      suspect
      && suspect.getAttribute(targetAttr) !== null
      && suspect.getAttribute(targetAttr).indexOf(href) !== -1
    ) {
      return true
    }
  }
  return false
}

export function loadStyleSheet(path, reload) {
  return new Promise((resolve) => {
    if (reload) {
      removeStyleSheet(path)
    }

    setTimeout(() => {
      if (reload || !isStyleSheetLoaded(path)) {
        path = versionizePath(path)
        addStyleSheet(path)
      }
      resolve(path)
    }, 1)
  })
}

export function isNumberLike(suspectedNumber) {
  return isFinite(suspectedNumber * 1)
}

export function ensureNumber(suspectedNumber) {
  return isNumberLike(suspectedNumber) ? suspectedNumber * 1 : undefined
}

/**
 * @param {String} str -
 */
export function camelCase(str) {
  if (typeof str !== 'string') {
    const type = Object.prototype.toString.call(str)
    throw new Error(
      `\`camelCase\` expects a string as only parameter. ${type} given!`
    )
  }

  if (/^\S{2}-\S{2}$/.test(str)) {
    return str
  }

  return str.replace(/\S[-_]([\S])/gi, (match) => {
    return `${match[0]}${match[2].toUpperCase()}`
  })
}

export function camelCaseJSON(data = undefined) {
  let key
  if (!(data && (isObject(data) || Array.isArray(data)))) {
    return data
  }
  const newData = Array.isArray(data) ? [] : {}
  // eslint-disable-next-line
  for (key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      const val = data[key]
      let camelKey = camelCase(key)
      if (camelKey === 'i18N') {
        camelKey = 'i18n'
      }
      if (Array.isArray(val) || isObject(val)) {
        newData[camelKey] = camelCaseJSON(val)
      }
      else {
        newData[camelKey] = val
      }
    }
  }
  return newData
}

/**
 * Create geneva specific css classes
 * @todo Use this to add geneva- prefix to all base stylings.
 * @returns {String} - The combined string for a className
 */
export function genevaCssClass(...args) {
  if (!args.length) {
    return ''
  }
  return `${GENEVA_CLASS_PREFIX}-${args.join('-')}`
}

export function testClass(className) {
  return `test-${className}`
}

export function getIdByStatusName(name) {
  console.log('not implemented')
  return -1
}
export function getStatusNameById(id) {
  console.log('not implemented')
  return 'unknown'
}

export function isModuleIsMissingError(e) {
  const msg = e.toString()
  return msg.indexOf('404 Not Found') > -1
  // in case of local files (e.g. in Tests)
  || msg.indexOf('no such file or directory') > -1
}

/**
 * default add to collection
 * @param {*} self - allows store reference added to model
 * @param {*} collection - any collection to add to
 * @param {*} itemData - raw data to make a model of or update
 * @param {*} Model - model
 * @param {*} opts - {
 *              replace: ignores previous added item
 *              reset: clears collection before adding new item (only if item is not in existing collection)
 *            }
 * @returns model of type Model
 */
export function addToCollection(self, collection, itemData, Model, opts = {}) {
  if (!itemData.id) {
    throw new Error('attempting to add an object to a collection with no id')
  }

  const alreadyExistingModel = itemData.id && collection.find(obj => obj.id === itemData.id)
  let ItemModel = itemData

  if (alreadyExistingModel && !opts.replace) {
    if (typeof alreadyExistingModel.set === 'function') {
      alreadyExistingModel.set(itemData)
    }
    else {
      Object.assign(alreadyExistingModel, itemData)
    }
    return alreadyExistingModel
  }

  if (opts.replace) {
    const collectionIndex = collection.findIndex(el => el.id === itemData.id)
    if (collectionIndex > -1) {
      collection.splice(collectionIndex, 1)
    }
  }

  // build the model
  if (Model && !(ItemModel instanceof Model)) {
    ItemModel = new Model(self, itemData)
  }

  if (opts.reset) {
    collection = []
  }
  collection.splice(collection.length, 0, ItemModel)

  return ItemModel
}
