import * as validate from 'validate.js'

Object.keys(validate)
  .filter(key => /^is[A-Z]/.test(key))
  .forEach((methodKey) => {
    const type = methodKey.replace(/^is/, '').toLowerCase()

    // eslint-disable-next-line func-names
    validate.validators[type] = function (
      value, // The value exactly how it looks in the attribute object.
      options, // The options for the validator. Guaranteed to not be null or undefined.
      key, // The attribute name.
      attributes, // The entire attributes object.
      globalOptions // The options passed when calling validate (will always be an object, non null
    ) {
      return validate[methodKey](value)
        ? null
        : `Expected \`${key}\` to be of type \`${type}\`, but was \`${typeof value}\``
    }
  })

validate.validators.oneOf = function oneOf(
  value,
  options,
  key,
  attributes,
  globalOptions
) {
  if (!Array.isArray(options)) {
    throw new Error('Expected the options to be an array!')
  }

  return options.indexOf(value) > -1
    ? null
    : `Expected \`${key}\` to be one of \`${options.join(
      ', '
    )}\`, but was \`${value}\``
}

validate.validators.oneOfType = function oneOfType(
  value,
  options,
  key,
  attributes,
  globalOptions
) {
  if (!Array.isArray(options)) {
    throw new Error('Expected the options to be an array!')
  }

  return options.every((type) => {
    const method = `is${type[0].toUpperCase()}${type.substring(1)}`
    return !validate[method](value)
  })
    ? null
    : `Expected \`${key}\` to be one of \`${options.join(
      ', '
    )}\`, but was \`${value}\``
}

validate.validators.either = function eitherOr(
  value,
  options,
  key,
  attributes,
  globalOptions
) {
  const existingParts = key
    .split('|')
    .map(s => s.trim())
    .filter((part) => {
      return part in attributes && part[attributes]
    })

  const invalidParts = existingParts
    .map((part) => {
      return validate(attributes, options[part])
    })
    .filter(invalid => !!invalid)

  // if we have exactly as many invalid parts as we have existing parts,
  // then none of the parts was valid and we should report an error
  if (invalidParts.length === existingParts.length) {
    return invalidParts.reduce((memo, part) => {
      return { ...memo, ...part }
    }, {})
  }

  return null
}

const validateFn = typeof validate === 'function' ? validate : validate.validate

function betterValidate(attributes, constraints, options) {
  const resolvedConstraints = Object.keys(constraints).reduce((memo, key) => {
    let value = constraints[key]
    // TODO
    // there should be a value instanceof ValidationHelper check here, but that
    // would cause a circular dependency right now, so we do a plain simple
    // duck typing check
    if (value && 'get' in value && value.get) {
      value = value.get()
    }
    memo[key] = value
    return memo
  }, {})
  return validateFn(attributes, resolvedConstraints, options)
}

export { betterValidate as default, validateFn as validate }
