import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { autobind } from 'core-decorators'
import classNames from 'classnames'
import { TreeNode } from 'draggable-react-tree-component'
import PerfectScrollbar from 'perfect-scrollbar'
import { formatMessage, FormattedMessage } from '../../translations'

import ContentLoadingBox from '../../shared/components/ContentLoadingBox'
import ProjectDropdown from '../../shared/components/ProjectDropdown'
import LanguageDropdown from '../../shared/components/LanguageDropdown'
import { testClass } from '../../shared/utils'

import PageTreeContainer from './Tree'
import BranchSelect from '../containers/Tree/BranchSelect'

const css = typeof window === 'undefined' ? {} : require('../styles.scss')

@observer
class CopyManagerRemotePanel extends Component {

  static propTypes = {
    project: PropTypes.object,
    projectStore: PropTypes.object,
    pageStore: PropTypes.object,
    updateExternalDragNode: PropTypes.func,
    externalDragNode: PropTypes.object,
    externalDragNodeKeys: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string
    ]),
  }

  constructor(props) {
    super(props)
    this.remoteProjectChannel = props.project.channel
    this.ps = null
  }

  // eslint-disable-next-line react/sort-comp
  @observable remoteProject = this.props.project

  @observable expandedKeys= new Set()

  @observable dropdownIndicator = {
    // option: 0 - single copy, 1 - copy all sub pages
    option: 0,
    // id is the page id of the last dropdown used
    id: 0
  }

  componentDidMount() {
    this.ps = new PerfectScrollbar('#remote-copy-tree', {
      suppressScrollX: true
    })
  }

  isReady() {
    return !this.remoteProject.loading && this.remoteProject.isComplete
  }

  @autobind
  handleExpand(expandedKeys) {
    this.expandedKeys.clear()
    expandedKeys.forEach(item => this.expandedKeys.add(item))
  }

  @autobind
  handleBranchSelection(parent) {
    this.dropdownIndicator = {
      option: 1,
      id: parent.id
    }
  }

  @autobind
  handleBranchDeselection(parent) {
    this.dropdownIndicator = {
      option: 0,
      id: parent.id
    }
  }

  /**
   * receives project group and gets first project from list to use as remote-current
   * @param {event} param0
   */
  @autobind
  handleProjectGroupChanged({ target }) {
    const { projectStore } = this.props

    const desiredProjGroup = projectStore.getGroupById(target.value)
    if (desiredProjGroup && desiredProjGroup.projects.length > 0) {
      const desiredProj = desiredProjGroup.projects[0]

      if (desiredProj.pages.length > 0) {
        this.remoteProject = desiredProj
      }
      else {
        this.props.projectStore.load(desiredProj.id, { backgroundLoad: true })
          .then((model) => {
            this.remoteProject = model
          })
      }
    }
  }

  /**
   * receives project by language choice
   * @param {event} param0
   */
  @autobind
  handleLanguageChanged({ target }) {
    const { projectStore } = this.props

    const desiredProj = projectStore.getPartialById(target.value)

    if (desiredProj.pages.length > 0) {
      this.remoteProject = desiredProj
    }
    else {
      this.props.projectStore.load(target.value, { backgroundLoad: true })
        .then((model) => {
          this.remoteProject = model
        })
    }
  }

  @autobind
  handleDragOver({ event }) {
    event.dataTransfer.dropEffect = 'none'
  }

  @autobind
  handleDragStart({ event, node, nodeKeys }) {

    event.dataTransfer.effectAllowed = 'copy'

    // only drag one node if:
    // a. no dropdown selection happened
    // b. a node was dragged which is not selected via dropdown
    if (this.dropdownIndicator.option === 0
      // !(`${this.dropdownIndicator.id}` in nodeKeys) // removed because of issue with chrome
      || nodeKeys.indexOf(`${this.dropdownIndicator.id}`) === -1
    ) {
      nodeKeys = nodeKeys.slice(-1)
    }

    // Set the dragging node
    this.props.updateExternalDragNode(node, nodeKeys, 'remote')
  }

  @autobind
  renderHeader() {
    const baseGroupProject = this.props.projectStore.getGroupById(this.remoteProject.projectGroupId)

    const projectName = baseGroupProject ? baseGroupProject.name : formatMessage({ id: 'project.loading' })

    return (<div className={classNames(css.copyManOptionsBar, 'v-align')}>
      <ProjectDropdown
        className="small button plain"
        projectStore={this.props.projectStore}
        currentProject={this.remoteProject}
        onSelect={this.handleProjectGroupChanged}
        isScrollable
        isButton
        isGenevaButton
        title={projectName}
        channel={this.remoteProjectChannel}
      />
      <LanguageDropdown
        className="small button plain"
        projectStore={this.props.projectStore}
        currentProject={this.remoteProject}
        onSelect={this.handleLanguageChanged}
        isScrollable
        isButton
        isGenevaButton
        label={this.remoteProject.language}
      />
    </div>)
  }

  renderRemoteItem(item) {
    return item.navigationName || item.name
  }

  renderBranchSelect(item) {
    let localDI = false
    if (item.id === this.dropdownIndicator.id
        && this.dropdownIndicator.option === 1
    ) {
      localDI = true
    }
    return (<BranchSelect
      item={item}
      dropdownIndicator={localDI}
      handleBranchSelection={this.handleBranchSelection}
      handleBranchDeselection={this.handleBranchDeselection}
    />)
  }

  @autobind
  renderItems(items) {
    return items.map((item) => {
      return (<TreeNode
        key={item.id}
        items={(item.sub && item.sub.length) ? this.renderItems(item.sub) : null}
        className="node"
      >
        <div className={classNames('grid-block',
          css.copyManagerItem,
          testClass(`item-remote-${item.name}`))}
        >
          <img
            alt=""
            src="/images/drag-icon.png"
            className={classNames({ single: !item.hasChildren })}
          />
          {this.renderRemoteItem(item)}
          {this.renderBranchSelect(item)}
        </div>
        <hr />
      </TreeNode>)
    })
  }

  renderPageTree() {
    const pageTreeChildren = this.remoteProject.pageTree.children || {}

    if (!(pageTreeChildren
      && pageTreeChildren.length > 0)
    ) {
      return (<FormattedMessage
        id="page-tree-not-found"
      />)
    }

    return (<div>
      <hr className="split-screen-hr" />
      <div className="page-type-desc">
        <FormattedMessage id="project.navigation.normal-pages" />
      </div>
      <hr className="split-screen-hr" />
      <PageTreeContainer
        className="publist normal-tree"
        expandedKeys={[...this.expandedKeys]} // convert type set to array
        page={this.props.pageStore}
        project={this.remoteProject}
        onExpand={this.handleExpand}
        renderItems={this.renderItems}

        draggable
        onDragStart={this.handleDragStart}
        onDragEnter={this.handleDragEnter}
        onDragOver={this.handleDragOver}
        onDrop={this.handleDrop}

        autoExpandParent
        checkStrictly
        selectable
        treeChildren={pageTreeChildren}
        externalDragMode={{
          externalDragNode: this.props.externalDragNode,
          externalDragNodeKeys: this.props.externalDragNodeKeys
        }}
      />
    </div>)
  }

  renderSpecialTree() {
    const specialPageTreeChildren = this.remoteProject.specialPageTree.children || {}
    if (!(specialPageTreeChildren
    && specialPageTreeChildren.length > 0)
    ) {
      return (
        <span> </span>
      )
    }
    return (<div>
      <div className="page-type-desc">
        <FormattedMessage id="project.navigation.special-pages" />
      </div>
      <hr className="split-screen-hr" />
      <PageTreeContainer
        className="publist special-tree"
        expandedKeys={[...this.expandedKeys]} // convert type set to array
        page={this.props.pageStore}
        project={this.remoteProject}
        onExpand={this.handleExpand}
        renderItems={this.renderItems}

        draggable
        onDragStart={this.handleDragStart}
        onDragEnter={this.handleDragEnter}
        onDragOver={this.handleDragOver}
        onDrop={this.handleDrop}

        autoExpandParent
        checkStrictly
        selectable
        treeChildren={specialPageTreeChildren}
        externalDragMode={{
          externalDragNode: this.props.externalDragNode,
          externalDragNodeKeys: this.props.externalDragNodeKeys
        }}
      />
    </div>)
  }

  renderTrees() {

    if (!this.isReady()) {
      return (<div
        className={classNames(`
          ${css.controlCenter}
          grid-frame vertical`
        )}
      >
        <ContentLoadingBox
          spinner
          message={{
            id: 'project.loading'
          }}
        />
      </div>)
    }

    return (<div>
      {this.renderPageTree()}
      {this.renderSpecialTree()}
    </div>
    )
  }

  render() {
    if (this.ps) {
      this.ps.update()
    }

    return (<div
      className={classNames(
        'vertical grid-block',
        css.copyManager,
        css.copyManagerRemote,
        testClass('copy-manager-remote'))}
    >
      {this.renderHeader()}
      <div className="option-bar vertical">
      </div>
      <div
        className="grid-block vertical tree-block"
        id="remote-copy-tree"
      >
        {this.renderTrees()}

      </div>
    </div>)
  }
}

export default CopyManagerRemotePanel
