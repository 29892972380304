import { observable, computed } from 'mobx'
import { store as uiStore } from '../ui'
import { apiClient } from '../shared/api'
import { inspector } from '../shared/decorators/inspector'
import { Store } from '../shared/store'
import { i18n } from '../shared/utils'
import Project from './model'
import ProjectGroup from './groupModel'
import { addToCollection } from '../shared/utils'

const defaultVals = {
  page: 0,
  hasMoreItems: null,
  collection: [],
  filter: {},
  loading: null,
  total: 0
}

@inspector('save', 'saving')
@inspector('load', 'project.loading')
@apiClient
export default
class ProjectStore
  extends Store {

  // Put all project list properties here. This regards also state properties.

  // project collection
  @observable collection = []

  // partial projects that are loaded for display options collection
  @observable projectPartialsCollection = []

  // project Group collection
  @observable groupCollection = []

  @observable current = {}

  @computed get hasCurrent() {
    return !!this.current.id
  }

  // only if need ascending set
  @computed get groupCollectionSorted() {
    return this.groupCollection
      .sort((a, b) => {
        const aCombined = `${a.channelName} - ${a.name}`
        const bCombined = `${b.channelName} - ${b.name}`
        return aCombined.localeCompare(bCombined)
      })
  }

  @observable total = 0

  @observable page = 0

  @observable limit = -1

  @observable loading = false

  hasMoreItems = true

  previousProjectId = null

  @observable languageCollection = []

  @observable filter = {}

  @observable projectHistory = []

  // Put all properties that are not to be observed into the constructor.

  constructor(opts = {}) {

    super(opts)

    this.Model = Project
  }

  parse(data) {
    if (data.data) {
      data = data.data
    }
    return data
  }

  load(id = null, opts = {}) {
    if (this.current && this.current.id === id) {
      return new Promise((resolve) => {
        resolve(this.current, { cancelled: true })
      })
    }

    if (!this.loading && id) {
      if (!opts.backgroundLoad) {
        this.loading = true
      }

      const path = `project/${id}`

      return this.dispatch('get', {
        path,
        params: opts.params
      }).then((response) => {
        this.lastLoadFailed = false

        if (!response || !response.body || response.error) {
          throw new Error('error in page get')
        }

        const model = addToCollection(this, this.collection, response.body.data, Project, { replace: true })
        model.isComplete = true

        // if there is a current but it's not a background load, then set it to current
        if (opts && !opts.backgroundLoad) {
          this.clearOngoingOpeningActions(true)
          this.setCurrent(model)
        }

        // If there is not current item, make the first item in the collection
        // the current one
        if ((!this.current.id && model) || opts.setCurrent) {
          this.clearOngoingOpeningActions(true)
          this.setCurrent(model)
        }

        if (!opts.backgroundLoad) {
          this.loading = false
        }
        return model
      })
    }
    return new Promise((resolve) => {
      resolve(this.current, { cancelled: true })
    })
  }

  loadProjects(opts = {}) {
    const path = 'project'

    return this.dispatch('get', {
      path,
      params: opts.params
    }).then((response) => {

      if (!response || !response.body || response.error) {
        throw new Error('error in page get')
      }

      this.projectPartialsCollection = []
      // if request was without 'id', then all projects are returned as an array
      if (Array.isArray(response.body.data)) {
        response.body.data.forEach((projectData) => {
          const model = addToCollection(this, this.projectPartialsCollection, projectData, Project, { replace: true })
          this.addProjectToGroup(projectData.projectGroup, model)
        })
        this.total = response.body.data.length
      }

      return response
    })
  }

  reset(opts) {

    if (this.loading) {
      return
    }

    const vals = {
      ...defaultVals
    }

    delete vals.filter

    if (!('filter' in opts)) {
      delete vals.collection
    }

    Object.assign(this, {
      ...vals,
      ...opts
    })

    this.load()

  }

  canLoad() {
    return !this.loading && this.hasMoreItems
  }

  setCurrent(current = {}) {
    if (this.current && this.current.id && this.current.id === current.id) {
      return
    }

    // if setting current and there currently is one, flag that the page needs to be updated
    if (this.current && this.current.id) {
      this.previousProjectId = this.current.id
    }

    this.current = current
    this.current.isActive = true
    uiStore.contentLanguage = (current && current.language) || 'de-DE'
  }

  setProjectHistory(lastOpenedProjects) {
    this.projectHistory = [
      ...lastOpenedProjects
    ]
  }

  getGroupById(id) {
    return this.groupCollection.find(group => group.id === (id * 1))
  }

  /**
   * @param {string} lang - desired language to return a project id in the same project group
   * this will default to the current lang if one isn't given
   * This also supports article in languages that don't yet have a project
   */
  getSiblingProjectId(
    lang = this.current.language,
    currentId = this.current.id
  ) {

    let currentProjectGroupId = this.current.projectGroupId

    if (!currentProjectGroupId) {
      const project = this.getById(currentId)
      currentProjectGroupId = project ? project.projectGroupId : null
    }

    const desiredGroup = this.getGroupById(currentProjectGroupId) || {}
    const desiredProject = desiredGroup.getProjectByLang(lang) || {}

    return (desiredProject && desiredProject.id) || currentId
  }

  /**
   * all projects in projectPartialsCollection filtered by channel
   * @param {number} channelId
   */
  getProjectsByChannel(channelId) {
    return this.projectPartialsCollection
      .filter(proj => proj.channelId === channelId)
  }

  /**
   * returns the page partials primarily used by trees
   * @param {Number} id
  */
  getPartialById(id) {
    return this.getById(id, { collection: this.projectPartialsCollection })
  }

  /**
   * loops through all projects to see collect all channels
   * @return {Array} - [{ id, name }]
   */
  getChannelList() {
    const channelList = []

    this.projectPartialsCollection.forEach((projectPartial) => {
      const name = i18n(projectPartial, 'channel.i18n.name', uiStore.language)
      if (!channelList.find(channel => channel.id === projectPartial.channelId)) {
        channelList.push({
          id: projectPartial.channelId,
          name
        })
      }
    })
    return channelList
  }

  addToProjectHistory(projectId) {
    projectId *= 1 // ensure number

    // eslint-disable-next-line no-restricted-globals
    if (isNaN(projectId)) {
      console.warn('Only numbers may be added to the project history')
      return
    }

    if (this.projectHistory.find(el => el === projectId)) {
      // remove if already in the list
      const index = this.projectHistory.indexOf(projectId)
      this.projectHistory.splice(index, 1)
    }
    // add to the start of the array
    this.projectHistory.splice(0, 0, projectId)
  }

  /**
   * Group Collection builder
   * @param {groupModel} newGroup
   */
  addProjectToGroup(newGroup, newProject) {
    if (!newGroup.id) {
      throw new Error('missing project group id')
    }

    const existingGroup = this.groupCollection.find(pg => pg.id === newGroup.id)
    if (!existingGroup) {
      const model = new ProjectGroup(newGroup)
      model.projects = [newProject]
      this.groupCollection.push(model)
    }
    else {
      existingGroup.addProject(newProject)
    }
  }

  loadLanguageCollection() {
    if (this.languageCollection.length === 0) {
      // load langages
      return this.dispatch('get', {
        path: 'language'
      }).then((result) => {
        this.languageCollection = result.body.data
      }).catch(() => {
        throw new Error('unable to load languages')
      })
    }
    return false
  }

}
