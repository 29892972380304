import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { autobind, debounce } from 'core-decorators'
import Messenger from '../../shared/messenger'

import { i18n } from '../../shared/utils'

const MM_ID_PREFIX = 'mm_'

export default class MediaManagerIFrame extends Component {

  static propTypes = {
    src: PropTypes.string.isRequired,
    className: PropTypes.string,
    imageSpec: PropTypes.object,
    onChange: PropTypes.func,
    searchValue: PropTypes.string
  }

  constructor(props) {
    super(props)

    this.mmMessageChannel = null
    this.messenger = Messenger.getInstance()
    this.searchValue = ''
  }

  componentWillReceiveProps(props) {
    if (this.messenger && this.searchValue !== props.searchValue) {
      this.searchValue = props.searchValue
      this.sendMessage()
    }
  }

  @debounce(500)
  sendMessage() {
    const message = {
      externalFilter: this.searchValue
    }
    this.messenger.channels[this.mmMessageChannel].recipient
      .postMessage(
        message,
        this.messenger.channels[this.mmMessageChannel].recipientUrl
      )
  }

  @autobind
  handleMessage(channel, message) {

    if (this.mmMessageChannel === channel && this.props.onChange) {

      if (message.type === 'image-selection') {

        let data = message.data

        if (data && !Array.isArray(data)) {
          data = [data]
        }

        const images = data.map(img => ({
          id: MM_ID_PREFIX + img.id,
          i18n: i18n({}, 'draft.original', undefined, {
            w: img.w,
            h: img.h,
            src: img.src
          })
        }))

        this.props.onChange({
          target: { value: images }
        })
      }
    }

  }

  @autobind
  handleLoad() {
    const { src, imageSpec, multiple, maxImages, projectId, lang, projectName } = this.props
    const url = new URL(src)
    if (this.refIFrame) {
      this.iframeWindow = this.refIFrame.contentWindow
      this.messenger.on(`${this.constructor.name}:message`, this.handleMessage)
      this.messenger.initRecipientForModule({
        channel: this.mmMessageChannel,
        recipient: this.iframeWindow,
        recipientUrl: url.origin,
        moduleName: this.constructor.name,
        message: { imageSpec, multiple, maxImages, projectId, lang, projectName }
      },
      (channel) => {
        this.mmMessageChannel = channel
      })
    }
  }

  render() {
    return (
      <iframe
        title="Media Manager iFrame"
        {...this.props}
        ref={(ref) => {
          this.refIFrame = ref
        }}
        onLoad={this.handleLoad}
      />
    )
  }

}
