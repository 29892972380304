import { createConfirmation } from 'react-confirm'
import ConfirmDeleteDialog from '../../components/dialogs/ConfirmDelete'

const confirm = createConfirmation(ConfirmDeleteDialog)


export default function (
  confirmation,
  options = {}
) {
  if (typeof confirmation !== 'string') {
    options = confirmation
    confirmation = ''
  }
  return confirm({ confirmation, options })
}
