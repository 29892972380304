exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".src-article-version-styles__note___3HzUH{background-color:#fff;border-radius:5px;margin-bottom:4px;padding:8px}.src-article-version-styles__noteForm___2XlmG .grid-block .grid-content{padding-left:0;padding-right:0}.src-article-version-styles__noteForm___2XlmG label{padding-left:0;padding-right:0}\n", "", {"version":3,"sources":["/builds/GENEVA/wm/frontend/src/article/version/styles.scss"],"names":[],"mappings":"AAEA,0CACE,sBACA,kBACA,kBACA,WAAY,CACb,wEAKK,eACA,eAAgB,CAJtB,oDASI,eACA,eAAgB,CACjB","file":"styles.scss","sourcesContent":["@import \"../../styles/variables\";\n\n.note {\n  background-color: $secondary-color;\n  border-radius: 5px;\n  margin-bottom: 4px;\n  padding: 8px;\n}\n\n.noteForm {\n  :global(.grid-block) {\n    :global(.grid-content) {\n      padding-left: 0;\n      padding-right: 0;\n    }\n  }\n\n  label {\n    padding-left: 0;\n    padding-right: 0;\n  }\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"note": "src-article-version-styles__note___3HzUH",
	"noteForm": "src-article-version-styles__noteForm___2XlmG"
};