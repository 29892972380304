import { createConfirmation } from 'react-confirm'
import ScreenshotDialog from '../components/ScreenshotDialog'

const confirm = createConfirmation(ScreenshotDialog)


export default function (
  confirmation: string|Object,
  options: Object = {}
): any {
  if (typeof confirmation !== 'string') {
    options = confirmation
    confirmation = ''
  }
  return confirm({ confirmation, options })
}
