import * as React from 'react'
import * as PropTypes from 'prop-types'
import Dispatcher from './Dispatcher'

import {
  Store,
  DispatcherOpts,
  CommandDispatcherContext,
  CommandDispatcherProps
} from './command.d'

export const DispatcherPropType = PropTypes.shape({
  exec: PropTypes.func.isRequired
})

export default
class CommandDispatcherProvider
extends React.Component<CommandDispatcherProps, undefined> {

  static childContextTypes = {
    dispatcher: DispatcherPropType
  }

  private dispatcher: Dispatcher = null

  constructor(props: CommandDispatcherProps) {
    super(props)
    this.dispatcher = new Dispatcher({
      store: this.props.store
    })
  }

  getChildContext(): CommandDispatcherContext {
    return {
      dispatcher: this.dispatcher
    }
  }

  render() {
    return this.props.children
  }

}
