import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { autobind, debounce } from 'core-decorators'
import { formatMessage, FormattedMessage } from '../../../translations'

import InternalLinkPageSelect from '../../../shared/components/Dialog/InternalLinkPageSelect'
import { getI18nLangFromObject } from '../../../shared/utils/i18n'
import Icon from '../../../shared/icons'
import { parseHref } from '../../../shared/link-helper'

const css = require('./styles.scss')

@observer
export default class RedirectDisplayer extends Component {

  static propTypes = {
    pageStore: PropTypes.object,
    projectStore: PropTypes.object,
    currentPage: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.state = {
      name: '',  // name not seo-title-tag
      navigationName: '',
      redirectType: 1, // 1-internal 2-external
      redirectUrl: '',
      redirectTarget: null, // 1-current tab 2-new tab
      externalScheme: `${'http'}://`,  // defaults to http
      externalSchemeType: null,
      readyToRender: false,
    }
    this.currentPageId = null
  }

  componentDidMount() {
    if (this.props.currentPage) {
      this.setUpPage(this.props)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.currentPage.id !== this.currentPageId) {
      this.setUpPage(nextProps)
    }
  }

  @autobind
  setUpPage(props) {
    const parsedHref = parseHref(props.currentPage.redirectUrl)
    this.currentPageId = this.props.currentPage.id
    this.currentPartialPage = this.props.pageStore.getPartialById(this.props.currentPage.id)

    this.setState({
      name: props.currentPage.name,  // name not seo-title-tag
      navigationName: props.currentPage.navigationName || '',
      redirectType: props.currentPage.redirectType || 1, // 1-internal 2-external
      redirectUrl: this.validateInternalLink(parsedHref.externalUrl || parsedHref.internalPage) || '',
      redirectTarget: props.currentPage.redirectTarget, // 1-current tab 2-new tab
      externalScheme: `${parsedHref.externalScheme || 'http'}://`,  // defaults to http
      externalSchemeType: this.getExternalSchemeType(parsedHref.externalScheme),
      readyToRender: true
    })
  }

  getExternalSchemeType(type) {
    if (!type) {
      return ''
    }

    if (type) {
      return type === 'http' ? 0 : 1
    }

    if (this.state.externalSchemeType) {
      return this.state.externalSchemeType
    }

    return 0
  }

  validateInternalLink(url) {
    if (!url) {
      return ''
    }

    const internalIds = url.split(':')

    // removing the extra internal
    if (internalIds && internalIds[1] === 'internal') {
      internalIds.splice(0, 1)
      url = ''
      internalIds.forEach((el) => {
        url = url.concat(el, ':')
      })

      // remove the last ':' again
      url = url.slice(0, -1)
    }

    return url
  }

  @autobind
  handleInputChange({ target }) {
    this.setState({
      [target.id]: target.value
    })
    this.handleSave()
  }

  @autobind
  handleRedirectChange({ target }) {
    const value = target.value.trim()
    let parsedHref = parseHref(value)

    // Depending on the value put into input (with or without schemes like http://)
    if (!parsedHref.externalUrl) {
      // run again but with scheme
      parsedHref = parseHref(this.state.externalScheme + value)
    }
    this.setState({
      redirectUrl: parsedHref.externalUrl,
      externalSchemeType: this.getExternalSchemeType(parsedHref.externalScheme),
      externalScheme: `${parsedHref.externalScheme}://`
    })
    this.handleSave()
  }

  @autobind
  handleSelectionChange({ target }) {
    // do not save when type changed
    // and set link target to the default for that type
    if (target.id === 'redirectType') {
      this.setState({
        redirectType: target.value * 1,
        redirectTarget: target.value * 1,
        redirectUrl: ''
      })
    }
    else if (target.id === 'externalScheme') {
      this.setState({
        externalScheme: target[target.value].text,
        externalSchemeType: target.value
      })
      this.handleSave()
    }
    else {
      this.setState({
        [target.id]: target.value * 1
      })
      this.handleSave()
    }
  }

  @autobind
  handleInternalLinkSelect({ target }) {
    this.setState({
      redirectUrl: target.value,
    })
    this.handleSave()
  }

  @debounce(500)
  handleSave() {
    const page = this.props.currentPage
    const i18nKey = getI18nLangFromObject(page)
    const pageData = {
      redirectUrl: this.state.redirectType === 1  // internal links need an extra internal
        ? `internal:${this.state.redirectUrl}`
        : `${this.state.externalScheme}${this.state.redirectUrl}`,
      redirectType: this.state.redirectType * 1,
      redirectTarget: this.state.redirectTarget * 1,
      i18n: {
        [i18nKey]: {
          ...page.i18n[i18nKey],
          name: this.state.name,
          navigationName: this.state.navigationName
        }
      }
    }

    this.currentPartialPage.set(pageData)
    page.set(pageData)
    page.save()
  }

  renderHeader() {
    return (<div>

      <div className="redirect-header-info">
        <Icon name="ion-ios-information" />
        <div className="grid-block vertical">
          <FormattedMessage id="redirect-dialog.info" />
          <FormattedMessage id="redirect-dialog.info2" />
        </div>
      </div>

      <hr />

      <label htmlFor="name">
        <FormattedMessage id="redirect-dialog.page-title" />
        <input
          id="name"
          type="text"
          value={this.state.name}
          onChange={this.handleInputChange}
        />
      </label>

      <label htmlFor="navigationName">
        <FormattedMessage id="redirect-dialog.navigation-name" />
        <div className="sub-text">
          <FormattedMessage id="redirect-dialog.navigation-name-sub" />
        </div>
        <input
          id="navigationName"
          type="text"
          value={this.state.navigationName}
          onChange={this.handleInputChange}
        />
      </label>

      <hr />

    </div>)
  }

  renderLinkTypeSelection() {
    return (<label htmlFor="redirectType">
      <FormattedMessage id="redirect-dialog.redirectType" />

      <select
        id="redirectType"
        onChange={this.handleSelectionChange}
        value={this.state.redirectType}
      >

        <option value={1}>
          {formatMessage({ id: 'redirect-dialog.internal' })}
        </option>

        <option value={2}>
          {formatMessage({ id: 'redirect-dialog.external' })}
        </option>

      </select>
    </label>)
  }

  renderInternalLinkForm() {
    let url = this.state.redirectUrl

    if (url) {
      url = this.validateInternalLink(url)
    }

    return (<InternalLinkPageSelect
      className="internal-link-form"
      onSelect={this.handleInternalLinkSelect}
      options={{ modal: { page: this.props.pageStore, project: this.props.projectStore } }}
      internalPage={url}
      selectedProject={this.props.projectStore.current}
    />)
  }

  renderExternalLinkForm() {

    return (<label htmlFor="redirectUrl">
      <FormattedMessage id="redirect-dialog.url" />
      <div className="sub-text">
        <FormattedMessage id="redirect-dialog.url-sub" />
      </div>

      <div className="external-scheme">
        <select
          className=""
          name="externalScheme"
          id="externalScheme"
          onChange={this.handleSelectionChange}
          value={this.state.externalSchemeType}
        >
          <option value={0}>
            {formatMessage({ id: 'link-dialog.external-scheme-http' })}
          </option>
          <option value={1}>
            {formatMessage({ id: 'link-dialog.external-scheme-https' })}
          </option>
        </select>

        <input
          id="redirectUrl"
          type="url"
          value={this.state.redirectUrl}
          onChange={this.handleRedirectChange}
        />
      </div>
    </label>)
  }

  renderLinkTargetSelection() {
    return (<label htmlFor="redirectTarget">
      <FormattedMessage id="redirect-dialog.redirectTarget" />

      <select
        id="redirectTarget"
        onChange={this.handleSelectionChange}
        value={this.state.redirectTarget}
      >

        <option value={1}>
          {formatMessage({ id: 'redirect-dialog.target-self' })}
        </option>

        <option value={2}>
          {formatMessage({ id: 'redirect-dialog.target-blank' })}
        </option>

      </select>
    </label>)
  }

  render() {
    if (!this.props.currentPage || !this.props.currentPage.id || !this.state.readyToRender) {
      return null
    }
    return (<div className={css.redirectDisplayer}>
      {this.renderHeader()}
      {this.renderLinkTypeSelection()}
      {this.state.redirectType === 1
        ? this.renderInternalLinkForm()
        : null}
      {this.state.redirectType === 2
        ? this.renderExternalLinkForm()
        : null}
      {this.renderLinkTargetSelection()}
    </div>)
  }
}
