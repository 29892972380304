
import * as PropTypes from 'prop-types'

import React, { Component } from 'react'
import { autobind } from 'core-decorators'
import { filter } from '../../obj'
import FileProcessor from '../../FileProcessor'
import { uploadSettings, processableFileTypes } from '../../../config'

const css = /* typeof window === 'undefined' ? {} : */ require('./styles.scss')

export default class FileSelectButton extends Component {
  static propTypes = {
    className: PropTypes.string,
    accept: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),

    disabled: PropTypes.bool,
    autoFocus: PropTypes.bool,
    required: PropTypes.bool,
    multiple: PropTypes.bool,
    mediaType: PropTypes.string,

    children: PropTypes.node,

    onFocus: PropTypes.func,
    onChange: PropTypes.func,
    onProgress: PropTypes.func,
    onFileDragHover: PropTypes.func,

    // wether or not to read a file with the file reader
    readFile: PropTypes.bool,

    // wether or not to reset the input in this rerun
    reset: PropTypes.bool,
  };

  static defaultProps = {
    disabled: false,
    autoFocus: false,
    required: false,
    multiple: false,
    readFile: false,
    children: 'Upload',
    mediaType: 'image',
  };

  ensureFileSize(result) {
    const file = result[0]
    const maxSize
      = file instanceof Blob
        ? uploadSettings[this.props.mediaType].fileSize.multiUpload
        : uploadSettings[this.props.mediaType].fileSize.singleUpload

    return file.size < maxSize
  }

  ensureFileNameLength(files) {
    const ret = []
    const fileConfig = uploadSettings[this.props.mediaType]
    if (!fileConfig) {
      console.warn('missing file config in geneva customer config')

    }

    // files is of type "FileList"
    for (let i = 0; i < files.length; i++) {
      const file = files.item(i)

      if (
        file && fileConfig
        && file.name.length > fileConfig.fileNameLength
      ) {
        ret.push(file.name)
      }
    }

    return ret
  }

  @autobind
  handleFileChange(event) {
    event.preventDefault()

    const files = event.target.files || event.dataTransfer.files

    if (!files) {
      throw new Error(`${this.constructor.name}: No files in input!`)
    }

    const invalidFileNames = this.ensureFileNameLength(files)
    if (this.props.onError && invalidFileNames.length) {
      this.props.onError({
        message: 'file.name-invalid',
        names: invalidFileNames,
      })
      return
    }

    const processor = new FileProcessor({
      processableFileTypes: this.props.knownFileTypes || processableFileTypes,
      readFile: this.props.readFile,
    })

    processor.processFiles(files).then((result) => {
      if (!Array.isArray(result)) {
        event = [result]
      }

      if (this.props.onError && !this.ensureFileSize(result)) {
        this.props.onError(new Error(`${this.props.mediaType}.size-invalid`))
      }
      else if (this.props.onChange) {
        this.props.onChange(result)
      }
    })
  }

  @autobind
  handleFileDragHover(e) {
    e.stopPropagation()
    e.preventDefault()

    if (this.props.onFileDragHover) {
      let isFileDrag = false
      const data = e.dataTransfer

      if (e.type === 'dragover') {
        isFileDrag
          = data.types
          && ((data.types.length && data.types[0] === 'Files')
            || (data.types.contains
              && data.types.contains('application/x-moz-file')))
      }

      this.props.onFileDragHover({
        target: {
          dragOver: isFileDrag,
        },
      })
    }
  }

  @autobind
  handleFileDrop(e) {
    this.handleFileDragHover(e)
    this.handleFileChange(e)
  }

  render() {
    const { children } = this.props
    const inputProps = filter(this.props, [
      'disabled',
      'accept',
      'autoFocus',
      'required',
      'multiple',
    ])

    if (!inputProps.accept) {
      inputProps.accept = uploadSettings[this.props.mediaType].fileTypes
    }

    return (
      <label
        className={`${this.props.className} ${css.fileUpload}`}
        onDragOver={this.handleFileDragHover}
        onDragLeave={this.handleFileDragHover}
        onDrop={this.handleFileDrop}
      >
        <input
          key="file-select-input"
          type="file"
          {...inputProps}
          onChange={this.handleFileChange}
          multiple={this.props.multiple}
          ref={(ref) => {
            if (ref && this.props.reset) {
              ref.value = null
            }
          }}
        />
        {children}
      </label>
    )
  }
}
