import { createConfirmation } from 'react-confirm'
import FontStyleDialog from '../components/Dialog/FontStyleDialog'

const fontStyleDialog = createConfirmation(FontStyleDialog)

export default function (confirmation, options = {}) {

  if (typeof confirmation !== 'string') {

    options = {
      modal: {
        ...confirmation.data,
      }
    }
    confirmation = ''

  }

  return fontStyleDialog({ confirmation, options })

}
