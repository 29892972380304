import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import { toJS } from 'mobx'
import { observer } from 'mobx-react'
import { appendQueries, extractIdFromUrl } from '../../../shared/utils/url'

export default function connectMediaToContext(Media, parentProps) {

  @observer
  class PMContextConnectedMedia extends Component {
    static propTypes = {
      value: PropTypes.string,
      pid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    };


    render() {
      const env = toJS(parentProps.env)

      let value
        = this.props.value || parentProps.articlePlaceholders.get(this.props.pid)

      const alt = parentProps.articlePlaceholders.get(
        this.props.pid,
        'value.alt',
        parentProps.articlePlaceholders.defaultLang
      )
      const title = parentProps.articlePlaceholders.get(
        this.props.pid,
        'value.title',
        parentProps.articlePlaceholders.defaultLang
      )

      // Bail out if not in CM and no value
      if (!value && !env.CM) {
        return null
      }

      let id
      if (value && typeof value === 'object') {
        id = value.id
        value = value.url

        // Sometimes old videos are not saved with an ID
        // So extract it out of the url
        if (!id) {
          id = extractIdFromUrl(value)
        }

        // TODO: Adjust the data in DB
        // Sometimes old videos has a complete url as ID
        // and therefore a not usable url
        // An ID should never contain 'id='
        // Pass the correct id to the mediaType
        if (id && id.includes('id=')) {
          value = id
          id = extractIdFromUrl(id)
        }
      }

      if (value && typeof value !== 'string') {
        console.error(
          `Invalid value of type \`${typeof value}\` for Media (value: \`${JSON.stringify(
            value
          )}\`). Check placeholder id \`ph${this.props.pid}\` for ${
            parentProps.articlePlaceholders.name
          }.`
        )
        value = null
      }

      if (value) {
        // appending the query ensures that the image is actually reloaded
        value = appendQueries(value, `${new Date().getTime()}`)
      }

      return (
        <Media
          {...this.props}
          alt={alt}
          title={title}
          pid={`${this.props.pid}`}
          id={id}
          value={value}
          env={env}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
        />
      )
    }
  }

  return PMContextConnectedMedia
}
