import { uploadSettings } from '../../config'

// Added support function when a cropper sizing option is being used
// and not the default min sizing
export function createDestConstraints(image) {
  // if croppersize is allowed, then verify that the upload isn't too large
  let destWidth = image.constraints.minWidth
  let destHeight = image.constraints.minHeight
  if (image.constraints.useCropperSize) {
    const customerMaxWidth = uploadSettings.image.imageMaxWidth
    const customerMaxHeight = uploadSettings.image.imageMaxHeight
    // see if sizing is truly too big
    if (image.frame.width > customerMaxWidth || image.frame.height > customerMaxHeight) {
      const widthOverSizeRatio = image.frame.width / customerMaxWidth
      const heightOverSizeRatio = image.frame.height / customerMaxHeight
      const greaterRatio = widthOverSizeRatio > heightOverSizeRatio
        ? widthOverSizeRatio
        : heightOverSizeRatio
      // greaterRatio should be something > 1, so this division brings
      // both down by that amount and keeps the ratio
      destWidth = image.frame.width / greaterRatio
      destHeight = image.frame.height / greaterRatio
    }
    else {
      destWidth = image.frame.width
      destHeight = image.frame.height
    }
  }

  return {
    w: destWidth,
    h: destHeight
  }
}
