import * as PropTypes from 'prop-types'
import { Component } from 'react'

/**
 * Expires (hides) children after a given `delay`.
 */
export default class Expire extends Component {
  static propTypes = {
    /**
     * Whether or not the node is visible
     */
    visible: PropTypes.bool,
    /**
     * The delay till the children should expire (and be hidden)
     */
    delay: PropTypes.number,
    /**
     * The children that should expire after `duration`
     */
    children: PropTypes.node,
    /**
     * Callback that get's called once the timeout expires
     */
    onExpire: PropTypes.func,
  };

  static defaultProps = {
    delay: 1000,
    visible: false,
  };

  constructor(props) {
    super(props)
    this.state = {
      visible: 'visible' in props ? props.visible : true,
    }
  }

  componentDidMount() {
    this.setTimer()
  }

  componentWillReceiveProps(nextProps) {
    // reset the timer if children are changed
    if (nextProps.children !== this.props.children) {
      this.setTimer()
      this.setState({ visible: true })
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
  }

  setTimer() {
    // clear any existing timer
    clearTimeout(this.timer)

    // hide after `delay` milliseconds
    this.timer = setTimeout(() => {
      this.setState({ visible: false }, () => {
        if (this.props.onExpire) {
          this.props.onExpire()
        }
      })
      this.timer = null
    }, this.props.delay)
  }

  render() {
    let retval = null

    if (this.state.visible) {
      retval = this.props.children
    }

    if (!retval) {
      retval = null
    }

    return retval
  }
}
