import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { autobind } from 'core-decorators'
import { formatMessage } from '../../../../../translations'

import alert from '../../../../../shared/components/Alert'
import validate from '../../../../../shared/utils/validate-object'

import { dispatcher } from '../../../../../shared/lib/command'

@dispatcher
@observer
export default
class PageConnectedEditArticleButton
  extends Component {

  static propTypes = {
    disabled: PropTypes.bool,
    page: PropTypes.object,
    onGetContext: PropTypes.func.isRequired,
    viewModels: PropTypes.shape({
      currentlyOpenWidget: PropTypes.shape({
        item: PropTypes.object,
      })
    }),
    Target: PropTypes.func.isRequired,
    router: PropTypes.shape({
      push: PropTypes.func
    }).isRequired,
  }

  constructor(props) {
    super(props)
    this.validateProps()
  }

  componentWillReceiveProps() {
    this.validateProps()
  }

  validateProps() {
    try {
      validate(this.props, {
        onGetContext: 'function',
      }, '`EditArticleButton` expects it\'s `props`')
    }
    catch (ex) {
      console.error(ex.message)
    }
  }

  @autobind
  handleContentActionTriggered() {

    const {
      onGetContext,
      router
    } = this.props

    try {
      const { item } = onGetContext()

      item.type = item.type || 'article'

      this.context.dispatch(

        this.props.commands.EditItemCommand,
        {
          router,
          ...item
        }

      )

    }
    catch (ex) {
      alert(
        formatMessage({ id: 'widget.error.cannot-open-content-item' }),
        formatMessage({ id: 'widget.error.opening-item' })
      )
      console.error(ex)
    }

  }

  render() {

    const {
      Target,
      page,
      viewModels: { currentlyOpenWidget },
      ...rest } = this.props

    const widget = currentlyOpenWidget.data.item

    return (<Target
      {...rest}
      disabled={!widget || widget.isUpdating}
      pageId={page.id}
      onClick={this.handleContentActionTriggered}
    />)

  }
}
