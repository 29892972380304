import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { Link } from 'react-router'
import { autobind } from 'core-decorators'
import classNames from 'classnames'
import { FormattedMessage } from '../../../translations'

import { getUserNameById } from '../../../shared/utils'
import FormattedDateTime from '../../../shared/components/FormattedDateTime'
import { store as authStore } from '../../../auth'
import {
  convertDateToUserLocal,
  convertDateToSystemLocal
} from '../../../shared/DateConverter'

import VersionNote from './VersionNote'
import VersionNoteForm from './VersionNoteForm'

@observer
export default
class VersionListItem
  extends Component {

  static propTypes = {
    version: PropTypes.object.isRequired,
    selectedVersionNumber: PropTypes.number.isRequired,
    articleBaseLink: PropTypes.string,
    versionListLength: PropTypes.number,
    isVersionLoading: PropTypes.bool,
    context: PropTypes.object
  }


  constructor(props) {
    super(props)

    this.state = {
      noteFormOpen: false
    }
  }

  @autobind
  handleToggleNoteForm() {
    this.setState({
      noteFormOpen: !this.state.noteFormOpen,
      isNoteError: false
    })
  }

  @autobind
  handleCreateNote({ target }) {

    const newNote = {
      createdAt: convertDateToSystemLocal(new Date(), { simplified: true }),
      createdBy: authStore.user.id,
      text: target.value
    }


    this.setState({
      noteFormOpen: false
    })

    return this.props.version.addNote(newNote)
      .catch((err) => {
        console.log(err)
        this.setState({
          noteFormOpen: true,
          isNoteError: true
        })
      })
  }

  @autobind
  handleVersionClick() {
    const { version, context: contextStore } = this.props

    // This function is only relevant for versionComparing.
    // Otherwise the Link component will lead to a different logic
    if (contextStore.type !== 'version') {
      return
    }

    // either use version or the actual article
    const compareTo = version.isVersion ? version.id : version.versionNumber + 1
    // set to the same value again or it will be lost
    const compareFrom = contextStore.target.compareFrom

    // Do not change context if the compared versions would be the same
    if (compareTo === compareFrom) {
      return
    }

    contextStore.unlockContext()

    contextStore.createFromElement({
      type: 'version',
      compareFrom,
      compareTo
    })

    // lock the context so it won't change when clicking somewhere
    contextStore.lockContext()
  }

  render() {

    const {
      version,
      selectedVersionNumber,
      articleBaseLink,
      versionListLength,
      isVersionLoading,
      context
    } = this.props

    const size = `${version.editDistance}px`

    let versionType = null
    const isDraft = versionListLength >= 0
    const displayVersionNumber = isDraft
      ? version.versionNumber + 1
      : version.instanceVersion
    const versionNumber = isDraft ? version.instanceVersion : version.id

    if (isDraft) {
      versionType = (<div className="grid-block version-type">
        <FormattedMessage id="article.version.draft-version" />
      </div>)
    }

    const versionBaseLink = `${articleBaseLink}/version/${versionNumber}`

    // Mark the selected/loaded version, special case for selected is active edit version
    const isSelected = (selectedVersionNumber === displayVersionNumber)
      || (selectedVersionNumber === -1 && isDraft)

    let isCompared = false

    if (context.target && context.type === 'version') {
      const { compareTo, compareFrom } = context.target

      isCompared = compareTo === version.instanceVersion
        || compareFrom === version.instanceVersion
        || (isDraft && compareTo === version.versionNumber + 1)
        || (isDraft && compareFrom === version.versionNumber + 1)
    }

    return (
      <li className="version-item" onClick={this.handleVersionClick}>
        <Link
          to={isVersionLoading || context.type === 'version' ? null : `${versionBaseLink}`}
          key={version.versionNumber}
          className={
            classNames('version-item-link', {
              selected: isSelected,
              compared: isCompared
            })
          }
        >
          <div className="grid-block ">

            <div className="version-difference-circle-container">
              <div className={
                classNames('version-difference-circle', {
                  selected: isSelected,
                  compared: isCompared
                })
              }
              // eslint-disable-next-line indent
                style={{
                width: size,
                height: size,
                lineHeight: size
              }}
              >
                {displayVersionNumber}
              </div>

            </div>


            <div className="vertical grid-block version-info">
              <div className="grid-block version-username">
                {isDraft
                  ? getUserNameById(authStore.user.id)
                  : getUserNameById(version.updatedBy)
                }
              </div>
              {versionType}
              <div className="grid-block version-date">
                { // eslint-disable-next-line
                  version.createdAt
                    ? isDraft
                      ? <FormattedDateTime value={convertDateToUserLocal(version.updatedAt)} />
                      : <FormattedDateTime value={convertDateToUserLocal(version.createdAt)} />

                    : <FormattedMessage id="article.version.missing-date" />
                }
              </div>
            </div>
          </div>
        </Link>

        {
          isSelected && !isDraft
            ? (<a
              className="version-toggle-note-form"
              onClick={this.handleToggleNoteForm}
            ><FormattedMessage id="article.version.note.add-note" />
            </a>)
            : null
        }

        <div className="grid-block vertical version-note-list">
          {
            isSelected && this.state.noteFormOpen
              ? <VersionNoteForm
                onCreateNote={this.handleCreateNote}
              />
              : null
          }
          {
            this.state.isNoteError
              ? <FormattedMessage id="article.version.note.error" />
              : null
          }
          {(version.notes || []).reverse().map(note => (
            <VersionNote
              key={`${note.createdAt}-${note.createdBy}`}
              note={note}
            />
          ))}
        </div>
      </li>
    )
  }

}
