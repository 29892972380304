import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { autobind } from 'core-decorators'
import { confirmable } from 'react-confirm'
import classNames from 'classnames'
import { formatMessage, FormattedMessage } from '../../../translations'
import { store as pageStore } from '../..'
import { store as projectStore } from '../../../project'
import { articleStore } from '../../../article/reducers'
import { Dialog } from '../../../shared/components/Dialog'
import { deepGet } from '../../../shared/obj'
import SelectArticleItem from '../SelectArticleItem'
import ArticleListContainer from '../../containers/List'
import GenevaButton from '../../../ui/components/GenevaButton'

const css = typeof window === 'undefined' ? {} : require('../../styles.scss')


// This is a special case of selection management. Normally this should happen
// in the parent component and it should only pass the selected state to
// the actual items. In this case all rendering is done by the InfiniteList
// further down the tree. That InfiniteList keeps a list of rendered items that
// are only rendered once the infinite list changes. Thuse, changing the
// selection from the outside does not do anything right now. Therefor we
// connect the item directly to the selection manager via mobx.
// In future: just simply pass the selection property through to the infinite
// list and manage selecting there
const connectArticleToStateManager = (Article, stateManager) => {

  const StateManagerConnectedSelectArticleItem = observer((props) => {
    const selected = stateManager.selected
      && stateManager.selected.id * 1 === props.article.id * 1
    return <Article {...props} selected={selected} />
  })

  return StateManagerConnectedSelectArticleItem

}

const DialogFooter = observer(({ stateManager, onCancel, onCommit, onCopy, previouslyAdded }) => {
  const sameLanguage = stateManager.selected
    && (stateManager.selected.createdIso === projectStore.current.language)

  return (<div className={classNames(css.selectArticleDialogFooter)}>
    <div>
      <GenevaButton type="button"
        className="small button"
        disabled={!stateManager.selected || !sameLanguage}
        onClick={onCopy}
      >
        <FormattedMessage id="select-article-dialog.copy" />
      </GenevaButton>
    </div>
    <div>
      <GenevaButton type="button"
        className="small button"
        onClick={onCancel}
      >
        <FormattedMessage id="select-article-dialog.cancel" />
      </GenevaButton>
      <GenevaButton type="submit"
        className="small primary button"
        disabled={!stateManager.selected || previouslyAdded}
        onClick={onCommit}
      >
        <FormattedMessage id="select-article-dialog.confirm" />
      </GenevaButton>
    </div>
  </div>)
})

@observer
@confirmable
class SelectArticle
  extends Component {

  /* eslint-disable react/sort-comp */
  @observable selected = null
  /* eslint-enable react/sort-comp */

  static propTypes = {
    show: PropTypes.bool,            // indicates if the dialog is shown or not.
    proceed: PropTypes.func,         // call to close the dialog with promise resolved.
    cancel: PropTypes.func,          // call to close the dialog with promise rejected.
    dismiss: PropTypes.func,         // call to only close the dialog.
    options: PropTypes.shape({
      pageId: PropTypes.number.isRequired,
      article: PropTypes.object,
      widgetItems: PropTypes.object
    }).isRequired,
  }

  constructor(props) {

    super(props)

    const page = pageStore.getById(props.options.pageId)

    // in widget which can add articles (slider) a different logic is used
    // therefore the widgetItems from props shall be used
    // otherwise read the content article of the widget to prevent adding them again
    let widgetItems = props.options.widgetItems
    if (!widgetItems) {
      widgetItems = []
      page.items.forEach((item) => {
        if (item.type === 'widget') {
          const widgetContent = item.content || item.data.content
          widgetContent.forEach(content => widgetItems.push(content))
        }
      })
    }

    const previouslyAddedArticles = page.items.map((item) => {
      return item.type === 'article' ? item.id : undefined
    }).filter(n => n)

    if (widgetItems && widgetItems.length) {
      previouslyAddedArticles.push(...widgetItems.map(item => item.id))
    }

    this.state = {
      filter: '',
      elements: [],
      sorting: '',
      previouslyAddedArticles
    }

  }


  @autobind
  getFocus() {
    return this.focus
  }

  @autobind
  handleRef(el) {
    if (el !== null) {
      this.focus = el
    }
  }

  loadList(opts) {
    if (opts.force || !deepGet(this, 'props.article.collection.length')) {
      if (articleStore.canLoad()) {
        articleStore.load(opts)
      }
    }
  }

  @autobind
  handleSelect({ target }) {
    this.selected = target
    if (this.state.previouslyAddedArticles) {
      this.setState({
        previouslyAdded: (this.state.previouslyAddedArticles.indexOf(this.selected.id) !== -1)
      })
    }
    // if (this.state.selectedArticle === target) {
    //   this.setState({
    //     selectedArticle: null
    //   })
    //   this.props.options.article.selected = {}
    // }
    // else {
    //   this.setState({
    //     selectedArticle: target
    //   })
    //   this.props.options.article.selected = target
    // }
  }

  @autobind
  handleChange(e) {
    this.setState({
      filter: e.target.value.trim()
    })
  }

  @autobind
  handleCommit(e) {
    if (e && e.preventDefault) {
      e.preventDefault()
    }

    const template = global.GENEVA_CONFIG.templates
      .find(templ => templ.id === this.selected.templateId)

    return this.props.proceed({
      type: 'article',
      article: this.selected,
      template,
      action: 'insertArticle'
    })
  }

  @autobind
  handleCopy(e) {
    if (e && e.preventDefault) {
      e.preventDefault()
    }
    const page = pageStore.getById(this.props.options.pageId)
    const projectId = page ? page.projectId : projectStore.current.id

    return articleStore.copyArticle(
      this.selected,
      { projectId }
    ).then((result) => {
      this.props.proceed({
        type: 'article',
        article: result,
        action: 'insertArticle'
      })
    })
  }

  @autobind
  handleCancel(e) {
    if (e && e.preventDefault) {
      e.preventDefault()
    }
    return this.props.dismiss()
  }


  @autobind
  renderFooter() {
    return (<DialogFooter
      onCancel={this.handleCancel}
      onCommit={this.handleCommit}
      onCopy={this.handleCopy}
      stateManager={this}
      previouslyAdded={this.state.previouslyAdded}
    />)
  }

  @autobind
  renderItem(itemData) {

    const StateManagerConnectedSelectArticleItem = connectArticleToStateManager(
      SelectArticleItem,
      this
    )

    return (<StateManagerConnectedSelectArticleItem
      key={itemData.id}
      article={itemData}
      onSelect={this.handleSelect}
      onDoubleClick={this.handleCommit}
      previouslyAddedArticles={this.state.previouslyAddedArticles}
      project={projectStore.current}
    />)
  }

  render() {
    // Make sure this is only the store
    const {
      show,
      options: { article, ui, allowedArticleTemplates },
    } = this.props

    return (
      <Dialog
        className={css.selectArticleDialog}
        overlayClassName={css.tableDialogOverlay}
        isOpen={show}
        onAfterOpen={this.handleAfterOpen}
        onRequestClose={this.handleReuqestClose}
        renderFooter={this.renderFooter}
        title={formatMessage({ id: 'select-article-dialog.title' })}
        onCancel={this.hancleCancel}
        onCommit={this.handleCommit}
        focusEl={this.getFocus}
        id="existingArticleDialog"
      >
        <ArticleListContainer
          layout="list"
          listContainerHeight={440}
          renderItem={this.renderItem}
          article={article}
          ui={ui}
          allowedArticleTemplates={allowedArticleTemplates}
          handleRef={this.handleRef}
          project={projectStore.current}
        />
      </Dialog>
    )
  }
}

export default SelectArticle
