import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import { autobind } from 'core-decorators'
import { observer } from 'mobx-react'
import { store as contextStore } from '../../../context'

import { filter } from '../../../shared/obj'
import {
  getEventHandlerFromType,
  delegateEventsFromProps,
} from '../../../shared/utils/events'
import getTemplateConnectionData from '../../../shared/template/getTemplateConnectionData'
import wrapInErrorHandler from '../../../shared/template/wrapInErrorHandler'

import TemplatePreview from './Components/TemplatePreview'

export default function connectTemplateToEnv(TargetTemplate, parentProps) {
  wrapInErrorHandler(TargetTemplate, parentProps)

  @observer
  class PMEnvConnectedWidgetTemplate extends Component {
    static propTypes = {
      env: PropTypes.object,
      keyValue: PropTypes.object,
      content: PropTypes.object,
      onFocus: PropTypes.func,
      item: PropTypes.shape({
        type: PropTypes.string,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    };

    @autobind
    handleEvent({ type }) {
      const eventName = getEventHandlerFromType(type)

      // special event case for article sorting
      // click events would cause onBlur and deselect of an article
      // since dragevents need click events preventDefault would not work in this case
      // for more information see page/components/ToolsPanel.js
      if (eventName === 'onBlur' && contextStore.preventBlur) {
        contextStore.preventBlur = false
      }
      else if (this.props[eventName]) {
        this.props[eventName]({
          target: {
            type: this.props.item.type,
            id: this.props.item.id,
          },
        })
      }
    }

    renderEmptyItem() {
      const { template } = parentProps

      return (
        <TemplatePreview shortcut={template.shortcut} vendor={template.vendor}>
          {/*
          <FormattedMessage
            id="template.empty"
            values={{
              name: this.props.placeholder.article.name
            }}
          />
        */}
        </TemplatePreview>
      )
    }

    render() {
      const divProps = filter(this.props, ['className', 'tabIndex', 'title'])

      const eventHandlers = delegateEventsFromProps(
        this.props,
        this.handleEvent
      )

      return (
        <div
          ref={ref => (this.templateRef = ref)}
          {...divProps}
          {...eventHandlers}
        >
          <TargetTemplate
            {...getTemplateConnectionData(
              'pm',
              'widget',
              parentProps,
              this.props
            )}
          />
        </div>
      )
    }
  }

  return PMEnvConnectedWidgetTemplate
}
