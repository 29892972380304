import { store as uiStore } from '../../ui'
import { deepGet, deepSet } from '../obj'

/**
 * Allows easy (read and write) access to deeply nested i18n properties.
 * Takes an object and a property keypath to access the deep property. Each
 * occurence of `i18n` within this path will be replaced with `i18n.<lang>`.
 * @param {Object} obj - The object to extract the property at `keypath` from.
 * @param {String} keypath - The keypath to follow. It has to be in the shape
 * of `root.level1.level2`. Any occurence of `i18n` within this path will become
 * `i18n.<lang>`. If there is no `i18n` in the whole keypath, the keypath will
 * become `i18n.<keypath>`.
 * @param {String|null} lang - The language to use. Defaults to the current
 * contentLanguage.
 * @param {*} value - A value to be set as the new i18n-object
 * value.
 *
 * @returns {Object} - If the value parameter was set, the `obj` that was
 * handed in will be returned. Otherwise the value at given `keypath` will be
 * returned.
 */
export default function i18n(obj, keypath, lang = null, value = undefined) {
  const method = value !== undefined ? deepSet : deepGet

  lang = lang || uiStore.contentLanguage

  keypath = keypath.indexOf('i18n') < 0 ? `i18n.${keypath}` : keypath

  // creates the final keypath that might look something like
  // `this.is.the.i18n.de-DE.keypath` or just `i18n.de-DE.keypath`
  keypath = keypath.replace('i18n', `i18n.${lang}`).replace('..', '.')

  // The new api uses the `translations` object for internationalization.
  // To following section copes with that.
  const keypathParts = keypath.split('i18n.')
  const subObj
    = keypathParts[0].length > 1
      ? deepGet(obj, keypathParts[0].replace(/\.$/, ''))
      : obj

  if (
    subObj
    && 'translations' in subObj
    && subObj.translations.locale === lang
  ) {
    obj = subObj.translations
    keypath = keypathParts[1].replace(`${lang}.`, '')
  }

  // get or set the value
  return method(obj, keypath, value)
}

export function getI18nLangFromObject(obj, index = 0) {
  if (obj.i18n) {
    const keys = Object.keys(obj.i18n)
    if (keys && index in keys) {
      return keys[index]
    }
  }
  return uiStore.contentLanguage
}
