import { createConfirmation } from 'react-confirm'
import UploadDialog from '../components/Dialog/UploadDialog'

const uploadDialog = createConfirmation(UploadDialog)


export default function (confirmation, options = {}) {
  if (typeof confirmation !== 'string') {
    options = confirmation
    confirmation = ''
  }
  return uploadDialog({ confirmation, options })
}
