import ContentRenderer from '../../../pageTemplate/components/ContentRenderer'
import DependencyCollector from '../../../shared/template/DependencyCollector'

// Template components

export default
class PageTemplateDependencyCollector
  extends DependencyCollector {


  collect() {

    return {

      ...super.collect(),

      ContentRenderer: this.connectors
        .connectContentRendererToContext(
          ContentRenderer, {
            env: this.ENV,
            content: this.props.page.current.templateData,
            items: this.props.page.current.items,
            page: this.props.page.current,
          },
          this.contextStore
        )
    }


  }
}
