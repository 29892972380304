import { observable, computed, action, transaction } from 'mobx'

import config from '../config'

import Model from '../shared/model'
import { Store } from '../shared/store'
import { store as uiStore } from '../ui'

import * as TemplateLoader from '../shared/template/loader'

class Template extends Model {
  // Put all template properties here.
  @observable name = '';

  @observable module = null;

  @observable shortcut = null;

  @observable templateType = 'page';

  @computed get asJSON() {
    return {
      id: this.id,
      name: this.name,
    }
  }

  resetModule() {
    transaction(() => {
      this.shortcut = null
      this.module = null
      this.moduleFile = null
    })
  }
}

class PageTemplateStore extends Store {
  // Put all template list properties here. This regargds also state properties.
  @observable collection = [];

  @observable current = {};

  @observable alwaysReloadStyleSheets = false; // !config.isProduction || false

  @observable loading = false;

  // Put all properties that are not to be observed here:

  constructor(opts = {}) {
    super(opts)

    this.Model = Template
  }

  load(...args) {
    // TODO properly load template
    let template = this.getById(args[0])

    if (!template || !template.shortcut) {
      // eslint-disable-next-line max-len
      log.todo(
        'Missing page template with id %d in pagePageTemplateStore: %o',
        args[0],
        this.collection
      )
      template = this.createModel({
        id: args[0],
        shortcut: 'startpage',
      })
      this.collection.push(template)
    }

    this.setCurrent(template)
  }

  openTemplateModuleByShortcut(shortcut) {
    const templateLoader = TemplateLoader.getInstance(
      this.collection,
      uiStore.language,
      config.pageTemplateBasePath
    )

    templateLoader.loadTemplateByShortcut(shortcut, config)
  }

  @action
  actionResetModule(id) {
    const template = id !== undefined ? this.getById(id) : this.current

    if (template && template.shortcut && template.resetModule) {
      const templateLoader = TemplateLoader.getInstance(
        this.collection,
        uiStore.language,
        config.pageTemplateBasePath
      )

      templateLoader.removeTemplateByShortcut(template.shortcut)

      template.resetModule()
    }
  }
}

export const templateStore = new PageTemplateStore()
