import * as PropTypes from 'prop-types'
import React from 'react'

import TransformGhostPageCommand from '../commands/transform-ghost-page'

import createParentPropsInjector from '../../shared/utils/create-parent-props-injector'
import commandConnector from '../../shared/lib/command'

import GhostDisplayer from '../components/GhostDisplay/GhostDisplayer.js'

export default function connectGhostDisplayerToCommands(parentProps) {
  const injectParentProps = createParentPropsInjector(parentProps)

  // Connector function
  function ConnectGhostDisplayerToCommandsConnector({ ...rest }) {
    return <GhostDisplayer {...rest} />
  }

  // Component props
  ConnectGhostDisplayerToCommandsConnector.propTypes = {
    ui: PropTypes.object,
    pageStore: PropTypes.object,
    projectStore: PropTypes.object,
  }

  // Inject commands
  return commandConnector({
    TransformGhostPageCommand,
  })(injectParentProps(ConnectGhostDisplayerToCommandsConnector))
}
