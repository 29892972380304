import { closest } from './dom-helpers'
import BaseCommand from './BaseCommand'

export
class FontStyleCommandImpl
extends BaseCommand {


  static defaultData = {
    className: ''
  }

  static defaultOpts = {
    tagName: 'span',
    className: ''
  }

  static commandName = 'applyFontStyle'

  get fontStyleSelector () {
    return `${this.opts.tagName}.`
  }

  nodeGetClassName (node) {
    const classNode = this.isTextNode(node) ? node.parentNode : node
    this.className = classNode.className
    return classNode.className
  }

  getInitialData() {

    const selection = new this.scribe.api.Selection()

    let className = undefined

    if (
      selection &&
      selection.range &&
      selection.range.commonAncestorContainer
    ) {
      const parent = selection.range.commonAncestorContainer
      className = this.nodeGetClassName(parent)
    }

    return {
      className
    }

  }

  applyFontStyle(className) {

    return new Promise((resolve, reject) => {

      if (this.className) {
        // remove all nodes that are (partially) selected
        this.removePreviousNodes(this.className)
      }

      const selection = new this.scribe.api.Selection()

      if (selection.selection.isCollapsed) {
        // extend selection to complete word
        this.extendSelection(selection, newClassName)
      }

      if (className !== 'none') {
        document.execCommand('fontName', false, className)

        // wait for the dom node to have been inserted
        window.setTimeout(() => {

          const fontStyleNodes = this.scribe.el.querySelectorAll('font[face]')

          if (fontStyleNodes.length) {

            // replace the node that was just insereted with the one defined in
            // the settings
            Array.prototype.forEach.call(fontStyleNodes, (font) => {

              const fontName = font.face
              const node = this.createCommandElement(fontName)

              while (font.firstChild) {
                node.appendChild(font.firstChild)
              }

              font.parentNode.insertBefore(node, font)
              font.parentNode.removeChild(font)
            })

          }
          else {

            const newSelection = new this.scribe.api.Selection()
            const node = this.createCommandElement(className)

            node.appendChild(this.createInvisibleCharTextNode())
            selection.range.insertNode(node)

            newSelection.removeMarkers()
            const range = document.createRange()
            range.selectNodeContents(node)
            selection.updateFromRange(range)
            selection.placeMarkers()
            selection.selectMarkers(true)

          }


          resolve()
        }, 1)
      }

      else {
        resolve()
      }

    })

  }

  createCommandElement(className) {
    const node = document.createElement(this.opts.tagName)
    node.className = className
    return node
  }

  extendSelection(selection, newClassName = '') {
    const container = selection.range.commonAncestorContainer
    const fontStyleNode = closest(container, this.fontStyleSelector + newClassName)
    if (fontStyleNode) {
      const range = document.createRange()
      range.selectNode(fontStyleNode)
      selection.updateFromRange(range)
      selection.removeMarkers()
      selection.placeMarkers()
      selection.selectMarkers(true)
    }
  }

  /**
   * @private
   */
  removePreviousNodes(newClassName) {
    let selection = new this.scribe.api.Selection()
    let commonAncestorContainer = selection.range.commonAncestorContainer

    let containedStyledNodes = []

    // in cases where the range is collapsed, look fo the closest
    // ancestor fontStyle node and select all it's contents
    if (selection.range.collapsed) {

      const parentFontStyle = closest(
        commonAncestorContainer,
        this.fontStyleSelector + newClassName
      )

      if (parentFontStyle) {
        selection.removeMarkers()
        parentFontStyle.insertBefore(
          this.createMarker(),
          parentFontStyle.firstChild
        )
        parentFontStyle.appendChild(this.createMarker())
        selection.selectMarkers(true)
        selection = new this.scribe.api.Selection()
        commonAncestorContainer = parentFontStyle
      }

    }

    let child

    // if the whole selection is within a text node, then that
    // node - per definition - cannot have other children that may need
    // un-styling, so just go up one level to the parent node and work from
    // there
    if (commonAncestorContainer.nodeType === Node.TEXT_NODE) {
      commonAncestorContainer = commonAncestorContainer.parentNode
    }

    const containingStyledNode = closest(
      commonAncestorContainer,
      this.fontStyleSelector + newClassName
    )

    if (containingStyledNode) {
      containedStyledNodes.unshift(containingStyledNode)
    }

    containedStyledNodes.forEach(node => {
      while (child = node.firstChild) {
        node.parentNode.insertBefore(child, node)
      }
      node.parentNode.removeChild(node)
    })

    selection.selectMarkers(true)

  }

  /**
   * @private
   */
  selectNode(node) {

    // Select the created link
    const range = document.createRange()
    const selection = new this.scribe.api.Selection()

    range.setStartBefore(node)
    range.setEndAfter(node)

    selection.range = range
    selection.selection.removeAllRanges()
    selection.selection.addRange(range)
    selection.placeMarkers()
    selection.selectMarkers()

  }
}

export default
function scribePluginFontStyleCommandFactory(styleOpts = {}) {

  return function scribePluginFontStyleCommand(scribe) {

    const fontStyleCommand = new scribe.api.CommandPatch('fontStyle')

    fontStyleCommand.execute = function executeFontStyleCommand() {

      const impl = new FontStyleCommandImpl(scribe, this)

      scribe.transactionManager.runAsync((complete) => {

        impl.cacheSelection()

        styleOpts.showFontStylePrompt(impl.initialData)
        .then((result) => {

          impl.restoreSelection()
          .then(() => {
            if (result && result.status === 'committed') {
              return impl.executeCommand(
                FontStyleCommandImpl.commandName,
                result.className
              )
            }
          })
          .then(() => impl.finishCommand())
          .then(complete)

        })

      })
    }

    fontStyleCommand.queryState = function queryStateFontStyleCommand() {

      const impl = new FontStyleCommandImpl(scribe, this)

      const cb = (node) => {
        return //node.nodeName === 'SPAN' &&
          impl.nodeGetClassName(node) !== ''
      }

      const selection = new scribe.api.Selection()
      let classNamedNode
      let className = ''

      if (selection.selection.isCollapsed) {
        classNamedNode = selection.range.commonAncestorContainer
        // if (!cb(classNamedNode)) {
        //   classNamedNode = null
        // }
      }
      else {
        // getContaining delivers only if the selection is fully contained.
        // Partial inclusions are ignored.
        classNamedNode = selection.range.startContainer //selection.getContaining(cb)
      }

      if (classNamedNode) {
        className = impl.nodeGetClassName(classNamedNode)
      }

      return {
        active : className !== '',
        value : className,
        type : 'className'
      }

    }


    fontStyleCommand.queryEnabled = function queryEnableFontStyleCommand () {
      // We could only enable the command if there is some text in the
      // selection...
      // var selection = new scribe.api.Selection()
      // const enabled = !selection.selection.isCollapsed
      // return enabled
      //
      // but especially when removing a style it's much easier to do this
      // without the need for a whole selection
      return true
    }

    scribe.commands.fontStyle = fontStyleCommand
  }

}
