import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { autobind } from 'core-decorators'
import { confirmable } from 'react-confirm'
import { formatMessage, FormattedMessage } from '../../../translations'

import { Dialog } from '../../../shared/components/Dialog'
import GenevaButton from '../../../ui/components/GenevaButton'

const css = /* typeof window === 'undefined' ? {} : */require('../../styles.scss')


@confirmable
class CenshareTranslationWarning extends Component {

  static propTypes = {
    show: PropTypes.bool,            // indicates if the dialog is shown or not.
    proceed: PropTypes.func,         // call to close the dialog with promise resolved.
    cancel: PropTypes.func,          // call to close the dialog with promise rejected.
    dismiss: PropTypes.func,         // call to only close the dialog.
    confirmation: PropTypes.string,
    options: PropTypes.shape({
      errorCount: PropTypes.number.isRequired,
      languageIso: PropTypes.string.isRequired
    })
  }

  @autobind
  getFocus() {
    return this.focus
  }

  @autobind
  handleRef(el) {
    if (el !== null) {
      this.focus = el
    }
  }

  @autobind
  handleClose() {
    this.props.dismiss()
  }

  @autobind
  handleConfirm() {
    this.props.proceed()
  }

  @autobind
  renderFooter() {
    return (
      <div className="grid-content text-right">
        <GenevaButton
          type="button"
          className="small button"
          onClick={this.handleClose}
          ref={this.handleRef}
        >
          <FormattedMessage id="simple-dialog.cancel" />
        </GenevaButton>

        <GenevaButton
          type="button"
          className="small primary button"
          onClick={this.handleConfirm}
          ref={this.handleRef}
        >
          <FormattedMessage id="censhare.translation-waring-modal.proceed" />
        </GenevaButton>
      </div>
    )
  }

  render() {
    const { errorCount, languageIso } = this.props.options

    return (
      <Dialog
        className={css.censhareTranslationWarning}
        isOpen={this.props.show}
        renderFooter={this.renderFooter}
        title={formatMessage({ id: 'censhare.translation-waring-modal.title' })}
        focusEl={this.getFocus}
      >
        <div className="form-main">
          <span>
            {errorCount === -1
              ? formatMessage({ id: 'censhare.translation-waring-modal.amount-text-2' }, { iso: languageIso })
              : formatMessage(
                { id: 'censhare.translation-waring-modal.amount-text-1' },
                { count: errorCount, iso: languageIso }
              )
            }
          </span>
          <FormattedMessage id="censhare.translation-waring-modal.confirmation" />
        </div>
      </Dialog>
    )
  }
}

export default CenshareTranslationWarning
