
import PropTypes from 'prop-types'

import React, { Component } from 'react'
import { autorun, observable } from 'mobx'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import { loadStyleSheet } from '../../shared/utils'
import { deepGet } from '../../shared/obj'
import { TemplateLoadingError } from '../../shared/errors'

import * as connectors from '../../pageTemplate/connectors'
import {
  TemplateDependencyCollector,
  TemplateActivator
} from '../loaders/pm'

import PureLoadingScreenDisplayer from '../components/LoadingScreenDisplayer'
import PureNoPageSelectedDisplayer from '../components/NoPageSelectedDisplayer'
import PurePageContentDisplayer from '../components/PageContentDisplayer'
import PurePageToolsDisplayer from '../components/PageToolsDisplayer'

const css = require('../styles.scss')

const LoadingScreenDisplayer = observer(PureLoadingScreenDisplayer)
const NoPageSelectedDisplayer = observer(PureNoPageSelectedDisplayer)
const PageContentDisplayer = observer(PurePageContentDisplayer)
const PageToolsDisplayer = observer(PurePageToolsDisplayer)

class PageDetailsContainer extends Component {

  static propTypes = {
    auth: PropTypes.object.isRequired,
    customLocal: PropTypes.object.isRequired,
    context: PropTypes.object.isRequired,
    page: PropTypes.object.isRequired,
    pageTemplate: PropTypes.object.isRequired,
    project: PropTypes.object.isRequired,
    ui: PropTypes.object.isRequired,
  }

  constructor(props) {

    super(props)

    this.state = {
      template: null,
      templateError: null,
      templateShortcut: null
    }

    this.handler = []

    this.pmTemplateActivator = new TemplateActivator(
      this.ENV,
      connectors,
      {
        reloadStylesheets: false,
        type: 'page'
      }
    )

    // TODO intoduce a shallow object that tracks the setup process of templates
    // this.handler.push(
    //   autorun(() => this.handleTemplateLoad(this.props.pageTemplate.currentState))
    // )

  }

  componentWillMount() {
    if (!this.state) {
      // eslint-disable-next-line react/no-direct-mutation-state
      this.state = {
        template: null,
        templateError: null,
        templateShortcut: null
      }
    }
  }

  componentDidMount() {
    const { page, pageTemplate } = this.props
    this.handler = [

      // Ask the api for it's template
      autorun(() => this.loadTemplate(
        page.hasCurrent && page.current.templateId
      )),

      // once the api responded, load the template file
      autorun(() => this.loadTemplateModule(
        pageTemplate.current && pageTemplate.current.shortcut
      )),

      // once the template file is loaded, validate and render
      autorun(() => {
        const module = pageTemplate.current && pageTemplate.current.module
        if (
          page.hasCurrent
          && page.current.complete
          && page.current.contentTemplatesLoaded
          && module
          && module.getTemplate
        ) {
          this.validateTemplateModule(module)
        }
      })

    ]
  }

  componentWillUnmount() {

    if (this.handler.length) {
      this.handler.forEach(handler => handler())
      this.handler = null
    }

    this.pmTemplateActivator = null

    this.reset()

  }

  @observable ENV = {
    CM: false,
    PM: true,
    PREVIEW: false,
    PUBLISH: false
  }


  reset() {

    this.setState({
      template: null,
      templateError: null,
      templateShortcut: null
    })
    this.currentModule = null
    this.currentlyDisplayedPage = null
    this.props.pageTemplate.setCurrent(null)

  }

  currentTemplateIsCurrentPageTemplate() {

    const { props } = this
    const currentTemplate = deepGet(props, 'page.current.templateId') * 1
    const newTemplate = deepGet(props, 'pageTemplate.current.id') * 1
    log.tmplLoader(
      'Current page\'s template  (%s) is same as loaded pageTemplate (%s): %s',
      currentTemplate, newTemplate, currentTemplate === newTemplate
    )
    return currentTemplate === newTemplate

  }

  loadTemplate(id) {

    // check if the current page is actually part of the current project.
    // if not: fail
    if (!this.props.project.current.id === this.props.page.projectId) {
      id = null
    }

    if (!id || id < 0) {
      this.reset()
      return
    }

    if (this.currentlyDisplayedPage
      && this.currentlyDisplayedPage.templateId !== id) {
      this.reset()
    }

    if (this.props.pageTemplate.current && this.props.pageTemplate.current.module) {
      return
    }

    log.tmplLoader('Loading page template with id %s', id)

    this.props.pageTemplate.load(id)

  }

  loadTemplateModule(shortcut) {

    if (!shortcut || this.state.templateShortcut === shortcut) {
      return
    }

    this.setState({
      templateShortcut: shortcut
    })

    if (!this.currentTemplateIsCurrentPageTemplate()) {
      this.reset()
      return
    }

    this.props.pageTemplate.openTemplateModuleByShortcut(shortcut)

  }


  loadStylesheets(styles) {

    if (!Array.isArray(styles)) {
      styles = [styles]
    }

    styles.forEach((path) => {

      if (/\.s[ac]ss$/.test(path)) {

        global.System.delete(`${path}!`)

        const systemStyle = document.head
          .querySelector(`style[data-url="${path}"]`)

        if (systemStyle) {
          systemStyle.parentNode.removeChild(systemStyle)
        }

        setTimeout(() => {
          import(`${path}!`)
        }, 100)

      }
      else {

        loadStyleSheet(
          `${path}`,
          this.props.pageTemplate.alwaysReloadStyleSheets
        )

      }
    })

  }


  validateTemplateModule(module) {

    const { page } = this.props

    // if there is no module, we cannot be showing any
    // page, so reset the flag
    if (!module) {
      this.currentlyDisplayedPage = null
    }

    log.tmplLoader('Validating template')

    const newPage = page.current
    if (!newPage
        || !newPage.complete
    ) {
      this.reset()
      log.tmplLoader('...aborted [page invalid]')
      return
    }

    if (!module
      || !this.currentTemplateIsCurrentPageTemplate()) {
      this.reset()
      log.tmplLoader('...aborted [template invalid]')
      return
    }

    if (newPage === this.currentlyDisplayedPage) {
      log.tmplLoader('...skipping [page identical]')
      return
    }

    log.tmplLoader('...ok. Loading template...')

    this.currentlyDisplayedPage = page.current
    this.currentModule = module

    this.activateTemplate()

  }

  activateTemplate() {

    const { customLocal, context } = this.props

    return new Promise((resolve) => {

      if (this.currentModule instanceof Error) {
        throw this.currentModule
      }

      const dependencyCollector = new TemplateDependencyCollector({
        props: this.props,
        connectors,
        componentOverrides: {},
        ENV: this.ENV,
        contextStore: context,
      })

      const dependencies = {
        // TODO: remove article specific dependencies, so template can be cached
        // over various articles with same template id
        ...dependencyCollector.collect(),
        ...this.props.pageTemplate.current.dependencies
      }

      const templateComponent = this.currentModule.getTemplate(dependencies)

      return this.pmTemplateActivator
        .activate({
          channelShortcut: this.props.page.current.channelShortcut,
          templateSpec: this.props.pageTemplate.current,
          templateComponent,
          templateModule: this.currentModule,
          dependencies,
          injectTemplateProps: {
            pageStore: this.props.page,
            page: this.props.page.current
          },
          customLocalStore: customLocal
        })
        .then(template => resolve({ template }))

    })
      .catch((error) => {
        const templateError = new TemplateLoadingError(
          'error.template-error',
          this.props.pageTemplate.current.shortcut,
          error
        )
        console.error(templateError)
        return { templateError }
      })
      .then((result) => {

        this.setState({
          template: result.template || null,
          templateError: result.templateError || null
        })

      })

  }

  render() {

    const { page, ui, project, router } = this.props
    const { template } = this.state

    if (!page.hasCurrent) {
      return null
    }

    log.renderer('Page/Details#render: Rendering Details')

    return (<div
      className={classNames('grid-block', css.wmScreen)}
    >

      <NoPageSelectedDisplayer
        page={page}
      />

      <LoadingScreenDisplayer
        page={page}
        template={template}
      />

      <PageContentDisplayer
        page={page}
        project={project}
        template={template}
        ui={ui}
        container={this.container}
      />

      <PageToolsDisplayer
        page={page}
        project={project}
        ui={ui}
        router={router}
      />

    </div>)
  }


}

export default PageDetailsContainer
