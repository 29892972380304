import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { autobind } from 'core-decorators'
import { confirmable } from 'react-confirm'
import { formatMessage, FormattedMessage } from '../../../translations'

import { Dialog } from '../../../shared/components/Dialog'
import ContentLoadingBox from '../../../shared/components/ContentLoadingBox'
import GenevaButton from '../../../ui/components/GenevaButton'

const css = /* typeof window === 'undefined' ? {} : */require('../../styles.scss')


@confirmable
class CenshareImageRefresh extends Component {

  static propTypes = {
    show: PropTypes.bool,            // indicates if the dialog is shown or not.
    proceed: PropTypes.func,         // call to close the dialog with promise resolved.
    cancel: PropTypes.func,          // call to close the dialog with promise rejected.
    dismiss: PropTypes.func,         // call to only close the dialog.
    confirmation: PropTypes.string,
    options: PropTypes.shape({
      updateAction: PropTypes.func.isRequired
    })
  }

  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      displayState: '',
    }

    this.load(true)
  }

  load(preview) {
    const { updateAction } = this.props.options
    return updateAction({ preview })
      .then((res) => {

        const newState = {
          isLoading: false
        }

        if (preview) {
          newState.displayState = res.changedAssets.length > 0 ? 'new-content' : 'no-content'
        }

        this.setState(newState)
      })
      .catch((err) => {

        const newState = {
          isLoading: false
        }

        switch (err.message) {
          case 'Censhare articles were not found.':
            newState.displayState = 'no-article'
            newState.assetId = err.assetId
            break
          case 'There was no censhare iso found.':
            newState.displayState = 'no-iso'
            break
          case 'The connection to censhare was not possible.':
            newState.displayState = 'no-connection'
            break
          default:
            break
        }

        this.setState(newState)
      })
  }

  @autobind
  updateContent() {
    // Call without preview to trigger changes
    this.load(false)
      .then(() => {
        this.props.proceed()
      })
  }

  @autobind
  getFocus() {
    return this.focus
  }

  @autobind
  handleRef(el) {
    if (el !== null) {
      this.focus = el
    }
  }

  @autobind
  handleClose() {
    this.props.dismiss()
  }

  getDialogTitle() {

    const { isLoading, displayState } = this.state
    if (isLoading) {
      return 'censhare.refresh-modal.image-title'
    }

    switch (displayState) {
      case 'new-content':
        return 'censhare.refresh-modal.content-title'
      case 'no-content':
        return 'censhare.refresh-modal.no-content-title'
      case 'no-connection':
        return 'censhare.refresh-modal.no-connection-title'
      case 'no-article':
        return 'censhare.refresh-modal.no-article-title'
      case 'no-iso':
        return 'censhare.refresh-modal.no-iso-title'
      default:
        return ''
    }
  }

  renderMessage() {
    const { displayState, assetId } = this.state
    switch (displayState) {
      case 'new-content':
        return <FormattedMessage id="censhare.refresh-modal.content-info" values={{ list: 'todo' }} />
      case 'no-content':
        return <FormattedMessage id="censhare.refresh-modal.no-content-info" />
      case 'no-connection':
        return <FormattedMessage id="censhare.refresh-modal.no-connection-info" />
      case 'no-article':
        return <FormattedMessage id="censhare.refresh-modal.no-article-info" values={{ id: assetId }} />
      case 'no-iso':
        return <FormattedMessage id="censhare.refresh-modal.no-iso-info" />
      default:
        return null
    }
  }

  renderLoader() {
    return (<div>
      <FormattedMessage id="censhare.refresh-modal.image-info" />
      <ContentLoadingBox
        className={css.contentLoadingBox}
        spinner
        message={{
          id: 'placeholder-empty'
        }}
      />
    </div>)
  }

  @autobind
  renderFooter() {
    const { displayState, isLoading } = this.state

    if (isLoading) {
      return (
        <div className="grid-content text-right">
          <GenevaButton
            type="button"
            className="small button"
            onClick={this.handleClose}
            ref={this.handleRef}
          >
            <FormattedMessage id="simple-dialog.cancel" />
          </GenevaButton>
        </div>
      )
    }

    if (displayState === 'new-content') {
      return (
        <div className="grid-content text-right">
          <GenevaButton
            type="button"
            className="small button"
            onClick={this.handleClose}
            ref={this.handleRef}
          >
            <FormattedMessage id="simple-dialog.cancel" />
          </GenevaButton>

          <GenevaButton
            type="button"
            className="small primary button"
            onClick={this.updateContent}
            ref={this.handleRef}
          >
            <FormattedMessage id="simple-dialog.apply" />
          </GenevaButton>
        </div>
      )
    }

    return (
      <div className="grid-content text-right">
        <GenevaButton
          type="button"
          className="small button"
          onClick={this.handleClose}
          ref={this.handleRef}
        >
          <FormattedMessage id="simple-dialog.close" />
        </GenevaButton>
      </div>
    )
  }

  render() {
    const { isLoading } = this.state

    return (
      <Dialog
        className={css.censhareImageRefresh}
        isOpen={this.props.show}
        renderFooter={this.renderFooter}
        title={formatMessage({ id: this.getDialogTitle() })}
        focusEl={this.getFocus}
      >
        <div className={css.formMain}>
          {isLoading
            ? this.renderLoader()
            : <div className={css.updateMessages}>{this.renderMessage()}</div>}
        </div>
      </Dialog>
    )
  }
}

export default CenshareImageRefresh
