import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { observable, action } from 'mobx'
import classNames from 'classnames'
import { autobind } from 'core-decorators'
import PerfectScrollbar from 'perfect-scrollbar'
import { formatMessage, FormattedMessage } from '../../../translations'
import { contentLangs } from '../../../config'

import { dispatcher } from '../../../shared/lib/command'
import GenevaButton from '../../../ui/components/GenevaButton'

import { widgetTemplateStore } from '../../../widgetTemplate/reducers'
import { store as articleStore } from '../../../article'
import { widgetStore } from '../../../widget/reducers'
import { templateStore as articleTemplateStore } from '../../../template/reducers'
import { testClass } from '../../../shared/utils'
import { deepGet } from '../../../shared/obj'

import { censhareTranslationWarning } from '../../../censhare/containers/dialogs'

import GhostItem from './GhostItem'
import GhostHeader from './GhostHeader'
import LoadingScreenOverlay from '../LoadingScreenOverlay'
import connectStatusToComponent from '../../../shared/components/StatusList/connector'

const css = /* typeof window === 'undefined' ? {} : */require('../../styles.scss')

const templateStores = {
  article: articleTemplateStore,
  widget: widgetTemplateStore
}

const ghostOptions = ['keep', 'copy', 'translate', 'remove']

@connectStatusToComponent
@dispatcher
@observer
export default
class GhostDisplayer extends Component {

  static propTypes = {
    ui: PropTypes.object,
    pageStore: PropTypes.object,
    projectStore: PropTypes.object,
    onUpdateStatus: PropTypes.func,
  }

  constructor(props) {
    super(props)
    this.page = props.pageStore.current
    this.pageItems = this.page.items
    this.project = props.projectStore.current

    // Build list from pagegrid items
    this.page.traverseGrid((gr, gb, i, itemAtPos) => {
      this.ghostArticleList.push(itemAtPos)
    })

    // Enhance with copy info
    this.ghostArticleList = this.ghostArticleList.map((item) => {
      return {
        id: (item.id * 1), // some ids come from backend as strings
        value: this.getValidActions(item)[0],
        copyable: true, // (item.data.createdIso !== this.props.ui.contentLanguage),
        type: item.type
      }
    })

    const contentLangKey = Object.keys(contentLangs).find(key => key === deepGet(this, 'props.projectStore.current.language'))
    const languageIso = contentLangs[contentLangKey].censhareIso

    this.state = {
      publicationNotAllowed: true,
      hideNavigation: false,
      lockCommit: false,
      languageIso
    }

    if (this.project.hasCenshare) {
      if (!ghostOptions.find(el => el === 'censhare')) {
        ghostOptions.push('censhare')
      }
    }

    this.psPadding = null
    this.psContainer = null
  }


  componentDidMount() {
    if (this.ghostArticleList.length) {
      this.psPadding = new PerfectScrollbar('#ghost-dialog-padding', {
        suppressScrollX: true
      })
    }
    this.psContainer = new PerfectScrollbar('#ghost-dialog-container', {
      suppressScrollX: true
    })
  }

  @observable ghostArticleList = []

  @observable batchChoiceEnabled = false

  @observable ghostSubHeaderChoice = ghostOptions[0]

  // Returns the valid copy action for a pageItem
  getValidActions(item) {

    const pageItem = this.pageItems.find(contentItem => contentItem.id === item.id * 1)
    const contentItemTemplate = templateStores[pageItem.type].getById(pageItem.templateId)
    let retVal = ghostOptions.slice()

    if (contentItemTemplate.config && contentItemTemplate.config.disabledCopyActions) {

      retVal = retVal.filter((actionType) => {

        return !contentItemTemplate.config.disabledCopyActions.find(e => e === actionType)
      })
    }

    return retVal
  }

  getCenshareElementCounter(item, actions) {
    if (!this.project.hasCenshare) {
      // Bail out if project has no censhare
      return 0
    }

    const article = articleStore.getById(item.id * 1)

    let censhareElementCounter = 0

    if (item.type === 'widget') {
      const widget = widgetStore.getById(item.id * 1)
      let foundCenshare = false
      widget.items.forEach((widgetArticle) => {
        const fullWidgetAricle = articleStore.getById(widgetArticle.id * 1)
        Object.keys(fullWidgetAricle.phAccessor.placeholderMap).forEach((ph) => {
          const source = fullWidgetAricle.phAccessor.placeholderMap[ph].getSource()

          if (source) {
            foundCenshare = true
          }
        })
      })
      if (foundCenshare) {
        censhareElementCounter += 1
      }
    }

    else {
      Object.keys(article.phAccessor.placeholderMap).forEach((ph) => {
        const source = article.phAccessor.placeholderMap[ph].getSource()

        if (source) {
          censhareElementCounter += 1
        }
      })
    }

    if (censhareElementCounter === 0) {
      const index = actions.indexOf('censhare')
      if (index !== -1) {
        actions.splice(index, 1)
      }
    }

    return censhareElementCounter
  }

  @autobind
  @action
  updateSubHeaderChoice(value) {
    this.ghostSubHeaderChoice = value
  }

  @autobind
  @action
  updateArticleList(id, value, copyable) {

    this.ghostArticleList.map((item) => {
      if (item.id === (id * 1)) {
        item.value = value
        item.copyable = copyable || item.copyable
      }
      return item
    })
  }

  @autobind
  switchBatchEnabled() {
    this.batchChoiceEnabled = !this.batchChoiceEnabled
  }

  @autobind
  handleSubHeaderEnabled() {
    this.switchBatchEnabled()
  }

  @autobind
  handleBatchSet() {

    this.ghostArticleList.map((item) => {

      const validActions = this.getValidActions(item)
      const censhareElementCounter = this.getCenshareElementCounter(item, validActions)

      if (validActions.find(type => type === this.ghostSubHeaderChoice)) {
        item.value = this.ghostSubHeaderChoice
      }
      else if (this.project.hasCenshare && this.ghostSubHeaderChoice === 'censhare') {
        // Default to translate if no censhare action possible
        item.value = 'translate'
      }

      return item
    })

  }

  @autobind
  handleSubHeaderChanged({ target }) {
    this.updateSubHeaderChoice(target.value)
  }

  @autobind
  handleMixedConfirm() {
    if (!this.state.lockCommit) {

      this.page.hideNavigation = !this.state.hideNavigation
      this.page.publicationNotAllowed = this.state.publicationNotAllowed

      // Notify the user of work being done and lock the comfirm button
      this.setState({
        lockCommit: true
      })
      if (this.props.onUpdateStatus) {
        this.props.onUpdateStatus({
          id: 'pm-page-copy',
          name: 'page.copy-started',
          value: 'saving',
          priority: 3
        }, 90000)
      }

      this.context.dispatch(
        this.props.commands.TransformGhostPageCommand,
        {
          page: this.page,
          itemList: this.ghostArticleList
        }
      ).then(() => {
        // Notify of complete page copy or error
        if (this.props.onUpdateStatus) {
          this.props.onUpdateStatus({
            id: 'pm-page-copy',
            name: 'page.copy-ended',
            value: 'saving',
            priority: 2
          }, 3000)
        }
        this.setState({
          lockCommit: false
        })
      }).catch((err) => {
        if (this.props.onUpdateStatus) {
          this.props.onUpdateStatus({
            id: 'pm-page-copy',
            name: 'page.copy-error',
            value: 'saving',
            priority: 2
          }, 3000)
        }
        this.setState({
          lockCommit: false
        })
        // Special censhare handling
        if (err.message === 'Missing language variants for content.') {
          this.handleCenshareMissingTranslation(err)
        }
      })
    }
  }

  @autobind
  handleCenshareMissingTranslation(error) {

    let missingTranslations = deepGet(error, 'errors')

    if (missingTranslations) {
      missingTranslations = Object.keys(missingTranslations).map((key) => {
        return {
          id: key * 1,
          placeholders: Object.values(missingTranslations[key]) || []
        }
      })
    }

    let errorCount = 0

    // Filter the ones already removed
    this.ghostArticleList = this.ghostArticleList.filter((el) => {
      if (el.value === 'remove') {

        // Also remove it from the page
        this.page.items = this.page.items.filter(item => item.id !== el.id)
        return false
      }
      return true
    })

    this.ghostArticleList.forEach((item) => {
      if (item.value === 'censhare') {

        if (item.type === 'widget') {
          const widget = widgetStore.getById(item.id * 1)
          widget.items.forEach((widgetArticle) => {
            const fullWidgetAricle = articleStore.getById(widgetArticle.id * 1)
            Object.keys(fullWidgetAricle.phAccessor.placeholderMap).forEach((ph) => {
              const source = fullWidgetAricle.phAccessor.placeholderMap[ph].getSource()

              if (source) {
                const missingTranslation = missingTranslations.find(el => el.id === fullWidgetAricle.id)

                // If a source is found in missing translation
                if (missingTranslation && missingTranslation.placeholders.find(el => el === ph)) {

                  // increase errorCount
                  errorCount += 1
                }
              }
            })
          })
        }
        // type article
        else {
          const article = articleStore.getById(item.id * 1)

          // Check all placeholders for source object
          Object.keys(article.phAccessor.placeholderMap).forEach((ph) => {
            const source = article.phAccessor.placeholderMap[ph].getSource()

            if (source) {

              const missingTranslation = missingTranslations.find(el => el.id === article.id)

              // If a source is found in missing translation
              if (missingTranslation && missingTranslation.placeholders.find(el => el === ph)) {

                // increase errorCount
                errorCount += 1
              }
            }
          })
        }
      }
    })

    // If every element has a translation
    if (errorCount === 0) {
      this.forceCopy(missingTranslations)
      this.handleMixedConfirm()
      return
    }

    // Warn user that not all elements have translations
    censhareTranslationWarning({
      errorCount,
      languageIso: this.page.createdIso
    }).then(() => {

      this.forceCopy(missingTranslations)
      this.handleMixedConfirm()

    }).catch((err) => {
      // Catch the cancel event from the dialog
      console.log(err)
    })
  }

  forceCopy(missingTranslations) {
    this.ghostArticleList.forEach((item) => {
      if (item.value === 'censhare' && item.type !== 'widget') {

        // A copy should be forced at that point
        item.force = true

        const article = articleStore.getById(item.id * 1)

        // Check all placeholders for source object
        Object.keys(article.phAccessor.placeholderMap).forEach((ph) => {
          const source = article.phAccessor.placeholderMap[ph].getSource()

          if (source) {

            const missingTranslation = missingTranslations.find(el => el.id === article.id)

            // If a source is found in missing translation
            if (missingTranslation && missingTranslation.placeholders.find(el => el === ph)) {

              // Prevent copy of source
              if (item.preventSource) {
                item.preventSource = [ph]
              }
              else {
                item.preventSource.push(ph)
              }
            }
          }
        })
      }
    })
  }

  @autobind
  handleCheckBoxChange({ target }) {
    this.setState({
      [target.id]: target.checked
    })
  }

  renderEmptyMessage() {
    return (
      <div className={classNames(css.ghostSubheader, css.ghostDialogPadding)}>
        <FormattedMessage id="ghost-dialog.empty" />
      </div>
    )
  }

  renderGhostBody() {
    return (
      <div className={css.ghostDialogPaddingBody}>
        <ul id="ghost-dialog-padding" className="element-list">
          {this.ghostArticleList.map((item) => {
            const actions = this.getValidActions(item)
            const censhareElementCounter = this.getCenshareElementCounter(item, actions)
            return (
              <GhostItem
                key={item.id.toString()}
                item={item}
                updateArticleList={this.updateArticleList}
                censhareElementCounter={censhareElementCounter}
                hasCenshare={this.project.hasCenshare}
                censhareIso={this.state.languageIso}
                ghostOptions={actions}
                ui={this.props.ui}
              />
            )
          })}
        </ul>
      </div>
    )
  }

  renderGhostHeaderAndBody() {
    return (
      <div>
        <GhostHeader
          ghostOptions={ghostOptions}
          handleSubHeaderEnabled={this.handleSubHeaderEnabled}
          ghostSubHeaderChoice={this.ghostSubHeaderChoice}
          batchChoiceEnabled={this.batchChoiceEnabled}
          handleBatchSet={this.handleBatchSet}
          ui={this.props.ui}
          handleSubHeaderChanged={this.handleSubHeaderChanged}
          ghostArticleListLength={this.ghostArticleList.length}
          censhareIso={this.state.languageIso}
        />

        {
          this.ghostArticleList.length
            ? this.renderGhostBody()
            : this.renderEmptyMessage()
        }

        <div className={classNames(css.ghostFooter, css.ghostDialogPadding)}>

          <div>
            <input
              id="publicationNotAllowed"
              type="checkbox"
              checked={this.state.publicationNotAllowed}
              onChange={this.handleCheckBoxChange}
            />
            <label htmlFor="publicationNotAllowed">
              <FormattedMessage
                id="page-security-dialog.publication-not-allowed"
              />
            </label>

            <input
              id="hideNavigation"
              type="checkbox"
              checked={this.state.hideNavigation}
              onChange={this.handleCheckBoxChange}
            />
            <label htmlFor="hideNavigation">
              <FormattedMessage
                id="page-settings-dialog-content.show-in-navigation"
              />
            </label>
          </div>
          <GenevaButton
            onClick={this.handleMixedConfirm}
            className={classNames(
              'small button',
              testClass('ghost-page-submit')
            )}
            title={formatMessage({ id: 'ghost-dialog.confirm' })}
          >
            <FormattedMessage id="ghost-dialog.confirm" />
          </GenevaButton>
        </div>
      </div>
    )
  }

  renderGhostLoadingScreen() {
    return (
      <LoadingScreenOverlay />
    )
  }

  render() {
    const lockCommit = this.state.lockCommit
    return (<div
      id="ghost-dialog-container"
      className={classNames(css.ghostFrameContainer)}
    >
      <div className={classNames(css.ghostFrame)}>
        {
          lockCommit
            ? this.renderGhostLoadingScreen()
            : this.renderGhostHeaderAndBody()
        }
      </div>
    </div>)
  }

}
