import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import { toJS } from 'mobx'
import { observer } from 'mobx-react'

export default function connectRepeatableToContext(Repeatable, parentProps) {
  if (!parentProps.articlePlaceholders) {
    throw new Error(
      '`connectRepeatableToContext` '
        + "requires an articlePlaceholders Object in it's "
        + 'parentProps'
    )
  }

  @observer
  class PMContextConnectedRepeatable extends Component {
    static pid(baseId, index, itemPid) {
      return `${baseId}:${index}~${itemPid}`
    }

    static propTypes = {
      pid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      maxLen: PropTypes.number,
      createNewChildren: PropTypes.func,
      renderChild: PropTypes.func.isRequired,
      value: PropTypes.array,
    };


    render() {
      const env = toJS(parentProps.env)

      const value
        = this.props.value
        || parentProps.articlePlaceholders.get(this.props.pid)
        || []

      return (
        <Repeatable
          {...this.props}
          value={value}
          env={env}
          onChange={this.handleChange}
        />
      )
    }
  }

  return PMContextConnectedRepeatable
}
