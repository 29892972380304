import { observable, computed, toJS, action, transaction } from 'mobx'
import { formatMessage } from '../translations'
import { specialPageIds, PAGE_ID_STARTPAGE } from '../shared/const'
import Model from '../shared/model'
import { i18n, getUserNameById } from '../shared/utils'
import { lockable } from '../shared/decorators/communicator'
import { deepGet } from '../shared/obj'
import { CircularDependencyFreeStore } from '../CircularDependencyFreeStore'
import {
  UpdatePageContentCommand,
} from './commands'

/**
 * Properties:
    autofilled : 0
    changedAt : "2022-11-21 15:28:32"
    content : "<div></div>"
    createFolder : false
    createdAt : "2021-09-13 12:00:20"
    createdBy : "5902018aedffcb32008b4567"
    createdIso : "en"
    editable : 1
    hasChanged : 1
    header : HTML Node for the header
    hideBreadcrumb : 0
    hideNavigation : 0
    i18n : {,…}
    id : 16241
    isGhostPage : 0
    isSilent : false
    itemTemplates : {article: {4: {id: 4, vendor: "default", shortcut: "hotspot"},…},…}
    items : [{id: 106190, type: "article", templateId: 22, data: {,…},…},…]
    lastPublishedAt : null
    lastUnpublishedAt : null
    meta : {censhareArticleIds: [495758]}
    navigationArticleId : null
    parentId : 34
    position : 2
    projectId : 3
    publicationNotAllowed : 0
    publishAt : null
    publishMailTo : null
    publishedUrl : null
    realUrl : null
    redirectTarget : 1
    redirectType : 1
    redirectUrl : ""
    releasePublicationLockAt : null
    sourceId : null
    status : null
    template : {vendor: "atrivio", shortcut: "main-page"}
    templateData : {,…}
    templateId : 2
    type : 1
    unpublishAt : null
    unpublishMailTo : null
    updatedAt : "2022-11-21 15:28:32"
    updatedBy : "5902018aedffcb32008b4567"
 */

@lockable
export default class PartialPage extends Model {
  // Put all page properties here.
  // the placeholder object
  content = '';

  items = [];

  meta = {};

  templateData = {};

  __gridOrder = [];

  @observable sub = [];

  @observable i18n = {};

  // the template id of this page
  templateId = '';

  // whether or not this item is marked as active
  isActive = false;

  complete = false;

  contentTemplatesLoaded = false;

  // whether a page has changed since last publish
  hasChanged = false;

  @observable lastPublishedAt = null;

  // Last date/time this was published
  @observable lastUnpublishedAt = null;

  // Last date/time this was unpublished
  @observable publishAt = null;

  // A date/time when this will be published
  @observable publicationNotAllowed = false;

  // Whether publishing is locked for this page
  @observable publishedUrl = null;

  // The url/filename of this page after publishing
  @observable releasePublicationLockAt = null;

  // Date/time when the lock will be cleared
  @observable isGhostPage = false;

  navigationArticleId = null;

  isSaving = false;

  redirectUrl = '';

  redirectTarget = null;

  reviewModelId = null;

  // 1-current tab 2-new tab
  redirectType = 1; // 1-internal 2-external

  @observable unpublishAt = null; // A date/time when this will be unpublished

  @computed get isEmpty() {
    return !this.items.length
  }

  // special template type. renders without template
  @computed get isRedirectPage() {
    return this.templateId === -1
  }

  // with negative templateId, special rendering. no template needed
  @computed get needsTemplate() {
    return this.templateId < 0
  }

  @computed get channelShortcut() {
    const channelShortcut = deepGet(
      CircularDependencyFreeStore,
      'projectStore.current.channelShortcut'
    )
    return channelShortcut
  }

  @computed get asJSON() {
    return this.getJSON()
  }

  getJSON() {
    return {
      id: this.id,
      name: this.name,
      navigationName: this.navigationName,
      navigationArticleId: this.navigationArticleId,
      i18n: toJS(this.i18n),
      content: toJS(this.templateData),
      parentId: this.parentId,
      projectId: this.projectId,
      // position: this.position, should not be used with a normal save
      hideNavigation: this.hideNavigation,
      createFolder: this.createFolder,
      templateId: this.templateId,
      publicationNotAllowed: this.publicationNotAllowed,
      releasePublicationLockAt: this.releasePublicationLockAt,
      // these props may be used upon page creation to create a page
      // with a template matching this spec. This is handy when the templateId
      // is not known
      vendor: this.vendor || null,
      shortcut: this.shortcut || null,
      hideInSearch: this.hideInSearch,
      type: this.type,
      meta: this.meta,
      updatedBy: this.updatedBy,
      redirectTarget: this.redirectTarget || 1, // default
      redirectType: this.redirectType || 1, // default
      redirectUrl: this.redirectUrl,
      hideBreadcrumb: this.hideBreadcrumb,
      hideFromRobots: this.hideFromRobots,
    }
  }

  // react-tree-component props
  @computed get children() {
    return this.sub
  }

  @computed get hasChildren() {
    return !!this.sub.length
  }

  getExpandedKeys() {
    let expandedKeys = []
    let curr = this.parent || this.store.getPartialById(this.parentId)
    while (curr) {
      expandedKeys.push(`${curr.id}`)
      curr = curr.parent || this.store.getPartialById(curr.parentId)
    }
    expandedKeys = expandedKeys.reverse()

    return expandedKeys
  }

  @computed get name() {
    return i18n(this, 'name', this.createdIso)
  }

  @computed get navigationName() {
    return i18n(this, 'navigationName', this.createdIso)
  }

  @computed get title() {
    return i18n(this, 'title', this.createdIso)
  }

  @computed get keywords() {
    return i18n(this, 'keywords', this.createdIso)
  }

  @computed get description() {
    return i18n(this, 'description', this.createdIso)
  }

  get leaf() {
    return false // !this.children.length
  }

  get collapsed() {
    return 'isCollapsed' in this && this.isCollapsed !== undefined
      ? this.isCollapsed
      : this.depth >= 1
  }

  set collapsed(isCollapsed) {
    this.isCollapsed = isCollapsed
  }

  get isDeletable() {
    // Do not allow to delete or archive this page if:
    // a) this is the Startpage
    if (
      this.parentId === PAGE_ID_STARTPAGE
      || (this.parent && this.parent.id === PAGE_ID_STARTPAGE)
    ) {
      return false
    }

    // b) one of the sub pages is published
    return this.publishedChildren.length === 0
  }

  get publishedChildren() {
    const publishedChildren = []
    this.traverse((page) => {
      if (page.publishedUrl) {
        publishedChildren.push(page)
      }
    })
    return publishedChildren
  }

  get allChildren() {
    const allChildren = []
    this.traverse((page) => {
      allChildren.push(page.id)
    })
    return allChildren
  }

  get updatedByName() {
    return getUserNameById(this.updatedBy)
  }

  constructor(store, rawData, opts = {}) {
    opts.ignore = true
    super(store, rawData, opts)
    this.isActive = false
    this.store = store

    this.autoSavable = false
    this.autoSave = false

    this.destoySaveHandler = null
    this.modelOpts = { idProp: 'id' }

    this.set(rawData)

    this.initLockable()

    this.isSpecialPage = this.ensureIsSpecialPage()
  }

  parse(data) {
    // eslint-disable-next-line no-underscore-dangle
    if (data.__parsed) {
      // if the data was already parsed once, bail out immediately
      return data
    }

    if (!data.id && data.data) {
      data = data.data
    }

    if (data.id) {
      data.id *= 1
    }

    if (data.projectId) {
      data.projectId *= 1
    }

    if (this.content && !data.content) {
      delete data.content
    }

    if (!data.meta || Array.isArray(data.meta)) {
      data.meta = {}
    }

    if (
      (!this.templateData && !data.templateData)
      || Array.isArray(data.templateData)
    ) {
      data.templateData = {}
    }

    if (!data.itemTemplates || Array.isArray(data.itemTemplates)) {
      data.itemTemplates = {}
    }

    if (
      !data.itemTemplates.article
      || Array.isArray(data.itemTemplates.article)
    ) {
      data.itemTemplates.article = {}
    }

    if (
      !data.itemTemplates.widget
      || Array.isArray(data.itemTemplates.widget)
    ) {
      data.itemTemplates.widget = {}
    }

    delete data.name

    // eslint-disable-next-line no-underscore-dangle
    data.__parsed = true

    return data
  }

  set(data) {
    data = this.parse(toJS(data))

    // If a value is null it could still exist
    // but the backend did not send it.
    let templateData = null
    if ('templateData' in data) {
      templateData = data.templateData
      delete data.templateData
    }

    let items = null
    if ('items' in data) {
      items = data.items
      delete data.items
    }

    Object.assign(this, data)

    // subs are not delivered when requesting a single page, so
    // in case there is no data for data.sub, don't do anything,
    // unless the model has no sub property yet
    let subs = null

    if (!this.sub || data.sub) {
      const childDepth = data.depth + 1
      subs = (data.sub || []).map((item) => {
        item.depth = childDepth
        const subModel = new PartialPage(this.store, item)
        subModel.parent = this
        subModel.parentId = this.id
        return subModel
      })
      delete data.sub
    }


    if (subs) {
      // somehow mobx replace is buggy, splice seems to work
      // this.sub.replace(subs)
      if (!this.sub) {
        this.sub = []
      }
      this.sub.splice(0, this.sub.length, ...subs)
    }

    this.complete = true
  }

  ensureIsSpecialPage() {
    return !!specialPageIds.find(id => id === this.id)
  }


  /** Get the translated name for that page
   * @returns {String} name - The name of the Page.
   * The fake Pages for the Navigation will have there names translated.
   */
  getName() {
    return this.isSpecialPage && typeof formatMessage === 'function'
      ? formatMessage({ id: this.name })
      : this.name
  }

  setName(name) {
    i18n(this, 'name', this.createdIso, name)
    this.save()
  }


  saveChild(data) {
    if (this.transactionsRunning) {
      this.saveCalled = true
      return Promise.resolve(this)
    }
    return this.store.saveChild(data, this)
  }

  /**
   * @param {Page} page
   * @param {number} index
   * @param {Object} opts
   *
   */
  addChild(page, index, opts = {}) {
    if (!(page instanceof PartialPage)) {
      throw new Error('Only page instances can be added as sub pages')
    }

    if (
      (page.parent && page.parent !== this)
      || (page.parentId && page.parentId !== this.id)
    ) {
      // eslint-disable-next-line max-len
      throw new Error(
        `The page ${page.name} (${page.id}) is already child of ${page.parent.name} (${page.parent.id}). Remove it first to be able to add it.`
      )
    }

    const currPageIndex = this.indexOfChild(page)

    const isAlreadyChild = currPageIndex >= 0

    if (isAlreadyChild && !opts.ignoreExist) {
      // eslint-disable-next-line max-len
      throw new Error(
        `The page ${page.name} (id: ${page.id}) already exists as a child (name: ${this.sub[currPageIndex].name}) in the collection of the page ${this.name} (id: ${this.id}).`
      )
    }

    if (!this.sub) {
      this.sub = []
    }

    if (isAlreadyChild) {
      // extract the child at the old pos
      this.sub.splice(currPageIndex, 1)
    }

    if (index === undefined) {
      // always add new pages at the top
      index = 0
    }
    else if (index > this.sub.length) {
      index = this.sub.length
    }

    page.parent = this
    page.parentId = this.id
    page.position = index + 1 // backend starts counting at 1
    page.depth = this.depth + 1

    this.sub.splice(index, 0, page)
  }

  /**
   *
   * @param {Page} page
   */
  removeChild(page) {
    const index = this.indexOfChild(page)
    page.parent = null
    page.parentId = null
    page.position = -1
    page.depth = -1
    this.sub.splice(index, 1)
  }

  /**
   *
   * @param {Page} page
   */
  indexOfChild(page) {
    let index = this.sub.indexOf(this.ensurePage(page))
    if (index >= 0) {
      return index
    }

    page = this.sub.find(p => p.id === page.id)
    if (page) {
      index = this.sub.indexOf(this.ensurePage(page))
    }

    return index
  }

  /**
   *
   * @param {Page} page
   */
  ensurePage(page) {
    let pageId

    if (typeof page === 'string' || typeof page === 'number') {
      pageId = page
      page = null
    }

    if (pageId) {
      page = this.sub.find(sub => sub.id === pageId)
    }

    if (!page) {
      throw new Error('Cannot remove page. Page not found in children!')
    }

    return page
  }

  traverse(fn) {
    fn(this)
    this.sub.map(page => page.traverse(fn))
  }

  /**
   * Starts a transaction. All code calling save after the
   * transaction was started will be blocked untill
   * PageModel#endTransaction was called.
   * Multiple calls to this function while a transaction is
   * already running will raise a counter for running
   * transactions. Every call to PageModel#endTransaction
   * decreases that counter again.
   * Cals to PageModel#save() while a transaction is running
   * will **always** return a successfully resolving Promise!l
   * @method startTransaction
   */
  startTransaction() {
    if (!this.transactionsRunning) {
      this.saveCalled = false
      if (!this.transactionsRunning) {
        this.transactionsRunning = 0
      }
      this.transactionsRunning += 1
    }
  }

  /**
   * Ends a transaction. If this was the last transaction on the
   * transaction stack and PageModel#save would have been called
   * some time time a transaction was active, save will be called
   * now.
   * @method endTransaction
   * @return {Promise<Object>|null} - The promise of the call to
   * save, or null if save was actually never called while the
   * transaction was active.
   */
  endTransaction() {
    this.transactionsRunning -= 1
    if (!this.transactionsRunning && this.saveCalled) {
      this.saveCalled = false
      return this.save()
    }
    return null
  }
}
