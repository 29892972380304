import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import { autobind } from 'core-decorators'
import classNames from 'classnames'
import { observer } from 'mobx-react'

@observer
export default class Hotspot extends Component {
  static propTypes = {
    activeSpot: PropTypes.any,
    posPid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    textPid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    spotWrapperClassName: PropTypes.string,
    labelClassName: PropTypes.string,

    editorSpec: PropTypes.object,
    editorTagName: PropTypes.string,

    positionableChild: PropTypes.object,
    repeatableProps: PropTypes.object,

    Resizable: PropTypes.func,
    Editor: PropTypes.func,
    Positionable: PropTypes.func,

    spotId: PropTypes.string,
    spotClassName: PropTypes.string,

    onSpotActivated: PropTypes.func,
    onSpotDragStart: PropTypes.func,
    onSpotDragEnd: PropTypes.func,
    onSpotResize: PropTypes.func,
  };

  constructor(props) {
    super(props)
    this.state = {
      isHidden: !!props.env.CM,
    }
  }

  @autobind
  handleSpotActivated() {
    if (this.props.env.CM) {
      this.setState(prevState => ({ isHidden: !prevState.isHidden }))
    }

    if (this.props.onSpotActivated) {
      this.props.onSpotActivated({
        target: {
          value: this.props.posPid,
        },
      })
    }
  }

  render() {
    const {
      spotId,
      spotClassName,
      spotWrapperClassName,
      labelClassName,
      posPid,
      repeatableProps,
      positionableChild,
      Resizable,
      Positionable,
      additionalTools,
    } = this.props

    const resizableStyle = this.state.isHidden ? { display: 'none' } : null

    return (
      <div
        className={classNames('spot-wrapper', spotWrapperClassName)}
        key={posPid}
      >
        <Positionable
          data-spot={spotId}
          pid={posPid}
          className={classNames('spot', spotClassName)}
          onClick={this.handleSpotActivated}
          onDragEnd={this.props.onSpotDragEnd}
          onDragStart={this.props.onSpotDragStart}
        >
          {positionableChild}
        </Positionable>

        <Resizable
          style={resizableStyle}
          activeElement={this.props.activeSpot}
          pid={posPid}
          className={labelClassName}
          onResize={this.props.onSpotResize}
        >
          {this.props.children}
          {repeatableProps && repeatableProps.tools}
          {additionalTools}
        </Resizable>
      </div>
    )
  }
}
