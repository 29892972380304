/**
 * @name defaultButtonStte
 * @type {Object}
 * @property {Boolean} active - Whether the button is active. Default: `false`
 * @property {Boolean} disabled - Whether the button is disabled. Default: `true`
 * @property {String} cmd - The name of the command. Defaults to `no-name`
 */
export default {
  active: false,
  disabled: true,
  cmd: 'no-name'
}
