import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { autobind, throttle } from 'core-decorators'

import { dispatcher } from '../../../../../shared/lib/command'

@dispatcher
@observer
export default
class MoveArtilceToPageConnectedTarget
  extends Component {

  static propTypes = {
    page: PropTypes.object,
    disabled: PropTypes.bool,
    onGetContext: PropTypes.func,
    Target: PropTypes.func.isRequired,
    viewModels: PropTypes.shape({
      currentlyOpenWidget: PropTypes.shape({
        item: PropTypes.object,
      })
    })
  }

  getDataForMoveContentInWidgetCommand() {
    const context = this.getContext()
    return {
      widgetId: this.props.viewModels.currentlyOpenWidget.data.item.id,
      moveFromPosition: context.positionFrom,
      moveToPosition: context.positionTo,
    }
  }

  getContext() {
    const { onGetContext } = this.props
    let context = {}

    if (onGetContext) {
      const tmpContext = onGetContext()
      if (tmpContext) {
        context = tmpContext
      }
    }

    if (!context.positionTo || !context.positionFrom) {
      throw new Error('Could not determine position for move action.')
    }

    return context
  }

  @autobind
  @throttle(200, { leading: true })
  handleContentActionTriggered() {

    this.context.dispatch(
      this.props.commands.MoveContentInWidgetCommand,
      this.getDataForMoveContentInWidgetCommand()
    )

  }

  render() {

    const {
      Target,
      page,
      onGetContext,
      disabled,
      viewModels: { currentlyOpenWidget },
      ...rest
    } = this.props

    const widget = currentlyOpenWidget.data.item || {}

    return (<Target
      {...rest}
      disabled={disabled || widget.isUpdating}
      // allow keeping the mouse button pressed on the button
      onMousePress={this.handleContentActionTriggered}
      pageId={page.id}
    />)

  }
}
