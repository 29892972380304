function computedStyle(el, key) {
  return getComputedStyle(el).getPropertyValue(key)
}
function scrollParent(el, includeHidden = false) {
  const position = computedStyle(el, 'position')

  let currEl = el
  const parents = []
  // eslint-disable-next-line no-cond-assign
  while ((currEl = currEl.parentNode) && currEl !== document) {
    parents.push(currEl)
  }

  let scrollParents = null
  parents.forEach((parent) => {

    const parentParent = parent.parentNode

    if (parentParent.className && parentParent.className.includes('ps--active-y') && !scrollParents) {
      scrollParents = parentParent
    }

  })
  return scrollParents
}

export { scrollParent as default }
