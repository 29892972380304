import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observable, action } from 'mobx'
import { observer } from 'mobx-react'
import { autobind } from 'core-decorators'
import classNames from 'classnames'
import { TreeNode } from 'draggable-react-tree-component'
import PerfectScrollbar from 'perfect-scrollbar'

import { formatMessage, FormattedMessage } from '../../translations'
import alert from '../../shared/components/Alert'
import { confirmDelete } from '../../page/containers/dialogs'
import GenevaButton from '../../ui/components/GenevaButton'
import { i18n } from '../../shared/utils'
import ContentLoadingBox from '../../shared/components/ContentLoadingBox'
import { dispatcher } from '../../shared/lib/command'
import { testClass } from '../../shared/utils'

import PageTreeContainer from './Tree'
import ProjectSelect from './ProjectSelect'

const css = typeof window === 'undefined' ? {} : require('../styles.scss')

@dispatcher
@observer
export default
class CopyManagerLocalPanel extends Component {

  static propTypes = {
    project: PropTypes.object,
    pageStore: PropTypes.object,
    updateExternalDragNode: PropTypes.func,
    externalDragNode: PropTypes.object,
    externalDragNodeKeys: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string
    ]),
    originTree: PropTypes.string,
    router: PropTypes.shape({
      push: PropTypes.func
    }).isRequired,
  }

  constructor(props) {
    super(props)

    this.deletable = false
    this.state = {
      expandedKeys: [],
      speciaTreeHover: ''
    }

    this.ps = null
  }

  // eslint-disable-next-line react/sort-comp
  @observable dragNode

  @observable selectedKeys = new Set()

  @observable selectedNodeId = null

  @autobind
  @action
  updateDragNode(node) {
    this.dragNode = node
  }

  updateExpandedKeys(key) {
    this.setState({
      expandedKeys: key
    })
  }

  componentDidMount() {
    this.ps = new PerfectScrollbar('#local-copy-tree', {
      suppressScrollX: true
    })
  }

  isReady() {
    return !this.props.project.loading && this.props.project.isComplete
  }

  copyPage(info) {

    // Converting to number
    const destinationId = Number(info.node.props.eventKey)
    const sourceId = Number(info.dragNode.props.eventKey)
    const dragKeys = this.props.externalDragNodeKeys
    const fullTree = typeof dragKeys === 'string'
      ? false
      : dragKeys.length > 1

    this.context.dispatch(
      this.props.commands.CopyPageCommand,
      {
        parentId: destinationId,
        pageId: sourceId,
        followingPages: fullTree,
        position: info.dropToGap
          ? info.dropPosition + 1   // backend starts counting at 1
          : 0
      }
    )

  }

  sortPage(info) {
    const destinationId = info.node.props.eventKey
    const sourceId = info.dragNode.props.eventKey

    this.props.pageStore.actionAddChild(
      destinationId,
      sourceId,
      info.dropToGap
        ? info.dropPosition
        : 0
    )
  }

  @autobind
  handleExpand(expandedKeys) {
    this.updateExpandedKeys(expandedKeys)
  }

  @autobind
  handleDeleteEntry(opts = { archive: false }) {
    const pageId = this.selectedNodeId
    const page = this.props.pageStore.getPartialById(pageId)

    if (!page.isDeletable) {
      const list = page.publishedChildren.map((child) => {
        return i18n(child, 'name')
      })

      alert(
        formatMessage(
          { id: 'page.alert.delete.info' },
          {
            name: page.name
          },
        ),
        formatMessage({
          id: 'page.alert.delete.title'
        }),
        {
          list
        })

      return
    }
    confirmDelete({
      page,
      opts
    }).then(() => {
      this.props.pageStore.actionDestroyItem(pageId, { archive: false })
      this.selectedNodeId = null
      this.selectedKeys.clear()

    }).catch((err) => {
      // Catch the cancel event from the dialog
      console.log(err)
    })
  }

  @autobind
  handleOpenPage() {

    const { router } = this.props

    this.context.dispatch(
      this.props.commands.EditItemCommand,
      {
        router,
        type: 'page',
        id: (this.selectedNodeId * 1)
      }
    )
  }

  @autobind
  handleSelect(item) {

    if (item.length > 0 && this.selectedNodeId !== item[0]) {
      this.selectedNodeId = item[0]
      this.selectedKeys.clear()
      this.selectedKeys.add(item[0])
    }
  }

  @autobind
  handleDragStart({ event, node, nodeKeys }) {

    event.dataTransfer.effectAllowed = 'copy'

    // Set the dragging node
    this.props.updateExternalDragNode(node, nodeKeys, 'local')
    this.updateDragNode(node)

    this.selectedKeys.clear()
  }

  @autobind
  handleDragOver({ event, node }) {
    const maxNavigationLevel = this.props.project.maxNavigationLevel
    const page = this.props.pageStore.getPartialById(node.props.eventKey)

    if (maxNavigationLevel && maxNavigationLevel < page.depth) {
      event.dataTransfer.dropEffect = 'none'
    }
  }

  @autobind
  handleDrop(info) {

    if (this.props.originTree === 'remote') {
      this.copyPage(info)
    }

    // Can only sort nodes on the same tree
    // else if (this.props.originTree === 'local' && !(info.node.props.root
    //   && info.node.props.root.refs.tree !== this.dragNode.props.root.refs.tree)) {
    else {
      this.sortPage(info)
    }

    // Keep the pages expanded after drop
    const page = this.props.pageStore.getPartialById(info.node.props.eventKey)
    const parentKeys = page.getExpandedKeys()
    parentKeys.push(info.node.props.eventKey)

    this.updateExpandedKeys([
      ...this.state.expandedKeys,
      ...parentKeys
    ])

  }

  @autobind
  handleSpecialDrop(info) {
    console.log(info)
    const destinationId = -2
    const sourceId = this.dragNode.props.eventKey * 1

    this.props.pageStore.actionAddChild(
      destinationId,
      sourceId,
      0
    )

    this.setState({
      speciaTreeHover: ''
    })
  }

  @autobind
  handleSpecialDragOver(event) {
    // prevent unnecessary writing to the state
    if (this.state.speciaTreeHover === '') {
      this.setState({
        speciaTreeHover: 'hover'
      })
    }
    event.preventDefault()
    return false
  }

  @autobind
  handleSpecialDragLeave(event) {
    // prevent unnecessary writing to the state
    if (this.state.speciaTreeHover === 'hover') {
      this.setState({
        speciaTreeHover: ''
      })
    }
    event.preventDefault()
    return false
  }

  @autobind
  renderHeader() {
    return (<div className={classNames(css.copyManOptionsBar, 'v-align')}>
      <ProjectSelect
        currentProject={this.props.project}
      />
    </div>)
  }

  renderLocalItem(item) {
    return item.navigationName || item.name
  }

  renderOptionBar() {

    return (<div>
      <GenevaButton
        onClick={this.handleDeleteEntry}
        disabled={this.selectedNodeId === null}
        className={classNames(
          'button float-left flat',
          testClass('button-delete')
        )}
      >
        <FormattedMessage id="page.delete" />
      </GenevaButton>
      <GenevaButton
        onClick={this.handleOpenPage}
        disabled={this.selectedNodeId === null}
        className={classNames(
          'button float-right flat',
          testClass('button-open-page')
        )}
      >
        <FormattedMessage id="page.open-page" />
      </GenevaButton>
    </div>
    )
  }

  @autobind
  renderItems(items) {
    return items.map((item) => {
      return (<TreeNode
        key={item.id}
        items={(item.sub && item.sub.length) ? this.renderItems(item.sub) : null}
        selected={item.isActive}
      >
        <div className={classNames('grid-block',
          css.copyManagerItem,
          testClass(`item-${item.name}`)
        )}>
          {this.renderLocalItem(item)}
        </div>
        <div className="circle" />
        <hr />
      </TreeNode>)
    })
  }

  renderPageTree() {

    const pageTreeChildren = this.props.project.pageTree.children || {}

    if (!(pageTreeChildren
      && pageTreeChildren.length > 0)
    ) {
      return (<FormattedMessage
        id="page-tree-not-found"
      />)
    }

    return (
      <React.Fragment>
        <hr className="split-screen-hr" />
        <div className="page-type-desc">
          <FormattedMessage id="project.navigation.normal-pages" />
        </div>
        <hr className="split-screen-hr" />
        <PageTreeContainer
          className="publist normal-tree"
          expandedKeys={this.state.expandedKeys}
          selectedKeys={[...this.selectedKeys]} // convert type set to array
          page={this.props.pageStore}
          project={this.props.project}
          onExpand={this.handleExpand}
          renderItems={this.renderItems}
          onSelect={this.handleSelect}

          draggable
          onDragStart={this.handleDragStart}
          onDragEnter={this.handleDragEnter}
          onDrop={this.handleDrop}
          onDragOver={this.handleDragOver}

          autoExpandParent
          checkStrictly
          selectable
          treeChildren={pageTreeChildren}
          externalDragMode={{
            externalDragNode: this.props.externalDragNode,
            externalDragNodeKeys: this.props.externalDragNodeKeys
          }}
        />
      </React.Fragment>)
  }

  renderSpecialTree() {

    const specialPageTreeChildren = this.props.project.specialPageTree.children || {}

    return (<div>
      <div
        id="specialNode"
        className={classNames(`page-type-desc special ${this.state.speciaTreeHover}`)}
        onDragOver={this.handleSpecialDragOver}
        onDragLeave={this.handleSpecialDragLeave}
        onDrop={this.handleSpecialDrop}
      >
        <FormattedMessage id="project.navigation.special-pages" className="xyz" />
      </div>
      <hr className="split-screen-hr" />
      <PageTreeContainer
        className="publist special-tree"
        expandedKeys={this.state.expandedKeys}
        selectedKeys={[...this.selectedKeys]} // convert type set to array
        page={this.props.pageStore}
        project={this.props.project}
        onExpand={this.handleExpand}
        renderItems={this.renderItems}
        onSelect={this.handleSelect}

        draggable
        onDragStart={this.handleDragStart}
        onDragEnter={this.handleDragEnter}
        onDrop={this.handleDrop}
        onDragOver={this.handleDragOver}

        autoExpandParent
        checkStrictly
        selectable
        treeChildren={specialPageTreeChildren}
        externalDragMode={{
          externalDragNode: this.props.externalDragNode,
          externalDragNodeKeys: this.props.externalDragNodeKeys
        }}
      />
    </div>)
  }

  renderTrees() {

    if (!this.isReady()) {
      return (<div
        className={classNames(`
          ${css.controlCenter} ${css.copyManagerLocal}
          grid-frame vertical`
        )}
      >
        <ContentLoadingBox
          spinner
          message={{
            id: 'project.loading'
          }}
        />
      </div>)
    }

    return (<div>
      {this.renderPageTree()}
      {this.renderSpecialTree()}
    </div>
    )
  }

  render() {
    if (this.ps) {
      this.ps.update()
    }

    return (<div
      className={classNames(
        'vertical grid-block',
        css.copyManager,
        testClass('copy-manager-local'))}
    >
      {this.renderHeader()}
      <div className="option-bar vertical">
        {this.renderOptionBar()}
      </div>
      <div
        className="grid-block vertical tree-block"
        id="local-copy-tree"
      >
        {this.renderTrees()}

      </div>
    </div>)
  }
}
