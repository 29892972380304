import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import ReactCSSTransitionGroup from 'react-transition-group/CSSTransitionGroup'
import classNames from 'classnames'
import { autobind } from 'core-decorators'
import { autorun, observable } from 'mobx'
import { inject, observer } from 'mobx-react'

import PerfectScrollbar from 'perfect-scrollbar'
import config from '../../config'
import { deepGet } from '../../shared/obj'
import { TemplateLoadingError } from '../../shared/errors'
import ContentErrorBox from '../../shared/components/ContentErrorBox'
import ContentLoadingBox from '../../shared/components/ContentLoadingBox'
import { getContentClass } from '../../shared/utils'
import { widgetTemplateStore } from '../reducers'
import cancelable from '../../shared/decorators/cancelable-promise'

import DevTools from '../components/DevTools'

import {
  TemplateDependencyCollector,
  TemplateActivator,
} from '../loaders/settings'

import * as settingsConnectors from '../connectors/pm'
import { CircularDependencyFreeStore } from '../../CircularDependencyFreeStore'

const css = /* typeof window === 'undefined' ? {} : */ require('../styles.scss')

@inject('page')
@observer
@cancelable
class WidgetTemplateDetailsContainer extends Component {
  static propTypes = {
    widget: PropTypes.object.isRequired,
    widgetTemplate: PropTypes.object.isRequired,
    context: PropTypes.object.isRequired,
    env: PropTypes.object,
  };

  constructor(props) {
    super(props)

    this.state = {
      template: null,
      templateError: null,
      templateShortcut: null,
    }

    this.handler = []

    if (deepGet(this, 'props.widget.current.id')) {
      const currents = this.props.context.getCurrentIds()
      this.props.context.setCurrentIds(Object.assign(currents, { widgetId: this.props.widget.current.id }))
    }

    this.settingsTemplateActivator = new TemplateActivator(
      this.ENV,
      settingsConnectors,
      {
        reloadStylesheets: false,
        type: 'widget',
      }
    )
  }

  componentDidMount() {
    const { widgetTemplate } = this.props
    const { pageStore } = CircularDependencyFreeStore

    this.handler = [
      autorun(() => this.loadTemplate(widgetTemplate.current.id)),

      autorun(() => this.loadTemplateModule(widgetTemplate.current.shortcut)),

      autorun(() => {
        const module = this.props.widgetTemplate.current.module

        // the conent reandere will need the page, so wait till it is complete
        if (module && module.getTemplate && pageStore.current && pageStore.current.complete) {
          this.validateTemplateModule(module)
        }
      }),

      autorun(() => {
        this.settingsTemplateActivator.opts.reloadStylesheets
          = this.props.widgetTemplate
          && this.props.widgetTemplate.alwaysReloadStyleSheets
      }),
    ]

    this.ps = new PerfectScrollbar('#widget-details-container')
  }

  componentWillUnmount() {
    if (this.handler.length) {
      this.handler.forEach(handler => handler())
      this.handler = null
    }

    this.reset()
  }

  @observable ENV = {
    CM: false,
    PM: true,
    PREVIEW: false,
    PUBLISH: false,
  };

  reset() {
    this.currentlyDisplayedWidget = null
    this.setState({
      template: null,
      templateError: null,
      templateShortcut: null,
    })
  }

  currentTemplateIsCurrentArticleTemplate() {
    const { props } = this
    return (
      deepGet(props, 'widget.current.templateId')
      === deepGet(props, 'widgetTemplate.current.id')
    )
  }

  loadTemplate(id) {
    // only open this template if it's has the same id that the
    // current article needs
    if (!id || !this.currentTemplateIsCurrentArticleTemplate()) {
      this.reset()
      return
    }

    if (this.props.widgetTemplate.current.module) {
      return
    }

    console.info('Loading widget template with id %s', id)

    widgetTemplateStore.load(id)
  }

  loadTemplateModule(shortcut) {
    if (!shortcut || this.state.templateShortcut === shortcut) {
      // TODO: if widgetTemplate swapping makes problems:
      // - use this.reset()
      // - investigate endless loading

      //  this.reset()
      return
    }

    this.setState({
      templateShortcut: shortcut,
    })

    if (!this.currentTemplateIsCurrentArticleTemplate()) {
      this.reset()
      return
    }

    widgetTemplateStore.openTemplateModuleByShortcut(shortcut)
  }

  validateTemplateModule(module) {
    // if there is no module, we cannot be showing any
    // article, so reset the flag
    if (!module) {
      this.currentlyDisplayedWidget = null
    }

    console.info('Validating template')

    const newWidget = this.props.widget.current
    if (!newWidget || !newWidget.keyValueAccessor || !newWidget.complete) {
      this.reset()
      console.info('...aborted [widget invalid]')
      return
    }

    if (!module || !this.currentTemplateIsCurrentArticleTemplate()) {
      this.reset()
      console.info('...aborted [template invalid]')
      return
    }

    if (newWidget === this.currentlyDisplayedWidget) {
      console.info('...skipping [widget identical]')
      return
    }

    console.info('...ok. Loading template...')

    this.currentlyDisplayedWidget = this.props.widget.current
    this.currentModule = module

    this.activateTemplate()
  }

  activateTemplate() {
    const { customLocal, context } = this.props

    return this.makeCancelable(
      new Promise((resolve) => {
        if (this.currentModule instanceof Error) {
          throw this.currentModule
        }

        const channelShortcut = this.props.widget.current.channel

        const dependencyCollector = new TemplateDependencyCollector({
          props: this.props,
          connectors: settingsConnectors,
          componentOverrides: {},
          ENV: this.ENV,
          contextStore: context,
        })

        const dependencies = {
          // TODO: remove article specific dependencies, so template can be cached
          // over various articles with same template id
          ...dependencyCollector.collect(),
          ...this.props.widgetTemplate.current.dependencies,
          channelShortcut,
        }

        const templateComponent = this.currentModule.getSettings(dependencies)

        return this.settingsTemplateActivator
          .activate({
            channelShortcut,
            templateSpec: this.props.widgetTemplate.current,
            templateComponent,
            templateModule: this.currentModule,
            dependencies,
            injectTemplateProps: {},
            customLocalStore: customLocal,
          })
          .then(template => resolve({ template }))
      })
    )
      .catch((error) => {
        if (this.isCanceledPromise(error)) {
          return null
        }
        const templateError = new TemplateLoadingError(
          'error.template-error',
          this.props.widgetTemplate.current.shortcut,
          error
        )
        console.error(templateError)
        return { templateError }
      })
      .then((result) => {
        if (result) {
          this.setState({
            template: result.template || null,
            templateError: result.templateError || null,
          })
        }
      })
  }

  @autobind
  handleFocusTemplateItem(/* target */) {}

  @autobind
  handleBlurTemplateItem(/* target */) {}


  renderTemplate() {
    const { template } = this.state
    const { widget } = this.props

    const ConnectedTemplate = template

    return (
      <ConnectedTemplate
        item={widget.current}
        content={widget.current.content}
        env={this.ENV}
        onFocus={this.handleFocusTemplateItem}
        onBlur={this.handleBlurTemplateItem}
        keyValue={widget.current.keyValueAccessor}
      />
    )
  }

  render() {
    const { template, templateError } = this.state

    let style = null

    const error = templateError

    if (this.ps) {
      this.ps.update()
    }

    if (!error) {
      if (!template) {
        return (
          <ContentLoadingBox
            id="widget-details-container"
            spinner
            message={{ id: 'template.loading' }}
          />
        )
      }
    }

    if (!config.isProduction) {
      style = {
        marginTop: '10px',
      }
    }

    return (
      <div
        id="widget-details-container"
        className={classNames('grid-block', css.allowOverflow, css.allowScroll)}
      >
        {!config.isProduction ? <DevTools /> : null}
        <div
          className={classNames(
            'grid-block',
            'geneva-pm-template-preview',
            getContentClass(),
            css.contentPreviewContainer
          )}
          style={style}
        >
          <ReactCSSTransitionGroup
            component="div"
            transitionEnter={false}
            transitionLeave={false}
            transitionName="page"
            transitionAppear
            transitionAppearTimeout={500}
            className={classNames(
              'react-transition-group',
              css.contentPreviewContainerContent
            )}
          >
            {!error ? (
              this.renderTemplate()
            ) : (
              <ContentErrorBox
                className={classNames(css.templateError, 'grid-block')}
                error={error}
              />
            )}
          </ReactCSSTransitionGroup>
        </div>
      </div>
    )
  }
}

export default WidgetTemplateDetailsContainer
