exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".src-shared-components-FileSelectButton-styles__fileUpload___1jQhw input[type=\"file\"]{display:none}.src-shared-components-FileSelectButton-styles__fileUpload___1jQhw span{padding-right:0}\n", "", {"version":3,"sources":["/builds/GENEVA/wm/frontend/src/shared/components/FileSelectButton/styles.scss"],"names":[],"mappings":"AAAA,sFAGI,YAAa,CAHjB,wEAOI,eAAgB,CACjB","file":"styles.scss","sourcesContent":[".fileUpload {\n\n  input[type=\"file\"] {\n    display: none;\n  }\n\n  span {\n    padding-right: 0;\n  }\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"fileUpload": "src-shared-components-FileSelectButton-styles__fileUpload___1jQhw"
};