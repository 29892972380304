import React from 'react';
import { observer } from 'mobx-react'

import {
  Entry,
} from '../../../../shared/components/Dropdown'
import Icon from '../../../../shared/icons'

import getButtonProps from './getButtonProps'

const ObservableEntry = observer(({
  buttonSpec,
}) => {

  const buttonProps = getButtonProps(buttonSpec)
  buttonProps.checked = buttonSpec.active

  return <Entry {...buttonProps}>

    {buttonSpec.icon
      ? <Icon name={buttonSpec.icon} />
      : null
    }

    {buttonProps.title}

  </Entry>
})

export { ObservableEntry as default }