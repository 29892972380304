import { toJS } from 'mobx'
import InfoProvider from './InfoProvider'
import { deepGet, filter } from '../obj'

const getContentForType = (type, props) => {

  switch (type) {
    case 'article':
      // for templates
      return deepGet(
        props,
        'placeholder.article',
        // for meta settings
        deepGet(props, 'article')
      )
    case 'widget':
      // for templates
      return deepGet(
        props,
        'keyValue.widget',
        // for settings
        deepGet(props, 'widget')
      )
    case 'page':
      return deepGet(props, 'page')
    default:
      return null
  }
}

const SHARED_PROPS = ['template', 'className']

const ARTICLE_PROPS_LIST = [
  'placeholder',

  ...SHARED_PROPS,

  // for settings dialogs
  'meta',
  'article',
  'onChangeSize',
  'registerBeforeCommitHandler',

  // injectTemplateProps from Activator
  // nothing sofar
]
const WIDGET_PROPS_LIST = [
  'content',
  'keyValue',
  'items',

  ...SHARED_PROPS,

  // for settings dialogs
  'meta',
  'widget',

  // injectTemplateProps from Activator
  // nothing sofar
]

const PAGE_PROPS_LIST = [
  'page',
  ...SHARED_PROPS,

  // injectTemplateProps from Activator: PM-- NOT in ContentRenderer
  'pageStore',
]

const propsLists = {
  article: ARTICLE_PROPS_LIST,
  widget: WIDGET_PROPS_LIST,
  page: PAGE_PROPS_LIST,
}

const PM_SPECIAL_PROPS = ['className', 'tabIndex', 'title']

const filterPropsForTemplateProps = (platform, type, props) => {
  let filterList = deepGet(propsLists, `${type}`, [])

  // in pm, stuff like tabIndex, className and title are applied to a
  // parent container
  if (platform === 'pm' && (type === 'article' || type === 'widget')) {
    filterList = filterList.filter(
      prop => PM_SPECIAL_PROPS.indexOf(prop) === -1
    )
  }

  return { ...filter(props, filterList) }
}

/**
 *
 * @param {string} platform
 * @param {string} type
 * @param {Object} parentProps
 * @param {Object} props
 */
export default function getTemplateConnectionData(
  platform,
  type,
  parentProps,
  props
) {
  // Apply a filter function to all props passed to the connector Component
  // and only let those pass that are valid for given template type.
  const templateProps = filterPropsForTemplateProps(platform, type, {
    ...parentProps,
    ...props,
  })

  // This line is also crucial to start observing of env!
  const env = toJS(parentProps.env)
  const customLocalStore = parentProps.customLocalStore
  const projectStore = parentProps.projectStore || props.projectStore
  const pageStore = parentProps.pageStore || props.pageStore

  const content = getContentForType(type, props)

  const info = new InfoProvider({
    content,
    env,
    customLocalStore,
    projectStore,
    pageStore,
  })

  return {
    // parentProps is most likely unnecessary, just keeping it to ensure
    // everything works fine still
    ...parentProps,

    ...templateProps,
    env,
    info,
  }
}
