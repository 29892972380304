import { toNumber as _toNumber } from 'lodash'
import { Store } from '../../shared/store'
import { templateStore as articleTemplateStore } from '../../template/reducers'
import { widgetTemplateStore } from '../../widgetTemplate/reducers'
import { articleStore } from '../../article/reducers'
import { widgetStore } from '../../widget/reducers'
import Page from '../model'

const contentStores = {
  article: articleStore,
  widget: widgetStore,
}

const templateStores = {
  article: articleTemplateStore,
  widget: widgetTemplateStore,
}

/**
 * Command for loading a rendered page with all it's data.
 */
export default class LoadRenderedPageCommand {
  /**
   * @param {PageStore} pageStore - The store this command is executed in.
   * @param {String | Number} id - The id of the page
   * @param {Object} pageData - The page data
   */
  constructor(pageStore, id, pageData = {}) {
    this.pageStore = pageStore
    this.pageId = id
    this.pageData = pageData
  }

  /**
   * Validates the command.
   * @private
   */
  validate() {
    if (!this.pageStore || !(this.pageStore instanceof Store)) {
      throw new Error(`${this.constructor.name} requires a 'pageStore' property
      of type 'Store'`)
    }

    // tries to convert the pageId from router params to a number
    this.pageId = _toNumber(this.pageId)

    if (!this.pageId || typeof this.pageId !== 'number') {
      throw new Error(`${this.constructor.name} requires a 'pageId' property
      of type 'Number'`)
    }
  }

  /**
   * Executes the command.
   */
  exec() {
    this.validate()

    let promise

    if (this.pageData && this.pageData.items) {
      promise = Promise.resolve(this.pageData)
    }
    else {
      // eslint-disable-next-line
      console.warn(
        'While rendering the page, the `LoadRenderedPageCommand` detected '
          + 'that the given page data does not conatin rendered `items`. '
          + 'Thus it will try to render the page by calling the remote renderer. '
          + 'This is not expected to happen (as of now). '
          + 'There is something wrong with the input data most likely.'
      )
    }

    return promise
      .then(pageData => this.ensurePageItemsInCollections(pageData))
      .then(pageData => this.ensurePageItemTemplatesInCollections(pageData))
      .then(pageData => this.setRenderedPageTemplateOnModel(pageData))
  }

  /**
   * Ensures that all the {@see ContentItemData} `items` are actually in their
   * respective collections.
   * @param {RenderedPageData} pageData - The data of the rendered page.
   * @returns Promise
   * @private
   */
  ensurePageItemsInCollections(pageData) {
    const pageItems = this.createFlatItemArray(pageData)

    return Promise.all(
      pageItems.map((item) => {
        const store = contentStores[item.type]
        let model = store.getById(item.id)
        if (!model) {
          model = store.createModel(item.data)
          store.add(model)
        }
        else {
          model.set(item.data)
        }
        return true
      })
    ).then(() => pageData)
  }

  createFlatItemArray(parent) {
    if (parent && parent.items) {
      return parent.items.reduce((memo, item) => {
        memo.push(item, ...this.createFlatItemArray(item.data))
        return memo
      }, [])
    }
    return []
  }

  /**
   * Ensures that all the items templates are actually in their
   * respective collections.
   * @param {RenderedPageData} pageData - The data of the rendered page.
   * @returns Promise
   * @private
   */
  ensurePageItemTemplatesInCollections(pageData) {
    return Promise.all(
      Object.keys(pageData.itemTemplates).reduce((flatMap, type) => {
        const templates = pageData.itemTemplates[type]
        const currTemplateStore = templateStores[type]

        flatMap.concat(
          Object.keys(templates).map((templateId) => {
            const template = templates[templateId]
            let model = currTemplateStore.getById(templateId)
            if (!model) {
              model = currTemplateStore.createModel(template)
              currTemplateStore.add(model)
            }
            return currTemplateStore.loadTemplateModule(template.shortcut)
          })
        )

        return flatMap
      }, [])
    ).then(() => pageData)
  }

  /**
   * Sets the page data on the respective page model.
   * @param {RenderedPageData} pageData - The data of the rendered page.
   * @private
   */
  setRenderedPageTemplateOnModel(pageData) {
    const page = this.pageStore.getById(this.pageId)
    pageData.contentTemplatesLoaded = true

    log.tmplLoader('PageTemplate completely loaded')

    // if it is already a model, then the 'set' has already been done
    if (page && !(pageData instanceof Page)) {
      page.set(pageData)
    }
  }
}

/**
 * @typedef {Object} RenderedPageData
 * @property {String} content - The HTML of the rendered page.
 * @property {Object} templateData - The data of the template's gird rows and
 * grid blocks.
 * @property {Object} templateData.gr<i> - The data in grid row i
 * @property {Array<GridBlockEntryData>} templateData.gr<i>.gb<j> - The data
 * in grid row i, grid block j
 * @property {Array<ContentItemData>} items - A (flat) array of all items in the
 * page.
 * @property {Object} itemTemplates - List of templates
 * @property {Object} itemTemplates.article - List of article templates
 * @property {Object} itemTemplates.article.<templateId> - The complete article
 * template data for article template with id <templateId>
 *
 */

/**
 * @typedef {Object} ContentItemData
 * @property {Number} id - The id of the item.
 * @property {String} type - The type of the item. One of 'article' or 'widget'
 * @property {Number} templateId - The id of the template for this item.
 * @property {Object} data - The complete data of the item.
 */

/**
 * @typedef {Object} GridBlockEntryData
 * @property {Number} id - The id of the item.
 * @property {String} type - The type of the item. One of 'article' or 'widget'
 */
