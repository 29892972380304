import { closest } from './dom-helpers'
import ScribeHelper from '../plugins/scribe-helper'
import HTMLJanitor from '../customized/html-janitor'
import htmlSplit from '../../../shared/html-split'

const INVISIBLE_CHAR = '\uFEFF'

export default
class BaseCommandImpl {

  static INVISIBLE_CHAR = INVISIBLE_CHAR

  static defaultData = {}
  static defaultOpts = {
    invisibleChar: INVISIBLE_CHAR
  }
  static commandName = null

  constructor(scribe, cmd, opts = {}) {
    this.cachedContent = null
    this.scribe = scribe
    this.cmd = cmd
    this.opts = {
      ...this.constructor.defaultOpts,
      ...opts
    }
    this.initialData = this.getInitialData()
  }

  executeCommand(commandName, data) {

    // this is needed to ensure the .then is executed for sure
    const command = this[commandName || this.constructor.commandName]
    const args = [data]

    return command.call(this, ...args)

  }

  cacheSelection() {

    this.ensureSelectionIsInContenteditable()

    this.beforeCacheSelection()

    const selection = new this.scribe.api.Selection()

    selection.placeMarkers()

    // Due to the asynchronus nature of many commands, it is very likely
    // that scribes formatting will kick in before the command was applied
    // (this happens for example once the TransactionManager runs, which
    // pushes the editor state onto the stack and therefor removes all
    // selection markers). Thus we have to cache the editor contents
    // ourselves and restore them afterwards.
    this.helper = ScribeHelper.create(this)

    this.helper.cloneContentsFromTarget()

    const anchorNode = this.getAnchorNodeInSelection(selection)
    this.initialData = this.getInitialData(anchorNode)

  }

  beforeCacheSelection() {}

  /**
   * Removes **all** markers.
   * @private
   */
  removeMarkers() {
    const markers = this.scribe.el.querySelectorAll('em.scribe-marker')
    Array.prototype.forEach.call(markers, (el) => {
      el.parentNode.removeChild(el)
    })
  }

  /**
   * Merges multiple consecutive markers into one.
   * @private
   */
  cleanSelectionMarkers() {
    this.scribe.el.innerHTML = this.scribe.el.innerHTML
    .replace(
      /(<em class="scribe-marker" style="display: none;"><\/em>)+/g,
      '<em class="scribe-marker" style="display: none;"></em>'
    )
  }

  /**
   * Gets the range marked by the current selection's markers.
   */
  getRangeOfSelection() {
    const selection = new this.scribe.api.Selection()
    selection.selectMarkers(true)

    // it's necessary to use selection.selection here, as
    // selection.range does not get updated automatically
    return selection.selection.rangeCount
    ? selection.selection.getRangeAt(0)
    : null
  }


  /**
   * @private
   */
  getSelectionContents() {
    const range = this.getRangeOfSelection()
    return range
    ? range.cloneContents()
    : null
  }


  /**
   * @private
   */
  selectContent() {

    // remove markers that follow on eachother immediately
    const markers = this.scribe.el.querySelectorAll('.scribe-marker+.scribe-marker')
    Array.prototype.forEach.call(markers, (marker) => {
      // onyl remove the marker if the previous element is not a text node
      if (marker.previousSibling.nodeType !== Node.TEXT_NODE) {
        marker.parentNode.removeChild(marker)
      }
    })

    let selection = new this.scribe.api.Selection()

    if (!selection.selection.rangeCount) {
      selection.selectMarkers(true)
      selection = new this.scribe.api.Selection()
    }

    // if there is no selection, create one of the complete
    // editor
    // if (!selection.range) {
    //   selection.removeMarkers()
    //   const range = document.createRange()
    //   range.selectNodeContents(this.scribe.el)
    //   selection.placeMarkers()
    // }
    // else {
    //   selection.range.selectNodeContents(this.scribe.el)
    // }

  }



  /**
   * @private
   */
  insertHTML(html: string|Node) {



    // select the current markers
    let selection = new this.scribe.api.Selection()

    // if there is a range in the selection,
    // remove it's contents and place the
    // new markers behind it
    if (selection.range) {

      // This is the really tricky part:
      // 1. document.execCommand('insertHTML') inserts some elements we don't
      // want, also it splits parent nodes at the given position apart and (at
      // least in Chrome) it adds weird spans elements with inline font styles
      // around elements following the selection.
      // 2. the current solution properly inserts the elements, yet it does not
      // enure that for example p tags are not insert into existing p tags

      // utilize scribes formatter to get the propper html
      const formatted = this.scribe._htmlFormatterFactory.format(html)
      let contentHelper = this.prepareHTMLForInsert(formatted)
      let insertionHelper = document.createElement('p')

      // contents HAVE to be removed because insertNode does not replace contets
      selection.range.extractContents()
      // insert the insertion helper as an anchor to work from
      selection.range.insertNode(insertionHelper)

      if (insertionHelper) {
        const parent = insertionHelper.parentNode

        // ad the selection start marker
        parent.insertBefore(this.createMarker(), insertionHelper)
        while(contentHelper.firstChild) {
          parent.insertBefore(contentHelper.firstChild, insertionHelper)
        }

        // ad the selection start marker
        parent.insertBefore(this.createMarker(), insertionHelper)

        // remove the now superfluos insertion helper
        parent.removeChild(insertionHelper)
      }


      // this.moveNestedPTags(this.scribe.el.querySelectorAll('p p'))

      // this.moveMarkersOutOfEmptyElements(selection)

      // selection.range = range
      // selection.placeMarkers()
      // selection.selectMarkers(true)

      // this.joinBoundaries()

    }

  }

  moveNestedPTags(nestedPs) {

    // if we ended up with nested p tags,
    // unwrap the deeper ones (this should actually never happen)
    Array.prototype.forEach.call(nestedPs, p => {
      while (p.firstChild) {
        p.parentNode.insertBefore(p.firstChild, p)
      }
      p.parentNode.removeChild(p)
    })

    nestedPs = this.scribe.el.querySelectorAll('p p')
    if (nestedPs.length) {
      moveNestedPTags(nestedPs)
    }

  }

  joinBoundaries() {
    const {range} = new this.scribe.api.Selection()
    const start = range.startContainer.childNodes[range.startOffset - 1]
    const end = range.endContainer.childNodes[range.endOffset]


    if (start.tagName === end.tagName) {
      // move anything between start and end into the start container and
      // then also everything within the end container


    }

    return null
  }

  prepareHTMLForInsert(html: string|Node) {
    // const insertHTMLCommand = this.scribe.getCommand('insertHTML')

    // for whatever reason document.execCommand as well as
    // scribes implementation of it worked, so we are using
    // a custom insert content algorithm for now
    // The native methods always removed the original content,
    // but did not insert the new html
    let frag = document.createElement('div')

    // allow the use of plain html strings, create a
    // DocumentFragment from them
    if (html instanceof Node) {
      frag.appendChild(html)
    } else {
      frag.innerHTML = html
    }

    return frag
  }

  moveMarkersOutOfEmptyElements(selection) {
    const markers = selection.getMarkers()

    // if our markers are in empty tags, remove those tags
    if (markers && markers.length) {

      markers.forEach((marker) => {

        if (
          marker &&
          marker.parentNode &&
          this.scribe.el.contains(marker.parentNode) &&
          marker.parentNode !== this.scribe.el &&
          !marker.parentNode.textContent
        ) {
          marker.parentNode.parentNode.insertBefore(
            marker,
            marker.parentNode
          )
          marker.parentNode.parentNode.remove(
            marker.parentNode
          )
        }
      })

    }
  }


  restoreSelection() {
    return new Promise((resolve) => {
      const selection = new this.scribe.api.Selection()

      this.helper.use().focus()

      setTimeout(() => {
        selection.selectMarkers(true)
        resolve()
      }, 0)
    })

  }

  finishCommand({ doFormatting } = {}) {
    if (!doFormatting) {
      this.scribe._skipFormatters = true
    }
    if (this.helper) {
      this.helper.applyToTarget({ doFormatting }).destroy()
    }
    this.scribe.el.focus()
    const selection = new this.scribe.api.Selection()
    selection.selectMarkers()
    if (!doFormatting) {
      this.scribe._skipFormatters = false
    }
  }


  ensureSelectionIsInContenteditable() {

    const selection = new this.scribe.api.Selection()
    const editable = selection.getContaining(node => (
      node.hasAttribute && node.hasAttribute('contenteditable')
    ))

    if (editable) {
      const range = document.createRange()
      range.selectNodeContents(editable)

      selection.selection.removeAllRanges()
      selection.selection.addRange(range)
    }

  }


  /**
  * @private
  * @param {scribe.Selection} selection The selection to try to find the
  * anchor node in.
  * @return {Node} The DOM node within the selection, that's a tag of tye
  * defined in the cmd.nodeName property.
  */
  getAnchorNodeInSelection(selection) {
    return selection.getContaining(node =>
      node.nodeName === this.cmd.nodeName
    )
  }


  getAnchorNodeAroundSelection() {
    const selection = new this.scribe.api.Selection()
    const parent = selection.range
      ? selection.range.commonAncestorContainer
      : null
    let anchor = null

    if (parent) {
      anchor = closest(parent, this.cmd.nodeName)
    }

    return anchor
  }

  /**
  * @private
  * @param {scribe.Selection} selection The selection to ensure the anchor
  * node in.
  * @return {Node}           The anchor node.
  */
  ensureAnchorInSelection(selection, opt = {}) {
    let anchor = this.getAnchorNodeInSelection(selection)

    if (anchor) {
      return anchor
    }
   
    // Section below if attempting to find the anchor programatically
    if (selection.selection.anchorNode.nodeType === Node.TEXT_NODE) {
      anchor = selection.selection.anchorNode.parentNode
    }
    else if (selection.selection.anchorNode.nodeType === Node.ELEMENT_NODE) {
      // Happens when the cursor is on a position after a blank character without any text marked
      anchor = selection.selection.anchorNode.firstElementChild
      // support 2+ links in a single Node by picking the last one
      if (opt.type && opt.value) {
        selection.selection.anchorNode.childNodes.forEach((el) => {
          // type ~ href, value ~ www.blah.com
          if (opt.type === 'href' && el[opt.type]) {
            // trim extra '/' that can cause matching failures
            const elItem = el[opt.type].slice(-1) === '/' ? el[opt.type].slice(0, -1) : el[opt.type]
            const passIn = opt.value[opt.value.length] === '/' ? opt.value.slice(0, -1) : opt.value
            if (elItem === passIn) {
              anchor = el
            }
          }
          else if (el[opt.type] === opt.value) {
            anchor = el
          }
        })
      }
    }

    return anchor
  }


  /**
  * @private
  * @param  {Node} anchor The anchor to be selected.
  */
  selectAnchor(anchor) {
    const selection = new this.scribe.api.Selection()
    selection.range.setStartAfter(anchor)
    selection.range.setEndAfter(anchor)

    selection.selection.removeAllRanges()
    selection.selection.addRange(selection.range)
    selection.selection.collapseToEnd()

    this.focusEditable(selection)
  }

  /**
  * @private
  * @param  {scribe.Selection} selection The selection containing the
  * element to be focused
  */
  focusEditable(selection) {
    let el = closest(selection.range.commonAncestorContainer, '[contenteditable]')[0]

    if (!el) {
      el = selection.getContaining(node =>
        node.hasAttribute && node.hasAttribute('contenteditable')
      )
    }

    if (el) {
      el.focus()
    }
  }

  /**
  * @private
  * @return {Object} The data found in any pre-existing element,
  * or a default object if no pre-existing data was found.
  */
  getInitialData(/* anchorNode */) {
    return {...this.constructor.defaultData}
  }

  isTextNode(node) {
    return this.scribe.node.isText(node)
  }


  createMarker() {
    var node = document.createElement('em')
    node.style.display = 'none'
    node.classList.add('scribe-marker')
    return node
  }


  createInvisibleCharTextNode() {
    return document.createTextNode(
      this.opts.invisibleChar !== undefined
      ? this.opts.invisibleChar
      : BaseCommandImpl.INVISIBLE_CHAR
    )
  }
}
