
import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import classNames from 'classnames'
import { FormattedHTMLMessage } from '../../../translations'


export default class Target extends Component {
  static propTypes = {
    style: PropTypes.object,
    children: PropTypes.node,
    onDrop: PropTypes.func.isRequired,
    canDrop: PropTypes.bool,
    isDragging: PropTypes.bool,
  };

  render() {
    const { isDragging, canDrop } = this.props

    let dropAllowed = undefined

    let color = 'transparent'

    // we are dragging and we are over a valid drop target
    if (isDragging && canDrop) {
      color = 'rgba(0, 255, 80, 0.5)'
      dropAllowed = true
    }
    // we cannot drop
    else if (isDragging && !canDrop) {
      color = 'rgba(255, 80, 0, 0.5)'
      dropAllowed = false
    }

    return (
      <div
        className={classNames('positionable-target', {
          'positionable-dragging': this.props.isDragging,
          'positionable-drop-allowed': dropAllowed,
        })}
        style={{
          ...this.props.style,
          position: 'relative',
          outline: `4px solid ${color}`,
        }}
      >
        {this.props.children}
        {dropAllowed === false ? (
          <div
            className={classNames(
              'positionable-drop-not-allowed'
            )}
          >
            <FormattedHTMLMessage id="positionable.drop-not-allowed" />
          </div>
        ) : null}
      </div>
    )
  }
}
