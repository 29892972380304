exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".src-app-components-AppDevTools__appDevTools___3Dbc9{bottom:0;left:0;margin-bottom:0;padding:5px;position:absolute;z-index:3000}.src-app-components-AppDevTools__appDevTools___3Dbc9 button{margin:0}\n", "", {"version":3,"sources":["/builds/GENEVA/wm/frontend/src/app/components/AppDevTools.scss"],"names":[],"mappings":"AAAA,qDACE,SACA,OACA,gBACA,YACA,kBACA,YAAa,CANf,4DASI,QAAS,CACV","file":"AppDevTools.scss","sourcesContent":[".appDevTools {\n  bottom: 0;\n  left: 0;\n  margin-bottom: 0;\n  padding: 5px;\n  position: absolute;\n  z-index: 3000;\n\n  button {\n    margin: 0;\n  }\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"appDevTools": "src-app-components-AppDevTools__appDevTools___3Dbc9"
};