import { Atom, computed } from 'mobx'
import { isEqual } from 'lodash'
import { i18n } from '../../shared/utils'
import { deepGet, deepSet } from '../../shared/obj'

// type TPhId = string | number

export default class Placeholder extends Atom {
  /**
   * Same as `Placeholder#toJSON()` but as a `@computed` getter, thus allowing
   * to listen for changes when using it.
   */
  @computed get asJSON() {
    this.reportObserved()
    return this.toJSON()
  }

  /**
   * Creates a new Placeholder Atom.
   */
  constructor(phId, value, lang, parentPlaceholderAccessor) {
    // Call the Atom constructor with a speaking name
    super(
      `Placeholder ${phId} on Article "${parentPlaceholderAccessor.article.name}" [${parentPlaceholderAccessor.article.id}]`
    )

    this.phId = phId
    this.value = value
    this.defaultLang = lang
    this.placeholderAccessor = parentPlaceholderAccessor
  }

  /**
   * Transforms the data of the Placeholder into JSON format.
   */
  toJSON() {
    return this.value
  }

  /**
   * This is the same as `Placeholder#get()` with the sole
   * difference being that the function call will not be observed.
   * @param {String?} key - The key path within the object to the
   * value that should be returned. If no path is given 'value' will be used.
   * @param {String?} lang - The language to receive the value in. If no
   * language is given, `Placeholder#defaultLang` will be used
   * @return {Any} - The value at given key. `undefined` if not set.
   */
  steal(key, lang) {
    lang = lang || this.defaultLang
    key = key || this.getKey()

    let retval = i18n(this.value, key, lang)

    if (!retval && (key === 'value.h' || key === 'value.w')) {
      // TODO: remove once the hh/ww issue is resolved
      retval = i18n(
        this.value,
        key === 'value.h' ? 'value.hh' : 'value.ww',
        lang
      )
    }

    return retval
  }

  /**
   * Retreives the value at given key path for given language.
   * @param {String?} key - The key path within the object to the
   * value that should be returned. If no path is given 'value' will be used.
   * @param {String?} lang - The language to receive the value in. If no
   * language is given, `Placeholder#defaultLang` will be used
   * @return {Any} - The value at given key. `undefined` if not set.
   */
  get(key, lang) {
    this.reportObserved()

    const retval = this.steal(key, lang)

    return retval
  }

  getSource() {
    return this.value && this.value.source
  }

  getEditorState(lang) {
    lang = lang || this.defaultLang
    return i18n(this.value, 'editorState', lang)
  }

  /**
   *
   * @param {String?} key
   * @param {any?} valueObj
   * @param {String?} lang
   */
  set(key, valueObj, lang) {
    lang = lang || this.defaultLang
    key = key || this.getKey()

    if (key.indexOf('hh') >= 0 || key.indexOf('ww') > 0) {
      // eslint-disable-next-line
      debugger;
    }

    if (valueObj === null) {
      valueObj = { value: null }
    }
    else if (typeof valueObj === 'string') {
      valueObj = { value: valueObj, type: 'text' }
    }
    else if (typeof valueObj === 'object' && !('value' in valueObj)) {
      valueObj = { value: valueObj, type: 'keyValue' }
    }

    let newValue = valueObj.value
    const newSource = valueObj.source || null
    const newType = valueObj.type
    const newEditorState = valueObj.editorState
    let oldValue = i18n(this.value, key, lang)
    const oldSource = this.getSource() || null
    const oldEditorState = i18n(this.value, 'editorState', lang)

    // BAIL OUT EARLY if placeholder types missmatch
    if (
      newType
      && this.value
      && this.value.type
      && newType !== this.value.type
    ) {
      // eslint-disable-next-line max-len
      log.placeholder(
        `Placeholder.set(): You are trying to change the placeholder type from "${this.value.type}" to "${newType}". Changing the type is not allowed => skipping!"`
      )
      return this
    }

    // BAIL OUT EARLY if new value would be the same at the previous one
    if (isEqual(oldValue, newValue) && isEqual(oldSource, newSource) && isEqual(oldEditorState, newEditorState)) {
      // eslint-disable-next-line max-len
      log.placeholder(
        `Placeholder.set(): old value equals new value ("${newValue}") => skipping!`
      )
      return this
    }

    oldValue = this.value
    if (oldValue) {
      // BAIL OUT EARLY if new value would be the same at the previous one
      if (isEqual(deepGet(oldValue, `i18n.${lang}`, undefined), newValue)) {
        // eslint-disable-next-line max-len
        log.placeholder(
          `Placeholder.set(): old value equals new value ("${newValue}") => skipping!`
        )
        return this
      }

      key = key.replace('value.', '')
      deepSet(this.value, `i18n.${lang}${key ? `.${key}` : ''}`, newValue)
      this.value.source = newSource

      // never allow changing the value once it was set
      if (!this.value.type) {
        this.value.type = newType
      }
    }
    else {
      newValue = i18n({}, key, lang, newValue || '')
      newValue.type = newType
      this.value = newValue
      this.value.source = newSource
    }

    // undefined for scribe editor
    // null or array for draft editor
    if (valueObj.editorState || valueObj.editorState === null) {
      deepSet(this.value, `i18n.${lang}.editorState`, valueObj.editorState)
    }

    this.reportChanged()
    this.placeholderAccessor.reportChanged()
    this.placeholderAccessor.article.saveWithDebounce({ noBackendSet: true })

    return this
  }

  destroy() {
    this.value = null
    this.placeholderAccessor.reportChanged()
    this.reportChanged()
  }

  /**
   *
   * @param {String?} key
   * @param {String?} lang
   */
  isEmpty(key = this.getKey(), lang = this.defaultLang) {
    const value = i18n(this.value, key, lang)
    return !value
  }

  getKey() {
    const type = this.getType()
    switch (type) {
      case 'image':
        return 'value.src'
      case 'video':
        return 'value'
      default:
      case 'text':
        return 'value'
    }
  }

  /**
   * Get's the type of given placeholder.
   */
  getType() {
    return (this.value && this.value.type) || null
  }

  setType(type) {
    if (this.value) {
      this.value.type = type
    }
    return this
  }
}
