import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { transformToLinkAttributes } from '../../../shared/link-helper'

export default function connectLinkableToContext(Linkable, parentProps) {
  if (!parentProps.articlePlaceholders) {
    // eslint-disable-next-line max-len
    throw new Error(
      "`connectLinkableToContext` requires an articlePlaceholders Object in it's parentProps"
    )
  }

  @observer
  class PMContextConnectedLinkable extends Component {
    static transformToLinkAttributes(props) {
      return transformToLinkAttributes(props)
    }

    static propTypes = {
      pid: PropTypes.string.isRequired,
      value: PropTypes.object,
      children: PropTypes.node,
    };


    render() {
      let value
        = this.props.value || parentProps.articlePlaceholders.get(this.props.pid)

      if (value) {
        value = transformToLinkAttributes(value)
      }

      return (
        <Linkable {...this.props} onChange={this.handleChange} value={value}>
          {this.props.children}
        </Linkable>
      )
    }
  }

  return PMContextConnectedLinkable
}
