import { Atom, transaction, toJS } from 'mobx'
import { isEqual } from 'lodash'
import { i18n } from '../../shared/utils'

// eslint-disable-next-line no-undef
// interface IPlaceholder {
//   type: string,
//   i18n: Object
// }

export default class KeyValueAccessor {
  /**
   *
   * @param {Object} widget
   * @param {(string|null)?} lang
   */
  constructor(widget, lang = null) {
    this.atom = new Atom(`KeyValueAccessor for Widget ${widget.id}`)

    this.widget = widget
    this.keyValue = widget.keyValue

    this.defaultLang = lang || this.widget.createdIso

    if (!this.defaultLang) {
      // eslint-disable-next-line max-len
      throw new Error(
        `Could not initialize KeyValueAccessor for Widget ${widget.id}. Make sure 'new KeyValueAccessor()' always get's passed a language as second argument or the widget has a createdIso property`
      )
    }
  }

  get length() {
    const values = this.get()
    const keys = Object.keys(toJS(values)).filter(
      key => values[key] !== undefined
    )
    return keys.length
  }

  /**
   *
   * @param {string?} key
   * @param {any?} value
   * @param {string?} lang
   * @returns {KeyValueAccessor}
   */
  set(key, value, lang) {
    const provider = toJS(this.keyValue)

    lang = lang || this.defaultLang
    key = key || this.getKey(value)

    // BAIL OUT EARLY if new value would be the same at the previous one
    let newValue = value
    const oldValue = this.get(key, lang)
    if (isEqual(oldValue, newValue)) {
      return this
    }

    newValue = i18n({}, 'value', lang, {
      ...i18n(provider, 'value', lang),
      [key]: value,
    })

    this.keyValue.merge(newValue)

    this.widget.saveDebounced()
    this.atom.reportChanged()

    return this
  }

  /**
   *
   * @param {String?} key
   * @param {String?} lang
   */
  get(key, lang) {
    this.atom.reportObserved()

    const provider = toJS(this.keyValue)

    lang = lang || this.defaultLang

    key = key ? `value.${key}` : 'value'

    return i18n(provider, key, lang)
  }

  // eslint-disable-next-line no-undef
  getKey(/* phValue: Object*/) {
    return 'value'
  }

  /**
   * Removes given placeholder.
   *
   * @param {String} key
   * @returns {KeyValueAccessor}
   */
  remove(key) {
    this.set(key, null)

    return this
  }

  /**
   * Checks if given placeholder is empty.
   *
   * @param {String?} key
   * @param {String?} lang
   * @returns {boolean}
   */
  isEmpty(key, lang) {
    const val = this.get(key, lang)

    let isEmpty = !val

    if (!isEmpty) {
      // for texts interprete empty lines (aka <br />) as empty
      if (typeof val === 'string') {
        isEmpty = !val
          .replace(/<br\s*\/?>/g, '')
          .replace(/<p>\s*<\/p>/g, '')
          .trim().length
      }
    }

    return isEmpty
  }

  /**
   * Returns the data in the given placeholder as and object as expected
   * by react's dangerouslyInsertInnerHTML.
   *
   * @param {string?} key
   * @param {string?} lang
   * @returns {Object}
   */
  html(key, lang) {
    return {
      __html: this.get(key, lang),
    }
  }

  /**
   * Executes given function in a transaction.
   *
   * @param {(self: KeyValueAccessor) => void} transactionCallback
   * @returns {KeyValueAccessor}
   */
  transaction(transactionCallback) {
    transaction(() => transactionCallback(this))

    return this
  }
}
