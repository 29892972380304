import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { autobind } from 'core-decorators'

import { dispatcher } from '../../../../../shared/lib/command'

@dispatcher
@observer
export default
class RemoveArticleToPageConnectedTarget
  extends Component {

  static propTypes = {
    disabled: PropTypes.bool,
    onGetContext: PropTypes.func,
    page: PropTypes.object,
    Target: PropTypes.func.isRequired,
    viewModels: PropTypes.shape({
      currentlyOpenWidget: PropTypes.shape({
        item: PropTypes.object,
      })
    })
  }

  getDataForRemoveContentFromWidgetCommand() {
    return {
      commands: this.props.commands,
      context: this.getContext(),
      widgetId: this.props.viewModels.currentlyOpenWidget.data.item.id,
    }
  }

  getContext() {
    const { onGetContext } = this.props
    let context = {}

    if (onGetContext) {
      const tmpContext = onGetContext()
      if (tmpContext) {
        context = tmpContext
      }
    }

    if (!context.position) {
      throw new Error('Could not determine position for remove action.')
    }

    return context
  }

  @autobind
  handleContentActionTriggered(/* data */) {

    this.context.dispatch(
      this.props.commands.RemoveContentFromWidgetMacroCommand,
      this.getDataForRemoveContentFromWidgetCommand()
    )

  }

  render() {

    const {
      Target,
      page,
      onGetContext,
      disabled,
      viewModels: { currentlyOpenWidget },
      ...rest
    } = this.props

    const widget = currentlyOpenWidget.data.item || {}

    return (<Target
      {...rest}
      disabled={disabled || widget.isUpdating}
      onClick={this.handleContentActionTriggered}
      pageId={page.id}
    />)

  }
}
