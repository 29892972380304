/* eslint-disable */
import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observable, action } from 'mobx'
import { autobind } from 'core-decorators'
import classNames from 'classnames'
import Sortable from 'react-anything-sortable'
import { formatMessage } from '../../../translations'
import { pick } from 'lodash'
import warn from '../../../dev/warn'
import Tools from './Tools'
import ItemTools from './ItemTools'
import Item from './Item'
import SortableItem from './SortableItem'

function warnCBL(id, ...args) {
  return warn(`repeatable.${id}`, ...args)
}

class Repeatable extends Component {
  static propTypes = {
    // required properties
    pid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,

    env: PropTypes.object.isRequired,

    sortable: PropTypes.bool,
    isSorting: PropTypes.bool,
    autofilled: PropTypes.bool,

    disabled: PropTypes.bool,
    tagName: PropTypes.string,

    renderChild: PropTypes.func.isRequired,
    // this is not required, as in the PM for example nothing should
    // happen on change
    onChange: PropTypes.func,
    onSortStart: PropTypes.func,
    onSortEnd: PropTypes.func,

    className: PropTypes.string,

    maxItems: PropTypes.number,

    css: PropTypes.object,

    activeElement: PropTypes.any,
  };

  static defaultProps = {
    sortable: false,
    autofilled: false,
    isSorting: false,
    css: {},
    tagName: 'div',
  };

  /* eslint-disable react/sort-comp */
  @observable internalState = {
    selectedIndex: -1,
  };
  /* eslint-enable react/sort-comp */

  @action
  setInternalState(newState) {
    this.internalState = {
      ...this.internalState,
      ...newState,
    }
  }

  constructor(props) {
    super(props)

    const value = this.validateValue(this.props.value || [])

    this.state = {
      isSorting: this.props.isSorting || false,
      value,
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.state.value) {
      this.setState({
        value: this.validateValue(nextProps.value),
      })
    }

    if (nextProps.selectedIndex !== this.state.selectedIndex) {
      this.setInternalState({
        selectedIndex: nextProps.selectedIndex,
      })
    }
  }

  getEventContext(event) {
    return {
      target: {
        ...event.target,
        type: 'text',
        pid: this.getDataAttribute('pid'),
      },
    }
  }

  getItemToolProps(index) {
    return {
      onRemoveItem: this.handleRemoveItem,
      index,
      css: this.props.css,
    }
  }

  @autobind
  handleSort(newOrder) {
    if (this.props.onChange) {
      const uniqueNewOrder = newOrder.reduce((memo, item) => {
        if (memo.indexOf(item) === -1) {
          memo.push(item)
        }
        return memo
      }, [])

      this.props.onChange({
        target: {
          value: uniqueNewOrder,
          oldValue: this.state.value,
        },
      })
    }
  }

  @autobind
  handleToggleSortableClick() {
    // eslint-disable-next-line react/no-access-state-in-setstate
    const isSorting = !this.state.isSorting

    this.setState({
      isSorting,
    })

    if (isSorting) {
      if (this.props.onSortStart) {
        this.props.onSortStart()
      }
    }
    else {
      if (this.props.onSortEnd) {
        this.props.onSortEnd()
      }
    }
  }

  @autobind
  handleAddItem() {
    const oldArray = this.state.value

    let id = 1

    // if the array is not empty get the max and inc by one
    if (oldArray.length) {
      if (!this.max) {
        this.max = Math.max(...oldArray.map(nr => nr * 1))
      }
      id = this.max + 1
    }

    // cache the max
    this.max = id

    if (!Number.isInteger(id)) {
      warnCBL('index-should-be-number', id)
      return
    }

    const newArray = [id, ...oldArray]

    if (this.props.onChange) {
      this.props.onChange({
        target: {
          id,
          value: newArray,
          oldValue: oldArray,
        },
      })
    }

    // TODO: verify this is not necessary when state
    // management is made by onChange
    else {
      this.setState({ value: newArray })
    }
  }

  @autobind
  handleRemoveItem(id) {
    if (!Number.isInteger(id)) {
      warnCBL('remove-index-number', id)
      return
    }

    // eslint-disable-next-line no-restricted-globals
    if (
      confirm(
        formatMessage({
          id: 'repeatable.confirmRemoveItem',
        })
      )
    ) {
      const oldArray = this.state.value

      const index = oldArray.indexOf(id)

      const newArray = [
        ...oldArray.slice(0, index),
        ...oldArray.slice(index + 1, oldArray.length),
      ]

      if (this.props.onChange) {
        this.props.onChange({
          target: {
            id,
            value: newArray,
            oldValue: oldArray,
          },
        })
      }

      this.setState({ value: newArray })
    }
  }

  @autobind
  handleSelect(selectedIndex) {
    this.setInternalState({
      selectedIndex,
    })
  }

  isCM() {
    return this.props.env.CM
  }

  isSortable() {
    return this.isCM() && this.props.sortable
  }

  isSorting() {
    return this.isSortable() && this.state.isSorting
  }

  validateValue(value) {
    // ===========================================================
    // -- GENEVA V1 fix
    // -----------------------------------------------------------
    // This is a fix for legacy repeatables from Geneva v1,
    // that kept the list in an keyValue object:
    // value.value: String (= '1, 2')
    if (
      typeof value === 'object'
      && 'value' in value
      && typeof value.value === 'string'
    ) {
      // eslint-disable-next-line max-len
      console.warn(
        'Repeatable#IndexList: value given is no array, but legacy object of type `value.value`: "%s"',
        value.value
      )
      if (value.value === '') {
        value = []
      }
      else {
        value = value.value.split(',').map(val => parseInt(val.trim(), 10))
      }
    }
    // ===========================================================

    // TODO: this slice-check is necessary because the array is
    // most likely an mobx observable array. This is not very
    // nice, as the Repeatable should not know about mobx. So the
    // data handed in to it should be mobx-free and toJS should
    // have been called before the data is passed to this
    // Repeatable.
    // There were some issues with toJS, that's why at the moment
    // this workaround was applied. Check if this can be resolved
    // by adding toJS to the connectRepeatabale* modules' render
    // method.
    if (
      !Array.isArray(value)
      && !(value.slice && Array.isArray(value.slice()))
    ) {
      warn('array.noArray', 'Repeatable#IndexList', value)
      value = []
    }

    return value
      .filter((index) => {
        if (!Number.isInteger(index)) {
          warnCBL('render-number', index)
          return false
        }
        return true
      })
      .reduce((memo, index) => {
        if (memo.indexOf(index) > -1) {
          console.warn(
            'Repeatable: The repeatable array was not unique. '
              + `Removed duplicate entry "${index}"`
          )
          return memo
        }
        memo.push(index)
        return memo
      }, [])
  }

  renderChildTools(index) {
    return <ItemTools {...this.getItemToolProps(index)} />
  }

  renderTools() {
    if (this.props.hideTools) {
      return null
    }
    return (
      <Tools
        value={this.state.value}
        env={this.props.env}
        autofilled={this.props.autofilled}
        sortable={this.props.sortable}
        isSorting={this.isSorting()}
        disabled={this.props.disabled}
        maxItems={this.props.maxItems}
        css={this.props.css}
        itemCountLabel={this.props.itemCountLabel}
        addItemButtonLabel={this.props.addItemButtonLabel}
        sortItemButtonLabel={this.props.sortItemButtonLabel}
        onToggleSortableClick={this.handleToggleSortableClick}
        onAddItem={this.handleAddItem}
      />
    )
  }

  renderContent() {
    return this.state.value.map(index => this.renderChild(index))
  }

  renderChild(index) {
    const { pid } = this.props

    const additionalProps = {}

    if (this.isCM()) {
      additionalProps.tools = this.renderChildTools(index)
      additionalProps.toolsProps = this.getItemToolProps(index)
    }

    const key = `${pid}:${index}`

    let item = (
      <Item
        pid={pid}
        key={key}
        index={index}
        activeElement={this.props.activeElement}
        isSorting={this.isSorting()}
        parent={this}
        onSelect={this.handleSelect}
        renderChild={this.props.renderChild}
        {...additionalProps}
      />
    )

    if (this.isSorting()) {
      // This HAS to be here! The react sort anything lib requires the
      // SortableItem the be direct child of sortable!
      item = (
        <SortableItem className="ui-sortable-item" key={key} sortData={index}>
          {item}
        </SortableItem>
      )
    }

    return item
  }

  render() {
    const TagName = this.props.tagName
    const props = {
      className: classNames(this.props.className, 'repeatable-list-container'),
    }
    const items = this.renderContent()
    let content = items
    const tools = this.isCM() ? this.renderTools() : null

    if (this.isSorting()) {
      content = (
        <Sortable onSort={this.handleSort} dynamic>
          {items}
        </Sortable>
      )
    }

    return (
      <TagName {...props}>
        {content}
        {tools}
      </TagName>
    )
  }
}

Repeatable.getItemProps = (props) => {
  return pick(props, ['key', 'onClick', 'repeatableClassName'])
}

export default Repeatable
