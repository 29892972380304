import { createConfirmation } from 'react-confirm'
import ConfirmRemoveFromPageDialog from '../../components/dialogs/ConfirmRemoveFromPage'

const confirm = createConfirmation(ConfirmRemoveFromPageDialog)


export default function (confirmation, options = {}) {
  if (typeof confirmation !== 'string') {
    options = confirmation
    confirmation = ''
  }
  return confirm({ confirmation, options })
}
