import * as PropTypes from 'prop-types'
import React from 'react'
import { withRouter } from 'react-router'

import createParentPropsInjector from '../../../../shared/utils/create-parent-props-injector'
import validate from '../../../../shared/utils/validate-object'

import EditItemCommand from '../../../../shared/commands/EditItemCommand.js'

import PageConnectedEditArticleButton from './components/PageConnectedEditArticleButton'

import DistinctModelObserver from '../../../../shared/view-models/DistinctModelObserver.ts'
import viewModelConnector from '../../../../shared/lib/view-model'

import commandConnector from '../../../../shared/lib/command'

export default
function connectEditArticleToPage(Target, parentProps: Object) {

  validate(parentProps, {
    page: 'object',
    'page.id': ['string', 'number'],
    'page.projectId': ['string', 'number'],
  }, '`connectEditArticleToPage` expects it\'s `parentProps` argument')

  const injectParentProps = createParentPropsInjector(parentProps)

  function EditArticleToPageConnector({
    // props that are NOT passed to the wrapped component
    // other props
    ...rest
  }) {

    return (<PageConnectedEditArticleButton
      {...rest}
      Target={Target}
    />)

  }

  EditArticleToPageConnector.propTypes = {
    router: PropTypes.shape({
      push: PropTypes.func
    }).isRequired,
    disabled: PropTypes.bool,
    page: PropTypes.object,
    onGetContext: PropTypes.func.isRequired,
  }

  return commandConnector({
    EditItemCommand
  })(
    viewModelConnector([{
      class: DistinctModelObserver,
      data: [{
        type: 'widget',
        id: DistinctModelObserver.fromContext('widgetId'),
        as: 'item'
      }],
      as: 'currentlyOpenWidget'
    }])(
      withRouter(injectParentProps(EditArticleToPageConnector))
    ))

}
