import Command, {ICommand, CommandData} from './Command'


export interface MacroCommandData
extends CommandData {
  commands: {
    [key: string]: ICommand
  }
}

export abstract class MacroCommand extends Command {

  public data: MacroCommandData

  protected validateInternally() {
    super.ensureStoreRequirements()
    this.ensureCommands()
  }

  public dispatch(command: ICommand, data: any): Promise<any>|any {
    return this.dispatcher.exec(command, data)
  }

  protected ensureCommands() {
    if (!(
      this.data &&
      this.data.commands &&
      Object.keys(this.data.commands).length
    )) {
      throw new Error(

    `is a MacroCommand and requires some commands! In the component from ` +
    `which you are triggering this command, make sure you pass on the the ` +
    `\`commands\` prop to this command in the dispatcher's data argument object:

    this.context.dispatcher(
      this.props.commands.${this.constructor.name},
      {
        this.props.commands,
        ...yourOtherProps
      }
    )`


      )
    }
  }

}

export {MacroCommand as default}