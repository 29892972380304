import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import { confirmable } from 'react-confirm'
import { autobind } from 'core-decorators'
import { FormattedMessage } from '../../translations'
import { Dialog } from '../../shared/components/Dialog'

const css = require('../styles.scss')

@confirmable
class ScreenshotDialog extends Component {
  static propTypes = {
    show: PropTypes.bool, // indicates if the dialog is shown or not.
    proceed: PropTypes.func, // call to close the dialog with promise resolved.
    cancel: PropTypes.func, // call to close the dialog with promise rejected.
    dismiss: PropTypes.func, // call to only close the dialog.
    confirmation: PropTypes.string, // arguments of your confirm function
    options: PropTypes.shape({
      screenshotLocation: PropTypes.number,
      screenshots: PropTypes.array.isRequired,
    }), // arguments of your confirm function
  };

  constructor(props) {
    super(props)
    this.state = {
      screenshotLocation: this.props.options.screenshotLocation || 0,
    }
  }

  componentDidMount() {
    document.addEventListener('keyup', (e) => {
      if (e.keyCode === 37) this.handleImageSwap('left')
    })
    document.addEventListener('keyup', (e) => {
      if (e.keyCode === 39) this.handleImageSwap('right')
    })
  }

  @autobind
  handleImageSwap(direcion) {
    const { screenshots } = this.props.options
    const location = this.state.screenshotLocation

    if (direcion === 'left' && location !== 0) {
      this.setState({
        screenshotLocation: location - 1,
      })
    }
    if (direcion === 'right' && location !== screenshots.length - 1) {
      this.setState({
        screenshotLocation: location + 1,
      })
    }
  }

  render() {
    const {
      show,
      proceed,
      dismiss,
      options: { screenshots },
    } = this.props
    const { url, text, resolution } = screenshots[
      this.state.screenshotLocation
    ]

    return (
      <Dialog
        className={css.screenshotDialog}
        overlayClassName=""
        isOpen={show}
        title="&nbsp;"
        renderFooter={() => {} /* no footer needed */}
        onAfterOpen={this.handleAfterOpen}
        onRequestClose={this.handleRequestClose}
        onCancel={dismiss}
        onCommit={proceed}
      >
        <img src={url} alt="" />

        <div className="comment-wrapper">
          {text ? (
            <label>
              <FormattedMessage id="review-center.dialog.comment-colon" />
              {text}
            </label>
          ) : null}

          <label className="screen-view">
            <FormattedMessage id="review-center.dialog.screenview" />
            {resolution > 1023 ? 'Desktop' : 'Mobile'}
          </label>
        </div>
      </Dialog>
    )
  }
}
export default ScreenshotDialog
