import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { autobind } from 'core-decorators'
import { formatMessage, FormattedMessage } from '../../../translations'

import Dropdown, { Entry } from '../../../shared/components/Dropdown'
import GenevaDropdownOpener from '../../../ui/components/GenevaDropdownOpener'

@observer
class BranchSelect extends Component {

  static propTypes = {
    item: PropTypes.object,
    dropdownIndicator: PropTypes.bool,

    handleBranchSelection: PropTypes.func,
    handleBranchDeselection: PropTypes.func,
  }

  @autobind
  handleGroupSelection({ target }) {
    if (this.props.handleBranchSelection && target) {
      this.props.handleBranchSelection(this.props.item)
    }
  }

  @autobind
  handleGroupDeselection({ target }) {
    if (this.props.handleBranchDeselection && target) {
      this.props.handleBranchDeselection(this.props.item)
    }
  }

  renderUpdateMessage() {
    const message = formatMessage({ id: 'publicationTree.allSelected' })

    return (
      <div className="dropdownIndicator">
        {message}
      </div>
    )
  }

  renderDropdown(item) {

    return (
      <div className="dropdown-branch">
        <GenevaDropdownOpener
          clickToClose
          arrow
          caret={false}
        />

        <Dropdown>
          <Entry
            onClick={this.handleGroupSelection}
            identifier={item.id}
          >
            <FormattedMessage id="publicationTree-branch-select" />
          </Entry>
          <Entry
            onClick={this.handleGroupDeselection}
            identifier={item.id}
          >
            <FormattedMessage id="publicationTree-node-not-branch" />
          </Entry>
        </Dropdown>
      </div>
    )
  }

  render() {
    const item = this.props.item

    return (<div>
      {item.hasChildren ? this.renderDropdown(item) : ''}
      {(this.props.dropdownIndicator === true)
        ? this.renderUpdateMessage() : ''}
    </div>)
  }
}

export default BranchSelect
