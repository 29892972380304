import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { confirmable } from 'react-confirm'
import { autobind } from 'core-decorators'
import { formatMessage, FormattedMessage } from '../../../translations'

import { Dialog } from '../../../shared/components/Dialog'
import GenevaButton from '../../../ui/components/GenevaButton'

const css = /* typeof window === 'undefined' ? {} : */require('../../styles.scss')
const MIN_PASSWORD_LENGTH = 7

@confirmable
class PasswordDialog extends Component {

  static propTypes = {
    show: PropTypes.bool,            // indicates if the dialog is shown or not.
    proceed: PropTypes.func,         // call to close the dialog with promise resolved.
    cancel: PropTypes.func,          // call to close the dialog with promise rejected.
    dismiss: PropTypes.func,         // call to only close the dialog.
    confirmation: PropTypes.string,  // arguments of your confirm function
    options: PropTypes.object        // arguments of your confirm function
  }

  constructor(props) {
    super(props)
    this.state = {
      oldPassword: '',
      newPassword: '',
      newPasswordConfirm: '',
      isValid: false,
      isMatch: true
    }
  }

  @autobind
  getFocus() {
    return this.focus
  }

  @autobind
  handleRef(el) {
    if (el !== null) {
      this.focus = el
    }
  }

  @autobind
  handleCommit() {
    this.props.proceed({
      ...this.state
    })
  }

  validate(target) {
    const { newPassword, newPasswordConfirm } = this.state
    let { isValid, isMatch } = this.state

    if (target.id === 'newPassword') {
      isMatch = target.value === newPasswordConfirm
      isValid = target.value.length >= MIN_PASSWORD_LENGTH
    }
    else if (target.id === 'newPasswordConfirm') {
      isMatch = target.value === newPassword
    }

    return { isValid, isMatch }
  }

  @autobind
  handleInputChange({ target }) {
    this.setState({
      [target.id]: target.value,
      ...this.validate(target)
    })
  }

  @autobind
  renderFooter() {
    return (
      <div className="grid-content text-right">
        <GenevaButton
          type="button"
          className="small button"
          onClick={this.props.dismiss}
        >
          <FormattedMessage id="simple-dialog.cancel" />
        </GenevaButton>
        <GenevaButton
          type="submit"
          className="small primary button"
          onClick={this.handleCommit}
          disabled={!this.state.isValid || !this.state.isMatch || !this.state.oldPassword}
        >
          <FormattedMessage id="simple-dialog.confirm" />
        </GenevaButton>
      </div>
    )
  }

  render() {

    const {
      show,
    } = this.props

    return (<Dialog
      className={css.passwordDialog}
      overlayClassName={css.tableDialogOverlay}
      isOpen={show}
      title={formatMessage({ id: 'auth.password-change' })}
      focusEl={this.getFocus}
      renderFooter={this.renderFooter}
    >
      <div className="grid-content">

        <label htmlFor="oldPassword">
          <FormattedMessage id="auth.password-old" />
          <input
            id="oldPassword"
            className="oldPassword-input"
            type="password"
            value={this.state.oldPassword}
            onChange={this.handleInputChange}
            ref={this.handleRef}
            autoFocus
          />
        </label>

        <label htmlFor="newPassword">
          <FormattedMessage id="auth.password-new" />
          <input
            id="newPassword"
            className="newPassword-input"
            type="password"
            value={this.state.newPassword}
            onChange={this.handleInputChange}
          />
        </label>

        <label htmlFor="newPasswordConfirm">
          <FormattedMessage id="auth.password-confirm" />
          <input
            id="newPasswordConfirm"
            className="newPasswordConfirm-input"
            type="password"
            value={this.state.newPasswordConfirm}
            onChange={this.handleInputChange}
          />
        </label>

        <div className="text-info">
          { !this.state.isMatch
            ? <FormattedMessage id="auth.password-nomatch" />
            : null}
        </div>

      </div>
    </Dialog>)
  }
}

export default PasswordDialog
