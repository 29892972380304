import * as PropTypes from 'prop-types'

import React, { Component } from 'react'
import classNames from 'classnames'
import { autobind } from 'core-decorators'

import { getHTMLEventsFromProps } from '../../../shared/utils/events'

import Target from './Target'

export default class Container extends Component {
  static propTypes = {
    children: PropTypes.node,
    debug: PropTypes.bool,
    style: PropTypes.object,
    handleChildDrop: PropTypes.func,
  };

  constructor(props) {
    super(props)

    this.state = {
      canDrop: false,
      isDragging: false,
    }
  }

  // only record the drop if in a valid location. Otherwise let the Drop event
  // propigate in the case that something else could do some thing with it
  @autobind
  handleOnDrop(event) {
    if (this.state.canDrop) {
      event.preventDefault()

      const data = event.dataTransfer.getData('positionable')
      if (data) {
        const { pid } = JSON.parse(data)
        if (pid && this.props.handleChildDrop) {
          this.props.handleChildDrop(event, pid)
        }
      }
      this.setState({ isDragging: false })
    }
  }

  @autobind
  handleDragStart() {
    this.setState({ isDragging: true })
  }

  @autobind
  handleDragOver(event) {
    event.preventDefault()
    this.setState({ canDrop: true })
  }

  @autobind
  handleDragEnd(event) {
    this.setState({ isDragging: false })
    event.preventDefault()
  }

  @autobind
  handleDragLeave() {
    this.setState({ canDrop: false })
  }


  render() {
    const {
      className,
      style,
      children,
    } = this.props

    const { canDrop, isDragging } = this.state

    const eventHandlers = getHTMLEventsFromProps(this.props)
    return (
      <Target
        style={style}
        canDrop={canDrop}
        isDragging={isDragging}
      >
        <div
          ref={(ref) => {
            this.props.containerRef(ref)
            this.containerRef = ref
          }}
          {...eventHandlers}
          onDrop={this.handleOnDrop}
          onDragStart={this.handleDragStart}
          onDragEnd={this.handleDragEnd}
          onDragOver={this.handleDragOver}
          onDragLeave={this.handleDragLeave}
          onDragEnter={event => event.preventDefault}
          style={{
            position: 'relative',
          }}
          className={classNames('positionable-container', className)}
        >
          {children}
        </div>
      </Target>
    )
  }
}
