import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { autorun } from 'mobx'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import { autobind } from 'core-decorators'
import PerfectScrollbar from 'perfect-scrollbar'

import { formatMessage, FormattedMessage } from '../../translations'
import { dispatcher } from '../../shared/lib/command'
import ContentLoadingBox from '../../shared/components/ContentLoadingBox'
import { convertDateToUserLocal } from '../../shared/DateConverter'
import GenevaButton from '../../ui/components/GenevaButton'

const css = /* typeof window === 'undefined' ? {} : */ require('../styles.scss')

@dispatcher
@observer
export default class GoogleTranslateBar extends Component {

  static propTypes = {
    articleStore: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)

    const current = this.props.viewModels.currentArticle.data.googleTranslateArticle
    const activePlaceholder = current.meta.translationText
      ? Object.keys(current.meta.translationText).filter(el => el !== 'timestamp')
      : {}

    this.state = {
      isLoading: false,
      isTranslating: false,
      activePlaceholder,
      activeArticle: null,
      activeVersionNumber: current.versionNumber + 1,
      originArticle: current,
      currentArticle: current,
      activeTargetLanguage: current.createdIso,
      activeSourceLanguage: current.createdIso,
      isInTranslationMode: !!current.meta.translationText
    }

    this.ps = null
  }

  componentWillMount() {
    const article = this.state.originArticle
    // Load the source article when it is a copied one
    if (article && article.sourceId) {
      this.handleSourceLanguageChange({
        target: {
          value: this.state.originArticle.sourceId
        }
      })
    }
  }

  componentDidMount() {
    this.ps = new PerfectScrollbar('#googleTranslate-container', {
      suppressScrollX: true
    })

    this.handler = autorun(() => {
      const current = this.state.currentArticle

      // switch mode when XOR between translationText and translation state is true
      if (current
        && current.meta
        && ((current.meta.translationText
        && !this.state.isInTranslationMode)
        || !current.meta.translationText
        && this.state.isInTranslationMode)) {

        this.setState({
          isTranslating: false,
          isInTranslationMode: !this.state.isInTranslationMode
        })

      }
    })
  }

  componentWillUnmount() {
    this.handler()
    this.handler = null
  }

  @autobind
  handleSourceLanguageChange({ target }) {

    const newId = target.value * 1
    let sourceLang
    this.state.currentArticle.languages
      .forEach((lang) => {
        const key = Object.keys(lang)[0]
        if (lang[key] === newId) {
          sourceLang = lang.createdIso
        }
      })

    const newArticle = this.props.articleStore.getById(newId)

    if (!newArticle && this.props.articleStore.canLoad()) {

      this.setState({
        isLoading: true
      })

      this.props.articleStore.load(newId)
        .then((model) => {
          this.setState({
            isLoading: false,
            activeArticle: model,
            activeVersionNumber: model.versionNumber + 1,
            originArticle: model,
            activeSourceLanguage: sourceLang
          })
        })
    }
    else {
      this.setState({
        activeArticle: newArticle,
        activeVersionNumber: newArticle.versionNumber + 1,
        originArticle: newArticle,
        activeSourceLanguage: sourceLang
      })
    }
  }

  @autobind
  handleSourceVersionChange({ target }) {

    const newId = target.value * 1

    // The highest versionNumber is the originArticle
    // therefore set it as active and skip the rest
    if (newId === this.state.originArticle.versionNumber + 1) {
      this.setState({
        activeArticle: this.state.originArticle,
        activeVersionNumber: newId
      })

      return
    }

    // If a version is loaded and set as activeArticle
    // then there is no versionStore and we need to load from the related article
    const versionStore = this.state.activeArticle && this.state.activeArticle.versionStore
      ? this.state.activeArticle.versionStore
      : this.state.originArticle.versionStore

    this.setState({
      isLoading: true
    })

    versionStore.load(newId)
      .then((model) => {
        this.setState({
          isLoading: false,
          activeArticle: model,
          activeVersionNumber: model.id
        })
      })
  }

  @autobind
  handleTargetLanguageChange({ target }) {
    this.setState({
      activeTargetLanguage: target.createdIso
    })
  }

  @autobind
  handleActivePlaceholderChanged({ target }) {
    const activePlaceholder = this.state.activePlaceholder
    if (target.checked) {
      activePlaceholder[target.id] = true
    }
    else {
      delete activePlaceholder[target.id]
    }
    this.setState({ activePlaceholder })
  }

  @autobind
  handleTranslate() {
    const text = {}

    // gather all marked text blocks
    Object.keys(this.contentText).forEach((key) => {
      const el = this.contentText[key]
      if (el.text && this.state.activePlaceholder[el.name]) {
        text[el.name] = el.text
      }
    })

    this.setState({
      isTranslating: true
    })

    this.context.dispatch(
      this.props.commands.GoogleTranslateCommand,
      {
        article: this.state.currentArticle,
        sourceLang: this.state.activeSourceLanguage,
        targetLang: this.state.activeTargetLanguage,
        text
      }
    ).then(() => {
      this.setState({
        isTranslating: false
      })
    })
  }

  @autobind
  handleRemoveTranslation() {
    this.context.dispatch(
      this.props.commands.GoogleTranslateCommand,
      {
        article: this.state.currentArticle,
        sourceLang: this.state.activeSourceLanguage,
        targetLang: this.state.activeTargetLanguage,
        text: {},
        opts: { reset: true }
      }
    )

    this.setState({
      activePlaceholder: {},
      isInTranslationMode: false
    })
  }

  @autobind
  applyTranslation({ target }) {
    const article = this.state.currentArticle
    const data = article.meta.translationText[target.id]

    article.phAccessor.set(
      target.id,
      'value',
      { type: 'text', value: data }
    )
  }

  renderBrand() {
    return <div className="brand-image">
      <img src="/images/google-translate.svg" alt="" />
    </div>
  }

  renderResetBar() {
    const lastTranslationTime = this.state.currentArticle.meta.translationText.timestamp

    return (<div className="reset-bar">
      <div className="grid-content reset-bar-text">
        {formatMessage({ id: 'article.google-translate.reset-bar-text' })}
        <div className="sub-text">
          {convertDateToUserLocal(lastTranslationTime, { barDisplay: true })}
        </div>
      </div>
      <GenevaButton
        className="small button"
        onClick={this.handleRemoveTranslation}
      >
        {formatMessage({ id: 'article.google-translate.new' })}
      </GenevaButton>
    </div>)
  }

  renderPlaceholderContentOverlay(entry) {
    const translationText = this.state.currentArticle.meta.translationText
    const hasTranslation = !!translationText[entry.name]
    const text = hasTranslation ? translationText[entry.name] : entry.text

    if (!hasTranslation) {
      return (<div
        className="behind-overlay"
        key={entry.name}
        id={entry.name}
        dangerouslySetInnerHTML={{
          __html: text
        }}
      />)
    }

    return [
      <div
        className="placeholder-item-translated"
        key={entry.name}
        dangerouslySetInnerHTML={{
          __html: text
        }}
      />,
      <GenevaButton
        key={`${entry.name}button`}
        className="small plain apply button"
        id={entry.name}
        onClick={this.applyTranslation}
      >
        {formatMessage({ id: 'article.google-translate.apply' })}
      </GenevaButton>
    ]
  }

  renderLoader() {
    const messageId = this.state.isTranslating
      ? 'article.google-translate.translating-started'
      : 'article.google-translate.loading-started'

    return (<div className="content-loader-background">
      <ContentLoadingBox className="content-loader"
        message={{
          id: messageId
        }} />
    </div>)
  }

  renderSourceSelection(article) {

    const languages = article.languages || this.state.currentArticle.languages

    // If a version is loaded and set as activeArticle
    // then there is no versionStore and we need to load from the related article
    const versionStore = article.isVersion
      ? this.state.originArticle.versionStore
      : article.versionStore

    const versions = versionStore.collection

    // Skip the first version. Fake version
    const versionList = versions
      .filter(version => version.versionNumber !== 0)

    // Add the article itself to the list
    versionList.push({ ...article, versionNumber: article.versionNumber + 1 })

    // Extend if more languages should be shown as target language
    const targetLanguages = [this.state.activeTargetLanguage]

    return (<div className={classNames(
      'source-selection',
      this.state.isInTranslationMode ? 'disabled' : null
    )}>
      <div className="grid-block">
        <label htmlFor="lang-source">
          <FormattedMessage id="article.translate.lang-source" />
        </label>
        <select
          className="lang-source"
          disabled={this.state.isInTranslationMode}
          id="lang-source"
          onChange={this.handleSourceLanguageChange}
          value={article.isVersion ? article.articleId : article.id}
        >
          {languages
            ? languages.map((lang) => {
              return (<option
                key={lang.id}
                value={lang.id}
              >
                {lang.createdIso}
              </option>)
            })
            : null}
        </select>

      </div>
      <div className="grid-block">

        <label htmlFor="version-source">
          <FormattedMessage id="article.translate.version-source" />
        </label>
        <select
          className="version-source"
          id="version-source"
          disabled={this.state.isInTranslationMode}
          onChange={this.handleSourceVersionChange}
          value={this.state.activeVersionNumber}
        >
          {versionList.map((version) => {
            const number = version.isVersion ? version.id : version.versionNumber
            return (<option
              key={number}
              value={number}
            >
              {number}&nbsp;
              {formatMessage({ id: 'article.translate.version-of' })}&nbsp;
              {convertDateToUserLocal(version.updatedAt, { barDisplay: true })}
            </option>)
          })}
        </select>

      </div>

      <div className="grid-block">

        <label htmlFor="target-lang">
          <FormattedMessage id="article.translate.target-lang" />
        </label>
        <select
          className="target-lang"
          id="target-lang"
          disabled={this.state.isInTranslationMode}
          onChange={this.handleTargetLanguageChange}
          value={this.state.activeTargetLanguage}
        >
          {targetLanguages.map((lang) => {
            return (<option
              key={lang}
              value={lang}
            >
              {lang}
            </option>)
          })}
        </select>

      </div>
    </div>)
  }

  renderPlaceholderContent(entry) {
    return (<div key={entry.name} className="placeholder-item translate">
      <input
        id={entry.name}
        type="checkbox"
        checked={this.state.activePlaceholder[entry.name] || false}
        onChange={this.handleActivePlaceholderChanged}
      />
      <label htmlFor={entry.name}>
        <div
          dangerouslySetInnerHTML={{
            __html: entry.text
          }}
        />
      </label>
    </div>)
  }

  renderSourceContent(article) {
    const channel = article.channelShortcut
    let phA = article.phAccessor
      ? article.phAccessor.asJSON[channel]
      : article.placeholder[channel]
    const content = {}

    // phA always needs to be an object
    if (!phA) {
      phA = {}
    }

    // Copy the content of the placeholders
    // into a simple object without channel and language dependencies
    Object.keys(phA).forEach((entry) => {

      // Only text can be translated
      if (phA[entry].type === 'text') {

        const index = entry.replace('ph', '')

        content[index] = {
          text: article.getDataInPlaceholder(entry),
          name: entry
        }
      }
    })

    this.contentText = content
    return (<div className="grid-content placeholder-list">
      {Object.keys(content).map((entry) => {
        if (!content[entry].text) {
          return null
        }
        if (this.state.isInTranslationMode) {
          return this.renderPlaceholderContentOverlay(content[entry])
        }
        return this.renderPlaceholderContent(content[entry])
      })}

      {!this.state.isInTranslationMode
        ? <div className="send-button">
          <GenevaButton
            className="small button"
            disabled={
              Object.keys(this.state.activePlaceholder).length < 1
            || this.state.activeSourceLanguage === this.state.activeTargetLanguage
            }
            onClick={this.handleTranslate}
            title={formatMessage({ id: 'article.google-translate.sendToGoogle' })}
          >
            {formatMessage({ id: 'article.google-translate.sendToGoogle' })}
          </GenevaButton>
        </div>
        : this.renderBrand()}

    </div>)
  }

  render() {
    let article = this.state.activeArticle

    // Wait until an article is active
    if (!article && this.state.originArticle.sourceId && !this.state.isLoading) {
      return this.renderLoader()
    }
    // use the current one if not copied (no sourceId)
    if (!article && !this.state.originArticle.sourceId && !this.state.isLoading) {
      article = this.state.originArticle
    }

    if (this.ps) {
      this.ps.update()
    }

    return (
      <div
        id="googleTranslate-container"
        className={classNames('grid-block vertical', css.translationTab)}
      >
        {!this.state.isLoading
          ? this.renderSourceSelection(article)
          : null}
        {this.state.isInTranslationMode
          ? this.renderResetBar()
          : null}
        {this.state.isLoading || this.state.isTranslating
          ? this.renderLoader()
          : this.renderSourceContent(article)}
      </div>
    )
  }
}
