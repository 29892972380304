
import * as PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'
import { FormattedHTMLMessage } from '../../../translations'
import * as config from '../../../config'
import Icon from '../../icons'
import { deepGet } from '../../obj'

const css = typeof window === 'undefined' ? {} : require('./styles.scss')

function getErrorInfo(error) {
  let message = error.message
  let stackLines = []

  if (!config.isProduction) {
    let stack
      = deepGet(error, 'originalErr.stack')
      || deepGet(error, 'origError.stack')
      || deepGet(error, 'origError.originalErr.stack')
      || deepGet(error, 'stack')

    message = null

    // Template errors often conatin this origError property
    if (
      error.origError
      && error.origError.message
      && error.origError.message.indexOf('SyntaxError') >= 0
    ) {
      stack = error.origError.message
    }

    if (stack) {
      stackLines = stack.split('\n')
    }
  }

  return {
    message,
    stackLines,
  }
}

function ContentErrorBox(props) {
  const { error, className } = props
  const { message, stackLines } = getErrorInfo(error)

  return (
    <div className={classNames(className, css.contentErrorBox)}>
      <div className="grid-block">
        <div className="grid-block small-2 v-align align-center text-center">
          <Icon size={40} name={error.icon || 'ion-alert-circled'} />
        </div>
        <div className="grid-block vertical">
          <h3>
            <FormattedHTMLMessage
              id={error.id || 'error.content-error'}
              values={{
                templateName: error.templateName,
                message: message || error.message,
                ...error.values,
              }}
            />
          </h3>
          {stackLines.length ? (
            <div className="geneva-error-stack">
              {stackLines.map(line => (
                <div key={line}>{line}</div>
              ))}
            </div>
          ) : null}

          {props.children}
        </div>
      </div>
    </div>
  )
}

ContentErrorBox.defaultProps = {
  error: {},
  className: '',
}

ContentErrorBox.propTypes = {
  error: PropTypes.shape({
    /**
     * This is the id that will be used by the translation framework to
     * determine the translated string
     */
    id: PropTypes.string,
    message: PropTypes.string,
    /**
     * The actual Error object
     */
    origError: PropTypes.object,
    /**
     * Data that's passed to the translation framework
     */
    data: PropTypes.object,
    /**
     * Data that's passed to the translation framework
     */
    values: PropTypes.object,
    /**
     * The name of an icon that can be loaded by the `Icon`
     * component
     */
    icon: PropTypes.string,
  }),
  className: PropTypes.string,
  children: PropTypes.node,
}

export { ContentErrorBox as default }
