import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { autobind } from 'core-decorators'

import { i18n } from '../../shared/utils'
import { deepGet } from '../../shared/obj'

const BI_ID_PREFIX = 'bi_'

export default class BynderIFrame extends Component {

  static propTypes = {
    src: PropTypes.string.isRequired,
    className: PropTypes.string,
    imageSpec: PropTypes.object,
    onChange: PropTypes.func,
    onClose: PropTypes.func,
  }

  @autobind
  handleMessage(event) {
    let messageArray = event && event.data || []

    if (!Array.isArray(messageArray)) {
      messageArray = [messageArray]
    }

    if (this.props.onChange) {

      if (messageArray[0] && messageArray[0].type === 'IMAGE') {

        const images = messageArray.map(img => ({
          id: BI_ID_PREFIX + img.id,
          databaseId: img.databaseId,
          i18n: i18n({}, 'draft.original', undefined, {
            w: deepGet(img, 'files.webImage.width'),
            h: deepGet(img, 'files.webImage.height'),
            src: deepGet(img, 'files.webImage.url')
          })
        }))

        // Disconnect observer
        this.observer.disconnect()

        // Set to prevent observer logic
        this.isClosing = true

        this.props.onChange({
          target: { value: images }
        })
      }

      else if (messageArray[0] && messageArray[0].type === 'VIDEO') {

        const video = messageArray.map(vid => ({
          id: BI_ID_PREFIX + vid.id,
          i18n: i18n({}, 'draft.original', undefined, {
            w: deepGet(vid, 'files.webImage.width'),
            h: deepGet(vid, 'files.webImage.height'),
            src: deepGet(vid, 'files.webImage.url')
          }),
          url: vid.url,
          previewUrls: vid.previewUrls,
        }))

        // Disconnect observer
        this.observer.disconnect()

        // Set to prevent observer logic
        this.isClosing = true

        this.props.onChange({
          target: { value: video }
        })
      }

      else if (messageArray[0] && messageArray[0].type === 'DOCUMENT') {

        const file = messageArray.map(doc => ({
          id: BI_ID_PREFIX + doc.id,
          url: doc.url,
        }))

        // Disconnect observer
        this.observer.disconnect()

        // Set to prevent observer logic
        this.isClosing = true

        this.props.onChange({
          target: { value: file }
        })
      }
    }

  }

  @autobind
  handleLoad() {
    if (this.refIFrame) {
      window.addEventListener('message', e => this.handleMessage(e))

      this.iframeWindow = this.refIFrame.contentWindow

      // Create an observer instance linked to the callback function
      this.observer = new MutationObserver(this.observerCallback)

      // Start observing the target node for childnodes
      this.observer.observe(this.refIFrame.contentDocument, { childList: true, subtree: true })
    }
  }

  @autobind
  observerCallback(mutationsList) {
    // Call close method when watched DOM is destructing
    mutationsList.forEach((mutation) => {
      if (mutation.type === 'childList' && mutation.target.localName === 'body') {
        if (mutation.removedNodes && mutation.removedNodes.length) {
          this.handleClose()
        }
      }
    })
  }

  @autobind
  handleClose() {
    // Timeout to wait for DOM destructing triggered by success
    setTimeout(() => {
      if (this.isClosing) {
        return
      }

      this.isClosing = true

      // Disconnect observer
      this.observer.disconnect()

      if (this.props.onClose) {
        this.props.onClose()
      }
    }, 300)
  }

  render() {
    const { src, type = 'image', imageSpec, multiple, maxImages, projectId, lang, projectName } = this.props

    const language = lang === 'de' ? 'de_DE' : 'en_US'
    const imageCount = multiple ? 'MultiSelect' : 'SingleSelectFile'
    let assetType = type
    if (type === 'file') {
      assetType = 'document'
    }

    return (
      <iframe
        title="Bynder iFrame"
        {...this.props}
        ref={(ref) => {
          this.refIFrame = ref
        }}
        srcDoc={`<!DOCTYPE html>
        <html lang="en">
          <head>
            <meta charset="UTF-8" />
            <title>Bynder Compact View</title>
          </head>
          <body>
            <div id="importedAssets"></div>
            <script src="https://d8ejoa1fys2rk.cloudfront.net/5.0.5/modules/compactview/bynder-compactview-2-latest.js"></script>
            <script>
              BynderCompactView.open({
                portal: {
                  url: "${src}",
                  editable: false
                },
                language: "${language}",
                theme: {
                  colorButtonPrimary: "#3380FF"
                },
                mode: "${imageCount}",
                assetTypes: ["${assetType}"],
                searchTerm: "Andriod",
                onSuccess: function(assets, additionalInfo) {
                  var importedAssetsContainer = document.getElementById(
                    "importedAssets"
                  );
                  importedAssetsContainer.innerHTML = "";

                  var asset = assets[0];
                  window.parent.postMessage(assets, '*');

                }
              });
            </script>
          </body>
        </html>
        `}
        onLoad={this.handleLoad}
      >
      </iframe>
    )
  }

}
