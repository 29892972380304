export default (store, config) => {

  const channels = config.pageTemplates
  const pageTemplates = Object.keys(channels)
    .map((channel) => {
      const pageTemplatesArr = channels[channel]
      return pageTemplatesArr
        .map((item) => {
          return {
            ...item,
            channel
          }
        })
    })
    .reduce((memo, channelPageTemplates) => (
      memo.concat(channelPageTemplates)
    ), [])

  const pageTemplateStore = 'default' in store
    ? store.default.pageTemplate
    : store.pageTemplate

  pageTemplateStore.add(pageTemplates)
  pageTemplateStore.alwaysReloadStyleSheets = false

}
