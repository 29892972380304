import React, { Component } from 'react'
import * as PropTypes from 'prop-types'

class RadioGroup extends Component {
  static propTypes = {
    itemList: PropTypes.array.isRequired,
    handleOnRadioClick: PropTypes.func.isRequired,
    initialChoice: PropTypes.string,
    buttonGroupName: PropTypes.string.isRequired,
    // if the itemList is an array of objects, the key of the prop to use as name
    displayKey: PropTypes.string,
    activeValue: PropTypes.string,
  };

  render() {
    const { itemList, displayKey, buttonGroupName } = this.props

    return (
      <div className="radio-group">
        {itemList.map((item) => {
          let key = item
          if (typeof item === 'object' && displayKey) {
            key = item.id
            item = item[displayKey]
          }

          return (
            <div className="radio-group-item" key={key}>
              <hr />
              <input
                id={key}
                value={item}
                name={buttonGroupName}
                type="radio"
                key={item}
                checked={this.props.activeValue === item}
                onChange={this.props.handleOnRadioClick}
              />
              <label htmlFor={key}>{item}</label>
            </div>
          )
        })}
      </div>
    )
  }
}

export default RadioGroup
