import * as PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'

const EntryWrapper = (props) => {
  return (
    <li
      className={classNames(props.className, {
        disabled: props.disabled,
      })}
    >
      {props.children}
    </li>
  )
}

EntryWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
}

export { EntryWrapper as default }
