import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import { toJS } from 'mobx'
import { observer } from 'mobx-react'
import { autobind } from 'core-decorators'
import classNames from 'classnames'

import { getHTMLEventsFromProps } from '../../../shared/utils/events'
import { getDataAttributes } from '../../../shared/utils/attribs'

export default function connectPositionableToContext(
  Positionable,
  parentProps
) {
  if (!parentProps.articlePlaceholders) {
    // eslint-disable-next-line max-len
    throw new Error(
      "`connectPositionableToContext` requires an articlePlaceholders Object in it's parentProps"
    )
  }


  @observer
  class PMContextConnectedPositionable extends Component {
    static propTypes = {
      pid: PropTypes.string.isRequired,
      maxLen: PropTypes.number,
    };


    @autobind
    handleChange({ target }) {
      const { value } = target

      this.updatePlaceholder(value)
    }

    updatePlaceholder(value) {
      const key = 'value'

      const currValue = parentProps.articlePlaceholders.get(this.props.pid) || {
        left: 0,
        top: 0,
      }

      parentProps.articlePlaceholders.set(this.props.pid, key, {
        type: 'keyValue',
        value: {
          left: currValue.left + value.x,
          top: currValue.top + value.y,
        },
      })
    }

    render() {
      // eslint-disable-next-line no-unused-vars
      const env = toJS(parentProps.env)

      const eventHandlers = getHTMLEventsFromProps(this.props)
      const dataAttributes = getDataAttributes(this.props)

      const value = parentProps.articlePlaceholders.get(this.props.pid) || {
        left: 0,
        top: 0,
      }

      return (
        <div
          className={classNames(
            this.props.className,
            'positionable-positionable'
          )}
          style={{
            ...this.props.style,
            position: 'absolute',
            left: `${value.left}px`,
            top: `${value.top}px`,
          }}
          {...eventHandlers}
          {...dataAttributes}
        >
          {this.props.children}
        </div>
      )
    }
  }

  // eslint-disable-next-line max-len
  PMContextConnectedPositionable.TransformationInfo = function PMContextConnectedPositionableTransformationInfo() {
    return null
  }

  // eslint-disable-next-line max-len
  PMContextConnectedPositionable.Container = function PMContextConnectedPositionableContainer(
    props
  ) {
    const { style, children, className } = props
    const eventHandlers = getHTMLEventsFromProps(props)

    return (
      <div style={style}>
        <div
          style={{
            position: 'relative',
          }}
          className={classNames('positionable-container', className)}
          {...eventHandlers}
        >
          {children}
        </div>
      </div>
    )
  }

  return PMContextConnectedPositionable
}
