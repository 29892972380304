import { store as contextStore } from '../../context'
import Command from '../../shared/command'
import { Store } from '../../shared/store'
import { deepGet } from '../../shared/obj'
import { createDestConstraints } from './helper'

export default class UploadImageCommand extends Command {

  static frameKeyMap = {
    width: 'w',
    height: 'h',
    top: 'y',
    left: 'x'
  }

  constructor(imageStore: Store, data, opts: Object) {
    super(imageStore, data, opts)

    this.imageStore = imageStore

    this.data = opts.editing
      ? this.parseEditing(data)
      : this.parseUploading(data, opts)

    this.opts = JSON.parse(JSON.stringify(opts))
  }

  validate(imageStore: Store, data, opts: Object) {
    if (!imageStore) {
      this.throwError('expects `imageStore` argument to be present')
    }

    if (!data) {
      this.throwError('expects `data` argument to be present')
    }

    if (!data.constraints) {
      this.throwError('`data.constraints` is required')
    }

    if (!(data.file || data.url)) {
      this.throwError('one of `data.file` or `data.url` is required')
    }

    if (typeof data.channel !== 'string') {
      this.throwError('`data.channel` of type string is required')
    }

    if (typeof deepGet(opts, 'params.lang') !== 'string') {
      this.throwError('expects opts passed to the command to include desired image lang')
    }

    if (opts.editing && data.image && !data.image.id) {
      this.throwError('expects `image.id` when editing an existing image')
    }
  }

  parseEditing(data) {
    return {
      id: data.image.id,
      projectId: contextStore.getCurrentIds().projectId,
      constraints: this.getConstraintsForUpload(data)
    }
  }

  parseUploading(data, opts) {

    let sourceType = data.file ? 'file' : 'url'
    const source = deepGet(data, 'image.source')

    // bynder logic
    if (source && source.name !== 'censhare') {
      sourceType = 'source'
      data.source = {}
      data.source.source = data.image.source
      data.source.sourceId = data.image.sourceId
    }

    // In case of multiple image upload the blob has no id
    const id = data.image ? data.image.id : undefined

    return {
      id: data.id || id || opts.id,
      projectId: contextStore.getCurrentIds().projectId,
      constraints: this.getConstraintsForUpload(data),
      filePreview: data.value,
      shouldIntersect: data.constraints.shouldIntersect,
      [sourceType]: data[sourceType]
    }
  }

  getConstraintsForUpload(data) {

    // NOTE: in case we are uploading a zip file, constrainst need to be
    // removed, otherwise the backend tries to create the cropped images from
    // the zip.
    if (data.file && data.file.type === 'application/zip') {
      return null
    }

    // if croppersize is allowed, then verify that the upload isn't too large
    const sizingObj = createDestConstraints(data)
    const destWidth = sizingObj.w || data.constraints.minWidth
    const destHeight = sizingObj.h || data.constraints.minHeight

    return {
      [data.channel]: {
        dest: {
          w: destWidth,
          h: destHeight
        },
        name: data.channel,
        frame: Object.keys(data.frame || {})
          .reduce((memo, key) => {
            memo[UploadImageCommand.frameKeyMap[key]] = data.frame[key]
            return memo
          }, {}),
        source: data.image.source
      }
    }

  }

  exec() {
    return this.imageStore.create(this.data, this.opts)
  }

}
