import { deepGet } from '../../shared/obj'
import { apiClient } from '../../shared/api'
import Command from '../../shared/command'

@apiClient
export default class AddUserToChatCommand extends Command {

  constructor({ reviewStore, users }) {
    super({ reviewStore, users })
    this.users = users
    this.reviewStore = reviewStore
  }

  validate({ reviewStore, users }) {

    if (!reviewStore) {
      return `Expected \`reviewStore\` to be present. "${reviewStore}" given.`
    }

    if (!Array.isArray(users)) {
      return 'Expected users to be given as array.'
    }

    return false
  }

  exec() {

    const {
      users,
      reviewStore
    } = this

    const currentModel = reviewStore.current

    return this.dispatch('post', {
      path: `review/${currentModel.id}/user`,
      data: {
        userIds: users,
      }
    }).then((res) => {
      currentModel.users = deepGet(res, 'body.data.users')
    }).catch((err) => {
      throw new Error('unable to add user to review')
    })
  }

}
