exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".src-article-dev-styles__articleDevTools___2_7nX span{display:inline-block;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;width:400px}\n", "", {"version":3,"sources":["/builds/GENEVA/wm/frontend/src/article/dev-styles.scss"],"names":[],"mappings":"AAAA,sDAEI,qBACA,gBACA,uBACA,mBACA,WAAY,CACb","file":"dev-styles.scss","sourcesContent":[".articleDevTools {\n  span {\n    display: inline-block;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n    width: 400px;\n  }\n\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"articleDevTools": "src-article-dev-styles__articleDevTools___2_7nX"
};