import { store as uiStore } from '../../../ui'

export default function talkative(target) {
  const provider = target.prototype

  provider.speak = function speakDecorator(
    name,
    value = null,
    duration = 3000
  ) {
    uiStore.addStatusInfo(
      {
        id: this.constructor.name,
        priority: 'low',
        name,
        value,
      },
      duration
    )
  }
}
