import { observable } from 'mobx'
import { Command } from '../../../shared/lib/command'

export default
class SelectContentInWidgetCommand
extends Command {

  static storeRequirements = {
    context: true,
  }

  static validationSpec = {
    context: Command.ensureData.isPresent,
    'context.widget': Command.ensureData.isObject,
    // item: object | null
    // index: number | null
  }

  constructor(data: object = {}) {
    super(data)
  }

  exec() {

    const {
      context: { widget },
      item = null,
      index = undefined
    } = this.data

    return new Promise<void>((resolve) => {


      if (widget.handleSelectItem) {
        widget.handleSelectItem({ target: { item, index } })
      }
      else {
        console.warn(
          `${widget.constructor.name} should implemenet the ` +
          'method "handleSelectItem({target: { item }})": \n' +
          `
            handleSelectItem({ target: { item, index } }) {
              const { content } = this.props

              if (!item && index !== undefined) {
                if (content.length) {
                  index = index === -1 ? content.length - 1 : index
                  item = content[index]
                }
                else {
                  item = null
                }
              }

              this.setState({
                selectedItem: item
              })
            },
          `
        )
      }

      resolve()

    })
  }
}
