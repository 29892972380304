exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".src-auth-styles__loginMain___2dAUl form{background-color:white;border-radius:2px;max-width:360px;padding:0.5em 1.5em}.src-auth-styles__loginMain___2dAUl form div{align-items:flex-end;display:flex}.src-auth-styles__loginMain___2dAUl form img{display:block;margin:2em auto}.src-auth-styles__loginMain___2dAUl form label{width:100%}.src-auth-styles__loginMain___2dAUl form input{height:34px}.src-auth-styles__loginMain___2dAUl form .geneva-button{margin-bottom:20px;margin-right:10px}.src-auth-styles__loginMain___2dAUl form .message.message-error{padding:0 10px 20px}\n", "", {"version":3,"sources":["/builds/GENEVA/wm/frontend/src/auth/styles.scss"],"names":[],"mappings":"AAGA,yCAII,uBACA,kBACA,gBACA,mBAAoB,CAPxB,6CAUM,qBACA,YAAa,CAXnB,6CAeM,cACA,eAAgB,CAhBtB,+CAoBM,UAAW,CApBjB,+CAwBM,WAAY,CAxBlB,wDA4BM,mBACA,iBAAkB,CA7BxB,gEAiCM,mBAAoB,CACrB","file":"styles.scss","sourcesContent":["@import \"../styles/variables\";\n@import \"../styles/mixins\";\n\n.loginMain {\n\n  form {\n\n    background-color: white;\n    border-radius: $modal-border-top-radius;\n    max-width: 360px;\n    padding: 0.5em 1.5em;\n\n    div {\n      align-items: flex-end;\n      display: flex;\n    }\n\n    img {\n      display: block;\n      margin: 2em auto;\n    }\n\n    label {\n      width: 100%;\n    }\n\n    input {\n      height: 34px;\n    }\n\n    :global(.geneva-button) {\n      margin-bottom: 20px;\n      margin-right: 10px;\n    }\n\n    :global(.message.message-error) {\n      padding: 0 10px 20px;\n    }\n  }\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"loginMain": "src-auth-styles__loginMain___2dAUl"
};