import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import { autorun } from 'mobx'
import { observer, propTypes as MobXPropTypes } from 'mobx-react'
import { autobind } from 'core-decorators'
import classNames from 'classnames'
import { formatMessage } from '../../../translations'

import ContentLoadingBox from '../../../shared/components/ContentLoadingBox'
import TemplateErrorBox from '../../../shared/components/TemplateErrorBox'
import cancelable from '../../../shared/decorators/cancelable-promise'

import scrollIntoViewIfNeeded from '../../../shared/utils/scroll-into-view-if-needed'

import i18n from '../../../shared/utils/i18n'

import ContentProvider from './ContentProvider'

import { store as contextStore } from '../../../context'

@cancelable
@observer
export default class WidgetItem extends Component {
  static propTypes = {
    onFocus: PropTypes.func,
    onDoubleClick: PropTypes.func,
    index: PropTypes.number,
    className: PropTypes.string,
    gr: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    gb: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    item: PropTypes.shape({
      type: PropTypes.string,
      templateId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    env: PropTypes.object,
    children: PropTypes.node,

    items: MobXPropTypes.arrayOrObservableArray,

    page: PropTypes.object,
    wrappers: PropTypes.object,
  };

  constructor(props) {
    super(props)

    this.state = {
      ConnectedTemplate: null,
    }

    this.contentProvider = new ContentProvider(
      props.item.type,
      props.env,
      props.customLocal
    )

    // we are listening for this separately and set the className in an oldscool
    // way to prevent rerendering of the complete items
    this.resolveAutorun = autorun(() => {
      const selected = contextStore.matches(this.getEventContext())
      if (this.itemRef && this.itemRef.templateRef) {
        this.itemRef.templateRef.classList[selected ? 'add' : 'remove'](
          'selected'
        )
        if (selected) {
          scrollIntoViewIfNeeded(this.itemRef.templateRef, 20)
        }
      }
    })
  }

  componentWillMount() {
    const promise = this.makeCancelable(
      this.contentProvider.provide(this.props.item, this.props, {
        contextStore,
      })
    )
      // We need to catch here, otherwise errors that happen as a result of
      // setState (which is the rendering) would also be delt with in this
      // handler. That's impossible though as then the component is alread in an
      // inconsistent state then.
      .catch((ex) => {
        if (this.isCanceledPromise(ex)) {
          return {}
        }
        console.error('WidgetItem#componentDidMount():', ex)
        this.setState({
          error: ex,
        })
        return {}
      })
      .then(({ content, ConnectedTemplate }) => {
        if (content && ConnectedTemplate) {
          this.setState({
            content,
            ConnectedTemplate,
          })
        }
      })
      .catch((ex) => {
        console.error(ex)
        this.setState({
          error: ex,
        })
      })
  }

  componentWillUnmount() {
    this.resolveAutorun()
  }

  getEventContext() {
    const { item, gr, gb, index } = this.props

    // use the content or create a fake content object
    const content = this.state.content || {
      id: item.id,
      name: i18n(item.data, 'name'),
      contentType: item.type,
    }

    return {
      target: {
        type: item.type,
        id: item.id,
        [item.type]: content,
        gr,
        gb,
        index,
      },
    }
  }

  @autobind
  handleFocus() {
    if (this.props.onFocus) {
      this.props.onFocus(this.getEventContext())
    }
  }

  @autobind
  handleDoubleClick() {
    if (this.props.onDoubleClick) {
      this.props.onDoubleClick(this.getEventContext())
    }
  }

  isLocked(content) {
    return (
      content.publicationNotAllowed
      || (content.isLockedByOther() && !content.isLockedBySystem())
    )
  }

  isLockedMessage(content) {
    if (content.publicationNotAllowed) {
      return formatMessage({ id: 'locked.widget-byChoice' })
    }
    if (content.isLockedByOther() && !content.isLockedBySystem()) {
      return formatMessage({ id: 'locked.widget-byOther' })
    }
    return null
  }

  renderConnectedTemplate() {
    try {
      const { ConnectedTemplate, content } = this.state

      return [
        <div
          key="template-lock-indicator"
          className={classNames(
            'template-lock-indicator',
            !this.isLocked(content) ? 'hidden' : null
          )}
        >
          {this.isLockedMessage(content)}
        </div>,
        <ConnectedTemplate
          key="widgetItem"
          tabIndex="1"
          title={`${content.templateName}: ${content.name}`}
          {...this.props}
          onFocus={this.handleFocus}
          onDoubleClick={this.handleDoubleClick}
          keyValue={content.keyValueAccessor}
          content={content.content}
          items={this.props.items}
          ref={ref => (this.itemRef = ref)}
        >
          {this.props.children}
        </ConnectedTemplate>,
      ]
    }
    catch (error) {
      return this.renderContentError(error)
    }
  }

  renderContentError(error) {
    const {
      env,
      item: {
        data: { widgetTemplate },
      },
    } = this.props
    return (
      <div
        tabIndex="1"
        onFocus={this.handleFocus}
        onDoubleClick={this.handleDoubleClick}
      >
        <TemplateErrorBox error={error} env={env} template={widgetTemplate} />
      </div>
    )
  }

  render() {
    const { ConnectedTemplate, content, error } = this.state

    if (error) {
      return this.renderContentError(error)
    }

    if (!ConnectedTemplate || !content) {
      return <ContentLoadingBox />
    }

    return this.renderConnectedTemplate()
  }
}
