import {
  action,
  observable,
  computed,
  transaction,
  extendObservable,
  autorun,
  toJS,
  isObservableArray,
} from 'mobx'
import request from 'superagent'
import moment from 'moment'
import { guid, filterCustomerAllowedButtons } from '../shared/utils'
import getEditorToolbarButtons from './editor-toolbar-buttons'
import getDraftEditorToolbarButtons from './draft-editor-toolbar-buttons'
import { editor as editorConfig } from '../config'

const applyButtonOptions = (button, options) => {
  Object.keys(button)
    .filter(key => key !== 'subCommands' && key !== 'cmd')
    .filter(key => key in options)
    .forEach((key) => {
      button[key] = options[key]
    })

  if (button.subCommands && options.subCommands) {
    Object.keys(options.subCommands).forEach((subCommandName) => {
      const regexp = new RegExp(`${subCommandName}$`)

      const subCommandButton = button.subCommands.find(btn => regexp.test(btn.cmd)
      )

      if (subCommandButton) {
        applyButtonOptions(
          subCommandButton,
          options.subCommands[subCommandName]
        )
      }
    })
  }
}

function getWindowDimensions() {
  if (typeof window === 'undefined') {
    return {
      width: 0,
      height: 0,
    }
  }

  return {
    // eslint-disable-next-line no-restricted-globals
    width: innerWidth,
    // eslint-disable-next-line no-restricted-globals
    height: innerHeight,
  }
}

class UIStore {
  static contexts = ['article'];

  // Defines how two panes should be split. Currently dev only.
  @observable paneSplit = null;

  static defaults = {
    dragAction: {
      active: false,
      types: [],
    },
  };

  @observable dragAction = {
    active: false,
    types: [],
  };

  @computed get hasActiveURLDrag() {
    return (
      this.dragAction.active
      && this.dragAction.types.indexOf('text/uri-list') >= 0
    )
  }

  @computed get hasActiveFileDrag() {
    return (
      this.dragAction.active && this.dragAction.types.indexOf('Files') >= 0
    )
  }

  /**
   *
   * @type Object
   */
  @observable mediaManagerDialog = {
    status: 'undefined',
  };

  /**
   *
   * @type Object
   */
  @observable bynderDialog = {
    status: 'undefined',
  }

  /**
   *
   * @type Object
   */
  @observable censhareDialog = {
    status: 'undefined',
  }

  /**
   *
   * @type Object
   */
  @observable imageEditorDialog = {
    status: 'undefined',
  };

  /**
   *
   * @type Object
   */
  @observable contentLoadingBoxFullscreenDialog = {
    status: 'undefined',
  }

  /**
   * The defaults for the TableDialog.
   * 'status' determines the current status of the dialog. Can be one of
   * 'undefined', 'editing', 'committed', 'canceled'. A custom value may be used
   * to indicate a custom action.
   * @type Object
   */
  @observable tableDialog = {
    status: 'undefined',
    cols: 3,
    rows: 3,
  };

  /**
   * The defaults for the LinkDialog.
   * 'status' determines the current status of the dialog. Can be one of
   * 'undefined', 'editing', 'committed', 'canceled'. A custom value may be used
   * to indicate a custom action. In this case 'remove-link' is used to remove a
   * link.
   * @type Object
   */

  /**
   * These are custom tools that may be speciefied in a template.
   * @type Array
   */
  @observable articleContextTools = [];

  @observable articleMetaSettings = [];

  @observable language = 'en';

  @observable contentLanguage = 'de-DE';

  @observable translations = null;

  @observable pendingRequestCount = 0;

  // asStructure makes sure observer won't be signaled only if the
  // dimensions object changed in a deepEqual manner
  // - mobx update - delete when no problems occur
  // @observable windowDimensions = asStructure(getWindowDimensions())
  @observable windowDimensions = getWindowDimensions();

  @observable paneState = {};

  statusInfos = {};

  @observable statusInfosIdStack = [];

  @observable cachedDraftEditorButtons = getDraftEditorToolbarButtons();

  @computed get editorButtonsFlatMap() {
    if (!this.cachedEditorButtonsFlatMap) {
      // just to trigger creation of flatmap
      // eslint-disable-next-line
      const trigger = this.editorButtons;
    }
    return this.cachedEditorButtonsFlatMap
  }

  @computed get editorButtons() {
    if (!this.cachedEditorButtons) {
      this.cachedEditorButtonsFlatMap = {}

      this.cachedEditorButtons = filterCustomerAllowedButtons(
        getEditorToolbarButtons(),
        editorConfig,
      )
        // .map((button) => {
        //
        //   if (button.cmd in editor.buttonOptions) {
        //
        //     const options = editor.buttonOptions[button.cmd]
        //
        //     applyButtonOptions(button, options)
        //
        //   }
        //
        //   return button
        //
        // })
        .reduce((memo, button, i) => {
          if (button === 'separator') {
            memo[`separator${i}`] = { type: 'separator' }
          }
          else {
            if (button.subCommands) {
              button.subCommands = button.subCommands.map((btn) => {
                const observableButton = observable(btn)
                this.cachedEditorButtonsFlatMap[btn.cmd] = observableButton
                return observableButton
              })
            }

            this.cachedEditorButtonsFlatMap[button.cmd] = memo[
              button.cmd
            ] = observable(button)
          }
          return memo
        }, {})
    }

    return this.cachedEditorButtons
  }

  @computed get draftEditorButtons() {
    return this.cachedDraftEditorButtons
  }

  @computed get appIsInSync() {
    return this.pendingRequestCount === 0
  }

  constructor() {
    if (typeof window !== 'undefined') {
      window.addEventListener(
        'resize',
        () => {
          this.windowDimensions = getWindowDimensions()
        },
        false
      )
    }

    // used for storage for any component between mountings.
    //  data being stored should not be reacted to
    this.localStorage = {}
  }

  @action
  addTranslations(locale, messages) {
    // TODO: use correct locale here
    // if (this.translations.locale === locale) {
    extendObservable(this.translations || {}, messages)
    // }
  }

  setContext(context) {
    context.forEach((ctx) => {
      ctx.state = Object.assign(
        {
          disabled: false,
          checked: false,
        },
        ctx.state || {}
      )
    })

    Object.assign(this.context, context)
  }

  updatePaneState(newState) {
    this.paneState = {
      ...this.paneState,
      ...newState,
    }
  }

  @action
  setPaneSplit(value = null) {
    this.paneSplit = value
  }

  @action
  setDraftEditorButtons(buttonString = '') {
    const buttons = buttonString.split(',')

    this.cachedDraftEditorButtons.forEach((button) => {
      button.isVisible = false

      if (buttons.indexOf(button.name) > -1) {
        button.isVisible = true
      }
    })
  }

  @action
  setEditorButtons(commandName, state) {
    try {
      const button = this.editorButtonsFlatMap[commandName]

      transaction(() => {
        // only update values that did change
        Object.keys(state)
          .filter(prop => button[prop] !== state[prop])
          .forEach((prop) => {
            button[prop] = state[prop]
          })
      })
    }
    catch (ex) {
      console.log(
        `Error: Tying to set state for button \`${commandName}\` failed!`
      )
    }
  }

  loadLang(lang = 'en') {
    if (lang === this.lang) {
      // ignore the request, everything should be fine
      return
    }

    this.translations = null

    const appLocaleFileUrl = `/intl/${lang}.json`

    request.get(appLocaleFileUrl).end((err, res) => {
      if (err) {
        throw err
      }

      this.language = lang
      this.translations = res.body
      moment.locale(lang)
    })
  }

  /**
   * Adds a new item to the status list.
   * @param {Object} info - The info item that should be added to
   * the status list.
   * @param {String} info.id - The ID of the info object.
   * If not defined, a random ID will be created.
   * @param {Object|Array} info.data - The info data.
   * @param {String} info.data.name - An identifier for the
   * status. A translation for this status should exist. The
   * translation id will be created by prefixing this name with
   * `'status.'`.
   * @param {Any} info.data.value - Values that will be passed to
   * the translation engine to be used with the name property.
   * @param {String} info.priority - The priority for this item.
   * Can be `low` or `high`.
   * @param {String} info.type - An type for the kind of status
   * info. May be used to render an icon or similar.
   * @param {Number|String} ttl - Time to live for the status.
   * If not set, it will be 'forever'. Otherwise it should be a
   * number defining the milliseconds till the status should be
   * disposed.
   *
   * @example
   *
   * uiStore.addStatusInfo({
   *   data: validation.errors,
   *   type: 'error',
   *   priority: 'high',
   *   id: 'text-validation-error'
   * })
   *
   */
  addStatusInfo(origInfo, ttl = 'forever') {
    if (!origInfo.id) {
      origInfo.id = guid()
    }

    let infos

    if (
      origInfo.data
      && (Array.isArray(origInfo.data) || isObservableArray(origInfo.data))
    ) {
      infos = origInfo.data.map((itm, i) => {
        const id = `${itm.id || origInfo.id}_${i}`
        return extendObservable(
          {},
          {
            ...toJS(itm),
            id,
          }
        )
      })
    }
    else {
      infos = [origInfo]
    }

    infos.forEach(info => this.addSingleStatusInfo(info, ttl))
  }

  addSingleStatusInfo(origInfo, ttl) {
    if (typeof origInfo.value === 'number') {
      origInfo.values = { count: origInfo.value }
    }
    else if (typeof origInfo.value === 'string') {
      origInfo.values = { value: origInfo.value }
    }
    else {
      origInfo.values = origInfo.value || {}
    }

    origInfo.lastUpdated = new Date().getTime()
    let info = extendObservable(
      {},
      {
        ...toJS(origInfo),
      }
    )

    if (this.statusInfos[info.id]) {
      if (info.timeout) {
        clearTimeout(info.timeout)
      }

      if (info.update) {
        info = extendObservable(this.statusInfos[info.id], origInfo)
      }

      this.statusInfos[info.id] = info
      if (!info.update) {
        this.statusInfosIdStack.splice(
          this.statusInfosIdStack.indexOf(info.id),
          1,
          info.id
        )
      }
    }
    else {
      this.statusInfos[info.id] = info
      this.statusInfosIdStack.push(info.id)
    }

    if (ttl !== 'forever') {
      info.timeout = setTimeout(() => this.removeStatusInfo(info.id), ttl)
    }
  }

  /**
   * Removes the status with thegiven id from the status stack.
   * @param {String} id - The status id.
   */
  removeStatusInfo(id) {
    const idRegExp = new RegExp(`${id}(_\d+)?`)

    let wildcardRegExp = /!never-matched/ // just a never matching regexp
    if (id.indexOf('*') >= 0) {
      wildcardRegExp = new RegExp(`${id.replace('*', '(\\S+)')}`)
    }

    const keys = this.statusInfosIdStack.filter(
      currentId => idRegExp.test(currentId) || wildcardRegExp.test(currentId)
    )

    if (keys) {
      keys.forEach((actualId) => {
        if (actualId in this.statusInfos) {
          clearTimeout(this.statusInfos[actualId].timeout)
          this.statusInfos[actualId].timeout = null
          delete this.statusInfos[actualId]
        }

        const index = this.statusInfosIdStack.indexOf(actualId)
        if (index > -1) {
          this.statusInfosIdStack.splice(index, 1)
        }
      })
    }
  }

  /**
   * Returns the state obj for the dialog with the given name.
   * Will throw if name is not valid or no dialog state by that name exists.
   */
  getDialogState(name) {
    if (!name || typeof name !== 'string') {
      throw new Error(
        'Dialog Methods have to be a require a string argument as first parameter.'
      )
    }

    const key = `${name}Dialog`
    if (!(key in this)) {
      throw new Error(`There is no dialog state for dialog of name ${name}.`)
    }

    return this[key]
  }

  /**
   * Opens the dialog by the given name.
   * @param {String} name - The name of the dialog to be opened. A spec object
   * for this dialog has to be defined on this store.
   * @param {Object} props - Properties to be passed on to the dialog.
   * @returns {Promise} - A promise that allows to react on dialog
   * state change.
   */
  @action
  openDialog(name, props = {}) {
    const state = this.getDialogState(name)

    return new Promise((resolve) => {
      extendObservable(state, {
        ...props,
        status: 'editing',
      })

      state.onChange = (newData) => {
        extendObservable(state, newData)
      }
      state.onCommit = () => {
        state.status = 'committed'
      }
      state.onCancel = () => {
        state.status = 'cancelled'
      }

      // APPROVED: wehenever the status of the dialog changes,
      // determine neccessary actions here
      const removeDialogHandler = autorun(() => {
        if (state.status === 'editing') {
          return
        }

        removeDialogHandler()
        if (state.status === 'cancelled') {
          return
        }

        resolve(toJS(state))
      })
    })
  }

  @action
  clearContextOptions(contextFilter = null) {
    UIStore.contexts
      .filter(context => (contextFilter ? contextFilter === context : true))
      .forEach(context => (this[`${context}ContextTools`] = []))
  }

  /**
   * Set a list of commands that should be available in the specified context.
   * The module responsible for that context may decide itself how/where to
   * render those commands.
   * Note: Tools are always replaced. Not merged!
   * @param {String} context - The context
   * @param {Object} tools - An object defining the tools available in this context
   * @property {String} tools.name - The name of the tool. Should be displayable
   * (consider i18n!)
   * @property {Boolean} tools.state - The initial state of the tool.
   * @property {Boolean} tools.state.checked - Whether or not the tool is
   * currently active.
   * @property {Boolean} tools.state.disabled - Whether or not the tool is
   * currently disabled.
   * @property {Function} tools.handleClick - The function that handles a click
   * on the tool.
   * @property {Function} tools.queryState - A function returns an object like
   * `tools.state`. This function will be called in several occations through
   * the livecycle of the tool. It should return the state of the tool at the
   * very moment of it's execution.
   */
  @action
  setContextOptions(context, tools) {
    this[`${context}ContextTools`] = tools
  }

  @action
  clearMetaSettings(contextFilter = null) {
    UIStore.contexts
      .filter(context => (contextFilter ? contextFilter === context : true))
      .forEach(context => (this[`${context}MetaSettings`] = null))
  }

  @action
  setMetaSettings(context, metaSettings) {
    this[`${context}MetaSettings`] = metaSettings
  }

  setGlobalState(name, state) {
    extendObservable(this[name], UIStore.defaults[name] || {}, state)
  }
}

const uiStore = new UIStore()
export default uiStore
