import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { autobind } from 'core-decorators'
import { i18n } from '../../../shared/utils'
import { Separator } from '../../../shared/components/Dropdown'
import { store as pageStore } from '../../../page'
import { store as projectStore } from '../../../project'
import { store as uiStore } from '../../../ui'

export default
class ListEntry extends Component {

  static propTypes = {
    page: PropTypes.object,
    handleLinkClick: PropTypes.func,
    handleMissingRightsClick: PropTypes.func,
    fallbackProjectInfo: PropTypes.array
  }

  @autobind
  handleNewTabLink({ target }) {
    this.props.handleLinkClick({
      id: target.dataset.pageId,
      projectId: target.dataset.projectId,
      openInNewTab: true
    })
  }

  renderPagePath(pageId) {

    const pageNames = pageStore.getPagePath(pageId)

    return pageNames
      ? (<div className="page-path">{
        pageNames.map((pageName) => {
          return pageName
        })
      }
      </div>)
      : null
  }

  renderProjectInfo(page) {
    const project = projectStore.getPartialById(page.projectId)

    // missing project is common when lacking rights to the project
    if (!project) {
      const title = i18n(page, 'name', page.createdIso)
      const fallbackProjects = this.props.fallbackProjectInfo && this.props.fallbackProjectInfo.projects
      const fallbackProject = Array.isArray(fallbackProjects)
        && fallbackProjects.find(el => el.id === page.projectId)
      let projectInfo = ''

      if (fallbackProject) {
        const channelName = i18n(fallbackProject, 'channel.i18n.name', uiStore.language)
        const projectName = `${channelName} ${fallbackProject.label}`
        const projectLanguage = fallbackProject.language ? `| ${fallbackProject.language}` : ''

        projectInfo = fallbackProject ? `${projectName} ${projectLanguage} | ` : ''
      }

      return (
        <div className="project-info">
          {projectInfo}
          <a data-page-id={page.id}
            data-project-id={page.projectId}
            onClick={this.props.handleMissingRightsClick}
          >
            {title}
          </a>
        </div>
      )
    }

    const title = i18n(page, 'name', project.language)
    return (
      <div className="project-info">
        {
          `${project.name} | ${project.language} | `
        }
        <a data-page-id={page.id}
          data-project-id={page.projectId}
          onClick={this.handleNewTabLink}
        >
          {title}
        </a>
        <a data-page-id={page.id}
          data-project-id={page.projectId}
          onClick={this.handleNewTabLink}
        >
          <div data-page-id={page.id}
            data-project-id={page.projectId}
            className="new-tab-icon" />
        </a>
      </div>
    )
  }

  render() {
    if (this.props.page) {
      return (<div key={this.props.page.id}>
        {this.renderProjectInfo(this.props.page)}
        {this.renderPagePath(this.props.page.id)}
        <Separator />
      </div>)
    }
    return null
  }
}
