import moment from 'moment'
import React from 'react'
import { store as uiStore } from './ui'

/**
 * Finds the correct plural option to use
 * @param {Object} text - Object of cases, like '0', '1', and 'other'
 * @param {Object} variables - only needs the variable 'count' included
 * @returns String
 */
function pluralParsing(text = {}, variables) {
  if (!variables.count && variables.count !== 0) {
    console.warn('missing count in translation')
    return text['0'] || ''
  }

  let foundPlural = text.other || ''
  // Limitation: extend this if you need more than 0, 1, or other as options
  switch (variables.count) {
    case 0:
      foundPlural = text['0'] || ''
      break
    case 1:
      foundPlural = text['1'] || ''
      break
    default:
      foundPlural = text.other || ''
      break
  }

  if (foundPlural === '') {
    console.warn('translation missing plural text for count: ', variables.count)
  }

  // // Limitation: this only replaces a single time, extend if you need multiple
  // foundPlural = foundPlural.replace(/\#/g, variables.count)

  return foundPlural
}

// matches a variable 'X' with the first section in the text that is'{X}'
function variableParsing(text, variables) {
  if (text) {

    if (typeof text !== 'string') {
      return 'error: text not a string'
    }

    Object.keys(variables).forEach((variable) => {
      text = text.replaceAll(`{${variable}}`, variables[variable])
    })
  }

  return text
}

/**
 * returns only the string, not an HTML element
 * @param {String|Object} id - id in the json file
 * @param {Object} variables - any varialbes to be inserted or 'count' if using Plural
 * @returns String
 */
export function formatMessage(description, variables) {
  let id = ''
  if (typeof description === 'string') {
    id = description
  }
  else {
    if (!description || !description.id) {
      console.warn('missing language id')
      return ''
    }
    id = description.id
  }

  if (id && id !== '' && uiStore.translations !== null) {
    let translated = uiStore.translations[id]

    if (variables) {
      if (typeof translated === 'string') {
        translated = variableParsing(translated, variables)
      }
      else if (typeof translated === 'object') {
        const translatedString = pluralParsing(translated, variables)
        translated = variableParsing(translatedString, variables)
      }
      else {
        console.warn('error processing translation', id)
      }
    }

    return translated
  }
  console.warn('error getting translation')
  return ''
}

/**
 * returns an HTML Span
 * @param {Object} props -id (String) and any variables to be inserted, and/or 'count' if using Plural
 * optional: 'className', 'key'
 * @returns HTML Element
 */
export function FormattedMessage(props = {}) {
  if (props.id && props.id !== '' && uiStore.translations !== null) {
    let translated = uiStore.translations[props.id]

    if (typeof translated === 'string') {
      if (props.values) {
        translated = variableParsing(translated, props.values)
      }
    }
    else if (typeof translated === 'object') {
      const translatedString = pluralParsing(translated, props.values)
      translated = variableParsing(translatedString, props.values)
    }
    else {
      console.warn('error processing translation', props.id)
    }

    return (<span
      className={props.className}
      key={props.className}
    >
      {translated}
    </span>)
  }
  console.warn('error getting translation, missing id')
  return ''
}

/**
 * returns an HTML Span. Only use for translations from the system, nothing with user variables
 * @param {Object} props -id (String) and any variables to be inserted, and/or 'count' if using Plural
 * optional: 'className', 'key'
 * @returns HTML Element
 */
export function FormattedHTMLMessage(props = {}) {
  if (props.id && props.id !== '' && uiStore.translations !== null) {
    let translated = uiStore.translations[props.id]

    if (typeof translated === 'string') {
      if (props.values) {
        translated = variableParsing(translated, props.values)
      }
    }
    else if (typeof translated === 'object') {
      const translatedString = pluralParsing(translated, props.values)
      translated = variableParsing(translatedString, props.values)
    }
    else {
      console.warn('error processing translation', props.id)
    }

    const dangerousTranslated = { __html: translated }

    return (<span
      className={props.className}
      key={props.className}
      dangerouslySetInnerHTML={dangerousTranslated}
    >
    </span>)
  }
  console.warn('error getting translation')
  return ''
}

export function FormattedRelative(props = {}) {
  return (
    <span>
      {moment(props.value).fromNow()}
    </span>
  )
}

export function FormattedTime(props = {}) {
  return (
    <span>
      {moment(props.value).format('LT')}
    </span>
  )
}

export function FormattedDate(props = {}) {
  return (
    <span>
      {moment(props.value).format('L')}
    </span>
  )
}
