import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { autorun } from 'mobx'
import { observer } from 'mobx-react'
import { autobind } from 'core-decorators'
import classNames from 'classnames'
import { Link } from 'react-router'
import Tree, { TreeNode } from 'draggable-react-tree-component'
import { FormattedMessage } from '../../translations'
import Icon from '../../shared/icons'


// import 'node_modules/draggable-react-tree-component/lib/styles.css'


const controlledProps = [
  'selectedKeys',
  'expandedKeys',
  'checkedKeys'
]

const treePropNames = [
  'className',
  'draggable',
  'onExpand',
  'onDragStart',
  'onDragEnter',
  'onDragOver',
  'onDragLeave',
  'onDrop',
  'selectedKeys',
  'expandedKeys',
  'checkStrictly',
  'checkable',
  'selectable',
  'externalDragMode'
]

@observer
export default class PageTreeContainer extends Component {

  static propTypes = {
    autoExpandParent: PropTypes.bool,
    expandedKeys: PropTypes.array,
    selectedKeys: PropTypes.array,
    checkedKeys: PropTypes.array,
    page: PropTypes.object.isRequired,
    project: PropTypes.object.isRequired,
    draggable: PropTypes.bool,
    onSelect: PropTypes.func,
    onExpand: PropTypes.func,
    onDragStart: PropTypes.func,
    onDragEnter: PropTypes.func,
    onDrop: PropTypes.func,
    onCheck: PropTypes.func,
    onUnmount: PropTypes.func,
    renderItems: PropTypes.func,
    treeChildren: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array
    ]),
  }

  constructor(props) {

    super(props)

    this.state = {
      tree: null,
      autoExpandParent: 'autoExpandParent' in props
        ? props.autoExpandParent
        : true,
      expandedKeys: props.expandedKeys || [],
      selectedKeys: props.selectedKeys || [],
      checkedKeys: props.checkedKeys || [],
    }

    // this.updateTreeInState()

  }

  componentDidMount() {
    this.handler = autorun('AUTORUN:page-pagePartialsCollection-length-changed', () => {
      this.updateTreeInState(this.props.page.pagePartialsCollection.length)
    })
  }

  componentWillReceiveProps(nextProps) {
    let controlledPropDidChanged = false

    const newState = controlledProps.reduce((memo, key) => {
      if (this.props[key] !== nextProps[key]) {
        controlledPropDidChanged = true
        memo[key] = nextProps[key]
      }
      return memo
    }, {})

    if (controlledPropDidChanged) {
      this.setState(newState)
    }

    if (nextProps.project.id !== this.props.project.id) {
      this.setState({
        tree: nextProps.treeChildren
      })
    }

  }

  componentWillUnmount() {
    if (this.props.onUnmount) {
      this.props.onUnmount(this.state.expandedKeys, this.getTreeType())
    }
    this.handler()
  }

  updateTreeInState(/* len */) {
    this.setState({
      tree: this.props.treeChildren
    })
  }

  getTreeType() {
    return this.state.tree[0].type
  }

  @autobind
  handleSelect(selectedNodeIds) {
    if (this.props.onSelect) {
      this.props.onSelect(selectedNodeIds)
    }
  }

  @autobind
  handleCheck(checkedNodeIds) {
    if (this.props.onCheck) {
      this.props.onCheck(checkedNodeIds)
    }
  }

  @autobind
  handleExpand(expandedKeys) {
    this.setState({
      expandedKeys,
      autoExpandParent: false,
    })
    if (this.props.onExpand) {
      this.props.onExpand(expandedKeys, this.getTreeType())
    }
  }

  @autobind
  renderItemContent(item) {
    const lockUser = item.lockState.user

    // Use the optional name for geneva navigation if given
    const itemName = item.navigationName || item.name

    return (
      <div>
        {
          item.id < 0
            ? <FormattedMessage id={item.name} />
            : itemName
        }
        {
          lockUser && lockUser !== 'system'
            ? <span className="muted">
            &nbsp;
              <Icon
                title={`${lockUser.firstname} ${lockUser.lastname}`}
                name="ion-ios-locked"
              />
            </span>
            : ''
        }
      </div>
    )
  }

  @autobind
  renderItems(items) {

    if (this.props.renderItems) {
      return this.props.renderItems(items)
    }

    const { page } = this.props
    return items.map((item) => {
      return (<TreeNode
        basePadding={14}
        key={item.id}
        items={(item.sub && item.sub.length) ? this.renderItems(item.sub) : null}
        selected={(page.current && page.current.id === item.id)}
        className={classNames('node')}
      >
        {
          item.id > 0
            ? <Link
              to={`/publish/${this.props.project.id}/page/${item.id}`}
              draggable={false}
            >
              {this.renderItemContent(item)}
            </Link>
            : this.renderItemContent(item)
        }
      </TreeNode>)
    })
  }

  render() {
    if (!this.state.tree) {
      return <span><FormattedMessage id="project.tree.loading" /></span>
    }

    const treeProps = treePropNames.reduce((memo, key) => {
      if (this.props[key]) {
        memo[key] = this.props[key]
      }
      return memo
    }, {})

    return (
      <Tree
        {...treeProps}
        expandedKeys={this.state.expandedKeys}
        selectedKeys={this.state.selectedKeys}
        checkedKeys={this.state.checkedKeys}
        autoExpandParent={this.state.autoExpandParent}
        onExpand={this.handleExpand}
        onSelect={this.handleSelect}
        onCheck={this.handleCheck}
        projectId={this.props.project.id}
      >
        {(this.props.renderItems || this.renderItems)(this.state.tree)}
      </Tree>
    )

  }

}
