import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import { autorun } from 'mobx'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import { autobind } from 'core-decorators'
import { formatMessage } from '../../../translations'

import ContentLoadingBox from '../../../shared/components/ContentLoadingBox'
import TemplateErrorBox from '../../../shared/components/TemplateErrorBox'
import cancelable from '../../../shared/decorators/cancelable-promise'

import scrollIntoViewIfNeeded from '../../../shared/utils/scroll-into-view-if-needed'

import i18n from '../../../shared/utils/i18n'

import ContentProvider from './ContentProvider'

import { store as contextStore } from '../../../context'
@cancelable
@observer
export default class ArticleItem extends Component {
  static propTypes = {
    onFocus: PropTypes.func,
    onDoubleClick: PropTypes.func,
    index: PropTypes.number,
    className: PropTypes.string,
    gr: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    gb: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    item: PropTypes.shape({
      type: PropTypes.string,
      templateId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    env: PropTypes.object,
    children: PropTypes.node,
  };

  constructor(props) {
    super(props)

    this.state = {
      ConnectedTemplate: null,
      content: null,
    }

    this.contentProvider = new ContentProvider(
      props.item.type,
      props.env,
      props.customLocal
    )

    // we are listening for this separately and set the className in an oldscool
    // way to prevent rerendering of the complete items
    this.resolveAutorun = autorun(() => {
      const selected = contextStore.matches(this.getEventContext())
      if (this.itemRef && this.itemRef.templateRef) {
        this.itemRef.templateRef.classList[selected ? 'add' : 'remove'](
          'selected'
        )
        if (selected) {
          scrollIntoViewIfNeeded(this.itemRef.templateRef, 20)
        }
      }
    })
  }

  componentDidMount() {
    const promise = this.makeCancelable(
      this.contentProvider.provide(this.props.item, this.props)
    )
      // We need to catch here, otherwise errors that happen as a result of
      // setState (which is the rendering) would also be delt with in this
      // handler. That's impossible though as then the component is alread in an
      // inconsistent state then.
      .catch((ex) => {
        if (this.isCanceledPromise(ex)) {
          return {}
        }
        console.error('ArticleItem#componentDidMount():', ex)
        this.setState({
          error: ex,
        })
        return {}
      })
      .then(({ content, ConnectedTemplate }) => {
        if (content && ConnectedTemplate) {
          this.setState({
            content,
            ConnectedTemplate,
          })
        }
      })
  }

  componentWillUnmount() {
    this.resolveAutorun()
  }

  getEventContext() {
    const { item, gr, gb, index } = this.props

    // use the content or create a fake content object
    const content = this.state.content || {
      id: item.id,
      name: i18n(item.data, 'name'),
      contentType: item.type,
    }

    return {
      target: {
        type: item.type,
        id: item.id,
        [item.type]: content,
        gr,
        gb,
        index,
      },
    }
  }

  @autobind
  handleFocus() {
    if (this.props.onFocus) {
      this.props.onFocus(this.getEventContext())
    }
  }

  @autobind
  handleDoubleClick() {
    if (this.props.onDoubleClick) {
      this.props.onDoubleClick(this.getEventContext())
    }
  }

  isLocked(content) {
    return (
      content.publicationNotAllowed
      || (content.isLockedByOther() && !content.isLockedBySystem())
      || content.mandatoryFieldsMissing
    )
  }

  isLockedMessage(content) {
    const result = []
    if (content.publicationNotAllowed) {
      result.push(formatMessage({ id: 'locked.article-byChoice' }))
    }
    if (content.isLockedByOther() && !content.isLockedBySystem()) {
      result.push(formatMessage({ id: 'locked.article-byOther' }))
    }
    if (content.mandatoryFieldsMissing) {
      result.push(
        formatMessage({ id: 'locked.article-byMandatoryField' })
      )
    }
    return result
  }

  renderConnectedTemplate() {
    try {
      const { ConnectedTemplate, content } = this.state

      return [
        <div
          key="template-lock-indicator"
          className={classNames(
            'template-lock-indicator',
            !this.isLocked(content) ? 'hidden' : null
          )}
        >
          {this.isLockedMessage(content).map((result) => {
            return <div key={result}>{result}</div>
          })}
        </div>,
        <ConnectedTemplate
          key="articleItem"
          tabIndex="1"
          title={`${content.templateName}: ${content.name}`}
          {...this.props}
          onFocus={this.handleFocus}
          onDoubleClick={this.handleDoubleClick}
          placeholder={content.phAccessor}
          ref={ref => (this.itemRef = ref)}
        >
          {this.props.children}
        </ConnectedTemplate>,
      ]
    }
    catch (error) {
      return this.renderContentError(error)
    }
  }

  renderContentError(error) {
    const {
      env,
      item: {
        data: { template },
      },
    } = this.props
    return (
      <div
        tabIndex="1"
        onFocus={this.handleFocus}
        onDoubleClick={this.handleDoubleClick}
      >
        <TemplateErrorBox error={error} env={env} template={template} />
      </div>
    )
  }

  render() {
    const { ConnectedTemplate, content, error } = this.state

    if (error) {
      return this.renderContentError(error)
    }

    if (!ConnectedTemplate || !content) {
      return <ContentLoadingBox />
    }

    return this.renderConnectedTemplate()
  }
}
