// TODO: reliably add templates to config before using the data here
// import {
//   templates as articleTemplates,
//   widgetTemplates,
//   pageTemplates
// } from 'config'

const getTemplates = (templateType) => {
  return ({
    article: window.GENEVA_CONFIG.templates,
    widget: window.GENEVA_CONFIG.widgetTemplates,
    page: window.GENEVA_CONFIG.pageTemplates
  })[templateType]
}

export default
class TemplateError extends ReferenceError {
  constructor(msg, templateId, templateType = 'article') {
    super(msg)
    const template = (getTemplates(templateType) || [])
      .filter(tmpl => tmpl.id === templateId)
    this.templateName = template.name
    this.name = `TemplateError: ${this.templateName}`
    this.templateId = templateId
    this.tempalteType = templateType
  }
}
