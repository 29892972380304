import PropTypes from 'prop-types'
import React, { Component } from 'react'
import classNames from 'classnames'
import { formatMessage, FormattedMessage } from '../../../translations'

import { testClass } from '../../../shared/utils'
import Icon from '../../../shared/icons'
import GenevaButton from '../../../ui/components/GenevaButton'

const css = /* typeof window === 'undefined' ? {} : */require('../../styles.scss')

export default class GhostHeader extends Component {

  static propTypes = {
    ghostOptions: PropTypes.array,
    switchBatchEdit: PropTypes.bool,
    handleSubHeaderEnabled: PropTypes.func,
    ghostSubHeaderChoice: PropTypes.string,
    batchChoiceEnabled: PropTypes.bool,
    handleBatchSet: PropTypes.func,
    handleSubHeaderChanged: PropTypes.func,
    ui: PropTypes.object,
    ghostArticleListLength: PropTypes.number,
    censhareIso: PropTypes.string.isRequired,
  }

  renderSubHeader() {
    return (
      <div className={classNames(css.ghostSubheader, css.ghostDialogPadding)}>
        <div className="batch-choice-text">
          <input
            type="checkbox"
            value="batchChoice"
            id="batchChoice"
            onChange={this.props.handleSubHeaderEnabled}
          />
          <label
            htmlFor="batchChoice"
            className={testClass('batch-option-toggle')}
          >
            <FormattedMessage id="ghost-dialog.subheader" />
          </label>
        </div>
        <select
          id="batch-choice-selection"
          value={this.props.ghostSubHeaderChoice}
          onChange={this.props.handleSubHeaderChanged}
          disabled={!this.props.batchChoiceEnabled}
        >
          { this.props.ghostOptions.map((option) => {
            return (<option
              key={option}
              value={option}
            >
              {formatMessage(
                { id: `ghost-dialog.${option}` },
                { language: option === 'censhare'
                  ? this.props.censhareIso
                  : this.props.ui.contentLanguage }
              )}
            </option>)
          })}
        </select>
        <GenevaButton
          onClick={this.props.handleBatchSet}
          className={classNames(
            'button',
            testClass('batch-submit')
          )}
          title={formatMessage({ id: 'ghost-dialog.sub-confirm' })}
          disabled={!this.props.batchChoiceEnabled}
        >
          <FormattedMessage id="ghost-dialog.sub-confirm" />
        </GenevaButton>
      </div>
    )
  }

  render() {
    const { ghostArticleListLength } = this.props

    return (<div>
      <div className={classNames(css.ghostHeader, css.ghostDialogPadding)}>
        <Icon name="ion-ios-information" />
        <div className={classNames(
          css.ghostHeaderText,
          'grid-block vertical noscroll')}
        >
          <FormattedMessage id="ghost-dialog.header-copy" />
          <FormattedMessage id="ghost-dialog.header-options" />
        </div>
      </div>
      {
        ghostArticleListLength
          ? this.renderSubHeader()
          : null
      }
    </div>)
  }
}
