import * as PropTypes from 'prop-types'
import React from 'react'
import { withRouter } from 'react-router'

import createParentPropsInjector from '../utils/create-parent-props-injector'
import commandConnector from '../../shared/lib/command'

import { store as projectStore } from '../../project'
import { store as authStore } from '../../auth'

import EditItemCommand from '../commands/EditItemCommand'
import LimitedProjectDropdown from '../components/LimitedProjectDropdown'

export default function connectLimitedProjectDropdown(parentProps) {
  const injectParentProps = createParentPropsInjector(parentProps)

  // Connector function
  function LimitedProjectDropdownConnector({ ...rest }) {
    const contentStores = {
      project: projectStore,
      auth: authStore,
    }

    return <LimitedProjectDropdown contentStores={contentStores} {...rest} />
  }

  // Component props
  LimitedProjectDropdownConnector.propTypes = {
    currentProject: PropTypes.object.isRequired,
    onRenderEntry: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    className: PropTypes.string,
  }

  // Inject commands
  return commandConnector({
    EditItemCommand,
  })(
    injectParentProps(withRouter(LimitedProjectDropdownConnector))
  )
}
