import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { autobind } from 'core-decorators'
import { FormattedMessage } from '../../../translations'
import Icon from '../../../shared/icons'
import VersionPortal from './VersionPortal'

@observer
class VersionCMToolbar extends Component {

  static propTypes = {
    className: PropTypes.string,
    context: PropTypes.object
  }

  constructor(props) {
    super(props)
    this.state = {
      isComparing: false,
      compareFrom: props.context.compareFrom,
      compareTo: props.context.compareTo
    }
  }

  componentWillReceiveProps(nextprops) {
    const { target } = nextprops.context

    // Do not change the state without context target
    if (!target) {
      return
    }

    this.setState({
      isComparing: target.type === 'version',
      compareFrom: target.compareFrom,
      compareTo: target.compareTo
    })
  }

  @autobind
  handleEndCompareMode() {
    const { context } = this.props

    context.unlockContext()
    context.createFromElement(null)
  }

  renderVersionLabel(text) {
    return (<div key={text} className="version-label">Version {text}</div>)
  }


  render() {
    const { isComparing, compareFrom, compareTo } = this.state

    return (
      <div className={this.props.className} onClick={this.handleEndCompareMode}>
        {
          isComparing
            ? [
              <FormattedMessage key="comparing" id="article.version.comparing" />,
              this.renderVersionLabel(compareFrom),
              <FormattedMessage key="comparing-and" id="article.version.comparing-and" />,
              this.renderVersionLabel(compareTo),
              <Icon key="close" name="ion-ios-close-empty" />,
            ]
            : null
        }
        {
          this.props.context.type === 'version'
            ? <VersionPortal context={this.props.context} />
            : null
        }
      </div>
    )
  }
}

export default VersionCMToolbar
