import React from 'react'
import ReactDOM from 'react-dom'
import LoadingInfo from './components/LoadingInfo'

export default ({ root }) => {
  ReactDOM.render(
    (<LoadingInfo>
      Loading Geneva Configuration...
    </LoadingInfo>),
    root
  )
}
