import ModelSyncAction from './ModelSyncAction'

export default
class LoadAction
  extends ModelSyncAction {

  executeAction(opts = {}) {
    return this.model.store.load(this.model.id, opts)
  }

}
