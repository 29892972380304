import Command from '../command'

/**
 * The InsertVideoFromContextIntoArticlePlaceholderCommand uses the data in the
 * context store to edit and upload an video and finally insert it into the
 * article placeholder.
 * @extends shared~Command
 */
export default
class InsertVideoFromContextIntoArticlePlaceholderCommand
  extends Command {

  /**
   * Creates a new InsertVideoFromContextIntoArticlePlaceholderCommand using
   * the data from the contextStore.
   */
  constructor(videoData, placeholderData) {

    placeholderData.video = videoData

    super(placeholderData)

    this.data = placeholderData

  }

  /**
   * Validates the data in this command.
   * @param {Object} data - The data of the command
   * @private
   */
  validate() {
    // TODO
  }

  exec() {
    return this.data.article.placeDataInPlaceholder(
      this.data.type, this.data.pid, this.data.video
    )
  }
}
