import React from 'react'
import classNames from 'classnames'
import ContentLoadingBox from '../../shared/components/ContentLoadingBox'

const css = /* typeof window === 'undefined' ? {} : */require('../styles.scss')

const LoadingScreenDisplayer = () => {

  return (<div
    className={
      classNames(css.projectPreview, 'grid-block', 'align-center')
    }
  >
    <div className="grid-block vertical shrink noscroll">
      <ContentLoadingBox
        spinner
        message={{
          id: 'project.loading',
        }}
      />
    </div>
  </div>)

}

export { LoadingScreenDisplayer as default }
