import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { autobind } from 'core-decorators'
import classNames from 'classnames'
import { FormattedMessage } from '../../translations'
import GenevaButton from '../../ui/components/GenevaButton'

@observer
export default class CenshareCMToolbar extends Component {

  static propTypes = {
    context: PropTypes.object,
    className: PropTypes.string,
    onDisconnect: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    onUpdateArticle: PropTypes.func.isRequired
  }

  @autobind
  handleUpdateArticle() {

    if (this.props.onUpdateArticle) {
      this.props.onUpdateArticle()
    }
  }

  @autobind
  handleDisconnect() {
    if (this.props.onDisconnect) {
      this.props.onDisconnect('text')
    }
  }

  @autobind
  handleRemove() {
    if (this.props.onRemove) {
      this.props.onRemove()
    }
  }

  render() {

    const { target } = this.props.context

    const hasValue = !!(target
      && target.type
      && target.type === 'censhare')

    return (<div
      className={classNames(this.props.className, 'toolbar', 'v-align')}
    >
      <div className="muted">
        <FormattedMessage id="censhare.article.shortcut" />
      </div>

      <GenevaButton
        className="small button plain"
        onClick={this.handleUpdateArticle}
        disabled={!hasValue}
      >
        <FormattedMessage id="censhare.article.update" />
      </GenevaButton>

      <GenevaButton
        className="small button plain"
        onClick={this.handleRemove}
        disabled={!hasValue}
      >
        <FormattedMessage id="censhare.article.remove" />
      </GenevaButton>

      <GenevaButton
        className="small button plain"
        onClick={this.handleDisconnect}
        disabled={!hasValue}
      >
        <FormattedMessage id="censhare.article.disconnect" />
      </GenevaButton>

    </div>)
  }
}
