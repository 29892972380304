import React from 'react'
import classNames from 'classnames'
import * as style from './styles.scss'

export default
function Section(props) {
  return (<div
    className={classNames(props.className, style.navbarSection)}
  >
    {props.children}
  </div>)
}
