import ContentRenderer from '../../components/ContentRenderer'
import DependencyCollector from '../../../shared/template/DependencyCollector'

// Template components


// connectors

/**
 * The dependency collector used by the PM
 */
export default
class TemplateDependencyCollector
  extends DependencyCollector {

  constructor(opts) {
    opts.componentOverrides = opts.componentOverrides || {}
    super(opts)
  }

  getConnectionProps() {
    return {
      widgetKeyValues: this.props.widget.keyValueAccessor || null,
      content: this.props.widget.content || [],
      items: this.props.widget.items || [],
    }
  }

  collect() {

    return {

      ...super.collect(),

      // data:
      // ...

      // components:

      ContentRenderer: this.connectors.connectContentRendererToContext(
        ContentRenderer, {
          env: this.ENV,
          widget: this.props.widget,
          content: this.props.widget.content,
          items: this.props.page.items,
          page: this.props.page,
          wrappers: this.props.wrappers,
        },
        this.contextStore
      ),

    }

  }
}
