
import PropTypes from 'prop-types'

import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { autorun } from 'mobx'
import classNames from 'classnames'
import { ProjectNavigationContainer, LoadingScreenDisplayer } from '../../project'
import { ensureNumber } from '../../shared/utils'
import { deepGet } from '../../shared/obj'
import { store as authStore } from '../../auth'
import { default as Menubar } from '../components/Menubar'
import Navbar from '../components/Navbar'

const css = /* typeof window === 'undefined' ? {} : */require('../styles.scss')


const ProjectNavigationContainerDisplayer = observer(({
  auth, page, project, ui, pageTemplate
}) => {

  return ui.paneState.projectToolsPanelOpen
    ? (<ProjectNavigationContainer
      project={project}
      page={page}
      pageTemplate={pageTemplate}
      auth={auth}
      ui={ui}
    />)
    : null

})


@observer
export default class App extends Component {

  static propTypes = {
    auth: PropTypes.object.isRequired,
    page: PropTypes.object.isRequired,
    pageTemplate: PropTypes.object.isRequired,
    project: PropTypes.object.isRequired,
    context: PropTypes.object.isRequired,
    image: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
    ui: PropTypes.object.isRequired,
    children: PropTypes.node
  }

  static contextTypes = {
    router: PropTypes.object.isRequired
  }

  constructor(...args) {

    super(...args)

    this.changeInProgressMutex = false

    let currProjectId = null
    this.handler = autorun('AUTORUN: url state changed', () => {
      // need to watch current for project switching
      // eslint-disable-next-line
      const projectId = this.props.project.current &&
        this.props.project.current.id

      if (currProjectId !== projectId || this.props.project.calledToChangeCurrent) {
        this.projectChanged()
        this.props.project.calledToChangeCurrent = false
      }
      currProjectId = projectId
    })
    this.OS = (navigator.appVersion.indexOf('Mac') !== -1)
      ? 'MacOS'
      : 'WindowsOS'
  }


  componentWillMount() {
    const projectId = this.ensureValidId(this.props.params.projectId)
    if (projectId) {
      this.openProject(projectId)
    }
    // if project id is unknown, then load it from history or the collection
    this.loadList(!!projectId)
  }

  componentWillReceiveProps(nextProps) {

    const newProjectId = deepGet(nextProps, 'params.projectId')
    const oldProjectId = deepGet(this.props, 'params.projectId')

    if (newProjectId !== oldProjectId) {
      this.projectChanged(nextProps)
      this.props.page.setCurrent(null)
    }

  }

  componentWillUnmount() {

    if (this.handler) {
      this.handler()
      this.handler = null
    }

  }

  getFallbackProjectId() {
    const projectCollection = this.props.project.projectPartialsCollection
    const projectHistory = this.props.project.projectHistory

    return projectHistory && projectHistory.length
      && projectCollection.find(proj => proj.id === projectHistory[0])
      ? projectHistory[0]
      : projectCollection[0].id
  }

  getFallbackPageId(projectId) {
    const project = this.props.project.getById(projectId) || this.props.project.getPartialById(projectId)

    let page = null
    if (project) {
      // The last GET page/{:id} the user did
      const lastOpenedPage = authStore.user.lastOpenedPage
      const pageModel = this.props.page.pagePartialsCollection.find(pagep => pagep.id === lastOpenedPage)
      if (lastOpenedPage && pageModel && pageModel.projectId * 1 === projectId) {

        return lastOpenedPage
      }

      page = project.getDefaultPage()
    }
    return page ? page.id : null
  }

  projectChanged(props = null) {

    const { project, page, params } = props || this.props

    if (
      // if there is a change in progress already, or
      this.changeInProgressMutex
      // while the collection is not loaded...
      || !project.projectPartialsCollection.length
      // or if there is no current project to work with
      || !project.hasCurrent
    ) {
      // don't do anything
      return
    }

    const currProjectId = deepGet(this.props, 'project.current.id')
    const controlCenterOpen = (params.subview === 'control-center')
    const paramsProjectId = params && params.projectId && params.projectId * 1
    const paramsPageId = params && params.pageId && params.pageId * 1

    // if there is a current but not what's on the url, then we need to open it
    // if no current project, build the url
    if (!paramsProjectId) {
      const projectToLoad = this.getFallbackProjectId()
      this.setUrl(projectToLoad)
    }

    else if (!currProjectId || currProjectId !== paramsProjectId) {
      page.flush()

      const projectToLoad = this.ensureValidId(paramsProjectId) || this.getFallbackProjectId()

      if (project.loading || !projectToLoad) {
        return
      }

      project.load(projectToLoad)
        .then((loadedProject) => {
          const pageId =  this.getFallbackPageId(loadedProject.id)
          if (!paramsPageId && !controlCenterOpen) {
            this.setUrl(loadedProject.id, pageId)
          }
          else {
            this.setUrl(loadedProject.id, paramsPageId)
          }
        })
    }

    // if project exists on url, but no page on the url
    else if (!paramsPageId && !controlCenterOpen) {
      const pageId = this.getFallbackPageId(currProjectId)
      if (!paramsPageId && !controlCenterOpen) {
        this.setUrl(currProjectId, pageId)
      }
    }
  }

  setUrl(projectId, pageId) {
    let url = null
    const { params } = this.props

    const controlCenterOpen = (params.subview === 'control-center')

    if (projectId) {
      url = `/publish/${projectId}`
    }

    if (controlCenterOpen) {
      url = `${url}/control-center`
    }

    else {
      if (pageId) {
        url = `${url}/page/${pageId}`
      }

      if ('articleId' in params) {

        url = `${url}/article/${params.articleId}`

        if ('articleAction' in params) {
          url = `${url}/${params.articleAction}`
        }

        if ('articleActionDetails' in params) {
          url = `${url}/${params.articleActionDetails}`
        }

      }

      if ('widgetId' in params) {

        url = `${url}/widget/${params.widgetId}`

        if ('widgetAction' in params) {
          url = `${url}/${params.widgetAction}`
        }

      }
    }

    if (url) {
      this.context.router.push(url)

      this.props.context.setCurrentIds({ ...params, pageId })
      // keep the project history in sync
      this.props.project.addToProjectHistory(params.projectId)
    }

  }


  openProject(projectId) {
    if (!projectId) {
      throw new Error('missing id to open project')
    }

    return this.props.project.load(projectId, {
      setCurrent: true
    })

  }

  /**
   * @param {Boolean} loadFallbackProject - loads fallback project from history or collection
   * @returns projectPartial collection
   */
  loadList(loadFallbackProject) {
    if (!this.props.project.projectPartialsCollection.length) {
      return this.props.project.loadProjects()
        .then(() => {
          if (!loadFallbackProject) {
            const projectIdToUse = this.getFallbackProjectId()
            || this.project.projectPartialsCollection[0] && this.project.projectPartialsCollection[0].id
            return this.openProject(projectIdToUse).then(() => this.props.project.projectPartialsCollection)
          }
          return this.props.project.projectPartialsCollection
        })
    }
    return this.props.project.projectPartialsCollection
  }

  ensureValidId(id) {
    // it happens that the id is a string reading 'undefined'
    return id && id !== 'undefined' && ensureNumber(id)
      ? ensureNumber(id)
      : null
  }

  get brand() {
    return <img className="logo" role="presentation" src="/images/atr-geneva-red.svg" />
  }

  get links() {
    return (<div className={css.navbarTitleText}>
      <span>WEBSITE</span>
      <span>MANAGER</span>
    </div>)
  }

  renderNavbar() {

    return (<Navbar
      navbarStyle={`primary ${css.pageTitlebar}`}
      ui={this.props.ui}
      brand={this.brand}
      links={this.links}
    />)

  }


  renderMenubar() {

    const { project, page, context, ui } = this.props

    return (<Menubar
      key="publishMenubar"
      menuBarStyles={css.pageMenubar}
      ref="menubar"
      project={project}
      page={page}
      context={context}
      ui={ui}
    />)

  }

  render() {

    return (<div className="grid-block vertical">

      {this.renderNavbar()}

      <div
        className={classNames('grid-block', {
          hidden: this.props.image.editing
        })}
      >

        <div className="grid-block">
          <div className="grid-block">

            <div className="grid-block vertical">

              {this.renderMenubar()}

              <div className="grid-block">

                <ProjectNavigationContainerDisplayer
                  key="projectNavigationContainerDisplayer"
                  {...this.props}
                />
                {this.props.project.hasCurrent
                  ? this.props.children
                  : <LoadingScreenDisplayer key="LoadingScreenDisplayer" />
                }
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>)

  }

}
