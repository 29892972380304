import { store as uiState } from '../../ui'

export const CreateVersionInspector = {
  before: (target) => {
    uiState.addStatusInfo({
      id: target.constructor.name,
      priority: 'high',
      name: 'version.creation-started'
    })
  },
  then: (target) => {
    uiState.addStatusInfo({
      id: target.constructor.name,
      priority: 'high',
      name: 'version.creation-completed'
    }, 2000)
  },
  catch: (target, error) => {
    let name = 'version.creation-failed'
    let retval = error

    if (error.message.indexOf('Cannot create a new version') > -1) {
      // This happens if it makes no sense to create a new version.
      // Such cases are valid, so return true
      name = 'version.creation-failed-cannot-create'
      retval = true
    }

    uiState.addStatusInfo({
      id: target.constructor.name,
      priority: 'high',
      name
    }, 4000)

    return retval
  }
}
