import { store as uiStore } from '../../../ui'

export function DefaultSimpleInspector() {
  this.exec = (targetId) => {
    uiStore.addStatusInfo(
      {
        id: targetId,
        priority: 'high',
        name: this.messageId,
      },
      2000
    )
  }
}
