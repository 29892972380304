import eventEmitter from 'event-emitter'
export default class Messenger {

  constructor(options: Object = {}) {

    this.options = options

    this.numChannels = 0
    this.channels = {}

    this.setupBus()
    eventEmitter(this)

  }

  static getInstance(opts: Object = {}) {
    if (!Messenger.instance) {
      Messenger.instance = new Messenger(opts)
    }
    return Messenger.instance
  }

  initRecipientForModule(opts, onAdded) {

    const channel = this.getChannel(opts)
    const message = {
      type: 'init',
      data: opts.message || 'welcome'
    }

    this.channels[channel] = opts

    onAdded(channel)

    this.sendMessage(channel, message)

  }

  getChannel(opts) {

    const reuseChannel = !!opts.channel && this.channels[opts.channel]
    const channel = opts.channel || (opts.moduleName + this.numChannels)

    if (reuseChannel) {
      this.numChannels += 1
    }

    return channel

  }


  setupBus() {

    this.bus = window

    if (this.bus) {
      this.bus.addEventListener('message', this.handleMessage.bind(this))
    }

  }

  handleMessage(e) {
    // Bail out if message event was emitted by react tools
    if (e.data.source && e.data.source.indexOf('react') !== -1) {
      return
    }

    const message = JSON.parse(e.data)

    if (!message.channel) {
      throw new Error('Invalid message! Message channel missing!')
    }

    if (!(message.channel in this.channels)) {
      throw new Error(`Unknown channel "${message.channel}"!`)
    }

    const handler = this.channels[message.channel].moduleName

    this.emit(
      `${handler}:message`,
      message.channel,
      message.message
    )
  }

  sendMessage(channel, message) {

    message = {
      channel,
      message
    }

    message = JSON.stringify(message)

    this.channels[channel].recipient
      .postMessage(
        message,
        this.channels[channel].recipientUrl
      )
  }
}
