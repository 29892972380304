import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer } from 'mobx-react'

import { useGoogleTranslate } from '../../config'
import { List as VersionList } from '../version'
import connectTranslateBar from '../connectors/connectTranslateBar'
import connectGoogleTranslateBar from '../connectors/connectGoogleTranslateBar'
import connectCenshareSidebar from '../../censhare/connectors/connectCenshareSidebar'
import SidebarHeader from './SidebarHeader'
import SidebarTools from './SidebarTools'

@observer
export default class ArticleSideBar extends Component {

  static propTypes = {
    className: PropTypes.string,
    article: PropTypes.object.isRequired,
    page: PropTypes.object.isRequired,
    project: PropTypes.object.isRequired,
    paneState: PropTypes.bool,
    template: PropTypes.object.isRequired,
    tool: PropTypes.string,
    tools: PropTypes.array.isRequired,
    currentVersion: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ])
  }

  renderSidebarContent() {

    const url = window.location.pathname
    const articleBaseLink = url.replace(
      /(page\/\d+).*/, `$1/article/${this.props.article.current.id}`
    )

    const TranslateBar = connectTranslateBar()
    const GoogleTranslateBar = connectGoogleTranslateBar()
    const CenshareSidebar = connectCenshareSidebar()

    let tool = this.props.tool

    // check if sidebar feature is allowed for customer
    const allowedTools = this.props.tools.filter((el) => {
      if (!useGoogleTranslate && el.link === 'google-translate') {
        if (tool === 'google-translate') {
          tool = 'edit'
        }
        return false
      }

      // Remove censhare if project has no connection
      if (this.props.page
        && this.props.page.current
        && this.props.project
        && !this.props.project.current.hasCenshare
        && el.link === 'censhare') {

        if (tool === 'censhare') {
          tool = 'edit'
        }
        return false
      }

      return true
    })

    // If a version is currently displayed, redirect directly to VersionList
    if (this.props.article.current && this.props.article.current.isVersion) {
      tool = 'version'
    }

    switch (tool) {
      case 'version':
        return [
          <SidebarHeader
            key="1"
            articleBaseLink={articleBaseLink}
            tool={this.props.tools[0]}
          />,
          <VersionList
            key="2"
            article={this.props.article}
            currentVersion={(this.props.currentVersion
              || this.props.article.current.instanceVersion)}
            articleBaseLink={articleBaseLink}
          />]
      case 'translate':
        return [
          <SidebarHeader
            key="1"
            articleBaseLink={articleBaseLink}
            tool={this.props.tools[1]}
          />,
          <TranslateBar
            key="2"
            articleStore={this.props.article}
          />]
      case 'google-translate':
        return [
          <SidebarHeader
            key="1"
            articleBaseLink={articleBaseLink}
            tool={this.props.tools[2]}
          />,
          <GoogleTranslateBar
            key="2"
            articleStore={this.props.article}
          />]
      case 'censhare':
        return [
          <SidebarHeader
            key="1"
            articleBaseLink={articleBaseLink}
            tool={this.props.tools[3]}
          />,
          <CenshareSidebar
            key="2"
            pageStore={this.props.page}
            templateStore={this.props.template}
            articleStore={this.props.article}
            projectStore={this.props.project}
          />]
      default:
        return (<SidebarTools
          article={this.props.article}
          template={this.props.template}
          tools={allowedTools}
          articleBaseLink={articleBaseLink}
        />)
    }
  }

  render() {

    if (!this.props.paneState || !this.props.article.current.id) {
      return null
    }

    return (<div className={this.props.className}>
      {this.renderSidebarContent()}
    </div>)

  }
}
