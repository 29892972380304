import * as PropTypes from 'prop-types'
import React from 'react'
// import { inject, observer } from 'mobx-react'
import { withRouter } from 'react-router'

import createParentPropsInjector from '../../../../shared/utils/create-parent-props-injector'
// import validate from '../../../../shared/utils/validate-object'

import MoveContentInWidgetCommand from './commands/MoveContentInWidgetCommand.ts'

import PageConnectedMoveArticleButton from './components/PageConnectedMoveArticleButton'

import DistinctModelObserver from '../../../../shared/view-models/DistinctModelObserver.ts'
import viewModelConnector from '../../../../shared/lib/view-model'

import commandConnector from '../../../../shared/lib/command'

export default function connectMoveArticleToPage(Target, parentProps: Object) {
  const injectParentProps = createParentPropsInjector(parentProps)

  function MoveArticleToPageConnector({
    // props that are NOT passed to the wrapped component
    // other props
    ...rest
  }) {
    return <PageConnectedMoveArticleButton {...rest} Target={Target} />
  }

  MoveArticleToPageConnector.propTypes = {
    router: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    disabled: PropTypes.bool,
    page: PropTypes.object,
    onGetContext: PropTypes.func.isRequired,
  }

  return commandConnector({
    MoveContentInWidgetCommand,
  })(
    viewModelConnector([
      {
        class: DistinctModelObserver,
        data: [
          {
            type: 'widget',
            id: DistinctModelObserver.fromContext('widgetId'),
            as: 'item',
          },
        ],
        as: 'currentlyOpenWidget',
      },
    ])(withRouter(injectParentProps(MoveArticleToPageConnector)))
  )
}
