import React from 'react'
import {
  Modifier,
  EditorState
} from 'draft-js'
import config from '../../../config'
import { deepGet } from '../../../shared/obj'

const Link = (props) => {
  const data = props.contentState.getEntity(props.entityKey).getData()
  return (
    <a href={data.href} title={data.title} target={data.target} rel={data.rel} className={data.appearance}>
      {props.children}
    </a>
  )
}

export const ENTITY_CONTROL = {
  LINK: {
    type: 'LINK',
    icon: '#icon-link',
    decorator: Link,
    // Keep attributes in sync with Link return properties above
    attributes: ['href', 'class', 'appearance', 'rel', 'target', 'title'],
    whitelist: {
      href: '^(?![#/])',
      class: ''
    },
  }
}

/**
 * @param {EditorState} editorState
 * @param {String} entityType
 * @param {Object} entityData
 * @param {String} entityText
 * @param {'IMMUTABLE'|'MUTABLE'} entityMutability
 * @returns nextState
 */
export function createEntityWithText(
  editorState,
  entityType,
  entityData,
  entityText,
  entityMutability = 'IMMUTABLE',
) {
  const contentState = editorState.getCurrentContent()
  const selection = editorState.getSelection()
  const contentStateWithEntity = contentState.createEntity(
    entityType,
    entityMutability,
    entityData,
  )
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey()

  let nextContentState

  if (selection.isCollapsed()) {
    nextContentState = Modifier.insertText(
      contentState,
      selection,
      entityText,
      null,
      entityKey,
    )
  }
  else {
    nextContentState = Modifier.replaceText(
      contentState,
      selection,
      entityText,
      null,
      entityKey,
    )
  }

  const nextState = EditorState.push(
    editorState,
    nextContentState,
    'insert-fragment',
  )

  return nextState
}

export function getCustomHTMLMap() {
  // Can be overwritten by .geneva.config
  const tagNames = deepGet(config, 'editor.tagNames') || {}
  return {
    defaultBlockTag: 'p', // Blocks caused by pressing return
    inlineStyles: {
      BOLD: {
        element: tagNames.BOLD || 'b'
      },
      ITALIC: {
        element: tagNames.ITALIC || 'i'
      },
      STRIKETHROUGH: {
        element: tagNames.STRIKETHROUGH || 'strike'
      },
      SUPERSCRIPT: {
        element: tagNames.SUPERSCRIPT || 'sup'
      },
      SUBSCRIPT: {
        element: tagNames.SUBSCRIPT || 'sub'
      }
    }
  }
}

export function getCustomFunctionMap() {
  return {
    /* when adding, add also to draft/index.js constructor
    blockStyleFn: (block) => {
      if (block.getData().get('color')) {
        return {
          style: {
            color: block.getData().get('color')
          }
        }
      }
    },
    */
    entityStyleFn: (entity) => {
      const entityType = entity.get('type').toLowerCase()
      if (entityType === 'link') {
        const data = entity.getData()
        return {
          element: 'a',
          attributes: {
            href: data.href,
            title: data.title,
            target: data.target,
            rel: data.rel,
            className: data.appearance,
          },
          style: {
            // Put styles here...
          },
        }
      }
      return null
    }
  }
}

export function sliceContent(editorState, start, end) {
  const anchorOffset = start < end ? start : end
  const focusOffset = start < end ? end : start
  const contentState = editorState.getCurrentContent()
  const selection = editorState.getSelection().merge({
    anchorOffset,
    focusOffset
  })
  return Modifier.removeRange(
    contentState,
    selection,
    ''
  )
}
