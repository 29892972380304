import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { autobind } from 'core-decorators'
import { withRouter } from 'react-router'
import { formatMessage } from '../../../translations'

import commandConnector from '../../../shared/lib/command'
import { dispatcher } from '../../../shared/lib/command'
import EditItemCommand from '../../../shared/commands/EditItemCommand.js'
import Navbar, { BrandSection, FixedSection, FlexSection } from '../../../shared/components/Navbar'
import Icon from '../../../shared/icons'
import StatusList from '../../../shared/components/StatusList'

import * as css from '../../styles.scss'
import { projectStore } from '../../reducers'

@dispatcher
@observer
class ProjectNavbar
  extends Component {

  static propTypes = {
    ui: PropTypes.object.isRequired,
    navbarStyle: PropTypes.string,
    pageStore: PropTypes.object.isRequired,
    id: PropTypes.string,
  }

  static contextTypes = {
    router: PropTypes.object.isRequired
  }

  @autobind
  handleCloseClick() {

    if (this.props.pageStore.current && this.props.pageStore.current.id) {
      this.context.dispatch(
        this.props.commands.EditItemCommand,
        {
          router: this.props.router,
          type: 'page',
          id: this.props.pageStore.current.id
        }
      )
    }
    else if (projectStore.current && projectStore.current.id && projectStore.current.id === this.props.params.projectId * 1) {
      const pageFollowBack = projectStore.current.getDefaultPage()
      this.context.dispatch(
        this.props.commands.EditItemCommand,
        {
          router: this.props.router,
          type: 'page',
          id: pageFollowBack.id
        }
      )
    }
    else {
      this.context.dispatch(
        this.props.commands.EditItemCommand,
        {
          router: this.props.router,
          type: 'publish',
          id: this.props.params.projectId * 1
        }
      )
    }

  }

  render() {

    const {
      navbarStyle,
      ui
    } = this.props

    return (<Navbar
      navbarStyle={navbarStyle}
      id={this.props.id}
    >

      <BrandSection>
        {formatMessage({ id: 'page.control-center' })}
      </BrandSection>

      <FlexSection>
        <StatusList ui={ui} className={css.controlCenterStatusList} />
      </FlexSection>

      <FixedSection>
        <a
          onClick={this.handleCloseClick}
          className="close-button"
        >
          <Icon name="ion-ios-close-empty" />
        </a>
      </FixedSection>

    </Navbar>)
  }

}

export default commandConnector({
  EditItemCommand
})(
  withRouter(ProjectNavbar)
)
