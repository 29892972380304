import { observable } from 'mobx'

export default
class WidgetContentAction {

  static INSERT = 'insert'

  static REMOVE = 'remove'

  constructor(
    widget,
    position,
    action = WidgetContentAction.INSERT
  ) {

    if (!Array.isArray(position) || position.length < 1) {
      throw new Error(
        '`WidgetContentAction` expects a position array with at '
        + 'least one entry as second parameter!'
      )
    }

    this.widget = widget
    this.position = this.getPosition(position)

    try {
      this.parentPosition = this.getPositionParent(
        position,
        action === WidgetContentAction.INSERT
      )
    }
    catch (ex) {
      if (ex.message === 'Entry Missing') {
        throw new Error(
          // eslint-disable-next-line prefer-template
          '`WidgetContentAction` determined that the parent position it should '
          + 'work upon is `undefined`! Apparently the `widget.content` passed to '
          + 'the action does not contain an entry for the position '
          + JSON.stringify(position) + '.'
        )
      }
      else {
        throw ex
      }
    }

    this.action = action

  }

  /**
   *
   * @param {any} [data]
   *
   * NOTE: THIS ANNOTATION IS REQUIRED FOR TS INTEROP!
   */
  execute(data) {
    return this[this.action](data)
  }

  /**
   * @private
   */
  insert(data) {
    if (this.parentPosition) {
      return this.parentPosition.splice(this.position, 0, data)
    }
    return this.widget.splice(this.position, 0, data)
  }

  /**
   * @private
   */
  remove() {
    if (this.parentPosition) {
      return this.parentPosition.splice(this.position, 1)
    }
    return this.widget.splice(this.position, 1)
  }

  /**
   * @private
   */
  getPosition(position) {
    return position.slice(-1)[0]
  }

  /**
   * @private
   */
  getPositionParent(
    position,
    createIfNotExist = false
  ) {
    return position.slice(0, -1)
      .reduce((memo, pos) => {
        if (!memo[pos]) {
          if (createIfNotExist) {
            memo[pos] = observable([])
          }
          else {
            throw new Error('Entry Missing')
          }
        }
        return memo[pos]
      }, this.widget.content)
  }


}
