import PropTypes from 'prop-types'
import React from 'react'

const LoadingInfo = (props) => {
  return (<div className="grid-frame">
    <div className="grid-content v-align align-center">
      <div className="grid-block text-center align-center">
        <div className="vertical">
          {props.children}
        </div>
      </div>
    </div>
  </div>)
}

LoadingInfo.propTypes = {
  children: PropTypes.node
}

export { LoadingInfo as default }
