import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import { autobind } from 'core-decorators'
import { formatMessage } from '../../translations'

import { store as uiStore } from '../../ui'

import { store as contextStore } from '../../context'
// eslint-disable-next-line max-len
import InsertFileFromContextIntoArticlePlaceholderCommand from '../commands/insert-file-from-context-into-article-placeholder'
import alert from '../components/Alert'

export default function connectFileToolsToCommands(Target) {
  class ConnectedTarget extends Component {
    static propTypes = {
      value: PropTypes.string,
      onBeforeChangeFile: PropTypes.func,
      ui: PropTypes.object,
    };

    constructor(props) {
      super(props)
      this.state = {
        value: props.value,
      }
    }

    componentWillReceiveProps(nextProps) {
      if (nextProps.value !== this.state.value) {
        this.setState({
          value: nextProps.value,
        })
      }
    }

    @autobind
    handleReplaceFile() {
      contextStore.assert('file')
      new InsertFileFromContextIntoArticlePlaceholderCommand()
        .exec()
        .catch((err) => {
          console.error(err)
          this.setState({
            value: '',
            sourceType: null,
            file: null,
            url: '',
          })
        })
    }

    @autobind
    handleFileImportFromBynder() {
      const { target } = this.props.context

      return uiStore.openDialog('bynder', target)
        .then((data) => {
          if (data.status === 'committed') {
            this.handleFileChange(data.result)
          }
        })
    }

    @autobind
    handleRemoveFile() {
      // new RemoveFileFromContextFromArticlePlaceholderCommand()
      // .exec()
    }

    @autobind
    handleFileChange(input) {
      if (this.props.onBeforeChangeFile) {
        this.props.onBeforeChangeFile()
      }

      // waiting for possible refocusing in the
      // onBeforeChangeFile handler
      setTimeout(() => {
        const context = contextStore

        let ok = false
        let fileData
        let mimeType

        if (typeof input === 'string') {
          ok = true
          fileData = this.collectFileDataFromUrl(input)

          // TODO this needs to go
          const urlObj = new URL(fileData.url)
          mimeType = `file/${urlObj.pathname.split('/').slice(-1)[0]}`
        }
        else if (Array.isArray(input)) {
          if (input[0].file && input[0].file instanceof File) {
            fileData = this.collectFileDataFromFile(input)
            ok = !!fileData.file
          }
          else if (input[0] instanceof Blob) {
            fileData = this.collectFileDataFromBlob(input)
            ok = !!fileData.file
          }

          mimeType = fileData.file.type
        }

        this.setState(fileData)

        if (ok) {
          // ensure the context
          contextStore.createFromElement({
            pid: context.target.pid,
            article: context.target.article,
            type: context.target.type,
            multiple: context.target.multiple,
            mimeType,
            ...fileData,
          })

          this.handleReplaceFile()
        }
      }, 1)
    }

    collectFileDataFromFile(files) {
      let file
      let value
      const sourceType = 'file'

      files = this.validateFiles(files)

      if (files.length) {
        file = files[0].file
        value = files[0].content
      }

      return {
        file,
        value,
        sourceType,
      }
    }

    collectFileDataFromBlob(blobs) {
      let file
      let value
      const sourceType = 'file'

      blobs = this.validateBlobs(blobs)

      if (blobs.length) {
        file = blobs[0]
        value = blobs[0].name
      }

      return {
        file,
        value,
        sourceType,
      }
    }

    collectFileDataFromUrl(url) {
      return {
        url,
        value: url,
        sourceType: 'url',
      }
    }

    validateFiles(files = []) {
      const errors = []
      files = files.filter((file) => {
        if (!file.success) {
          errors.push(file)
        }

        return file.success
      })

      if (errors.length) {
        uiStore.addStatusInfo({
          data: {
            count: errors.length,
          },
          type: 'error',
          priority: 'high',
          id: 'file-error',
        })
        return null
      }

      return files
    }

    validateBlobs(blobs = []) {
      const errors = []
      blobs = blobs.filter((blob) => {
        if (!blob.success || blob.type !== 'application/zip') {
          errors.push(blob)
        }

        return blob.success
      })

      if (errors.length) {
        uiStore.addStatusInfo({
          data: {
            count: errors.length,
          },
          type: 'error',
          priority: 'high',
          id: 'file-error',
        })
        return null
      }

      return blobs
    }

    @autobind
    handleError(err) {
      const names = err.names ? err.names.join(', ') : ''

      alert(
        formatMessage({ id: err.message }, { names }),
        formatMessage({ id: `${err.message}-title` })
      )
    }

    render() {

      return (<Target

        {...this.props}
        {...this.state}

        onErrorFile={this.handleError}
        onEditFile={this.handleEditFile}
        onChangeFile={this.handleFileChange}
        onReplaceFile={this.handleReplaceFile}
        onRemoveFile={this.handleRemoveFile}
        onFileImportFromBynder={this.handleFileImportFromBynder}

      />)

    }
  }

  return ConnectedTarget
}
