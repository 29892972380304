import * as PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'
import * as css from './styles.scss'

export default function DropdownContainer(props) {
  return (
    <div
      className={classNames(
        props.className || 'dropdown-container',
        css.dropdownContainer
      )}
    >
      {props.children}
    </div>
  )
}

DropdownContainer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}
