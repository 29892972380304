import React from 'react'
import ReactDOM from 'react-dom'
import config from '../config'
import LoadingInfo from './components/LoadingInfo'

const ERROR_UNAUTHORIZED = 401

export default ({ root, err }) => {
  const destination = config && config.am && config.am.url
  if (err) {
    console.log(err.status, err.message)
    console.log(err)
  }

  if (destination && err.status === ERROR_UNAUTHORIZED) {
    let ending = ''
    if (window.location.pathname) {
      ending = `?redirectUrl=${encodeURIComponent(window.location)}`
    }

    window.location.href = `${destination}${ending}`
  }
  // if normal login needed, then don't show an error
  else {
    ReactDOM.render(
      (<LoadingInfo>
        <h1>Upps...</h1>
        <p>An error occured while loading the configuration for Geneva. </p>
      </LoadingInfo>),
      root
    )
  }
}
