import * as PropTypes from 'prop-types'
import React from 'react'
import { withRouter } from 'react-router'

import EditItemCommand from '../../shared/commands/EditItemCommand.js'

import commandConnector from '../../shared/lib/command'

import ArticleHeaderWarning from '../components/ArticleHeaderWarning'

export default function connectArticleHeaderWarningToCommands() {
  // Connector function
  function ArticleHeaderWarningToCommandsConnector({ ...rest }) {
    return <ArticleHeaderWarning {...rest} />
  }

  // Component props
  ArticleHeaderWarningToCommandsConnector.propTypes = {
    router: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    article: PropTypes.object.isRequired,
  }

  // Inject commands
  return commandConnector({
    EditItemCommand,
  })(withRouter(ArticleHeaderWarningToCommandsConnector))
}
