import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { withRouter } from 'react-router'
import { autobind } from 'core-decorators'
import keydown from 'react-keydown'
import { store as contextStore } from '../../../context'
import { templateStore as articleTemplateStore } from '../../../template/reducers'
import EditItemCommand from '../../../shared/commands/EditItemCommand.js'
import commandConnector from '../../../shared/lib/command'
import { dispatcher } from '../../../shared/lib/command'
import Navbar, {
  FlexSection,
  BrandSection
} from '../../../shared/components/Navbar'
import StatusList from '../../../shared/components/StatusList'
import { articleMandatoryFields as articleMandatoryFieldsDialog } from '../../containers/dialogs'
import { shortcut } from '../../../shared/const'
import ArticleNavbarTools from './Tools'

import * as css from '../../styles.scss'


@dispatcher
class ArticleNavbar extends Component {

  static propTypes = {
    article: PropTypes.object,
    brand: PropTypes.node.isRequired,
    id: PropTypes.string,
    language: PropTypes.string,
    navbarStyle: PropTypes.string,
    ui: PropTypes.object.isRequired,
  }

  @autobind
  closeEditor() {
    const { article } = this.props
    // TODO: Put an unlocking inside edit-item command
    // TODO: Do this with edit-item command
    // Release the locked article
    article.unsetCurrent()

    const ids = contextStore.getCurrentIds() || { pageId: this.props.params.pageId * 1 }
    let id = ids.widgetId ? ids.widgetId : ids.pageId
    // if deeplinked, ids might not be loaded for widget or page
    if (!id) {
      id = (this.props.params.widgetId || this.props.params.pageId) * 1
    }
    const type = ids.widgetId ? 'widget' : 'page'

    this.context.dispatch(
      this.props.commands.EditItemCommand,
      {
        router: this.props.router,
        type,
        id
      }
    )
  }

  @autobind
  @keydown(shortcut.CLOSE_APP)
  handleClose() {
    const { article } = this.props

    let missingMandatoryField = false
    // verify if mandatory fields are defined, that they are filled in
    articleTemplateStore.getLoadedTemplateById(article.current.templateId)
      .then((template) => {
        const mandatorySet = (template.dependencies.spec
            && template.dependencies.spec.mandatoryFields) || []

        mandatorySet.forEach((mandatoryId) => {
          if (!article.current.getDataInPlaceholder(mandatoryId)) {
            missingMandatoryField = true
          }
        })
      })
      .finally(() => {

        if (missingMandatoryField) {
          articleMandatoryFieldsDialog({
          }).then(() => {
            // the 'proceed' is the non-primary button, so 'then' is valid to leave
            this.closeEditor()
          })
        }
        else {
          this.closeEditor()
        }
      })
  }

  render() {
    const {
      navbarStyle,
      brand,
      ui,
      id,
      language
    } = this.props

    return (<Navbar
      navbarStyle={navbarStyle}
      id={id}
    >

      <BrandSection>
        {brand}
      </BrandSection>

      <FlexSection>

        <StatusList
          ui={ui}
          className={css.articleStatusList}
        />

      </FlexSection>

      <ArticleNavbarTools
        language={language}
        onClose={this.handleClose}
      />

    </Navbar>)
  }
}

export default commandConnector({
  EditItemCommand
})(
  withRouter(observer(ArticleNavbar))
)
