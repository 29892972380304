import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Link as RouterLink } from 'react-router'

import { observer } from 'mobx-react'
import { autobind } from 'core-decorators'
import classNames from 'classnames'

import { FormattedMessage } from '../../translations'
import { hasPermissions } from '../../shared/utils/user-rights'
import { filterDivAttrs, filterElemAttrs, A_ATTRS } from '../../shared/react-helpers'
import connectLimitedProjectDropdown from '../../shared/connectors/connectLimitedProjectDropdown'
import connectLanguageDropdown from '../connectors/connectLanguageDropdown'
import Dropdown, {
  DropdownContainer, Entry, Separator
} from '../../shared/components/Dropdown'
import GenevaDropdownOpener from '../../ui/components/GenevaDropdownOpener'
import { projectStore } from '../reducers'

const LimitedProjectDropdown = connectLimitedProjectDropdown()
const LanguageDropdown = connectLanguageDropdown()

const Link = (props) => {
  return <RouterLink {...filterElemAttrs(props, [...A_ATTRS, 'to'])}>{props.children}</RouterLink>
}

Link.propTypes = {
  children: PropTypes.node
}

const ObservedLinkEntry = observer((props) => {

  const { url, entry, id } = props

  const handleClick = ({ target }) => {
    if (target && target.value) {
      projectStore.calledToChangeCurrent = true
    }
  }

  return (<Entry
    key={id}
    component={Link}
    {...entry.state}
    identifier={id}
    onClick={handleClick}
    to={url}
  >
    {entry.name}
  </Entry>)

})

@observer
class PMProjectToolbar extends Component {

  static propTypes = {
    className: PropTypes.string,
    ui: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    project: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.permissions = hasPermissions(['getProject', 'getProjectLang', 'controlCenter'])
  }

  @autobind
  handleEntryClick({ target }) {
    const entry = this.props.ui.projectContextTools[target.identifier]
    entry.handleClick()
    if (entry.handleQueryState) {
      entry.handleQueryState(entry.state)
    }
  }


  renderDropdownEntry(entry) {
    if (!entry) return null

    return (<ObservedLinkEntry
      url={`/publish/${entry.id}`}
      key={entry.key}
      entry={entry}
      id={entry.id}
    />)
  }

  renderControlCenterEntry() {
    const projectId = this.props.project.current && this.props.project.current.id ? this.props.project.current.id : 1
    return (<div>

      {this.permissions.length > 1
        ? <Separator />
        : null}

      <li>
        <RouterLink to={`/publish/${projectId}/control-center`}
          style={{
            color: 'black'
          }}
          className="dropdown-entry"
        >
          <FormattedMessage id="page.control-center" />
        </RouterLink>
      </li>
    </div>)
  }

  render() {

    const { project, disabled } = this.props
    const divProps = filterDivAttrs(this.props)

    return (<div {...divProps}
      className={classNames(this.props.className, 'toolbar', 'v-align')}
    >
      <DropdownContainer>
        <GenevaDropdownOpener
          clickToClose
          disabled={disabled || !this.permissions.length}
          caret={false}
          arrow
        >
          <FormattedMessage id="project.project" />
        </GenevaDropdownOpener>
        <Dropdown>

          {this.permissions.getProject
            ? <LimitedProjectDropdown
              currentProject={project.current}
              onRenderEntry={this.renderDropdownEntry}
            />
            : null}

          {this.permissions.getProjectLang
            ? <LanguageDropdown
              currentProject={project.current}
              onRenderEntry={this.renderDropdownEntry}
            />
            : null}

          {this.permissions.controlCenter
            ? this.renderControlCenterEntry()
            : null}

        </Dropdown>
      </DropdownContainer>

    </div>)

  }
}

export default PMProjectToolbar
