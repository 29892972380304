import { closest } from './dom-helpers'
import BaseCommand from './BaseCommand'

const blockElementNames = ['P', 'LI', 'DIV', 'TABLE']
function isBlockElement(node) {
  return blockElementNames.indexOf(node.nodeName) !== -1
}


function createDocumentFragmentFromNode(node) {
  const frag = document.createDocumentFragment()
  const tmp = document.createElement('div')
  let child = tmp.firstChild

  tmp.innerHTML = node.innerHTML

  while (child) {
    frag.appendChild(child)
    child = tmp.firstChild
  }

  return frag
}

export
class FootnoteCommandImpl
extends BaseCommand {

  static defaultData = {
    hasFootnote: false
  }

  static defaultOpts = {
    tagName: 'span',
    className: 'footnote'
  }

  static commandName = 'footnote'

  get footnoteSelector() {
    return `${this.opts.tagName}.${this.opts.className}`
  }


  getInitialData() {

    const selection = new this.scribe.api.Selection()

    const footnote = this.getFootnoteOutsideOfSelection(selection) ||
      this.getFootnoteInsideOfSelection(selection)

    return {
      hasFootnote: !!footnote
    }

  }

  // todo
  // 1 .this should divide a footnote if the cursor is in the middle of it
  // 2. or unset the footnote for the selection, if it is at the
  // front or the end of it
  removeFootnote() {
    let selection = new this.scribe.api.Selection()
    let parent = selection.range.commonAncestorContainer

    // in cases where the range is collapsed, look fo the closest
    // ancestor footnotes node and select all it's contents
    if (selection.range.collapsed) {

      const parentFootnote = closest(parent, this.footnoteSelector)
      if (parentFootnote) {
        selection.removeMarkers()
        parentFootnote.insertBefore(
          this.createMarker(),
          parentFootnote.firstChild
        )
        parentFootnote.appendChild(this.createMarker())
        selection.selectMarkers(true)
        selection = new this.scribe.api.Selection()
        parent = parentFootnote
      }
    }

    let child

    if (parent.nodeType === Node.TEXT_NODE) {
      parent = parent.parentNode
    }

    let containedFootnoteNodes = parent.querySelectorAll(this.footnoteSelector)
    containedFootnoteNodes = Array.prototype.slice.call(containedFootnoteNodes);

    const containingFootnoteNode = closest(parent, this.footnoteSelector)
    if (containingFootnoteNode) {
      containedFootnoteNodes.unshift(containingFootnoteNode)
    }

    containedFootnoteNodes.forEach(node => {
      while (child = node.firstChild) {
        node.parentNode.insertBefore(child, node)
      }
      node.parentNode.removeChild(node)
    })

    selection.selectMarkers(true)
  }

  footnote() {
    this.removeFootnote()

    const selection = new this.scribe.api.Selection()
    const footnote = document.createElement(this.opts.tagName)
    const fragment = document.createDocumentFragment()

    let range


    fragment.appendChild(footnote)

    // append an invisible char, so it is actually possible to get out
    // of the footnote
    fragment.appendChild(this.createInvisibleCharTextNode())

    footnote.className = this.opts.className

    if (selection.selection.isCollapsed) {
      selection.removeMarkers()

      footnote.innerHTML = `${
        this.createMarker().outerHTML
      }${
        this.createInvisibleCharTextNode().textContent
      }`

      selection.range.extractContents()
      selection.range.insertNode(fragment)

      selection.selectMarkers(true)
    }
    else {

      // place the markers and modify the range so they are
      // included in it
      selection.selectMarkers(true)

      range = selection.selection.getRangeAt(0)
      selection.removeMarkers()

      footnote.appendChild(this.createMarker())
      // clone the range into the footnote
      if (range) {
        footnote.appendChild(range.cloneContents())
        footnote.appendChild(this.createMarker())
      }

      selection.range.extractContents()
      selection.range.insertNode(fragment)
    }

    selection.selectMarkers(true)
  }

  getFootnoteInsideOfSelection(selection) {
    const range = selection.range

    if (!range) {
      return null
    }

    const container = range.commonAncestorContainer

    if (container.nodeType === Node.TEXT_NODE) {
      return null
    }

    return range.commonAncestorContainer.querySelector(this.footnoteSelector)
  }

  getFootnoteOutsideOfSelection(selection) {
    const range = selection.range

    if (!range) {
      return null
    }

    return closest(range.commonAncestorContainer, this.footnoteSelector)
  }

}

export default function () {

  return function (scribe) {

    const footnoteCommand = new scribe.api.Command('footnote')

    footnoteCommand.execute = function executeFootnoteCommand() {
      const selection = new scribe.api.Selection()

      const command = new FootnoteCommandImpl(scribe, this)

      scribe.transactionManager.run(() => {
        command.executeCommand(
          !command.initialData.hasFootnote
          ? 'footnote'
          : 'removeFootnote'
        )
        command.finishCommand()
      })

    }

    footnoteCommand.queryState = function queryStateFootnoteCommand() {
      const command = new FootnoteCommandImpl(scribe, this)
      return command.initialData.hasFootnote
    }

    footnoteCommand.queryEnabled = function queryEnableFootnoteCommand() {
      const selection = new scribe.api.Selection()
      const range = selection.range
      const fragment = range.cloneContents()
      let noBlocks = true

      // selection should not conatin any block elements
      noBlocks = Array.prototype.every.call(fragment.childNodes, (node) =>
        !isBlockElement(node)
      )

      const command = new FootnoteCommandImpl(scribe, this)

      return noBlocks && (command.initialData.hasFootnote
        ? true
        : !selection.selection.isCollapsed)
    }

    scribe.commandPatches.footnote = footnoteCommand



  }

}
