import DependencyCollector from '../../../shared/template/DependencyCollector'
import { deepGet } from '../../../shared/obj'
// Template components

import Repeatable from '../../components/Repeatable'
import Editor from '../../components/Editor'
import DraftEditor from '../../components/Draft'
import Positionable from '../../components/Positionable'
import Media from '../../components/Media'
import Resizable from '../../components/Resizable'
import Linkable from '../../components/Linkable'
import Hotspot from '../../components/Hotspot'

// connectors


/**
 * The dependency collector used by the Article Editor (CM)
 */
export default
class TemplateDependencyCollector
  extends DependencyCollector {

  getConnectionProps() {
    return {
      articlePlaceholders: this.props.article.current.phAccessor || null
    }
  }

  collect() {
    const components = this.connectClassic()
    return {
      ...components,
      Hotspot: this.connect(
        this.componentOverrides.Hotspot || Hotspot,
        this.connectors.connectHotspotToContext,
        {},
        {
          Resizable: components.Resizable,
          Editor: components.Editor,
          Positionable: components.Positionable
        }
      )
    }
  }

  connectClassic() {
    const editorVersion = deepGet(this.props, 'article.current.editorVersion')
    return {
      ...super.collect(),

      // data
      placeholder: this.props.article.current.phAccessor,

      Positionable: this.connect(
        this.componentOverrides.Positionable || Positionable,
        this.connectors.connectPositionableToContext
      ),

      Repeatable: this.connect(
        this.componentOverrides.Repeatable || Repeatable,
        this.connectors.connectRepeatableToContext
      ),

      Editor: this.connect(
        editorVersion === 'draft'
          ? DraftEditor
          : this.componentOverrides.Editor || Editor,
        this.connectors.connectEditorToContext,
        {
          toolbarElem: this.props.toolbarElem,
        },
      ),

      Media: this.connect(
        this.componentOverrides.Media || Media,
        this.connectors.connectMediaToContext,
        {
          image: this.props.image,
        }
      ),

      Resizable: this.connect(
        this.componentOverrides.Resizable || Resizable,
        this.connectors.connectResizableToContext
      ),

      Linkable: this.connect(
        this.componentOverrides.Linkable || Linkable,
        this.connectors.connectLinkableToContext
      )
    }

  }
}
