import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { confirmable } from 'react-confirm'
import { autobind } from 'core-decorators'
import PerfectScrollbar from 'perfect-scrollbar'
import classNames from 'classnames'

import { formatMessage, FormattedMessage } from '../../../translations'
import GenevaButton from '../../../ui/components/GenevaButton'
import { pageStore } from '../../../page/reducers'
import { projectStore } from '../../reducers'
import { contextStore } from '../../../context/reducers'
import { Dialog } from '../../../shared/components/Dialog'
import ContentLoadingBox from '../../../shared/components/ContentLoadingBox'
import FilterPageCommand from '../../../shared/commands/filter-page'
import EditItemCommand from '../../../shared/commands/EditItemCommand.js'
import { deepGet } from '../../../shared/obj'
import Icon from '../../../shared/icons'
import InternalLinkList from '../../../shared/components/InternalLinkList'
import MissingAccessDialog from '../../../shared/components/Dialog/MissingAccessDialog'

const css = /* typeof window === 'undefined' ? {} : */require('../../styles.scss')

@confirmable
export default class InternalLink extends Component {

  static propTypes = {
    show: PropTypes.bool,            // indicates if the dialog is shown or not.
    proceed: PropTypes.func,         // call to close the dialog with promise resolved.
    cancel: PropTypes.func,          // call to close the dialog with promise rejected.
    dismiss: PropTypes.func,         // call to only close the dialog.
    confirmation: PropTypes.string,  // arguments of your confirm function
    options: PropTypes.object        // arguments of your confirm function
  }

  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      pages: [],
      selectedPageId: null,
      selectedPageTitle: '',
      mode: 'tableMode',
      showMissingAccessDialog: false,
    }

    this.ps = null
  }

  componentDidMount() {
    const {
      options: { pageIds }
    } = this.props

    new FilterPageCommand('id', pageIds, 'internalLinks')
      .exec()
      .then((pages) => {

        // Remove pages without internalLinks
        pages = pages.filter(page => page.internalLinks.data.length)

        if (!pages.length) {
          // end dialog if nothing to show
          this.props.proceed()
        }

        this.setState({
          isLoading: false,
          pages
        })
      })
  }

  handleRowClick(page) {
    this.setState({
      selectedPageId: page.id,
      selectedPageTitle: page.name,
      mode: 'detailMode'
    })
  }

  @autobind
  handleBack() {
    this.setState({
      selectedPageId: null,
      selectedPageTitle: '',
      mode: 'tableMode'
    })
  }

  @autobind
  handleOpenArticle(article) {
    const page = article.pages[0]
    const partialProject = projectStore.getPartialById(page.projectId)

    if (!partialProject) {
      this.setState({
        showMissingAccessDialog: true
      })
      return true
    }

    new EditItemCommand({
      router: this.props.options.router,
      type: 'article',
      id: article.id,
      projectId: page.projectId,
      pageId: page.id,
      store: {
        project: projectStore,
        context: contextStore
      }
    }).exec()

    return this.props.dismiss()
  }

  @autobind
  handleHideDialog() {
    this.setState({
      showMissingAccessDialog: false
    })
  }

  @autobind
  renderMissingAccessDialog() {
    return (
      <MissingAccessDialog
        handleHideDialog={this.handleHideDialog}
      >
      </MissingAccessDialog>
    )
  }

  renderArticleList(pageId) {
    return (<InternalLinkList
      pageId={pageId}
      handleOpenArticle={this.handleOpenArticle}
    />)
  }

  renderRow(page) {
    const internalLinkCount = deepGet(page, 'internalLinks.data.length')

    // Bail out if page has no internal links to show
    if (!internalLinkCount) {
      return null
    }

    return (<div className={css.pageRow} onClick={() => this.handleRowClick(page)}>
      <div className={css.nameWrapper}>{page.name}</div>
      <FormattedMessage
        id="page.link-target.pages"
        className={css.info}
        values={{
          count: page.internalLinks && page.internalLinks.data.length
        }}
      />
      <div className={css.iconWrapper}>
        <Icon name="fa-chevron-right" />
      </div>
    </div>)
  }

  renderLoader() {
    return (<ContentLoadingBox
      spinner
      message={{
        id: 'placeholder-empty'
      }}
    />)
  }

  @autobind
  setScrollContainer() {
    this.ps = new PerfectScrollbar('#row-wrapper', {
      suppressScrollX: true
    })
  }

  @autobind
  renderFooter() {
    const {
      options: { closeButtonId, confirmButtonId },
    } = this.props

    return (<div className="grid-content text-right">
      {closeButtonId
        ? <GenevaButton type="button"
          className="small button"
          onClick={this.props.dismiss}
        >
          <FormattedMessage id={closeButtonId} />
        </GenevaButton>
        : null}

      {confirmButtonId
        ? <GenevaButton type="button"
          className="small button"
          onClick={this.props.proceed}
          id="new-article-submit"
        >
          <FormattedMessage id={confirmButtonId} />
        </GenevaButton>
        : null}
    </div>)
  }

  @autobind
  renderInternalLinkDialog() {
    const {
      options: { headerTextId },
    } = this.props

    const { isLoading, pages, selectedPageId, mode, selectedPageTitle } = this.state

    return (
      <Dialog
        className={css.projectInternalLink}
        isOpen={this.props.show}
        title={selectedPageTitle || formatMessage({ id: 'page-internal-link-dialog.title' })}
        onCancel={this.props.dismiss}
        onCommit={this.props.proceed}
        onAfterOpen={this.setScrollContainer}
        renderFooter={this.renderFooter}
      >
        <div className="form-main">
          <div className={classNames(css.contentWrapper, mode === 'tableMode' ? css.tableMode : css.detailMode)}>

            <div className={css.tablePane}>
              <div className={css.headerText}>
                {!isLoading
                  ? <FormattedMessage id={headerTextId} />
                  : null
                }
              </div>
              <div id="row-wrapper" className={css.rowWrapper}>
                {isLoading
                  ? this.renderLoader()
                  : pages.map(page => this.renderRow(page))
                }
              </div>
            </div>

            <div>
              <div className={css.header}>
                <div className={css.backButton} onClick={this.handleBack}>
                  <Icon name="ion ion-ios-arrow-left" />
                </div>
                <div className={css.headline}>
                  <FormattedMessage id="page-internal-link-dialog.header-text" />
                </div>
              </div>
              <div>
                {selectedPageId
                  ? this.renderArticleList(selectedPageId)
                  : null
                }
              </div>
            </div>
          </div>

        </div>
      </Dialog>
    )
  }

  render() {
    return (
      <React.Fragment>
        {
          this.state.showMissingAccessDialog
            ? this.renderMissingAccessDialog()
            : this.renderInternalLinkDialog()
        }
      </React.Fragment>
    )
  }
}
