import * as PropTypes from 'prop-types'
import React from 'react'

import createParentPropsInjector from '../../shared/utils/create-parent-props-injector'
import commandConnector from '../../shared/lib/command'
import InsertContentIntoPageCommand from '../../shared/commands/insert-content-into-page'

import { store as articleStore } from '../../article'
import { store as widgetStore } from '../../widget'
import ArticleSort from '../components/ArticleSort'

import RemoveFromPageCommand from '../commands/remove-from-page'
import SortArticleCommand from '../commands/sort-article'

export default function connectArticleSort(parentProps: Object) {
  const injectParentProps = createParentPropsInjector(parentProps)

  // Connector function
  function ArticleSortConnector({ ...rest }) {
    const contentStores = {
      article: articleStore,
      widget: widgetStore,
    }

    return <ArticleSort contentStores={contentStores} {...rest} />
  }

  // Component props
  ArticleSortConnector.propTypes = {
    context: PropTypes.object,
    page: PropTypes.object.isRequired,
  }

  // Inject commands
  return commandConnector({
    SortArticleCommand,
    InsertContentIntoPageCommand,
    RemoveFromPageCommand,
  })(injectParentProps(ArticleSortConnector))
}
