import sanitizeHTML from 'sanitize-html';

class Str {

  constructor(str, opts) {
    this.str = str;
    this.opts = Object.freeze(opts);
  }

  // heavily pulling from plugin:
  //  https://github.com/EDMdesigner/textversionjs/blob/master/src/textversion.js
  preprocessHTML() {
    if (this.str.indexOf('</html>') > -1) {

      let tmpHTML = this._removeLinebreaks(this.str);

      // remove everything before and after <body> tags including the tag itself
      const bodyEndMatch = tmpHTML.match(/<\/body>/i)
      if (bodyEndMatch) {
        tmpHTML = tmpHTML.substring(0, bodyEndMatch.index)
      }
      const bodyStartMatch = tmpHTML.match(/<body[^>]*>/i)
      if (bodyStartMatch) {
        tmpHTML = tmpHTML.substring(
          bodyStartMatch.index + bodyStartMatch[0].length,
          tmpHTML.length)
      }

      // remove inbody scripts and styles
      tmpHTML = tmpHTML.replace(/<(script|style)( [^>]*)*>((?!<\/\1( [^>]*)*>).)*<\/\1>/gi, "")

      // remove all tags except that are being handled separately
      // eslint-disable-next-line max-len
      tmpHTML = tmpHTML.replace(/<(\/)?((?!h[1-6]( [^>]*)*>)(?!img( [^>]*)*>)(?!a( [^>]*)*>)(?!ul( [^>]*)*>)(?!ol( [^>]*)*>)(?!li( [^>]*)*>)(?!p( [^>]*)*>)(?!div( [^>]*)*>)(?!td( [^>]*)*>)(?!br( [^>]*)*>)[^>\/])[^<>]*>/gi, "")

      this.str = tmpHTML
    }

    return this;
  }

  preprocessSpecialChars() {
    this.str = this.str.replace(//g, '');
    return this;
  }

  // eslint-disable-next-line class-methods-use-this
  _cleanWhitespace(str) {
    return str
      // .trim()
      // double newlines should become a real new line
      .replace(/(\n+|\r+|(?:\n\r)+|(?:\r\n+)){2}/g, '<br />')
      // single newlines should become concatenated with a single whitespace
      .replace(/(\n+|\r+|(?:\n\r)+|(?:\r\n+))/g, ' ');
  }

  // eslint-disable-next-line class-methods-use-this
  _removeLinebreaks(str, replacement) {
      // (\r\n|\n\r|\n|\r)
    return str.replace(/((\r\n)|(\n\r)|\n|\r)/mg, replacement || ' ');
  }

  sanitize() {
    let isProperHTML = false;

    function transformLine() {
      isProperHTML = true;
      return {
        tagName: 'p'
      };
    }

    const opts = {
      ...this.opts,
      allowedTags: [
        ...this.opts.allowedTags,
        'br'
      ]
    };



    const optsClone = {
      ...opts,
      textFilter: this._cleanWhitespace,
      exclusiveFilter: frame => (frame.tag !== 'br'
          ? !this._cleanWhitespace(frame.text).trim()
          : false)
    };

    opts.transformTags = {
      ...(opts.transformTags || {}),
      div: transformLine,
      li: transformLine,
      p: transformLine
    };

    this.str = sanitizeHTML(this.str, opts);


    if (!isProperHTML) {
      // Then take care of linebreaks
      this.str = this.str.split(/$/mg).map(line => `<p>${line}</p>`).join('');
    }

    this.str = sanitizeHTML(this.str, optsClone);

    return this;
  }

  handleTabs() {
    this.str = this.str.replace(/\t/g, ' ');
    return this;
  }

  handleSpace() {
    // replace multiple whitespaces with a single one, see http://stackoverflow.com/questions/3871816/is-there-a-javascript-regular-expression-to-remove-all-whitespace-except-newline
    this.str = this.str.replace(/[ \u00a0\t\r]+/g, ' ');

    return this;
  }

  get() {
    return this.str;
  }

}


export default
function sanitize(data, opts = {}) {

  return new Str(data, {
    ...opts
  })
  .preprocessHTML()
  .preprocessSpecialChars()
  .sanitize()
  .handleTabs()
  .handleSpace()
  .get();

}
