import React, { Component } from 'react'
import { FormattedMessage, formatMessage } from '../../translations'
import cancelable from '../decorators/cancelable-promise'

import loadPlugins from './loadPlugins'

export default (PluginComponent, opts) => {

  @cancelable
  class WrappedPluginComponent extends Component {

    constructor(plugins) {
      super(plugins)
      this.state = {}
    }

    componentDidMount() {
      const additionalDeps = { intl: { FormattedMessage, formatMessage } }
      this.makeCancelable(loadPlugins(opts.hook, additionalDeps))
        .then((plugins) => {
          return plugins.filter((plugin) => {
            if (plugin.module instanceof Error) {
            // eslint-disable-next-line max-len
              console.warn(`There was some error creating the \`${plugin.name}.${opts.hook}\` plugin`, plugin.module)
              return false
            }
            return true
          })
        })
        .then((plugins) => {
          this.setState({
            plugins
          })
        })
    }

    render() {
      return (<PluginComponent
        {...this.props}
        plugins={this.state.plugins}
      />)
    }
  }

  return WrappedPluginComponent
}
