
import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import ReactCSSTransitionGroup from 'react-transition-group/CSSTransitionGroup'
import { autobind } from 'core-decorators'
import classNames from 'classnames'
import PerfectScrollbar from 'perfect-scrollbar'
import { FormattedMessage } from '../../../translations'
import Expire from '../Expire'
import TransformableTools from './TransformableTools'

const css = /* typeof window === 'undefined' ? {} : */ require('./styles.scss')

const DEFAULT_ZOOM_FACTOR = 0.25
const DEFAULT_MAX_ZOOM = 3


export default class Transformable extends Component {
  static propTypes = {
    className: PropTypes.string,
    wrapperClassName: PropTypes.string,
    toolsClassName: PropTypes.string,
    children: PropTypes.node,
    zoomFactor: PropTypes.number,
    maxZoom: PropTypes.number,
  };

  constructor(props) {
    super(props)

    this.state = {
      translating: false,
      startX: null,
      zoom: 1,
      positionX: 0,

      zoomFactor: DEFAULT_ZOOM_FACTOR,
      maxZoom: DEFAULT_MAX_ZOOM,
    }
    this.ps = null
  }

  componentDidMount() {
    this.ps = new PerfectScrollbar('#transformable-container', {})
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      zoomFactor: nextProps.zoomFactor || this.state.zoomFactor,
      maxZoom: nextProps.maxZoom || this.state.maxZoom,
    })
  }

  @autobind
  handleZoomInCanvas() {
    this.setState({
      zoom: Math.min(this.state.zoom + this.state.zoomFactor, 3),
      changedProp: 'zoom',
    })
  }

  @autobind
  handleZoomOutCanvas() {
    this.setState({
      zoom: Math.max(this.state.zoom - this.state.zoomFactor, 0.25),
      changedProp: 'zoom',
    })
  }

  @autobind
  handleToggleTranslateCanvas() {
    const isTranslating = !this.state.translating
    const state = {
      translating: isTranslating,
    }
    if (isTranslating) {
      state.changedProp = 'position'
    }
    this.setState(state)
  }

  @autobind
  handleStartTranslateCanvas({ screenX }) {
    if (this.state.translating) {
      this.setState({
        startX: screenX,
      })
    }
  }

  @autobind
  handleEndTranslateCanvas() {
    if (this.state.translating && this.state.startX !== null) {
      this.setState({
        startX: null,
      })
    }
  }

  @autobind
  handleTranslateCanvas({ screenX }) {
    if (
      this.state.translating
      && this.state.startX !== null
      && this.state.startX - screenX
    ) {
      this.setState({
        positionX: -(this.state.startX - screenX),
      })
    }
  }

  @autobind
  handleExpire() {
    this.setState({
      changedProp: null,
    })
  }

  renderHelper() {
    switch (this.state.changedProp) {
      case 'zoom':
        return this.renderHelperExpire(`${this.state.zoom * 100}%`)

      case 'position':
        return this.renderHelperExpire(
          <FormattedMessage id="transformable.click-and-drag" />
        )
      default:
        return null
    }
  }

  renderHelperExpire(content) {
    console.log(
      `visible = ${!!this.state.changedProp} (${this.state.changedProp})`
    )
    return (
      <Expire
        delay={2000}
        onExpire={this.handleExpire}
        // this is required here, as the element gets unmounted
        // as soon as the delay expires
        visible={!!this.state.changedProp}
      >
        <ReactCSSTransitionGroup
          transitionName="transformable-helper-info"
          transitionEnterTimeout={500}
          transitionLeaveTimeout={300}
        >
          <div
            key="transformable-helper-info"
            className={css.transfromableHelperInfo}
          >
            {content}
          </div>
        </ReactCSSTransitionGroup>
      </Expire>
    )
  }

  render() {
    if (this.ps) {
      this.ps.update()
    }
    return (
      <div id="transformable-container" className={`${this.props.className} ${css.transformableWrapper}`}>
        <div className={`${css.transformableTools}`}>
          <TransformableTools
            className={this.props.toolsClassName}
            isTranslating={this.state.translating}
            onZoomIn={this.handleZoomInCanvas}
            onZoomOut={this.handleZoomOutCanvas}
            onTranslate={this.handleToggleTranslateCanvas}
          />
        </div>
        <div
          className={classNames(this.props.wrapperClassName, {
            translating: this.state.translating,
          })}
          style={{
            // eslint-disable-next-line max-len
            transform: `translateX(${this.state.positionX}px) scale(${this.state.zoom}, ${this.state.zoom})`,
          }}
        >
          {this.props.children}
        </div>
        {this.state.translating ? (
          <div
            className={css.transformableHelper}
            onMouseDown={this.handleStartTranslateCanvas}
            onMouseUp={this.handleEndTranslateCanvas}
            onMouseMove={this.handleTranslateCanvas}
          ></div>
        ) : null}

        {this.renderHelper()}
      </div>
    )
  }
}
