import debug from 'debug';
(typeof window !== 'undefined' ? window : global).debug = debug;
(typeof window !== 'undefined' ? window : global).log = {
  todo: debug('geneva:TODO'),
  renderer: debug('geneva:RENDERER:INFO'),
  rendererWarn: debug('geneva:RENDERER:WARN'),
  tmplLoader: debug('geneva:TEMPLATE_LOADER:INFO'),
  tmplLoaderWarn: debug('geneva:TEMPLATE_LOADER:WARN'),
  editor: debug('geneva:EDITOR'),
  placeholder: debug('geneva:Placeholder')
}
