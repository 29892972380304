import { createConfirmation } from 'react-confirm'
import { getPageTemplates } from '../../../shared/utils'
import NewPageDialog from '../../components/dialogs/NewPage'

const newPage = createConfirmation(NewPageDialog)


export default function (confirmation: string|Object, options: Object = {}) {

  let pageTemplates = getPageTemplates(confirmation.channel)

  // remove template which cannot be created by the user
  if (pageTemplates) {
    pageTemplates = pageTemplates.filter(templates => templates.userCreatable)
  }

  if (typeof confirmation !== 'string') {
    options = { ...confirmation, pageTemplates }
    confirmation = ''
  }
  return newPage({ confirmation, options })
}
