import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router'
import { FormattedHTMLMessage, FormattedMessage } from '../../translations'
import ContentErrorBox from '../../shared/components/ContentErrorBox'

const WrongProjectError = ({ page, project }) => {

  const actualProject = project.getPartialById(page.projectId)

  return (<ContentErrorBox
    error={{
      id: 'page.error.wrong-project',
      values: {
        pageName: page.name,
        projectName: project.current.name,
      }
    }}
  >
    <div>
      <FormattedHTMLMessage
        id="page.error.wrong-project-link-info"
        values={{
          projectName: actualProject
            ? actualProject.name
            : 'unknown'
        }}
      />&nbsp;
      <Link
        onClick={() => {
          project.calledToChangeCurrent = true
          page.store.load(page.id, { setCurrent: true })
        }}
        to={`/publish/${page.projectId}/page/${page.id}`}
      >
        {page.name || <FormattedMessage id="page.error.open-page-link" />}
      </Link>
    </div>
  </ContentErrorBox>)
}

WrongProjectError.propTypes = {
  page: PropTypes.object,
  project: PropTypes.object,
}

export { WrongProjectError as default }
