import { Atom, action, observable, transaction, computed } from 'mobx'
import { deepGet } from '../../shared/obj'
import { defaultChannel } from '../../config'
import { CircularDependencyFreeStore } from '../../CircularDependencyFreeStore'

import Placeholder from './Placeholder'
import Article from '../model'

const getArticleChannel = (article) => {
  // eslint-disable-next-line max-len
  // console.warn('Note this is potentially unsave as the article channel may be a different one than the current project channel')
  return (
    (article.channels && article.channels[0])
    // the api is a littlebid messed up here. so sometimes the channels array
    // doesn't exist, but instead a complete channel object
    || (article.channel
      && (typeof article.channel === 'string'
        ? article.channel
        : article.channel.shortcut))
    || defaultChannel
  )
}

class VoidPlaceholder extends Atom {
  set() {}

  get() {
    this.reportObserved()
    return null
  }
}

// eslint-disable-next-line no-undef
// interface IPlaceholder {
//   type: string;
//   i18n: Object;
// }

// type TPhId = string | number;

export default class PlaceholderAccessor extends Atom {
  placeholderPrefix = 'ph';

  @observable length = 0;

  @computed get asJSON() {
    this.reportObserved()
    // eslint-disable-next-line no-unused-vars
    const length = this.length
    return this.toJSON()
  }

  constructor(article, placeholder, channel = null, lang = null) {
    super(`PlaceholderAccessor on Article "${article.name}" [${article.id}]`)

    if (typeof placeholder === 'string') {
      lang = channel
      channel = placeholder
      placeholder = null
    }

    if (!placeholder) {
      placeholder = article.placeholder
    }

    // for debugging
    try {
      if (deepGet(placeholder, 'web.ph1.i18n.de-DE.value.hh')) {
        // this should never happen, so stop here if it actually does happen
        // eslint-disable-next-line
        console.log(
          'PlaceholderAccessor#constructor(): Placeholder contains keye `hh`. This is an invalid key and should never happen!'
        )
      }
    }
    catch (ex) {
      /* noop */
    }

    this.article = article
    this.placeholder = null

    /**
     * This is the map of real article placeholders in a datastructure that
     * provides quick access.
     * @type {Object}
     */
    this.placeholderMap = {}
    /**
     * Components that try to access non-exist placeholders would never be
     * notified of teh creation of those placeholders. That's what we use the
     * void placeholders for. This map contains all placeholders in this article
     * any component tried to access, but which did not exist at the time of
     * accessing.
     * @type {Object}
     */
    this.voidPlaceholderMap = {}

    this.channel = channel || getArticleChannel(this.article)

    this.defaultLang = lang || this.article.createdIso

    if (!this.defaultLang) {
      // eslint-disable-next-line max-len
      throw new Error(
        `Could not initialize PlaceholderAccessor for Article ${article.id}. Make sure 'new PlaceholderAccessor()' always get's passed a language `
          + 'as third argument or the article has a createdIso property!'
      )
    }

    this.update(placeholder)
  }

  setChannel(newChannel) {
    if (newChannel !== this.channel) {
      this.channel = newChannel
      this.update()
    }
  }

  update(newPlaceholder = null) {
    if (newPlaceholder) {
      this.placeholder = newPlaceholder
    }
    const placeholder = this.channelPlaceholder

    if (placeholder) {
      // The single source of truth here is the new data we are provided with,
      // so we have to dump the old one, but if placeholders already exist that
      // are also in the new data, we need to reuse them, as there may be
      // observers already attached to them. Thus we reuse the old list and
      // copy the new data over while omitting those placeholders that don't
      // exist anymore.
      this.placeholderMap = Object.keys(placeholder).reduce((memo, pid) => {
        const ph = this.createPlaceholder(pid, placeholder[pid])

        if (this.placeholderMap[pid]) {
          const value = ph.get('value')
          const source = ph.getSource()
          memo[pid] = this.placeholderMap[pid].set('value', {
            value,
            source,
            type: ph.getType(),
          })
        }
        else {
          memo[pid] = ph
        }

        return memo
      }, {})
    }
    else {
      console.warn(
        `Invalid placeholder object for channel ${this.channel}: `,
        placeholder
      )
    }

    this.sanitize()
    this.reportChanged()
    return this
  }

  @action
  sanitize() {
    // updating type for legacy objects
    Object.keys(this.placeholderMap)
      .filter(phId => phId.indexOf('.type') !== -1)
      .forEach((phId) => {
        const typePh = this.placeholderMap[phId]
        const ph = this.placeholderMap[phId.replace('.type', '')]

        if (typePh && ph && ph.getType() === 'keyValue') {
          const val = typePh.steal()
          const type
            = typeof val === 'string' ? val : val.type || val.value || undefined
          ph.setType(type)
        }
      })

    let keys = []

    if (this.placeholderMap) {
      // iterate over all placeholders
      keys = Object.keys(this.placeholderMap)
        // filter out those that are empty
        .filter((phId) => {
          const hasValue = !this.isEmpty(phId)
          return hasValue
        })
    }

    if (this.length !== keys.length) {
      this.length = keys.length
    }
  }

  toJSON() {
    return {
      [this.channel]: Object.keys(this.placeholderMap).reduce((memo, key) => {
        memo[key] = this.placeholderMap[key]
          ? this.placeholderMap[key].toJSON()
          : null
        return memo
      }, {}),
    }
  }

  addPlaceholders(placeholders) {
    const pids = Object.keys(placeholders)
    this.placeholderMap = {
      ...(this.placeholderMap || {}),
      ...pids.reduce((memo, pid) => {
        memo[pid] = this.createPlaceholder(pid, placeholders[pid])

        return memo
      }, {}),
    }

    return this
  }

  createPlaceholder(pid, value) {
    const ph = new Placeholder(pid, value, this.defaultLang, this)

    return ph
  }

  /**
   *
   * @param {TPhId} pid
   * @returns {string}
   */
  $(pid) {
    if (typeof pid === 'string' && pid.indexOf(this.placeholderPrefix) === 0) {
      return pid
    }

    return `${this.placeholderPrefix}${pid}`
  }

  get channelPlaceholder() {
    if (!this.placeholder) {
      return {}
    }
    return this.placeholder[this.channel] || {}
  }

  /**
   *
   * @param {TPhId} phId
   * @param {string?} key
   * @param {any?} value
   * @param {string?} lang
   * @returns {PlaceholderAccessor}
   */
  set(phId, key, value, lang) {
    let ph = this.getObject(phId)
    let voidPlaceholder

    if (!ph) {
      this.addPlaceholders({ [this.$(phId)]: null }, lang)
      ph = this.getObject(phId)

      // In case this placeholder did not exist yet, there should be a
      // voidPlaceholder for it. We get a reference to it here.
      voidPlaceholder = this.voidPlaceholderMap[phId]
    }

    ph.set(key, value, lang)

    if (voidPlaceholder) {
      // in case we have a void placeholder, we delete it now that the value
      // was set and call it's change notifier
      delete this.voidPlaceholderMap[phId]
      voidPlaceholder.reportChanged()
    }

    this.sanitize()

    return this
  }

  /**
   *
   * @param {TPhId} phId - The placeholder id to remove. Wildcard `*` is allowed.
   * @param {string?} key
   * @param {string?} lang
   * @returns {any}
   */
  get(phId, key, lang) {
    const ph = this.getObject(phId)

    if (!ph) {
      // we create a new voidPlaceholderMap to work as a stub for a real
      // placeholder
      if (!this.voidPlaceholderMap[phId]) {
        this.voidPlaceholderMap[phId] = new VoidPlaceholder()
      }
      return this.voidPlaceholderMap[phId].get()
    }

    return ph.get(key, lang)
  }

  /**
   *
   * @param {TPhId} phId - The placeholder id to remove. Wildcard `*` is allowed.
   * @returns {any}
   */
  getKey(phId) {
    const ph = this.getObject(phId)

    if (!ph) {
      return null
    }

    return ph.getKey()
  }

  /**
   * Get's the whole placeholder object for the given placeholder id.
   * @param {TPhId} phId - The placeholder id to remove. Wildcard `*` is allowed.
   * @returns {Object | null}
   */
  getObject(phId) {
    const provider = this.placeholderMap
    const name = this.$(phId)

    if (!provider) {
      return null
    }

    return provider[name]
  }

  getSource(phId) {
    const ph = this.getObject(phId)
    if (!ph) {
      return null
    }
    return ph.getSource()
  }

  getEditorState(phId) {
    const ph = this.getObject(phId)
    if (!ph) {
      return null
    }
    return ph.getEditorState()
  }

  /**
   * Get's the type of given placeholder.
   * @param {TPhId} phId - The placeholder id to remove. Wildcard `*` is allowed.
   * @returns {string | undefined}
   */
  getType(phId) {
    const ph = this.getObject(phId)

    let type = ph ? ph.getType() : undefined

    // =========================================================
    // Geneva v1 legacy:
    // For things like video, type was saved saved in
    // <phId>.type
    const typePh = this.get(`${phId}.type`, 'value')

    if (typePh) {
      type
        = typeof typePh === 'string'
          ? typePh
          : typePh.type || typePh.value || undefined
    }

    // =========================================================

    return type
  }

  /**
   * Removes given placeholder.
   * @param {TPhId} phId - The placeholder id to remove. Wildcard `*` is allowed.
   * @returns {PlaceholderAccessor}
   * @example
   *
   *  phId = '1~2:*' // will remove 1~2:1 and 1~2:2.type but NOT 1~2.type
   *  phId = '1~2*'  // will remove 1~2:1 and 1~2:2.type as well as 1~2.type but NOT 1~22*
   * TODO: Try to fix: A remove is currently not updating the view, since the placeholder will just be missing inside the model
   */
  remove(phId) {
    const provider = this.placeholderMap
    phId = this.$(phId)
    let matches = [phId]

    if (phId.indexOf('*') >= 0) {
      // replace everything that starts with the given placeholder
      const search = /(:|~)\*/.test(phId)
        ? new RegExp(phId.replace(/\*/, '[^$]*$'))
        : new RegExp(phId.replace(/(:|~)?\*/, '$1(([^0-9]+)|$)[^$]*$'))

      matches = Object.keys(provider).filter(pid => search.test(pid))
    }

    // In a first pass destroy each placeholder.
    //
    // In this pass mute changes on this `placeholderAccessor` object, as those
    // will be reported as one change in the end. A `transaction` won't work
    // here, as that mutes ALL `reportChanged` calls no matter on what object
    const origReportChanged = this.reportChanged
    this.reportChanged = function mutedChange() {}
    matches.forEach((pid) => {
      if (this.placeholderMap[pid] && this.placeholderMap[pid].destroy) {
        this.placeholderMap[pid].destroy()
      }
    })
    this.reportChanged = origReportChanged

    // In the second pass for each key set the whole placeholder to null.
    // Run this also in a `transaction` to prevent fireing of `reportChanged`
    // for each placeholder.
    transaction(() => {
      matches.forEach((pid) => {
        if (this.placeholderMap[pid]) {
          this.placeholderMap[pid] = null
        }
      })
    })

    this.sanitize()

    // for when the reporting isn't triggering
    if (this.article && this.article instanceof Article) {
      this.article.save()
    }

    return this
  }

  /**
   * Checks if given placeholder is empty.
   * @param {TPhId} phId
   * @param {string?} key
   * @param {string?} lang
   * @returns {boolean}
   */
  isEmpty(phId, key, lang) {
    const val = this.get(phId, key, lang)

    let isEmpty = !val

    if (!isEmpty) {
      const type = this.getType(phId)
      // for texts interprete empty lines (aka <br />) as empty
      if (type === 'text' && typeof val === 'string') {
        isEmpty = !val
          .replace(/<div><br\s*\/?><\/div>/g, '') // Draft empty structure
          .replace(/<br\s*\/?>/g, '') // Browser's "empty" structure
          .replace(/<p>\s*<\/p>/g, '') // Scribe empty structure
          .trim().length
      }
      // else if (type === 'image') {
      //   // TODO isEmpty for type image needs to be implemented
      //   throw new Error('isEmpty for type image needs to be implemented')
      // }
    }

    return isEmpty
  }

  /**
   * Returns the data in the given placeholder as and object as expected
   * by react's dangerouslyInsertInnerHTML.
   *
   *
   * @param {TPhId} phId
   * @param {string?} key
   * @param {string?} lang
   * @returns {Object}
   */
  html(phId, key, lang) {
    return {
      __html: this.get(phId, key, lang) || undefined,
    }
  }

  /**
   * Executes given function in a transaction.
   *
   * @param {(self: PlaceholderAccessor) => void} transactionCallback
   * @param {*} param1
   */
  transaction(transactionCallback) {
    transaction(() => transactionCallback(this))

    return this
  }

  /**
   * Calls the standard command to delete an image
   * and remove it from placeholder.
   * A save will be triggered
   *
   * @param {TPhId} phId
   * @returns {Promise}
   */
  deleteImage(phId) {
    const placeholderData = this.article.getDataInPlaceholder(phId)
    const opts = { lang: this.article.createdIso }

    this.article
      .removeDataInPlaceholder(`${phId}*`)

    if (placeholderData && placeholderData.id) {
      const { imageStore } = CircularDependencyFreeStore
      imageStore.destroy(placeholderData.id, opts)
        .catch((err) => {
          // eslint-disable-next-line max-len
          console.warn(`The image with the id '${placeholderData.id} could not be deleted: ${err.message}'`)
        })
    }
  }
}
