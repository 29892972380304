import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { autobind } from 'core-decorators'
import { FormattedMessage } from '../../../translations'

export default class SEOSettingsForm extends Component {

  static propTypes = {
    titleTag: PropTypes.string,
    metaDescription: PropTypes.string,
    keywords: PropTypes.string,
    robots: PropTypes.string,
    onInputChange: PropTypes.func
  }

  constructor(props) {
    super(props)

    let indexValue = 'index'
    let followValue = 'follow'

    // props.robots has pattern like 'index, follow' as default
    if (typeof props.robots === 'string') {
      const selectedRobotValue = props.robots.split(',')
      indexValue = selectedRobotValue[0] ? selectedRobotValue[0] : indexValue
      followValue = selectedRobotValue[1] ? selectedRobotValue[1].trim() : followValue
    }

    this.state = {
      indexValue,
      followValue
    }
  }

  @autobind
  handleInputChange(e) {
    if (this.props.onInputChange) {
      this.props.onInputChange(e)
    }
  }

  @autobind
  handleRadioChange({ target }) {
    this.setState({
      [target.name]: target.value
    }, () => {

      if (this.props.onInputChange) {
        this.props.onInputChange({
          target: {
            id: 'robots',
            value: `${this.state.indexValue}, ${this.state.followValue}`
          }
        })
      }

    })

  }

  render() {
    const { indexValue, followValue } = this.state
    return (
      <div className="grid-content">
        <label htmlFor="title-tag">
          <FormattedMessage id="page-settings-dialog-content.title-tag" />
          <input
            id="titleTag"
            type="text"
            value={this.props.titleTag}
            onChange={this.handleInputChange}
            autoFocus
          />
        </label>
        <label htmlFor="metaDescription">
          <FormattedMessage id="page-settings-dialog-content.meta-description" />
          <textarea
            id="metaDescription"
            className="textarea"
            value={this.props.metaDescription}
            onChange={this.handleInputChange}
          />
        </label>
        <label htmlFor="keywords">
          <FormattedMessage id="page-settings-dialog-content.keywords" />
          <textarea
            id="keywords"
            className="textarea"
            value={this.props.keywords}
            onChange={this.handleInputChange}
          />
        </label>

        <label>
          <FormattedMessage id="page-settings-dialog-content.robots" />
          <div className="robot-meta-tag-wrapper">
            <div className="robot-meta-tag">
              <input
                id="index"
                className="radio-input"
                type="radio"
                name="indexValue"
                value="index"
                checked={indexValue === 'index'}
                onChange={this.handleRadioChange}
              />
              <label htmlFor="index">index</label>

              <input
                id="noindex"
                className="radio-input"
                type="radio"
                name="indexValue"
                value="noindex"
                checked={indexValue === 'noindex'}
                onChange={this.handleRadioChange}
              />
              <label htmlFor="noindex">noindex</label>
            </div>
            <div className="robot-meta-tag">
              <input
                id="follow"
                className="radio-input"
                type="radio"
                name="followValue"
                value="follow"
                checked={followValue === 'follow'}
                onChange={this.handleRadioChange}
              />
              <label htmlFor="follow">follow</label>

              <input
                id="nofollow"
                className="radio-input"
                type="radio"
                name="followValue"
                value="nofollow"
                checked={followValue === 'nofollow'}
                onChange={this.handleRadioChange}
              />
              <label htmlFor="nofollow">nofollow</label>
            </div>
          </div>

        </label>
      </div>
    )
  }

}
