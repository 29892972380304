import { transaction } from 'mobx'
import { Command } from '../../shared/lib/command/index'
/**
  * Transform a ghost page to a normal page
  * by adding actions to the content items and saving it to the api
  * @param {Object} page - The page model to transform
  * @param {Array} itemList - All content item objects inside the page
  * plus the action to be executed
*/
export default class TransformGhostPageCommand extends Command {

  static validationSpec = {
    page: Command.ensureData.isObject,
    itemList: Command.ensureData.isObject
  }

  exec() {
    const {
      page,
      itemList
    } = this.data

    let position
    let gridBlock
    let item

    return transaction(() => {

      page.items.forEach((contentItem) => {

        position = page.getContentPosition(contentItem)
        gridBlock = page.getGridBlock(position)

        item = itemList.find(el => el.id === contentItem.id)

        if (item && item.value) {
          if (item.value === 'remove') {
            gridBlock.remove(position)
          }
          else {
            gridBlock.append(position, 'action', item.value)

            if (item.force) {
              gridBlock.append(position, 'force', item.force)
            }

            if (item.preventSource) {
              gridBlock.append(position, 'preventSource', item.preventSource)
            }

          }
        }

      })

      return page.save({ force: true })

    })
  }
}
