import { transaction } from 'mobx'
import { templateStore } from '../../template/reducers'
import { widgetTemplateStore } from '../../widgetTemplate/reducers'
import Command from '../../shared/command'

const stores = {
  article: templateStore,
  widget: widgetTemplateStore,
}
const getLoadedTemplateForItem = (item) => {
  const store = stores[item.contentType]
  return store.getLoadedTemplateById(item.templateId)
}

export default class InsertIntoPageCommand extends Command {
  constructor(pageModel, data) {
    super({ pageModel, data })
    this.pageModel = pageModel
    this.item = data.item
    this.type = data.type
    this.position = data.position
  }

  validate({ pageModel, data }) {
    if (!pageModel) {
      return `Expected \`pageModel\` to be present. "${pageModel}" given.`
    }

    return ['type', 'item', 'position']
      .filter(key => !data[key])
      .map((key) => {
        return `Expected \`data.${key}\` to be present. "${data[key]}" given.`
      })
      .join(', ')
  }

  exec() {
    const { item, position, type } = this

    this.pushItemToItemsList(item, type)

    return this.insertContentItemAtPosition(position, item, type)
  }

  /**
   * Pushes an item to the simple list of items within a page
   * @private
   */
  pushItemToItemsList(item, type) {
    const { items } = this.pageModel

    if (!items.find(currItem => item.id === currItem.id)) {
      items.push({
        id: item.id,
        type,
        templateId: item.templateId,
        data: item,
      })
    }

    return item
  }

  /**
   * Adds the item to a specific position within the page
   * @private
   */
  insertContentItemAtPosition(position, item, type) {
    const {
      pageModel,
      pageModel: { itemTemplates },
    } = this

    return getLoadedTemplateForItem(item)
      .then((template) => {
        if (!itemTemplates[type][item.templateId]) {
          itemTemplates[type][item.templateId] = template
        }
        return template
      })
      .then((template) => {
        transaction(() => {
          const gridBlock = pageModel.getGridBlock(position, true)

          gridBlock.insert(position, {
            id: item.id,
            type,
          })

          this.pageModel.save()
        })

        return template
      })
  }
}
