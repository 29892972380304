/* eslint-disable no-underscore-dangle */
import { observable } from 'mobx'
import createProxy from '../shared/utils/createProxy'

export default
class GridBlock {

  constructor(block, position) {
    this.block = block
    position.index = this._getBlockIndex(position)
    this.position = position
    createProxy(this)
  }

  get length() {
    return (this.block || []).length
  }


  /**
   * @private
   * @method _getBlockIndex
   * @param  {Position}  position - The position to read the index from.
   * @return {Number}             - The index.
   */
  _getBlockIndex(position) {
    return 'i' in position
      ? position.i
      : position.index
  }

  /**
   * @private
   * @method _getSubContentIndex
   * @param  {Position} position - The position to read the index from.
   * @return {Number}            - The index
   */
  _getSubContentIndex(position) {
    return position.content.slice(-1)[0]
  }

  /**
   * @method _positionHasSubContent
   * @param  {Position} position - The position to determine whether it's for a
   *                               sub content.
   * @return {Boolean}           - `true` if it's a sub content position
   */
  _positionHasSubContent(position) {
    return position.content && position.content.length
  }

  /**
   * In case the `position` parameter defines no content property, just returns
   * this block. Otherwise `goTo` traverses through the block to the given
   * content entry described by `position.content`. It will NOT go to the item
   * the last entry of `position.content` is pointing to, but the second last
   * (the parent of the last item).
   * If on the way there entries are missing, `goTo` creates those on the fly
   * (Observable Arrays) and finally returns the last parent.
   * @method goTo
   * @param  {Position} position - The position to go to.
   * @return {Array}             - The item at given position.
   */
  goTo(position) {
    if (this._positionHasSubContent(position)) {
      let pos = this.block[this._getBlockIndex(position)]
      if (!pos.content) {
        pos.content = observable([])
      }
      pos = pos.content
      position.content.slice(0, -1).forEach((index) => {
        if (!pos[index]) {
          pos[index] = observable([])
        }
        pos = pos[index]
      })
      return pos
    }

    return this.block
  }

  /**
   * Gets the index form the postion object.
   * @method getIndex
   * @param  {Position} position  - The position object to get the index from.
   * @return {Number}             - The position.
   */
  getIndex(position) {
    // In case the position object contains a content property (e.g. widget),
    // the index is that of the last entry in that content array.
    if (this._positionHasSubContent(position)) {
      return this._getSubContentIndex(position)
    }
    return this._getBlockIndex(position)
  }

  /**
   * Returns the item at given position. Like GridBlock#goTo, just that the
   * actual item position is pointing at will be returned.
   * @method getItem
   * @param  {Position} position  - The position to return the item for.
   * @return {Item}               - The item at `position`.
   */
  getItem(position) {
    const target = this.goTo(position)
    return target[this.last(position)]
  }

  /**
   * Inserts an item into this grid block at given position.
   * @method insert
   * @param  {Position} pos   - The position where to insert the Item.
   * @param  {Item}     item  - The Item to be inserted.
   * @return {Item}           - The item that was inserted.
   */
  insert(pos, item) {
    const target = this.goTo(pos)
    target.splice(this.getIndex(pos), 0, item)
    return item
  }

  remove(pos) {
    const target = this.goTo(pos)
    const items = target.splice(this.getIndex(pos), 1)
    return items ? items[0] : null
  }

  append(pos, key, value) {
    const target = this.goTo(pos)
    target[this.getIndex(pos)][key] = value
  }

  isSameAs(other) {
    const pathParts = ['gr', 'gb']
    const hasContent = !!this.position.content
      && !!other.position.content

    if (
      !hasContent
      && (this.position.content || other.position.content)
    ) {
      return false
    }

    if (hasContent) {
      pathParts.push('index')
    }

    const isSameBase = pathParts
      .reduce((memo, p) => {
        return memo && this.position[p] === other.position[p]
      }, true)

    if (!hasContent || !isSameBase) {
      return isSameBase
    }

    return other.position.content.slice(0, -1).join(':')
      === this.position.content.slice(0, -1).join(':')
  }

  last(pos) {
    pos = pos || this.position

    if (
      pos.content && Array.isArray(pos.content)
    ) {
      return pos.content[pos.content.length - 1]
    }

    return pos.index
  }

}
/* eslint-enable no-underscore-dangle */

/**
 * @typedef Item
 * @type {Object}
 * @prop {String}         type - The type of the item. One of 'article' or 'widget'.
 * @prop {String|Number}  id   - The id of the item.
 */


/**
  * @typedef Position
  *
  * @description The position within a page or an grid block.
  * @type {Object}
  * @prop {Array} content - The content array at this grid block.
  * @prop {String} i      - The index within the grid block.
  *                         Same as `position.index`. Legacy value.
  *                         Takes precedence over `position.index`.
  * @prop {String} index  - The index within the grid block.
  */
