import { store as uiStore } from '../../../ui'

export const fileInspector = {
  before: (target, id, data) => {
    fileInspector.createStatusInfo('started', id, target, 'forever', data)
  },
  then: (target, id, data) => {
    fileInspector.createStatusInfo('completed', id, target, 2000, data)
  },
  catch: (target, id, data) => {
    fileInspector.createStatusInfo('failed', id, target, 4000, data)
  },

  createStatusInfo: (type, id, target, duration = 'forever', data = {}) => {
    const name = data && data.file && data.file.name

    uiStore.addStatusInfo(
      {
        id: target.constructor.name,
        priority: 'very-high',
        name: name ? `saving-${type}-name` : `saving-${type}`,
        value: { name },
        thumb: data.filePreview,
        total: 0,
        progress: 0,
      },
      duration
    )
  },
}
