import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { confirmable } from 'react-confirm'
import classNames from 'classnames'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { formatMessage, FormattedMessage } from '../../../translations'
import { Dialog } from '../../../shared/components/Dialog'
import ContentLoadingBox from '../../../shared/components/ContentLoadingBox'
const css = /* typeof window === 'undefined' ? {} : */require('../../styles.scss')

@confirmable
@observer
class ConfirmDelete extends Component {

  static propTypes = {
    show: PropTypes.bool,            // indicates if the dialog is shown or not.
    proceed: PropTypes.func,         // call to close the dialog with promise resolved.
    cancel: PropTypes.func,          // call to close the dialog with promise rejected.
    dismiss: PropTypes.func,         // call to only close the dialog.
    confirmation: PropTypes.string,  // arguments of your confirm function
    options: PropTypes.object        // arguments of your confirm function
  }

  constructor(props) {
    super(props)

    const {
      options: { content, opts: { itemStore } }
    } = this.props

    if (itemStore && itemStore.load) {
      itemStore.load(content.id, { force: true })
        .then(() => {
          this.usage = content.usage && content.usage.length || 0
          this.loading = false
        })

    }
    else {
      this.loading = false
    }
  }

  usage = 0

  @observable loading = true

  renderUsage() {
    if (this.loading === true) {
      // show spinner
      return (
        <span>
          <ContentLoadingBox
            className={css.confirmRemoveFromPageLoadingBox}
            spinner
            message={{
              id: 'placeholder-empty'
            }}
          />
        </span>
      )
    }

    if (this.usage > 1) {
      // show warning
      return (
        <div className={classNames(css.deleteMessageWarning)}>
          <FormattedMessage
            id="content.delete-from-page-usage"
            values={{
              usage: this.usage
            }}
          />
        </div>
      )
    }
    // not enough to show a warning or default
    return (
      ''
    )
  }

  render() {

    const {
      show,
      proceed,
      dismiss,
      options: { content, page }
    } = this.props

    return (<Dialog
      className=""
      overlayClassName="content-remove"
      isOpen={show}
      onAfterOpen={this.handleAfterOpen}
      onRequestClose={this.handleRequestClose}
      title={formatMessage({ id:
        'content.confirm.delete.title'
      })}
      onCancel={dismiss}
      onCommit={proceed}
      id="contentDelete"
    >
      <div>
        <FormattedMessage
          id="content.confirm.remove-from-page.are-you-sure-and-delete"
          values={{
            type: formatMessage({ id: `content.type.${content.contentType}` }),
            contentName: content.name,
            pageName: page.name,
          }}
        />
        <p />
        {this.renderUsage()}
      </div>
    </Dialog>)
  }
}

export default ConfirmDelete
