import * as PropTypes from 'prop-types'
import React, { Component } from 'react'

export default class ToolsSidebarIcon extends Component {
  static propTypes = {
    left: PropTypes.bool,
    className: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
  };

  static defaultProps = {
    width: 128,
    height: 128,
    className: '',
    left: false,
  };

  constructor(props) {
    super(props)

    this.state = {
      ...ToolsSidebarIcon.defaultProps,
      ...props,
    }
  }

  componentWillReceiveProps(newProps) {
    this.setState(newProps)
  }

  // render() {
  //
  //   return (<svg version="1.1"
  //     xmlns="http://www.w3.org/2000/svg"
  //     x="0px"
  //     y="0px"
  //     width={`${this.state.width}px`}
  //     height={`${this.state.height}px`}
  //     viewBox="0 0 512 512"
  //     id="tools-sidebar"
  //     className={this.state.className || ''}
  //   >
  //
  //     <g>
  //
  //       <rect x="56" y="120" width="400" height="24" />
  //
  //       <rect x="56" y="120" width="24" height="274" />
  //
  //
  //       <rect x="280" y="120" width="24" height="274" />
  //
  //
  //       <rect x="328" y="176" width="78" height="24" />
  //
  //       <rect x="328" y="230" width="78" height="24" />
  //
  //       <rect x="328" y="284" width="78" height="24" />
  //
  //
  //       <rect x="432" y="120" width="24" height="274" />
  //
  //
  //       <rect x="56" y="370" width="400" height="24" />
  //
  //     </g>
  //
  //   </svg>)
  //
  // }

  render() {
    return (
      <svg
        viewBox="0 0 24 16"
        enableBackground="new 0 0 24 24"
        className={`sidebar-icon ${this.props.className}`}
        width={this.props.width}
        height={this.props.height}
      >
        {this.props.left ? (
          <g id="Ebene_2" data-name="Ebene 2">
            <g id="Ebene_1-2" data-name="Ebene 1">
              <path
                // eslint-disable-next-line max-len
                d="M23.48,0H.52A.5.5,0,0,0,0,.47V15.53A.5.5,0,0,0,.52,16h23a.5.5,0,0,0,.52-.47V.47A.5.5,0,0,0,23.48,0ZM1,15V1H8V15Zm22,0H9V1H23Z"
              />
              <rect x="3" y="9" width="3" height="1" />
              <rect x="3" y="3" width="3" height="1" />
              <rect x="3" y="6" width="3" height="1" />
            </g>
          </g>
        ) : (
          <g id="Ebene_2" data-name="Ebene 2">
            <g id="Ebene_1-2" data-name="Ebene 1">
              <path
                // eslint-disable-next-line max-len
                d="M23.48,0H.52A.5.5,0,0,0,0,.47V15.53A.5.5,0,0,0,.52,16h23a.5.5,0,0,0,.52-.47V.47A.5.5,0,0,0,23.48,0ZM1,15V1H15V15Zm22,0H16V1h7Z"
              />
              <rect x="18" y="9" width="3" height="1" />
              <rect x="18" y="3" width="3" height="1" />
              <rect x="18" y="6" width="3" height="1" />
            </g>
          </g>
        )}
      </svg>
    )
  }
}

// left
// <g>
// <path
// eslint-disable-next-line max-len
//  d="M0,25.2L0,6.8c0-1.6,1.3-2.9,2.9-2.9h26.2c1.6,0,2.9,1.3,2.9,2.9v18.4c0,1.6-1.3,2.9-2.9,2.9H2.9 C1.3,28.1,0,26.8,0,25.2z M2.9,5.7c-0.6,0-1.1,0.5-1.1,1.1v18.4c0,0.6,0.5,1.1,1.1,1.1h26.2c0.6,0,1.1-0.5,1.1-1.1V6.8 c0-0.6-0.5-1.1-1.1-1.1H2.9z"
// />
// <rect x="9.8" y="5" width="1.8" height="22.4" />
// <rect x="3.3" y="8.1" width="5" height="2.2" />
// <rect x="3.3" y="11.8" width="5" height="2.2" />
// <rect x="3.3" y="15.6" width="5" height="2.2" />
// </g>

// right
// <g>
//   <path
// eslint-disable-next-line max-len
//     d="M29.1,28.1H2.9c-1.6,0-2.9-1.3-2.9-2.9V6.8c0-1.6,1.3-2.9,2.9-2.9h26.2c1.6,0,2.9,1.3,2.9,2.9v18.4 C32,26.8,30.7,28.1,29.1,28.1z M2.9,5.7c-0.6,0-1.1,0.5-1.1,1.1v18.4c0,0.6,0.5,1.1,1.1,1.1h26.2c0.6,0,1.1-0.5,1.1-1.1V6.8 c0-0.6-0.5-1.1-1.1-1.1H2.9z"
//   />
//   <rect x="20.4" y="5" width="1.8" height="22.4" />
//   <rect x="23.7" y="8.1" width="5" height="2.2" />
//   <rect x="23.7" y="11.8" width="5" height="2.2" />
//   <rect x="23.7" y="15.6" width="5" height="2.2" />
// </g>
