import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import classNames from 'classnames'
import * as styles from './styles.scss'

import FixedSection from './FixedSection'
import FlexSection from './FlexSection'
import BrandSection from './BrandSection'

export { FlexSection, FixedSection, BrandSection }

export default class Navbar extends Component {
  static propTypes = {
    /** @deprecated */
    brand: PropTypes.node,
    children: PropTypes.node,
    id: PropTypes.string,
    /** @deprecated */
    links: PropTypes.node,
    navbarStyle: PropTypes.string,
  };

  static defaultProps = {
    navbarStyle: 'default',
  };

  render() {
    return (
      <div
        className={classNames(this.props.navbarStyle, 'title-bar')}
        id={this.props.id}
      >
        {/* legacy code provides backwards compatibility */}
        <BrandSection>
          {this.props.brand}
          {this.props.links}
        </BrandSection>

        {this.props.children}
      </div>
    )
  }
}
