import * as PropTypes from 'prop-types'
import React from 'react'
import { formatMessage, FormattedMessage } from '../../../translations'

const InternalLinkForm = ({
  internalPage,
  onInputChanged,
}) => {
  return (
    <div className="grid-content">
      <div className="grid-block vertical">
        <label className="vertical" htmlFor="link-scheme">
          <FormattedMessage id="link-dialog.internal-page" />
          <input
            type="text"
            readOnly
            name="internalPageName"
            id="link-internal-page-name"
            placeholder={formatMessage({
              id: 'link-dialog.placeholder-internal-page',
            })}
            value={internalPage}
            onChange={onInputChanged}
          />
          <span className="loader-container hidden">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 32 32"
              width="32"
              height="32"
            >
              <path
                opacity=".25"
                // eslint-disable-next-line
                d="M16 0 A16 16 0 0 0 16 32 A16 16 0 0 0 16 0 M16 4 A12 12 0 0 1 16 28 A12 12 0 0 1 16 4"
              />

              <path d="M16 0 A16 16 0 0 1 32 16 L28 16 A12 12 0 0 0 16 4z">
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  from="0 16 16"
                  to="360 16 16"
                  dur="0.8s"
                  repeatCount="indefinite"
                />
              </path>
            </svg>
          </span>
          <input
            type="hidden"
            name="internal-page"
            id="link-internal-page"
            value={internalPage}
          />
          <div className="info">
            <FormattedMessage id="link-dialog.internal-id-info" />
          </div>
        </label>
      </div>
    </div>
  )
}

InternalLinkForm.validate = (value) => {
  if (!value) {
    return false
  }
  if (typeof value !== 'string') {
    // In case of state object
    value = value.internalPage
  }
  return (
    !!value && value.split(':').length === 3 && !value.includes('undefined')
  )
}

InternalLinkForm.propTypes = {
  onInputChanged: PropTypes.func,
  internalPage: PropTypes.string,
}

export default InternalLinkForm
