import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { autobind } from 'core-decorators'
import classNames from 'classnames'

// const css = typeof window === 'undefined' ? {} : require('./styles.scss')
import { BUTTON_ATTRS, filterElemAttrs } from '../../../shared/react-helpers'

const GENEVA_BUTTON_CSS_CLASS = 'geneva-button'

export default class GenevaButton extends Component {

  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.object, PropTypes.array, PropTypes.string
    ]),
    className: PropTypes.string,
    onClick: PropTypes.func,
  }

  // Overwritting contains function for:
  // DropdownOpener component checks for contains on ref
  contains(e) {
    return this.openerRef.contains(e)
  }

  @autobind
  focus() {
    this.openerRef.focus()
  }

  @autobind
  handleClick(e) {
    if (this.props.onClick) {
      this.props.onClick(e)
    }
  }

  render() {
    const { className, children } = this.props
    return (
      <button
        ref={ref => (this.openerRef = ref)}
        {...filterElemAttrs(this.props, BUTTON_ATTRS)}
        className={classNames(GENEVA_BUTTON_CSS_CLASS, className)}
        onClick={this.handleClick}
      >
        {children}
      </button>
    )
  }
}
