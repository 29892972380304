import { isObject } from 'lodash'
import * as config from '../config'

/**
 * Link helper should contain all possible options and in the customer
 * config, it should be set what is actually allowed for the customer
 * (this is because the customer config is loaded after these variables are set)
 */

const EMAIL_SCHEME = 'mailto'
const TEL_SCHEME = 'tel'
const INTERNAL_SCHEME = 'internal'

// all lowercase! the comparison later forces lowercase
const PROTOCOLS_WITH_LEADING_SLASHES = (config.linkSettings
  && config.linkSettings.protocolsWithLeadingSlashes) || [
  'http',
  'https',
  'ftp',
  'ssh',
  'notes',
  '',
]

function getTarget(data = {}) {
  if (data.type === 'email') {
    return null
  }

  return data.target
}

export const validTypes = [
  'internal',
  'external',
  'email',
  'tel',
  /* media', 'file', */
]

export const validSchemes = ['http', 'https', 'ftp', 'Notes']

export const validLinkProps = [
  'appearance',
  'emailAddress',
  'emailSubject',
  'externalUrl',
  'externalScheme',
  'href',
  'internalPage',
  'rel',
  'status',
  'target',
  'telNumber',
  'title',
  'type',
  'content',
  'contentNotPossible',
  'exists',
  'objId', // for plugin use
]

export function getDataForPlugins() {
  return {
    apiUrl: `${config.apiScheme}//${config.apiHost}/${config.apiBasePath}`,
  }
}

export function getLinkProps(props) {
  return validLinkProps.reduce((memo, prop) => {
    if (prop in props) {
      memo[prop] = props[prop]
    }
    return memo
  }, {})
}

export function determineAuthorityPrefix(scheme) {
  return PROTOCOLS_WITH_LEADING_SLASHES.indexOf(scheme.toLowerCase()) >= 0
    ? '//'
    : ''
}

/**
 * Parsing the placeholder data on an existing link.
 * @param {String} href - The link data object.
 * @returns {Object} data - Split information on the link
 */
export function parseHref(href) {
  const PROTOCOL_PART = 0
  const URL_PART = 1
  const EMAIL_PART = 0
  const EMAIL_OPTIONS_PART = 1

  let data = {}
  let parts
  let opts

  if (href) {
    // in case of a protocol relative url, fake a : afront
    if (href.indexOf('//') === 0) {
      href = `:${href}`
    }

    parts = href.split(':')

    if (parts.length === 1) {
      parts.unshift('')
    }

    // replace leading slashes
    parts[URL_PART] = parts[URL_PART].replace(/^\/\//, '')

    if (validSchemes.indexOf(parts[PROTOCOL_PART]) >= 0) {
      data = {
        externalScheme: parts.shift(), // just the first piece: the protocal
        externalUrl: parts.join(':').trim(), // join remaining pieces ohne protocal
        type: 'external',
      }
    }
    else if (parts[PROTOCOL_PART] === EMAIL_SCHEME) {
      parts = parts[URL_PART].split('?')
      opts = parts[EMAIL_OPTIONS_PART]
      data = {
        emailAddress: parts[EMAIL_PART].trim(),
        emailSubject: decodeURIComponent(opts.replace('subject=', '')),
        type: 'email',
      }
    }
    else if (parts[PROTOCOL_PART] === INTERNAL_SCHEME) {
      data = {
        internalPage: parts.slice(URL_PART).join(':').trim(),
        type: 'internal',
      }
    }
    else if (parts[PROTOCOL_PART] === TEL_SCHEME) {
      data = {
        telNumber: parts[URL_PART].trim(),
        type: 'tel',
      }
    }
    else {
      // Assuming it is a link from a plugin
      data = {
        objId: parts[URL_PART],
        type: parts[PROTOCOL_PART],
      }
    }
  }

  return data
}

/**
 * This function tries to detect legacy link objects using DuckTyping.
 * Legacy link objects have no `type` but a `href` property.
 * @param {Object} data - The link data object.
 * @returns {Boolean} - `true` if the object is suspected to be a legacy link
 * object.
 */
export function isLegacyHrefData(data = {}) {
  return isObject(data) && !('type' in data) && 'href' in data
}

/**
 * Transforms a legacy link object into the new structure.
 * @param {Object} data - The link data object.
 */
export function transformLegacyHrefData(data = {}) {
  const parsed = parseHref(data.href)
  Object.assign(data, parsed)
  delete data.href
  return data
}

export function getHref(data = {}) {
  let scheme
  let authorityAndPath
  let query = ''

  // DuckTyping old api. in this case, create the new data structure
  if (isLegacyHrefData(data)) {
    data = transformLegacyHrefData(data)
  }

  if (!data.type) {
    return null
  }

  if (data.type === 'external') {
    scheme = data.externalScheme
    authorityAndPath = data.externalUrl
  }
  else if (data.type === 'internal') {
    scheme = 'internal'
    authorityAndPath = data.internalPage
  }
  else if (data.type === 'email') {
    scheme = 'mailto'
    authorityAndPath = data.emailAddress
    query = `?subject=${encodeURIComponent(data.emailSubject)}`
  }
  else if (data.type === 'tel') {
    scheme = 'tel'
    authorityAndPath = data.telNumber
  }
  // Check for a plugin function e.g. livelink
  else if (data.resolve) {
    const pluginData = data.resolve(data)
    scheme = pluginData.scheme
    authorityAndPath = pluginData.path
  }
  // if nothing applies just return the link
  // this happens in the renderer, but at that point everything is already build out
  else {
    return data.href
  }

  const authPrefix = determineAuthorityPrefix(scheme)
  scheme = scheme ? `${scheme}:` : ''

  return scheme + authPrefix + authorityAndPath + query
}

export function transformToLinkAttributes(data = {}) {
  return {
    href: getHref(data),
    target: getTarget(data),
    rel: data.rel || '',
    title: data.title,
    content: data.content,
    appearance: data.appearance,
    command: data.status,
  }
}

export function transfromToLinkObject(data = {}) {
  return {
    ...data,
    ...parseHref(data.href),
  }
}

export function getDefaultLinkAttributes() {
  return {
    href: 'http://',
    target: '_blank',
    rel: 'noopener',
    title: '',
    content: '',
    appearance: null,
    command: null,
  }
}
