import PropTypes from 'prop-types'
import { autorun } from 'mobx'
import { Component } from 'react'
import { observer } from 'mobx-react'
import sanitizeHtml from 'sanitize-html'

import { store as ArticleStore } from '../..'
import { wordDiff } from '../../../shared/utils/diff'


@observer
export default class VersionPortal extends Component {

  static propTypes = {
    context: PropTypes.object
  }

  constructor(props) {
    super(props)

    const originArticle = ArticleStore.current.isVersion
      ? ArticleStore.current.origin
      : ArticleStore.current

    this.state = {
      originArticle,
      sourceArticle: ArticleStore.current,
      phAccessor: ArticleStore.current.getPlaceholderAccessor(),
      compareToArticle: {}
    }

    // Turn the autosave off to prevent saving of temporary placeholder data
    this.autoSavable = originArticle.autoSave
    originArticle.autoSave = false
  }

  componentDidMount() {
    this.handler = autorun(() => {
      if (!this.state.isLoading
        && this.props.context.target
        && this.state.activeVersionNumber !== this.props.context.target.compareTo) {

        this.contextChanged(this.props.context.target)
      }
    })
  }

  componentWillUnmount() {
    if (this.handler) {
      this.handler()
      this.handler = null
    }

    this.reset()

    const { originArticle } = this.state

    // Turn autosave back to original state
    originArticle.autoSave = this.autoSavable

    // Release the article to be editable again
    originArticle.release()
  }

  reset() {
    // Reset to old placeholder data
    Object.keys(this.originalPlaceholderData).forEach((ph) => {

      const sourcePh = this.originalPlaceholderData[ph]

      this.state.phAccessor.set(
        sourcePh.name,
        'value',
        {
          type: 'text',
          value: sourcePh.text,
          editorState: sourcePh.editorState
        }
      )

    })
  }

  contextChanged({ compareTo }) {
    this.loadArticle(compareTo)
      .then(() => this.setDiff())
  }

  loadArticle(versionId) {
    const versionStore = this.state.originArticle.versionStore

    if (versionId === this.state.originArticle.versionNumber + 1) {
      // No loading needed. Compare to the originArticle
      this.setState({
        compareToArticle: this.state.originArticle,
        activeVersionNumber: versionId
      })

      return Promise.resolve()
    }

    this.setState({
      isLoading: true
    })

    return versionStore.load(versionId)
      .then((model) => {
        this.setState({
          isLoading: false,
          compareToArticle: model,
          activeVersionNumber: model.id
        })
      })
  }

  // Setting the difference of the placeholder data into the placeholder
  setDiff() {
    const { sourceArticle, compareToArticle, phAccessor } = this.state

    if (!this.originalPlaceholderData) {
      // Save original placeholder data for reseting
      this.originalPlaceholderData = this.getDataInPlaceholder(sourceArticle)
    }
    else {
      // Reset to initial state
      this.reset()
    }

    const sourceArticleData = this.getDataInPlaceholder(sourceArticle)
    const compareToArticleData = this.getDataInPlaceholder(compareToArticle)

    Object.keys(sourceArticleData).forEach((ph) => {

      const sourcePh = sourceArticleData[ph] || { text: '' }
      const compareToPh = compareToArticleData[ph] || { text: '' }
      const diff = wordDiff(compareToPh.text, sourcePh.text)

      phAccessor.set(
        sourcePh.name,
        'value',
        {
          type: 'text',
          value: diff
        }
      )

    })

  }

  getDataInPlaceholder(article) {
    const channel = article.channelShortcut
    let phA = article.phAccessor
      ? article.phAccessor.asJSON[channel]
      : article.placeholder[channel]
    const content = []

    // phA always needs to be an object
    if (!phA) {
      phA = {}
    }

    // Copy the content of the placeholders
    // into a simple array without channel and language dependencies
    Object.keys(phA).forEach((entry) => {

      // We are only interested in text
      if (phA[entry].type === 'text') {

        // Object.keys has no specific order.
        // This is not beautiful, but order must be maintained
        let index = entry.slice(-1)
        if (content[index]) {
          index = entry.slice(-2)
        }

        const text = this.originalPlaceholderData
          ? this.parse(article.getDataInPlaceholder(entry))
          : article.getDataInPlaceholder(entry)

        const editorState = article.getEditorState(entry)
        content[index] = {
          text,
          name: entry,
          editorState
        }
      }
    })
    return content
  }

  parse(phText) {
    return sanitizeHtml(phText, {
      allowedTags: ['p'],
      allowedAttributes: {}
    })
  }

  render() {
    // Nothing to be rendered. Only the component logic is used - no DOM appearence
    return null
  }
}
