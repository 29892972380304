import {
  isObservable, isObservableArray, isObservableObject
} from 'mobx'


// eslint-disable-next-line consistent-return
export function observableShape(props, propName, componentName) {

  if (!isObservable(props[propName])) {
    return new Error(
      `Invalid prop \`${propName}\` supplied to \`${componentName} \`.
      Validation failed: Expected mobx Observable.`
    )
  }

}

// eslint-disable-next-line consistent-return
export function observableArrayShape(props, propName, componentName) {

  if (!isObservableArray(props[propName])) {
    return new Error(
      `Invalid prop \`${propName}\` supplied to \`${componentName} \`.
      Validation failed: Expected mobx ObservableArray.`
    )
  }

}

// eslint-disable-next-line consistent-return
export function observableObjectShape(props, propName, componentName) {

  if (!isObservableObject(props[propName])) {
    return new Error(
      `Invalid prop \`${propName}\` supplied to \`${componentName} \`.
      Validation failed: Expected mobx ObservableObject.`
    )
  }

}
