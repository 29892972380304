import ModelSyncAction from './ModelSyncAction'

export default class SaveAction extends ModelSyncAction {
  constructor(model) {
    super(model)
    this.asJSON = undefined
    this.asString = undefined
  }

  executeAction(opts = {}) {
    return this.prepareData()
      .then(() => this.checkNeedsSave(opts))
      .then(() => this.save(opts))
  }

  prepareData() {
    try {
      this.asJSON = this.model.getJSON()
      this.asString = JSON.stringify(this.asJSON)
      return Promise.resolve()
    }
    catch (error) {
      return Promise.reject(error)
    }
  }

  checkNeedsSave(opts) {
    // Check whether the data did change or a save was forced.
    // Only save if it did.
    this.didChange
      = opts.force || this.asString !== this.model.previouslySavedJSON
  }

  save(opts) {
    const { model } = this
    let data = model

    if (!this.didChange) {
      // if no change, return the currently running save promise...
      if (model.saving) {
        return model.saving
      }
      // or just the current data as a promise
      return Promise.resolve(this.asJSON)
    }

    // sequencialize new save calls
    const savePromise = model.saving || Promise.resolve(true)

    // use specific data for saving when passed. Default data is the model.
    if (opts.data) {
      data = opts.data
    }

    return savePromise.then(() => {
      model.saving = model.store
        .save(model.id, data, opts)
        .then(result => this.handleSaveSuccess(result))
        .catch(err => this.handleSaveError(err))
      return model.saving
    })
  }

  handleSaveSuccess(result) {
    this.model.previouslySavedJSON = this.asString
    // this.model.set(result.body)
    this.model.saving = null
    return result
  }

  handleSaveError(err) {
    this.model.saving = null
    throw err
  }
}
