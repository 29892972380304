import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { confirmable } from 'react-confirm'
import { autobind } from 'core-decorators'
import classNames from 'classnames'
import { formatMessage, FormattedMessage } from '../../../translations'
import GenevaButton from '../../../ui/components/GenevaButton'

import { Dialog } from '.'

const css = /* typeof window === 'undefined' ? {} : */require('./styles.scss')

@confirmable
export default class InfoDialog extends Component {

  static propTypes = {
    show: PropTypes.bool,            // indicates if the dialog is shown or not.
    proceed: PropTypes.func,         // call to close the dialog with promise resolved.
    cancel: PropTypes.func,          // call to close the dialog with promise rejected.
    dismiss: PropTypes.func,         // call to only close the dialog.
    confirmation: PropTypes.string,  // arguments of your confirm function
    options: PropTypes.object        // arguments of your confirm function
  }

  @autobind
  getFocus() {
    return this.focus
  }

  @autobind
  handleRef(el) {
    if (el !== null) {
      this.focus = el
    }
  }

  @autobind
  renderFooter() {
    const {
      options: { actionButtonTextId },
    } = this.props

    return (
      <div className={css.footerButtons}>
        <GenevaButton
          type="button"
          className="small button"
          onClick={this.props.dismiss}
        >
          <FormattedMessage id="simple-dialog.cancel" />
        </GenevaButton>
        <GenevaButton
          type="submit"
          className="small primary button"
          onClick={this.props.proceed}
        >
          <FormattedMessage id={actionButtonTextId || 'simple-dialog.confirm'} />
        </GenevaButton>
      </div>
    )
  }

  // No header to display
  renderHeader() {
    return null
  }

  render() {

    const {
      show,
      options: { text, titleText },
    } = this.props

    return (
      <Dialog
        className={css.infoDialog}
        isOpen={show}
        onRequestClose={this.handleRequestClose}
        renderFooter={this.renderFooter}
        renderHeader={this.renderHeader}
        focusEl={this.getFocus}
      >
        <span className={css.titleText}>
          {titleText}
        </span>
        <span className={css.textContent}>
          {text}
        </span>
      </Dialog>
    )
  }
}
