import { transaction } from 'mobx'

export default class RemoveFromPageCommand {
  constructor(pageModel, fromPosition, toPosition) {
    this.pageModel = pageModel
    this.fromPosition = fromPosition
    this.toPosition = toPosition
  }

  exec() {
    transaction(() => {
      const gridBlockFrom = this.pageModel.getGridBlock(this.fromPosition)
      const gridBlockTo = this.pageModel.getGridBlock(this.toPosition)

      if (!gridBlockFrom || !gridBlockTo) {
        // eslint-disable-next-line max-len
        throw new Error(
          `Page ${this.pageModel.name} has no grid block at gr${this.data.gr}.gb${this.data.gb}`
        )
      }

      const isSameBlock = gridBlockFrom.isSameAs(gridBlockTo)

      if (isSameBlock && gridBlockFrom.last() === gridBlockTo.last()) {
        return // same: don't do anything
      }

      const item = gridBlockFrom.remove(this.fromPosition)
      gridBlockTo.insert(this.toPosition, item)

      this.pageModel.saveDebounced()
    })
  }
}
