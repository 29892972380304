/**
 * An abstract class to be overritten in concrete implementations. The class
 * provides unified methods for how all server-sync actions.
 * It sets the models isUpdating property to true at the beginning of the sync
 * and back to false afterwards.
 * @abstract
 */
export default class ModelSyncAction {
  constructor(model) {
    this.model = model
  }

  validate() {
    return new Promise((resolve, reject) => {
      if (!this.model.store) {
        // eslint-disable-next-line max-len
        reject(
          new Error(
            `The model of type "${this.constructor.name}" has no store property. Each model that shouls be saved NEEDS a store!`
          )
        )
      }

      if (!this.model.getJSON) {
        // eslint-disable-next-line max-len
        reject(
          new Error(
            `Trying to save the Model "${this.model.constructor.name}" but getJSON is missing on this model`
          )
        )
      }

      resolve(true)
    })
  }

  execute(opts = {}) {
    if (this.model.setBeginUpdate) {
      this.model.setBeginUpdate()
    }

    return this.validate()
      .then(() => this.executeAction(opts))
      .then((result) => {
        if (this.model.setFinishUpdate) {
          this.model.setFinishUpdate()
        }
        return result
      })
      .catch((ex) => {
        if (this.model.setFinishUpdate) {
          this.model.setFinishUpdate()
        }
        throw ex
      })
  }

  /**
   * @abstract
   * @method executeAction
   * @return {Promise}      The result of the action you want to implement
   */
  executeAction() {
    console.log('Overwrite executeAction in your concrete implementation')
  }
}
