import { observable, extendObservable } from 'mobx'
import { store as uiStore } from '../ui'

class Context {

  static CONTEXT_TYPE_ARTICLE = 'article'

  static CONTEXT_TYPE_WIDGET = 'widget'

  static CONTEXT_TYPE_LINK = 'link'

  static CONTEXT_TYPE_TEXT = 'text'

  static CONTEXT_TYPE_IMAGE = 'image'

  static CONTEXT_TYPE_VIDEO = 'video'

  static CONTEXT_TYPE_FILE = 'file'

  static CONTEXT_TYPE_CENSHARE = 'censhare'

  static CONTEXT_TYPE_VERSION = 'version'

  static subContextTypes = [
    'link'
  ]

  static defaultContext = {
    type: null,
    target: null,
    placeholder: null,
    options: null
  }

  static isSubContextType(contextType) {
    return Context.subContextTypes.indexOf(contextType) >= 0
  }


  @observable type = null

  @observable target = null

  @observable subContexts = []

  @observable editorButtons = []

  @observable value = null

  @observable placeholder = null

  @observable options = null

  @observable preventBlur = false

  @observable currents = {}

  @observable contextLock = false

  createFromElement(target) {

    // No context change as long as the lock is active
    if (this.contextLock) {
      return
    }

    if (!target) {
      this.set(null)
      this.resetSubContexts()
      return
    }

    if (Context.isSubContextType(target.type)) {
      this.addSubContextFromElement(target)
      return
    }

    // reset all sub contexts. they wil be re-populated as the events bubble
    // further up the DOM
    this.resetSubContexts(null)

    if (target.type === Context.CONTEXT_TYPE_TEXT) {
      this.setEditorContext(target.buttonList)
    }

    const contextTypeRequiresPID = target.type !== Context.CONTEXT_TYPE_ARTICLE
      && target.type !== Context.CONTEXT_TYPE_WIDGET
      && target.type !== Context.CONTEXT_TYPE_VERSION

    if (contextTypeRequiresPID && !target.pid) {
      this.set(null)
      // eslint-disable-next-line max-len
      throw new Error('The `target` argument for `Context#createFromTarget` has to have a `pid` property')
    }

    let contextType = Context.CONTEXT_TYPE_ARTICLE
    const type = target.type

    if (type) {

      switch (type) {

        // main contexts
        case Context.CONTEXT_TYPE_IMAGE:
        case Context.CONTEXT_TYPE_TEXT:
        case Context.CONTEXT_TYPE_VIDEO:
        case Context.CONTEXT_TYPE_FILE:
          contextType = type
          break

        case Context.CONTEXT_TYPE_WIDGET:
          contextType = Context.CONTEXT_TYPE_WIDGET
          break

        case Context.CONTEXT_TYPE_VERSION:
          contextType = Context.CONTEXT_TYPE_VERSION
          break

        case Context.CONTEXT_TYPE_CENSHARE:
          contextType = Context.CONTEXT_TYPE_CENSHARE
          break

        default:
        case Context.CONTEXT_TYPE_ARTICLE:
          contextType = Context.CONTEXT_TYPE_ARTICLE

      }

    }

    // only update the context if it actually changed
    if (!contextTypeRequiresPID || this.pid !== target.pid) {

      this.set({
        type: contextType,
        target
      })

    }


  }

  resetSubContexts() {
    this.subContexts.splice(0, this.subContexts.length)
  }

  addSubContextFromElement(target) {

    const existing = this.subContexts.find(ctx => ctx.type === target.type)

    if (existing) {
      this.subContexts.splice(this.subContexts.indexOf(existing), 1)
    }

    this.subContexts.push({
      type: target.type,
      target
    })

  }

  setEditorContext(buttonList) {
    // Empty the list
    this.editorButtons.splice(0, this.editorButtons.length)

    if (buttonList && buttonList.length > 0) {
      buttonList.forEach((button) => {
        this.editorButtons.push(button)
      })
    }
  }

  set(context) {
    // No context change as long as the lock is active
    if (this.contextLock) {
      return
    }

    extendObservable(this, {}, Context.defaultContext, context || {})
  }

  get(contextType) {
    let context = {}

    if (this.type === contextType) {
      context = this
    }
    else if (Context.isSubContextType(contextType)) {
      context = this.subContexts
        .find(ctx => ctx.type === contextType) || {}
    }

    return context.target
  }

  assert(type) {

    if (!Array.isArray(type)) {
      type = [type]
    }

    if (!type.find((t => t === this.type))) {
      throw new Error(
        `ContextError: Current context type is \`${this.type}\`, not \`${type}\``
      )
    }

  }

  // Activate the context lock
  lockContext() {
    this.contextLock = true
  }

  // Remove the context lock
  unlockContext() {
    this.contextLock = false
  }

  ifInContext(type, cb) {

    try {
      this.assert(type)
      cb()
    }
    catch (ex) {
      if (ex.message.indexOf('ContextError') === -1) {
        throw ex
      }
      // console.log(
      //   `Action for context \`${JSON.stringify(type)}\` canceled ` +
      //   `as it doesn't match current context \`${this.type}\`.`
      // )
    }

  }

  displayContextInfo(contextInfo) {

    if (contextInfo && contextInfo.infos) {
      uiStore.addStatusInfo({
        data: contextInfo.infos.data,
        type: 'validation',
        priority: 'low',
        id: contextInfo.infos.name
      })
    }

    if (contextInfo && contextInfo.errors) {
      uiStore.addStatusInfo({
        data: contextInfo.errors.data,
        type: 'error',
        priority: 'high',
        id: contextInfo.errors.name
      })
    }

  }

  removeContextInfo(contextInfoId) {
    uiStore.removeStatusInfo(contextInfoId)
  }

  matches(item = null) {
    if (!(item && this.target)) {
      return false
    }
    if (('target' in item)) {
      item = item.target
    }
    const { gr, gb, index, id, type } = this.target
    return item.gr === gr
      && item.gb === gb
      && item.index === index
      && item.type === type
      && item.id === id
  }

  setCurrentIds(ids) {
    this.currents = {
      ...ids
    }
  }

  // Get the current ids for viewModel creation
  getCurrentIds() {
    // Ensure number
    return {
      articleId: this.currents.articleId * 1,
      pageId: this.currents.pageId * 1,
      projectId: this.currents.projectId * 1,
      widgetId: this.currents.widgetId * 1,
      articleAction: this.currents.articleAction,
      widgetAction: this.currents.widgetAction,
      articleActionDetails: this.currents.articleActionDetails
    }
  }

}


export const contextStore = new Context()
