/**
* Creates a new Image object from a current image using a cacheBust and thus
* forcing a cache free refetch from the server. Requests in the future making
* requests for the same image with the same cacheBust will be serverd this
* cached version.
* @param {Object|String} image - Either an object containing the image's `src`
* or the actual `src` string.
* @property {String} image.src - The actual `src` string.
* @param {Number} iteration - used to 'update' the cache by adding a param to the
* url. example: send '1' for the first, then send '2' when the cache needs to be updated
* @returns {Image} - The cached image or null if cache could not be created.
*/

export default function cacheImage(
  image: Object|string,
  cacheBust?: number|string = new Date().getTime()
): boolean {

  let src = image

  if (typeof image !== 'string') {
    src = 'src' in image
      ? image.src
      : null
  }

  if (!src || typeof src !== 'string') {
    console.warn('Function `cacheImage` requires an `src` property of type string on it\'s first argument to return an image')
    return null
  }

  const cachedImage = new Image()
  cachedImage.src = `${src}?${cacheBust}`
  cachedImage.cacheBust = cacheBust

  return cachedImage
}
