
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
// import { Link } from 'react-router'
// import { FormattedMessage } from '../../i18n'
// import classNames from 'classnames'
// import Icon from '../../shared/icons'
// import { store as authStore } from '../../auth'
// import GenevaButton from '../../ui/components/GenevaButton'
import { ADMIN_MODE } from '../../shared/const'

// const css = /* typeof window === 'undefined' ? {} : */require('../styles.scss')

// function logout() {
//   authStore.logout()
// }

const AppDashboard = observer(() => {

  // goes to AM for login unless specificed
  const adminMode = window.location.search.substr(1).split('&').find(item => item === ADMIN_MODE)

  if (!adminMode) {
    window.location.href = `${window.location.href}publish`
  }

  // With admin mode, the AdminMode needs to be removed before redirecting
  // Using else to avoid race condition between window.location.href's
  else {
    window.location.href = `${window.location.origin}/publish`
  }

  /* no need for an app select if the user is in Admin mode (that doens't have app options)

    const username = props.auth &&
      props.auth.user &&
      props.auth.user.name

    return (
      <div
        className={
          classNames('grid-block', 'v-align', 'align-center', css.appBackground)
        }
        style={{
          backgroundImage: 'url(/images/bg-blue.svg)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center bottom'
        }}
      >
        <div
          className={classNames(
            'grid-block',
            'vertical',
            css.appDashboard
          )}
        >

          {
            (!username)
            // THIS CANNOT BE INTERNATIONALIZED AS USER LANGUAGE
            // NOT YET KNOWN
            ? (<div>Please wait</div>)
            : (<div>
              <header>
                <div>
                  <strong>
                    <FormattedMessage id="app.hello"
                      values={{
                        username
                      }}
                    />
                  </strong>
                  <GenevaButton onClick={logout}>
                    <Icon name="ion-power" />
                  </GenevaButton>
                </div>
                <div>
                  <FormattedMessage id="app.what-to-start-with" />
                </div>
              </header>
              <ul className="no-bullet app-list">

                {Object.keys(config.apps).map((name) => {
                  const link = config.apps[name]
                  return (<li key={name}>
                    <Link to={link.url}>
                      <div className={css.appLink}
                        style={{
                          backgroundColor: link.background
                        }}
                      >
                        {link.acronym}
                      </div>
                      <div className="app-desc">
                        <span
                          className="app-title"
                        >{link.name}</span>
                        <FormattedMessage id={link.tagLine} />
                      </div>
                    </Link>
                  </li>)
                })}
              </ul>
            </div>)
          }
        </div>
      </div>
    )
  */
})

AppDashboard.propTypes = {
  auth: PropTypes.object
}

export { AppDashboard as default }
