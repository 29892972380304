export default function versionDecorator(target) {

  const provider = target.prototype

  if (!provider.constructor.INVALID_INSTANCE_VERSION) {
    // eslint-disable-next-line max-len
    throw new Error('The `versionDecorator` requires the target to provide the static property `INVALID_INSTANCE_VERSION`.')
  }

  Object.defineProperty(provider, 'isVersion', {
    get: function isVersion() {
      return this.instanceVersion
        && this.instanceVersion !== provider.constructor.INVALID_INSTANCE_VERSION
    }
  })

  // extendObservable(provider, {
  //   versionType: function versionType() {
  //
  //     const versions = this.versions
  //
  //     const latest = maxBy(
  //       versions,
  //       (version) => version.versionNumber
  //     )
  //
  //     console.log(this.versionNumber, latest.versionNumber)
  //     if (this.versionNumber === latest.versionNumber) {
  //       return 'currentRelease'
  //     }
  //
  //     if (!this.isVersion) {
  //       return 'draft'
  //     }
  //
  //     return 'version'
  //   }
  // })

  // Object.defineProperty(provider, 'versionType', {
  //   get: function
  // })

}
