import * as PropTypes from 'prop-types'
import React, { Component } from 'react'

import ContentLoadingBox from '../components/ContentLoadingBox'

import cancelable from '../decorators/cancelable-promise'

import loadPlugins from './loadPlugins'

/**
 * A PluginHook Component may be rendered anywhere. It takes a `hook` prop
 * specifying the templates that should be loaded.
 */
@cancelable
export default class PluginHook extends Component {
  static propTypes = {
    children: PropTypes.node,
    hook: PropTypes.string,
  };

  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
      plugins: [],
    }
  }

  componentDidMount() {
    const { hook, intl } = this.props
    const additionalDeps = { intl }
    this.makeCancelable(loadPlugins(hook, additionalDeps)).then((plugins) => {
      this.setState({
        plugins,
        loaded: true,
      })
    })
  }

  renderPlugin(module, pluginSpec, children = null) {
    if (module instanceof Error) {
      throw module
    }

    const Comp = module

    return <Comp {...pluginSpec} {...this.props} children={children} />
  }

  renderPlugins() {
    const { hook } = this.props
    const { plugins } = this.state

    // If there are no children in the plugin hook,
    // render a flat array of plugins after eachother.
    if (!this.props.children) {
      return (plugins || []).map(({ module, pluginSpec }) => {
        try {
          return this.renderPlugin(module, pluginSpec)
        }
        catch (ex) {
          // eslint-disable-next-line no-restricted-globals
          console.warn(
            `There was some error creating the \`${pluginSpec.name}.${hook}\` plugin:`,
            ex
          )
          return null
        }
      })
    }

    // Otherwise build a tree structure with the children at
    // the bottom most and each plugin within the other.
    return plugins
      .reverse()
      .reduce((children, { module, pluginSpec, name }) => {
        try {
          return this.renderPlugin(module, pluginSpec, children)
        }
        catch (ex) {
          // eslint-disable-next-line no-restricted-globals
          console.warn(
            `There was some error creating the \`${name}.${hook}\` plugin:`,
            ex
          )
          return children
        }
      }, this.props.children)
  }

  render() {
    const { hook } = this.props
    const { plugins } = this.state

    // while loading, show a loader
    if (Array.isArray(plugins) && plugins.length && !this.state.loaded) {
      return (
        <ContentLoadingBox
          message={{
            id: 'plugin.loading',
          }}
        />
      )
    }

    return <div className={`plugin-hook-${hook}`}>{this.renderPlugins()}</div>
  }
}
