import React from 'react'
import { observer } from 'mobx-react'

import didChange from '../../utils/did-change'
import PureStatusList from './StatusList'
import PureStatusItem from './StatusItem'

const StatusItemDisplayer = observer(({ item }) => {
  didChange(item.lastUpdated)
  return <PureStatusItem item={item} />
})

const StatusListDisplayer = observer(({ ui, className }) => {
  didChange(ui.statusInfosIdStack.length)
  return (<PureStatusList
    statusInfos={ui.statusInfos}
    statusInfosIdStack={ui.statusInfosIdStack}
    className={className}
    StatusItem={StatusItemDisplayer}
  />)
})

export { StatusItemDisplayer as StatusItem }
export { StatusListDisplayer as default }

export connectStatusToComponent from './connector'
