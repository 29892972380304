import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import { autobind } from 'core-decorators'
import { confirmable } from 'react-confirm'
import classNames from 'classnames'
import { formatMessage, FormattedMessage } from '../../../translations'

import { store as articleStore } from '../../../article'

import FileSelectButton from '../FileSelectButton'
import { connectToPluginHook } from '../../plugin'
import Icon from '../../icons'

import GenevaButton from '../../../ui/components/GenevaButton'

import DefaultUploadImageForm from './DefaultUploadImageForm'
import DefaultUploadFileForm from './DefaultUploadFileForm'
import { Dialog } from '.'

const css = /* typeof window === 'undefined' ? {} : */ require('./styles.scss')

@confirmable
class UploadDialog extends Component {
  static propTypes = {
    show: PropTypes.bool, // indicates if the dialog is shown or not.
    proceed: PropTypes.func, // call to close the dialog with promise resolved.
    cancel: PropTypes.func, // call to close the dialog with promise rejected.
    dismiss: PropTypes.func, // call to only close the dialog.
    confirmation: PropTypes.string, // arguments of your confirm function
    options: PropTypes.object, // arguments of your confirm function
    plugins: PropTypes.array,
  };

  constructor(props) {
    super(props)

    this.state = {
      selectedTab:
        props.options.selectedTab || props.options.mediaType || 'image',
      mediaType: props.options.mediaType,
      checkedRadio: 'fromUpload', // default
      video:
        !this.props.options.mediaType
        || this.props.options.mediaType === 'video',
      image:
        !this.props.options.mediaType
        || this.props.options.mediaType === 'image',
      file: this.props.options.mediaType === 'file',
      id: this.props.options.id,
      url: this.props.options.url,
    }

    // Standard upload forms provided by GENEVA base
    this.tabs = []
    if (this.state.image) {
      this.tabs.push({
        name: 'image',
        component: DefaultUploadImageForm,
        icon: 'ion-ios-camera-outline',
      })
    }

    if (this.state.file) {
      this.tabs.push({
        name: 'file',
        component: DefaultUploadFileForm,
        icon: 'ion-document',
      })
    }
  }

  // Uses a given handleChange as default.
  // Optional a function to determine the proper handle function itself
  @autobind
  getSpecificHandleFunctions(type) {
    let func = this.props.options.handleChange
    if (this.props.options.getSpecificHandleFunctions) {
      func = this.props.options.getSpecificHandleFunctions(
        this.state.selectedTab
      )[type]
    }
    else if (this.props.options[type]) {
      func = this.props.options[type]
    }
    return func
  }

  @autobind
  getFocus() {
    return this.focus
  }

  @autobind
  handleRef(el) {
    if (el !== null) {
      this.focus = el
    }
  }

  @autobind
  handleClickOptionElement(e) {
    e.preventDefault()

    this.setState({
      selectedTab:
        e.target.name
        || e.target.parentElement.name
        || e.target.parentElement.getAttribute('for'),
    })
  }

  @autobind
  handleValidation(isValid) {
    this.setState({
      isValid,
    })
  }

  @autobind
  handleProceedOnChange(file) {
    this.handleOnBeforeItemChange(this.state.selectedTab)

    this.getSpecificHandleFunctions('handleChange')(file)

    this.props.dismiss()
  }

  @autobind
  handleAbortOnError(err) {
    this.getSpecificHandleFunctions('handleError')(err)
  }

  @autobind
  handleRadioClick({ target }) {
    this.setState({
      checkedRadio: target.id,
    })
  }

  @autobind
  handleInputChange({ target }, isValid = false) {
    this.setState({
      [target.id]: target.value,
      isValid,
    })
  }

  @autobind
  handleOnBeforeItemChange(type) {
    if (this.props.options.onBeforeItemChange) {
      // Ensure the context is set and ready to insert
      this.props.options.onBeforeItemChange(type)
    }
  }

  @autobind
  handleCommit(e) {
    if (e && e.preventDefault) {
      e.preventDefault()
    }

    this.handleOnBeforeItemChange(this.state.selectedTab)

    const articleLang = articleStore.current.createdIso
    const result = {
      type: this.state.selectedTab,
      tab: this.state.selectedTab
    }

    if (this.state.checkedRadio === 'fromExternal') {
      result.url = this.state.url
    }
    else if (this.state.checkedRadio === 'fromMediamanager') {
      result.type = 'fromMediamanager'
    }
    else if (this.state.checkedRadio === 'fromBynder') {
      result.type = 'fromBynder'
    }
    else {
      const tabSpec = this.props.plugins.find(
        item => item.module.name === this.state.selectedTab
      )
      if (tabSpec.module.resolve) {
        result.url = tabSpec.module.resolve(
          this.state.url || this.state.id,
          articleLang
        )

        result.id = this.state.id || this.state.url
      }
    }

    this.props.proceed({
      ...result,
    })
  }

  @autobind
  renderFileSelect() {
    const config = this.props.options.config
    config.mediaType = this.state.selectedTab

    return (
      <FileSelectButton
        className="geneva-button fileSelectButton button primary"
        {...config}
        onError={this.handleAbortOnError}
        onFocus={this.props.options.handlePreventFocus}
        onChange={this.handleProceedOnChange}
        onProgress={this.getSpecificHandleFunctions('handleProgress')}
        onFileDragHover={this.props.options.handleFileDragHover}
        knownFileTypes={this.props.options.knownFileTypes}
        readFile
      >
        <FormattedMessage id="image.file-upload-dialog.confirm" />
      </FileSelectButton>
    )
  }

  @autobind
  renderFooter() {
    const { selectedTab, checkedRadio } = this.state

    return (
      <div className="grid-content text-right">
        <GenevaButton
          type="button"
          className="small button"
          onClick={this.props.dismiss}
        >
          <FormattedMessage id={`${selectedTab}.file-upload-dialog.cancel`} />
        </GenevaButton>
        {this.state.checkedRadio === 'fromUpload'
        && (this.state.selectedTab === 'image'
          || this.state.selectedTab === 'file') ? (
            this.renderFileSelect()
          ) : (
            <GenevaButton
              className="small primary button"
              disabled={!this.state.isValid && checkedRadio !== 'fromMediamanager' && checkedRadio !== 'fromBynder'}
              onClick={this.handleCommit}
              type="submit"
            >
              <FormattedMessage
                id={`${selectedTab}.file-upload-dialog.confirm`}
              />
            </GenevaButton>
          )}
      </div>
    )
  }

  renderOptionElement(tabSpec) {
    const name = tabSpec.name
    const messageID = `${tabSpec.name}.file-upload-dialog.button`
    const selected = this.state.selectedTab === name

    return (
      <GenevaButton
        id={name}
        name={name}
        key={name}
        type="button"
        onClick={this.handleClickOptionElement}
        className={classNames({ selected }, 'plain button tab-button')}
      >
        <Icon name={tabSpec.icon} />
        <br />
        <FormattedMessage id={messageID} />
      </GenevaButton>
    )
  }

  renderOptionBar(tabs) {
    return (
      <div className="grid-content setting-option">
        {tabs.map(item => this.renderOptionElement(item))}
      </div>
    )
  }

  render() {
    const tabs = [...this.tabs]

    const pluginTabs = (this.props.plugins || []).map(({ module }) => module)

    if (pluginTabs.length) {
      pluginTabs.forEach((item) => {
        if (this.state[item.name]) {
          const ele = tabs.find(el => el.name === item.name)
          // Plugin components replace existing ones
          if (ele) {
            tabs.splice(ele, 1, item)
          }
          else {
            tabs.push(item)
          }
        }
      })
    }

    const { selectedTab } = this.state
    const tabSpec = tabs.find(tab => tab.name === selectedTab)
    const FormComponent = tabSpec ? tabSpec.component : null

    return (
      <Dialog
        className={css.uploadDialog}
        isOpen={this.props.show}
        renderFooter={this.renderFooter}
        title={formatMessage({ id: `${selectedTab}.file-upload-dialog.title` })}
        focusEl={this.getFocus}
      >
        {tabs.length > 1 ? this.renderOptionBar(tabs) : null}
        <div className="grid-block main-content">
          {FormComponent ? (
            <FormComponent
              {...this.props}
              {...this.state}
              handleRef={this.handleRef}
              onInputChange={this.handleInputChange}
              onRadioClick={this.handleRadioClick}
              onValidation={this.handleValidation}
            />
          ) : null}
        </div>
      </Dialog>
    )
  }
}

const PluginConnectedUploadDialog = connectToPluginHook(UploadDialog, {
  hook: 'uploadDialog',
})
export default PluginConnectedUploadDialog
