import { observable } from 'mobx'
import { paginate, filterable, apiClient } from '../../shared/api'

import { Store } from '../../shared/store'
import { deepGet } from '../../shared/obj'
import { inspector } from '../../shared/decorators/inspector'
import { overloadable } from '../../shared/decorators/overload'
import { CreateVersionInspector } from './inspectors'
import Version from './model'
import { addToCollection } from '../../shared/utils'


const defaultVals = {
  page: 0,
  hasMoreItems: null,
  collection: [],
  filter: {},
  loading: null,
  total: 0,
}
@paginate
@overloadable
@filterable
@apiClient
@inspector('create', CreateVersionInspector)
@inspector('restore', 'version.restoring')
@inspector('load', 'version.loading')
class ArticleVersionStore extends Store {
  // Put all version list properties here. This regargds also state properties.

  @observable collection = [];

  @observable current = {};

  @observable total = 0;

  @observable page = 0;

  @observable limit = 20;

  @observable loading = false;

  hasMoreItems = true;

  @observable filter = {};

  @observable sorting = {};

  // Put all properties that are not to be observed here:

  constructor(opts = {}) {
    if (!opts.article) {
      throw new Error('No article passed to the ArticleVersionStore.')
    }

    super(opts)

    this.Model = Version

    this.collection = [
      ...(opts.article.versions || []).map(version => this.createModel(version)
      ),
    ]
  }

  load(id, opts) {
    // since changes are unlikely to happen not on the users machine, api loads are only needed if model is missing
    return this.getByIdAsync(id, opts)
      .then((versionModel) => {
        if (versionModel && versionModel.isComplete) {
          return versionModel
        }
        return this.dispatch('get', {
          path: `article/${this.opts.article.id}/version/${id}`
        }).then((response) => {
          if (!response || !response.body || response.error) {
            throw new Error('error in version get')
          }

          response.body.data.id = id
          response.body.data.isComplete = true
          return addToCollection(this, this.collection, response.body.data, Version)
        })
      })
  }

  reset(opts) {
    if (this.loading) {
      return
    }

    const vals = {
      ...defaultVals,
    }

    delete vals.filter

    if (!('filter' in opts)) {
      delete vals.collection
    }

    Object.assign(this, {
      ...vals,
      ...opts,
    })

    this.load()
  }

  create() {
    return this.dispatch('post', {
      path: `article/${this.opts.article.id}/version`
    }).then((result) => {
      // a message implys something failed
      if (result.body.message) {
        throw new Error(result.body.message)
      }
      else {
        this.collection = [
          ...result.body.data.versions.map(version => this.createModel(version)),
        ]
      }
    })
  }

  restore(id) {
    return this.dispatch('post', {
      path: `article/${this.opts.article.id}/version/${id}/restore`
    }).then((result) => {
      // The shared/Store will push an instance to the collection.
      // That is unnecessary in this case. So remove that element again
      const existing = this.collection.find(
        version => version.id === result.id
      )

      if (existing) {
        this.collection.splice(this.collection.indexOf(existing), 1)
      }

      return result.body.data
    })
  }

  getEditorState(pid) {
    const ret = deepGet(this.placeholder, `${this.channelShortcut}.${pid}`)
    return deepGet(ret, `i18n.${this.createdIso}.editorState`)
  }

  canLoad() {
    return !this.loading && this.hasMoreItems
  }

  addNote(note, version) {
    // Adding the note.  This is observable and push has an issue, so copying
    //  to a seperate array, pushing, then copying back
    const tmp = version.notes.slice()
    tmp.push(note)
    version.notes = tmp

    // Notifying the server
    return this.dispatch('post', {
      path: `article/${this.opts.article.id}/version/${version.id}/note`,
      data: {
        text: note.text
      }
    }).then((result) => {
      if (!result || !result.body || result.error) {
        throw new Error('error in version get')
      }

      // Compares the notes and uses the one from the server if not the same
      const versionNotes = deepGet(result, `body.data.versions.${version.id}.notes`)

      if (!versionNotes.length) {
        throw new Error('VersionModel: No notes found in version!')
      }

      const serverNote = versionNotes[versionNotes.length - 1]

      // the clientside note is already in the version note list, so just get
      // it's index and then update the version note list at this position with
      // the note coming from the server
      const index = version.notes.indexOf(note)

      if (index > -1) {
        version.notes[index] = serverNote
      }
    })
      .catch((err) => {
        // Removing the note in error case
        version.notes.pop()
        throw err
      })
  }

  /**
   * Gets a model from the store by it's id.
   * @returns Null if no model by this id was found.
   */
  getByIdAsync(id, opts = {}) {
    return new Promise((resolve) => {
      id *= 1
      resolve(this.collection.find(item => item && item.id * 1 === id))
    })
  }
}

export const VersionStore = ArticleVersionStore
