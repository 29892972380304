export function transformToFootnoteAttributes(data = {}) {
  return data
}

export function transfromToFootnoteObject(data = {}) {
  return data
}

export function getDefaultFootnoteAttributes() {

  return {
    color: ''
  }

}

export function getFootnoteProps(props) {

  return {
    color: props.color
  }

}
