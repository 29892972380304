import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { autobind } from 'core-decorators'
import { observable, autorun } from 'mobx'
import { difference } from 'lodash'
import { intersection } from 'lodash'
import { formatMessage } from '../../translations'

import { store as uiStore } from '../../ui'
import { inspector } from '../../shared/decorators/inspector'
import alert from '../../shared/components/Alert'
import { publishPage } from '../../page/containers/dialogs'
import { internalLink } from './dialogs'
import infoDialog from '../../shared/containers/InfoDialog'

import PublicationManagerDisplay from '../components/PublicationManager'

export const PublishInfoInspector = {
  before: (target, item) => {
    if (item) {
      PublishInfoInspector.createStatusInfo('started', item, target)
    }
  },
  then: (target, item) => {
    if (item) {
      PublishInfoInspector.createStatusInfo('completed', item, target, 2000)
    }
  },
  catch: (target, item) => {
    if (item) {
      PublishInfoInspector.createStatusInfo('failed', item, target, 4000)
    }
  },
  createStatusInfo: (type, item, target, duration = 'forever') => {

    uiStore.addStatusInfo({
      id: target.constructor.name,
      priority: 'low',
      name: `pub-manager.${item}-${type}`,
      value: target ? target.state.checkedKeys.length : null
    }, duration)
  }
}

export const RequestInspector = {
  before: (target, item) => {
    const checkedPages = target.state.checkedKeys.map(el => target.props.pageStore.getPartialById(el))
    let count = checkedPages.length
    const subKeyList = [] // To keep track of counted subpages
    checkedPages.forEach((partialPage) => {
      partialPage.traverse((el) => {
        if (el.id !== partialPage.id && !target.state.checkedKeys.find(key => key * 1 === el.id) && !subKeyList.find(key => key === el.id)) {
          count += 1
          subKeyList.push(el.id)
        }
      })
    })
    uiStore.openDialog('contentLoadingBoxFullscreen', {
      spinner: true,
      message: {
        id: item === 'archiveGroup'
          ? 'status.pub-manager.archiveGroup-started'
          : 'status.pub-manager.deleteGroup-started',
        values: { count }
      }
    })
  },
  then: (target, item) => {
    uiStore.contentLoadingBoxFullscreenDialog.status = 'closed'
  },
  catch: (target, item) => {
    uiStore.contentLoadingBoxFullscreenDialog.status = 'closed'
  }
}

@inspector('executePublishRequest', PublishInfoInspector)
@inspector('executeRequest', RequestInspector)
class PublicationManager extends Component {

  static propTypes = {
    projectStore: PropTypes.object,
    pageStore: PropTypes.object,
    pageTree: PropTypes.object,
    specialPageTree: PropTypes.object,
    router: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
  }

  constructor(props) {

    super(props)
    this.state = {
      project: this.props.projectStore.current,
      pageStore: this.props.pageStore,
      initialKeys: [],
      expandedKeys: [],
      checkedKeys: [],
      allKeys: [],
      switchIt: true,
      parentTree: [],
      allowPublish: false,
      allowUnpublish: false,
      allowDelete: false,
      allowArchive: false,
      actionIsUpdated: false,
    }

    const dropdownBuilder = {}
    this.pageTree = this.props.projectStore.current.pageTree
    this.specialPageTree = this.props.projectStore.current.specialPageTree

    this.props.pageStore.pagePartialsCollection.forEach((page) => {
      // somehow possible to be missing an id
      if (page && page.id) {
        if (page.publishedUrl !== '') {
          this.state.initialKeys.push(page.id.toString())
        }
        this.state.allKeys.push(page.id.toString())

        // build out dropdown indicator initial set
        dropdownBuilder[page.id] = false

      }
    })
    this.dropdownIndicatorSet = observable(dropdownBuilder)

    // if pageStore still has a set current, unset it
    if (this.props.pageStore.current && this.props.pageStore.current.id) {
      this.props.pageStore.setCurrent(null)
    }
  }

  componentDidMount() {
    // opens the parent of both the regular and special tree to make it more obvious to the user
    if (this.pageTree.children) {
      this.addToExpanded(this.pageTree.children[0].id)
    }
    if (this.specialPageTree.children && this.specialPageTree.children[0]) {
      this.addToExpanded(this.specialPageTree.children[0].id)
    }

    this.handler = autorun(() => {
      this.setState({
        actionIsUpdated: false
      })
      // Dummy return to trigger autorun on toggle change
      return this.props.pageStore.publishToggle
    })
  }

  componentWillUnmount() {
    this.handler()
    this.handler = null
  }

  baseData = {
    publishAt: null,
    mailTo: null,
  }

  executePublishRequest(action, pubOptions = {}) {
    const toChange = this.state.checkedKeys.map((id) => {
      return Number(id)
    }).filter((id) => {
      return id >= 0
    })

    const data = {
      pageIds: toChange,
      ...this.baseData
    }

    return this.state.pageStore.publishGroup({ ...data, ...pubOptions }, { action })
      .catch((err) => {
        console.warn('publishing error:', err.message, err)
        if (action === 'publishGroup') {
          alert(formatMessage(
            { id: 'publicationTree-error' }
          ),
          formatMessage({ id: 'publicationTree-error-title' }))
        }
        else {
          alert(formatMessage(
            { id: 'publicationTree-unpublishing-error' }
          ),
          formatMessage({ id: 'publicationTree-unpublishing-error-title' }))
        }
        throw err
      })
  }

  /** Extension to basic expand commands to support custom actions like
   * selecting all children of a parent
   * This follows for the below functions also
   * @param {number|Array} id - (change this to either array or number)
   */
  addToExpanded(id) {
    const holder = this.state.expandedKeys
    const ids = typeof id === 'number' ? [id] : id

    ids.forEach((element) => {
      if (holder.indexOf(element.toString()) === -1) {
        holder.push(element.toString())
      }
    })

    this.setState({
      expandedKeys: holder
    })
  }

  // wipes all dropdown indicators in observable
  @autobind
  resetIndicatorSet() {
    Object.keys(this.dropdownIndicatorSet).forEach((key) => {
      this.dropdownIndicatorSet[key] = false
    })
  }

  /**
   * Sort of a ass-backward way to know if all children are still selected
   *  but the selection is not exposed here (only in the Tree library)
   */
  @autobind
  checkChildrenAreAllSelected(checkedKeys) {
    // to minimize pref impact, runs only on keys that actively had all children selected
    Object.keys(this.dropdownIndicatorSet).reduce((memo, entry) => {
      if (this.dropdownIndicatorSet[entry] === true) {
        memo.push(entry)
      }
      return memo

    }, []).forEach((parent) => {
      const children = (this.props.pageStore.getPartialById(parent)).allChildren

      // remove parent if a child is not still selected
      children.forEach((child) => {
        if (!checkedKeys.includes(`${child}`)) {
          this.dropdownIndicatorSet[parent] = false
        }
      })
    })
  }

  /** param {array} checkedKeys - array of numbers as strings
   * example: ["111", "101"]
   */
  @autobind
  handleOnCheck(checkedKeys) {
    const parentTree = this.state.parentTree
    const removed = difference(this.state.checkedKeys, checkedKeys)
    const added = difference(checkedKeys, this.state.checkedKeys)
    let index
    let parent

    if (added.length) {
      const addedPage = this.props.pageStore.getPartialById(added[0])

      // check if parent is already selected
      parent = parentTree.find(el => el.parent.id === addedPage.parent.id)
      if (parent) {
        // Add added page as sub
        parent.subs.push(addedPage)
      }
      else {
        // Add added page as parent
        parentTree.push({
          parent: addedPage,
          subs: []
        })
      }
    }
    else {
      const removedPage = this.props.pageStore.getPartialById(removed[0])
      // check if parent is already selected
      parent = parentTree.find(el => el.parent.id === removedPage.parent.id)
      index = parentTree.findIndex(el => el.parent.id === removedPage.id)

      if (parent && index === -1) {
        // Remove removed page from sub
        index = parent.subs.findIndex(el => el.id === removedPage.id)
        if (index > -1) {
          parent.subs.splice(index, 1)
        }
      }
      else {
        // Remove removed page from parent
        parentTree.splice(index, 1)
      }
    }

    const expandSet = this.state.expandedKeys
    expandSet.push(checkedKeys[checkedKeys.length - 1])

    this.setState({
      expandedKeys: expandSet,
      checkedKeys,
      parentTree,
      actionIsUpdated: false,
    })
    this.checkChildrenAreAllSelected(checkedKeys)
  }

  @autobind
  handleExpand(expandedKeys) {
    this.setState({
      expandedKeys
    })
  }

  @autobind
  handleOfflinePage() {
    const page = {}

    internalLink({
      headerTextId: 'project-internal-link-dialog.title',
      closeButtonId: 'simple-dialog.cancel',
      confirmButtonId: 'project-internal-link-dialog.take-offline',
      pageIds: this.state.checkedKeys.map(el => el * 1), // Ensure numbers
      router: this.props.router
    }).then(() => {

      publishPage({
        page,
        jobType: 'unpublish'
      })
        .then((result) => {
          this.executePublishRequest('unpublishGroup', result.unpublish)
            .then(() => {
              this.setState({
                checkedKeys: this.state.checkedKeys
              })
            })
        })
    })
  }

  @autobind
  handlePublishPage() {
    const page = {}

    publishPage({
      page,
      jobType: 'publish'
    })
      .then((result) => {
      // standard pub action unless special unpublish only request
        if (!result.unpublishOnly) {
          this.executePublishRequest('publishGroup', result.publish)
            .then(() => {
              this.setState({
                checkedKeys: this.state.checkedKeys
              })
            })
        }
        // Check if unpublish action is required
        if (result.unpublish && result.unpublish.unpublishAt) {
          this.executePublishRequest('unpublishGroup', result.unpublish)
            .then(() => {
              this.setState({
                checkedKeys: this.state.checkedKeys
              })
            })
        }
      })

  }

  @autobind
  handleDeletePage() {
    return this.handleInfoDialog('page.delete.confirm', 'delete')
      .then(() => {
        return this.executeRequest('deleteGroup', this.state.parentTree)
      })
  }

  @autobind
  handleArchivePage() {
    return this.handleInfoDialog('page.archive.confirm', 'archive')
      .then(() => {
        return this.executeRequest('archiveGroup', this.state.parentTree)
      })
  }

  executeRequest(action, data) {
    return this.props.pageStore[action](data)
      .then((res) => {
        // Reset selected keys since pages got removed
        this.handleSelectNoneButton()
        return res
      })
  }

  @autobind
  handleInfoDialog(titleId, action) {
    const checkedPages = this.state.checkedKeys.map(el => this.props.pageStore.getPartialById(el))
    let count = checkedPages.length
    const titleText = formatMessage(titleId)
    const subKeyList = [] // To keep track of counted subpages

    checkedPages.forEach((partialPage) => {
      partialPage.traverse((el) => {
        if (el.id !== partialPage.id && !this.state.checkedKeys.find(key => key * 1 === el.id) && !subKeyList.find(key => key === el.id)) {
          count += 1
          subKeyList.push(el.id)
        }
      })
    })

    return infoDialog({
      titleText,
      text: formatMessage(`publicationTree.info-${action}`, { count }),
      actionButtonTextId: `page.${action}`
    })
  }

  @autobind
  handleSelectAllButton() {
    const moreKeys = this.state.allKeys
    const parentPages = this.props.projectStore.current.specialPageTreeRoot.sub
      .concat(this.props.projectStore.current.pageTreeRoot.sub)

    parentPages.forEach((page) => {
      this.handleBranchSelection(page)
    })

    this.resetIndicatorSet()
  }

  @autobind
  handleSelectNoneButton() {
    const noKeys = []
    this.setState({
      checkedKeys: noKeys,
      parentTree: [],
      actionIsUpdated: false
    })
    this.resetIndicatorSet()
  }

  @autobind
  handleBranchSelection(parent) {
    const parentTree = this.state.parentTree
    let parentTreeIndex = parentTree.findIndex(el => el.parent && el.parent.id === parent.id)
    if (parentTreeIndex === -1) {
      parentTree.push({
        parent,
        subs: []
      })
      parentTreeIndex = parentTree.length - 1
    }

    const origNodes = this.state.checkedKeys
    const ids = [parent.id]
    parent.traverse((page) => {
      if (origNodes.indexOf(page.id.toString()) === -1) {
        origNodes.push(page.id.toString())
        ids.push(page.id)

        // Add page as sub to parent in parentTree
        if (parentTree[parentTreeIndex].parent.id !== page.id) {
          parentTree[parentTreeIndex].subs.push(page)
        }
      }
    })

    // find indicator to update
    parent.id in this.dropdownIndicatorSet
      ? this.dropdownIndicatorSet[parent.id] = true
      : console.warn('missing page in dropdown indicator list')

    this.setState({
      checkedKeys: origNodes,
      parentTree,
      actionIsUpdated: false
    })

    this.addToExpanded(ids)
  }

  @autobind
  handleBranchDeselection(parent) {
    const origNodes = this.state.checkedKeys
    const parentTree = this.state.parentTree
    const notBranchNodes = []
    const newNodeSet = []
    parent.traverse((page) => {
      notBranchNodes.push(page.id.toString())
    })
    origNodes.map((id) => {
      if (notBranchNodes.indexOf(id) === -1) {
        newNodeSet.push(id)
      }
      return true
    })

    const parentTreeIndex = parentTree.findIndex(el => el.parent.id === parent.id)
    if (parentTreeIndex > -1) {
      parentTree.splice(parentTreeIndex, 1)
    }

    // necessary if a super-parent node clears a child branch select
    this.checkChildrenAreAllSelected(newNodeSet)

    this.setState({
      checkedKeys: newNodeSet,
      parentTree,
      actionIsUpdated: false
    })
    this.addToExpanded(parent.id)
  }

  @autobind
  setAllowedActions(page) {
    if (page.publishedUrl) {
      this.allowUnpublish = true
      this.allowArchive = false
      this.allowDelete = false
    }

    if (!page.publicationNotAllowed) {
      this.allowPublish = true
    }
  }

  @autobind
  checkAllowedActions() {
    const checkedPages = this.state.checkedKeys.map(el => this.props.pageStore.getPartialById(el))
    this.allowDelete = !!this.state.checkedKeys.length
    this.allowArchive = !!this.state.checkedKeys.length
    this.allowPublish = false
    this.allowUnpublish = false

    checkedPages.forEach((partialPage) => {
      partialPage.traverse((page) => {
        this.setAllowedActions(page)
      })
    })

    if (!this.state.actionIsUpdated) {
      this.setState({
        allowPublish: this.allowPublish,
        allowUnpublish: this.allowUnpublish,
        allowDelete: this.allowDelete,
        allowArchive: this.allowArchive,
        actionIsUpdated: true
      })
    }
  }

  render() {
    return (
      <PublicationManagerDisplay
        project={this.props.projectStore.current}
        pageStore={this.props.pageStore}
        specialPageTreeChildren={this.specialPageTree.children || {}}
        pageTreeChildren={this.pageTree.children || {}}

        checkedKeys={this.state.checkedKeys}
        checkedKeysLength={this.state.checkedKeys.length}
        expandedKeys={this.state.expandedKeys}

        checkAllowedActions={this.checkAllowedActions}
        actionIsUpdated={this.state.actionIsUpdated}
        allowDelete={this.state.allowDelete}
        allowArchive={this.state.allowArchive}
        allowPublish={this.state.allowPublish}
        allowUnpublish={this.state.allowUnpublish}

        onCheck={this.handleOnCheck} // naming variation
        dropdownIndicatorSet={this.dropdownIndicatorSet}

        handleSelectNoneButton={this.handleSelectNoneButton}
        handleSelectAllButton={this.handleSelectAllButton}
        handlePublishPage={this.handlePublishPage}
        handleOfflinePage={this.handleOfflinePage}
        handleArchivePage={this.handleArchivePage}
        handleDeletePage={this.handleDeletePage}
        handleExpand={this.handleExpand}
        handleBranchSelection={this.handleBranchSelection}
        handleBranchDeselection={this.handleBranchDeselection}

      />
    )
  }

}

export default PublicationManager
