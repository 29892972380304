import React from 'react'
import { observer } from 'mobx-react'

import Dropdown, {
  DropdownOpener,
  Entry,
} from '../../../../shared/components/Dropdown'
import Icon from '../../../../shared/icons'

import ObservableEntry from './ObservableEntry'
import getButtonProps from './getButtonProps'

const ValueEntry = observer(({
  buttonSpec,
}) => {

  const buttonProps = getButtonProps({
    ...buttonSpec,
    'data-command-name': null,
    disabled: false,
    active: true
  })

  return <Entry key={buttonProps.key}>

      <DropdownOpener>
        {buttonSpec.icon
          ? <Icon name={buttonSpec.icon} />
        : null
        }
        {buttonProps.title}
      </DropdownOpener>

      <Dropdown>
        {buttonSpec.values.map((val) => {

          const valueButtonSpec = {
            ...buttonSpec,
            active: buttonSpec.active && buttonSpec.active[val.value],
            name: `${buttonSpec.cmd}.${val.name}`,
            translatedName: val.translatedName,
            value: val.value
          }

          return <ObservableEntry
            buttonSpec={valueButtonSpec}
            key={val.name}
          />

        })}
      </Dropdown>

    </Entry>
})

export { ValueEntry as default }