import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observable, action, computed } from 'mobx'
import { observer } from 'mobx-react'
import { autobind } from 'core-decorators'

import CopyManagerRemotePanel from '../components/CopyManagerRemotePanel'
import connectCopyManagerLocalPanelToCommands
  from '../connectors/connectCopyManagerLocalPanelToCommands'

const ConnectedCopyManagerLocalPanel = connectCopyManagerLocalPanelToCommands()

@observer
class CopyManager extends Component {

  static propTypes = {
    projectStore: PropTypes.object,
    pageStore: PropTypes.object,
  }

  @computed get currentProject() {
    return this.props.projectStore.getById(this.props.projectStore.current.id)
  }

  @observable externalDragNode = null

  @observable externalDragNodeKeys = null

  @observable originTree = null

  @autobind
  @action
  updateExternalDragNode(node, keys, tree) {
    this.externalDragNode = node
    this.externalDragNodeKeys = keys
    this.originTree = tree
  }

  render() {
    const dragContainer = {}
    return (<div className="grid-block">
      <ConnectedCopyManagerLocalPanel
        project={this.currentProject}
        pageStore={this.props.pageStore}
        projectStore={this.props.projectStore}
        dragContainer={dragContainer}
        updateExternalDragNode={this.updateExternalDragNode}
        externalDragNode={this.externalDragNode}
        externalDragNodeKeys={this.externalDragNodeKeys}
        originTree={this.originTree}
      />
      <CopyManagerRemotePanel
        project={this.currentProject}
        pageStore={this.props.pageStore}
        projectStore={this.props.projectStore}
        dragContainer={dragContainer}
        updateExternalDragNode={this.updateExternalDragNode}
        externalDragNode={this.externalDragNode}
        externalDragNodeKeys={this.externalDragNodeKeys}
      />
    </div>)
  }
}

export default CopyManager
