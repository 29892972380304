import {
  isNull,
  isBoolean,
  isString,
  isNumber,
  isObject,
  isArray,
  isDate,
  isUndefined
} from 'lodash'

const Test = {

  required(value) {
    return !isUndefined(value)
  },

  null(value) {
    return isNull(value)
  },

  bool(value) {
    return isBoolean(value)
  },

  string(value) {
    return isString(value)
  },

  number(value) {
    return isNumber(value)
  },

  array(value) {
    return isArray(value)
  },

  object(value) {
    return isObject(value)
  },

  date(value) {
    // eslint-disable-next-line no-restricted-globals
    return isDate(value) || !isNaN(Date.parse(value))
  },

  minLen(value, minLen = 0) {
    return Test.string(value)
    && (minLen * 1
      ? value.trim().length >= minLen * 1
      : true)
  },

  maxLen(value, maxLen = 0) {
    return Test.string(value)
    && (maxLen * 1
      ? value.trim().length <= maxLen * 1
      : true)
  },

  min(value, min = 0) {
    return Test.number(value)
    && (min * 1
      ? value >= min * 1
      : true)
  },

  max(value, max = 0) {
    return Test.number(value)
    && (max * 1
      ? value <= max * 1
      : true)
  }
}


export default class Validation {

  static parseType(type) {
    const enforce = Validation.makeEnforcer(type)
    const types = Validation.makeValidator(type).split('|')
    return { enforce, types }
  }

  static ensure(type, value) {

    const { enforce, types } = Validation.parseType(type)

    if (!Validation.isValid(type, value)) {
      if (enforce !== undefined) {
        value = Validation.enforceType(types[types.length - 1])
      }
    }

    return value

  }

  static isEnforce(type) {
    return type.indexOf('default') !== -1
  }

  static makeEnforcer(type) {
    let value = undefined
    if (Validation.isEnforce(type)) {
      value = type.match(/default:([^|$]+)/)[1]
      if (value === 'null') {
        value = null
      }
    }
    return value
  }

  static isValid(type, value) {
    const { types } = Validation.parseType(type)
    return types.every(currType => Validation.testType(currType, value))
  }

  static makeValidator(type) {
    return type.replace(/default[^|$]*/, '')
      .replace(/^\|+/, '')
      .replace(/\|+$/, '')
  }

  static enforceType(type) {
    switch (type) {
      case 'date':
      case 'null':
      default:
        return null
    }
  }

  static testType(rawType, value) {
    const [complexType, ...opts] = rawType.split(':')
    let types = [complexType]

    if (complexType.indexOf('Or') !== -1) {
      types = complexType.split('Or')
        .map(t => `${t[0].toLowerCase()}${t.substr(1)}`)
    }

    return types.some((type) => {
      if (type in Test) {
        return Test[type](value, ...opts)
      }
      // in case there is no test for one type, just log the
      // missing test and accept it as valid
      console.warn(`Validation: Missing test for type ${type}. Assuming valid.`)
      return true
    })

  }


}

export class ValidationInvalidError extends Error {}
