import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import { autobind } from 'core-decorators'
import { confirmable } from 'react-confirm'
import classNames from 'classnames'
import PerfectScrollbar from 'perfect-scrollbar'
import { formatMessage } from '../../../translations'
import { Dialog } from './index.js'

const css = require('./styles.scss')

@confirmable
class ProjectDialog extends Component {
  static propTypes = {
    show: PropTypes.bool, // indicates if the dialog is shown or not.
    proceed: PropTypes.func, // call to close the dialog with promise resolved.
    cancel: PropTypes.func, // call to close the dialog with promise rejected.
    dismiss: PropTypes.func, // call to only close the dialog.
    confirmation: PropTypes.string, // arguments of your confirm function
    options: PropTypes.shape({
      authStore: PropTypes.object,
      currentProject: PropTypes.object,
      projectStore: PropTypes.object,
    }),
  };

  constructor(props) {
    super(props)

    this.state = {
      selectedChannelId:
        props.selectedChannel || props.options.currentProject.channelId,
    }

    this.psChannel = null
    this.psProjectGroup = null

    this.channelList = props.options.projectStore.getChannelList()
  }

  @autobind
  setScrollBars() {
    this.psChannel = new PerfectScrollbar('#channelList-container', {
      suppressScrollX: true
    })
    this.psProjectGroup = new PerfectScrollbar('#projectGroup-container', {
      suppressScrollX: true
    })
  }

  @autobind
  getFocus() {
    return this.focus
  }

  @autobind
  handleRef(el) {
    if (el !== null) {
      this.focus = el
    }
  }

  @autobind
  handleChannelChange({ target }) {
    this.setState({
      selectedChannelId: target.id * 1,
    })
  }

  @autobind
  handleProjectChange({ target }) {
    const group = this.props.options.projectStore.getGroupById(target.id * 1)
    // since the group should have valid project(s), get the first in channel
    const first = group.getFirstProject(this.state.selectedChannelId)

    this.props.proceed({
      newProjectId: first.id, // ensure number
    })
  }

  sortAscending(itemList) {
    return Object.keys(itemList).sort((a, b) => itemList[a].localeCompare(itemList[b])
    )
  }

  renderChannelList() {
    return this.channelList.map((channel) => {
      return [
        <div
          key={channel.id}
          id={channel.id}
          onClick={this.handleChannelChange}
          className={classNames(
            this.state.selectedChannelId === channel.id ? 'active' : null,
            'project-dialog-channel-item'
          )}
        >
          <span id={channel.id}>{channel.name}</span>
        </div>,
        <hr key={`${channel.id}-hr`} />,
      ]
    })
  }

  renderProjectGroupList() {
    const { projectStore } = this.props.options
    // create a list of groups that contain at least one project in the channel
    // and it's possible for a group to belong to multiple channels
    const channelProjects = projectStore.groupCollectionSorted.reduce(
      (memo, pg) => {
        if (pg.verifyContainsChannel(this.state.selectedChannelId)) {
          memo.push(pg)
        }
        return memo
      },
      []
    )

    return channelProjects.map((projectGroup) => {
      return (
        <div
          key={projectGroup.id}
          id={projectGroup.id}
          onClick={this.handleProjectChange}
          className="project-dialog-project-item"
        >
          <span id={projectGroup.id}>{projectGroup.name}</span>
        </div>
      )
    })
  }

  render() {
    if (this.psChannel) {
      this.psChannel.update()
    }
    if (this.psProjectGroup) {
      this.psProjectGroup.update()
    }

    return (
      <Dialog
        className={css.projectDialog}
        isOpen={this.props.show}
        onAfterOpen={this.setScrollBars}
        withoutFooter
        title={formatMessage({ id: 'project-dialog.title' })}
        focusEl={this.getFocus}
      >
        <div className="grid-block main-content">
          <div className="project-dialog-channel-list" id="channelList-container">
            <hr />
            {this.renderChannelList()}
          </div>
          <div className="project-dialog-project-list" id="projectGroup-container">
            {this.renderProjectGroupList()}
          </div>
        </div>
      </Dialog>
    )
  }
}

export default ProjectDialog
