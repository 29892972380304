import * as PropTypes from 'prop-types'
import React from 'react'
import DropIndicator from './DropIndicator'
import URLStrategy from './URLStrategy'
import FileStrategy from './FileStrategy'

export function connectDropIndicatorToStrategy(Indicator, Strategy) {
  const strategy = new Strategy()
  function ConnectedDropIndicator(props) {
    strategy.updateProps(props.strategyProps)
    return (
      <Indicator {...props} strategy={strategy}>
        {props.children}
      </Indicator>
    )
  }

  ConnectedDropIndicator.propTypes = {
    children: PropTypes.node,
    strategyProps: PropTypes.object,
  }

  return ConnectedDropIndicator
}

export const URLDropIndicator = connectDropIndicatorToStrategy(
  DropIndicator,
  URLStrategy
)

export const FileDropIndicator = connectDropIndicatorToStrategy(
  DropIndicator,
  FileStrategy
)

export default DropIndicator
