import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { autobind } from 'core-decorators'
import { formatMessage, FormattedMessage } from '../../translations'
import Dropdown, { DropdownContainer, Entry } from '../../shared/components/Dropdown'
import { selectArticleDialog, newArticleDialog } from '../../page'
import GenevaDropdownOpener from '../../ui/components/GenevaDropdownOpener'

@observer
export default class ArticleSelectButton extends Component {
  static propTypes = {
    allowedArticleTemplates: PropTypes.array,
    children: PropTypes.node,
    disabled: PropTypes.bool,
    onItemSelected: PropTypes.func.isRequired,
    pageId: PropTypes.number.isRequired,
    viewModels: PropTypes.shape({
      currentlyOpenWidget: PropTypes.shape({
        item: PropTypes.object,
      }),
    }),
  };

  @autobind
  handleSelectArticle() {
    const {
      pageId,
      allowedArticleTemplates,
      viewModels: { currentlyOpenWidget },
    } = this.props

    const widget = currentlyOpenWidget.data.item

    selectArticleDialog({
      pageId,
      allowedArticleTemplates: allowedArticleTemplates || null,
      // as of now, widgets are not allowed within widgets
      allowedWidgetTemplates: [],
      widgetItems: widget.items,
    })
      .then((data) => {
        if (
          data.action === 'insertArticle'
          && data.type
          && data[data.type]
          && 'onItemSelected' in this.props
        ) {
          this.props.onItemSelected({
            // todo determine type in modal
            openForEditing: false,
            type: data.type,
            [data.type]: data[data.type],
            template: data.template,
            preventNewArticle: true
          })
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }

  @autobind
  handleCreateNewArticle() {
    const { allowedArticleTemplates } = this.props

    newArticleDialog({
      allowedArticleTemplates: allowedArticleTemplates || null,
      // as of now, widgets are not allowed within widgets
      allowedWidgetTemplates: [],
    })
      .then((data) => {
        if (
          data.action.indexOf('createArticle') >= 0
          && 'onItemSelected' in this.props
        ) {
          this.props.onItemSelected({
            type: data.template.templateType,
            template: data.template,
            openForEditing: data.action === 'createArticleAndEdit',
          })
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }

  render() {
    const {
      viewModels: { currentlyOpenWidget },
    } = this.props
    const props = {
      disabled: this.props.disabled,
    }
    const widget = currentlyOpenWidget.data.item

    if (!this.props.pageId) {
      props.title = formatMessage({
        id: 'article-select-button.loading-page',
      })
      props.disabled = true
    }

    return (
      <DropdownContainer>
        <GenevaDropdownOpener
          clickToClose
          disabled={!widget || widget.isUpdating || props.disabled}
          title={formatMessage({
            id: 'article-select-button.add-a-new-article',
          })}
          className={`geneva-small-button widget-header ${
            props.disabled ? 'disabled' : ''
          }`}
        >
          {this.props.children || (
            <FormattedMessage id="article-select-button.add-article" />
          )}
        </GenevaDropdownOpener>
        <Dropdown>
          <Entry {...props} onClick={this.handleCreateNewArticle}>
            <FormattedMessage id="article-select-button.create-new-article" />
          </Entry>
          <Entry {...props} onClick={this.handleSelectArticle}>
            <FormattedMessage id="article-select-button.select-existing-article" />
          </Entry>
        </Dropdown>
      </DropdownContainer>
    )
  }
}
