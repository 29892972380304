import React from 'react'
const jsDiff = require('diff')

/**
 * Determine the difference between two strings on a single character level
 * The first serves as base and the result depends on what was removed or added by the second
 * @param {String} string1 - The base string
 * @param {String} string2 - The second string
 * @param {Boolean} ignoreCase - Optional flag to ignore casing difference. Defaults to false.
 * @returns {Array} - An array of jsx elements ready to insert into the DOM
 */
export function charDiffJSX(string1, string2, ignoreCase: false) {

  const diffs = jsDiff.diffChars(string1, string2, { ignoreCase })

  return diffs.map((el) => {
    let value = el.value

    if (el.added) {
      value = <ins>{value}</ins>
    }
    else if (el.removed) {
      value = <del>{value}</del>
    }

    return value
  })
}

/**
 * Determine the difference between two strings on a single character level
 * The first serves as base and the result depends on what was removed or added by the second
 * @param {String} string1 - The base string
 * @param {String} string2 - The second string
 * @param {Boolean} ignoreCase - Optional flag to ignore casing difference. Defaults to false.
 * @returns {String} - A concatenated string of all diffs enriched with html tags
 */
export function charDiff(string1, string2, ignoreCase: false) {

  const diffs = jsDiff.diffChars(string1, string2, { ignoreCase })
  let retVal = ''
  let value
  diffs.forEach((el) => {

    if (el.added) {
      value = `<ins>${el.value}</ins>`
    }
    else if (el.removed) {
      value = `<del>${el.value}</del>`
    }
    else {
      value = el.value
    }
    retVal = `${retVal}${value}`
  })

  return retVal
}

/**
 * Determine the difference between two strings on a word basis, ignoring whitespace
 * The first serves as base and the result depends on what was removed or added by the second
 * @param {String} string1 - The base string
 * @param {String} string2 - The second string
 * @param {Boolean} ignoreCase - Optional flag to ignore casing difference. Defaults to false.
 * @returns {Array} - An array of jsx elements ready to insert into the DOM
 */
export function wordDiffJSX(string1, string2, ignoreCase: false) {

  const diffs = jsDiff.diffWords(string1, string2, { ignoreCase })

  return diffs.map((el) => {
    let value = el.value

    if (el.added) {
      value = <ins>{value}</ins>
    }
    else if (el.removed) {
      value = <del>{value}</del>
    }

    return value
  })
}

/**
 * Determine the difference between two strings on a word basis, ignoring whitespace
 * The first serves as base and the result depends on what was removed or added by the second
 * @param {String} string1 - The base string
 * @param {String} string2 - The second string
 * @param {Boolean} ignoreCase - Optional flag to ignore casing difference. Defaults to false.
 * @returns {String} - A concatenated string of all diffs enriched with html tags
 */
export function wordDiff(string1, string2, ignoreCase: false) {

  const diffs = jsDiff.diffWords(string1, string2, { ignoreCase })
  let retVal = ''
  let value
  diffs.forEach((el) => {

    if (el.added) {
      value = `<ins>${el.value}</ins>`
    }
    else if (el.removed) {
      value = `<del>${el.value}</del>`
    }
    else {
      value = el.value
    }
    retVal = `${retVal}${value}`
  })

  return retVal
}

/**
 * Counts the number of differences between two strings on a word basis, ignoring whitespace
 * The first serves as base and the result depends on what was removed or added by the second
 * @param {String} string1 - The base string
 * @param {String} string2 - The second string
 * @param {Boolean} ignoreCase - Optional flag to ignore casing difference. Defaults to false.
 * @returns {Number} - The number of words changed from string1 to string2
 */
export function diffCount(string1, string2, ignoreCase: false) {

  let counter = 0
  const diffs = jsDiff.diffWords(string1, string2, { ignoreCase })

  diffs.forEach((diff) => {
    if (diff.added || diff.removed) {
      counter += 1
    }
  })

  return counter
}
