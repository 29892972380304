import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { autobind } from 'core-decorators'
import classNames from 'classnames'
import { formatMessage, FormattedMessage } from '../../translations'

import {
  FlexSection,
  FixedSection
} from '../../shared/components/Navbar'
import GenevaButton from '../../ui/components/GenevaButton'
import * as css from '../styles.scss'

@observer
export default class MediaManagerFooter extends Component {

  static propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    handleInputChange: PropTypes.func.isRequired,
    navbarStyle: PropTypes.string,
    submitDisabled: PropTypes.bool,
    imageCount: PropTypes.number,
  }

  static contextTypes = {
    router: PropTypes.object.isRequired
  }

  @autobind
  handleCancel() {
    if (this.props.onCancel) {
      this.props.onCancel()
    }
  }

  @autobind
  handleSubmit() {
    if (this.props.onSubmit) {
      this.props.onSubmit()
    }
  }

  render() {

    const {
      imageCount,
    } = this.props

    return (
      <div className={classNames(css.m2mFooter)}>

        <FixedSection className="navbar-text-container">

          <FlexSection className="footer-text">
            {formatMessage({ id: 'mm.navbar-text' }, { count: imageCount })}
          </FlexSection>

          <GenevaButton
            onClick={this.handleCancel}
            className="small button"
          >
            <FormattedMessage id="mm.cancel" />
          </GenevaButton>
          <GenevaButton
            onClick={this.handleSubmit}
            className="small button primary"
            disabled={this.props.submitDisabled}
          >
            <FormattedMessage id="mm.submit" />
          </GenevaButton>
        </FixedSection>

      </div>
    )
  }

}
