import { createConfirmation } from 'react-confirm'
import WidgetSecurityDialog from '../../components/dialogs/WidgetSecurity'

const widgetSecurity = createConfirmation(WidgetSecurityDialog)


export default function (confirmation, options = {}) {
  if (typeof confirmation !== 'string') {
    options = confirmation
    confirmation = ''
  }
  return widgetSecurity({ confirmation, options })
}
