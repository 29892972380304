import React, { Component } from 'react'
import { deepGet, deepSet } from '../../obj'

/**
 * http://git.atrivio.net:3000/geneva/backend/blob/20-feature-list-articles/docs/FILTERING_AND_SORTING.md
 * Filter decoration:
 * filter=[
 * {"templateId":"24"},
 * ]
 * options for filter: createdBy, channel ("web"), templateId (["24","25"]),
 * createdAt: {"condition": ">","value": "2017-02-01"}, name ("of article")
 *
 * Sorting decoration:
 * sorting=[
 * {"createdAt":"desc"},
 * {"i18n.name":"asc"},
 * ]
 *
 * 1) Order matters. first item is the primary sort, then secondary, etc...
 * 2) Only pass what is needed
 * 3) New items can be added to the backend on request
 */
export function filterable(target) {
  const provider = target.prototype || target

  //  const origUpdateFilter = provider.updateFilter
  const origLoad = provider.load
  const modifiedLoad = provider.modifiedLoad

  provider.updateFilter = function updateFilter(modifiers) {
    /* idea: this will clear previous filter and sorting data and use the values passed in
     * This requires each as an object.
     * The reset function will call a load after clearing the filter collection */
    return this.reset({
      filter: deepGet(modifiers, 'filter'),
      sorting: deepGet(modifiers, 'sorting'),
    })

    /* no component using an updatefilter for that case for now
    if (origUpdateFilter) {
      origUpdateFilter.call(this, [deepGet(modifiers, 'filter')])
    }
    */
  }

  /**
   * Addition to normal load.  Values should be set before this point
   *  (ex: this.filter or this.sorting)
   */
  provider.load = function load(id = null, opts = {}, data = {}) {
    if (Object.prototype.toString.call(id) === '[object Object]') {
      data = opts
      opts = id
      id = null
    }

    /* Filter section */
    const useFilter
      = this.filter && (id === undefined || id === null) && !opts.noFilter
    const filterDefined = !!deepGet(opts, 'params.filter')

    if (useFilter && !filterDefined) {
      // TODO: stringification should probably happen much deeper in the sync module
      // expects store.filter to be an array like: [{},{},{{}}] form
      deepSet(opts, 'params.filter', JSON.stringify(this.filter))
    }

    /* Sorting section */
    const useSorting
      = this.sorting && (id === undefined || id === null) && !opts.noSorting
    const sortingDefined = !!deepGet(opts, 'params.sorting')

    // uses the complex store.sorting obj and breaks it into the simplier ~opts.sorting
    if (useSorting && !sortingDefined) {
      // TODO: stringification should probably happen much deeper in the sync module
      deepSet(opts, 'params.sorting', JSON.stringify(this.sorting))
    }

    return id ? origLoad.call(this, id, opts, data) : modifiedLoad.call(this, id, opts, data)
  }
}

export function connectToFilterable(BaseComponent, filterableStore) {
  if (!BaseComponent) {
    throw new Error(
      'Argument#1: connectToFilterable expects an BaseComponent as first argument'
    )
  }

  if (!Object.prototype.isPrototypeOf.call(Component, BaseComponent)) {
    const name
      = BaseComponent.name
      || BaseComponent.constructor.name
      || BaseComponent.prototype.constructor.name
      || 'undefined'

    // eslint-disable-next-line max-len
    throw new Error(
      `Argument#1: Should be an instance of React.Component. ${name} (${Object.prototype.toString.apply(
        BaseComponent
      )}) given.`
    )
  }

  if (!filterableStore) {
    throw new Error(
      'Argument#2: connectToFilterable expects an filterableStore as second argument'
    )
  }

  if (!filterableStore.updateFilter) {
    // eslint-disable-next-line max-len
    throw new Error(
      "Argument#2: The given filterableStore doesn't define the updateFilter method and thus is not filterable. Decorate the store with the @filterable decorator."
    )
  }

  class FilterConnection extends Component {
    constructor(props) {
      super(props)
      this.state = {
        filter: {},
        sorting: {},
      }
      this.handleFilterSortChanged = this.handleFilterSortChanged.bind(this)
    }

    handleFilterSortChanged(modifiers) {
      this.setState({ filter: modifiers.filter })
      this.setState({ sorting: modifiers.sorting })
      return filterableStore.updateFilter(modifiers)
    }

    render() {
      return (
        <BaseComponent
          onChange={this.handleFilterSortChanged}
          {...this.props}
          {...this.state}
        />
      )
    }
  }

  return FilterConnection
}
