import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { autobind } from 'core-decorators'
import classNames from 'classnames'

import CenshareContent from './components/CenshareContent'
import CenshareNavbar from './components/CenshareNavbar'

import * as css from './styles.scss'

export CMToolbar from './components/CMToolbar'

export default class Censhare extends Component {

  static propTypes = {
    dialog: PropTypes.object
  }

  render() {
    return [
      <div key="censhare-outerwrapper" className={css.outerWrapper}>
        <div className={css.innerWrapper}>
          <CenshareNavbar
            navbarStyle={css.censhareNavbar}
            onClose={this.props.dialog.onCancel}
          />
        </div>
      </div>,
      <div key="censhare-outercontentwrapper" className={classNames(css.outerContentWrapper, 'grid-frame vertical')}>
        <div className={css.innerContentWrapper}>
          <CenshareContent
            page={this.props.dialog.page}
            project={this.props.dialog.project}
            onClose={this.props.dialog.onCancel}
          />
        </div>
      </div>
    ]
  }

}
