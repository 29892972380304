import Command from '../../shared/command'
import { Store } from '../../shared/store'

export default class UploadFileCommand extends Command {

  constructor(fileStore: Store, data: Object, opts: Object) {
    super(fileStore, data)
    this.fileStore = fileStore
    this.data = this.parse(data)
    this.opts = opts
  }

  validate(fileStore: Store, data: Object) {
    if (!fileStore) {
      this.throwError('expects `fileStore` argument to be present')
    }

    if (!data) {
      this.throwError('expects `data` argument to be present')
    }

    if (!(data.file || data.url)) {
      this.throwError('one of `data.file` or `data.url` is required')
    }
  }

  parse(data) {

    if (data.data) {
      data = data.data
    }

    const sourceType = data.file ? 'file' : 'url'

    return {
      id: data.id,
      filePreview: data.value,
      [sourceType]: data[sourceType]
    }
  }

  exec() {
    return this.fileStore.create(this.data, this.opts)
  }

}
