import { action, autorun, extendObservable, observable } from 'mobx'
import { debounce } from 'core-decorators'

import { isNumberLike, ensureNumber } from '../utils'

import LoadAction from '../LoadAction'
import SaveAction from '../SaveAction'
import DestroyAction from '../DestroyAction'

/**
 * A simple Model implementation.
 * @param {Store} store - The store the model belongs to
 * @param {Object} rawData -  The raw (json) input data.
 * @param {Object} opts - Some options for the model.
 * @property {Boolean} opts.autoSavable - Whether or not this
 * model should automatically save itself upon changes.
 */
export default class Model {
  /**
   * Determines if a save action is going on currnetly
   * @type {Boolean}
   */
  @observable isUpdating = false;

  /**
   * Sets the model to be in the updating state (e.g. it's syncing with the
   * sever)
   */
  @action
  setBeginUpdate() {
    this.isUpdating = true
  }

  /**
   * Sets the model to have finished updating (e.g. it's now in syncing with
   * the sever again)
   */
  @action
  setFinishUpdate() {
    this.isUpdating = false
  }

  constructor(store, rawData = {}, opts = {}) {
    if (opts.ignore) {
      return
    }

    let data = this.clone(rawData)

    this.modelOpts = Object.assign(
      {
        idProp: this.constructor.idProp || 'id',
      },
      opts
    )

    /**
     * The id of the model.
     * @type {Number|String}
     */
    if (!this.id && this.modelOpts.idProp in data) {
      this.id = data[this.modelOpts.idProp]
    }
    delete data[this.modelOpts.idProp]

    /**
     * Whether or not this model is currently active.
     * @type {Boolean}
     */
    this.isActive = false

    /**
     * The store this model belongs to.
     * @type {Store}
     */
    this.store = null

    const autosaveDefaults = {
      autoSavable: this.constructor.autoSavable || false,
      autoSave: false,
    }

    Object.assign(autosaveDefaults, opts)

    /**
     * @private
     * @type {Boolean}
     */
    this.autoSavable = autosaveDefaults.autoSavable

    /**
     * @private
     * @type {Boolean}
     */
    this.autoSave = autosaveDefaults.autoSave

    /**
     * @private
     * @type {Function}
     */
    this.destoySaveHandler = null

    this.setStore(store)

    data = Object.assign({}, this.constructor.defaultProps || {}, data)

    this.set(data)

    this.handleModelCreated()
  }

  /**
   * Before data is applied to this model, a clone will
   * be created, so the original data always stays the same.
   * The easiest method - and also the default here - is to
   * JSON stingify and parse the data.
   *
   * In some cases this may cause trouble (e.g. with special)
   * objects like dates or files. In such cases, override this
   * method.
   * @param {Object} data - The data to be cloned.
   * @returns {Object} - The cloned data.
   */
  clone(data = {}) {
    return JSON.parse(JSON.stringify(data))
  }

  parse(data = {}) {
    if (data.data) {
      data = data.data
    }
    return data
  }

  /**
   * A hook that get's called once a new model was creted.
   * @hook
   */
  handleModelCreated() {}

  /**
   * Updates the data fields with those from the argument data.
   * @param {Object} data - The new data.
   */
  // eslint-disable-next-line no-unused-vars
  set(data, opts = {}) {
    const shouldAutosave = this.autoSave
    this.autoSave = false

    if (this.modelOpts.idProp in data) {
      const id = data[this.modelOpts.idProp]
      data[this.modelOpts.idProp] = isNumberLike(id) ? ensureNumber(id) : id
    }

    data = this.parse(this.clone(data))

    if (!this.id && this.modelOpts.idProp in data) {
      this.id = data[this.modelOpts.idProp]
    }

    delete data[this.modelOpts.idProp]

    if ('id' in data) {
      // eslint-disable-next-line max-len
      console.warn(
        `The data handed to the model contains an \`id\` property while using the property ${this.modelOpts.idProp} as id property. This will cause the \`id\` property would overwrite the \`idProp\`. The \`id\` will be removed from the data.`
      )
      delete data.id
    }

    if ('store' in data) {
      // eslint-disable-next-line max-len
      console.warn(
        `The data handed to the \`${this.constructor.name}\` model contains a \`store\` property. \`store\` is a protected prooperty and cannot be set. It will be removed.`
      )
      delete data.store
    }

    extendObservable(this, data)

    this.autoSave = shouldAutosave
  }

  /**
   * Sets the store reference.
   * @param {Store} store - The store reference
   */
  setStore(store) {
    this.store = store
    this.initialStoreSet = true

    this.destoySaveHandler = autorun(
      `AUTORUN:${this.constructor.name}.autoSave`,
      () => {
        // observe everything that is used in the JSON:
        // eslint-disable-next-line no-unused-vars
        const json = this.asJSON

        if (this.initialStoreSet) {
          this.initialStoreSet = false
          return
        }

        // todo: this is here from the beginning and why save when setting the store??
        // if autoSave is on, send json to server
        if (this.autoSave && this.store) {
          this.saveDebounced()
        }
      }
    )
  }

  /**
   * Load the model from the server utilizing the store.
   * This method does not return anything. But rather the store will populate
   * this model automatically once the server returned the reply.
   */
  load(opts = {}) {
    return new LoadAction(this).execute(opts)
  }

  /**
   * Save the model to the store. This model does not return anything but much
   * rather the store saves the model to the server and once that succeeded,
   * updates this model with the data returned from the server.
   *
   * This method is debounced and will only be executed 1s after it was last
   * called.
   */
  save(opts = {}) {
    // If there is specific data, a patch to that model is needed
    // and the save needs to be forced.
    if (opts.data) {
      opts.force = true
      opts.method = 'patch'
    }
    return new SaveAction(this).execute(opts)
  }

  /**
   * Destroys (deletes) this model from the server.
   */
  destroy(opts) {
    return new DestroyAction(this).execute(opts)
  }

  @debounce(1000)
  saveDebounced() {
    return this.save()
  }
}
