import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { autobind } from 'core-decorators'

import { Entry } from '../../shared/components/Dropdown'

export class ArticleLanguageEntry extends Component {

  static propTypes = {
    article: PropTypes.object.isRequired,
    checked: PropTypes.bool.isRequired,
    languageKey: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
  }

  @autobind
  handleSelectLanguage() {
    this.props.onClick(this.props.article, this.props.languageKey)
  }

  render() {
    return (
      <Entry
        onClick={this.handleSelectLanguage}
        key={this.props.languageKey}
        checked={this.props.checked}
      >
        {this.props.languageKey}
      </Entry>
    )
  }

}
