import React from 'react'
import TemplateErrorBox from '../components/TemplateErrorBox'

export default function wrapInErrorHandler(Template, parentProps) {
  const origRender = Template.prototype.render

  Template.prototype.render = function catchableRender(...args) {
    try {
      return origRender.call(this, ...args)
    }
    catch (error) {
      return (
        <TemplateErrorBox
          error={error}
          env={parentProps.env}
          template={parentProps.template}
        />
      )
    }
  }
}
