import { observable, extendObservable, computed, toJS } from 'mobx'
import Model from '../../shared/model'
import { inspector } from '../../shared/decorators/inspector'
import { deepGet } from '../../shared/obj'

function getIdFromVersionNumber(item) {
  return item.instanceVersion !== undefined && item.instanceVersion !== null
    ? item.instanceVersion
    : item.id || item.versionNumber
}

@inspector('addNote', 'version.note')
export default class Version extends Model {
  static asJSONProps = [
    'id',
    'articleId',
    'versionNumber',
    'templateId',
    'createdIso',
    'sourceId',
    'createdAt',
    'updatedAt',
    'createdBy',
    'updatedBy',
    'deletedAt',
  ];

  autosave = false;

  // Put all version properties here.
  @observable id = null;

  @observable articleId = '';

  @observable versionNumber = '';

  @observable notes = [];

  @observable createdIso = '';

  // the template id of this version
  @observable templateId = '';

  @observable sourceId = '';

  @observable createdAt = '';

  @observable updatedAt = '';

  @observable createdBy = '';

  @observable updatedBy = '';

  @observable deletedAt = '';

  @observable isComplete = false;

  @computed get channelShortcut() {
    return this.channel.shortcut
  }

  @computed get asJSON() {
    return {
      id: this.id,
      articleId: this.articleId,
      versionNumber: this.versionNumber,
      templateId: this.templateId,
      createdIso: this.createdIso,
      sourceId: this.sourceId,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      createdBy: this.createdBy,
      updatedBy: this.updatedBy,
      deletedAt: this.deletedAt,
      notes: this.notes,
    }
  }

  getJSON() {
    return {
      id: this.id,
      articleId: this.articleId,
      instanceVersion: this.instanceVersion,
      versionNumber: this.versionNumber,
      templateId: this.templateId,
      placeholder: toJS(this.placeholder),
      channels: toJS(this.channels),
      createdChannel: this.createdChannel,
      createdIso: this.createdIso,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      createdBy: this.createdBy,
      updatedBy: this.updatedBy,
      deletedAt: this.deletedAt,
      notes: toJS(this.notes),
    }
  }

  parse(data) {
    if (data.data) {
      data = data.data
    }

    data.instanceVersion = data.id = getIdFromVersionNumber(data)

    return data
  }

  set(data) {
    data = this.parse(toJS(data))
    if (this.id) {
      delete data.id
    }
    if (!this.editDistance) {
      // TODO make this size reflect version change
      this.editDistance = `${Math.floor(Math.random() * 21) + 25}`
    }

    extendObservable(this, data)
  }

  getDataInPlaceholder(pid) {
    const ret = deepGet(this.placeholder, `${this.channelShortcut}.${pid}`)
    return deepGet(ret, `i18n.${this.createdIso}.value`)
  }

  isVersion() {
    return true
  }

  getArticleData(articleId) {
    return {
      ...this.getJSON(),
      id: articleId || this.articleId,
    }
  }

  addNote(note) {
    return this.store.addNote(note, this)
  }
}
