import { createConfirmation } from 'react-confirm'
import InternalLinkDialog from '../../components/dialogs/InternalLink'

const internalLink = createConfirmation(InternalLinkDialog)


export default function (confirmation, options = {}) {
  if (typeof confirmation !== 'string') {
    options = confirmation
    confirmation = ''
  }
  return internalLink({ confirmation, options })
}
