import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { autobind } from 'core-decorators'
import { FormattedMessage } from '../../translations'
import { deepGet } from '../../shared/obj'
import ContentLoadingBox from '../../shared/components/ContentLoadingBox'
import { pageStore } from '../reducers'
import PageDetailsContainer from './Details'
@inject('customLocal') @observer
export default class PageContainer extends Component {

  static propTypes = {
    customLocal: PropTypes.object.isRequired,
    page: PropTypes.object.isRequired,
    pageTemplate: PropTypes.object.isRequired,
    project: PropTypes.object.isRequired,
    context: PropTypes.object.isRequired,
    ui: PropTypes.object.isRequired
  }

  componentDidMount() {

    const pageId = deepGet(this.props, 'params.pageId')
    this.openPage(pageId)
  }

  componentWillReceiveProps(nextProps) {

    const newPageId = deepGet(nextProps, 'params.pageId')
    const oldPageId = deepGet(this.props, 'params.pageId')

    if (newPageId === oldPageId) {
      // Bail out and prevent loading
      return
    }

    if (
      newPageId !== oldPageId
      // In some cases especially when initially loading the PM it can
      // happen, that the pageStore does not have a current yet. In those
      // cases, just load the page
      || !pageStore.hasCurrent
    ) {

      this.openPage(null)
      this.openPage(newPageId)

    }

  }

  openPage(pageId) {

    // Wait for the project being loaded before opening a page
    if (this.props.project.hasCurrent && pageId) {
      pageStore.load(pageId, {
        setCurrent: true,
        params: {
          include: 'internalLinks'
        }
      })
    }
  }

  renderLastLoadFailed() {
    return (<div className="grid-block align-center">
      <div className="grid-block vertical shrink noscroll">
        <FormattedMessage id="page.select-valid-page" />
      </div>
    </div>)
  }

  renderLoading() {
    return (<div className="grid-block">
      <ContentLoadingBox
        spinner
        message={{
          id: 'page.loading',
        }}
      />
    </div>
    )
  }

  @autobind
  renderContainer() {
    return (<div className="grid-block">
      <PageDetailsContainer
        key="pageDetailsContainer"
        {...this.props}
      />
    </div>
    )
  }

  render() {

    const { page } = this.props
    const { lastLoadFailed } = this.props.page

    if (lastLoadFailed) {
      return this.renderLastLoadFailed()
    }
    if (page.hasCurrent && page.current.id) {
      return this.renderContainer()
    }
    return this.renderLoading()

  }


}
