export default class ScribeHelper {

  static create(target) {
    return new ScribeHelper(target)
  }

  constructor(target) {
    this.originalElement = null
    this.clonedElement = null
    this.target = target

    this.createHelperClone()
  }

  createHelperClone() {
    this.originalElement = this.target.scribe.el
    this.clonedElement = document.createElement('div')

    this.clonedElement.style.position = 'absolute'
    this.clonedElement.style.marginLeft = '-999999999px'
    this.clonedElement.style.height = '0px'
    this.clonedElement.style.fontSize = '0px'
    this.clonedElement.style.overflow = 'hidden'

    this.clonedElement.setAttribute('contenteditable', true)
    document.body.appendChild(this.clonedElement)
  }

  use() {
    this.target.scribe._skipFormatters = true;
    this.target.scribe.el = this.clonedElement

    return this
  }

  focus() {
    this.clonedElement.focus()

    return this
  }

  restoreElement() {
    this.target.scribe.el = this.originalElement
    return this
  }

  applyToTarget({ doFormatting } = {}) {
    this.restoreElement()
    this.cloneContentsToTarget()

    return this
  }

  destroy() {
    document.body.removeChild(this.clonedElement)
  }

  setContents(contents) {
    this.clonedElement.innerHTML = contents

    return this
  }

  getContents() {
    return this.clonedElement.innerHTML
  }

  cloneContentsFromTarget() {
    this.setContents(this.originalElement.innerHTML)

    return this
  }

  cloneContentsToTarget({ doFormatting } = {}) {
    let html = this.clonedElement.innerHTML

    if (!doFormatting) {
      html = this.target.scribe._htmlFormatterFactory.format(html)
    }

    this.originalElement.innerHTML = html

    return this
  }
}
