import PropTypes from 'prop-types'
import React from 'react'
import { formatMessage } from '../../translations'

import Dropdown, { DropdownOpener, Entry } from '../../shared/components/Dropdown'


function LanguageDropdown(props) {
  const { disabled, className, contentStores: { project } } = props

  const getLanguageEntry = (langProject) => {
    return {
      name: langProject.lang,
      state: {
        disabled: false,
        checked: props.currentProject.id === langProject.id
      },
      id: langProject.id,
      key: `project-lang-${langProject.id}-${langProject.lang}`
    }
  }

  const renderEntry = (project2) => {
    const entry = getLanguageEntry(project2)
    return props.onRenderEntry(entry)
  }

  const renderDropdown = () => {
    const { currentProject } = props
    const projectGroupLanguages = project.projectPartialsCollection
      .filter(proj => proj.projectGroupId === currentProject.projectGroupId)
      .map(proj => ({
        lang: proj.language,
        id: proj.id
      }))

    return projectGroupLanguages.map(lang => renderEntry(lang))
  }

  if (!project.projectPartialsCollection.length || !project.hasCurrent) {
    return null
  }

  return (<Entry>
    <DropdownOpener
      className={className}
      disabled={disabled || !project.collection.length}
      arrow
    >
      {formatMessage('project.language')}
    </DropdownOpener>
    <Dropdown
      className="geneva-dropdown"
    >
      {renderDropdown()}
    </Dropdown>
  </Entry>)
}

LanguageDropdown.propTypes = {
  contentStores: PropTypes.object.isRequired,
  currentProject: PropTypes.object.isRequired,
  onRenderEntry: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string
}

export default LanguageDropdown
