import { deepSet } from '../../obj'

export default function paginate(target) {
  const provider = target.prototype || target
  let resetFirst = false

  provider.nextPage = function nextPage() {
    resetFirst = true
    this.reset({
      page: this.page + 1,
    })
  }

  const origLoad = provider.load
  const modifiedLoad = provider.modifiedLoad

  provider.load = function load(id = null, opts = {}, data = {}) {
    if (Object.prototype.toString.call(id) === '[object Object]') {
      data = opts
      opts = id
      id = null
    }

    const usePagination = !id
    // Not paginationDefined only doesn't overwrite if both page and limit exist'
    const paginationDefined
      = opts.params && !!opts.params.page && !!opts.params.limit

    if (usePagination && !paginationDefined) {
      const page = this.page || 0
      // parseInt(this.collection.length / this.limit, 10)
      // const delta = (this.collection.length % this.limit) || 10
      // const limit = this.limit + (this.limit - delta)
      // const nextPage = parseInt(this.collection.length / this.limit, 10) + 1
      let nextPage = resetFirst ? page : page + 1
      nextPage = Math.max(nextPage, 1) // must be min of 1
      deepSet(opts, 'params.page', nextPage)
      deepSet(opts, 'params.limit', this.limit)
    }
    if (paginationDefined) {
      opts.params.page += 1
    }

    return id ? origLoad.call(this, id, opts, data) : modifiedLoad.call(this, id, opts, data)
      .then((response) => {
        if (usePagination) {
          // const page = deepGet(opts, 'params.page')
          //
          // if (page) {
          //
          //   this.page = page
          //
          // }
          // this.hasMoreItems = !((this.collection || []).length % this.limit)
        }

        return response
      })
  }
}
