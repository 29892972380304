import FileProcessor from '../../FileProcessor'

export default class FileStrategy {

  updateProps(props) {
    this.props = props
  }

  getData(event) {

    const files = event.target.files || event.dataTransfer.files

    if (!files) {
      throw new Error(`${this.constructor.name}: No files in input!`)
    }

    const opts = this.props.getProcessorOpts
      ? this.props.getProcessorOpts()
      : {
        readFile: this.props.readFile,
        processableFileTypes: this.props.processableFileTypes || null
      }

    const processor = new FileProcessor(opts)

    return processor.processFiles(files)
      .then((result) => {

        console.info('TODO: FileStrategy needs multifile support')
        if (!Array.isArray(result)) {
          result = [result]
        }

        return { files: result }

      })

  }

  readFiles(files) {

    return Promise.all(Array.prototype.map.call(files, file => new Promise((resolve) => {

      const reader = new FileReader()

      reader.addEventListener('load', () => {
        resolve({
          success: true,
          file,
          content: reader.result
        })
      })

      reader.addEventListener('error', () => {
        resolve({
          success: false,
          file,
          content: null
        })
      })

      reader.addEventListener('progress', (event) => {

        if (event.lengthComputable && this.onProgress) {
          this.props.onProgress(event.loaded / event.total)
        }

      })

      reader.readAsDataURL(file)

    })

    ))

  }


  isValidDrag({ type, dataTransfer }) {

    let isValidDrag = false

    if (type === 'dragover') {
      isValidDrag = dataTransfer.types
      && (
        (dataTransfer.types.length && dataTransfer.types[0] === 'Files')
        || (dataTransfer.types.contains && dataTransfer.types.contains('application/x-moz-file'))
      )
    }

    return isValidDrag

  }

}
