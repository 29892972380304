import PropTypes from 'prop-types'
import React from 'react'

import ContentLoadingBox from '../../shared/components/ContentLoadingBox'

const css = /* typeof window === 'undefined' ? {} : */require('../styles.scss')

/**
 * Displays the currently openend project
 * with name and language
 */
function ProjectSelect(props) {

  const { currentProject } = props

  if (!(currentProject && currentProject.name)) {
    return (<ContentLoadingBox
      message={{
        id: 'project.loading'
      }}
    />)
  }

  // ideally this is just groupname after fendt structure update
  const name = `${currentProject.channelName} | ${currentProject.groupName}`

  return (
    <header>
      <h3 className={css.projectSelect}>
        <span>
          {name}
        </span>
        <span>
          {currentProject.language}
        </span>
      </h3>
    </header>
  )
}


ProjectSelect.propTypes = {
  currentProject: PropTypes.object,
}

export default ProjectSelect
