/* eslint no-underscore-dangle: "off" */
import { getStatusNameById, getIdByStatusName } from '../../utils'

export default function statusDecorator(target) {
  const provider = target.prototype

  Object.defineProperty(provider, 'statusName', {
    get: function statusName() {
      if (this.isDeleted) {
        return 'deleted'
      }

      if (this.isLocked) {
        return 'locked'
      }

      return getStatusNameById(this.status)
    },
  })

  Object.defineProperty(provider, 'status', {
    get: function status() {
      if (this.isDeleted) {
        return 50
      }

      if (this.isLocked) {
        return 40
      }

      return this._status
    },

    set(status) {
      this._status = status
    },
  })

  Object.defineProperty(provider, 'isLocked', {
    get: function isLocked() {
      return false
    },
  })

  Object.defineProperty(provider, 'isDeleted', {
    get: function isDeleted() {
      return !!this.deletedAt
    },
  })

  provider.statusByName = function statusByName(name) {
    return getIdByStatusName(name)
  }

  provider.isInStatus = function isInStatus(status) {
    const currentStatus = this.statusName || 'draft'

    if (typeof status !== 'string') {
      if (status.indexOf(',') !== -1) {
        status = status.trim().split(/\s*,\s*/g)
      }
      else {
        status = [status]
      }
    }

    return status.indexOf(currentStatus) >= 0
  }
}
