import * as PropTypes from 'prop-types'
import React from 'react'

const ErrorMessage = ({ error }) => {
  return (
    <div className="geneva-error-message">
      {error.name}:
      <div
        dangerouslySetInnerHTML={{
          __html: error.message.replace(/\n/g, '<br />'),
        }}
      ></div>
    </div>
  )
}

ErrorMessage.propTypes = {
  error: PropTypes.shape({
    name: PropTypes.string,
    message: PropTypes.string,
  }).isRequired,
}

export { ErrorMessage as default }
