import PropTypes from 'prop-types'
import React from 'react'
import { observer } from 'mobx-react'
import PureValidationInfo from '../components/ValidationInfo'

const ValidationInfo = ({ article }) => {

  if (article.isValid) {
    return null
  }

  return (
    <PureValidationInfo />
  )
}

ValidationInfo.propTypes = {
  article: PropTypes.object
}

export default observer(ValidationInfo)
