
import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import classNames from 'classnames'
import * as css from './styles.scss'
import ContentLoadingBox from '../ContentLoadingBox'

// eslint-disable-next-line react/prefer-stateless-function
class ContentLoadingBoxFullscreen extends Component {

  render() {
    return (<div className={css.contentLoadingBoxFullscreen}>
      <ContentLoadingBox
        {...this.props}
      />
    </div>)
  }
}

ContentLoadingBoxFullscreen.defaultProps = {
  spinner: true,
  message: {
    id: 'content.loading',
  },
}

ContentLoadingBoxFullscreen.propTypes = {
  /**
   * The message.
   */
  message: PropTypes.shape({
    id: PropTypes.string,
    values: PropTypes.object,
  }),
  /**
   * Whether or not to show a loading spinner.
   */
  spinner: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  id: PropTypes.number,
}

export { ContentLoadingBoxFullscreen as default }
