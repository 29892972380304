import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import classNames from 'classnames'
import { autobind } from 'core-decorators'
import { formatMessage } from '../../../translations'

import ContentLoadingBox from '../../../shared/components/ContentLoadingBox'
import ListItem from '../../../article/components/ListItem'
import cancelable from '../../../shared/decorators/cancelable-promise'
import { articleStore } from '../../../article/reducers'
import { widgetStore } from '../../../widget/reducers'

const contentStores = {
  article: articleStore,
  widget: widgetStore
}
const css = /* typeof window === 'undefined' ? {} : */require('../../styles.scss')

@cancelable
@observer
export default class GhostItem extends Component { // (item) {

  static propTypes = {
    item: PropTypes.object,
    updateArticleList: PropTypes.func,
    censhareElementCounter: PropTypes.number,
    hasCenshare: PropTypes.bool,
    censhareIso: PropTypes.string.isRequired,
    ghostOptions: PropTypes.array,
    ui: PropTypes.object,
  }

  constructor(props) {
    super(props)

    this.store = contentStores[this.props.item.type]
    this.contentItem = this.store.getById(this.props.item.id)
    this.copyable = this.props.item.copyable

    if (!this.contentItem) {
      this.makeCancelable(new Promise(() => {
        this.store.load(this.props.item.id)
          .catch((ex) => {
            if (this.isCanceledPromise(ex)) {
              return {}
            }
            console.error('GhostDisplay#renderItem():', ex)
            this.setState({
              error: ex
            })
            return {}
          })
          .then((result) => {
          // triggers re-render
            const item = result.models[0]
            this.copyable = item.createdIso !== this.props.ui.contentLanguage
            this.props.updateArticleList(this.props.item.id, 'keep', this.copyable)
            this.contentItem = item
          })
      }))
    }
  }

  @observable contentItem = null

  @autobind
  handleSettingChanged({ target }) {
    this.props.updateArticleList(target.id, target.value)
  }

  render() {
    const {
      item,
      ghostOptions,
      censhareElementCounter,
      hasCenshare,
    } = this.props

    const templateName = {
      article: 'template',
      widget: 'widgetTemplate'
    }

    if (this.contentItem) {

      // TODO: use actual vendor
      const vendor = 'default' || item.vendor

      return (<div className={classNames(css.ghostItem)}>
        <img
          role="presentation"
          src={`/templates/${vendor}/${item.type}/${this.contentItem[templateName[item.type]].shortcut}/icon.svg`}
        />

        <ListItem
          article={this.contentItem}
          languageDisplay={this.contentItem.createdIso !== this.props.ui.contentLanguage}
        />
        <label className="censhare-info-label">
          {hasCenshare
            ? <div className="censhare-info">
              {formatMessage(
                { id: 'ghost-dialog.censhare-connection' },
                { count: censhareElementCounter })}
            </div>
            : null}
          <select
            value={item.value || 'keep'}
            name={this.contentItem.id}
            id={this.contentItem.id}
            onChange={this.handleSettingChanged}
          >
            { ghostOptions.map((option) => {
              return (<option
                key={option}
                name={option}
                id={option}
                value={option}
              >
                {formatMessage(
                  { id: `ghost-dialog.${option}` },
                  // For censhare use custom language iso
                  { language: option === 'censhare'
                    ? this.props.censhareIso
                    : this.props.ui.contentLanguage }
                )}
              </option>)
            })}
          </select>
        </label>
      </div>)
    }

    return (<div>
      <ContentLoadingBox
        className={css.ghostLoadingBox}
        message={{
          id: 'placeholder-empty'
        }}
      />
    </div>)
  }
}
