import warnings from './warnings.js'

function warn(messageId, ...args) {
  let message = warnings[messageId]
  let index = 0
  if (!message) {
    console.log('warn: Message missing!')
    return
  }
  message = message.replace(/(%([a-z]+))/g, (complete, g1, g2) => {

    if (g2 === 'type') {
      g1 = '%s (%s)'
      args.splice(index + 1, 0, warn.type(args[index]))
    }

    index += 1

    return g1
  })
  console.warn(message, ...args)
}

warn.type = function type(val) {
  return Object.prototype.toString.apply(val)
}


export { warn as default }
