import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { autobind } from 'core-decorators'
import classNames from 'classnames'
import { FormattedMessage } from '../../translations'

import Navbar, {
  FixedSection
} from '../../shared/components/Navbar'
import StatusList from '../../shared/components/StatusList'
import { store as uiStore } from '../../ui'
import GenevaButton from '../../ui/components/GenevaButton'
import * as css from '../styles.scss'

@observer
export default class CenshareNavbar extends Component {

  static propTypes = {
    onClose: PropTypes.func.isRequired,
    navbarStyle: PropTypes.string,
  }

  @autobind
  handleClose() {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  render() {

    const {
      navbarStyle,
    } = this.props

    return (
      <Navbar
        navbarStyle={navbarStyle}
      >

        <FixedSection>
          <div className={css.logo}>
            <img width="153" role="presentation" src="/images/atr-geneva-black.svg" />
            <div className={css.logoText}>
              <div className={css.desc}>
                <FormattedMessage id="custom-plugin" />
              </div>
              <div>
                <FormattedMessage id="censhare" />
              </div>
            </div>
          </div>
        </FixedSection>

        <FixedSection>
          <StatusList ui={uiStore} />
        </FixedSection>

        <FixedSection>

          <GenevaButton
            onClick={this.handleClose}
            className={classNames(css.closeButton, 'float-right close')}
          >
            <i className="ion-close-round"></i>
          </GenevaButton>

        </FixedSection>

      </Navbar>
    )
  }

}
