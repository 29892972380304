import { apiClient } from '../../shared/api'
import Command from '../../shared/command'

@apiClient
export default class UpdateUserCommand extends Command {

  constructor({ reviewStore, userId, data }) {
    super({ reviewStore, userId, data })
    this.userId = userId
    this.data = data
    this.reviewStore = reviewStore

    // optional action for deleting
    this.action = data.action

    if (!this.data.message) {
      // Transform to null as requested by backend
      this.data.message = null
    }
  }

  validate({ reviewStore, userId, data }) {

    if (!reviewStore) {
      return `Expected \`reviewStore\` to be present. "${reviewStore}" given.`
    }

    if (!userId) {
      return 'Expected userId to be given.'
    }

    if (!data) {
      return 'Expected new data for an user to update'
    }

    return false
  }

  exec() {

    const {
      userId,
      reviewStore,
      data,
      action
    } = this

    const currentModel = reviewStore.current
    const user = reviewStore.current.getUserById(userId)

    if (action && action === 'destroy') {

      this.dispatch('del', {
        path: `review/${currentModel.id}/user/${userId}`
      }).then(() => {
        currentModel.users.splice(currentModel.users.indexOf(user), 1)
      }).catch((err) => {
        console.error(err)
      })

      // End of command
      return
    }

    const userSet = currentModel.users.map(el => el.id)

    this.dispatch('put', {
      path: `review/${currentModel.id}/user/${this.userId}`,
      data
    }).then(() => {
      // add new data to user of currentModel
      Object.assign(user, data)
    }).catch((err) => {
      console.error(err)
    })
  }

}
