import load from './load'
import start from './start'
import error from './error'

export AppDashboard from './containers/Dashboard'
export AppLayout from './containers/Layout'

export default (opts) => {
  return {
    load: (params = {}) => {
      load({
        ...opts,
        ...params
      })
    },
    start: (params = {}) => {
      start({
        ...opts,
        ...params
      })
    },
    error: (params = {}) => {
      error({
        ...opts,
        ...params
      })
    }
  }
}
