import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import { autobind } from 'core-decorators'
import classNames from 'classnames'

export default class DropIndicator extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    strategy: PropTypes.object,
    onDrop: PropTypes.func,
    className: PropTypes.string,
    children: PropTypes.node,
    isValidDropTarget: PropTypes.bool,
  };

  constructor(props) {
    super(props)

    this.state = {
      urlDragOver: false,
    }
  }

  @autobind
  handleDrop(e) {
    e.stopPropagation()
    e.preventDefault()

    // reset drag over state
    this.handleDragOver(e)

    if (this.props.onDrop) {
      let promise = this.props.strategy.getData(e)

      if (!promise.then) {
        promise = Promise.resolve(promise)
      }

      promise.then((target) => {
        this.props.onDrop({ target })
      })
    }
  }

  @autobind
  handleDragOver(e) {
    e.stopPropagation()
    e.preventDefault()

    const isDragOver
      = !this.props.disabled && this.props.strategy.isValidDrag(e)

    this.setState({ isDragOver })
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   return ['disabled', 'isValidDropTarget'].reduce((memo, prop) => {
  //     memo || this.props[prop] !== nextProps[prop], false
  //   }) ||
  //   ['isDragOver'].reduce((memo, prop) =>
  //     memo || this.state[prop] !== nextState[prop], false)
  // }

  render() {
    const isValidDropTarget
      = !this.props.disabled && this.props.isValidDropTarget

    return (
      <div
        className={classNames(this.props.className, {
          'url-drag-active': isValidDropTarget,
          'url-drag-over': isValidDropTarget && this.state.isDragOver,
        })}
        onDragOver={this.handleDragOver}
        onDragLeave={this.handleDragOver}
        onDrop={this.handleDrop}
      >
        <div
          className="grid-block vertical v-align align-center text-center"
          style={{
            height: '100%',
          }}
        >
          {this.props.children}
        </div>
      </div>
    )
  }
}
