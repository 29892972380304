import PropTypes from 'prop-types'
import React from 'react'
import { observer } from 'mobx-react'

import Navbar, { FlexSection, FixedSection } from '../../../shared/components/Navbar'
import StatusList from '../../../shared/components/StatusList'
import connectNavbarOptions from '../../connectors/connectNavbarOptions'

const ConnectedNavbarOptions = connectNavbarOptions()

const PublishNavbar = observer((props) => {

  const {
    navbarStyle,
    brand,
    links,
    ui
  } = props

  return (
    <Navbar
      navbarStyle={navbarStyle}
    >
      <FixedSection>
        <div className="title">
          {brand}
        </div>
        {links}
      </FixedSection>
      <FlexSection>
        <StatusList ui={ui} />
      </FlexSection>
      <FixedSection>
        <ConnectedNavbarOptions />
      </FixedSection>
    </Navbar>
  )

})


PublishNavbar.propTypes = {
  ui: PropTypes.object.isRequired,
  navbarStyle: PropTypes.string,
  brand: PropTypes.node.isRequired,
  links: PropTypes.node
}

export default PublishNavbar
