import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { autobind } from 'core-decorators'
import { confirmable } from 'react-confirm'
import { autorun } from 'mobx'
import classNames from 'classnames'

import { formatMessage, FormattedMessage } from '../../../translations'

import { useProjectFilter, contentLangs } from '../../../config'

import RadioGroup from '../../../shared/components/RadioGroup'
import { Dialog } from '../../../shared/components/Dialog'
import { deepGet } from '../../../shared/obj'
import GenevaButton from '../../../ui/components/GenevaButton'
import AddLanguage from './AddLanguage'

const css = /* typeof window === 'undefined' ? {} : */require('../../styles.scss')

@confirmable
export default class ArticleLanguagesDialog extends Component {

  static propTypes = {
    show: PropTypes.bool,            // indicates if the dialog is shown or not.
    proceed: PropTypes.func,         // call to close the dialog with promise resolved.
    cancel: PropTypes.func,          // call to close the dialog with promise rejected.
    dismiss: PropTypes.func,         // call to only close the dialog.
    confirmation: PropTypes.string,
    options: PropTypes.shape({
      modal: PropTypes.shape({
        storeWithLangInfo: PropTypes.shape({
          languageCollection: PropTypes.object.isRequired,
          loadLanguageCollection: PropTypes.func,
        }).isRequired,
        article: PropTypes.object.isRequired
      })
    })
  }

  constructor(props) {
    super(props)


    this.state = {
      // Without env flag all possible languages should be shown
      // With env flag show only languages allowed in the !current! project
      // Note: this is highly dangerous without direct connection between articles and projects
      langList: useProjectFilter ? this.buildProjectLangSet() : this.buildLangSet(),
      langToDisplay: '',
      dialogTitle: formatMessage({ id: 'article.add-language' }),
      disabledContinue: true,
      disabledConfirm: true,
      displayState: 'initial',
      prevDisplayState: 'initial',
      prevPressedButton: null,
      censhareMap: []
    }

  }

  componentDidMount() {
    const langStore = this.props.options.storeWithLangInfo
    this.langListDates()

    this.handler = autorun('AUTORUN:projectStore-languageCollection-updated', () => {
      if (langStore.languageCollection) {
        this.setState({
          langList: useProjectFilter ? this.buildProjectLangSet() : this.buildLangSet()
        })
      }
    })
  }

  // Defining class variables (not needed in state)
  langSelected = []

  // Only when the project filter flag is set for the customer
  buildProjectLangSet() {
    const projectStore = this.props.options.storeWithLangInfo
    this.langSelected = this.getAlreadyCreatedLangs()
    const current = projectStore.current

    const projectGroupLanguages = projectStore.collection
      .filter(proj => proj.projectGroupId === current.projectGroupId && proj.id !== current.id)

    return this.sortLangList(projectGroupLanguages)
  }


  buildLangSet() {
    const { article } = this.props.options
    const langStore = this.props.options.storeWithLangInfo

    let groupLanguages = []
    const tempLangList = []

    // requires a minimum of the current lang in collection
    if (langStore.languageCollection.length === 0 && !this.isLoading) {
      groupLanguages[0] = { iso: article.createdIso }
      // start this loading in the background
      langStore.loadLanguageCollection()
      this.isLoading = true
    }
    else {
      groupLanguages = langStore.languageCollection
    }

    // loop through all possible langs for the project
    const createdLangs = this.getAlreadyCreatedLangs()
    groupLanguages.forEach((lang) => {
      // only include active langs
      if (lang.isActive) {
        tempLangList.push(lang)
      }
    })

    return this.sortLangList(tempLangList)
  }

  getAlreadyCreatedLangs() {
    const createdLangs = {}
    this.props.options.article.languages.forEach((createdArticle) => {
      Object.keys(createdArticle).forEach((item) => {
        createdLangs[item] = true
      })
    })
    return createdLangs
  }

  sortLangList(langList) {
    // alphabetical
    return langList.sort((a, b) => {
      if (a.iso < b.iso) {
        return -1
      }
      if (a.iso > b.iso) {
        return 1
      }
      return 0
    })
  }

  langListDates() {
    const { article } = this.props.options
    const { langList } = this.state

    for (let i = 0; i < langList.length; i++) {
      const langIso = langList[i].iso
      article.languages.map((createdArticle) => {
        // if previously created
        if (createdArticle[langIso]) {

          // Todo: simplfy this call
          const temp = article.store.getById(createdArticle[langIso])
          // if there is a created id but the article is not in the store yet...
          if (!temp) {
            article.store.load(createdArticle[langIso], { force: true })
              .then(() => {
                const tempCurrent = this.state.langList.find(item => item.iso === langIso
                )
                if (tempCurrent) {
                  const createdAt = article.store.getById(createdArticle[langIso]).createdAt || ''
                  langList[i] = {
                    iso: langIso,
                    isActive: true,
                    createdAt
                  }
                  this.setState({
                    langList
                  })
                }
              })
          }
          else {
            const createdAt = article.store.getById(createdArticle[langIso]).createdAt || ''
            langList[i] = {
              iso: langIso,
              isActive: true,
              createdAt
            }
            this.setState({
              langList
            })
          }
        }
        return createdArticle
      })
    }
  }

  @autobind
  handleOnRadioClick({ target }) {
    this.setState({
      disabledConfirm: false,
      langToDisplay: target.value
    })
  }

  // updates langSelected to have an array of only selected ids
  @autobind
  handleCheckboxChange({ target }) {
    if (target.checked) {
      if (this.langSelected.indexOf(target.id) < 0) {
        this.langSelected.push(target.id)
      }
    }
    else {
      this.langSelected.splice(this.langSelected.indexOf(target.id), 1)
    }
    this.setState({
      disabledContinue: this.langSelected.length === 0
    })
  }

  @autobind
  handleDropdownChange({ target }) {
    const censhareMap = this.state.censhareMap

    if (target.value === 'censhare') {
      // Add if not found
      if (censhareMap.indexOf(target.id) < 0) {
        censhareMap.push(target.id)
      }
    }
    else if (target.value === 'translate') {
      // Remove if found
      if (censhareMap.indexOf(target.id) > -1) {
        censhareMap.splice(censhareMap.indexOf(target.id), 1)
      }
    }

    this.setState({
      censhareMap
    })
  }

  @autobind
  handleCenshareSelection() {
    const { prevPressedButton } = this.state

    if (prevPressedButton === 'handleCreateAndEdit') {
      return this.handleCreateAndEdit()
    }

    return this.handleOnlyCreate()
  }


  @autobind
  handleCreateAndEdit() {
    // check if censhare is enabled
    const hasCenshare = deepGet(this.props, 'options.storeWithLangInfo.current.hasCenshare')

    if (hasCenshare && this.langSelected.find(el => contentLangs[el] && contentLangs[el].censhareIso) && !this.state.prevPressedButton) {
      const censhareMap = this.langSelected.map((langKey) => {
        return contentLangs[langKey] && contentLangs[langKey].censhareIso ? langKey : null
      })
      this.setState({
        displayState: 'censhare',
        prevDisplayState: this.state.displayState,
        prevPressedButton: 'handleCreateAndEdit',
        dialogTitle: formatMessage({ id: 'article.add-language-dialog.censhare-title' }),
        censhareMap
      })
    }
    else if (this.langSelected.length > 1) {
      this.setState({
        displayState: 'selection',
        prevDisplayState: this.state.displayState,
        dialogTitle: formatMessage({ id: 'article.add-language-dialog.language-option' })
      })
    }
    else {
      const result = this.langSelected.length !== 0
        ? { toCreate: [this.langSelected[0]], toOpen: this.langSelected[0] }
        : { toCreate: [], toOpen: '' }
      return (
        this.props.proceed({
          toCreate: result.toCreate,
          toOpen: result.toOpen,
          censhareMap: this.state.censhareMap
        })
      )
    }
    return true
  }

  @autobind
  handleOnlyCreate() {
    // check if censhare is enabled
    const hasCenshare = deepGet(this.props, 'options.storeWithLangInfo.current.hasCenshare')

    if (hasCenshare && this.langSelected.find(el => contentLangs[el] && contentLangs[el].censhareIso) && !this.state.prevPressedButton) {
      const censhareMap = this.langSelected.map((langKey) => {
        return contentLangs[langKey] && contentLangs[langKey].censhareIso ? langKey : null
      })
      return this.setState({
        displayState: 'censhare',
        prevDisplayState: this.state.displayState,
        prevPressedButton: 'handleOnlyCreate',
        dialogTitle: formatMessage({ id: 'article.add-language-dialog.censhare-title' }),
        censhareMap
      })
    }

    const result = { toCreate: this.langSelected, toOpen: '' }
    return (
      this.props.proceed({
        toCreate: result.toCreate,
        toOpen: result.toOpen,
        censhareMap: this.state.censhareMap
      })
    )
  }

  @autobind
  handleGoBack() {
    // Reset certain values to ensure a fresh state
    this.langSelected = []
    this.setState({
      displayState: 'initial',
      prevPressedButton: null,
      disabledContinue: true,
      disabledConfirm: true,
      censhareMap: [],
    })
  }

  @autobind
  handleConfirm() {
    return (
      this.props.proceed({
        toCreate: this.langSelected,
        toOpen: this.state.langToDisplay,
        censhareMap: this.state.censhareMap
      })
    )
  }

  renderCenshareOptions() {
    return <div className={css.censhareView}>
      <FormattedMessage id="article.add-language-dialog.censhare-info" />

      {this.langSelected.map((langKey) => {
        const censhareIso = contentLangs[langKey] && contentLangs[langKey].censhareIso
        if (!censhareIso) {
          // Bail out if no iso is set
          return null
        }

        return <div
          className={css.censhareEntry}
          key={langKey}
        >
          <div>{langKey}</div>
          <select
            name={langKey}
            id={langKey}
            onChange={this.handleDropdownChange}
          >
            <option
              key="censhare"
              name="censhare"
              id="censhare"
              value="censhare"
            >
              {formatMessage(
                { id: 'ghost-dialog.censhare' },
                { language: censhareIso }
              )}
            </option>
            <option
              key="translate"
              name="translate"
              id="translate"
              value="translate"
            >
              {formatMessage(
                { id: 'ghost-dialog.translate' },
                { language: langKey }
              )}
            </option>

          </select>
        </div>
      })}
    </div>
  }

  @autobind
  renderFooter() {
    const { disabledConfirm, disabledContinue, displayState, prevPressedButton } = this.state

    return (
      <div>
        {displayState === 'initial'
          ? <div className="grid-content text-right">
            <GenevaButton
              type="button"
              className={classNames(css.firstButton, 'small button')}
              onClick={this.props.dismiss}
            >
              <FormattedMessage id="article.add-language-dialog.cancel" />
            </GenevaButton>
            <GenevaButton
              type="button"
              className="small button"
              onClick={this.handleCreateAndEdit}
              disabled={disabledContinue}
            >
              <FormattedMessage id="article.add-language-dialog.create-and-edit" />
            </GenevaButton>
            <GenevaButton
              type="submit"
              className="small primary button"
              onClick={this.handleOnlyCreate}
              disabled={disabledContinue}
            >
              <FormattedMessage id="article.add-language-dialog.create" />
            </GenevaButton>
          </div>
          : null}
        {displayState === 'selection'
          ? <div className="grid-content text-right">
            <GenevaButton
              type="button"
              className="small button"
              onClick={this.handleGoBack}
            >
              <FormattedMessage id="article.add-language-dialog.cancel" />
            </GenevaButton>
            <GenevaButton
              type="button"
              className="small primary button"
              onClick={this.handleConfirm}
              disabled={disabledConfirm}
            >
              <FormattedMessage id="article.add-language-dialog.edit" />
            </GenevaButton>
          </div>
          : null}
        {displayState === 'censhare'
          ? <div className="grid-content text-right">
            <GenevaButton
              type="button"
              className="small button"
              onClick={this.handleGoBack}
            >
              <FormattedMessage id="article.add-language-dialog.cancel" />
            </GenevaButton>
            <GenevaButton
              type="button"
              className="small primary button"
              onClick={this.handleCenshareSelection}
            >
              {prevPressedButton === 'handleCreateAndEdit'
                ? <FormattedMessage id="article.add-language-dialog.create-and-edit" />
                : <FormattedMessage id="article.add-language-dialog.create" />}
            </GenevaButton>
          </div>
          : null}
      </div>
    )
  }

  render() {
    const { displayState, dialogTitle, langList } = this.state

    return (
      <Dialog
        className={css.addLanguageDialog}
        overlayClassName=""
        isOpen={this.props.show}
        title={dialogTitle}
        onCancel={() => this.props.dismiss()}
        renderFooter={this.renderFooter}
        id="ArticleLanguagesDialog"
      >
        {displayState === 'initial'
          ? (<AddLanguage
            langList={langList || {}}
            articleLanguage={this.props.options.article.createdIso}
            langSelected={this.props.options.article.languages}
            handleCheckboxChange={this.handleCheckboxChange}
          />)

          : null}
        {displayState === 'selection'
          ? <div className="grid-content language-options">
            <div className="subheader">
              <FormattedMessage id="article.add-language-dialog.subheader" />
            </div>
            <RadioGroup
              activeValue={this.state.langToDisplay}
              itemList={this.langSelected}
              handleOnRadioClick={this.handleOnRadioClick}
              buttonGroupName="languageSelection"
              displayKey="createdIso"
            />
          </div>
          : null}
        {displayState === 'censhare'
          ? this.renderCenshareOptions()
          : null}
      </Dialog>
    )
  }
}
