import { toJS, transaction } from 'mobx'

import { i18n } from '../../shared/utils'

export default class PlaceholderDataInserter {
  constructor(phAccessor, channel) {
    this.phAccessor = phAccessor
    this.channel = channel || this.phAccessor.channel
  }

  setChannel(newChannel) {
    this.channel = newChannel
  }

  /**
   *
   * @param {String} pid
   * @param {Object} fileData
   * @param {Function} getEntry
   */
  placeRawDataInPlaceholder(pid, fileData, getEntry) {
    const key = 'value'
    const handleSingleEntry = (entry, id) => {
      entry = toJS(entry)

      const [value, types] = getEntry(entry)

      this.phAccessor.set(id, key, {
        type: types.valueType,
        value,
        source: entry.source,
      })
    }

    if (Array.isArray(fileData)) {
      transaction(() => {
        let orderList = toJS(this.phAccessor.get(pid))

        if (orderList !== undefined && !Array.isArray(orderList)) {
          this.phAccessor.remove(pid)
          orderList = null
        }

        if (!orderList) {
          orderList = []
        }

        orderList = JSON.parse(JSON.stringify(orderList))

        let index = (orderList.length ? Math.max(...orderList) : -1) + 1
        fileData.forEach((entry) => {
          orderList.push(index)
          handleSingleEntry(entry, `${pid}:${index}`)
          index += 1
        })

        this.phAccessor.set(pid, key, {
          type: 'keyValue',
          value: orderList,
        })
      })
    }
    else {
      if (fileData) {
        handleSingleEntry(fileData, pid)
      }
    }
  }

  /**
   *
   * @param {String} pid
   * @param {Object} imageData
   */
  placeImageDataInPlaceholder(pid, imageData) {
    const getEntry = (entry) => {
      const lang
        = this.phAccessor.article && this.phAccessor.article.createdIso
          ? this.phAccessor.article.createdIso
          : entry.createdIso

      const channelData = i18n(entry, `draft.channels.${this.channel}`, lang)

      return [
        {
          id: entry.id,
          src: channelData.url,
          alt: i18n(entry, 'alt', lang),
          title: i18n(entry, 'title', lang),
          // needed primarily for cache-busting
          fileSize: channelData.fileSize
        },
        {
          valueType: 'image',
          dataType: 'image',
        },
      ]
    }

    this.placeRawDataInPlaceholder(pid, imageData, getEntry)
  }

  /**
   *
   * @param {String} pid
   * @param {Object} fileData
   */
  placeFileDataInPlaceholder(pid, fileData) {
    const getEntry = (entry) => {
      let meta = ''
      let fileInfo = ''
      const lang
        = this.phAccessor.article && this.phAccessor.article.createdIso
          ? this.phAccessor.article.createdIso
          : entry.createdIso

      meta = i18n(entry, 'draft', lang)
      fileInfo = i18n(entry, 'draft.original', lang)

      delete meta.original
      return [
        {
          id: entry.id,
          ...fileInfo,
          ...meta,
        },
        {
          valueType: 'file',
          dataType: 'file',
        },
      ]
    }

    this.placeRawDataInPlaceholder(pid, fileData, getEntry)
  }

  /**
   *
   * @param {string} pid
   * @param {Object} videoData
   */
  placeVideoDataInPlaceholder(pid, videoData) {
    const getEntry = () => {
      return [
        {
          id: videoData.id,
          url: videoData.url,
        },
        {
          valueType: 'keyValue',
          dataType: 'video',
        },
      ]
    }

    this.placeRawDataInPlaceholder(pid, videoData, getEntry)
  }
}
