import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { autobind } from 'core-decorators'
import keydown from 'react-keydown'
import { withRouter } from 'react-router'

import { store as contextStore } from '../../../context'

import WidgetNavbarTools from './Tools'

import Navbar, {
  BrandSection,
  FlexSection
} from '../../../shared/components/Navbar'
import StatusList from '../../../shared/components/StatusList'
import { shortcut } from '../../../shared/const'
import EditItemCommand from '../../../shared/commands/EditItemCommand.js'
import commandConnector from '../../../shared/lib/command'
import { dispatcher } from '../../../shared/lib/command'

import * as css from '../../styles.scss'

@dispatcher
class WidgetNavbar extends Component {

  static propTypes = {
    brand: PropTypes.node.isRequired,
    id: PropTypes.string,
    navbarStyle: PropTypes.string,
    ui: PropTypes.object.isRequired,
    widget: PropTypes.object,
  }

  @autobind
  @keydown(shortcut.CLOSE_APP)
  handleClose() {

    this.props.widget.unsetCurrent()

    const id = contextStore.getCurrentIds().pageId || this.props.params.pageId * 1

    this.context.dispatch(
      this.props.commands.EditItemCommand,
      {
        router: this.props.router,
        type: 'page',
        id
      }
    )

  }

  render() {

    const {
      navbarStyle,
      brand,
      ui,
      id,
    } = this.props

    return (
      <Navbar
        navbarStyle={navbarStyle}
        id={id}
      >

        <BrandSection>
          {brand}
        </BrandSection>

        <FlexSection>
          <StatusList ui={ui} className={css.widgetStatusList} />
        </FlexSection>

        <WidgetNavbarTools
          onClose={this.handleClose}
        />

      </Navbar>
    )
  }
}

export default commandConnector({
  EditItemCommand
})(
  withRouter(observer(WidgetNavbar))
)
