import Command from '../command'

import { pageStore } from '../../page/reducers'

export default class InsertIntoPageCommand extends Command {

  constructor(data) {
    super(data)
    this.data = data
  }

  validate(data) {
    if (!data.pageId) {
      return `Expects \`data.id\` to contain a valid page id. \`${data.id} given.\``
    }
    if (!data.type || (data.type !== 'article' && data.type !== 'widget')) {
      return `Expects \`data.type\` to be one of "article" or "widget". \`${data.type} given.\``
    }
    if (!(data.type in data)) {
      return `Expects \`data.${data.type}\` to contain a valid content model of type ${data.type}.`
    }
    return null
  }

  exec() {

    const {
      type,
      pageId,
    } = this.data

    this.pageModel = pageStore.getById(pageId)

    if (!this.pageModel) {
      throw new Error(`Could not find page model with id ${pageId}`)
    }

    const item = this.data[type]

    // Adds the projectId to the article (relevant for inserting existing article)
    const projectId = this.pageModel.projectId
    if (!item.projectIds.find(id => id === projectId)) {
      item.set({
        projectIds: [
          ...item.projectIds,
          projectId
        ]
      })
    }

    return this.pageModel.insertContent(item, type, this.data.position)

  }

}
