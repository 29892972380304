import { DefaultInspector } from './defaultInspector'
import { DefaultSimpleInspector } from './defaultSimpleInspector'
/**
 * An Inspector decorator, that wraps around calls to `method` and can execute
 * methods on a `Handler` before, after and on error cases.
 * The wrapped method MUST return a thenable!
 */
export function inspector(method, Handler) {
  return function inspectorDecorator(target) {
    const provider = target[method] ? target : target.prototype

    if (!(method in provider)) {
      console.warn(`${method} is not defined on ${target.name}. Skipping.`)
      return
    }

    const origMethod = provider[method]

    provider[method] = function inspectedMethod(...args) {
      if (typeof Handler === 'string') {
        const messageId = Handler
        Handler = new DefaultInspector()
        Handler.messageId = messageId
      }

      Handler.before(this, ...args)

      const thenable = origMethod.call(this, ...args)

      const isThenable = !!thenable.then

      if (isThenable && Handler.then) {
        thenable.then(
          result => Handler.then(this, ...args, result) || result
        )
      }

      if (isThenable && Handler.catch) {
        thenable.catch((err) => {
          throw Handler.catch(this, ...args, err) || err
        })
      }

      return thenable
    }
  }
}

export function simpleInspector(method, Handler) {
  return function inspectorDecorator(target) {
    const provider = target[method] ? target : target.prototype

    if (!(method in provider)) {
      console.warn(`${method} is not defined on ${target.name}. Skipping.`)
      return
    }

    const origMethod = provider[method]

    provider[method] = function inspectedMethod(...args) {
      if (typeof Handler === 'string') {
        const messageId = Handler
        Handler = new DefaultSimpleInspector()
        Handler.messageId = messageId
      }

      Handler.exec(target.name, ...args)

      origMethod.call(this, ...args)
    }
  }
}
