import Command from '../../shared/command'

/**
 * Removes the content of a censhare placeholder.
 * @extends shared~Command
 */
export default
class RemoveArticlePlaceholderCommand
  extends Command {

  constructor(placeholderData) {

    super(placeholderData)

    this.data = placeholderData

  }

  /**
   * Validates the data in this command.
   * @param {Object} data - The data of the command
   * @private
   */
  validate() {
    // TODO
  }

  exec() {
    this.data.article.removeDataInPlaceholder(`${this.data.pid}*`)
  }
}
