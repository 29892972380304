import { closest } from './dom-helpers'

export default function () {
  return function (scribe) {
    const superscriptCommand = new scribe.api.CommandPatch('superscript')

    /**
    * Chrome: Executing the superscript command inside a heading corrupts the markup.
    * Disabling for now.
    // */
    // superscriptCommand.queryEnabled = function (...args) {
    //   const selection = new scribe.api.Selection()
    //   const headingNode = selection.getContaining((node) => {
    //     return (/^(H[1-6])$/).test(node.nodeName)
    //   })
    //
    //   return ::scribe.api.CommandPatch.prototype.queryEnabled(...args) && ! headingNode
    // }

    // TODO: We can't use STRONGs because this would mean we have to
    // re-implement the `queryState` command, which would be difficult.

    superscriptCommand.queryState = function () {
      const selection = new scribe.api.Selection()
      const range = selection.range
      let container

      if (!range) {
        return false
      }

      container = range.commonAncestorContainer

      if (container.nodeType === Node.TEXT_NODE) {
        container = container.parentNode
      }

      return !!closest(container, 'sup')
    }

    // remove orig impl
    scribe.commands.superscript = null
    scribe.commandPatches.superscript = superscriptCommand

  }
}
