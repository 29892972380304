import { observable, computed, action } from 'mobx'
import Communicator from '../Communicator'
import { paginate, filterable, apiClient } from '../shared/api'

import { inspector } from '../shared/decorators/inspector'
import { overloadable } from '../shared/decorators/overload'
import { deepGet, deepSet } from '../shared/obj'
import { addToCollection, i18n } from '../shared/utils'
import { Store } from '../shared/store'
import communicate from '../shared/decorators/communicator'

import { store as uiStore } from '../ui'

import { CircularDependencyFreeStore } from '../CircularDependencyFreeStore'

import Widget from './model'

const defaultVals = {
  page: 0,
  hasMoreItems: null,
  collection: [],
  modifiedCollection: [],
  filter: {},
  sorting: {},
  loading: null,
  total: 0,
  filterTotal: null, // can't set to 0 since 0 is a meaningful result that we need to react to
}

@paginate
@overloadable
@filterable
@inspector('save', 'widget.saving')
@apiClient
@communicate(Communicator.getInstance())
export default class WidgetStore extends Store {
  // Put all widget list properties here. This regargds also state properties.

  @observable collection = [];

  @observable modifiedCollection = [];

  @observable current = {};

  @computed get hasCurrent() {
    return !!this.current.id
  }

  @observable total = 0;

  @observable filterTotal = 0;

  @observable page = 1;

  @observable limit = 20;

  @observable loading = false;

  hasMoreItems = true;

  @observable filter = {};

  @observable sorting = {};

  @observable selected = {};

  // Put all properties that are not to be observed here:

  // to see the store name when in production mode - Uglifyjs
  static storeName = 'WidgetStore';

  constructor(opts = {}) {
    super(opts)

    this.Model = Widget

    this.initCommunicator()
  }

  /**
   * Returns the current ediitable version. So even if the `current` property
   * of this store points to an older version, the original widget is returned.
   */
  @computed get currentEditable() {
    return this.current.origin || this.current
  }

  // @overload({ id: 'string?', opts: 'object?', data: 'object?' })
  /**
   * When @Pagination is included, limit and page will be updated in opts if no id and not defined
   * @Pagination will also add +1 to the page or this.page, by design
   * When @filterable is included: opts.params.filter will default to this.filter if not defined
   * Filtered collection needs to shadow the normal collection when no filter
   */
  load(id = null, opts = {}) {
    if (!deepGet(opts, 'params.lang')) {
      deepSet(opts, 'params.lang', uiStore.contentLanguage)
    }

    this.loading = true
    return this.dispatch('get', {
      path: `widget/${id}`,
      params: opts.params
    }).then((response) => {

      if (!response || !response.body || response.error) {
        throw new Error('error in article get')
      }

      const model = addToCollection(this, this.collection, response.body.data, Widget)

      // todo: are totals, filterTotal, and pagination even needed here?
      if (opts.params && opts.params.filter && opts.params.filter !== '[]') {
        this.filterTotal = deepGet(response.body, 'meta.pagination.total') || 0
      }
      else {
        this.total = deepGet(response.body, 'total') || 1
      }

      this.loading = false
      return model
    })
  }

  createVersion(widget) {
    widget.versionStore.create()
  }

  restoreVersion(widget, versionId) {
    return widget.versionStore.restore(versionId).then((result) => {
      const restoredWidget = this.createModel(result.body.data)
      widget.origin = restoredWidget
    })
  }

  loadVersion(
    versionId = Widget.INVALID_INSTANCE_VERSION,
    opts = {},
    data = null
  ) {
    if (!(this.current.id && this.current.versionStore)) {
      throw new Error('Loading versions require a propert version id')
    }

    const origin = this.current.origin || this.current

    if (versionId === Widget.INVALID_INSTANCE_VERSION) {
      if (origin) {
        // TODO: check if this is really needed or if database error
        // origin.handleModelCreated()
        origin.versionStore.collection = this.current.versionStore.collection
        this.setCurrent(origin)
      }
      return null
    }

    const currentVersionStore = this.current.versionStore

    opts.force = true

    return currentVersionStore.load(versionId, opts, data).then(() => {
      // The shared/Store will push an instance to the collection.
      // This holds the data in placeholder
      const version = currentVersionStore.collection.pop()
      version.origin = origin
      version.origin.versionStore.collection = this.current.versionStore.collection
      this.openFromVersion(version)
    })
  }

  save(id, model) {
    const { pageStore } = CircularDependencyFreeStore

    return this.dispatch('put', {
      path: `widget/${model.id}`,
      data: model.getJSON()
    }).then((result) => {
      // model is processed by the backend, so it it necessary to rebuild the model afterwards
      const updatedModel = addToCollection(this, this.collection, result.body.data, Widget)
      this.saving = null
      // - setting the page.hasChanged flag after widget on that page changes
      if (
        pageStore
        && pageStore.hasCurrent
        && pageStore.current.hasItem(updatedModel.id)
      ) {
        pageStore.current.hasChanged = 1
      }
      return updatedModel
    }).catch((err) => {
      this.saving = null
      throw err
    })
  }

  destroy(widget) {
    if (widget && widget.id) {
      return this.dispatch('del', {
        path: `widget/${widget.id}`,
      })
    }

    // if not an widget, throw error
    throw new Error('missing widget in delete action')
  }

  reset(opts) {
    // if (this.loading) {
    //   return
    // }

    const vals = {
      ...defaultVals,
    }

    delete vals.filter

    if (!('filter' in opts)) {
      delete vals.collection
      delete vals.modifiedCollection
    }

    const resetCollection = !!vals.collection
    delete vals.collection

    // This will overwrite/reset everything that hasen't been removed from vals
    Object.assign(this, {
      ...vals,
      ...opts,
    })

    if (resetCollection) {
      if ('filter' in opts) {
        this.modifiedCollection.splice(0, this.modifiedCollection.length)
      }
      else {
        this.collection.splice(0, this.collection.length)
        // operates as a child to collection
        this.modifiedCollection.splice(0, this.modifiedCollection.length)
      }

      this.page = 0
    }

    this.load()
  }

  canLoad() {
    return !this.loading && this.hasMoreItems
  }

  openFromVersion(version) {
    const widgetData = version.getWidgetData(version.origin.id)

    widgetData.type = 'version'
    widgetData.versions = version.origin.versionStore.toJSON()

    const versionWidgetModel = this.createModel(widgetData, {
      autoSave: false,
      autoSavable: false,
    })
    versionWidgetModel.origin = version.origin

    this.setCurrent(versionWidgetModel)
  }

  create(data = {}, opts = {}) {
    const createdIso = data.createdIso || uiStore.contentLanguage

    const { projectStore } = CircularDependencyFreeStore

    if (!(projectStore && projectStore.hasCurrent)) {
      throw new Error(
        'ArticleStore#create(): A projectStore with a current '
          + 'project is inevtable to create a new article in order to determine the '
          + 'proper channel to use!'
      )
    }

    data = {
      widgetTemplateId: data.templateId,
      ...i18n({}, 'name', createdIso, `New Widget (${data.templateName})`),
      channels: [projectStore.current.channelShortcut],
      createdIso,
      projectIds: [projectStore.current.id],
      content: [],
      keyValue: {},
      meta: {},
      publicationNotAllowed: false,
      releasePublicationLockAt: null,
      status: 20,
    }

    return this.dispatch('post', {
      path: 'widget',
      data
    }).then((response) => {
      if (deepGet(response, 'body.data') && !response.error) {
        return addToCollection(this, this.collection, response.body.data, Widget)
      }
      throw new Error('failed to create new Article')
    })
  }

  editWidget(widgetId) {
    console.log(widgetId)
  }


  @action
  actionDestroyItem(itemId) {
    const item = this.getById(itemId)

    if (!item) {
      // eslint-disable-next-line max-len
      throw new Error(
        `Could not find \`${this.Model.constructor.name}\` item with id \`${itemId}\`!`
      )
    }

    const isCurrent = this.current === item

    item.destroy()

    if (isCurrent) {
      this.setCurrent(null)
    }

    // TODO: select next widget or something like that
  }
}
