import { apiClient } from '../api/decorators/apiClient'
import Command from '../command'
import { deepGet } from '../obj'

/**
 * spec: http://confluence.atrivio.net:8090/pages/viewpage.action?pageId=12091478
 * example
   PUT http://api.geneva.localhost/public/image/5cf77c7eb3776c005c150f74/meta?lang=de-DE
    {
      "alt": "Der alt",
      "title": "Der title"
    }
 */
@apiClient
export default class AddMetaTagsToImage extends Command {

  constructor(data) {
    super(data)

    this.data = data
  }

  validate(data) {
    if (!data.imageId) {
      return `Expects \`data.imageId\` to contain a valid page id. \`${data.imageId} given.\``
    }
    if (!data.lang) {
      return `Expects \`data.lang\` to contain a valid page id. \`${data.lang} given.\``
    }
    return null
  }

  exec() {
    return this.dispatch('put', {
      path: `image/${this.data.imageId}/meta`,
      data: {
        title: this.data.metaTags.titleText || '',
        alt: this.data.metaTags.altText || ''
      },
      params: {
        lang: this.data.lang
      }
    }).then((result) => {
      if (result) {
        return deepGet(result, 'body.data')
      }
      return null
    })
  }
}
