import { UserCanceledDialogError } from '../../errors'

export default
function handleDialogError(ex, reject) {
  if (ex instanceof UserCanceledDialogError) {
    console.warn(ex.message)
    return
  }
  if (reject) {
    reject(ex)
  }
  else {
    throw ex
  }
}
