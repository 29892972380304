import { observable } from 'mobx'
import ChatStore from './ChatStore'
import { guid } from './utils'
/* global io*/

if (typeof io === 'undefined') {
  // eslint-disable-next-line
  global.io = () => {
    return {
      on: () => {},
      emit: () => {}
    }
  }
}

export default class Communicator {

  @observable isReady = false

  @observable userList = []

  constructor(settings, createGuid = guid) {
    this.settings = settings
    this.socket = io(this.settings.url)
    this.user = null

    this.createGuid = createGuid

    this.chatStore = new ChatStore(this)

    this.queue = []
    this.logger = () => {}

  }

  getUserFromList(userId) {
    return this.userList.find(user => user.id === userId)
  }

  setUser(user) {

    return new Promise((resolve) => {

      // if there is no io instance, just move on
      if (!io) {
        this.isReady = true
        resolve()
        return
      }

      this.user = user

      this.chatStore.setUser(this.user)

      this.on('ack', () => {
        this.queue.unshift({ action: 'identify' })
        this.flushQueue()
        this.isReady = true
        resolve()
      })

      this.on('connect', () => {
        this.ready({ user })
      })

      this.on('disconnect', () => {
        this.isReady = false
      })

      this.on('message', (data) => {
        switch (data.action) {
          case 'listUser':
            this.userList = Object.keys(data.data).map(id => data.data[id])
            break
          case 'message':
            // in case it's a chat message:
            if (data.recipient && data.recipient.id) {
              this.chatStore.handle(data)
            }

            break
          default:
            break
        }

      })

      setTimeout(() => {
        this.ready({ user })
      }, 1)

    })

  }

  addLogger(logger) {
    this.logger = logger
  }

  /**
   * @private
   */
  flushQueue() {
    while (this.queue.length) {
      this.forceSend(this.queue.shift())
    }
  }

  send(data) {

    if (!this.isReady || this.flushingQueue) {
      this.queue.push(data)
      return
    }

    this.forceSend(data)

  }

  forceSend(data, cmd = 'message') {

    if (!data) {
      return
    }

    data.user = this.user
    data.mid = this.createGuid()
    this.logger({ action: cmd, ...data }, 'out')

    if (this.socket) {
      this.socket.emit(cmd, data)
    }
  }

  on(evt, cb) {
    if (this.socket) {
      this.socket.on(evt, (data) => {
        this.logger(data || {}, 'in')
        cb(data)
      })
    }
  }

  watch(evt, cb) {
    this.on('message', (data) => {
      if (data.action === evt) {
        cb(data.data)
      }
    })

  }

  /**
   * @private
   */
  ready(data) {
    this.forceSend(data, 'ready')
  }

  /**
   * @private
   */
  setRoom(roomId) {
    this.forceSend(roomId, 'room')
  }

  action(action, data = {}) {
    if (!action) {
      return
    }
    this.send({ action, ...data })
  }

  lock(type, id, force = false) {
    this.action('lock', { document: { type, id }, force })
    this.setRoom({ room: id })
  }

  release(type, id) {
    this.action('release', { document: { type, id } })
  }

  message(message, recipient) {

    const data = {
      value: message,
      recipient
    }

    this.chatStore.addMessage({
      ...data,
      user: this.user
    })

    this.action('message', data)

  }


}
