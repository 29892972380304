import HTMLJanitor from './html-janitor'
import { merge, cloneDeep } from 'lodash'

/**
 * This plugin adds the ability to sanitize content when it is pasted into the
 * scribe, adhering to a whitelist of allowed tags and attributes.
 */
// FIX: added name to function
export default function htmlJanitorFormatter (config) {
  // We extend the config to let through (1) Scribe position markers,
  // otherwise we lose the caret position when running the Scribe content
  // through this sanitizer, and (2) BR elements which are needed by the
  // browser to ensure elements are selectable.
  const configAllowMarkers = merge(cloneDeep(config), {
    tags: {
      em: {
        class: 'scribe-marker',
        // FIX: added style attribute
        style: 'display: none;'
      },
      br: {}
    }
  });

  return function (scribe) {
    var janitor = new HTMLJanitor(configAllowMarkers);

    scribe.registerHTMLFormatter('sanitize', janitor.clean.bind(janitor));
  };
};
