import Command from '../command'
import { articleStore } from '../../article/reducers'
import { widgetStore } from '../../widget/reducers'
import { deepGet } from '../obj'
import { editorVersion } from '../../config'

const contentStores = {
  article: articleStore,
  widget: widgetStore
}

export default
class CreateContentCommand extends Command {

  /**
   * Creates a new CreateContentCommand. This command handles the content
   * creation based on a content type and template.
   * @param {Object} data - The spec of the content that should be created.
   * @param {string} data.type - The type of the content. One of `article` or
   * `widget`.
   * @param {string} [data.template] - The name of the template that should be
   * created.
   * @param {Object} [data.article] - The actual existing article.
   * @param {Object} [data.article.id] - The id of the article. This is required
   * if `data.article` is used!
   * @param {Object} [data.widget] - The actual existing widget.
   * @param {Object} [data.widget.id] - The id of the widget. This is required
   * if `data.widget` is used!
   */
  constructor(data: Object) {
    super(data)
    this.data = data
  }

  /**
   * Validates the given input data.
   * @method validate
   * @param  {Object} data - The data to be validated.
   * @return {String}      - The result of validation.
   */
  validate(data) {
    if (!data.type) {
      return `Expected \`data.type\` to be present. ${data.type} given.`
    }
    if (!data.article && !data.template) {
      // eslint-disable-next-line max-len
      return `Expected \`data.template\` or \`data.article\` to be present. ${data.template || data.article} given.`
    }
    return null
  }

  /**
   * Executes the create command. Determines the template that needs to be
   * created and creates it. Then either uses an existing content, or creates
   * the content anew. Finally returns a promise that will resolve with the
   * newly created content.
   * @method exec
   * @return {Promise<Object>} - The promise resolving with the created content
   */
  exec() {

    const {
      type, template
    } = this.data

    let promise = null
    const content = this.data[type]

    const contentStore = contentStores[type]

    if (template) {
      promise = contentStore.create({
        templateId: template.id,
        templateName: template.name,
        editorVersion: editorVersion || deepGet(template, 'config.editorVersion')
      })
    }
    else if (content) {
      const item = contentStore.getById(content.id)
      if (item.complete) {
        return Promise.resolve(item)
      }
      promise = item.load()
    }

    return promise

  }

}
