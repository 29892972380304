import { observable, computed, action, transaction } from 'mobx'
import * as config from '../config'
import { inspector } from '../shared/decorators/inspector'
import Model from '../shared/model'
import { Store } from '../shared/store'
import { apiClient } from '../shared/api'
import { store as uiStore } from '../ui'
import { deepGet } from '../shared/obj'
import * as TemplateLoader from '../shared/template/loader'
import { addToCollection } from '../shared/utils'

class Widget extends Model {
  // Put all widget properties here.
  @observable name = '';

  @observable module = null;

  @observable shortcut = null;

  @observable templateType = 'widget';

  @observable config = null;

  get moduleLoaded() {
    return this.module
  }

  set(data, opts = {}) {
    super.set(data, opts)
    if (opts.action === 'load') {
      this.synced = true
    }
    this.name
      = deepGet(data, `i18n.${uiStore.language}.name`)
      || deepGet(data, 'i18n.de.name')
      || ''
  }

  @computed get asJSON() {
    return {
      id: this.id,
      name: this.name,
    }
  }

  /**
   * This may be used internally to reset the loaded module to force re-loading
   * the source code for this widget the next time it is used. This is handy
   * at development.
   */
  resetModule() {
    transaction(() => {
      this.shortcut = null
      this.module = null
      this.moduleFile = null
    })
  }
}

@apiClient
@inspector('loadTemplateModule', 'widget.loading')
class WidgetTemplateStore extends Store {
  // Put all widget list properties here. This regargds also state properties.
  @observable collection = [];

  @observable didCompleteLoad = false;

  @observable current = {};
  //  @observable currentState = observable(asMap()) - mobx update - delete when no problems occur

  @observable alwaysReloadStyleSheets = false; // !config.isProduction || false

  @observable loading = false;

  // Put all properties that are not to be observed here:

  constructor(opts = {}) {
    super(opts)

    this.Model = Widget

  }

  load(id, opts = {}) {
    if (id) {
      return this.dispatch('get', {
        path: `widgetTemplate/${id}`
      }).then((response) => {
        if (!response || !response.body || response.error) {
          throw new Error('error in page get')
        }
        return addToCollection(this, this.collection, response.body.data, Widget)
      })
    }
    // if no id, the load all
    return this.dispatch('get', {
      path: 'widgetTemplate'
    }).then((response) => {
      if (!response || !response.body || response.error) {
        throw new Error('error in page get')
      }
      response.body.data.forEach((template) => {
        addToCollection(this, this.collection, template, Widget)
      })
      this.didCompleteLoad = true
      return true
    })
  }


  openTemplateModuleByShortcut(shortcut) {
    this.loadTemplateModule(shortcut)
  }

  loadTemplateModule(shortcut) {
    const templateLoader = TemplateLoader.getInstance(
      this.collection,
      uiStore.language,
      config.widgetTemplateBasePath
    )

    return templateLoader.loadTemplateByShortcut(shortcut, config)
  }

  getLoadedTemplateById(id) {
    const widget = this.getById(id)

    if (widget && widget.module && widget.moduleLoaded) {
      return Promise.resolve(widget)
    }

    let promise = Promise.resolve(widget)

    if (!(widget && widget.module)) {
      promise = this.load(id, { force: true })
    }

    return promise
      .then((model) => {
        if (!model) {
          // eslint-disable-next-line
          throw new Error(
            `The Widget module file with the id \`${id}\` (${widget.name}) could not be loaded`
          )
        }
        return model
      })
      .then(tmpl => this.loadTemplateModule(tmpl.shortcut))
      .then(() => widget)
  }

  @action
  actionResetModule(id) {
    const template = id !== undefined ? this.getById(id) : this.current

    if (template) {
      const templateLoader = TemplateLoader.getInstance(
        this.collection,
        uiStore.language,
        config.widgetTemplateBasePath
      )
      templateLoader.removeTemplateByShortcut(template.shortcut)

      template.resetModule()
    }
  }
}

export const widgetTemplateStore = new WidgetTemplateStore()
