import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { autobind } from 'core-decorators'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import { TreeNode } from 'draggable-react-tree-component'
import PerfectScrollbar from 'perfect-scrollbar'
import { FormattedMessage } from '../../translations'

import Dropdown, { Entry } from '../../shared/components/Dropdown'
import GenevaButton from '../../ui/components/GenevaButton'
import GenevaDropdownOpener from '../../ui/components/GenevaDropdownOpener'
import Icon from '../../shared/icons'

import ProjectSelect from './ProjectSelect'
import PageTreeContainer from './Tree'
import BranchSelect from '../containers/Tree/BranchSelect'
import {
  convertDateToUserLocal
} from '../../shared/DateConverter'

const css = typeof window === 'undefined' ? {} : require('../styles.scss')

@observer
class PublicationManagerDisplay extends Component {

  static propTypes = {

    project: PropTypes.object.isRequired,
    pageStore: PropTypes.object.isRequired,
    specialPageTreeChildren: PropTypes.object,
    pageTreeChildren: PropTypes.object,

    checkedKeys: PropTypes.array.isRequired,
    checkedKeysLength: PropTypes.number.isRequired,
    expandedKeys: PropTypes.array.isRequired,

    checkAllowedActions: PropTypes.func.isRequired,
    allowDelete: PropTypes.bool.isRequired,
    allowArchive: PropTypes.bool.isRequired,
    allowPublish: PropTypes.bool.isRequired,
    allowUnpublish: PropTypes.bool.isRequired,

    onCheck: PropTypes.func.isRequired,
    dropdownIndicatorSet: PropTypes.object.isRequired,

    handleSelectNoneButton: PropTypes.func.isRequired,
    handleSelectAllButton: PropTypes.func.isRequired,
    handlePublishPage: PropTypes.func.isRequired,
    handleOfflinePage: PropTypes.func.isRequired,
    handleExpand: PropTypes.func.isRequired,
    handleBranchSelection: PropTypes.func,
    handleBranchDeselection: PropTypes.func,
  }

  constructor(props) {
    super(props)

    this.ps = null
  }

  componentDidMount() {
    this.ps = new PerfectScrollbar('#normal-tree', {
      suppressScrollX: true
    })
  }

  renderDetails(item) {
    // order: blocked, already published, never published

    if (item.publicationNotAllowed) {
      // for some reasons not allowed to publish
      return (
        <div className="dates blocked">
          <Icon name="ion-ios-locked-outline" />
          <FormattedMessage id="publicationTree-blocked" />
        </div>
      )
    }

    if (item.publishAt) {

      // future publishing
      return (
        <div className="dates delayed-pub">
          <Icon name="ion-ios-clock-outline" />
          {`${convertDateToUserLocal(item.publishAt).format('L')}`}
          {` ${convertDateToUserLocal(item.publishAt).format('LT')}`}
        </div>
      )
    }

    if (item.publishedUrl) {
      if (!item.hasChanged) {

        // already published and no new versions
        return (
          <div className="dates">
            <Icon name="ion-ios-checkmark-outline" />
            {`${convertDateToUserLocal(item.updatedAt).format('L')} | `}
            <FormattedMessage id="publicationTree-no-change" />
          </div>
        )
      }

      // a new version that could be published
      return (
        <div className="dates new-version">
          <Icon name="ion-ios-refresh-outline" />
          {`${convertDateToUserLocal(item.updatedAt).format('L')} | `}
          <FormattedMessage id="publicationTree-new-change" />
        </div>
      )
    }

    // page offline but previously published
    if (item.lastUnpublishedAt) {
      return (
        <div className="dates new-version">
          <Icon name="ion-ios-cloud-upload-outline" />
          <FormattedMessage id="publicationTree-previously-uploaded" />
          {` ${convertDateToUserLocal(item.lastUnpublishedAt).format('L')}`}
        </div>
      )
    }
    // a new page never pushed or offline
    return (
      <div className="dates new-version">
        <Icon name="ion-ios-cloud-upload-outline" />
        <FormattedMessage id="publicationTree-not-uploaded" />
      </div>
    )
  }

  @autobind
  renderItems(items) {

    return items.map(item => (
      <TreeNode
        basePadding={14}
        key={item.id}
        items={(item.sub && item.sub.length) ? this.renderItems(item.sub) : null}
        selected={item.isActive}
        className={classNames('node')}
      >
        <div className={classNames('name', (item.isActive ? 'active' : ''))}>
          {item.navigationName || item.name}
          <BranchSelect
            item={item}
            dropdownIndicator={this.props.dropdownIndicatorSet[item.id]}
            handleBranchSelection={this.props.handleBranchSelection}
            handleBranchDeselection={this.props.handleBranchDeselection}
            pageStore={this.props.pageStore}
          />

        </div>
        {this.renderDetails(item)}
        <hr />

      </TreeNode>
    ))

  }

  renderPageTree() {
    if (!(this.props.pageTreeChildren
      && this.props.pageTreeChildren.length > 0)
    ) {
      return (<FormattedMessage
        id="page-tree-not-found"
      />)
    }
    return (
      <div>
        <hr className="full-hr" />
        <div className="page-type-desc">
          <FormattedMessage id="project.navigation.normal-pages" />
        </div>
        <hr className="full-hr" />
        <PageTreeContainer
          className="publist normal-tree"
          expandedKeys={this.props.expandedKeys}
          checkedKeys={this.props.checkedKeys}
          page={this.props.pageStore}
          project={this.props.project}
          onExpand={this.props.handleExpand}
          onCheck={this.props.onCheck}
          onchange={this.props.onCheck}
          renderItems={this.renderItems}
          autoExpandParent
          checkStrictly
          checkable
          treeChildren={this.props.project.pageTree.children}
        />
      </div>
    )
  }

  renderSpecialTree() {
    if (!(this.props.specialPageTreeChildren
    && this.props.specialPageTreeChildren.length > 0)
    ) {
      return (
        <span> </span>
      )
    }
    return (
      <div>
        <div className="page-type-desc">
          <FormattedMessage id="project.navigation.special-pages" />
        </div>
        <hr className="full-hr" />
        <PageTreeContainer
          className="publist special-tree"
          expandedKeys={this.props.expandedKeys}
          checkedKeys={this.props.checkedKeys}
          page={this.props.pageStore}
          project={this.props.project}
          onExpand={this.props.handleExpand}
          onCheck={this.props.onCheck}
          onchange={this.props.onCheck}
          renderItems={this.renderItems}
          autoExpandParent
          checkStrictly
          checkable
          treeChildren={this.props.project.specialPageTree.children}
        />
      </div>
    )
  }

  render() {

    if (this.ps) {
      this.ps.update()
    }

    this.props.checkAllowedActions()

    return (

      <div className={classNames('vertical grid-block', css.publicationTree)}>
        <div className="tree-options-bar v-align">
          <ProjectSelect
            currentProject={this.props.project}
          />
        </div>
        <div className="header-controls grid-block v-align">
          <div className="selectors">
            <GenevaButton
              id="selectAll"
              name="selectedAll"
              onClick={this.props.handleSelectAllButton}
            >
              <FormattedMessage id="publicationTree-select-all" />
            </GenevaButton>
            <GenevaButton
              id="selectNone"
              name="selectedNone"
              onClick={this.props.handleSelectNoneButton}
            >
              <FormattedMessage id="publicationTree-select-none" />
            </GenevaButton>
            <div className="select-info">
              {this.props.checkedKeysLength}
              <FormattedMessage id="publicationTree-count" />
            </div>
          </div>
          <div className="option-buttons">

            <GenevaButton
              type="submit"
              id="delete"
              className="small button align-right"
              onClick={this.props.handleDeletePage}
              disabled={!this.props.allowDelete}
            >
              <FormattedMessage id="page.delete" />
            </GenevaButton>
            <GenevaButton
              type="submit"
              id="archive"
              className="small button align-right"
              onClick={this.props.handleArchivePage}
              disabled={!this.props.allowArchive}
            >
              <FormattedMessage id="page.archive" />
            </GenevaButton>

            <GenevaButton
              type="submit"
              id="offline"
              className="small button align-right"
              onClick={this.props.handleOfflinePage}
              disabled={!this.props.allowUnpublish}
            >
              <FormattedMessage id="page.take-offline" />
            </GenevaButton>
            <GenevaButton
              type="submit"
              id="publish"
              className="small button align-right"
              onClick={this.props.handlePublishPage}
              disabled={!this.props.allowPublish}
            >
              <FormattedMessage id="page.release" />
            </GenevaButton>
          </div>
        </div>

        <div
          className="grid-block vertical tree-block"
          id="normal-tree"
        >
          {this.renderPageTree()}
          {this.renderSpecialTree()}
        </div>

      </div>
    )
  }
}

export default PublicationManagerDisplay
