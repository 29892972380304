import PropTypes from 'prop-types'
import React from 'react'
import { withRouter } from 'react-router'
import * as css from '../dev-styles.scss'

const DevArticleTools = withRouter(({
  article: { current },
}) => {
  // The template.shortcut causes problems when loading versions
  if (!current.isVersion) {
    return (<div className={css.articleDevTools}>
      <span>Template: {current.template.shortcut} (created: {current.createdAt})</span>

    </div>)
  }
  return null

})


DevArticleTools.propTypes = {
  article: PropTypes.shape({
    current: PropTypes.shape({
      id: PropTypes.number
    }),
    openById: PropTypes.func,
    router: PropTypes.shape({
      push: PropTypes.func
    })
  })
}


// <button
//   className="tiny button"
//   onClick={() => {
//
//     router.push(window.location.pathname
//       .replace(
//         /article\/\d+/, `article/${current.id + 1}`
//       )
//     )
//   }}
// >
//   Next &gt;
// </button>

export { DevArticleTools as default }
