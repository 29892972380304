import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import classNames from 'classnames'
import { autobind } from 'core-decorators'
import Icon from '../../icons'
import DropdownOpener from './DropdownOpener'
import EntryWrapper from './EntryWrapper'
import DefaultEntryLink from './DefaultEntryLink'

export default class Entry extends Component {
  static propTypes = {
    id: PropTypes.number,
    identifier: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    children: PropTypes.node,

    disabled: PropTypes.bool,
    checked: PropTypes.bool,

    onClick: PropTypes.func,

    component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  };

  static defaultProps = {
    children: 'DropdownEntry',
    component: DefaultEntryLink,
  };

  @autobind
  handleClick() {
    if (this.props.onClick) {
      this.props.onClick({
        target: {
          value: this.props.identifier,
          identifier: this.props.identifier,
          id: this.props.id,
        },
      })
    }
  }

  render() {
    const { children, checked, disabled } = this.props
    const child = Array.isArray(children) ? children[0] : children

    // React doc says: if only one child is passed to a component
    // then the children prop will be a single object.
    if (child && child.type === DropdownOpener) {
      return <EntryWrapper {...this.props}>{children}</EntryWrapper>
    }

    const Comp = this.props.component

    const propsClone = { ...this.props }
    delete propsClone.component

    const events = {}

    if (propsClone.onClick) {
      events.onMouseDown = this.handleClick
      delete propsClone.onClick
    }

    return (
      <EntryWrapper {...propsClone}>
        <Comp
          {...propsClone}
          {...events}
          className={classNames({ disabled }, 'dropdown-entry')}
        >
          {checked ? <Icon name="ion-checkmark" /> : null}
          {children}
        </Comp>
      </EntryWrapper>
    )
  }
}
