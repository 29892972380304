import PropTypes from 'prop-types';
import React from 'react';
import { observer } from 'mobx-react'
import { toJS } from 'mobx'

import Dropdown, {
  DropdownContainer,
  Entry,
  Separator
} from '../../../../shared/components/Dropdown'
import Icon from '../../../../shared/icons'
import GenevaDropdownOpener from '../../../../ui/components/GenevaDropdownOpener'
import GenevaButton from '../../../../ui/components/GenevaButton'

import defaultButtonState from './defaultButtonState'
import getButtonProps from './getButtonProps'
import ObservableEntry from './ObservableEntry'
import ValueEntry from './ValueEntry'

const ToolbarButton = observer((props/* : Props */) => {

  const {
    buttonSpec,
  } = props

  const state = toJS(buttonSpec) || defaultButtonState

  let buttonProps

  if (state.subCommands) {
    buttonProps = getButtonProps(state)

    // Search over the main command and all subcommands if any of
    // them is enabled. If so, enable this opener as well.
    const disabled = ![
      state,
      ...state.subCommands.filter(cmd => typeof cmd !== 'string')
    ]
    .find(cmd => 'disabled' in cmd && !cmd.disabled)

    return <DropdownContainer
      key={buttonProps.key}
    >

      {/*
      Apply the button props so the button get's the correct data-command-*
      attributes. This will allow setting the proper context state.
       */}
      <GenevaDropdownOpener
        clickToClose
        disabled={disabled}
      >
        {state.icon
          ? <Icon name={state.icon} />
          : buttonProps.title
        }
      </GenevaDropdownOpener>

      <Dropdown>

        <Entry {...buttonProps}>
          {buttonProps.title}
        </Entry>

        <Separator />

        {state.subCommands.map((state, i) => {

          if (state === 'separator') {
            return <Separator key={`${buttonProps.key}-separator-${i}`} />
          }

          const ButtonComp = state.values
          ? ValueEntry
          : ObservableEntry

          return <ButtonComp
            key={state.cmd}
            buttonSpec={state}
          />

        })}

      </Dropdown>

    </DropdownContainer>
  }

  buttonProps = getButtonProps(
    state,
    `${props.className || ''} small plain button`
  )

  return (<GenevaButton
    {...buttonProps}
    style={props.style}
  >
    {
      state.icon
      ? <Icon name={state.icon} />
      : props.children || buttonProps.title
    }
  </GenevaButton>)

})

ToolbarButton.propTypes = {
  className: PropTypes.string,
  buttonSpec: PropTypes.object.isRequired
}

export default ToolbarButton
