import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import { autobind } from 'core-decorators'
import { difference } from 'lodash'

import { dispatcher } from '../../shared/lib/command'
import ContentLoadingBox from '../../shared/components/ContentLoadingBox'

import ReviewCenterChat from './ReviewCenterChat'
import ReviewCenterUserList from './ReviewCenterUserList'
import ReviewCenterUsers from './ReviewCenterUsers'

const css = require('../styles.scss')

@dispatcher
@observer
export default class ReviewCenter extends Component {
  static propTypes = {
    context: PropTypes.object,
    page: PropTypes.object.isRequired,
    activeTabs: PropTypes.array,
    handleTabChange: PropTypes.func,
    reviewStore: PropTypes.object.isRequired, // from connector
    authStore: PropTypes.object.isRequired, // from connector
  };

  constructor(props) {
    super(props)

    const reviewModel
      = props.reviewStore.current.id === props.page.id
        ? props.reviewStore.current
        : null

    this.state = {
      view: 'chat', // options are: 'chat', 'settings'
      showUserList: false,
      reviewModel,
      isLoading: !reviewModel,
    }

    this.prevItems = []
    this.nameList = []
  }

  componentDidMount() {
    const { reviewStore, page } = this.props

    // Create the index list of already opened items
    this.props.activeTabs.forEach((tab) => {
      const index = this.nameList.indexOf(tab)
      if (index !== -1) {
        this.prevItems.push(index)
      }
    })

    if (this.state.isLoading) {
      // reviewModelId is the same as the pageId
      reviewStore.load(page.id).then((reviewModel) => {
        // if no users are added, start on user tab
        this.setState({
          view: reviewModel.users.length > 0 ? 'chat' : 'settings',
          reviewModel,
          isLoading: false,
        })
      })
      reviewStore.loadUsers()
    }
  }

  @autobind
  updateUser(data) {
    const { reviewStore, router } = this.props

    this.context.dispatch(this.props.commands.UpdateUserCommand, {
      reviewStore,
      router,
      userId: data.id,
      data,
    })

    if (data.message && data.message !== '') {
      // special case for invite, action 3 = invite
      this.sendAnswer(data.message, {
        action: 3,
        targetId: data.id,
        target: data.target,
      })
    }
  }

  @autobind
  addUserToChat(users) {
    const { reviewStore, router } = this.props

    this.context.dispatch(this.props.commands.AddUserToChatCommand, {
      reviewStore,
      router,
      users,
    })
  }

  @autobind
  sendAnswer(text, opt = {}) {
    const { reviewStore, router } = this.props

    this.context.dispatch(this.props.commands.AddChatMessageCommand, {
      reviewStore,
      router,
      text,
      action: opt.action,
      targetId: opt.targetId,
      targetName: opt.target,
    })
  }

  @autobind
  handleTabChange(e) {
    // find the index that was removed or added from the accordion list
    let diff = difference(e.activeItems, this.prevItems)
    if (diff.length === 0) {
      diff = difference(this.prevItems, e.activeItems)
    }
    this.prevItems = e.activeItems

    // notify parent component to update the state
    this.props.handleTabChange(this.nameList[diff[0]])
  }

  @autobind
  handleViewSwitch() {
    if (this.state.view === 'chat') {
      this.setState({
        view: 'settings',
      })
    }
    else {
      this.setState({
        view: 'chat',
      })
    }
  }

  @autobind
  toggleUserList() {
    this.setState(prevState => ({
      showUserList: !prevState.showUserList,
    }))
  }

  renderSubView() {
    const { reviewModel } = this.state
    const { authStore, reviewStore } = this.props

    if (!this.state.showUserList) {
      return (
        <ReviewCenterUsers
          reviewModel={reviewModel}
          users={reviewModel.users}
          handleViewSwitch={this.handleViewSwitch}
          toggleUserList={this.toggleUserList}
          updateUser={this.updateUser}
        />
      )
    }
    return (
      <ReviewCenterUserList
        authStore={authStore}
        reviewStore={reviewStore}
        reviewModel={reviewModel}
        toggleUserList={this.toggleUserList}
        addUserToChat={this.addUserToChat}
      />
    )
  }

  render() {
    const { view, reviewModel, isLoading } = this.state

    if (isLoading) {
      return <ContentLoadingBox spinner />
    }

    return (
      <div
        className={classNames(
          'grid-block',
          view === 'chat' ? css.rcChat : css.rcSettings,
          css.reviewCenter
        )}
        id="rc-chat"
      >
        <ReviewCenterChat
          reviewModel={reviewModel}
          handleViewSwitch={this.handleViewSwitch}
          sendAnswer={this.sendAnswer}
        />
        {this.renderSubView()}
      </div>
    )
  }
}
