import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import { autobind } from 'core-decorators'
import DisconnectArticlePlaceholderCommand from '../commands/disconnect-placeholder'
import RemoveArticlePlaceholderCommand from '../commands/remove-placeholder'
import UpdateArticlePlaceholderCommand from '../commands/update-placeholder'
import RemoveImageFromArticlePlaceholderCommand from '../../shared/commands/remove-image-from-article-placeholder'
import { store as contextStore } from '../../context'
import { store as articleStore } from '../../article'
import { censhareStore } from '../reducer'

export default function connectCenshareToolsToCommands(Target) {
  class ConnectedTarget extends Component {
    static propTypes = {
      value: PropTypes.string,
      spec: PropTypes.object,
      context: PropTypes.object,
    };

    getPlaceholderDataFromContext() {
      return {
        type: contextStore.type,
        article: articleStore.current,
        pid: contextStore.target.pid,
      }
    }

    @autobind
    handleDisconnect(type) {
      const placeholderData = this.getPlaceholderDataFromContext()
      return new DisconnectArticlePlaceholderCommand(placeholderData, type).exec()
    }

    @autobind
    handleUpdateArticle(opts = {}) {
      const placeholderData = this.getPlaceholderDataFromContext()
      return new UpdateArticlePlaceholderCommand({
        ...placeholderData,
        ...opts
      }, censhareStore).exec()
    }

    @autobind
    handleRemove() {
      const placeholderData = this.getPlaceholderDataFromContext()
      return new RemoveArticlePlaceholderCommand(placeholderData).exec()
    }

    render() {

      return (<Target

        {...this.props}

        onDisconnect={this.handleDisconnect}
        onRemove={this.handleRemove}
        onUpdateArticle={this.handleUpdateArticle}
      />)
    }
  }

  return ConnectedTarget
}
