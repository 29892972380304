import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Link } from 'react-router'
import { observer } from 'mobx-react'

import { FormattedMessage } from '../../translations'
import {
  getUserNameById
} from '../../shared/utils'

@observer
export default class ListItem extends Component {

  static propTypes = {
    article: PropTypes.object.isRequired,
    statusDisplay: PropTypes.bool,
    makeLinkable: PropTypes.bool,
    languageDisplay: PropTypes.bool,
  }

  renderLanguage() {
    const { article, languageDisplay } = this.props
    if (languageDisplay) {
      return (<span className="article-language">
        {article.createdIso}
      </span>)
    }
    return null
  }

  renderStatus() {
    const { article, statusDisplay } = this.props
    if (statusDisplay) {
      return (<span className="article-status badge float-right">
        <FormattedMessage
          id={`status.${article.statusName}`/* article status removed*/}
        />
      </span>)
    }

    return null

  }

  renderBody() {
    const { article } = this.props

    return (<div>
      <div className="element-list-item-meta">

        <span className="article-date">
          {}
          {/* <FormattedDate
            value={article.createdAt}
            year="2-digit"
            month="2-digit"
            day="2-digit"
          /> */}
          {/* <FormattedRelative
            value={new Date(article.createdAt).getTime()}
          /> */}
        </span>

        {this.renderStatus()}

        {this.renderLanguage()}

      </div>


      <div className="element-list-item-body">
        <h4 className="element-list-item-heading line-fade">{
          article.name
        }
        </h4>
      </div>


      <div className="element-list-item-meta">

        <span className="article-creator">

          <i className="ion ion-person"></i>&nbsp;{getUserNameById(article.createdBy)}

        </span>

        <span className="article-template">
          <i className="ion ion-document-text"></i>&nbsp;{article.templateName}

        </span>
      </div>
    </div>)
  }

  render() {
    const { article, active, makeLinkable } = this.props

    if (makeLinkable) {
      return (<li
        key={article.id}
        className={`element-list-item ${active ? 'active' : ''}`}
      >
        <Link to={`/article/${article.id}`}
          className={`${active ? 'active' : ''}`}
        >
          {this.renderBody()}
        </Link>
      </li>)
    }

    return (<li
      key={article.id}
      className={`element-list-item ${active ? 'active' : ''}`}
    >
      {this.renderBody()}
    </li>)
  }
}
