import superagent from 'superagent'
import config from '../../../config'

export function getBaseUrl() {
  let port = config.apiVersions.v2.apiPort
  port = port && port * 1 !== 80 ? `:${port}` : ''

  const basePath = config.apiVersions.v2.apiBasePath
  const apiHost = config.apiVersions.v2.apiHost

  // Prepend host and port of the API server to the path.
  return `${config.apiScheme}//${apiHost + port}/${(basePath)
    .replace('//', '/')}`.replace(/\/$/, '')
}

export function apiClient(target) {
  const provider = target.prototype || target

  /**
   * @param {String} method - get, post, put, del
   * @param {Object} content - properties: path, data, params (url params)
   * @returns Object or erro
   */
  provider.dispatch = function dispatch(method, content) {
    // verify path
    if (!content || !content.path) {
      throw console.error('missing path in api call')
    }
    else if (!method) {
      throw console.error('missing method in api call')
    }

    else {
      const baseUrl = getBaseUrl()
      const customRequest = superagent[method](`${baseUrl}/${content.path}`)
        .set('accept', 'json')
        .withCredentials()

      if (content.params) {
        customRequest.query(content.params)
      }
      if (content.data) {
        customRequest.send(content.data)
      }
      if (content.progress) {
        customRequest.on('progress', content.progress)
      }
      return customRequest.catch((err) => {
        // if a 'stub' is included, then it's for testing and the stub can be returned
        if (content.stub) {
          return content.stub
        }
        // If unauthorized, redirect to AM
        if (err.status === 401) {
          const destination = config && config.am && config.am.url
          let ending = ''
          if (window.location.pathname) {
            ending = `?redirectUrl=${encodeURIComponent(window.location)}`
          }

          window.location.href = `${destination}${ending}`
        }

        throw err.response && err.response.body
      })
    }
  }
}
