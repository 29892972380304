/**
 * @description
 * This module exports the root component. This component is the container for
 * all following components. All wrappers that should be applied to all
 * following components should be placed here. See the Router below for example.
 */
import PropTypes from 'prop-types'

import React, { Component } from 'react'
import { Router } from 'react-router'
import DevTools from 'mobx-react-devtools'
import { isProduction } from '../../config'


/**
 * This higher order component helps with passing props to their child
 * component.
 * @see
 * https://github.com/reactjs/react-router/issues/615#issuecomment-200143467
 * @param {Object} parentProps The object of parant properties that should be
 * passed to the children.
 */
function createElementFn(parentProps) {

  return (ChildComponent, props) => (
    <ChildComponent {...parentProps} {...props} />
  )

}

export default class Root extends Component {

  static propTypes = {
    history: PropTypes.object.isRequired,
    devRoutes: PropTypes.element,
    routes: PropTypes.element.isRequired,
    store: PropTypes.object.isRequired
  }

  /**
   * This getter returns a router with all it's routes. It ensures that
   * whenever the router creates one of its Components, they automatically get
   * passed the store.
   */
  renderContent() {

    return (
      <Router history={this.props.history}
        createElement={createElementFn(this.props.store)}
      >
        {this.props.devRoutes}
        {this.props.routes}
      </Router>
    )

  }

  render() {

    return (
      <div style={{ height: '100%' }}>
        {this.renderContent()}
        {isProduction ? null : <DevTools
          position={{ bottom: 0, right: 40 }}
        />}
      </div>
    )

  }

}
