import { transaction } from 'mobx'

export default class RemoveFromPageCommand {

  constructor(commandData) {
    this.pageModel = commandData.pageModel
    this.data = commandData.data
  }

  exec() {

    return transaction(() => {

      const gridBlock = this.pageModel.getGridBlock(this.data)

      if (!gridBlock) {
        // eslint-disable-next-line max-len
        throw new Error(`Page ${this.pageModel.name} has no grid block at gr${this.data.gr}.gb${this.data.gb}`)
      }

      gridBlock.remove(this.data)

      return this.pageModel.save()

    })

  }


}
