import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { autobind, debounce } from 'core-decorators'
import { autorun } from 'mobx'
import classNames from 'classnames'

import { i18n, testClass } from '../../shared/utils'
import Ion from '../../shared/icons/ion'
import { hasPermission } from '../../shared/utils/user-rights'
import { PAGE_TYPE_ARCHIVE_PAGE, PAGE_TYPE_ARCHIVE_SPECIAL_PAGE } from '../../shared/const'

const css = typeof window === 'undefined' ? {} : require('../styles.scss')

export default class PagePublishState extends Component {

  static propTypes = {
    page: PropTypes.object.isRequired,
    pageName: PropTypes.string.isRequired
  }

  constructor(props) {
    super(props)

    this.isPublishing = false
    this.permission = hasPermission('publishPage')
  }

  componentDidMount() {
    this.handlerPublishState = autorun(() => {
      if (this.props.page.hasCurrent) {
        this.updatePublishState(this.props.page.current)
      }
    })

    this.handlerPageType = autorun(() => {
      if (this.props.page.hasCurrent && this.props.page.current.type) {
        this.updatePublishState(this.props.page.current)
      }
    })
  }

  componentWillUnmount() {
    this.handlerPublishState()
    this.handlerPageType()
  }

  updatePublishState(currentPage) {

    const publishAt = currentPage.publishAt
    const unpublishAt = currentPage.unpublishAt
    const isPublished = !!currentPage.publishedUrl
    const hasChanged = currentPage.hasChanged

    // Show lock and signal that the page can not be published
    if (currentPage.publicationNotAllowed) {
      this.setState({
        publishState: 'isLocked',
        icon: 'ios-locked-outline'
      })
    }

    // Show clock and signal that the page in currently in queue
    else if (publishAt || unpublishAt) {
      this.setState({
        publishState: 'willBePublished',
        icon: 'ios-clock-outline'
      })
    }

    // Show arrow and signal that the page can be published
    else if (!isPublished || hasChanged) {
      this.setState({
        publishState: 'canBePublished',
        icon: 'ios-arrow-thin-up'
      })
    }

    // Show checkmark and signal that the page is published and not changed
    else if (isPublished && !hasChanged) {
      this.setState({
        publishState: 'isPublished',
        icon: 'ios-checkmark-outline'
      })
    }
  }

  @autobind
  @debounce(500)
  handleClick() {

    // Bail out of no permission to publish
    if (!this.permission) {
      return
    }

    const page = this.props.page.current

    if (this.state.publishState === 'canBePublished' && !this.isPublishing) {

      this.isPublishing = true

      this.props.page.publish(page, {
        publishFollowing: false
      }, {
        action: 'publish',
        id: page.id
      }).then(() => {
        this.isPublishing = false
      })
    }
  }

  render() {

    const { page } = this.props

    return (
      <div className={classNames(
        css.pagePublishState,
        'v-align',
        testClass('page-published-state'))}>
        {
          this.props.pageName
        }
        { // Only show when page is not archived
          page.current.type !== PAGE_TYPE_ARCHIVE_PAGE
        && page.current.type !== PAGE_TYPE_ARCHIVE_SPECIAL_PAGE
            ? <a className={!this.permission ? 'disabled' : null} onClick={this.handleClick}>
              {
                this.state
                  ? <div className={this.state.icon}>
                    <Ion name={this.state.icon} />
                  </div>
                  : null
              }
            </a>
            : null
        }
      </div>

    )
  }
}
