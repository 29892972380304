import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { confirmable } from 'react-confirm'
import { autobind } from 'core-decorators'
import DateTime from 'react-datetime'
import moment from 'moment'
import { formatMessage, FormattedMessage } from '../../../translations'

import { Dialog } from '../../../shared/components/Dialog'
import {
  convertDateToUserLocal,
  convertDateToSystemLocal
} from '../../../shared/DateConverter'

const css = /* typeof window === 'undefined' ? {} : */require('../../styles.scss')

@confirmable
class ArticleSecurity extends Component {

  static propTypes = {
    show: PropTypes.bool,            // indicates if the dialog is shown or not.
    proceed: PropTypes.func,         // call to close the dialog with promise resolved.
    cancel: PropTypes.func,          // call to close the dialog with promise rejected.
    dismiss: PropTypes.func,         // call to only close the dialog.
    confirmation: PropTypes.string,  // arguments of your confirm function
    options: PropTypes.object        // arguments of your confirm function
  }

  constructor(props) {
    super(props)

    const date = convertDateToUserLocal(props.options.filtered.releasePublicationLockAt)
    const publicationNotAllowed = this.props.options.filtered.publicationNotAllowed

    this.state = {
      ...props.options.filtered,
      releasePublicationLockAt: publicationNotAllowed ? date : null,
      releasePublicationLockAtActive: publicationNotAllowed ? !!date : false,
      publicationNotAllowed,
      isValidDate: true,
    }
  }

  @autobind
  handleCommit(e) {
    e.preventDefault()
    e.stopPropagation()
    const data = {
      publicationNotAllowed: this.state.publicationNotAllowed,
      releasePublicationLockAt: null
    }

    if (this.state.releasePublicationLockAtActive && this.state.releasePublicationLockAt) {
      data.releasePublicationLockAt = convertDateToSystemLocal(
        this.state.releasePublicationLockAt,
        { simplified: true }
      )
    }

    if (!this.state.isValidDate) {
      return null
    }

    return (
      this.props.proceed({
        data
      })
    )
  }


  @autobind
  handleChangePublicationNotAllowed({ target }) {

    this.setState({
      publicationNotAllowed: target.checked,
      releasePublicationLockAtActive: target.checked
        // todo: fix this state in state reference and test
        // eslint-disable-next-line react/no-access-state-in-setstate
        ? this.state.releasePublicationLockAtActive
        : false
    })
    if (!target.checked) {
      this.setState({
        releasePublicationLockAt: null,
        releasePublicationLockAtActive: false
      })
    }

  }

  @autobind
  handleChangeReleasePublicationLockAtActive({ target }) {

    this.setState({
      releasePublicationLockAtActive: target.checked
    })
    if (!target.checked) {
      this.setState({
        releasePublicationLockAt: null,
      })
    }
    if (target.checked && this.state.releasePublicationLockAt === null) {
      this.setState({
        releasePublicationLockAt: moment().format()
      })
    }
  }

  @autobind
  handleChangeReleasePublicationLockAt(date) {
    this.setState({
      isValidDate: moment(date).isValid(),
      releasePublicationLockAt: moment(date)
    })

  }

  render() {
    const publicationNotAllowed = this.state.publicationNotAllowed

    const yesterday = DateTime.moment().subtract(1, 'day')
    const valid = (current) => {
      return current.isAfter(yesterday)
    }

    return (
      <Dialog
        className={css.widgetSecurityDialog}
        overlayClassName={css.widgetSecurityDialogOverlay}
        isOpen={this.props.show}
        onRequestClose={this.handleRequestClose}
        title={formatMessage({ id: 'widget-security-dialog.title' })}
        onCancel={this.props.cancel}
        onCommit={this.handleCommit}
      >

        <div className="grid-content form-main">
          <div className="v-align content-flex-start">
            <input
              type="checkbox"
              id="publication-not-allowed"
              className="publicationNotAllowed"
              onChange={this.handleChangePublicationNotAllowed}
              checked={publicationNotAllowed}
            />
            <label htmlFor="publication-not-allowed">
              <FormattedMessage
                id="widget-security-dialog.publication-not-allowed"
              />
            </label>
          </div>
          <div className="v-align grid-block form-row">
            <div className="v-align grid-content small-6 content-flex-start">
              <input
                disabled={!publicationNotAllowed}
                id="release-publication-lock-at"
                type="checkbox"
                onChange={this.handleChangeReleasePublicationLockAtActive}
                checked={this.state.releasePublicationLockAtActive}
              />
              <label htmlFor="release-publication-lock-at"
                disabled={!publicationNotAllowed}
              >
                <FormattedMessage
                  id="widget-security-dialog.release-publication-lock-at"
                />:
              </label>
            </div>
            <div className="grid-content date-input">
              <DateTime
                id="dateTime"
                isValidDate={valid}
                locale="de"
                inputProps={{
                  disabled: !(publicationNotAllowed && this.state.releasePublicationLockAtActive)
                }}
                defaultValue={
                  !!this.state.releasePublicationLockAt
                    ? this.state.releasePublicationLockAt
                    : moment().format('L LT')
                }
                onBlur={this.handleChangeReleasePublicationLockAt}
              />
            </div>
            {
              !this.state.isValidDate
                ? <div className="message message-error date-error">
                  <FormattedMessage id="error.invalid-date" />
                </div>
                : null
            }
          </div>
        </div>
      </Dialog>
    )
  }

}

export default ArticleSecurity
