import * as PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'
import { formatMessage, FormattedMessage } from '../../../translations'
import Icon from '../../../shared/icons'
import GenevaButton from '../../../ui/components/GenevaButton'

function Tools(props) {
  const {
    env,
    css,
    autofilled,
    disabled,
    maxItems,
    value,
    isSorting,
    onAddItem,
    onToggleSortableClick,
  } = props

  const isDisabled = disabled || (maxItems ? value.length >= maxItems : false)

  if (!env.CM) {
    return null
  }

  return (
    <div
      className={classNames(
        'grid-block',
        'align-center',
        'v-align',
        'repeatable-tools',
        css.repeatableTools
      )}
    >
      <span className={css.repeatableToolsItemCount}>
        <FormattedMessage
          id={props.itemCountLabel || 'repeatable.items'}
          values={{
            count: value.length,
          }}
        />
      </span>

      {!autofilled ? (
        <GenevaButton
          className="small button plain add-item"
          disabled={isDisabled}
          onClick={onAddItem}
          title={formatMessage({
            id: 'repeatable.addItem',
          })}
        >
          <Icon name="ion-plus-round" />
          {!props.addItemButtonLabel ? null : (
            <FormattedMessage id={props.addItemButtonLabel} />
          )}
        </GenevaButton>
      ) : null}

      {props.sortable ? (
        <GenevaButton
          className="small button plain sort geneva-toggle-sort"
          onClick={onToggleSortableClick}
          disabled={disabled || value.length < 2}
        >
          <FormattedMessage
            id={`repeatable.${isSorting ? 'endSortItems' : 'startSortItems'}`}
          />
        </GenevaButton>
      ) : null}
    </div>
  )
}

Tools.propTypes = {
  // required properties
  value: PropTypes.array.isRequired,

  env: PropTypes.object.isRequired,

  sortable: PropTypes.bool,
  autofilled: PropTypes.bool,
  isSorting: PropTypes.bool,
  disabled: PropTypes.bool,

  maxItems: PropTypes.number,

  css: PropTypes.object,

  addItemButtonLabel: PropTypes.string,
  itemCountLabel: PropTypes.string,

  onToggleSortableClick: PropTypes.func,
  onAddItem: PropTypes.func,
}

Tools.defaultProps = {
  addItemButtonLabel: null,
  itemCountLabel: null,
}

export default Tools
