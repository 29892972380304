import React from 'react'
import PropTypes from 'prop-types'
import { autobind } from 'core-decorators'
import {
  DraftailEditor,
  createEditorStateFromRaw,
  serialiseEditorStateToRaw,
} from 'draftail'

import {
  RichUtils,
  CompositeDecorator
} from 'draft-js'

import { stateToHTML } from 'draft-js-export-html'

import { store as uiStore } from '../../../ui'
import {
  createEntityWithText,
  ENTITY_CONTROL,
  getCustomHTMLMap,
  getCustomFunctionMap,
  sliceContent,
} from './helper'

import { deepGet } from '../../../shared/obj'
import config from '../../../config'
import * as styles from './styles.scss' // Global styling from this file is used


export default class DraftEditor extends React.Component {

  static propTypes = {
    editorState: PropTypes.object,

    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onDrop: PropTypes.func,

    contextStore: PropTypes.object,
    spec: PropTypes.object,
    pid: PropTypes.number,

    defaultText: PropTypes.string,
    tagName: PropTypes.string,
    maxLen: PropTypes.number,
    singleLine: PropTypes.bool,
  }

  constructor(props) {
    super(props)

    const editorState = createEditorStateFromRaw(props.editorState)

    const decorator = new CompositeDecorator([
      {
        strategy: this.checkMaxLength,
        component: (prop) => {
          return (
            <span className="max-len-marker">
              {prop.children}
            </span>
          )
        }
      }
    ])

    // Ensure copy
    this.customHTMLMap = JSON.parse(JSON.stringify(getCustomHTMLMap()))
    this.customHTMLMap.entityStyleFn = getCustomFunctionMap().entityStyleFn

    // Adjust wrapping block for saving html depending on template setting
    this.customHTMLMap.defaultBlockTag = props.spec.block ? 'p' : 'div'

    let customStyles = deepGet(config, 'editor.buttonOptions.stylePicker')

    if (customStyles && Array.isArray(customStyles)) {

      customStyles = customStyles.map((style, index) => {
        const type = style.name || index
        style.type = type

        // Apply styles to customHTMLMap for stateToHTML in getEventContext
        this.customHTMLMap.inlineStyles[type] = style

        return style
      })
    }
    else {
      customStyles = []
    }

    this.state = {
      editorState,
      isVisible: false,
      placeholder: props.defaultText,
      singleLine: props.singleLine || props.spec.singleLine,
      decorator,
      customStyles,
    }
  }

  getEventContext(editorState) {
    let contentState = editorState.getCurrentContent()

    if (editorState && this.props.spec.enforceMaxLen) {
      const contentBlock = contentState.getLastBlock()

      // Trim and overwrite contentState for html converting (stateToHTML)
      this.checkMaxLength(contentBlock, (start, end) => {
        contentState = sliceContent(editorState, start, end)
      }, contentState)
    }

    const htmlContent = stateToHTML(contentState, this.customHTMLMap)
    const textContent = contentState.getPlainText()

    // Update this.selection info
    this.setSelectionInfo()

    return {
      target: {
        value: htmlContent,
        valueText: textContent, // used by connectEditorToContext to print remaining characters into the top bar
        selectedText: this.selectedText,
        editorState: serialiseEditorStateToRaw(editorState),
        isLinkTextEditable: this.isLinkTextEditable,
        entityData: this.entity ? this.entity.data : null,
        type: 'text',
        ref: this,
      },
      isTitleField: this.props.spec.isArticleName,
    }
  }

  setSelectionInfo() {
    const selectionState = this.state.editorState.getSelection()
    const contentState = this.state.editorState.getCurrentContent()

    const anchorKey = selectionState.getAnchorKey()
    const focusKey = selectionState.getFocusKey()

    const anchorContentBlock = contentState.getBlockForKey(anchorKey)

    const start = selectionState.getStartOffset()
    const end = selectionState.getEndOffset()

    const entityKey = anchorContentBlock.getEntityAt(start)

    this.isLinkSelected = false
    this.entity = null

    if (entityKey) {
      this.entity = contentState.getEntity(entityKey)
      this.entity.entityKey = entityKey

      if (this.entity.getType() === 'LINK') {
        this.isLinkSelected = true
      }
    }

    // If selection in the same block, the linktext can be edited
    this.isLinkTextEditable = anchorKey === focusKey
    this.selectedText = anchorContentBlock.getText().slice(start, end)
  }

  handleButtonContext(editorState, eventContext) {
    const buttonList = []

    // Get all the styles around the cursor
    editorState.getCurrentInlineStyle().forEach((el) => {
      buttonList.push(el)
    })

    if (this.isLinkSelected) {
      buttonList.push('linkPrompt')
    }

    // Triggers update of the toolbar
    this.props.contextStore.createFromElement({
      ...eventContext.target,
      buttonList,
      pid: this.props.pid,
    })
  }

  @autobind
  checkMaxLength(contentBlock, callback, contentState) {
    const threshold = this.props.maxLen || this.props.spec.maxLen

    if (!threshold) {
      // Bail out if there is no maxLen defined
      return
    }

    const blockKey = contentBlock.getKey()
    const blockLength = contentBlock.getLength()

    const previousContentLength = contentState
      .getBlockMap()
      .takeUntil(block => block.getKey() === blockKey)
      .reduce((length, block) => length + block.getLength(), 0)

    const currentLength = previousContentLength + blockLength
    const shouldDecorate = currentLength > threshold

    if (shouldDecorate) {
      // Decorate from the first character going over the threshold, to the end of the block.
      const startOffset = Math.max(0, threshold - previousContentLength)
      callback(startOffset, blockLength)
    }
  }

  @autobind
  handleRemoveLink() {
    let nextState = this.state.editorState
    const selection = nextState.getSelection()
    // Das geht nur, wenn auch der gesamte link ausgewählt wurde. ist das vll sogar ok?
    nextState = RichUtils.toggleLink(nextState, selection, null)

    // Trigger change
    this.handleChange(nextState)
  }

  @autobind
  handleLink(data) {
    let nextState = this.state.editorState
    const contentState = nextState.getCurrentContent()
    const selection = nextState.getSelection()

    // Only selected text inside one contentBlock can be edited
    if (this.isLinkTextEditable) {
      nextState = createEntityWithText(
        nextState,
        'LINK',
        data,
        data.content,
        'MUTABLE',
      )
    }
    // Selected text over more than one contentBlock cannot be edited,
    // so only wrap a new LINK entity around it
    else {
      const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', data)
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey()
      nextState = RichUtils.toggleLink(nextState, selection, entityKey)
    }

    // Trigger change
    this.handleChange(nextState)
  }

  @autobind
  handleClassName(data) {
    this.handleToggleInlineStyle(data.cmd)
  }

  @autobind
  handleChange(editorState) {

    if (!this.handleReturn(editorState)) {
      // Prevent the new state by setting the old
      this.setState({ editorState: this.state.editorState })
      return false
    }

    this.setState({ editorState }, () => {
      const eventContext = this.getEventContext(editorState)
      this.handleButtonContext(editorState, eventContext)
      this.props.onChange(eventContext)
    })

    return true
  }

  @autobind
  handleFocus() {
    this.handlePlaceholder()
    this.setEditorButtons()
    this.setState({ isVisible: true })

    if (this.props.onFocus) {
      this.props.onFocus(this.getEventContext(this.state.editorState))
    }
  }

  @autobind
  handleBlur() {
    this.handlePlaceholder()
    this.setState({ isVisible: false })

    if (this.props.onBlur) {
      this.props.onBlur(this.getEventContext(this.state.editorState))
    }
  }

  @autobind
  handleDrop(event) {
    if (this.props.onDrop) {
      const context = this.getEventContext(this.state.editorState)
      // Append with drag data
      context.data = event.dataTransfer.getData('censhare-text')

      this.props.onDrop(context)
    }
  }

  @autobind
  handleDragOver(e) {
    if (e.dataTransfer.types.includes('censhare-text')) {
      e.preventDefault()
    }
    else {
      e.dataTransfer.effectAllowed = 'none'
    }
  }

  @autobind
  handleDragEnter(e) {
    if (e.dataTransfer.types.includes('censhare-text')) {
      e.preventDefault()
    }
    else {
      e.dataTransfer.effectAllowed = 'none'
    }
  }

  @autobind
  setEditorButtons() {
    // Show necessary buttons defined by template
    uiStore.setDraftEditorButtons(this.props.spec.buttons)
  }

  @autobind
  handlePlaceholder() {
    // Add or remove placeholder example text
    this.setState({ placeholder: this.state.placeholder === '' ? this.props.defaultText : '' })
  }

  handleReturn(editorState) {
    if (editorState._immutable.lastChangeType === 'split-block') {
      if (this.state.singleLine) {
        // Prevent line break
        return false
      }
    }

    return true
  }

  render() {
    const { isVisible, editorState, placeholder, decorator, customStyles } = this.state
    const { className } = this.props
    const TagName = this.props.tagName || 'div'

    return (
      <TagName
        className={className}
        onDrop={this.handleDrop}
        onDragOver={this.handleDragOver}
        onDragEnter={this.handleDragEnter}
      >
        <DraftailEditor
          placeholder={placeholder}

          editorState={editorState}

          onChange={this.handleChange}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}

          decorators={[decorator]}
          entityTypes={[
            ENTITY_CONTROL.LINK
          ]}
          blockTypes={[
            { type: 'unordered-list-item' },
            { type: 'ordered-list-item' },
          ]}
          inlineStyles={[
            { type: 'BOLD' },
            { type: 'ITALIC' },
            { type: 'SUPERSCRIPT' },
            { type: 'SUBSCRIPT' },
          ].concat(customStyles)}
          topToolbar={null}
          bottomToolbar={(props) => {
            if (!isVisible) {
              return null
            }
            this.handleToggleInlineStyle = props.toggleInlineStyle
            this.handleToggleBlockType = props.toggleBlockType
            this.handleUndoRedo = props.onUndoRedo
            return null
          }}
        />
      </TagName>
    )
  }
}
