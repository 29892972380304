import ModelSyncAction from './ModelSyncAction'

export default
class DestroyAction
  extends ModelSyncAction {

  executeAction(opts = {}) {
    return this.model.store.destroy(this.model, opts)
  }
}
