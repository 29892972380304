import { createConfirmation } from 'react-confirm'

import { store as page } from '../../page'
import { store as project } from '../../project'
import LinkDialog from '../components/Dialog/LinkDialog'

const linkDialog = createConfirmation(LinkDialog)

export default function (confirmation, options = {}) {

  if (typeof confirmation !== 'string') {

    if (!confirmation.data.appearance) {
      confirmation.data.appearance = 'link-appearance-default'
    }

    options = {
      modal: {
        ...confirmation.data,
        page,
        project
      }
    }
    confirmation = ''

  }

  return linkDialog({ confirmation, options })

}
