
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { autobind } from 'core-decorators'
import Cropper from 'react-cropper'
import classNames from 'classnames'
import { FormattedMessage } from '../../translations'
import GenevaButton from '../../ui/components/GenevaButton'
import ContentLoadingBox from '../../shared/components/ContentLoadingBox'
import '../../../node_modules/cropperjs/dist/cropper.css'

const css = /* typeof window === 'undefined' ? {} : */require('../styles.scss')

class ImageEditorListItem extends Component {

  static propTypes = {
    onSelect: PropTypes.func,
    image: PropTypes.shape({
      image: PropTypes.object,
      constraints: PropTypes.object
    }).isRequired,
    active: PropTypes.bool
  }

  @autobind
  handleSelect() {
    if (this.props.onSelect && !this.props.disabled) {
      this.props.onSelect({
        target: {
          value: this.props.image
        }
      })
    }
  }

  render() {
    const { image } = this.props
    return (<li
      className={classNames({
        active: this.props.active
      })}
      onClick={this.handleSelect}
    >
      <img role="presentation" src={image.image.value} />
    </li>)
  }
}

export default class Editor extends Component {

  static propTypes = {
    editor: PropTypes.object.isRequired
  }

  constructor(props) {

    super(props)

    let { editor: { imageData } } = this.props

    if (imageData && !(imageData.splice && Array.isArray(imageData.splice()))) {
      imageData = [imageData]
    }

    const imagesHaveSameSize = true

    this.state = {
      showGuides: true,
      applyToAllImages: imagesHaveSameSize,
      imagesHaveSameSize,
      isReady: false,
      activeImage: imageData && imageData.length
        ? imageData[0]
        : null
    }

  }

  componentWillReceiveProps(nextProps) {

    let { editor: { imageData } } = nextProps

    if (imageData && !(imageData.splice && Array.isArray(imageData.splice()))) {
      imageData = [imageData]
    }

    this.setState({
      activeImage: imageData && imageData.length
        ? imageData[0]
        : null
    })

  }

  setInitialFrameSize(frame) {

    const { cropper } = this.refs

    if (cropper && frame) {
      const canvasData = cropper.getCanvasData()
      const leftOffset = canvasData.left
      const topOffset = canvasData.top
      const zoomRatio = canvasData.width / canvasData.naturalWidth

      // Initial sizing and positioning of the frame only after opening
      return {
        height: frame.h * zoomRatio,
        width: frame.w * zoomRatio,
        left: frame.x * zoomRatio + leftOffset,
        top: frame.y * zoomRatio + topOffset,
      }
    }

    // No Changes to return if no ref set
    return undefined
  }

  transformFrame() {
    const { cropper } = this.refs
    let zoomRatio = 1

    // Check when resizing if minimum sizes meets the defined constraints
    if (cropper) {
      const canvasData = cropper.getCanvasData()
      const cropperData = cropper.getData(true)
      const constraints = this.state.activeImage.constraints
      zoomRatio = canvasData.width / canvasData.naturalWidth

      // Adjust the cropBox size when undercutting the minimum sizes
      if (cropperData.width < constraints.minWidth) {
        return ({ width: constraints.minWidth * zoomRatio })
      }
      if (cropperData.height < constraints.minHeight) {
        return ({ height: constraints.minHeight * zoomRatio })
      }
    }

    // No Changes to return if no ref set
    return undefined
  }

  @autobind
  handleReady() {
    const { activeImage } = this.state
    const { cropper } = this.refs

    if (!cropper) {
      console.warn('Cropper invalidated. Please reload the page.')
      return
    }

    if (cropper && cropper.cropper) {
      cropper.cropper.options.checkCrossOrigin = false
    }

    if (activeImage) {
      if (activeImage.data) {
        setTimeout(() => {
          cropper.setData(activeImage.data)
        }, 100)
      }
      if (activeImage.image) {
        cropper.setCropBoxData(this.setInitialFrameSize(activeImage.image.frame))
      }
      this.setState({
        isReady: true
      })

      // With default 'applyToAllImages = true', ensure all images have a frame
      this.applyCroppingToActiveImage()
    }
  }

  @autobind
  handleCommit() {
    this.applyCroppingToActiveImage()
    this.props.editor.status = 'saveImage'
  }

  @autobind
  handleAbort() {
    this.props.editor.status = 'abort'
  }

  @autobind
  handleSelect({ target }) {

    this.applyCroppingToActiveImage()

    this.setState({
      activeImage: target.value,
      isReady: target.value === this.state.activeImage
    })
  }

  @autobind
  handleShowGuidesChanged() {
    this.setState({
      showGuides: !this.state.showGuides
    })
  }

  @autobind
  handleApplyToAllImagesChanged() {
    this.setState({
      applyToAllImages: !this.state.applyToAllImages
    })
  }

  @autobind
  handleCropperChange() {

    const { cropper } = this.refs

    if (cropper) {

      const cropBoxData = cropper.getCropBoxData()
      const canvasData = cropper.getCanvasData()

      // Scale the Data with zoomFactor to display correct values
      const zoomRatio = canvasData.naturalWidth / canvasData.width
      const width = cropBoxData.width * zoomRatio
      const height = cropBoxData.height * zoomRatio

      this.setState({
        width,
        height
      })
    }
  }

  applyCroppingToActiveImage() {

    const { editor: { imageData } } = this.props
    const { activeImage, applyToAllImages } = this.state
    const type = activeImage.type
    const opts = type === 'image/png'
      ? null
      : { fillColor: '#fff' }
    const cropper = this.refs.cropper

    const croppedCanvas = cropper
      .getCroppedCanvas(opts)

    if (!croppedCanvas) {
      return
    }

    const url = croppedCanvas.toDataURL(type)

    activeImage.cropped = { url }

    const canvasData = cropper.getCanvasData()
    const cropboxData = cropper.getCropBoxData()
    const data = cropper.getData()
    const zoomRatio = canvasData.naturalWidth / canvasData.width

    cropboxData.left -= canvasData.left
    cropboxData.top -= canvasData.top

    const frame = Object.keys(cropboxData)
      .reduce((memo, key) => {
        memo[key] = cropboxData[key] * zoomRatio
        return memo
      }, {})

    const images = imageData.length && applyToAllImages
      ? imageData
      : [activeImage]

    images.forEach((image) => {
      image.frame = frame
      image.data = data
      image.canvasData = canvasData
      image.cropboxData = cropboxData
    })

  }

  renderCropper() {

    const { activeImage: {
      image, data
    }, showGuides } = this.state

    const constraints = this.state.activeImage.constraints || {}

    let aspectRatio = constraints.aspectRatio

    if (!aspectRatio) {
      if (constraints.minWidth && constraints.minHeight) {
        aspectRatio = constraints.minWidth / constraints.minHeight
      }
    }

    return (<div className={classNames('grid-block')}>
      <div className={classNames(css.imageEditorCanvas)}>

        <Cropper

          // The key is important here, it ensures that a actually a new
          // element is created once the image changes. That on the other hand
          // is important, because most of the properties here are not meant
          // to be changed after the element was first mounted
          key={image.value}

          ref="cropper"
          className={classNames(css.imageEditorEditor, 'grid-block')}
          src={image.value}

          crop={this.handleCropperChange}
          cropBoxData={this.transformFrame()}

          // Cropper.js options
          aspectRatio={aspectRatio}
          background
          // cropBoxResizable={!!aspectRatio}
          // crossOrigin="Anonymous"
          data={data}
          dragMode="move"
          guides={showGuides}
          highlight
          // minCropBoxWidth={constraints.minWidth}
          // minCropBoxHeight={constraints.minHeight}
          modal
          responsive
          zoomable={false}
          viewMode={1}

          ready={this.handleReady}

        />

      </div>
    </div>)
  }

  renderLoader() {
    return (<div className={classNames(css.imageContentLoadingBox)}>
      <ContentLoadingBox
        className="bright"
        spinner
        message={{
          id: 'image-editor.loading'
        }}
      />
    </div>)
  }

  renderImageList() {

    const { editor: { imageData } } = this.props

    return (<div className={classNames(css.imageEditorList)}>
      <ul>
        {
          imageData.length
            ? imageData.map(image => (
              <ImageEditorListItem
                key={image.image.id || image.image.value}
                image={image}
                active={this.state.activeImage === image}
                onSelect={this.handleSelect}
                disabled={!this.state.isReady}
              />
            ))
            : null
        }
      </ul>
    </div>)

  }

  renderImageEditor() {

    const { editor: { imageData } } = this.props

    return (
      <div className={classNames('grid-block', 'vertical')}>
        {
          !this.state.isReady
            ? this.renderLoader()
            : null
        }
        {this.renderCropper()}
        {imageData.length > 1 ? this.renderImageList() : null}
      </div>
    )

  }

  renderApplyToAllImages() {

    const { editor: { imageData } } = this.props

    if (imageData.length < 2) {
      return null
    }

    return (<div>
      <input
        id="apply-to-all-images"
        type="checkbox"
        disabled={!this.state.imagesHaveSameSize}
        checked={this.state.imagesHaveSameSize ? this.state.applyToAllImages : false}
        onChange={this.handleApplyToAllImagesChanged}
      />
      <label htmlFor="apply-to-all-images" className="apply-to-all-images">
        <FormattedMessage id="image-editor.apply-to-all-images" />
      </label>
      {
        !this.state.imagesHaveSameSize
          ? (
            <span className="muted">
              &nbsp;
              (<FormattedMessage
                id="image-editor.apply-to-all-images-info"
              />)
            </span>
          )
          : null
      }
    </div>)
  }

  renderConstraintsInfo() {

    const minWidth = this.state.activeImage.constraints.minWidth
    const minHeight = this.state.activeImage.constraints.minHeight

    // If no constraints at all, do not render info
    if (!minWidth && !minHeight) {
      return null
    }

    // Take width or height as message id
    let messageId = minWidth
      ? 'image-editor.constraints-min-width'
      : 'image-editor.constraints-min-height'

    // Change to both if needed
    if (minWidth && minHeight) {
      messageId = 'image-editor.constraints'
    }

    return (
      <label className="constraints-info grid-block v-align">
        <FormattedMessage
          id={messageId}
          values={{ minWidth, minHeight }}
        />
      </label>
    )
  }

  @autobind
  renderCropperSize() {

    const width = Math.floor(this.state.width)
    const height = Math.floor(this.state.height)

    return (
      <label className="cropper-size-info grid-block v-align muted">
        <FormattedMessage
          id="image-editor.cropper-size"
          values={{ width, height }}
        />
      </label>
    )
  }


  renderImageEditorTools() {

    return (
      <div
        className={
          classNames('grid-block', css.imageEditorTools)
        }
      >

        <div className="grid-block">

          <div className="grid-content v-align align-center">
            {this.renderApplyToAllImages()}
          </div>

          <div className="grid-block stretch vertical medium-4 align-right image-editor-sizes">
            {this.renderConstraintsInfo()}
            {this.renderCropperSize()}
          </div>

          <div
            className={classNames(
              'grid-content', 'medium-2', 'v-align',
              css.imageEditorToolsButtons
            )}
          >

            <div className="button-group align-center">

              <GenevaButton
                className="small light button plain"
                onClick={this.handleAbort}
              >
                <FormattedMessage id="image-editor.abort" />
              </GenevaButton>

              <GenevaButton
                className="small button"
                disabled={!this.state.isReady}
                onClick={this.handleCommit}
              >
                <FormattedMessage id="image-editor.commit" />
              </GenevaButton>

            </div>
          </div>

        </div>

      </div>
    )
  }

  render() {
    return (
      <div className={classNames('grid-frame', css.imageEditor)}>
        <div className="grid-block vertical">
          {this.renderImageEditorTools()}
          {this.renderImageEditor()}
        </div>
      </div>
    )
  }

}

// <div>
//   <input
//     type="checkbox"
//     value={this.state.showGuides}
//     onChange={this.handleShowGuidesChanged}
//   /> <FormattedMessage id="image-editor.show-guides" />
// </div>
