/* eslint-disable react/sort-comp */
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { autobind } from 'core-decorators'
import PerfectScrollbar from 'perfect-scrollbar'

import TemplateErrorBox from '../../shared/components/TemplateErrorBox'
import { connectStatusToComponent } from '../../shared/components/StatusList'


class PageRenderer extends Component {

  static propTypes = {
    container: PropTypes.object, // DOM NODE
    page: PropTypes.object.isRequired,
    pageStore: PropTypes.object.isRequired,
    template: PropTypes.func.isRequired,
    ui: PropTypes.object.isRequired,
    method: PropTypes.oneOf(['raw', 'prepared']),
    onUpdateStatus: PropTypes.func
  }

  constructor(props) {
    super(props)

    this.state = {
      render: false,
      hasError: false,
      error: {},
    }

    this.ps = null
  }

  componentWillMount() {

    this.setState({
      render: true
    })

  }

  componentDidMount() {
    this.ps = new PerfectScrollbar('#pageRenderer-container', {
      suppressScrollX: true
    })
  }

  componentWillUnmount() {

    if (this.props.method === 'prepared') {
      this.cleanup()
    }

  }

  componentDidCatch(error) {
    // Catch error the react16 way - see https://reactjs.org/blog/2017/07/26/error-handling-in-react-16.html
    this.setState({ hasError: true, error })
  }

  @autobind
  handleClick(e) {

    const link = e.target.closest('A')

    if (link && link.href && !e.shiftKey) {

      e.preventDefault()

      if (this.props.onUpdateStatus) {
        this.props.onUpdateStatus({
          id: 'pm-link-info',
          name: 'link-prevented',
          value: link.href,
          priority: 4
        }, 5000)
      }

    }

  }

  @autobind
  loadScripts({ ref, scripts }) {
    if (!(scripts && scripts.length)) {
      return Promise.resolve(true)
    }

    const script = scripts.shift()

    return new Promise((resolve, reject) => {

      if (!script.type || script.type === 'text/javascript') {

        if (script.src) {
          const newScript = document.createElement('script')
          ref.appendChild(newScript)
          newScript.onload = () => resolve({ ref, scripts })
          newScript.onerror = reject
          newScript.src = script.src
        }
        else {
          // eslint-disable-next-line no-new-func
          const fn = new Function(script.innerHTML)
          try {
            fn()
            resolve({ ref, scripts })
          }
          catch (ex) {
            reject(ex)
          }
        }

        script.parentNode.removeChild(script)
      }

    })
      .then(this.loadScripts)


  }


  cleanup() {
    log.renderer('PageRenderer#cleanup: unmounting page renderer')
    clearTimeout(this.renderTimeout)
    try {
      ReactDOM.unmountComponentAtNode(this.container)
    }
    catch (ex) {
      console.error('There was an error unmounting the page:', ex)
    }
  }

  renderRawPage() {

    const content = this.props.page.templateData

    // if (!this.props.page.content) {
    //   console.warn('Content missing')
    //   return null
    // }

    if (!this.props.template) {
      console.warn('Template missing')
      return null
    }

    log.renderer('%cRendering PageRenderer', 'background-color:red;color:white;')

    const ConnectedTemplate = this.props.template

    return (<ConnectedTemplate
      page={this.props.page}
      pageStore={this.props.pageStore}
      content={content}
    />)

  }

  renderPreparedPage() {
    log.renderer('Component did update...')
    if (this.props.method === 'prepared') {
      log.renderer('...rendering prepared html')
      const content = this.props.page.templateData

      if (this.container && this.container.innerHTML) {
        this.cleanup()
      }

      this.renderTimeout = setTimeout(() => {

        if (!this.container) {
          console.warn('Container missing')
          return
        }

        if (!this.props.page.content) {
          console.warn('Content missing')
          return
        }

        log.renderer('PageRenderer.renderPreparedPage: rendering...')

        this.container.innerHTML = this.props.page.content

        const ConnectedTemplate = this.props.template

        try {
          ReactDOM.render(
            // (<IntlProvider
            //   locale="en"
            //   messages={this.props.ui.translations}
            // >
            (<div>
              <ConnectedTemplate
                page={this.props.page}
                pageStore={this.props.pageStore}
                content={content}
              />
            </div>),
            // </IntlProvider>),
            this.container
          )
        }
        catch (ex) {
          console.error(ex)
        }

      }, 1)

    }

    return null
  }

  @autobind
  renderPMHeader(ref) {
    if (ref) {
      this.loadScripts({
        ref,
        scripts: Array.prototype.slice.call(ref.querySelectorAll('script'))
      })
        .catch((ex) => {
          console.error(ex)
        })
    }
  }

  render() {

    if (this.state.hasError) {
      // Display fallback UI
      return (<TemplateErrorBox
        error={this.state.error}
        env={{ PM: true }}
        template={this.props.template}
      />)
    }

    if (this.ps) {
      this.ps.update()
    }

    return (<div
      onClick={this.handleClick}
      ref={ref => (this.container = ref)}
      id="pageRenderer-container"
      // the className may be used in template scss to overwrite styles
      className="page_template_wrapper"
    >
      <div
        className="pm-header"
        dangerouslySetInnerHTML={{ __html: this.props.page.header }}
        ref={this.renderPMHeader}
      />
      {this.props.method === 'prepared'
        ? this.renderPreparedPage()
        : this.renderRawPage()
      }
    </div>)

  }

}

export default connectStatusToComponent(PageRenderer)
