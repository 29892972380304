import * as PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'
import Icon from '../../../shared/icons'
import GenevaButton from '../../../ui/components/GenevaButton'

export default function ItemTools(props) {
  const { css, index, onRemoveItem } = props
  return (
    <div
      className={classNames(
        'repeatable-item-tools-container',
        css.repeatableItemToolsContainer
      )}
    >
      <GenevaButton
        onClick={() => onRemoveItem(index)}
        className={classNames(
          'small',
          'button',
          'remove-item',
          css.repeatableItemRemove
        )}
      >
        <Icon name="ion-close-round" />
      </GenevaButton>
    </div>
  )
}

ItemTools.propTypes = {
  css: PropTypes.object,
  onRemoveItem: PropTypes.func.isRequired,
  index: PropTypes.number,
}
