import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { autobind } from 'core-decorators'
import { observer } from 'mobx-react'
import classNames from 'classnames'

import { FormattedMessage } from '../../translations'
import { store as articleStore } from '..'

import Dropdown, {
  DropdownContainer, Entry, DropdownOpener, Separator
} from '../../shared/components/Dropdown'
import GenevaDropdownOpener from '../../ui/components/GenevaDropdownOpener'
import { DIV_ATTRS, filterElemAttrs } from '../../shared/react-helpers'
import { ignore } from '../../shared/obj'
import { testClass } from '../../shared/utils'
import { hasPermissions } from '../../shared/utils/user-rights'

import connectPMItemToolsToCommands from '../../shared/connectors/connectPMItemToolsToCommands'

const ObservedEntry = observer((props) => {

  const { entry, id, onClick } = props

  return (<Entry
    {...entry.state}
    identifier={id}
    onClick={onClick}
  >
    {entry.name}
  </Entry>)

})

@observer
class PMArticleToolbar extends Component {

  static propTypes = {
    className: PropTypes.string,
    ui: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    page: PropTypes.object,

    /**
     * from connectPMItemToolsToCommands
     */
    onEditItem: PropTypes.func,

    /**
     * from connectPMItemToolsToCommands
     */
    onRemoveItemFromPage: PropTypes.func,

    /**
     * from connectPMItemToolsToCommands
     */
    onDeleteItem: PropTypes.func,
  }

  constructor(props) {
    super(props)

    this.permissions = hasPermissions(['editArticle', 'deleteArticle'])
  }

  isValidEntry(entry) {

    if (entry === '-') {
      return true
    }

    if (!entry.name) {
      console.warn('article/PMToolbar: A custom entry HAS to have a name property of type string')
      return false
    }

    if (!('handleClick' in entry)) {
      // eslint-disable-next-line max-len
      console.warn('article/PMToolbar: A custom entry HAS to have an handleClick property of type function')
      return false
    }

    return true
  }

  @autobind
  handleEntryClick({ target }) {
    const entry = this.props.ui.articleContextTools[target.identifier]
    entry.handleClick()
    if (entry.handleQueryState) {
      entry.handleQueryState(entry.state)
    }
  }

  @autobind
  handleOpenArticle() {

    if (this.props.onEditItem) {
      this.props.onEditItem({
        target: {
          type: 'article'
        }
      })
    }

  }

  @autobind
  handleDeleteArticle() {

    if (this.props.onDeleteItem) {
      this.props.onDeleteItem({
        target: {
          type: 'article',
          pageId: this.props.page.current.id,
          itemStore: articleStore
        }
      })
    }

  }

  @autobind
  handleRemoveArticleFromPage() {

    if (this.props.onRemoveItemFromPage) {
      this.props.onRemoveItemFromPage({
        target: {
          type: 'article',
          pageId: this.props.page.current.id
        }
      })
    }

  }

  renderCustomContextTools() {
    const { ui: { articleContextTools } } = this.props

    return (<Entry>

      <DropdownOpener>
        <FormattedMessage id="article.options" />
      </DropdownOpener>

      <Dropdown>

        {articleContextTools.map((entry, id) => (entry === '-'

          ? <Separator />

          : <ObservedEntry
            key={entry.name}
            entry={entry}
            onClick={this.handleEntryClick}
            id={id}
          />

        ))}

      </Dropdown>

    </Entry>)
  }

  render() {
    // let { ui: { articleContextTools } } = this.props
    const { disabled } = this.props


    // articleContextTools = (articleContextTools || []).filter(this.isValidEntry)

    const divProps = ignore(filterElemAttrs(this.props, DIV_ATTRS), [
      'onEditItem',
      'onRemoveItemFromPage',
      'onDeleteItem'
    ])

    return (<div {...divProps}
      className={classNames(this.props.className, 'toolbar', 'v-align')}
    >
      <DropdownContainer>
        <GenevaDropdownOpener
          clickToClose
          disabled={disabled || !this.permissions.length}
          caret={false}
          arrow
        >
          <FormattedMessage id="article.article" />
        </GenevaDropdownOpener>
        <Dropdown>

          {this.permissions.editArticle
            ? <Entry
              className={testClass('article-edit-entry')}
              onClick={this.handleOpenArticle}
            >
              <FormattedMessage id="article.edit" />...
            </Entry>
            : null}

          {
          /*  {articleContextTools && articleContextTools.length
            ? this.renderCustomContextTools()
            : null
          }
        */
          }
          {this.permissions.editArticle
            ? <Separator />
            : null}

          {this.permissions.editArticle
            ? <Entry
              className={testClass('article-remove-from-page-entry')}
              onClick={this.handleRemoveArticleFromPage}
            >
              <FormattedMessage id="article.remove-from-page" />
            </Entry>
            : null}

          {this.permissions.deleteArticle
            ? <Entry
              className={testClass('article-delete-entry')}
              onClick={this.handleDeleteArticle}
            >
              <FormattedMessage id="article.delete" />
            </Entry>
            : null}

        </Dropdown>
      </DropdownContainer>

    </div>)

  }
}

export default connectPMItemToolsToCommands(PMArticleToolbar)
