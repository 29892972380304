/**
 * Copyright 2015, Yahoo! Inc.
 * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
 */
const REACT_STATICS = {
  childContextTypes: true,
  contextTypes: true,
  defaultProps: true,
  displayName: true,
  getDefaultProps: true,
  mixins: true,
  propTypes: true,
  type: true
}

const KNOWN_STATICS = {
  name: true,
  length: true,
  prototype: true,
  caller: true,
  callee: true,
  arguments: true,
  arity: true
}

const getOwnPropertySymbols = Object.getOwnPropertySymbols
const hasOwnProperty = Object.prototype.hasOwnProperty
const propIsEnumerable = Object.prototype.propertyIsEnumerable
const getPrototypeOf = Object.getPrototypeOf
const objectPrototype = getPrototypeOf && getPrototypeOf(Object)
const getOwnPropertyNames = Object.getOwnPropertyNames

function hoistNonReactStatics(targetComponent, sourceComponent, blacklist) {
  if (typeof sourceComponent !== 'string') { // don't hoist over string (html) components

    if (objectPrototype) {
      const inheritedComponent = getPrototypeOf(sourceComponent)
      if (inheritedComponent && inheritedComponent !== objectPrototype) {
        hoistNonReactStatics(targetComponent, inheritedComponent, blacklist)
      }
    }

    let keys = getOwnPropertyNames(sourceComponent)

    if (getOwnPropertySymbols) {
      keys = keys.concat(getOwnPropertySymbols(sourceComponent))
    }

    for (let i = 0; i < keys.length; ++i) {
      const key = keys[i]
      if (!REACT_STATICS[key] && !KNOWN_STATICS[key] && (!blacklist || !blacklist[key])) {
        // Only hoist enumerables and non-enumerable functions
        if (propIsEnumerable.call(sourceComponent, key) || typeof sourceComponent[key] === 'function') {
          try { // Avoid failures from read-only properties
            targetComponent[key] = sourceComponent[key]
          }
          catch (e) {
            console.log(e)
          }
        }
      }
    }

    return targetComponent
  }

  return targetComponent
}


export {
  hoistNonReactStatics as default,
  hoistNonReactStatics
}
