import { observable, computed } from 'mobx'
import { deepGet } from '../shared/obj'
import { addToCollection } from '../shared/utils'
import { paginate, filterable, apiClient } from '../shared/api'
import { Store } from '../shared/store'
import CenshareArticle from './model'

/**
 * note for future dev:
 * since censhare is complicated to connect to, you can pass stub responses to the
 * api call, with the param 'stub'. Then if it fails (which it will) the stub is returned
 */

@paginate
@filterable
@apiClient
export default
class CenshareStore
  extends Store {

  // censhare article collection
  @observable collection = []

  @observable current = {}

  @observable limit = 200;

  @observable page = 1

  @observable sorting = [{}]

  @observable filter = []

  @observable languageIso = ''

  @observable currentMeta = null;

  @computed get hasCurrent() {
    return !!this.current.id
  }

  @observable loading = false

  // to see the store name when in production mode - Uglifyjs
  static storeName = 'CenshareStore'

  constructor(opts = {}) {

    super(opts)

    this.Model = CenshareArticle

  }

  parse(data) {
    if (data.data) {
      data = data.data
    }
    return data
  }

  reset({ page }) {
    return this.loadMultiple({
      params: {
        page,
        limit: this.limit,
        sorting: this.sorting,
        filter: this.filter,
        languageIso: this.languageIso
      }
    })
  }

  /**
   * @param {number} id - id of censhare group
   * @param {*} opts - params as needed
   * @returns {CenshareArticle}
  */
  load(id = null, opts = {}) {
    if (!id) {
      throw new Error('missing id to load censhare article')
    }
    return this.dispatch('get', {
      path: `thirdparties/censhare/article/${id}`,
      params: opts.params,
    }).then((response) => {
      if (!response || !response.body || response.error) {
        throw new Error('error in censhare get')
      }
      // It's possible to have an empty censhare item
      if (deepGet(response, 'body.data.assetId')) {
        response.body.data.id = response.body.data.assetId
        return addToCollection(this, this.collection, response.body.data, CenshareArticle)
      }
      return null
    })
  }

  /**
   * @param {Object} opts - params
   * @returns {Object} - {models: CenshareArticle, meta: Object}
   */
  loadMultiple(opts = {}) {
    // to load all with pagination
    return this.dispatch('get', {
      path: 'thirdparties/censhare/article',
      params: opts.params,
    }).then((response) => {
      if (!response || !response.body || response.error) {
        throw new Error('error in censhare get')
      }
      const modelGroup = []
      response.body.data.forEach((article) => {
        if (article.assetId) {
          article.id = article.assetId
          modelGroup.push(addToCollection(this, this.collection, article, CenshareArticle))
        }
      })

      this.currentMeta = response.body.meta
      return {
        models: modelGroup,
        meta: response.body.meta
      }
    })
  }

  loadCategories(id, data, opts) {
    return this.dispatch('get', {
      path: 'thirdparties/censhare/category',
      params: opts.params,
    })
  }

  save(holder, data, opts = {}) {
    return this.dispatch('put', {
      path: `thirdparties/censhare/${opts.action}/${opts.id}/content`,
      data,
    })
  }

  canLoad() {
    return !this.loading && this.hasMoreItems
  }

  metaLoad() {
    return this.loadMultiple({ params: { page: 1, limit: 1 } })
  }

}
