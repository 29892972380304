import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import keydown from 'react-keydown'
import { autobind } from 'core-decorators'
import { shortcut } from '../../shared/const'

export default function connectItemToKeyboard(Comp) {
  class KeyboardConnectedItem extends Component {
    static propTypes = {
      onOpen: PropTypes.func,
      onDelete: PropTypes.func,
      onRemove: PropTypes.func,
    };

    getEventContext() {
      // get the vent context from the wrapped component
      if (
        this.wrappedComponentInstance
        && this.wrappedComponentInstance.getEventContext
      ) {
        return this.wrappedComponentInstance.getEventContext()
      }
      console.warn(
        'The wrapped component does not exist '
          + 'or does not provide a `getEventContext` method!'
      )
      return null
    }

    @autobind
    @keydown(shortcut.OPEN_ITEM, 'Enter')
    handleDoubleClick() {
      if (this.props.onOpen) {
        this.props.onOpen(this.getEventContext())
      }
    }

    @autobind
    @keydown(shortcut.DELETE_ITEM)
    handleKeydownCtrlBackspace() {
      if (this.props.onDelete) {
        this.props.onDelete(this.getEventContext())
      }
    }

    @autobind
    @keydown(shortcut.REMOVE_ITEM)
    handleKeydownBackspace() {
      if (this.props.onRemove) {
        this.props.onRemove(this.getEventContext())
      }
    }

    render() {
      const props = { ...this.props }
      delete props.onOpen
      delete props.onDelete
      delete props.onRemove
      props.ref = (comp) => {
        this.wrappedComponentInstance = comp
      }
      return <Comp {...props} onDoubleClick={this.handleDoubleClick} />
    }
  }

  return KeyboardConnectedItem
}
