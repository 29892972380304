/*
* {String} name - The name of the button.
* {String} icon - The icon of the button.
* {String} label - A string to use as label (instead of `icon`)
* {String} cmd - Represents the draft.js command to run
* {Boolean} isVisible - Whether or not the button should be visible.
* {Boolean} isActive - Whether or not the button should be rendered as active.
* {String} type - draft.js command type e.g. inline styles, block styles, link, undoRedo
*/
const getDraftEditorToolbarButtons = () => {

  return [
    {
      cmd: 'BOLD',
      name: 'bold',
      icon: 'fa-bold',
      isVisible: false,
      isActive: false,
      type: 'inline',
    },
    {
      cmd: 'ITALIC',
      name: 'italic',
      icon: 'fa-italic',
      isVisible: false,
      isActive: false,
      type: 'inline',
    },
    {
      cmd: 'UNDERLINE',
      name: 'underline',
      icon: 'fa-underline',
      isVisible: false,
      isActive: false,
      type: 'inline',
    },
    {
      cmd: 'STRIKETHROUGH',
      name: 'strikeThrough',
      icon: 'fa-strikethrough',
      isVisible: false,
      isActive: false,
      type: 'inline',
    },
    {
      cmd: 'SUPERSCRIPT',
      name: 'superscript',
      icon: 'fa-superscript',
      isVisible: false,
      isActive: false,
      type: 'inline',
    },
    {
      cmd: 'SUBSCRIPT',
      name: 'subscript',
      icon: 'fa-subscript',
      isVisible: false,
      isActive: false,
      type: 'inline',
    },
    {
      cmd: 'CLASSNAME',
      name: 'stylePicker',
      icon: 'fa-font',
      isVisible: false,
      isActive: false,
      type: 'classname',
    },
    {
      cmd: 'linkPrompt',
      name: 'linkPrompt',
      icon: 'fa-link',
      type: 'link',
    },
    {
      cmd: 'ordered-list-item',
      name: 'insertOrderedList',
      icon: 'fa-list-ol',
      isVisible: false,
      isActive: false,
      type: 'block',
    },
    {
      cmd: 'unordered-list-item',
      name: 'insertUnorderedList',
      icon: 'fa-list-ul',
      isVisible: false,
      isActive: false,
      type: 'block',
    },
    {
      cmd: 'undo',
      name: 'undo',
      icon: 'fa-undo',
      isVisible: false,
      isActive: false,
      type: 'undoRedo',
    },
    {
      cmd: 'redo',
      name: 'redo',
      icon: 'fa-repeat',
      isVisible: false,
      isActive: false,
      type: 'undoRedo',
    },
  ]
}

export default getDraftEditorToolbarButtons
