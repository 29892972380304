import statusDecorator from './status'
import versionDecorator from './version'
import editableDecorator from './editable'

import talkative from './talkative'
import validate from './validate'

//
// Mixins.LockingMixin = ModelMixin.create({
//   methods: {
//     isEditable() {
//       return this.isInEditableStatus() && !this.isLocked()
//     },
//
//     isLockedForEditing(userId) {
//       return this.isInEditableStatus() &&
//                 this.isLocked() &&
//                 !this.isLockedBy(userId)
//     },
//
//     isLocked() {
//       return !this.isInEditableStatus() || this.hasLock()
//     },
//
//     hasLock() {
//       return !!this.get('lock')
//     },
//
//     isLockedBy(userId) {
//       var lock = this.attributes.lock
//       return lock && lock.id === userId && lock.isOwn
//     },
//
//
//     lock(data) {
//       this.set('lock', typeof data !== 'undefined' ? data : false)
//     },
//
//     unlock() {
//       this.lock(false)
//     }
//   }
// })
//
// Mixins.SyncMixin = ModelMixin.create({
//   methods: {
//     sync(method, model, opts) {
//
//       if (method !== 'read') {
//
//         if ((!opts.isAllowedInLockState &&
//                     this.isLocked()) &&
//                     !this.isToBeRestored(opts) &&
//                     !this.isToBeNuked(method)) {
//
//           if (opts.error) {
//             opts.error(model, 'The action was prevented because the article is locked.', opts)
//           }
//           throw new Error('The action was prevented because the article is locked.')
//
//         }
//
//       }
//
//       Model.Model.prototype.sync.apply(this, arguments)
//
//     },
//
//     isToBeRestored(opts) {
//
//       opts = opts || {}
//
//       return (opts &&
//                     opts.params &&
//                     opts.params.restore) &&
//                 this.isRestorable ?
//                     this.isRestorable() :
//                     (!this.hasLock() &&
//                     this.isInStatus('deleted'))
//
//     },
//
//     isToBeNuked(method) {
//
//       return !this.hasLock() &&
//                 this.isInStatus('deleted') &&
//                 method.toLowerCase() === 'delete'
//
//     }
//   }
// })

export {
  statusDecorator,
  versionDecorator,
  editableDecorator,
  talkative,
  validate,
}
