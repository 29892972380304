export default (config) => {

  if (!global.GENEVA_CONFIG) {
    throw new Error('global.GENEVA_CONFIG not found!')
  }

  if (!global.GENEVA_CONFIG.contentClass) {
    config.contentClass = global.GENEVA_CONFIG.contentClass = 'customer-styles'
  }

  if (!config.contentLangs) {
    config.contentLangs = global.GENEVA_CONFIG.contentLangs
  }

  if (!global.GENEVA_CONFIG.PREVIEW_IN_WINDOW) {
    config.PREVIEW_IN_WINDOW = global.GENEVA_CONFIG.PREVIEW_IN_WINDOW = false
  }

  if (
    !config.pageTemplates
    && global.GENEVA_CONFIG.pageTemplates
  ) {
    config.pageTemplates = global.GENEVA_CONFIG.pageTemplates
  }

  if (!config.plugins) {
    config.plugins = global.GENEVA_CONFIG.plugins || {}
  }

  if (!config.features) {
    config.features = global.GENEVA_CONFIG.features || {}
  }

  if (!config.API_VERSION) {
    config.API_VERSION = global.GENEVA_CONFIG.API_VERSION || '0.0.0'
  }

  if (global.GENEVA_CONFIG.editor) {
    config.editor = global.GENEVA_CONFIG.editor
  }

  if (global.GENEVA_CONFIG.uploadSettings) {
    config.uploadSettings = global.GENEVA_CONFIG.uploadSettings
  }

  if (global.GENEVA_CONFIG.AM_URL) {
    config.am.url = global.GENEVA_CONFIG.AM_URL
  }

  if (global.GENEVA_CONFIG.AD_SUPPORT) {
    config.ad_support = global.GENEVA_CONFIG.AD_SUPPORT
  }

  if (global.GENEVA_CONFIG.MM_IFRAME_URL) {
    config.mm.url = global.GENEVA_CONFIG.MM_IFRAME_URL
  }

  if (global.GENEVA_CONFIG.env) {
    if (global.GENEVA_CONFIG.env.useProjectFilter) {
      config.useProjectFilter = global.GENEVA_CONFIG.env.useProjectFilter
    }

    if (global.GENEVA_CONFIG.env.useGoogleTranslate) {
      if (global.GENEVA_CONFIG.plugins && global.GENEVA_CONFIG.plugins['google-translate']) {
        config.useGoogleTranslate = global.GENEVA_CONFIG.env.useGoogleTranslate
      }
    }

    if (global.GENEVA_CONFIG.env.editorVersion) {
      config.editorVersion = global.GENEVA_CONFIG.env.editorVersion
    }

    if (global.GENEVA_CONFIG.env.useReviewCenter) {
      config.useReviewCenter = global.GENEVA_CONFIG.env.useReviewCenter
    }

    if (global.GENEVA_CONFIG.env.useBynderIntegration) {
      config.useBynderIntegration = global.GENEVA_CONFIG.env.useBynderIntegration
    }

    if (global.GENEVA_CONFIG.env.lastOpenedProjectsCount) {
      config.lastOpenedProjectsCount = global.GENEVA_CONFIG.env.lastOpenedProjectsCount
    }

    if (global.GENEVA_CONFIG.env.linkSettings) {
      config.linkSettings = global.GENEVA_CONFIG.env.linkSettings
    }

    if (global.GENEVA_CONFIG.env.seoSettings) {
      config.seoSettings = global.GENEVA_CONFIG.env.seoSettings
    }

    if (global.GENEVA_CONFIG.env.hideBreadcrumbOption) {
      config.hideBreadcrumbOption = global.GENEVA_CONFIG.env.hideBreadcrumbOption
    }

    if (global.GENEVA_CONFIG.env.hideFromRobotsOption) {
      config.hideFromRobotsOption = global.GENEVA_CONFIG.env.hideFromRobotsOption
    }

    if (global.GENEVA_CONFIG.env.services) {

      if (global.GENEVA_CONFIG.env.services.bynder) {
        config.bynder.url = global.GENEVA_CONFIG.env.services.bynder.url
      }

      if (global.GENEVA_CONFIG.env.services.censhare) {
        config.censhare = true
      }
    }
  }
  if (global.GENEVA_CONFIG.compiler) {
    if (global.GENEVA_CONFIG.compiler.compileType) {
      config.compiler.compileType = global.GENEVA_CONFIG.compiler.compileType
    }
    // currently not used, but part of the overal config
    if (global.GENEVA_CONFIG.compiler.minify) {
      config.compiler.minify = global.GENEVA_CONFIG.compiler.minify
    }
    if (global.GENEVA_CONFIG.compiler.cacheBusterMethod) {
      config.compiler.cacheBusterMethod = global.GENEVA_CONFIG.compiler.cacheBusterMethod
    }
  }
}
