if (!Promise.prototype.finally) {
  // This is how Domenic Denicola proposes to implement finally():
  // https://github.com/domenic/promises-unwrapping/issues/18
  // eslint-disable-next-line
  Promise.prototype.finally = function (callback) {
    const p = this.constructor
    // We don’t invoke the callback in here,
    // because we want then() to handle its exceptions
    return this.then(
      // Callback fulfills: pass on predecessor settlement
      // Callback rejects: pass on rejection (=omit 2nd arg.)
      value => p.resolve(callback()).then(() => value),
      reason => p.resolve(callback()).then(() => {
        throw reason
      })
    )
  }
}


// eslint-disable-next-line no-extend-native
Date.prototype.asInputValue = function asInputValue() {
  const local = new Date(this)
  local.setMinutes(this.getMinutes() - this.getTimezoneOffset())
  return local.toJSON().slice(0, 10)
}
Date.fromInputValue = function fromInputValue(dateStr: string) {
  return new Date(
    ...dateStr.split('-').map((val, i) => parseInt(i === 1 ? val - 1 : val, 10))
  )
}
Date.diffDays = function diffDays(date1: Date, date2: Date) {
  return Math.round(
    Math.abs(
      (date1.getTime() - date2.getTime()
      ) / (24 * 3600 * 1000))
  )
}
