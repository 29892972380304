import PropTypes from 'prop-types'
import React, { Component } from 'react'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import { autobind } from 'core-decorators'
import { store as uiState } from '../../ui'
import PageTreeContainer from './Tree'
import ProjectSelect from '../components/ProjectSelect.js'

const css = /* typeof window === 'undefined' ? {} : */require('../styles.scss')


@observer
export default class NavigationContainer extends Component {

  static propTypes = {
    auth: PropTypes.object.isRequired,
    page: PropTypes.object.isRequired,
    project: PropTypes.object.isRequired,
    ui: PropTypes.object.isRequired,
  }


  getSidebarSize() {
    const projectTreeColumnSize =      this.props.ui.paneState.projectTreeOpen
      ? this.props.ui.paneState.projectTreeColumnSize
      : 0

    const total = projectTreeColumnSize

    return {
      total,
      project: parseInt(projectTreeColumnSize / total * 12, 10)
    }
  }

  @autobind
  handleChangePageColumnVisibility() {

    const { paneState } = uiState

    paneState.projectTreeOpen = !paneState.projectTreeOpen

  }


  render() {

    const { ui, project } = this.props

    const currentProject = project.current

    return (<div
      className={classNames(
        'grid-block vertical left overlay sidebar',
        css.projectNavigation
      )}
    >
      <ProjectSelect
        currentProject={currentProject}
      />

      {
        currentProject.id
          ? <PageTreeContainer
            className={classNames(
              'content', css.pageTreeContainer
            )}
            page={this.props.page}
            project={currentProject}
            ui={ui}
          />
          : null
      }
    </div>)
  }


}
