import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { confirmable } from 'react-confirm'
import { autobind } from 'core-decorators'
import classNames from 'classnames'
import { formatMessage, FormattedMessage } from '../../../translations'

import { Dialog } from '../../../shared/components/Dialog'
import GenevaButton from '../../../ui/components/GenevaButton'
import { testClass } from '../../../shared/utils'

const css = /* typeof window === 'undefined' ? {} : */require('../../styles.scss')

@confirmable
class NewPage extends Component {

  static propTypes = {
    show: PropTypes.bool,            // indicates if the dialog is shown or not.
    proceed: PropTypes.func,         // call to close the dialog with promise resolved.
    cancel: PropTypes.func,          // call to close the dialog with promise rejected.
    dismiss: PropTypes.func,         // call to only close the dialog.
    confirmation: PropTypes.string,  // arguments of your confirm function
    options: PropTypes.object        // arguments of your confirm function
  }

  constructor(props) {

    super(props)
    this.state = {
      title: '',
      templateId: this.props.options.pageTemplates[0].id,
    }
  }

  @autobind
  getFocus() {
    return this.focus
  }

  @autobind
  handleRef(el) {
    if (el !== null) {
      this.focus = el
    }
  }

  @autobind
  handleTemplateSelectChange({ target }) {
    this.setState({
      templateId: target.value
    })
  }

  @autobind
  handleInputChange({ target }) {
    this.setState({
      [target.id]: target.value
    })
  }

  @autobind
  handleCommit(e) {
    if (e && e.preventDefault) {
      e.preventDefault()
    }
    return (
      this.props.proceed({
        ...this.state,
        templateId: parseInt(this.state.templateId, 10),
      })
    )
  }

  @autobind
  renderFooter() {
    return (
      <div className="grid-content text-right">
        <GenevaButton
          type="button"
          className="small button"
          onClick={this.props.dismiss}
        >
          <FormattedMessage id="new-page-dialog.cancel" />
        </GenevaButton>
        <GenevaButton
          type="submit"
          className="small primary button"
          onClick={this.handleCommit}
          disabled={this.state.title.length < 2}
        >
          <FormattedMessage id="new-page-dialog.confirm" />
        </GenevaButton>
      </div>
    )
  }

  render() {

    const {
      show,
      options: { pageTemplates },
    } = this.props

    return (
      <Dialog
        className={classNames(
          css.newPageDialog,
          testClass('new-page-dialog'))
        }
        overlayClassName={css.tableDialogOverlay}
        isOpen={show}
        onRequestClose={this.handleRequestClose}
        renderFooter={this.renderFooter}
        title={formatMessage({ id: 'new-page-dialog.title' })}
        focusEl={this.getFocus}
      >

        <div className="vertical grid-block">

          <div className="grid-content top-content">

            <label className="vertical" htmlFor="new-page-template">
              <FormattedMessage id="new-page-dialog.new-page-type" />

              <select
                id="new-page-template"
                value={this.state.templateId}
                onChange={this.handleTemplateSelectChange}
              >
                {
                  pageTemplates.map((template) => {
                    return (<option
                      key={template.id}
                      value={template.id}
                    >{template.name}
                    </option>)
                  })
                }
              </select>
            </label>
          </div>


          <div className="grid-content">

            <label htmlFor="title">
              <FormattedMessage
                id="new-page-dialog.new-page-title"
              />
              <input
                type="text"
                id="title"
                maxLength={60}
                value={this.state.title}
                ref={this.handleRef}
                onChange={this.handleInputChange}
              />
            </label>

          </div>

        </div>

      </Dialog>
    )
  }
}

export default NewPage
