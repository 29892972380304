import { createConfirmation } from 'react-confirm'
import CenshareRefreshDialog from '../../components/dialogs/CenshareRefresh'

const censhareRefresh = createConfirmation(CenshareRefreshDialog)


export default function (confirmation, options = {}) {

  if (typeof confirmation !== 'string') {
    options = { ...confirmation }
    confirmation = ''
  }

  return censhareRefresh({ confirmation, options })
}
