import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { confirmable } from 'react-confirm'
import { autobind } from 'core-decorators'

import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { formatMessage, FormattedMessage } from '../../../translations'

import GenevaButton from '../../../ui/components/GenevaButton'

import i18n from '../../utils/i18n'
import { getAvailableFontStyles } from '../../utils'
import { Dialog } from '.'

const css = /* typeof window === 'undefined' ? {} : */require('./styles.scss')

const FONTSTYLE_DIALOG_SMALL_SIZE = 480

@confirmable
@observer
class FontStyleDialog extends Component {

  /* eslint-disable react/sort-comp */
  /* as of https://github.com/yannickcr/eslint-plugin-react/issues/100 */
  @observable className = 'none'

  @observable status = null
  /* eslint-enable react/sort-comp */

  static propTypes = {
    show: PropTypes.bool,            // indicates if the dialog is shown or not.
    proceed: PropTypes.func,         // call to close the dialog with promise resolved.
    cancel: PropTypes.func,          // call to close the dialog with promise rejected.
    dismiss: PropTypes.func,         // call to only close the dialog.
    confirmation: PropTypes.string,  // arguments of your confirm function
    options: PropTypes.object        // arguments of your confirm function
  }

  constructor(props) {

    super(props)

    const { modal } = props.options
    // normalize the className value:
    // remove whitespaces
    this.className = this.normalizeClassNameValue(modal.className)
    this.status = modal.status

  }

  getResultData() {
    return {
      className: this.className,
      status: this.status
    }
  }


  @autobind
  getFocus() {
    return this.focus
  }

  normalizeClassNameValue(className) {
    return className.replace(/,\s*/g, ', ')
  }


  @autobind
  handleCommit(e) {
    if (e && e.preventDefault) {
      e.preventDefault()
    }
    this.status = 'committed'
    return this.props.proceed(this.getResultData())
  }

  @autobind
  handleCancel() {
    this.status = 'cancelled'
    return this.props.proceed(this.getResultData())
  }

  @autobind
  handleChange({ target: { type, value, name, checked } }) {
    // eslint-disable-next-line no-nested-ternary
    const val = type === 'checkbox'
      ? checked
      : (type === 'number' ? value * 1 : value)

    this[name] = val
  }


  @autobind
  handleAfterOpen() {
    this.status = 'editing'
  }

  @autobind
  handleAutoFocus({ target }) {
    this.focus = target
  }

  @autobind
  renderFooter() {
    return (<div className="grid-content text-right">

      <GenevaButton
        type="button"
        className="small button float"
        onClick={this.handleCancel}
      >
        <FormattedMessage id="font-style-dialog.cancel" />
      </GenevaButton>

      <GenevaButton
        type="submit"
        className="small primary button"
        onClick={this.handleCommit}
      >
        <FormattedMessage id="font-style-dialog.ok" />
      </GenevaButton>

    </div>)
  }

  render() {
    return (
      <Dialog
        className={css.foreColorDialog}
        overlayClassName={css.foreColorDialogOverlay}
        isOpen={this.props.show}
        onAfterOpen={this.handleAfterOpen}
        onRequestClose={this.props.dismiss}
        onChange={this.handleChange}
        modalStyle={{
          content: {
            width: FONTSTYLE_DIALOG_SMALL_SIZE
          }
        }}
        renderFooter={this.renderFooter}
        title={formatMessage({ id: 'font-style-dialog.font-style' })}
        focusEl={this.getFocus}
      >

        {getAvailableFontStyles().map((className) => {
          const value = this.normalizeClassNameValue(className.value)
          return (<label
            key={className.value}
            htmlFor={`radio-className-${className.name}`}
            className={css.colorItemLabel}
          >
            <input
              type="radio"
              name="className"
              value={value}
              id={`radio-className-${className.name}`}
              checked={value === this.className}
            />
            <span
              className={css.fontStyleItem}
              title={className.label}
              style={{
                backgroundColor: 'white'
              }}
            >
              { className.name.slice(0, 1) }
            </span>
            <span>
              {i18n(className.translations, 'name') || className.label}
            </span>
          </label>)
        })}

      </Dialog>
    )
  }
}

export default FontStyleDialog
