import htmlSplit from '../../../shared/html-split'

function reverseStr(str) {
  return str.split('').reverse().join('')
}
function removeMarkers(html, markerCssClass) {
  const div = document.createElement('div')
  div.innerHTML = html

  const markers = div.querySelectorAll(`.${markerCssClass}`)
  Array.prototype.forEach.call(markers, (node) => {
    const parent = node.parentNode
    while (node.firstChild) {
      parent.insertBefore(node.firstChild, node)
    }
    parent.removeChild(node)
  })

  return div.innerHTML
}

function getTextLength(html) {
  const div = document.createElement('div')
  div.innerHTML = html
  return div.textContent.length
}

export default function (maxLen, opts = {}) {
  return (scribe) => {

    const SCRIBE_MARKER = '<em class="scribe-marker"></em>'

    const SCRIBE_MAX_LEN_MARKER_TAG_NAME = opts.markerTagName || 'g'
    const SCRIBE_MAX_LEN_MARKER_CSS_CLASS = opts.markerCssClass || 'max-len-marker'

    // all block elements and special candidates like tr/td... wthat would cause
    // layout or logic changes
    const unsplitables = [
      // block
      'div', 'ol', 'ul', 'li', 'p', 'table', 'tbody', 'thead', 'tfoot', 'address', 'dl', 'dd', 'section', 'article', 'aside', 'pre',
      'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'blockquote', 'footer',
      // special
      'tr', 'th', 'td', 'df'
    ]

    const closingBlockRegExp = new RegExp(`(</(?:${unsplitables.join('|')})>)+$`, 'gi')

    // force DOM Mutation event
    scribe.el.addEventListener('input', () => {

      if (scribe.getTextContent().length >= maxLen ||
          scribe.el.innerHTML.indexOf(SCRIBE_MAX_LEN_MARKER_CSS_CLASS) >= 0) {
        const forceNode = document.createElement('force')
        scribe.el.appendChild(forceNode)
      }
    }, false)

    function mark(html) {
      const marker = document.createElement(SCRIBE_MAX_LEN_MARKER_TAG_NAME)
      marker.className = SCRIBE_MAX_LEN_MARKER_CSS_CLASS
      const sandbox = document.createElement('div')
      sandbox.innerHTML = html
      const treeWalker = document.createTreeWalker(
        sandbox,
        NodeFilter.SHOW_TEXT
      )
      const textNodes = []
      while(treeWalker.nextNode()) {
        textNodes.push(treeWalker.currentNode)
      }
      textNodes.map((node) => {
        const m = marker.cloneNode()
        const parent = node.parentNode
        m.textContent = node.textContent
        parent.insertBefore(m, node)
        parent.removeChild(node)
      })
      return sandbox.innerHTML
    }

    function merge (start, end) {

      // ensure that unsplitables remain together
      if (closingBlockRegExp.test(start)) {
        const tags = start.match(closingBlockRegExp)
        const openingTags = tags.map((tag) => tag
          .replace(/<\//g, '<')
          .replace(/>/g, '[^>]*>'))
          .join('')
          .split('><')
          .map((tag) => {
            if (tag.indexOf('<') === -1) {
              tag = '<' + tag
            }
            if (!/>$/.test(tag)) {
              tag = tag + '>'
            }
            return tag
          })
          .reverse()
          .join('')
        const openingTagsRegExp = new RegExp(`^${openingTags}`)
        start = start.replace(closingBlockRegExp, '')
        end = end.replace(openingTagsRegExp, '')
      }

      const html = `${start}${end}`

      // TODO: prevent things like <b>H</b><b>e</b><b>l</b>...

      return html
    }

    scribe.registerHTMLFormatter('normalize', (html) => {

      // this has to happen outside of the conditional always,
      // as markers should be removed always
      html = removeMarkers(html, SCRIBE_MAX_LEN_MARKER_CSS_CLASS)

      if (getTextLength(html) > maxLen) {

        let [start, end] = htmlSplit(html, maxLen)

        if (end) {

          // If the cursor was at the end of the allowed number of tokens, move
          // it into the marker
          if (reverseStr(start).indexOf(reverseStr(SCRIBE_MARKER)) === 0) {
            start = start.replace(SCRIBE_MARKER, '')
            end = SCRIBE_MARKER + end
          }

          end = mark(end)

          // const markedRest = `<${SCRIBE_MAX_LEN_MARKER_TAG_NAME} class="${SCRIBE_MAX_LEN_MARKER_CSS_CLASS}">${end}</${SCRIBE_MAX_LEN_MARKER_TAG_NAME}>`

          html = merge(start, end)

        }
      }

      return html

    })

    function cleanup(html) {
      const sandbox = document.createElement('div')
      sandbox.innerHTML = html

      const markers = sandbox.querySelectorAll(`.${SCRIBE_MAX_LEN_MARKER_CSS_CLASS}`)
      Array.prototype.forEach.call(markers, (node) => {
        if (!(node && node.parentNode)) {
          return
        }
        const parent = node.parentNode
        node.parentNode.removeChild(node)
        if (parent.textContent.length === 0) {
          parent.parentNode.removeChild(parent)
        }
      })

      // if (html.indexOf(SCRIBE_MARKER) === -1) {
      //   html += SCRIBE_MARKER
      // }

      return sandbox.innerHTML
    }

    scribe.getTruncatedContent = function getTruncatedContent() {
      return cleanup(scribe.getContent())
    }

    // eslint-disable-next-line arrow-body-style
    // scribe.el.addEventListener('blur', () => {
    //   window.setTimeout(() => {
    //     scribe.setHTML(cleanup(scribe.getHTML()))
    //   }, 100)
    // }, true)
  }
}
