import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { autobind } from 'core-decorators'
import classNames from 'classnames'
import PerfectScrollbar from 'perfect-scrollbar'
import { FormattedMessage } from '../../../translations'

import { deepGet } from '../../../shared/obj'
import Icon from '../../../shared/icons'
import { store as articleStore } from '../..'
import { store as contextStore } from '../../../context'
import { VersionStore } from '../reducers'
import VersionListItem from '../components/VersionListItem'
import GenevaButton from '../../../ui/components/GenevaButton'

const css = /* typeof window === 'undefined' ? {} : */ require('../../styles.scss')

@observer
export default class VersionDetailsContainer extends Component {

  static propTypes = {
    article: PropTypes.object.isRequired,
    currentVersion: PropTypes.number.isRequired,
    articleBaseLink: PropTypes.string.isRequired,
  }

  static contextTypes = {
    router: PropTypes.object.isRequired
  }

  constructor(props) {

    super(props)

    this.state = {
      isBusy: false
    }

    this.ps = null

    this.openVersion(this.props.currentVersion)

    const article = deepGet(this.props, 'article.current')

    if (article && article.versionStore.collection.length < article.versions.length) {
      // if the versionStore was never fully loaded (partial load comes from a page load)
      article.versionStore = new VersionStore({
        article
      })
    }

  }

  componentDidMount() {
    this.ps = new PerfectScrollbar('#versionList-container', {
      suppressScrollX: true
    })
  }

  componentWillReceiveProps(nextProps) {

    if (this.props.currentVersion !== nextProps.currentVersion) {
      this.toggleBusyState()
      this.openVersion(nextProps.currentVersion)
        .then(() => {
          this.toggleBusyState()
        })
    }
  }

  openVersion(versionId) {

    // If the version compare mode is on do not load a version on opening
    if (contextStore.type === 'version' && contextStore.contextLock) {
      return Promise.resolve()
    }

    // Resets the context, so that the elements of the original article
    // can't be edited
    contextStore.set(null)

    return articleStore.loadVersion(versionId)
  }

  toggleBusyState() {
    this.setState({
      isBusy: !this.state.isBusy
    })
  }

  @autobind
  handleVersionButtonClicked() {
    this.handleVersionAction()
      .then(() => {
        this.toggleBusyState()
      })
      .catch(() => {
        this.toggleBusyState()
      })
  }

  // Start or End the Version Compare Mode by context
  @autobind
  handleVersionCompareClick() {
    const article = this.props.article.current

    // bail out if nothing to compare to
    if (!article.versionNumber) {
      return
    }

    // End the compare mode and exit
    if (contextStore.type === 'version') {
      contextStore.unlockContext()
      contextStore.createFromElement(null)
      return
    }

    const {
      currentVersion
    } = this.props

    const compareTo = currentVersion === -1
      ? article.versions.slice(-1)[0].versionNumber
      : article.versionNumber + 1

    const compareFrom = currentVersion === -1
      ? article.versionNumber + 1
      : currentVersion

    contextStore.createFromElement({
      type: 'version',
      compareFrom,
      compareTo
    })

    // lock the context so it won't change when clicking somewhere
    contextStore.lockContext()
    article.initLockable()
  }

  handleVersionAction() {

    const article = deepGet(this.props, 'article.current')

    this.setState({
      isBusy: true
    })

    if (article.isVersion) {

      // Restore the currently selected Version and set it as origin
      return articleStore.restoreVersion(article, this.props.currentVersion)
        .then(() => {
        // Update the URL after new origin was set
        // This URL push will cause the load of the new origin
          const url = `${this.props.articleBaseLink}/version/${article.origin.instanceVersion}`
          this.context.router.push(url)
        })

    }

    return articleStore.createVersion(article)
  }

  renderVersionsList() {

    const article = deepGet(this.props, 'article.current')
    const { articleBaseLink } = this.props

    this.selectedVersionNumber = this.props.currentVersion * 1

    if (!(article.versionStore && article.versionStore.collection)) {
      return <FormattedMessage id="article.version.missing" />
    }

    const list = [

      // Removing the version 0 from the list.
      // It is not needed and not loadable for now
      ...article.versionStore.collection.slice(1)
    ]

    if (this.ps) {
      this.ps.update()
    }

    return (
      <div className={classNames('grid-block vertical', css.versionTab)}>

        <div className="versioning-tools">
          <GenevaButton
            className="small button"
            disabled={this.state.isBusy}
            onClick={this.handleVersionButtonClicked}
          >
            {
              article.isVersion
                ? <FormattedMessage id="article.version.restore-version" />
                : <FormattedMessage id="article.version.new" />
            }
          </GenevaButton>
        </div>

        <div className="version-compare" onClick={this.handleVersionCompareClick}>
          { contextStore.type === 'version'
            ? [
              <Icon key="toggle-filled" name="ion-toggle-filled" />,
              <FormattedMessage key="comparing-end" id="article.version.compare-end" />,
              <Icon key="close" name="ion-ios-close-empty" />,
            ]
            : [
              <Icon key="toggle" name="ion-toggle" />,
              <FormattedMessage key="comparing-start" id="article.version.compare-start" />
            ]
          }
        </div>

        <div
          id="versionList-container"
          className="grid-block vertical version-listing"
        >
          <ul className={classNames(css.versionList)}>

            <VersionListItem
              isVersionLoading={this.state.isBusy}
              key={article.id}
              version={article.origin || article}
              selectedVersionNumber={this.selectedVersionNumber}
              articleBaseLink={articleBaseLink}
              versionListLength={list.length}
              context={contextStore}
            />

            {
              list.reverse().map(version => (
                <VersionListItem
                  isVersionLoading={this.state.isBusy}
                  key={version.id + version.articleId}
                  version={version}
                  selectedVersionNumber={this.selectedVersionNumber}
                  articleBaseLink={articleBaseLink}
                  context={contextStore}
                />
              ))
            }
          </ul>
        </div>
      </div>)
  }

  render() {

    return (<div className={classNames(
      'grid-block',
      css.toolsPanel,
      css.versionTab
    )}>
      {this.renderVersionsList()}
    </div>)
  }
}
