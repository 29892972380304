import onClickOutside from 'react-onclickoutside'
import Sortable from 'react-anything-sortable'
import ContentRenderer from '../../components/ContentRenderer'
import SortableItem from '../../components/ContentRenderer/SortableItem.js'
import * as ui from '../../ui'
import ArticleListItem from '../../../page/components/SelectArticleItem'
import DependencyCollector from '../../../shared/template/DependencyCollector'
// eslint-disable-next-line max-len
import connectArticleListItemToNormalizer from '../../ui/connectors/connectArticleListItemToNormalizer'
import connectInsertArticleToPage from '../../ui/connectors/connectInsertArticleToPage'
import connectEditArticleToPage from '../../ui/connectors/connectEditArticleToPage'
import connectRemoveArticleToPage from '../../ui/connectors/connectRemoveArticleToPage'
import connectMoveArticleToPage from '../../ui/connectors/connectMoveArticleToPage'


/**
 * The dependency collector used by the Article Editor (CM)
 */
export default
class TemplateDependencyCollector
  extends DependencyCollector {

  getConnectionProps() {
    return {
      widgetKeyValues: this.props.widget.current.keyValueAccessor || null,
      content: this.props.widget.current.content || [],
      items: this.props.widget.current.items || [],
    }
  }

  collect() {

    return {
      ...super.collect(),

      // TODO: remove page.current
      ui: {
        ...ui,
        ArticleInsertButton: connectInsertArticleToPage(ui.ArticleSelectButton, {
          page: this.props.page.current,
        }),
        ArticleEditButton: connectEditArticleToPage(ui.ArticleContextButton, {
          page: this.props.page.current,
        }),
        ArticleRemoveButton: connectRemoveArticleToPage(ui.ArticleContextButton, {
          page: this.props.page.current,
        }),
        ArticleMoveButton:
        connectMoveArticleToPage(ui.ArticleContextButton, {
          page: this.props.page.current,
        }),
        ArticleListItem:
        connectArticleListItemToNormalizer(ArticleListItem),
      },

      utils: {
        onClickOutside
      },

      // data
      ContentRenderer: this.connectors.connectContentRendererToContext(
        ContentRenderer, {
          env: this.ENV,
          content: this.props.widget.current.content,
          widget: this.props.widget.current,
          items: this.props.page.current.items,
          page: this.props.page.current,
        },
        this.contextStore
      ),

      SortableItem,
      SortableContainer: Sortable

    }

  }
}
