export const VALIDATION_ID_TEXT_EDITOR_WILDCARD = 'text-editor:*'
export const VALIDATION_ID_TEXT_EDITOR_INFO = 'text-editor:validation-info'
export const VALIDATION_ID_TEXT_EDITOR_ERROR = 'text-editor:validation-error'

export const DEFAULT_LANG = 'en'

export const GENEVA_CLASS_PREFIX = 'geneva'

export const PAGE_TYPE_STARTPAGE = 1
export const PAGE_TYPE_SPECIAL_PAGE = 2
export const PAGE_TYPE_ARCHIVE_PAGE = 3
export const PAGE_TYPE_ARCHIVE_SPECIAL_PAGE = 4
export const PAGE_TYPE_TREE_ROOT = 5

export const PAGE_ID_STARTPAGE = -1
export const PAGE_ID_SPECIAL_PAGE = -2
export const PAGE_ID_ARCHIVE_PAGE = -3
export const PAGE_ID_ARCHIVE_SPECIAL_PAGE = -4
export const PAGE_ID_ARCHIVE_TREE_ROOT = -5

export const specialPageIds = [
  PAGE_ID_STARTPAGE,
  PAGE_ID_SPECIAL_PAGE,
  PAGE_ID_ARCHIVE_PAGE,
  PAGE_ID_ARCHIVE_SPECIAL_PAGE,
  PAGE_ID_ARCHIVE_TREE_ROOT
]

// These strings come from the server as such.
export const SERVER_RESPONSE_AUTH_INVALID_INPUT = 'Invalid Input.'
export const SERVER_RESPONSE_AUTH_INVALID_CREDENTIALS = 'Could not login the user with the given credentials.'

export const TEMPORARY_PUBLISHED_URL = ' '
export const TEMPORARY_UNPUBLISHED_URL = 'UNPUBLISHING'

// This are shortcuts that may be used througut the system
export const shortcut = {
  // Use this to close an app. E.g. ControlCenter, Editor, Image Editor...
  CLOSE_APP: 'alt+w',
  // Use this to open a currently selected item. E.g. in a page the currently
  // selected article.
  OPEN_ITEM: 'alt+o',
  // Use this to remove an item. E.g. in a page remove the currently
  // selected article from that page.
  REMOVE_ITEM: 'backspace',
  // Use this to delete an item. E.g. in a page permanently delete the currently
  // selected article.
  DELETE_ITEM: 'alt+backspace',
  // Use this to select the following item. E.g. in a page select the next
  // article.
  SELECT_NEXT_ITEM: 'down',
  // Use this to select the previous item. E.g. in a page select the previous
  // article.
  SELECT_PREV_ITEM: 'up',
  // Use this to open the following item. E.g. in a page open the next
  // article. (Works also in editor.)
  OPEN_NEXT_ITEM: 'alt+down',
  // Use this to open the previous item. E.g. in a page open the previous
  // article. (Works also in editor.)
  OPEN_PREV_ITEM: 'alt+up',

  // Use this to move an item left (e.g. a positionable)
  MOVE_LEFT: 'alt+left',
  // Use this to move an item right (e.g. a positionable)
  MOVE_RIGHT: 'alt+right',
  // Use this to move an item UP (e.g. a positionable)
  MOVE_UP: 'alt+up',
  // Use this to move an item down (e.g. a positionable)
  MOVE_DOWN: 'alt+down',

  // Use this to move an item left (e.g. a positionable)
  MOVE_LEFT_FAST: 'ctrl+alt+left',
  // Use this to move an item right (e.g. a positionable)
  MOVE_RIGHT_FAST: 'ctrl+alt+right',
  // Use this to move an item UP (e.g. a positionable)
  MOVE_UP_FAST: 'ctrl+alt+up',
  // Use this to move an item down (e.g. a positionable)
  MOVE_DOWN_FAST: 'ctrl+alt+down',


  ALT: 18,
}

// This is the connection of permission names used in the system
// and the names delivered by the AM
export const permissionList = {
  getProject: 'projectAccess',
  getProjectLang: 'projectLanguage',
  controlCenter: 'projectControlCenter',

  createPage: 'editPage',
  pageSettingDialog: 'pageSettings',
  pageSecurityDialog: 'pageRights',
  publishPage: 'pagePublish',
  unpublishPage: 'pageUnpublish',
  archivePage: 'pageArchive',
  deletePage: 'pageDelete',

  editArticle: 'editArticle',                   // valid for widgets too
  deleteArticle: 'articleDelete',               // valid for widgets too
  rcAccess: 'rcAccess',
  replaceImageLanguageVariants: 'replaceImageLanguageVariants'
}

export const sounds = {
  PING: '/sounds/beep-piano.mp3',
  FAIL: '/sounds/beep-reedy.mp3'
}

export const SERVER_TIMEZONE = 'UTC'
export const SERVER_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'
export const ADMIN_MODE = 'AdminMode'
export const GENEVA_DEFAULT_ARTICLE_NAME = 'Neuer Artikel'
export const GENEVA_DEFAULT_WIDGET_NAME = 'Neues Widget'
