import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { autobind } from 'core-decorators'
import cancelable from '../../../../../shared/decorators/cancelable-promise'

import deprecationWarning from '../../../../../shared/utils/deprecationWarning'

import { dispatcher } from '../../../../../shared/lib/command'

@dispatcher
@observer
@cancelable
export default
class PageConnectedInsertArticleButton
  extends Component {

  static propTypes = {
    disabled: PropTypes.bool,
    /** @deprecated */
    onGetPosition: PropTypes.func,
    onGetContext: PropTypes.func,
    page: PropTypes.object,
    viewModels: PropTypes.shape({
      currentlyOpenWidget: PropTypes.shape({
        item: PropTypes.object,
      })
    }),
    Target: PropTypes.func.isRequired,
    router: PropTypes.shape({
      push: PropTypes.func
    }).isRequired,
    commands: PropTypes.object
  }

  constructor(props) {
    super(props)
    this.state = {
      // It may happen that multiple entries are added at the same time (batch
      // entry, fast user, ...), so in those cases just setting the state to
      // loading and back to not loadng once the first item was loaded
      // successfully would cause some trouble. So just count how many items
      // are being loaded currently.
      countLoading: 0,
      // there can only one item that should be opened after insertion, so just
      // a simple boolean state indecator is enough for that
      disabled: false
    }
  }

  @autobind
  handleContentActionTriggered({ openForEditing, ...item } = {}) {

    const { countLoading } = this.state

    this.context.dispatch(

      this.props.commands.CreateAndInsertNewContentIntoWidgetCommand,
      this.getDataForCreateAndInsertNewContentIntoWidgetCommand({
        openForEditing,
        item
      }),
      // TODO: this state managemenet has to wander into the viewmodel
      {
        'command:start': () => {
          this.setState({
            // up-count the number of items that are loaded currently
            countLoading: Math.max(0, countLoading) + 1,
            // only force-disable, if the user want's to open the article
            // after insertion. Otherwise he could navigate somewhere else
            // while the article is being inserted
            // TODO: this should disable all buttons!
            disabled: openForEditing
          })
        },
        'command:error': () => {
          this.setState({
            // down-count the amount of currently loading items
            countLoading: Math.max(0, countLoading - 1),
            disabled: false
          })
        },
        'command:end': () => {
          this.setState({
            // down-count the amount of currently loading items
            countLoading: Math.max(0, countLoading - 1),
            disabled: false
          })
        }
      }

    )


  }

  getDataForCreateAndInsertNewContentIntoWidgetCommand({
    item,
    openForEditing
  }) {

    return {
      commands: this.props.commands,
      context: this.getContext(),
      widgetId: this.props.viewModels.currentlyOpenWidget.data.item.id,
      item,
      openForEditing,
      router: this.props.router
    }
  }

  getContext() {
    const { onGetContext, onGetPosition } = this.props
    let context = {}

    if (onGetContext) {
      const tmpContext = onGetContext()
      if (tmpContext) {
        context = tmpContext
      }

    }

    if (!context.position && onGetPosition) {
      context.position = deprecationWarning(
        onGetPosition,
        null,
        {
          title: '<ArticleInsertButton onGetPosition={...} />',
          url: 'http://git.atrivio.net:3000/geneva/frontend/blob/master/docs/WIDGET_SETTINGS_DIALOG.md'
        }
      )()
    }

    if (!context.position) {
      context.position = [0]
    }

    return context
  }


  render() {

    const {
      Target,
      page,
      onGetPosition,
      onGetContext,
      disabled,
      ...rest
    } = this.props

    return (<Target
      {...rest}
      onItemSelected={this.handleContentActionTriggered}
      pageId={page.id}
      disabled={
        !!this.state.countLoading
        || this.state.disabled
        || disabled
      }
    />)

  }
}
