
import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import classNames from 'classnames'
import { FormattedMessage } from '../../../translations'
import * as css from './styles.scss'

// eslint-disable-next-line react/prefer-stateless-function
class ContentLoadingBox extends Component {
  static contextTypes = {
  };

  // Change the loader here
  renderDots(inverseColors) {
    return (
      <div className={css.spinner}>
        <div className={classNames('bounce', css.bounce1, inverseColors ? css.inverseColors : '')}></div>
        <div className={classNames('bounce', css.bounce2, inverseColors ? css.inverseColors : '')}></div>
        <div className={classNames('bounce', css.bounce3, inverseColors ? css.inverseColors : '')}></div>
      </div>
    )
  }

  render() {
    const { message, spinner, className, children, id, inverseColors } = this.props

    return (
      <div
        id={id}
        className={classNames(
          className,
          css.contentLoadingBox,
          'content-loading-box',
          inverseColors ? 'inverse-colors' : ''
        )}
      >
        {spinner ? (
          <div className={css.contentLoadingBoxSpinner}>
            {this.renderDots(inverseColors)}
          </div>
        ) : (
          false
        )}
        <FormattedMessage
          id={message.id || 'content.loading'}
          values={message.values}
        />
        {children}
      </div>
    )
  }
}

ContentLoadingBox.defaultProps = {
  spinner: true,
  inverseColors: false,
  message: {
    id: 'content.loading',
  },
}

ContentLoadingBox.propTypes = {
  /**
   * The message.
   */
  message: PropTypes.shape({
    id: PropTypes.string,
    values: PropTypes.object,
  }),
  /**
   * Whether or not to show a loading spinner.
   */
  spinner: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  inverseColors: PropTypes.bool,
}

export { ContentLoadingBox as default }
