import { action, observable } from 'mobx'

export default
class TransformationState {

  @observable isActive = false

  @action
  setState(state) {
    Object.assign(this, state)
  }

}
