import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { autobind } from 'core-decorators'
import classNames from 'classnames'
import { FormattedMessage } from '../../translations'

import Navbar, {
  FixedSection
} from '../../shared/components/Navbar'
import GenevaButton from '../../ui/components/GenevaButton'
import * as css from '../styles.scss'

@observer
export default class BynderNavbar extends Component {

  static propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    handleInputChange: PropTypes.func.isRequired,
    navbarStyle: PropTypes.string,
    submitDisabled: PropTypes.bool,
    imageCount: PropTypes.number,
  }

  static contextTypes = {
    router: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props)

    this.OS = (navigator.appVersion.indexOf('Mac') !== -1)
      ? 'MacOS'
      : 'WindowsOS'
  }

  @autobind
  handleCancel() {
    if (this.props.onCancel) {
      this.props.onCancel()
    }
  }

  @autobind
  handleSubmit() {
    if (this.props.onSubmit) {
      this.props.onSubmit()
    }
  }

  renderBrand() {
    const mmApp = { short: 'BI' }

    return (
      <FixedSection>
        <div className={classNames(css.brand, this.OS)}>
          GENEVA
        </div>
        <div className={css.appIcon}
          style={{
            backgroundColor: '#ffffff'
          }}
        >
          {mmApp.short.toUpperCase()}
        </div>
      </FixedSection>
    )
  }

  render() {

    const {
      navbarStyle,
      imageCount,
    } = this.props

    return (
      <Navbar
        navbarStyle={navbarStyle}
      >

        {this.renderBrand()}

        <FixedSection className="navbar-text-container">

          <GenevaButton
            onClick={this.handleCancel}
            className="small button"
          >
            <FormattedMessage id="mm.cancel" />
          </GenevaButton>

        </FixedSection>

      </Navbar>
    )
  }

}
