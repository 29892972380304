import PropTypes from 'prop-types'
import React from 'react'
import ToolsPanel from './ToolsPanel'
const PageToolsDisplayer = ({ ui, page, project, router }) => {
  return ui.paneState.pageToolsPanelOpen
    ? (<ToolsPanel
      pageStore={page}
      channel={(project.hasCurrent && project.current.channelShortcut) || null}
      publishedUrl={(page.current.publishedUrl)}
      unpublishAt={(page.current && page.current.unpublishAt)}
      publishAt={(page.current && page.current.publishAt)}
      releasePublicationLockAt={(page.current && page.current.releasePublicationLockAt)}
      router={router}
    />)
    : null
}

PageToolsDisplayer.propTypes = {
  ui: PropTypes.object,
  page: PropTypes.object,
  project: PropTypes.object,
  router: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
}

export { PageToolsDisplayer as default }
