import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import { autobind } from 'core-decorators'
import { filterElemAttrs, A_ATTRS } from '../../react-helpers'

export default class DefaultEntryLink extends Component {
  static propTypes = {
    children: PropTypes.node,

    identifier: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    checked: PropTypes.bool,
    disabled: PropTypes.bool,

    onMouseDown: PropTypes.func,
  };

  @autobind
  handleClick() {
    if (this.props.onMouseDown) {
      this.props.onMouseDown({
        target: {
          identifier: this.props.identifier,
        },
      })
    }
  }

  render() {
    return (
      <a
        {...filterElemAttrs(this.props, A_ATTRS)}
        onMouseDown={this.handleClick}
      >
        {this.props.children}
      </a>
    )
  }
}
