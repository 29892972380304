import DependencyCollector from '../../../shared/template/DependencyCollector'

// Template components

import Repeatable from '../../components/Repeatable'
import Editor from '../../components/Editor'
import Positionable from '../../components/Positionable'
import Media from '../../components/Media'
import Resizable from '../../components/Resizable'
import Linkable from '../../components/Linkable'
import Hotspot from '../../components/Hotspot'


// connectors

/**
 * The dependency collector used by the PM
 */
export default
class TemplateDependencyCollector
  extends DependencyCollector {

  constructor(opts) {
    opts.componentOverrides = opts.componentOverrides || {}
    super(opts)
  }

  getConnectionProps() {
    return {
      articlePlaceholders: this.props.article.phAccessor || null,
    }
  }

  collect() {
    const components = this.connectClassic()
    return {
      ...components,
      Hotspot: this.connect(
        this.componentOverrides.Hotspot || Hotspot,
        this.connectors.connectHotspotToContext,
        {},
        {
          Resizable: components.Resizable,
          Editor: components.Editor,
          Positionable: components.Positionable
        }
      )
    }
  }

  connectClassic() {

    return {

      ...super.collect(),

      // data
      articlePlaceholders: this.props.article.phAccessor || null,

      Positionable: this.connect(
        this.componentOverrides.Positionable || Positionable,
        this.connectors.connectPositionableToContext
      ),

      Repeatable: this.connect(
        this.componentOverrides.Repeatable || Repeatable,
        this.connectors.connectRepeatableToContext
      ),

      Editor: this.connect(
        this.componentOverrides.Editor || Editor,
        this.connectors.connectEditorToContext,
        {
          onValidate: this.handleValidate,
          toolbarElem: this.props.toolbarElem,
        },
      ),

      Media: this.connect(
        this.componentOverrides.Media || Media,
        this.connectors.connectMediaToContext,
        {
          image: this.props.image,
        }
      ),

      Resizable: this.connect(
        this.componentOverrides.Resizable || Resizable,
        this.connectors.connectResizableToContext
      ),

      Linkable: this.connect(
        this.componentOverrides.Linkable || Linkable,
        this.connectors.connectLinkableToContext
      )

    }

  }
}
