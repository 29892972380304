import { MacroCommand } from '../../shared/lib/command/index.ts'

/**
  * Sorts an item on a page by removing and inserting it
  * @param {Number} itemId - The id of the item model to sort
  * @param {Object} page - The page model which contains the item
  * @param {Object} removeFrom - A position object where to remove it from
  * @param {Object} insertTo - A position object where to insert it
*/
export default class SortArticleCommand extends MacroCommand {

  static storeRequirements = {
    article: true,
    widget: true,
    context: true
  }

  static validationSpec = {
    itemId: MacroCommand.ensureData.isPresent,
    page: MacroCommand.ensureData.isPresent,
    removeFrom: MacroCommand.ensureData.isPresent,
    insertTo: MacroCommand.ensureData.isPresent,
  }

  exec() {
    const {
      page,
      itemId,
      removeFrom
    } = this.data

    if (!this.data.item) {
      this.data.item = this.data.store[removeFrom.type].getById(itemId)
    }

    this.data.store.context.createFromElement(null)
    page.startTransaction()
    page.isSaving = true
    return this.dispatch(
      this.data.commands.RemoveFromPageCommand,
      {
        pageModel: page,
        data: this.getDataForRemove()
      }
    )
      .then(() => {
        return this.dispatch(
          this.data.commands.InsertContentIntoPageCommand,
          this.getDataForInsert()
        )
      })
      .then(() => {
        return page.endTransaction()
      })
      .then(() => {
        this.setContext()
        page.isSaving = false
      })
  }

  setContext() {
    const { insertTo, item } = this.data
    this.data.store.context.createFromElement({
      gr: insertTo.gr,
      gb: insertTo.gb,
      index: insertTo.index,
      type: item.contentType,
      [item.contentType]: item.contentType,
      id: item.id
    })
  }

  getDataForInsert() {
    const {
      item,
      page,
      insertTo,
    } = this.data

    return {
      [item.contentType]: item,
      pageId: page.id,
      type: item.contentType,
      position: {
        gr: insertTo.gr,
        gb: insertTo.gb,
        index: insertTo.index
      }
    }
  }

  getDataForRemove() {
    const {
      removeFrom,
      item
    } = this.data

    return {
      item,
      ...removeFrom
    }
  }
}
