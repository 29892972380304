import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import { autobind } from 'core-decorators'
import { formatMessage } from '../../translations'
import cancelable from '../decorators/cancelable-promise'
// eslint-disable-next-line max-len
import InsertVideoInArticlePlaceholderCommand from '../commands/insert-video-into-article-placeholder'
import RemoveVideoFromArticlePlaceholderCommand from '../commands/remove-video-from-article-placeholder'
import { store as contextStore } from '../../context'
import { store as uiStore } from '../../ui'

export default function connectVideoToolsToCommands(Target) {
  @cancelable
  class ConnectedTarget extends Component {
    static propTypes = {
      value: PropTypes.string,
      onBeforeChangeVideo: PropTypes.func,
      spec: PropTypes.object,
      context: PropTypes.object,
    };

    constructor(props) {
      super(props)
      this.state = {
        value: props.value,
      }
      if (!this.cancelables) {
        this.cancelables = []
      }
    }

    componentWillReceiveProps(nextProps) {
      if (nextProps.value !== this.state.value) {
        const newState = {
          value: nextProps.value,
          ...(!nextProps.value ? { file: null, url: '' } : {}),
        }

        this.setState(newState)
      }
    }

    getVideoDataFromContext() {
      contextStore.assert('video')

      return {
        id: contextStore.target.id,
        value: contextStore.target.value,
        file: contextStore.target.file,
        url: contextStore.target.url,
        mimeType: contextStore.target.mimeType,
        type: contextStore.type,
        constraints: contextStore.target.constraints,
      }
    }

    getPlaceholderDataFromContext() {
      return {
        sourceType: contextStore.sourceType,
        type: contextStore.type,
        article: contextStore.target.article,
        pid: contextStore.target.pid,
        multiple: contextStore.target.multiple,
      }
    }

    @autobind
    handleVideoImportFromBynder() {
      const { target } = this.props.context

      return uiStore.openDialog('bynder', target)
        .then((data) => {
          if (data.status === 'committed') {
            this.handleChangeVideo(data.result[0])
          }
        })
    }

    subscribeToCommandEvents(command) {
      const { spec, context } = this.props

      const width = spec ? spec.minWidth : context.target.constraints.minWidth

      const height = spec
        ? spec.minHeight
        : context.target.constraints.minHeight

      const subscription = command.on('error:constraint-failure', (data) => {
        alert(
          formatMessage(
            { id: 'image.constraint-error' },
            {
              count: data.count,
              width,
              height,
            }
          ),
          formatMessage({ id: 'image.constraint-error-title' })
        )
      })

      this.cancelables.push(subscription)
    }

    @autobind
    handleChangeVideo(input) {
      if (this.props.onBeforeChangeVideo) {
        this.props.onBeforeChangeVideo()
      }

      const videoData = {
        id: input.id,
        url: input.url,
      }

      this.setState(videoData)

      setTimeout(() => {
        const context = contextStore

        contextStore.createFromElement({
          constraints: context.target.constraints,
          pid: context.target.pid,
          article: context.target.article,
          type: context.target.type,
          mimeType: context.target.mimeType,
          multiple: context.target.multiple,
          ...videoData,
        })

        this.handleReplaceVideo()
      }, 1)
    }

    @autobind
    handleReplaceVideo() {
      const videoData = this.getVideoDataFromContext()
      const placeholderData = this.getPlaceholderDataFromContext()

      const cmd = new InsertVideoInArticlePlaceholderCommand(
        videoData,
        placeholderData
      )

      this.subscribeToCommandEvents(cmd)
      const promise = cmd.exec()

      this.makeCancelable(promise).catch((err) => {
        if (this.isCanceledPromise(err)) {
          throw err
        }
        console.error(err)

        this.setState({
          value: '',
          sourceType: null,
          file: null,
          url: '',
        })
      })
    }

    @autobind
    handleRemoveVideo() {
      contextStore.assert('video')

      const { target } = contextStore

      new RemoveVideoFromArticlePlaceholderCommand({
        pid: target.pid,
        article: target.article,
      })
        .exec()
        .then(() => {
          const placeholderValue = target.article.phAccessor.get(target.pid)

          // Recreate the context but now without any value as that's been just
          // removed.
          this.props.context.createFromElement({
            ...target,
            // This should always return null, otherwise something must have went
            // wrong.
            // We still use this call instead of just setting it to null to ensure
            // we are consistent.
            id: placeholderValue,
            url: placeholderValue,
            value: placeholderValue,
          })
        })
    }

    render() {

      return (<Target

        {...this.props}
        {...this.state}

        onChangeVideo={this.handleChangeVideo}
        onReplaceVideo={this.handleReplaceVideo}
        onRemoveVideo={this.handleRemoveVideo}
        onVideoImportFromBynder={this.handleVideoImportFromBynder}
      />)
    }
  }

  return ConnectedTarget
}
