import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import { autobind } from 'core-decorators'

import { FormattedMessage } from '../../../translations'
import GenevaButton from '../../../ui/components/GenevaButton'
import { Dialog } from '.'


const css = /* typeof window === 'undefined' ? {} : */ require('./styles.scss')

/**
 * Overly simple dialog that just informs the user they don't have access to specific page / project
 * This doesn't use standard dialog support functions, just takes a prop function to inform the parent
 * when it's done
 */
export default class MissingAccessDialog extends Component {

  static propTypes = {
    handleHideDialog: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      showMissingAccessDialog: true
    }
  }

  @autobind
  handleCommit(e) {
    if (e && e.preventDefault) {
      e.preventDefault()
    }
    this.props.handleHideDialog(true)
    this.setState({
      showMissingAccessDialog: false
    })
  }

  @autobind
  handleCancel(e) {
    if (e && e.preventDefault) {
      e.preventDefault()
    }
    this.props.handleHideDialog(true)
    this.setState({
      showMissingAccessDialog: false
    })
  }


  @autobind
  renderFooter() {
    return (
      <div className="grid-content text-right">
        <GenevaButton
          type="button"
          className="small button float"
          onClick={this.handleCommit}
        >
          <FormattedMessage id="article-usage-dialog.close" />
        </GenevaButton>
      </div>
    )
  }

  render() {

    return (
      <Dialog
        className={css.noRightsDialog}
        overlayClassName={css.tableDialogOverlay}
        isOpen={this.state.showMissingAccessDialog}
        handleRequestClose={this.handleCancel}
        onCancel={this.handleCancel}
        onCommit={this.handleCommit}
        renderHeader={() => null}
        renderFooter={this.renderFooter}
      >
        <div className="grid-content form-main">
          <div className="no-right-title">
            <FormattedMessage id="article-usage-dialog.no-rights.title" />
          </div>
          <div className="no-right-body">
            <FormattedMessage id="article-usage-dialog.no-rights.body" />
          </div>
        </div>
      </Dialog>
    )
  }
}
