import { createConfirmation } from 'react-confirm'
import FootnoteDialog from '../components/Dialog/FootnoteDialog'

const footnoteDialog = createConfirmation(FootnoteDialog)

export default function (confirmation, options = {}) {

  if (typeof confirmation !== 'string') {

    options = {
      modal: {
        ...confirmation.data,
      }
    }
    confirmation = ''

  }

  return footnoteDialog({ confirmation, options })

}
