import { createConfirmation } from 'react-confirm'
import CenshareImageRefreshDialog from '../../components/dialogs/CenshareImageRefresh'

const censhareImageRefresh = createConfirmation(CenshareImageRefreshDialog)


export default function (confirmation, options = {}) {

  if (typeof confirmation !== 'string') {
    options = { ...confirmation }
    confirmation = ''
  }

  return censhareImageRefresh({ confirmation, options })
}
