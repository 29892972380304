import * as PropTypes from 'prop-types'
import React from 'react'
import moment from 'moment'
import { FormattedMessage, FormattedTime, FormattedDate } from '../../../translations'

const FormattedDateTime = props => (props.value && props.value instanceof moment ? (
  <span>
    <FormattedDate value={props.value} />
      &nbsp;<b>|</b>&nbsp;
    <FormattedTime value={props.value} />
  </span>
) : (
  <FormattedMessage id="article.version.missing-date" />
))

FormattedDateTime.propTypes = {
  value: PropTypes.instanceOf(moment),
}
FormattedDateTime.defaultProps = {
  value: new Date(),
}

export { FormattedDateTime as default }
