import Command from '../../shared/command'
import { deepGet } from '../../shared/obj'
import { createDestConstraints } from './helper'
import { apiClient } from '../../shared/api/decorators/apiClient'

@apiClient
export default class UploadImageBatch extends Command {

  constructor(imageStore, data, opts) {
    super(imageStore, data)
    this.data = data
    this.opts = opts
    this.imageStore = imageStore
  }

  validate(imageStore, data) {
    if (!imageStore) {
      this.throwError('expects an imageStore passed to the command')
    }
    if (!data.channel || !(typeof data.channel === 'string')) {
      this.throwError('expects data passed to the command to contain a channel string')
    }
    if (!data || !Array.isArray(data)) {
      this.throwError('expects `data` passed to the command to be an array')
    }
    if (!data.length) {
      this.throwError('expects at least one image to be passed in `data`')
    }
  }

  returnObjectByType(imageObj) {
    const returnObj = {
      route: null,
      constraints: {
        [this.data.channel]: {
          dest: createDestConstraints(imageObj),
          frame: {
            w: imageObj.frame.width,
            h: imageObj.frame.height,
            x: imageObj.frame.left,
            y: imageObj.frame.top,
          }
        }
      }

    }
    if (imageObj.image.source) {
      returnObj.source = imageObj.image.source
      returnObj.sourceId = imageObj.image.sourceId
    }
    else {
      returnObj.url = imageObj.url
    }
    return {
      data: returnObj,
      route: 'image'
    }
  }

  exec() {
    const data = this.data.map(image => this.returnObjectByType(image))

    const language = deepGet(this.opts, 'params.lang')
    const params = language ? { lang: language } : null

    return this.dispatch('post', {
      path: 'batch',
      data: { jobs: data },
      params
    })
      .then((response) => {
      // changing the structure to work with the rest of the image logic
        if (!response || !response.body || response.error) {
          throw new Error('error in image urls save')
        }
        const entries = response.body.map((result) => {
          if (result.body.data && result.data) {
            return Object.assign({}, result.data, result.body.data)
          }
          return null
        })
        return entries
      })
  }
}
