import { translate as translateConfig } from '../../config'

import { Command } from '../lib/command/index.ts'
import { convertDateToSystemLocal } from '../../shared/DateConverter'
import { apiClient } from '../api/decorators/apiClient'

/**
  * Sends a translation request to the backend
  * then saves the result to the article.meta
  * @param {Object} article - The article model to save the result
  * @param {String} sourceLang - The language the text is written - optional
  * @param {String} targetLang - The language to translate the text to
  * @param {Object} text - The actual text object to translate
  * @param {Object} opts - optional opts.reset for removing the meta.translation
*/
@apiClient
export default class GoogleTranslateCommand extends Command {

  static validationSpec = {
    article: Command.ensureData.isObject,
    sourceLang: Command.ensureData.isString,
    targetLang: Command.ensureData.isString,
    text: Command.ensureData.isObject,
  }

  exec() {
    const {
      article,
      sourceLang,
      targetLang,
      text,
      opts
    } = this.data

    // delete translation meta
    if (opts && opts.reset) {
      article.setMeta({ translationText: undefined })
      return
    }

    if (sourceLang === targetLang) {
      console.warn('Languages are identical. No request was sent.')
      return
    }

    // google uses language pattern like "de" not "de-DE"
    const data = {
      sourceLang: sourceLang.split('-')[0],
      targetLang: targetLang.split('-')[0],
      text
    }

    this.dispatch('post', {
      path: 'translate',
      data
    }).then((result) => {
      const summary = result.body
      // add the timestamp - UTC per default
      summary.timestamp = convertDateToSystemLocal(new Date())

      article.setMeta({ translationText: summary })
    })

  }
}
