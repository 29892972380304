import { observable, computed, action, transaction } from 'mobx'
import { isObject } from 'lodash'
import { getBaseUrl } from '../shared/api/decorators/apiClient'
import Communicator from '../Communicator'
import communicate from '../shared/decorators/communicator'
import { apiClient } from '../shared/api'
import { inspector, simpleInspector } from '../shared/decorators/inspector'
import { overloadable } from '../shared/decorators/overload'
import { deepGet } from '../shared/obj'
import { addToCollection, i18n } from '../shared/utils'
import { Store } from '../shared/store'
import {
  PAGE_ID_ARCHIVE_TREE_ROOT,
  PAGE_ID_STARTPAGE,
  PAGE_TYPE_ARCHIVE_PAGE,
  PAGE_TYPE_ARCHIVE_SPECIAL_PAGE,
  PAGE_TYPE_TREE_ROOT,
  PAGE_TYPE_SPECIAL_PAGE,
  TEMPORARY_PUBLISHED_URL,
  TEMPORARY_UNPUBLISHED_URL,
} from '../shared/const'
import { articleStore } from '../article/reducers'
import { widgetStore } from '../widget/reducers'
import { PublishInfoInspector } from './inspectors'
import { LoadRenderedPageCommand } from './commands'
import Page from './model'
import PartialPage from './partialModel'


const contentStores = {
  article: articleStore,
  widget: widgetStore,
}

const defaultVals = {
  page: 0,
  hasMoreItems: null,
  collection: [],
  filter: {},
  loading: null,
  total: 0,
}

@overloadable
@inspector('save', 'saving')
@inspector('load', 'page.loading')
@inspector('loadRenderedPage', 'page.loading-preview')
@inspector('publish', PublishInfoInspector)
@simpleInspector('notifyPublishing', 'page.publish-success')
@simpleInspector('notifyUnpublishing', 'page.unpublish-success')
@apiClient
@communicate(Communicator.getInstance())
class PageStore extends Store {
  // Put all page list properties here. This regargds also state properties.

  @observable collection = [];

  // partial projects that are loaded for display options collection
  @observable pagePartialsCollection = []

  @observable tree = {};

  @observable current = {};

  @computed get hasCurrent() {
    return !!(this.current && this.current.id)
  }

  @observable total = 0;

  @observable page = 0;

  @observable limit = -1;

  // Todo: see if loading is ever in the store used or could replace lastLoadFailed
  @observable loading = false;

  hasMoreItems = true;

  @observable filter = {};

  @observable lastLoadFailed = false;

  @observable publishToggle = false;

  // Put all properties that are not to be observed here:

  // to see the store name when in production mode - Uglifyjs
  static storeName = 'PageStore';

  constructor(opts = {}) {
    super(opts)

    this.Model = Page
    this.PartialModel = PartialPage
    // this implies that current pageId in the url shouldn't be trusted and a proper default found
    this.requireNewPage = false

    this.initCommunicator()
  }

  // @overload({ id: 'string?', opts: 'object?', data: 'object?' })
  load(id = null, opts = {}, data = null) {
    if (!id) {
      throw new Error('missing page id to load')
    }
    const path = `page/${id}`

    // todo: some components refresh their data be the current be set to null. This shouldn't be needed
    if (opts.setCurrent) {
      this.setCurrent(null)
    }
    return (
      this.dispatch('get', {
        path,
        params: opts.params
      }).then((response) => {
        this.lastLoadFailed = false

        if (!response || !response.body || response.error) {
          throw new Error('error in page get')
        }

        return this.processRawData(response.body.data)
      })
        .then(newModel => (id ? this.loadRenderedPage(newModel, id) : newModel))
        .then((newModel) => {
          if (newModel && opts.setCurrent) {
            this.clearOngoingOpeningActions(true)
            this.setCurrent(newModel)
          }

          return newModel
        })
        .catch((e) => {
          this.lastLoadFailed = true
          throw e
        })
    )
  }

  /**
   * This adds to the partial collection a filtered set of pages, which can be in or out of the current project
   * @param {Object} filterParams - { params }
   * @returns {Array[PartialPage]} - responseModels
   */
  loadFilter(filterParams) {
    if (!filterParams.params) {
      throw new Error('missing page filter params to load')
    }

    return (
      this.dispatch('get', {
        path: 'page',
        params: filterParams.params
      }).then((response) => {
        this.lastLoadFailed = false

        if (!response || !response.body || response.error) {
          throw new Error('error in page get')
        }
        const responseModels = []
        response.body.data.forEach((rawPageData) => {
          responseModels.push(addToCollection(this, this.pagePartialsCollection, rawPageData, PartialPage))
        })
        return responseModels
      })
    )
  }

  loadRenderedPage(pageModel, id) {
    const command = new LoadRenderedPageCommand(this, id, pageModel)
    return command.exec().then(() => pageModel)
  }

  processRawData(rawPageData) {
    const newModel = addToCollection(this, this.collection, rawPageData, Page)
    const itemTemplates = deepGet(newModel, 'itemTemplates')

    if (itemTemplates) {
      Object.keys(itemTemplates).forEach((templateType) => {
        const templates = itemTemplates[templateType]
        if (
          templates
            && !Array.isArray(templates)
            && isObject(templates)
        ) {
          Object.keys(templates).forEach((key) => {
            delete templates[key].module
          })
        }
      })
    }
    return newModel
  }

  getInternalLinks(pageId) {
    return this.dispatch('get', {
      path: `page/${pageId}/internalLinks`
    }).then((response) => {
      if (!response || !response.body || response.error) {
        throw new Error('error in getInternalLinks')
      }
      return response.body.data
    })
  }

  /**
   * @param {id} originalPageId
   * @param {Object} data : {followingPages: false, parentId: 368, position: 0}
   * @param {*} opts
   */
  copy(originalPageId, data, opts = {}) {
    return this.dispatch('post', {
      path: `page/${originalPageId}/copy`,
      data,
    }).then((response) => {
      const models = []
      if (deepGet(response, 'body.data') && !response.error) {
        response.body.data.forEach((pageData) => {
          const model = addToCollection(this, this.pagePartialsCollection, pageData, PartialPage)
          models.push(model)
        })
      }
      return models
    })
  }

  save(empty, page, opts = {}) {
    if (page.id) {
      return this.dispatch('put', {
        path: `page/${page.id}`,
        data: page.getJSON(),
        params: opts.params,
      }).then((response) => {
        const updatedModel = addToCollection(this, this.collection, response.body.data, Page)

        // Also update the partialModel to keep tree view in sync
        addToCollection(this, this.pagePartialsCollection, response.body.data, PartialPage)
        this.saving = null
        return updatedModel
      })
    }
    throw new Error('unable to save on model if never created in Backend')
  }

  /**
   * Deletes item from Backend
   * @param  {PagePartial} page: pagePartial
   * @returns {Boolean}
   */
  destroy(page) {
    if (!page || !page.id) {
      throw new Error('missing Page to delete page')
    }

    const pagePartial = this.getPartialById(page.id)
    const parent = this.getPartialById(pagePartial.parentId)
    let index

    if (parent) {
      index = parent.indexOfChild(pagePartial)
      parent.removeChild(pagePartial)
    }

    if (page && page.id) {
      return this.dispatch('del', {
        path: `page/${page.id}`,
      }).then(() => {
        // if successfully deleted from Backend, remove it from collections
        const pagePartialIndex = this.pagePartialsCollection.findIndex(el => el.id === page.id)
        const pageIndex = this.collection.findIndex(el => el.id === page.id)
        if (pagePartialIndex > -1) {
          this.pagePartialsCollection.splice(pagePartialIndex, 1)
        }
        if (pageIndex && pageIndex > -1) {
          this.collection.splice(pageIndex, 1)
        }
      }).catch(() => {
        if (parent) {
          parent.addChild(pagePartial, index)
        }
      })
    }

    // if not an page, throw error
    throw new Error('missing page in delete action')
  }

  /** Resets page store, like if changing projects */
  @action
  flush() {
    transaction(() => {
      this.collection.splice(0, this.collection.length - 1)
      this.pagePartialsCollection.splice(0, this.collection.length - 1)
      this.tree = {}
      this.setCurrent(null)
    })
  }

  canLoad() {
    return !this.loading && this.hasMoreItems
  }

  create(data = {}, opts = {}) {
    const newModel = new Page(this, data)
    return this.dispatch('post', {
      path: 'page',
      data: newModel.getJSON()
    }).then((response) => {
      if (deepGet(response, 'body.data') && !response.error) {
        const newPage = addToCollection(this, this.collection, response.body.data, Page)
        addToCollection(this, this.pagePartialsCollection, response.body.data, PartialPage)
        return newPage
      }
      throw new Error('failed to create new page')
    })
  }

  /**
   * Create a sub page.
   * @param {Object} data - The data to be used for the new page.
   * @property {String} data.name - The name for the new page.
   * @property {String} data.title - The title for the new page.
   * @property {Number} data.parentId - The parentId of the page.
   */
  createChild(data = {}, opts = {}) {
    i18n(data, 'title', null, data.title)
    i18n(data, 'name', null, data.title)
    delete data.title

    if ('id' in data && !data.id) {
      delete data.id
    }

    let parentId = data.parentId

    if (!parentId && this.hasCurrent) {
      // Use the current page as fallback if possible
      parentId = this.current.id
    }
    else if (!parentId) {
      // Use -2 as fallback since "Sonderseiten" is always there for the user to interact
      parentId = -2
    }

    const parent = this.getPartialById(parentId)

    if (!parent) {
      throw new Error(`Could not find a parent for parentId ${parentId}.`)
    }

    data.type = parent.type

    // Page Type specific props
    if (data.type === PAGE_TYPE_SPECIAL_PAGE) {
      // Overwritten by the response from the backend
      data.hideNavigation = 1
    }

    // in order to open the new page
    this.setCurrent(null)
    return this.create(data)
      .then((page) => {
        parent.collapsed = false
        const partialPage = this.getPartialById(page.id)
        parent.addChild(partialPage)
        this.setCurrent(page)
        return { models: [page] }
      })
      .catch((err) => {
        throw err
      })
  }

  /**
   * returns the page partials primarily used by trees
   * @param {Number} id
  */
  getPartialById(id) {
    return this.getById(id, { collection: this.pagePartialsCollection })
  }

  /**
   * Create an archive tree.
   * @param {Object} archive - The object to be used for the archive tree.
   * @returns {Page} - The root page of the archive tree
   */
  createArchiveTree(archive, opts = {}) {
    const pages = this.createTree(archive.pages, {
      root: 'project.navigation.archive-tree',
      type: PAGE_TYPE_ARCHIVE_PAGE,
      language: opts.language
    })

    const specialPages = this.createTree(archive.specialPages, {
      root: 'project.navigation.special-pages',
      type: PAGE_TYPE_ARCHIVE_SPECIAL_PAGE,
      language: opts.language
    })

    const treeRoot = {
      id: PAGE_ID_ARCHIVE_TREE_ROOT,
      type: PAGE_TYPE_TREE_ROOT,
    }

    i18n(treeRoot, 'name', opts.language, 'project.navigation.archive-pages')

    const tree = addToCollection(this, this.pagePartialsCollection, treeRoot, PartialPage, { replace: true })

    tree.addChild(specialPages)
    tree.addChild(pages)

    return tree
  }

  /**
   * Create a page tree.
   * @param {Array} pages - The array to be used for the new tree.
   * @property {String} opts.root - The name for the tree root.
   * @property {String} opts.type - The type for the new tree.
   * @property {Number} pages.parentId - The parentId of the page.
   * @returns {Page} - The root page of the new tree
   */
  createTree(pages = [], opts = {}) {
    const treeRoot = {
      id: deepGet(pages[0], 'parentId') || opts.type * -1,
      depth: 0,
      sub: pages,
      type: opts.type,
    }

    i18n(treeRoot, 'name', opts.language, opts.root)

    const tree = new PartialPage(this, treeRoot)

    tree.traverse((node) => {
      addToCollection(this, this.pagePartialsCollection, node, PartialPage, { replace: true })
    })

    return tree
  }

  @action
  actionAddChild(parentId, childId, index /* , opts*/) {
    const parent = this.getPartialById(parentId)
    const child = this.getPartialById(childId)

    // Fix for child.parent not set properly sometimes
    if (child.parent) {
      child.parent.removeChild(child)
    }
    else {
      const childParent = this.getPartialById(child.parentId)
      childParent.removeChild(child)
    }

    parent.addChild(child, index, { ignoreExist: true })
    child.saveChild(
      {
        position: child.position,
        parentId: child.parentId,
        type: parent.type,
      },
    )
  }

  @action
  actionRemoveContent(itemId, content, target, opts) {
    const item = this.getById(itemId)

    if (!item) {
      // eslint-disable-next-line max-len
      throw new Error(
        `Could not find \`${this.Model.name}\` item with id \`${itemId}\`!`
      )
    }

    item.removeContent(target)

    if (opts.destroy) {
      contentStores[content.contentType].actionDestroyItem(content.id)
    }
  }

  /**
   * Restores a given archive page by calling archivePage with 'restore'
   * @param {Object} page - The page model to be restored
   */
  restorePage(page) {
    return this.archivePage(page, 'restore')
  }

  /**
   * Archives a single page
   * @param {Object} page - The page model to be archived
   * @property {String} opts - Will be send along to the api.
   * Can be 'restore' or 'archive'
   */
  archivePage(page, direction) {
    const pagePartial = this.getPartialById(page.id)
    const parent = this.getPartialById(pagePartial.parentId)
    let index

    if (parent) {
      index = parent.indexOfChild(pagePartial)
      parent.removeChild(pagePartial)
    }

    return this.dispatch('put', {
      path: `page/${page.id}/${direction}`
    }).then((result) => {
      if (deepGet(result, 'body.data')) {
        addToCollection(this, this.collection, result.body.data, Page)
        // update the partials, since they are what is actually used for the Tree
        addToCollection(this, this.pagePartialsCollection, result.body.data, PartialPage)
        const newParent = this.getPartialById(result.body.data.parentId)
        if (newParent) {
          return newParent
        }
        return this.load(result.body.data.parentId)
      }
      throw new Error('unable to get parent to copy to')
    }).then((newParent) => {
      page.type = newParent.type
      this.setCurrent(null)
      newParent.addChild(pagePartial)
      this.setCurrent(page)
    }).catch((err) => {
      // In error case: Add the page again
      console.error('unable to move child and returning child to original location')
      return parent.addChild(pagePartial, index)
        .catch(() => {
          throw new Error('unable to re-add child. Likely missing parent')
        })
    })
  }

  /**
  * Deletes a group of pages
  * @param {Object} data - The pages to be deleted
  * @property {Object} data.parent - A root of a selected page tree
  * @property {Object} data.subs - The child pages of parent
  */
  deleteGroup(data) {
    const pageIds = data.reduce((memo, parent) => {
      memo.push(parent.parent.id)
      return memo.concat(parent.subs.map(el => el.id))
    }, [])

    data.forEach((el) => {
      // Keep the previous parent for tree management
      el.prevParent = el.parent.parent
    })

    return this.dispatch('del', {
      path: 'page',
      data: { pageIds }
    }).then((result) => {
      // Remove from previous parent
      data.forEach((el) => {
        if (el.prevParent) {
          el.prevParent.removeChild(el.parent)
        }
      })
    })
  }

  /**
  * Archives a group of pages from the control center
  * @param {Object} data - The pages to be archived
  * @property {Object} data.parent - A root of a selected page tree
  * @property {Object} data.subs - The child pages of parent
  */
  archiveGroup(data) {
    const pageIds = data.reduce((memo, parent) => {
      memo.push(parent.parent.id)
      return memo.concat(parent.subs.map(el => el.id))
    }, [])

    data.forEach((el) => {
      // Keep the previous parent for tree management
      el.prevParent = el.parent.parent
    })

    return this.dispatch('post', {
      path: 'page/archive',
      data: { pageIds }
    }).then((result) => {

      // Remove from previous parent
      data.forEach((el) => {
        if (el.prevParent) {
          el.prevParent.removeChild(el.parent)
        }
      })

      // Add to archive parent page
      result.body.data.forEach((el) => {
        // Only in control center and only for partial pages
        const page = this.getPartialById(el.id)
        const newParent = this.getPartialById(`-${el.type}`)
        newParent.addChild(page)
      })
    })
  }

  @action
  actionDestroyItem(itemId, opts = {}) {
    itemId *= 1

    // check where it should be deleted from
    const item = this.getById(itemId)
    const itemPartial = this.getPartialById(itemId)

    if (!item && !itemPartial) {
      // eslint-disable-next-line max-len
      throw new Error(
        `Could not find page (or partial) item with id \`${itemId}\`!`
      )
    }

    // If the parent page is just a tree root, open startpage

    const parentId = itemPartial.parentId < 0 ? 1 : itemPartial.parentId
    if (!itemPartial.parentId && !parentId) {
      itemPartial.parentId = itemPartial.parent && itemPartial.parent.id
    }

    if (opts.archive) {
      return this.archivePage(item, 'archive')
    }

    return this.destroy(itemPartial)
  }

  getPreviewURL(pageId) {
    const path = `page/${pageId}/preview`

    const baseUrl = getBaseUrl()

    return `${baseUrl}/${path}`
  }

  /**
   * Handles publishing and unpublishing a single page
   * @param {Object} data - pages to update and optional paramaters
   * @property {Array} data.pageIds - array of page ids as ints
   * @property {String} opts.action - cooresponds to config/index.js actions:
   * 'publishGroup', 'unpublishGroup'
   */
  publish(page, data, opts) {
    if (!data) {
      return console.warn('no publishing data')
    }

    if (!opts) {
      return console.warn('no command to publish or unpublish')
    }

    // handles unpublish and publish actions
    let publishAction = 'publish'
    if (opts && opts.action) {
      publishAction = opts.action
    }

    // data: publishFollowing: t/f, opts: {id: 1}, page: Page
    return this.dispatch('post', {
      path: `page/${opts.id}/${publishAction}`,
      data
    })
      .then((result) => {
        const resultData = deepGet(result, 'body.data')

        if (resultData) {
          this.storePublishingResult(page, resultData, publishAction)
        }
      })
      .catch((err) => {
        console.log(err.message)
        throw err
      })
  }

  /**
   * Handles publishing and unpublishing a group of pages
   * @param {Object} data - pages to update and optional paramaters
   * @property {Array} data.pageIds - array of page ids as ints
   * @property {String} opts.action - cooresponds to config/index.js actions:
   * 'publishGroup', 'unpublishGroup'
   */
  publishGroup(data, opts) {
    if (!data) {
      return console.warn('no publishing data')
    }

    if (!opts) {
      return console.warn('no command to publish or unpublish')
    }

    let publishAction = 'publish'
    if (opts && opts.action && opts.action !== 'publishGroup') {
      publishAction = 'unpublish'
    }

    // Communicator will update the models
    return this.dispatch('post', {
      path: `page/${publishAction}`,
      data
    }).catch((err) => {
      console.log(err.message)
      throw err
    })
  }

  /**
   * @private
   * @param {Object} page - page model to be operated on
   * @param {Object} resultData - backend results to publish style action
   * @param {String} publishAction - allows storage options based on action done by user
   */
  storePublishingResult(page, resultData, publishAction = '') {
    // If the publish job was accepted by the backend
    if (resultData && !resultData.message) {
      // if publish job
      if (!resultData.isUnpublishJob) {
        page.publishFollowing = resultData.publishFollowing
        page.publishMailTo = resultData.mailTo
        page.updatedAt = resultData.updatedAt

        // if delayed publish
        if (resultData.isDelayed) {
          page.publishAt = resultData.publishAt
        }
        else {
          // At this point the backend doesn't have a publishedUrl value
          // the publishedUrl will be received later over the websocket
          page.publishedUrl = TEMPORARY_PUBLISHED_URL
        }
      }

      // unpublish job
      else {
        // general
        page.hasChanged = false
        page.updatedAt = resultData.updatedAt

        // if delayed unpub job
        if (resultData.isDelayed) {
          page.unpublishAt = resultData.unpublishAt
        }
        else {
          // At this point the backend doesn't have a publishedUrl value
          // the publishedUrl will be received later over the websocket
          page.publishedUrl = TEMPORARY_UNPUBLISHED_URL
        }
      }
    }
    // If the publish job was canceled successfully
    else {
      // handle specific canels or run everything if not specified
      if (publishAction !== 'cancelUnpublication') {
        page.publishAt = null
        page.publishedUrl = null
        page.publishFollowing = null
      }
    }
  }

  // Checks if a filename / publish url is already in use
  validateUrl(url) {
    const data = {
      projectId: this.current.projectId,
    }

    i18n(data, 'url', null, url)

    return this.dispatch('post', {
      path: 'page/url/validate',
      data
    })
  }

  // Returns an array with displayable names for a page and its parents.
  // Tree logic, so uses partial collection
  getPagePath(pageId) {
    const path = []
    const page = this.getPartialById(pageId)
    if (page) {
      const pathKeys = page.getExpandedKeys()

      pathKeys.forEach((id) => {
        // Needs to be a number
        id = id * 1

        // This page is never visible for the user (parent of page with ID 1)
        if (id === PAGE_ID_STARTPAGE) {
          return
        }

        // Find the page in the collection, get the translated name and push it
        path.push(this.getPartialById(id).getName())

        // Push the separator between the page names
        path.push(' > ')
      })

      // At last the name of the page with the given param pageId
      path.push(page.name)

      return path
    }
    console.warn(`unable to find page ${pageId}`)
    return false
  }

  /**
   * Use to save partial information
   * @param {*} data: parentId, position, type
   * @param {Page} page: page model to save on
   */
  saveChild(data = {}, page = {}) {
    if (!page.id || !data.parentId || !data.position || !data.type) {
      throw new Error('missing required info to save page child')
    }

    return this.dispatch('patch', {
      path: `page/${page.id}`,
      data
    }).then((result) => {
      // results should be an array of full page models, not partials
      const pageArray = deepGet(result, 'body.data')
      if (!pageArray || !Array.isArray(pageArray)) {
        throw new Error('page update should be an array of pages')
      }
      return pageArray.forEach((pageData) => {
        addToCollection(this, this.collection, pageData, Page)
        // update the partials, since they are what is actually used for the Tree
        addToCollection(this, this.pagePartialsCollection, pageData, PartialPage)
      })
    }).catch(() => {
      throw new Error('error in updating page children')
    })
  }

  /**
   * Just a hook for the simpleInspector to print something
   * Will be extended in the future
   */
  notifyPublishing() {
    this.publishToggle = !this.publishToggle
  }

  notifyUnpublishing() {
    this.publishToggle = !this.publishToggle
  }
}

export { PageStore, PageStore as default }
