import { transaction } from 'mobx'

import { Command } from '../../../shared/lib/command'
import WidgetContentAction from '../actions/WidgetContentAction'

const ITEM_INDEX_LAST_ITEM = -1

export default
class RemoveContentFromWidgetCommand
extends Command {

  static storeRequirements = {
    widget: true
  }

  static validationSpec = {
    widgetId: Command.ensureData.isNumber,
    removeAtPosition: Command.ensureData.isPresent,
  }

  exec() {
    const {
      store,
      widgetId,
      removeAtPosition
    } = this.data

    const widget = store.widget.getById(widgetId)

    if (widget && removeAtPosition) {

      const widgetRemoveAction = new WidgetContentAction(
        widget,
        [...removeAtPosition],
        WidgetContentAction.REMOVE
      )

      try {
        return transaction(() => {
          widgetRemoveAction.execute()
          return widget.save()
        })
      }
      catch (ex) {
        console.error('Could not remove item', ex)
      }
    }
  }

}
