import classNames from 'classnames'
import { isObject } from 'lodash'
import { formatMessage } from '../../../../translations'

const getButtonProps = (
  state: Object,
  className: String = ''
) => {

  const labelLocalId = `editor.button.${
    state.name || state.cmd || 'no-name'
  }`

  return {
    className: classNames(className, {
      // active can be a truthy value or an object
      // defining the active key anew
      active: !!((state.active && isObject(state.active))
      ? state.active.active
      : state.active)
    }),
    active: state.active,
    disabled: state.disabled,
    key: state.cmd,
    'data-command-name': state.cmd,
    'data-command-value': state.value,
    // Some (especially customer buttons) may brind an already translated
    // name. In such cases, use that one.
    title: state.translatedName || formatMessage({ id: labelLocalId }),
    name: state.translatedName || formatMessage({ id: labelLocalId }),
  }

}

export default getButtonProps
