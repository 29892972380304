exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".src-shared-components-HeaderWarning-styles__headerWarning___9chUS{align-items:center;background-color:#f90;color:#fff;max-height:45px;min-height:45px;opacity:0.8}.src-shared-components-HeaderWarning-styles__headerWarning___9chUS .geneva-button{font-size:13px;height:23px;margin-left:20px;min-height:25px;min-width:auto;padding:4px 6px}.src-shared-components-HeaderWarning-styles__headerWarningClose___3uMp-{color:#fff;right:25px;top:10px}\n", "", {"version":3,"sources":["/builds/GENEVA/wm/frontend/src/shared/components/HeaderWarning/styles.scss"],"names":[],"mappings":"AAEA,mEACE,mBACA,sBACA,WACA,gBACA,gBACA,WAAY,CANd,kFASI,eACA,YACA,iBACA,gBACA,eACA,eAAgB,CACjB,wEAID,WACA,WACA,QAAS,CACV","file":"styles.scss","sourcesContent":["@import \"src/styles/variables\";\n\n.headerWarning {\n  align-items: center;\n  background-color: $ui-warn-color;\n  color: $secondary-text-color;\n  max-height: 45px;\n  min-height: 45px;\n  opacity: 0.8;\n\n  :global(.geneva-button) {\n    font-size: 13px;\n    height: 23px;\n    margin-left: 20px;\n    min-height: 25px;\n    min-width: auto;\n    padding: 4px 6px;\n  }\n}\n\n.headerWarningClose {\n  color: $secondary-text-color;\n  right: 25px;\n  top: 10px;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"headerWarning": "src-shared-components-HeaderWarning-styles__headerWarning___9chUS",
	"headerWarningClose": "src-shared-components-HeaderWarning-styles__headerWarningClose___3uMp-"
};