import { createConfirmation } from 'react-confirm'
import PageSecurityDialog from '../../components/dialogs/PageSecurity'

const pageSecurity = createConfirmation(PageSecurityDialog)


export default function (confirmation, options = {}) {
  if (typeof confirmation !== 'string') {
    options = confirmation
    confirmation = ''
  }
  return pageSecurity({ confirmation, options })
}
