import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import { autobind } from 'core-decorators'
import { FormattedMessage } from '../../../translations'

export default class DefaultUploadImageForm extends Component {
  static propTypes = {
    checkedRadio: PropTypes.string,
    selectedTab: PropTypes.string,
    handleRef: PropTypes.func,
    onInputChange: PropTypes.func,
    onRadioClick: PropTypes.func,
    onValidation: PropTypes.func,
  };

  componentWillMount() {
    const { url } = this.props
    this.handleValidation(url)
  }

  validate(value) {
    return (
      value && value.match(/\.(jpg|jpeg|tif|tiff|png|gif|svg)/) && value.length > 3
    )
  }

  @autobind
  handleInputChange(e) {
    if (this.props.onInputChange) {
      const { target } = e
      this.props.onInputChange(e, this.validate(target.value))
    }
  }

  @autobind
  handleRadioClick(e) {
    if (this.props.onRadioClick) {
      this.props.onRadioClick(e)
    }
  }

  handleValidation(url) {
    if (this.props.onValidation) {
      this.props.onValidation(this.validate(url))
    }
  }

  render() {
    const { selectedTab, checkedRadio, url } = this.props
    const isTextDisabled = checkedRadio !== 'fromExternal'

    return (
      <div className="main">
        <input
          id="fromUpload"
          type="radio"
          checked={checkedRadio === 'fromUpload'}
          onChange={this.handleRadioClick}
          ref={this.handleRef}
        />

        <label htmlFor="fromUpload">
          <FormattedMessage
            id={`${selectedTab}.file-upload-dialog.fromUpload`}
          />
        </label>

        <input
          id="fromExternal"
          type="radio"
          checked={checkedRadio === 'fromExternal'}
          onChange={this.handleRadioClick}
        />

        <label htmlFor="fromExternal">
          <FormattedMessage
            id={`${selectedTab}.file-upload-dialog.fromExternal`}
          />
        </label>

        <label htmlFor="url" disabled={isTextDisabled}>
          <FormattedMessage id={`${selectedTab}.file-upload-dialog.example`} />
          <input
            disabled={isTextDisabled}
            id="url"
            type="url"
            value={url}
            onChange={this.handleInputChange}
          />
        </label>
      </div>
    )
  }
}
