import { isNumber, isString, isObject } from 'lodash'

const getNth = (number: Number): String => {
  // validate('getNth', [{name: 'number', type: 'number'}], arguments)
  number += ''
  const lastDigit = number[number.length - 1] * 1
  if (lastDigit === 1) {
    return `${number}st`
  }

  if (lastDigit === 2) {
    return `${number}nd`
  }

  if (lastDigit === 3) {
    return `${number}rd`
  }

  return `${number}th`
}

const validate = (name, params = [], args) => {
  if (!Array.isArray(params)) {
    throw new Error('`validate` expects array as second argument!')
  }
  let msg = ''

  params.forEach((param, i) => {

    // sjip empty params
    if (!param) {
      return
    }

    const value = args[i]
    const paramInfo = `\`${name}\` expects ${getNth(i + 1)} argument \`${param.name}\``

    if (param.instanceof && !(value instanceof param.instanceof)) {
      // eslint-disable-next-line max-len
      msg += `${paramInfo} to be an instance of ${
        param.instanceof.name || param.instanceof.toString()
      }. \`${value}\` given.`
    }

    if (param.type) {
      let numErrors = 0
      let tmpMessage = ''
      const types = Array.isArray(param.type)
        ? param.type
        : [param.type]

      types.forEach((type) => {
        let err = false
        switch (type) {
          case 'number':
            err = !isNumber(value)
            break
          case 'string':
            err = !isString(value)
            break
          case 'object':
            err = !isObject(value)
            break
          case 'array':
            err = !Array.isArray(value)
            break
          default:
            console.warn(`\`validate\`: No validator for type \`${type}\``)
        }

        if (err) {
          numErrors += 1
          tmpMessage += `${paramInfo} to be of type ${type}. \`${typeof value}\` given.`
        }
      })

      if (numErrors === types.length) {
        msg += tmpMessage
      }

    }

    if (param.oneOf) {
      if (param.oneOf.indexOf(value) === -1) {
        msg += `${paramInfo} to be one of [${param.oneOf.join(', ')}]. \`${value}\` given.`
      }
    }


  })

  if (msg) {
    throw new Error(msg)
  }
}

export { validate as default }
