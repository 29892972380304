import Rx from 'rx'
// taken from:
// https://xgrommx.github.io/rx-book/content/how_do_i/simple_event_emitter.html
const hasOwnProp = {}.hasOwnProperty

function createName(name) {
  return `$ ${name}`
}

const WILDCARD_CHANNEL_NAME = '$ *'

export default
class Emitter {

  constructor() {
    this.subjects = {}
  }

  emit(name, data) {
    const fnName = createName(name)
    this.subjects[fnName] = this.subjects[fnName] || new Rx.Subject()

    this.subjects[fnName].onNext(data)

    if (this.subjects[WILDCARD_CHANNEL_NAME]) {
      const args = [name, data]
      this.subjects[WILDCARD_CHANNEL_NAME].onNext(args)
    }
  }

  listen(name, handler) {
    const fnName = createName(name)
    this.subjects[fnName] = this.subjects[fnName] || new Rx.Subject()
    return this.subjects[fnName].subscribe(handler)
  }

  dispose() {
    const subjects = this.subjects

    Object.keys(subjects).forEach((prop) => {
      if (hasOwnProp.call(subjects, prop)) {
        subjects[prop].dispose()
      }
    })

    this.subjects = {}
  }

}
