export function transformToForeColorAttributes(data = {}) {
  return data
}

export function transfromToForeColorObject(data = {}) {
  return data
}

export function getDefaultForeColorAttributes() {

  return {
    color: ''
  }

}

export function getForeColorProps(props) {

  return {
    color: props.color
  }

}
