exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".src-template-dev-styles__devtools___1ZszY{background-color:#fff;border:1px solid #e4e4e4;left:50%;margin-left:-175px;padding:2px 10px;position:absolute;top:3px;width:350px;z-index:100}.src-template-dev-styles__devtools___1ZszY label{font-size:11px;margin-bottom:0;width:120px}.src-template-dev-styles__devtools___1ZszY input[type=\"range\"]{height:20px}.src-template-dev-styles__devtools___1ZszY .button.small{font-size:12px;min-height:25px;min-width:auto;padding:3px 10px}.src-template-dev-styles__devtools___1ZszY .preview-changer{overflow:hidden}.src-template-dev-styles__devtools___1ZszY .grid-block{overflow:hidden}\n", "", {"version":3,"sources":["/builds/GENEVA/wm/frontend/src/template/dev-styles.scss"],"names":[],"mappings":"AAEA,2CACE,sBACA,yBACA,SACA,mBACA,iBACA,kBACA,QACA,YACA,WAAY,CATd,iDAYI,eACA,gBACA,WAAY,CAdhB,+DAmBI,WAAY,CAnBhB,yDAwBM,eACA,gBACA,eACA,gBAAiB,CA3BvB,4DA+BM,eAAgB,CA/BtB,uDAmCM,eAAgB,CACjB","file":"dev-styles.scss","sourcesContent":["@import \"../styles/variables\";\n\n.devtools {\n  background-color: $secondary-color;\n  border: 1px solid $primary-color;\n  left: 50%;\n  margin-left: -175px;\n  padding: 2px 10px;\n  position: absolute;\n  top: 3px;\n  width: 350px;\n  z-index: 100;\n\n  label {\n    font-size: 11px;\n    margin-bottom: 0;\n    width: 120px;\n  }\n\n  // IE/edge support\n  input[type=\"range\"] {\n    height: 20px;\n  }\n\n  :global {\n    .button.small {\n      font-size: 12px;\n      min-height: 25px;\n      min-width: auto;\n      padding: 3px 10px;\n    }\n\n    .preview-changer {\n      overflow: hidden;\n    }\n\n    .grid-block {\n      overflow: hidden;\n    }\n  }\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"devtools": "src-template-dev-styles__devtools___1ZszY"
};