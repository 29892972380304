import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import classNames from 'classnames'
import { autobind } from 'core-decorators'
import { Link } from 'react-router'

import {
  store as uiState
} from '../../ui'

import MainContainer from './Main'
import Navbar from '../components/Navbar'

import ManagedTextField from '../../shared/components/ManagedTextField'
import Icon from '../../shared/icons'

import {
  VALIDATION_ID_TEXT_EDITOR_WILDCARD,
  GENEVA_DEFAULT_WIDGET_NAME,
} from '../../shared/const'

const css = /* typeof window === 'undefined' ? {} : */require('../styles.scss')


@inject('customLocal') @observer
export default
class WidgetContainer
  extends Component {

  static propTypes = {
    auth: PropTypes.object.isRequired,
    widget: PropTypes.object.isRequired,
    context: PropTypes.object.isRequired,
    customLocal: PropTypes.object.isRequired,
    image: PropTypes.object.isRequired,
    widgetTemplate: PropTypes.object.isRequired,
    ui: PropTypes.object.isRequired,
  }


  componentDidMount() {

    uiState.updatePaneState({
      widgetListOpen: false,
      widgetListColumnSize: 2
    })

  }

  componentWillUnmount() {

    const { context } = this.props

    // remove all contexts related to the widget editor
    context.set(null)
    context.removeContextInfo(VALIDATION_ID_TEXT_EDITOR_WILDCARD)

    if (this.handler) {

      this.handler()
      this.handler = null

    }

  }

  @autobind
  handleNameChange({ target: { value } }) {
    this.props.widget.current.set({ name: value })
    // explicit call to save necessary here as set
    // turns off autosave
    this.props.widget.current.save()
  }

  renderBrand() {

    const widget = this.props.widget.current
    const defaultName = `${GENEVA_DEFAULT_WIDGET_NAME} (${widget.templateName})`

    if (widget && widget.id) {

      return (<div className={css.titleEditContainer}>
        <ManagedTextField
          value={this.props.widget.current.name}
          onChange={this.handleNameChange}
          className={classNames(css.titleEdit)}
          defaultName={defaultName}
        />
        {this.renderIsValid()}
      </div>)

    }

    return (<Link className="app-logo" to="/">Geneva</Link>)

  }

  renderIsValid() {

    const widget = this.props.widget.current

    if (widget.isValid) {
      return null
    }

    return (<span className={css.widgetValidationInfo}>
      <Icon className="text-warn" name="ion-alert-circled" />
    </span>)

  }


  renderNavbar() {

    return (<Navbar
      brand={this.renderBrand()}
      id="widgetAuthoring"
      navbarStyle={`primary ${css.widgetTitlebar}`}
      ui={this.props.ui}
      widget={this.props.widget}
    />)

  }

  render() {

    return (<div className="grid-frame vertical">
      {this.renderNavbar()}

      <div
        className={classNames('grid-block', 'noscroll', {
          hidden: this.props.image.editing
        })}
      >

        <div className="grid-block">
          <div className="grid-block">

            <MainContainer
              {...this.props}
            />

          </div>
        </div>
      </div>
    </div>)

  }

}
