import { createConfirmation } from 'react-confirm'

import { store as authStore } from '../../../auth'

import PagePlanningDialog from '../../components/dialogs/PagePlanning'

const pagePlanning = createConfirmation(PagePlanningDialog)


export default function (confirmation: string|Object, options: Object = {}) {
  if (typeof confirmation !== 'string') {

    const pageData = {
      mailTo: confirmation.page.publishMailTo || authStore.user.email,
      publishAt: confirmation.page.publishAt || '',
      unpublishAt: confirmation.page.unpublishAt || '',
      publishFollowing: confirmation.publishFollowing || false
    }
    options = { ...confirmation, ...pageData }
    confirmation = ''
  }
  return pagePlanning({ confirmation, options })
}
