/**
 * Extracts an id= value out of a given string.
 * @example
 * "videos?id=OcObucqgz4Q&part=snippet"
 * will return "OcObucqgz4Q"
 * @param {String} url - The string to get the value from.
 * @returns {String} id - The id as string
 */
export function extractIdFromUrl(url) {

  if (!url) {
    return null
  }

  let urlParts = url.split('id=')

  if (urlParts.length < 2) {
    return null
  }

  urlParts = urlParts[1].split('&')

  if (!urlParts.length) {
    return null
  }

  return urlParts[0]
}

export function extractQueries(url) {

  const urlParts = url.split('?')

  if (urlParts.length < 2) {
    return {}
  }

  return urlParts[1].split('&')
    .map(query => query.split('='))
    .reduce((memo, query) => {
      memo[decodeURIComponent(query[0])] = query[1]
        ? decodeURIComponent(query[1])
        : ''
      return memo
    }, {})

}

export function appendQueries(url, queries = {}) {

  const cleanUrl = url.split('?')[0]

  if (typeof queries === 'string') {
    queries = extractQueries(`?${queries}`)
  }

  const existingQueries = extractQueries(url)

  const allQueries = {
    ...existingQueries,
    ...queries
  }

  const qs = Object.keys(allQueries)
    .map((query) => {

      const value = allQueries[query]
        ? encodeURIComponent(allQueries[query])
        : null

      return `${encodeURIComponent(query)}${value ? `=${value}` : ''}`

    })
    .join('&')

  return `${cleanUrl}${qs ? `?${qs}` : ''}`

}
