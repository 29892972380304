import { store as fileStore } from '../../file'
import { store as contextStore } from '../../context'
import Command from '../command'
import { deepGet } from '../obj'


function keyRequired(key, path = null) {

  if (path) {
    path = `.${path}`
  }

  // eslint-disable-next-line max-len
  return `requires \`data.${path}${key}\` (from \`contextStore.target.${key}\`) to be present`

}

/**
 * The InsertFileFromContextIntoArticlePlaceholderCommand uses the data in the
 * context store to edit and upload an file and finally insrt it into the
 * article placeholder.
 * @extends shared~Command
 */
export default
class InsertFileFromContextIntoArticlePlaceholderCommand
  extends Command {

  /**
   * Creates a new InsertFileFromContextIntoArticlePlaceholderCommand using
   * the data from the contextStore.
   */
  constructor(overrideData = {}) {

    contextStore.assert('file')

    const fileData = Object.assign({
      value: contextStore.target.value,
      file: contextStore.target.file,
      url: contextStore.target.url,
      mimeType: contextStore.target.mimeType,
      type: 'file', // contextStore.type
    }, overrideData.file)

    delete overrideData.file

    const data = Object.assign({

      sourceType: contextStore.sourceType,
      type: 'file', // contextStore.type,
      article: contextStore.target.article,
      pid: contextStore.target.pid,
      file: fileData,
      multiple: contextStore.target.multiple

    }, overrideData)


    super(data)

    this.data = data

  }

  /**
   * Validates the data in this command.
   * @param {Object} data - The data of the command
   * @private
   */
  validate(data) {

    return ['article', 'file', 'pid']

      .filter(key => !(key in data) || !data[key])
      .map(key => keyRequired(key))
      .concat(
        ['file', 'url']
          .filter(key => data.sourceType === key)
          .filter(key => data.file && (!(key in data.file) || !data.file[key]))
          .map(key => keyRequired(key, 'file'))
      )
      .join(', ')

  }

  /**
   * Executes the InsertFileFromContextIntoArticlePlaceholderCommand.
   */
  exec() {

    return this.getEditableFileInstances()
      .then(dataSet => this.editFiles(dataSet))
      .then(({ model, data }) => {
        this.placeFileInPlaceholder(model, data)
      }).then(() => {
        // assumtion that article is the holder of the file and needs a save action
        if (deepGet(this, 'data.article')) {
          this.data.article.save()
        }
      })

  }

  /**
   * Actual file editing action.
   * @private
   * @param {Array} dataSet - An array of editable files.
   * @returns {Promise} - A promise that eventually delivers the result of
   * the article action article~Model#placeDataInPlaceholder.
   */
  editFiles(dataSet) {

    // this is ALWAYS a single file. Either the zipped archive or the single
    // selected file
    const data = dataSet[0]

    // get the current file id if there is any so it can be replaced
    const currFile = this.data.article.getDataInPlaceholder(this.data.pid)
    const lang = this.data.article.createdIso

    if (currFile) {
      data.file.id = currFile.id
    }

    return fileStore.editFile(
      data.file,
      {
        params: {
          lang
        }
      }
    )
      .then(model => ({
        model, data
      }))

  }

  placeFileInPlaceholder(models, data) {

    if (!this.data.multiple) {
      models = models[0]
    }

    return data.article
      .placeDataInPlaceholder(data.type, data.pid, models)
  }

  /**
   * Returns a list of editable files. Editable files are either objects
   * that contain an (file) file or an url to an file.
   * @private
   * @return {Promise} - A promise that will deliver a list of editable files
   * eventually.
   */
  getEditableFileInstances() {
    return Promise.resolve([this.data])
  }

}
