import injector, { dispatcher } from './decorator'
import Provider from './Provider'
import { Command, CommandData } from './Command'
import { MacroCommand, MacroCommandData } from './MacroCommand'

export {
  Command,
  CommandData,
  MacroCommand,
  MacroCommandData,
  Provider,
  injector as default,
  dispatcher
}
