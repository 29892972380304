export default
class MediaCacheBustRegistry {
  constructor() {
    this.registry = {}
  }

  getCacheBust(value, additionalKey = '') {

    const key = `${value}${additionalKey ? `:${additionalKey}` : ''}`

    if (!(key in this.registry)) {
      this.registry[key] = `${(new Date()).getTime()}`
    }

    return this.registry[key]

  }
}
