exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".src-template-components-TransformationInfo-styles__transformationInfo___2cJQE{position:absolute;top:-17px;left:-1px;font-size:9px;line-height:16px;height:16px;padding:0px 5px;background:#e0e0b8;color:#000;white-space:nowrap}.src-template-components-TransformationInfo-styles__transformationInfo___2cJQE .height-value,.src-template-components-TransformationInfo-styles__transformationInfo___2cJQE .left-value,.src-template-components-TransformationInfo-styles__transformationInfo___2cJQE .top-value,.src-template-components-TransformationInfo-styles__transformationInfo___2cJQE .width-value{margin:0 2px}.src-template-components-TransformationInfo-styles__transformationInfo___2cJQE .transfromation-info-position+.transfromation-info-size::before{content:' | '}\n", "", {"version":3,"sources":["/builds/GENEVA/wm/frontend/src/template/components/TransformationInfo/styles.scss"],"names":[],"mappings":"AAAA,+EACE,kBACA,UACA,UACA,cACA,iBACA,YACA,gBACA,mBACA,WACA,kBAAmB,CAVrB,8WAkBM,YAAa,CAlBnB,+IAuBQ,aAAc,CACf","file":"styles.scss","sourcesContent":[".transformationInfo {\n  position: absolute;\n  top: -17px;\n  left: -1px;\n  font-size: 9px;\n  line-height: 16px;\n  height: 16px;\n  padding: 0px 5px;\n  background: #e0e0b8;\n  color: #000;\n  white-space: nowrap;\n\n  // these values are meant to be overwritten by the user, so keep them global\n  :global {\n    .height-value,\n    .left-value,\n    .top-value,\n    .width-value {\n      margin: 0 2px;\n    }\n\n    .transfromation-info-position + .transfromation-info-size {\n      &::before {\n        content: ' | ';\n      }\n    }\n  }\n\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"transformationInfo": "src-template-components-TransformationInfo-styles__transformationInfo___2cJQE"
};