import PropTypes from 'prop-types'
import React, { Component } from 'react'
import classNames from 'classnames'
import { confirmable } from 'react-confirm'
import { autobind } from 'core-decorators'
import PerfectScrollbar from 'perfect-scrollbar'
import { formatMessage, FormattedMessage } from '../../../translations'
import { store as uiState } from '../../../ui'
import { Dialog } from '../../../shared/components/Dialog'
import {
  testClass,
  i18n
} from '../../../shared/utils'

import GenevaButton from '../../../ui/components/GenevaButton'

const css = typeof window === 'undefined' ? {} : require('../../styles.scss')


class Template extends Component {

  static propTypes = {
    template: PropTypes.object,
    selected: PropTypes.bool,
    onSelect: PropTypes.func,
    onDoubleClick: PropTypes.func,
    disabled: PropTypes.bool,
    filter: PropTypes.string
  }

  @autobind
  handleSelect() {
    if (this.props.onSelect && !this.props.disabled) {
      this.props.onSelect({
        target: this.props.template
      })
    }
  }

  @autobind
  handleDoubleClick() {
    if (this.props.onDoubleClick && !this.props.disabled) {
      this.props.onDoubleClick({
        target: this.props.template
      })
    }
  }

  render() {

    const { template } = this.props

    let name = template.name

    const vendor = 'default' // template.vendor  TODO: see issue #489 on gitlab

    name = name.replace(
      new RegExp(`(${this.props.filter})`, 'i'),
      '<span class="filter-result-highlight">$1</span>'
    )

    return (
      <li
        onClick={this.handleSelect}
        onDoubleClick={this.handleDoubleClick}
        className={classNames({
          selected: this.props.selected },
        'element-list-item')}
      >
        <div className="image-holder">
          <img
            role="presentation"
            src={`/templates/${vendor}/${template.templateType}/${template.shortcut}/icon.svg`}
          />
        </div>
        <div
          className="element-list-item-desc"
          id={template.name}
          dangerouslySetInnerHTML={{ __html: name }}
        >
        </div>
      </li>
    )
  }
}


@confirmable
class NewArticle extends Component {

  static propTypes = {
    show: PropTypes.bool,            // indicates if the dialog is shown or not.
    proceed: PropTypes.func,         // call to close the dialog with promise resolved.
    cancel: PropTypes.func,          // call to close the dialog with promise rejected.
    dismiss: PropTypes.func,         // call to only close the dialog.
    confirmation: PropTypes.string,  // arguments of your confirm function
    options: PropTypes.shape({      // arguments of your confirm function
      articleTemplate: PropTypes.object,
      widgetTemplate: PropTypes.object,
      allowedArticleTemplates: PropTypes.array,
      allowedWidgetTemplates: PropTypes.array,
    }).isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      filter: '',
      selectedTemplate: null
    }

    this.ps = null
  }

  componentWillMount() {
    const { options: { articleTemplate, widgetTemplate } } = this.props
    if (!articleTemplate.didCompleteLoad) {
      articleTemplate.load(null, { force: true })
        .then(() => {
        // This will cause an update of the view after the templates are loaded
          this.forceUpdate()
        })
    }
    if (!widgetTemplate.didCompleteLoad) {
      widgetTemplate.load(null, { force: true })
        .then(() => {
        // This will cause an update of the view after the templates are loaded
          this.forceUpdate()
        })
    }
  }

  @autobind
  getFocus() {
    return this.focus
  }

  @autobind
  setScrollbar() {
    this.ps = new PerfectScrollbar('#newArticle-container', {
      suppressScrollX: true
    })
  }

  @autobind
  handleRef(el) {
    if (el !== null) {
      this.focus = el
    }
  }

  @autobind
  handleSelect({ target }) {
    this.setState({
      selectedTemplate: target
    })
  }

  @autobind
  handleCreate(e) {
    if (e && e.preventDefault) {
      e.preventDefault()
    }
    this.handleCommit('createArticle')
  }

  @autobind
  handleCreateAndEdit(e) {
    if (e && e.preventDefault) {
      e.preventDefault()
    }
    this.handleCommit('createArticleAndEdit')
  }

  @autobind
  handleFilterChange({ target }) {
    this.setState({
      filter: target.value
    })
  }

  @autobind
  handleSortChange({ target }) {
    this.setState({
      sorting: target.value
    })
  }

  handleCommit(action) {
    return (
      this.props.proceed({
        template: this.state.selectedTemplate,
        action
      })
    )
  }

  filterList(list) {
    const {
      options: {
        allowedArticleTemplates,
        allowedWidgetTemplates
      }
    } = this.props

    return list
      .filter((currTemplate) => {

        if (currTemplate.templateType === 'article') {

          // if explicitly set to null, allow all templates
          if (allowedArticleTemplates === null) {
            return true
          }

          if (!(allowedArticleTemplates
            && allowedArticleTemplates.length)) {
            return false
          }

          return allowedArticleTemplates
            .indexOf(currTemplate.shortcut) >= 0
        }

        if (currTemplate.templateType === 'widget') {

          // if explicitly set to null, allow all templates
          if (allowedWidgetTemplates === null) {
            return true
          }

          if (!(allowedWidgetTemplates
            && allowedWidgetTemplates.length)) {
            return false
          }

          return allowedWidgetTemplates
            .indexOf(currTemplate.shortcut) >= 0
        }

        return true
      })
      .filter((currTemplate) => {
        if (!this.state.filter) {
          return true
        }

        return currTemplate.name.toLowerCase()
          .indexOf(this.state.filter.toLowerCase()) >= 0
      })
  }

  sortList(list) {

    // Initial is a simple alphebetical sort
    list.sort((a, b) => {

      const nameA = (i18n(a, 'name', uiState.language) || '')
        .toUpperCase()
      const nameB = (i18n(b, 'name', uiState.language) || '')
        .toUpperCase()

      if (nameA > nameB) {
        return 1
      }
      if (nameA < nameB) {
        return -1
      }

      return 0
    })

    // options: ascending, descending
    if (this.state.sorting === 'descending') {
      list.reverse()
    }
    return list
  }


  @autobind
  renderFooter() {
    return (<div className="grid-content text-right">
      <GenevaButton type="button"
        className="small button"
        onClick={this.props.dismiss}
      >
        <FormattedMessage id="new-article-dialog.cancel" />
      </GenevaButton>
      <GenevaButton type="button"
        className={classNames(
          testClass('new-article-submit'),
          'small button')}
        disabled={!this.state.selectedTemplate}
        onClick={this.handleCreate}
        id="new-article-submit"
      >
        <FormattedMessage id="new-article-dialog.create" />
      </GenevaButton>
      <GenevaButton type="submit"
        className="small primary button"
        disabled={!this.state.selectedTemplate}
        onClick={this.handleCreateAndEdit}
      >
        <FormattedMessage id="new-article-dialog.create-and-edit" />
      </GenevaButton>
    </div>)
  }


  render() {
    const {
      options: { articleTemplate, widgetTemplate },
      show
    } = this.props

    const templateSet = this.sortList(
      this.filterList([
        ...(articleTemplate.collection || []),
        ...(widgetTemplate.collection || [])
      ])
    )

    const setMinHeight = templateSet.length > 4 ? 'min-height' : ''

    return (
      <Dialog
        className={classNames(css.newArticleDialog, setMinHeight)}
        overlayClassName={css.tableDialogOverlay}
        isOpen={show}
        onAfterOpen={this.setScrollbar}
        onRequestClose={this.handleRequestClose}
        renderFooter={this.renderFooter}
        title={formatMessage({ id: 'new-article-dialog.title' })}
        focusEl={this.getFocus}
        id="newArticleDialog"
      >
        <div className={classNames(css.newArticleItems, 'vertical grid-content')}>
          <div className={classNames('grid-block', css.sortFilter)}>
            <div className="grid-block small-8 filter-block v-align align-center">
              <input
                placeholder={formatMessage({
                  id: 'new-article-dialog.search-templates'
                })}
                type="text"
                onChange={this.handleFilterChange}
                value={this.state.filter}
                ref={this.handleRef}
              />
            </div>
            <div className="sort grid-content v-align small-4">
              <label htmlFor="sortNewArticle">
                <FormattedMessage id="new-article-dialog.sort-title-text" />

                <select
                  onChange={this.handleSortChange}
                  id="sortNewArticle"
                >
                  <option value="ascending">{formatMessage({
                    id: 'new-article-dialog.ascending'
                  })}
                  </option>
                  <option value="descending">{formatMessage({
                    id: 'new-article-dialog.descending'
                  })}
                  </option>
                </select>
              </label>
              <span className="float-right muted">
                {
                  articleTemplate.loading || widgetTemplate.loading
                    ? (<FormattedMessage
                      id="new-article-dialog.loading-more-templates"
                    />)
                    : null
                }
              </span>
            </div>
          </div>
          <hr />
          <div
            id="newArticle-container"
            className="vertical grid-block new-article-display grid"
          >
            <ul className="image-list">{
              templateSet.map(currTemplate => (
                <Template
                  key={`${currTemplate.id}${currTemplate.templateType}`}
                  filter={this.state.filter}
                  selected={currTemplate === this.state.selectedTemplate}
                  onSelect={this.handleSelect}
                  template={currTemplate}
                  disabled={currTemplate.disabled}
                  onDoubleClick={this.handleCreateAndEdit}
                />
              ))
            }
            </ul>
          </div>
        </div>
      </Dialog>
    )
  }

}

export default NewArticle
