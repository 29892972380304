import { transaction } from 'mobx'
import { Command } from '../../../../../shared/lib/command'
import WidgetContentAction from '../../../actions/WidgetContentAction'

export default
class MoveContentInWidgetCommand
  extends Command {

  static storeRequirements = {
    widget: true
  }

  static validationSpec = {
    widgetId: Command.ensureData.isNumber,
    moveFromPosition: Command.ensureData.isPresent,
    moveToPosition: Command.ensureData.isPresent,
  }

  constructor(data: Object = {}) {
    super(data)
  }

  exec() {
    const {
      store,
      moveFromPosition,
      moveToPosition,
      widgetId
    } = this.data

    const widget = store.widget.getById(widgetId)

    if (widget && moveFromPosition && moveToPosition) {

      const widgetRemoveAction = new WidgetContentAction(
        widget,
        [...moveFromPosition],
        WidgetContentAction.REMOVE
      )

      const widgetInsertAction = new WidgetContentAction(
        widget,
        [...moveToPosition],
        WidgetContentAction.INSERT
      )


      try {

        return transaction(() => {

          const item = widgetRemoveAction.execute()

          if (item && item.length > 0 && item[0]) {
            widgetInsertAction.execute(item[0])
          }

          // this will not return anything as it's debounced.
          // debouncing is necessary as we want to give the
          // user the chance to move more than one position
          // before the widget is saved
          return widget.saveDebounced()
        })

      }
      catch (ex) {
        console.error('Could not move item', ex)
      }
    }
  }

}
