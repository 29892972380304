import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import ReactCSSTransitionGroup from 'react-transition-group/CSSTransitionGroup'
import { autobind } from 'core-decorators'
import classNames from 'classnames'
import { FormattedMessage } from '../../../translations'
import ContentLoadingBox from '../../../shared/components/ContentLoadingBox'
import { selectArticleDialog, newArticleDialog } from '../../../page'
import Icon from '../../../shared/icons'
import Dropdown, { DropdownContainer, Entry } from '../../../shared/components/Dropdown'
import connectContentRendererToolsToCommands from '../../../shared/connectors/connectContentRendererToolsToCommands'
import GenevaDropdownOpener from '../../../ui/components/GenevaDropdownOpener'
import { testClass } from '../../../shared/utils'

// eslint-disable-next-line max-len

import * as css from './styles.scss'

const HEIGHT_OF_DROPDOWN = 100
export class ContentRendererTools extends Component {
  static propTypes = {
    pageId: PropTypes.number.isRequired,
    allowedArticleTemplates: PropTypes.array,
    allowedWidgetTemplates: PropTypes.array,
    gr: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    gb: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    index: PropTypes.number,
    disabled: PropTypes.bool,

    // props from connectContentRendererToolsToCommands
    onInsertContent: PropTypes.func,
    loading: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    loadingMessage: PropTypes.string,
  };

  constructor(props) {
    super(props)
    this.state = { flipUp: false }
  }

  @autobind
  handleCreateNewArticle() {
    newArticleDialog({
      allowedArticleTemplates: this.props.allowedArticleTemplates,
      allowedWidgetTemplates: this.props.allowedWidgetTemplates,
    })
      .then((data) => {
        if (
          data.action.indexOf('createArticle') >= 0
          && 'onInsertContent' in this.props
        ) {
          this.props.onInsertContent({
            pageId: this.props.pageId,
            type: data.template.templateType,
            template: data.template,
            openForEditing: data.action === 'createArticleAndEdit',
            position: {
              gr: this.props.gr,
              gb: this.props.gb,
              index: this.props.index,
            },
          })
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }

  @autobind
  handleSelectArticle() {
    selectArticleDialog({
      pageId: this.props.pageId,
      allowedArticleTemplates: this.props.allowedArticleTemplates,
    })
      .then((data) => {
        if (
          data.action === 'insertArticle'
          && 'onInsertContent' in this.props
        ) {
          this.props.onInsertContent({
            pageId: this.props.pageId,
            // todo determine type in modal
            type: data.type,
            [data.type]: data[data.type],
            position: {
              gr: this.props.gr,
              gb: this.props.gb,
              index: this.props.index,
            },
          })
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }

  @autobind
  handleOnOpen(event) {
    this.setState({
      flipUp: (window.innerHeight - event.clientY < HEIGHT_OF_DROPDOWN)
    })
  }

  render() {
    const { flipUp } = this.state
    return (
      <div
        className={`grid-block align-center vertical
        ${css.contentRendererTools} overflow`}
      >
        <div
          className="grid-block text-center overflow"
          style={{
            overflow: 'visible',
          }}
        >
          <DropdownContainer>
            <GenevaDropdownOpener
              clickToClose
              className={classNames(
                testClass('add-article-button'),
                'geneva-small-button page-dropdown-opener'
              )}
              id="add-article-button"
              caret={false}
              disabled={this.props.disabled}
              onOpen={this.handleOnOpen}
            >
              <Icon name="ion-ios-plus-empty" />
            </GenevaDropdownOpener>

            <Dropdown
              className={classNames(flipUp ? 'flipUp' : '', 'geneva-dropdown')}
            >
              <Entry
                onClick={this.handleCreateNewArticle}
                id="create-new-article"
              >
                <FormattedMessage id="page.create-new-article" />
              </Entry>
              <Entry
                onClick={this.handleSelectArticle}
                id="select-existing-article"
              >
                <FormattedMessage id="page.select-existing-article" />
              </Entry>
            </Dropdown>
          </DropdownContainer>
        </div>
        <ReactCSSTransitionGroup
          component="div"
          className="grid-block text-center react-transition-group"
          transitionName="fade"
          transitionEnter
          transitionEnterTimeout={500}
          transitionLeave
          transitionLeaveTimeout={300}
          transitionAppear
          transitionAppearTimeout={500}
        >
          {this.props.loading > 0 ? (
            <ContentLoadingBox
              className={css.toolsLoader}
              message={{
                id: this.props.loadingMessage || 'content.creating-number',
                values: {
                  count: this.props.loading,
                },
              }}
            />
          ) : null}
        </ReactCSSTransitionGroup>
      </div>
    )
  }
}

// eslint-disable-next-line max-len
const CommandConnectedContentRendererTools = connectContentRendererToolsToCommands(
  ContentRendererTools
)

export { CommandConnectedContentRendererTools as default }
