import PropTypes from 'prop-types'
import React from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import { Accordion, AccordionItem } from 'react-sanfona'
import { formatMessage, FormattedMessage } from '../../translations'

import {
  getPageTemplateNameById
} from '../../shared/utils'
import { TEMPORARY_PUBLISHED_URL, TEMPORARY_UNPUBLISHED_URL } from '../../shared/const'
import { convertDateToUserLocal } from '../../shared/DateConverter'
import {
  PAGE_TYPE_ARCHIVE_PAGE,
  PAGE_TYPE_ARCHIVE_SPECIAL_PAGE
} from '../../shared/const'

import { store as articleStore } from '../../article'
import { widgetStore } from '../../widget/reducers'

const contentStores = {
  article: articleStore,
  widget: widgetStore
}

const css = /* typeof window === 'undefined' ? {} : */require('../styles.scss')

// displays publication link information, like url or a message
const PublicationInfo = ({ publishedUrl }) => {
  if (publishedUrl) {
    if (publishedUrl === TEMPORARY_PUBLISHED_URL) {
      return (<span>{
        formatMessage({ id: 'status.page.publish-started' })
      }
      </span>)
    }
    if (publishedUrl === TEMPORARY_UNPUBLISHED_URL) {
      return (<span>{
        formatMessage({ id: 'status.page.unpublish-started' })
      }
      </span>)
    }

    return (<a
      href={publishedUrl}
      rel="noreferrer noopener"
      target="_blank"
    >{publishedUrl}
    </a>)
  }

  return (<span>{
    formatMessage({ id: 'page.offline' })
  }
  </span>)
}

// detailed information on the selected page item
const SelectedItem = ({ context, className }) => {
  if (context && context.type && context.id) {
    if (context.type !== 'article' && context.type !== 'widget') {
      // Bail out if context cannot be handled
      return null
    }
    const item = context[context.type] || contentStores[context.type].getById(context.id)
    const templateName = context.type === 'widget' ? 'widgetTemplate' : 'template'

    const templates = global.GENEVA_CONFIG[`${templateName}s`]
    let currentTemplateName = ''

    if (item[templateName]) {
      const foundTemplate = templates.find(
        template => template.shortcut === item[templateName].shortcut
      )
      currentTemplateName = foundTemplate ? foundTemplate.name : ''
    }

    return (<div className={classNames(className, css.pageStatus)}>
      <dl>
        <div>
          <dt><FormattedMessage id="item.title" /></dt>
          <dd>
            {item.name}
          </dd>
        </div><div>
          <dt><FormattedMessage id="item.language" /></dt>
          <dd>
            {item.createdIso}
          </dd>
        </div><div>
          <dt><FormattedMessage id="item.updated-at" /></dt>
          <dd>
            <FormattedMessage id="item.updated-at-details"
              values={{
                date: convertDateToUserLocal(item.updatedAt, { barDisplay: true }),
                user: item.updatedByName
              }}
            />
          </dd>
        </div><div>
          <dt><FormattedMessage id="item.template" /></dt>
          <dd>
            {currentTemplateName}
          </dd>
        </div>
      </dl>
    </div>)
  }
  return null
}

// details on current locked status of the page and date to be unlocked, if needed
const LockedDetails = ({ page }) => {
  if (page.publicationNotAllowed) {
    let lockReleaseDate = ''
    if (page.releasePublicationLockAt) {
      lockReleaseDate = `${formatMessage({
        id: 'page.releaseAtDate',
      })} ${convertDateToUserLocal(page.releasePublicationLockAt, { barDisplay: true })}`
    }
    return (<div>
      <FormattedMessage
        id="page.blocked"
        values={{
          date: lockReleaseDate
        }}
      />
    </div>)
  }

  return null
}

// details on the future or current published status with dates, if needed
const PublishDetails = ({ page }) => {
  if (page.publishAt) {
    return (<div>
      <FormattedMessage
        id="page.publish-at"
        values={{
          date: convertDateToUserLocal(page.publishAt, { barDisplay: true })
        }}
      />
    </div>)
  }

  return null
}

// details on future unpublishing date, if needed
const UnpublishDetails = ({ page }) => {
  if (page.unpublishAt) {
    return (<div>
      <FormattedMessage id="page.unpublish-at" />
      {convertDateToUserLocal(page.unpublishAt, { barDisplay: true })}
    </div>)
  }
  return null
}

// goes through possible page statues and prints one
const PageStatus = ({ page }) => {
  if (page.type === PAGE_TYPE_ARCHIVE_PAGE
    || page.type === PAGE_TYPE_ARCHIVE_SPECIAL_PAGE) {
    return (<FormattedMessage id="page.archived" />)
  }

  if (page.publishedUrl && page.publishedUrl !== TEMPORARY_PUBLISHED_URL) {
    return (<div>
      <FormattedMessage id="page.published" />
      {convertDateToUserLocal(page.lastPublishedAt, { barDisplay: true })}
    </div>)
  }

  return (<FormattedMessage id="page.unpublished" />)
}

// general render (only real public function)
const PageInfo = ({
  page, channel, className, publishedUrl, context, activeTabs, handleTabChange, handleInternalLinkDialog, psInfo
}) => {
  const itemTypeSelected = (context && context.type)
    ? `page-info.${context.type}-selected`
    : 'page-info.article-selected'

  // Not garaunteed that the backend has a user for all edits.
  //  This can eventually be removed.
  const lastUpdatedUser = page.updatedByName !== 'Unknown user'
    ? formatMessage({
      id: 'item.updated-at-details' },
    {
      date: convertDateToUserLocal(page.updatedAt, { barDisplay: true }),
      user: page.updatedByName
    })
    : formatMessage({
      id: 'item.updated-only-date' },
    {
      date: convertDateToUserLocal(page.updatedAt, { barDisplay: true }),
    })

  if (psInfo) {
    psInfo.update()
  }

  return (
    <Accordion
      allowMultiple
      className={css.pageStatusAccordion}
    >
      <AccordionItem
        title={<div className="react-sanfona-item-title page-info-title">
          <div className="accordion-title">
            <FormattedMessage id="page.page" />
          </div>
        </div>}
        slug="pageAccordion"
        key="pageAccordion"
        onExpand={handleTabChange}
        onClose={handleTabChange}
        bodyClassName="page-status-accordion-body"
        expanded={!!activeTabs.find(tab => tab === 'pageAccordion')}
      >
        <div className={classNames(className, css.pageStatus)}>
          <dl>
            <div>
              <dt><FormattedMessage id="page.created-at" /></dt>
              <dd>
                {convertDateToUserLocal(page.createdAt, { barDisplay: true })}
              </dd>
            </div>

            <div>
              <dt><FormattedMessage id="page.last-changed" /></dt>
              <dd>
                {lastUpdatedUser}
              </dd>
            </div>

            <div>
              <dt>Status</dt>
              <dd>
                <PageStatus
                  page={page}
                />
                <LockedDetails
                  page={page}
                />
                <PublishDetails
                  page={page}
                />
                <UnpublishDetails
                  page={page}
                />
              </dd>
            </div>

            <div>
              <dt><FormattedMessage id="page.name" /></dt>
              <dd>{page.name}</dd>
            </div>

            <div>
              <dt><FormattedMessage id="page.template" /></dt>
              <dd>{getPageTemplateNameById(page.templateId, channel)}</dd>
            </div>

            <div>
              <dt><FormattedMessage id="page.title" /></dt>
              <dd>{page.title || '-'}</dd>
            </div>

            <div>
              <dt><FormattedMessage id="page.link-target" /></dt>
              <dd onClick={page.internalLinks && page.internalLinks.data.length ? handleInternalLinkDialog : null}>
                <FormattedMessage
                  id="page.link-target.pages"
                  className={page.internalLinks && page.internalLinks.data.length ? 'internal-link' : ''}
                  values={{
                    count: page.internalLinks && page.internalLinks.data.length
                  }}
                />
              </dd>
            </div>

            <div>
              <dt><FormattedMessage id="page.url" /></dt>
              <dd>
                <PublicationInfo
                  publishedUrl={publishedUrl}
                />
              </dd>
            </div>

            <div>
              <dt><FormattedMessage id="page.description" /></dt>
              <dd>{page.description || '-'}</dd>
            </div>

            <div>
              <dt><FormattedMessage id="page.keywords" /></dt>
              <dd>{page.keywords || '-'}</dd>
            </div>

          </dl>
        </div>
      </AccordionItem>
      { !context
        ? ''
        : <AccordionItem
          title={<div className="react-sanfona-item-title page-info-title">
            <div className="accordion-title">
              <FormattedMessage id={itemTypeSelected} />
            </div>
          </div>}
          slug="selectedAccordion"
          key="selectedAccordion"
          onExpand={handleTabChange}
          onClose={handleTabChange}
          bodyClassName="page-status-accordion-body"
          expanded={!!activeTabs.find(tab => tab === 'selectedAccordion')}
        >
          <SelectedItem
            page={page}
            context={context}
            className={className}
          />
        </AccordionItem>
      }
    </Accordion>
  )
}

PageInfo.propTypes = {
  page: PropTypes.object,
  channel: PropTypes.string.isRequired,
  className: PropTypes.string,
  publishedUrl: PropTypes.string,
  context: PropTypes.object,
  activeTabs: PropTypes.array,
  handleTabChange: PropTypes.func,
}

export default observer(PageInfo)
