import { validate } from './validate'

const methods = [
  ...Object.keys(validate || {})
  .filter(method => /^is[A-Z]/.test(method)),
  'isPresent',
  'oneOf',
  'oneOfType',
  'either',
  'raw',
  'get'
]

const addMethod = (target, method, defineGetter, defineMethod) => {
  if (!(method in target)) {

    if (/^is[A-Z]/.test(method)) {
      const prop = method.replace(/^is([A-Z])/, (all, match1) => {
        return match1.toLowerCase()
      })
      Object.defineProperty(target, method, {
        get: defineGetter(prop, method),
        enumerable: true
      })
      return
    }

    target[method] = defineMethod(method)

  }
}

interface IValidationHelperInternal {
  [key: string]: any;
}

interface IValidationHelper extends IValidationHelperInternal {
  new (): IValidationHelperInternal;
  [key: string]: any;
}

class ValidationHelper
implements IValidationHelperInternal {

  spec: {[key: string]: any} = {}

  constructor () {

    methods.forEach((method) => {
      addMethod(this, method, (prop) => {
        return function () {
          this.spec[prop] = true
          return this
        }
      }, (method) => {
        return function (opts = true) {
          this.spec[method] = opts
          return this
        }
      })
    })
  }

  raw(opts) {
    Object.assign(this.spec, opts)
  }

  get() {
    return this.spec
  }

  get isPresent() {
    this.spec.presence = true
    return this
  }

}

methods.forEach((method) => {
  try {
    addMethod(ValidationHelper, method, (prop, method) => {
      return function () {
        const helper = new ValidationHelper()
        return helper[method]
      }
    }, (method) => {
      return function (opts = true) {
        const helper = new ValidationHelper()
        return helper[method](opts)
      }
    })
  } catch(ex) {
    if (method === 'length') {
      return // silently skip length for now
    }
    console.warn(`ValidationHelper: Skipping method \`${method}\``)
  }
})


const H = ValidationHelper as IValidationHelper
export { H as default}
