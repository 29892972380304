export default
class TemplateLoadingError extends Error {

  constructor(id, templateName, origError) {
    let message = ''

    if (origError && origError instanceof Error) {
      message = `${origError.message}: ${origError.stack}`
    }

    super(message || '')
    this.id = id || ''
    this.templateName = templateName || 'unknown'
    this.origError = origError || null
  }

}
