import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import { autobind, throttle } from 'core-decorators'
import classNames from 'classnames'

import { getHTMLEventsFromProps } from '../../../shared/utils/events'

// const RESIZE_EVENTS = {
//   onResize: true,
//   onResizeStart: true,
//   onResizeEnd: true,
// }

function isNumber(num) {
  return num && Object.prototype.toString.call(num * 1) === '[object Number]'
}

function validateSize(size) {
  return {
    width: isNumber(parseInt(size.width, 10))
      ? parseInt(size.width, 10)
      : 'auto',
    height: isNumber(parseInt(size.height, 10))
      ? parseInt(size.height, 10)
      : 'auto',
  }
}

function validateLimit(limit = null) {
  switch (limit) {
    case 'x':
    case 'h':
    case 'horizontally':
      return 'x'
    case 'y':
    case 'v':
    case 'vertically':
      return 'y'
    default:
      return null
  }
}

const SIZE_CONSTRAINT_PROPS = [
  'minWidth',
  'minHeight',
  'maxWidth',
  'maxHeight',
]

class Resizable extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    onResizeEnd: PropTypes.func,
    onResizeStart: PropTypes.func,
    onResize: PropTypes.func,
    children: PropTypes.node,
    value: PropTypes.object,
    limit: PropTypes.string,
    css: PropTypes.shape({
      resizable: PropTypes.string,
      resizeHandle: PropTypes.string,
      resizeHelper: PropTypes.string,
    }),
    className: PropTypes.string,
    minWidth: PropTypes.number,
    minHeight: PropTypes.number,
  };

  static defaultProps = {
    //   css: {}
    minWidth: 0,
    minHeight: 0,
    // eslint-disable-next-line react/default-props-match-prop-types
    maxWidth: null,
    // eslint-disable-next-line react/default-props-match-prop-types
    maxHeight: null,
  };

  constructor(props) {
    super(props)

    this.state = {
      resizeActive: false,
      ...validateSize(this.props.value),
      limit: validateLimit(this.props.limit),
      ...SIZE_CONSTRAINT_PROPS.reduce((memo, prop) => {
        memo[prop] = props[prop]
        return memo
      }, {}),
    }
  }

  componentWillReceiveProps(nextProps) {
    let newState = {}
    const needsSetState = false

    if (
      nextProps.limit !== this.props.limit
      || nextProps.value !== this.props.value
    ) {
      newState = {
        ...validateSize(nextProps.value),
        limit: validateLimit(nextProps.limit),
      }
    }

    SIZE_CONSTRAINT_PROPS.forEach((prop) => {
      if (nextProps[prop] !== this.props[prop]) {
        newState[prop] = nextProps[prop]
      }
    })

    if (needsSetState) {
      this.setState(newState)
    }
  }

  @autobind
  @throttle(100)
  throttledPublishResizing(newState) {
    if (this.props.onResize) {
      this.props.onResize()
    }
    this.publishChange(newState)
  }

  // TODO: this does not work right now due to:
  // http://stackoverflow.com/questions/25777826/onclick-works-but-ondoubleclick-is-ignored-on-react-component
  @autobind
  handleDoubleClick() {
    const state = {
      width: 'auto',
      height: 'auto',
    }
    this.publishChange(state)
    if (this.props.onResizeEnd) {
      this.props.onResizeEnd(state)
    }
  }

  @autobind
  handleMouseDown(e) {
    if (this.resizable) {
      if (this.props.onResizeStart) {
        this.props.onResizeStart()
      }

      const size = this.resizable.getBoundingClientRect()

      this.setState({
        resizeActive: true,
        startX: e.screenX,
        startY: e.screenY,
        startWidth:
          this.props.limit === 'y' ? this.resizable.style.width : size.width,
        startHeight:
          this.props.limit === 'x' ? this.resizable.style.height : size.height,
      })
    }
  }

  @autobind
  handleMouseMove(e) {
    const {
      limit,
      startWidth,
      startX,
      startHeight,
      startY,
      minWidth,
      maxWidth,
      minHeight,
      maxHeight,
    } = this.state

    const newState = {}

    if (!limit || limit === 'x') {
      newState.width = Math.max(minWidth, startWidth + e.screenX - startX)

      if (maxWidth) {
        newState.width = Math.min(maxWidth, newState.width)
      }
    }

    if (!limit || limit === 'y') {
      newState.height = Math.max(minHeight, startHeight + e.screenY - startY)

      if (maxHeight) {
        newState.width = Math.min(maxHeight, newState.height)
      }
    }

    this.setState(newState)
    this.throttledPublishResizing(newState)
  }

  @autobind
  handleMouseUp() {
    this.setState({
      resizeActive: false,
    })

    const state = {
      width: this.state.width,
      height: this.state.height,
    }

    this.publishChange(state)

    if (this.props.onResizeEnd) {
      this.props.onResizeEnd(state)
    }
  }

  publishChange(value) {
    this.setState(value)

    if (this.props.onChange) {
      this.props.onChange({
        target: {
          value,
        },
      })
    }
  }

  render() {
    const { width, height } = this.state
    let { css } = this.props

    if (!css) {
      css = {}
    }

    const eventHandlers = getHTMLEventsFromProps(this.props)

    return (
      <div
        className={classNames('resizable', this.props.className, css.resizable)}
        style={{
          ...(this.props.style || {}),
          width: isNumber(width) ? `${width}px` : 'auto',
          height: isNumber(height) ? `${height}px` : 'auto',
        }}
        ref={ref => (this.resizable = ref)}
        {...eventHandlers}
      >
        {this.props.children}
        <span
          className={classNames('resize-handle', css.resizeHandle)}
          onDoubleClick={this.handleDoubleClick}
          onMouseDown={this.handleMouseDown}
        />
        {this.state.resizeActive ? (
          <div
            onMouseMove={this.handleMouseMove}
            onMouseUp={this.handleMouseUp}
            className={css.resizeHelper}
          />
        ) : null}
      </div>
    )
  }
}

export { Resizable as default }
