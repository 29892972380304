exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".src-shared-components-ContentErrorBox-styles__contentErrorBox___3mZKO{background-color:#fff;display:block;margin:0 auto;margin-top:40px;padding:25px}\n", "", {"version":3,"sources":["/builds/GENEVA/wm/frontend/src/shared/components/ContentErrorBox/styles.scss"],"names":[],"mappings":"AAEA,uEACE,sBACA,cACA,cACA,gBACA,YAAa,CACd","file":"styles.scss","sourcesContent":["@import \"src/styles/variables\";\n\n.contentErrorBox {\n  background-color: $secondary-color;\n  display: block;\n  margin: 0 auto;\n  margin-top: 40px;\n  padding: 25px;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"contentErrorBox": "src-shared-components-ContentErrorBox-styles__contentErrorBox___3mZKO"
};