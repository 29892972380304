import { computed, observable } from 'mobx'
import { store as uiStore } from '../ui'
import { inspector, fileInspector } from '../shared/decorators/inspector'
import { apiClient } from '../shared/api'
import Image from './model'
import { Store } from '../shared/store'
import { EditImageCommand, UploadImageCommand } from './commands'
import { addToCollection } from '../shared/utils'
import { getBaseUrl } from '../shared/api/decorators/apiClient'
import { CircularDependencyFreeStore } from '../CircularDependencyFreeStore'

@inspector('save', fileInspector)
@apiClient
export class ImageStore extends Store {

  // Put all article list properties here. This regargds also state properties.

  @observable collection = []

  @observable current = {}

  @computed get hasCurrent() {
    return !!this.current.id
  }

  @observable loading = false

  hasMoreItems = true

  @observable filter = {}

  @observable languageReferences = []

  static storeName = 'ImageStore'

  constructor(opts = {}) {

    super(opts)

    this.Model = Image

  }

  @observable editing = false

  getPreviewURL(url) {
    return `${getBaseUrl()}/image/preview?url=${url}`
  }

  /**
   * Edit a set of images with the given channel
   * @param {Array} images
   * @param {String} channel
   * @param {String} requestedLang
   * @param {Object} opts
   * @returns {Promise}
   */
  editImages(images, channel, requestedLang, opts) {
    return new EditImageCommand(
      this,
      { images, channel },
      {
        params: {
          lang: requestedLang
        },
        ...opts
      }
    )
      .exec()
  }

  /**
   * Upload a single image.
   * @param {Object} image
   * @param {Object} opts
   * @returns {Promise}
   */
  uploadImage(image, opts) {

    return new UploadImageCommand(this, image, opts)
      .exec()

  }

  // Adds progress information to api call
  progress(progress) {
    uiStore.addStatusInfo({
      // eslint-disable-next-line no-use-before-define
      id: ImageStore.name,
      update: true,
      priority: 'low',
      progress: progress.loaded,
      total: progress.total
    }, 'forever')
  }

  /**
  * Gets a model from the store by it's id.
  * Different from default by allowing a Hex value comparison and
  *  not forcing comparison as a std Int
  * @param {String} id - image id's are hex string
  * @returns Null if no model by this id was found
  */
  getById(id) {

    return this.collection.find(item => item && item.id === id
    )
  }

  /**
   * Overweriting model creating here to accomodate for multiple models
   * returned from the server.
   * @param {Object} opts - is highly expected to be passed in with lang
   */
  create(data, opts = {}) {

    const model = this.createModel(data)
    if (data.source) {
      Object.assign(opts.params, data.source)
    }

    return this.apiSave(null, model, opts)
      .catch((err) => {
        this.remove(model)
        throw err
      })
  }

  load(id = null, opts = {}) {
    return this.dispatch('get', {
      path: `image/${id}`,
      params: opts.params
    }).then((response) => {
      if (!response || !response.body || response.error) {
        throw new Error('error in page get')
      }
      const model = addToCollection(this, this.collection, response.body.data, Image)
      return model
    })
  }

  /**
   * used for image propigation
   * @param {String}} id
   * @param {Object} data
   * @param {Object} opts - params
   * @returns Image block
   */
  save(id, data, opts = {}) {
    return this.dispatch('post', {
      path: `image/${id}`,
      params: opts.params,
      data
    }).catch((err) => {
      throw err
    })
  }

  apiSave(id = null, data = {}, opts = {}) {
    const reqData = {}
    let fileData = null
    reqData.constraints = data.constraints
    reqData.projectId = data.projectId
    reqData.id = data.id

    const path = data.id ? `image/${data.id}` : 'image'

    // if image is url
    if (data.url) {
      reqData.url = [data.url]
      fileData = reqData
    }
    // if image is an actual file
    else {
      const formData = new FormData()

      formData.append('json', JSON.stringify(reqData))
      if (data.file) {
        formData.append('file', data.file, data.file.name)
      }
      fileData = formData
    }

    return this.dispatch('post', {
      path,
      data: fileData,
      params: opts.params,
      progress: this.progressTracker
    })
      .then(body => this.handleUploadResult(body, opts))
  }

  /** @private */
  handleUploadResult(response) {
    let entries = response.body.data
    if (!Array.isArray(entries)) {
      entries = [entries]
    }
    return Promise.all(entries.map((entry) => {
      return addToCollection(this, this.collection, entry, Image)
    }))
  }

  destroy(imageId) {
    if (imageId) {
      return this.dispatch('del', {
        path: `image/${imageId}`,
      })
    }
    throw new Error('missing image for image delele')
  }

  canLoad() {
    return !this.loading && this.hasMoreItems
  }

}

const imageStore = new ImageStore()

CircularDependencyFreeStore.imageStore = imageStore

export { imageStore as imageStore }
