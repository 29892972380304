import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { autobind } from 'core-decorators'
import classNames from 'classnames'
import { formatMessage } from '../../translations'

import Navbar, {
  FixedSection
} from '../../shared/components/Navbar'
import * as css from '../styles.scss'

@observer
export default class MediaManagerNavbar extends Component {

  static propTypes = {
    handleInputChange: PropTypes.func.isRequired,
    navbarStyle: PropTypes.string,
    onCancel: PropTypes.func.isRequired,
    searchValue: PropTypes.string,
    imageCount: PropTypes.number,
  }

  static contextTypes = {
    router: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      searchValue: this.props.searchValue
    }
  }

  @autobind
  handleClearSearch() {
    if (this.props.handleInputChange) {
      this.setState({ searchValue: '' })
      this.props.handleInputChange({ target: { id: 'searchValue', value: '' } })
    }
  }

  @autobind
  handleInputChange(e) {
    if (this.props.handleInputChange) {
      this.setState({ searchValue: e.target.value })
      this.props.handleInputChange(e)
    }
  }

  @autobind
  handleCancel() {
    if (this.props.onCancel) {
      this.props.onCancel()
    }
  }

  renderBrand() {
    return (
      <FixedSection>
        <img className="logo" role="presentation" src="/images/atr-geneva-red.svg" />
        <div className={css.mediaManagerTitleText}>
          <span>MEDIA</span>
          <span>MANAGER</span>
        </div>
      </FixedSection>
    )
  }

  render() {

    const {
      navbarStyle,
    } = this.props

    return (
      <Navbar
        navbarStyle={navbarStyle}
      >

        {this.renderBrand()}

        <FixedSection>
          <span className="inline-label">
            <input
              id="searchValue"
              type="text"
              value={this.state.searchValue}
              onChange={this.handleInputChange}
              placeholder={formatMessage({ id: 'mm.search-placeholder' })}
            />
            <a className="inline-icon">
            </a>
            <a
              onClick={this.handleClearSearch}
              className={classNames('cancel-icon', this.state.searchValue.length > 0 ? '' : 'hide')}
            >
            </a>
          </span>

          <a className="cancel-modal-icon" onClick={this.handleCancel}>
          </a>
        </FixedSection>


      </Navbar>
    )
  }

}
