import React from 'react'
import { observable } from 'mobx'
import * as PropTypes from 'prop-types'
import { FormattedMessage, formatMessage } from '../../translations'
import { store as uiStore } from '../../ui'
import config from '../../config'
import { versionizePath } from '../utils'

export default (hook, additionalDeps) => {
  const deps = {
    React,
    observable,
    FormattedMessage,
    formatMessage,
    PropTypes,
    config,
    ...additionalDeps,
  }

  const matchingPlugins = Object.keys(config.plugins)
    // Iterate over all known plugins and reduce to all those that contain a
    // module for given `hook`.
    .filter(key => hook in config.plugins[key])
    // create a datatstructure of plugin name and the matching plugin
    .map(key => [key, config.plugins[key]])

  return Promise.all(
    matchingPlugins
      // load all the plugins
      .map(([name, pluginSpec]) => {
        const url = versionizePath(
          `/templates/default/plugin/${name}/index.js`
        )

        return global.System.import(url)
          .then((module) => {
            const errorReturn = {
              hook: null,
            }

            if (module.dependencies) {
              if (module.dependencies.lang) {
                Object.keys(module.dependencies.lang)
                  .filter(lang => uiStore.language === lang)
                  .forEach(lang => uiStore.addTranslations(
                    lang,
                    module.dependencies.lang[lang]
                  )
                  )
              }
            }

            if (!(hook in module)) {
              console.warn(`Plugin for hook "${hook}" not exported in ${url}`)
              return errorReturn
            }

            return module[hook](deps)
          })
          .then(module => ({ module, pluginSpec }))
          .catch(error => ({ module: error, pluginSpec, name }))
      })
  )
}
