import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { autobind } from 'core-decorators'

import { store as widgetTemplateStore } from '..'
import GenevaButton from '../../ui/components/GenevaButton'

const css = /* typeof window === 'undefined' ? {} : */require('../dev-styles.scss')

@observer
export default class DevTools extends Component {

  static propTypes = {
  }

  @autobind
  handleClickReload() {
    widgetTemplateStore.actionResetModule(widgetTemplateStore.current.id)
  }

  @autobind
  handleAlwaysReloadStylesheetChange({ target }) {
    widgetTemplateStore.alwaysReloadStyleSheets = target.checked
  }

  render() {

    return (
      <div className={css.devtools}>
        <div className="grid-block">
          <div className="grid-block vertical">
            <input
              id="dev-slider-reload-stylesheets"
              type="checkbox"
              value="alwaysReloadStyleSheets"
              checked={widgetTemplateStore.alwaysReloadStyleSheets}
              onChange={this.handleAlwaysReloadStylesheetChange}
            />
            <label htmlFor="dev-slider-reload-stylesheets">
              <span>Reload Stylesheets</span>
            </label>
          </div>
          <GenevaButton
            disabled={!widgetTemplateStore.current.module}
            onClick={this.handleClickReload}
            className="small button"
          >
            Reload
          </GenevaButton>
        </div>
      </div>
    )

  }

}
