import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import { autobind } from 'core-decorators'

export default class ManagedTextField extends Component {
  static propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    className: PropTypes.string,
    defaultName: PropTypes.string,
  };

  constructor(props) {
    super(props)
    this.state = {
      value: this.props.value,
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      (!this.state.value && nextProps.current)
      || (nextProps.value && this.state.value !== nextProps.value)
    ) {
      this.setState({
        value: nextProps.value,
      })
    }
  }

  @autobind
  handleTextChange({ target }) {
    this.setState({
      value: target.value,
    })
  }

  @autobind
  handleOnBlur() {
    let articleName = this.state.value
    if (articleName !== this.props.value) {
      // allow the name to be reset to the default
      if (articleName === '' && this.props.defaultName) {
        articleName = this.props.defaultName
      }
      // save value and name error checking
      if (this.props.onChange) {
        this.props.onChange({
          target: {
            value: articleName,
          },
        })
      }
    }
    // if the value is cleared, set it back to the default
    if (!this.state.value || this.state.value.trim() === '') {
      this.handleTextChange({ target: { value: this.props.defaultName } })
    }
  }

  @autobind
  handleFocus() {
    if (this.state.value === this.props.defaultName) {
      this.handleTextChange({ target: { value: '' } })
    }
  }

  render() {
    return (
      <input
        type="search"
        className={this.props.className}
        value={this.state.value}
        onChange={this.handleTextChange}
        onBlur={this.handleOnBlur}
        onFocus={this.handleFocus}
      />
    )
  }
}
