import { Command } from '../../shared/lib/command'

/**
  * Copy one or more pages to the children of given parentId
  * @param {Number} pageId - The ID of the page to copy
  * @param {Number} parentId - ID of the parent page
  * @param {Number} position - The position of the the new page in the child array
  * @param {Bool} followingPages - Whether the sub pages should be copied
*/
export default class CopyPageCommand extends Command {

  static storeRequirements = {
    page: true
  }

  static validationSpec = {
    pageId: Command.ensureData.isNumber,
    parentId: Command.ensureData.isNumber,
    followingPages: Command.ensureData.isBoolean,
    position: Command.ensureData.isNumber
  }

  exec() {
    const {
      store,
      pageId,
      parentId,
      followingPages,
      position
    } = this.data

    const data = {
      parentId,
      followingPages,
      position
    }

    // execute on pageStore.save
    return store.page.copy(pageId, data)
      .then((models) => {

        let parentPage

        // Connect the pages to update trees
        models.forEach((item) => {
          parentPage = store.page.getPartialById(item.parentId)
          if (parentPage) {
            parentPage.addChild(item, item.position - 1)
          }
        })
      })
      .catch(() => {
        throw Error('Cannot copy pages')
      })
  }
}
