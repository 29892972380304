import { createConfirmation } from 'react-confirm'
import { store as articleStore } from '../../../article'
import ImagePropagationDialog from '../../components/dialogs/ImagePropagation'

const imagePropagation = createConfirmation(ImagePropagationDialog)

export default function (confirmation, options = {}) {

  const currentArticle = articleStore.current

  if (typeof confirmation !== 'string') {

    options = {
      ...confirmation,
      langList: currentArticle.languages,
      currentLang: currentArticle.createdIso,
      currentId: currentArticle.id
    }

    confirmation = ''

  }

  return imagePropagation({ confirmation, options })
}
