import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { autobind } from 'core-decorators'
import { isNumber } from 'lodash'
import { formatMessage, FormattedMessage } from '../../translations'
import { Dialog } from '../../shared/components/Dialog'
import config from '../../config'
import { deepGet } from '../../shared/obj'
import { getAvailableTableAppearance } from '../../shared/utils'
import { getMousePositionOffsetTo } from '../../shared/utils/events'


const css = /* typeof window === 'undefined' ? {} : */ require('../styles.scss')

const CELL_SIZE = 20

@observer
class TableDialog extends Component {
  static propTypes = {
    modal: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props)
    this.state = {
      type: props.modal.type,
    }

    this.tableAppearances = getAvailableTableAppearance(config)

    this.tableDefaultAppearance
      = deepGet(config, 'editor.buttonOptions.insertTable.subCommands.changeAppearance.default')
      || this.tableAppearances[0].value

    this.tableLayoutModes = [
      {
        value: 'fixed',
        name: 'fixed',
      },
      {
        value: 'responsive',
        name: 'responsive',
      },
    ]

    this.tableDefaultLayoutMode
      = deepGet(config, 'editor.buttonOptions.insertTable.subCommands.changeLayoutMode.default')
      || this.tableLayoutModes[0].value
  }

  isListening = false;

  @autobind
  handleTablePreviewMousedown(e) {
    const target = e.target.closest('.table-preview')
    if (this.isListening) {
      return
    }

    this.isListening = true

    const onMouseMove = (mouseMoveEvent) => {
      this.handleTableViewModified(
        getMousePositionOffsetTo(mouseMoveEvent, target)
      )
    }

    const onMouseUp = (mouseUpEvent) => {
      target.removeEventListener('mousemove', onMouseMove, false)
      document.body.removeEventListener('mouseup', onMouseUp, false)

      this.handleTableViewModified(
        getMousePositionOffsetTo(mouseUpEvent, target)
      )

      this.isListening = false
    }

    target.addEventListener('mousemove', onMouseMove, false)
    document.body.addEventListener('mouseup', onMouseUp, false)

    this.handleTableViewModified(getMousePositionOffsetTo(e, target))
  }

  @autobind
  handleTableViewModified({ x, y }) {
    const size = CELL_SIZE
    const cols = Math.ceil(x / size)
    const rows = Math.ceil(y / size)
    if (isNumber(cols) && isNumber(rows)) {
      this.props.modal.onChange({
        cols,
        rows,
      })
    }
  }

  @autobind
  handleCancelModal(e) {
    e.preventDefault()
    this.props.modal.onCancel()
  }

  @autobind
  handleCommitModal(e) {
    e.preventDefault()
    e.stopPropagation()
    this.props.modal.onCommit()
  }

  @autobind
  handleInputChanged({ target: { type, value, checked, name } }) {
    value
      // eslint-disable-next-line no-nested-ternary
      = type === 'number' ? value * 1 : (type === 'checkbox' ? checked : value)
    this.props.modal.onChange({
      [name]: value,
    })
  }

  @autobind
  handleAfterOpen() {
    if (this.props.modal.onChange) {
      // immediately set the default values
      this.props.modal.onChange({
        layoutMode: this.tableDefaultLayoutMode,
        appearance: this.tableDefaultAppearance,
      })
    }
  }

  render() {
    if (this.props.modal.status !== 'editing') {
      return null
    }

    let { rows, cols } = this.props.modal

    rows = Math.min(Math.max(isNumber(rows) ? rows : 1, 1), 20)
    cols = Math.min(Math.max(isNumber(cols) ? cols : 1, 1), 12)

    const minRows = Math.min(Math.max(rows + 1, 3), 20)
    const minCols = Math.min(Math.max(cols + 1, 4), 12)
    const height = Math.min(Math.max(minRows + 1, 6), 20)

    const appearance
      = this.props.modal.appearance || this.tableDefaultAppearance
    const layoutMode
      = this.props.modal.layoutMode || this.tableDefaultLayoutMode

    return (
      <Dialog
        className={css.tableDialog}
        overlayClassName={css.tableDialogOverlay}
        isOpen={this.props.modal.status === 'editing'}
        onAfterOpen={this.handleAfterOpen}
        style={{
          content: {
            width: `${this.state.type === 'internal' ? 1000 : 480}px`,
          },
        }}
        renderFooter={this.renderFooter}
        title={formatMessage({ id: 'table-dialog.settings' })}
        onCancel={this.handleCancelModal}
        onCommit={this.handleCommitModal}
      >
        <div className="grid-content vertical">
          <div className="grid-block">
            <div className="grid-content small-6">
              <label htmlFor="table-rows">
                <FormattedMessage id="table-dialog.rows" />

                <input
                  type="number"
                  value={this.props.modal.rows}
                  name="rows"
                  onChange={this.handleInputChanged}
                />
              </label>
            </div>

            <div className="grid-content small-6">
              <label htmlFor="table-rows">
                <FormattedMessage id="table-dialog.columns" />

                <input
                  type="number"
                  value={this.props.modal.cols}
                  name="cols"
                  onChange={this.handleInputChanged}
                />
              </label>
            </div>
          </div>

          <div className="grid-content muted">
            <FormattedMessage id="table-dialog.click-drag-hint" />
          </div>

          <div className="grid-content">
            <div
              ref="tablePreview"
              className="table-preview"
              onMouseDown={this.handleTablePreviewMousedown}
              style={{
                height: `${height}em`,
              }}
            >
              <div
                className="table-cells-active"
                style={{
                  width: `${cols}em`,
                  height: `${rows}em`,
                }}
              ></div>
              <div
                className="table-cells-available"
                style={{
                  width: `${minCols}em`,
                  height: `${minRows}em`,
                }}
              ></div>
            </div>
          </div>

          <label htmlFor="table-appearance" className="control-label">
            <FormattedMessage id="table-dialog.appearance" />
          </label>

          {this.tableAppearances.map(({ value, name }) => {
            return (
              <div key={`table-appearance-${value}`}>
                <input
                  type="radio"
                  id={`table-appearance-${value}`}
                  name="appearance"
                  defaultValue={value}
                  defaultChecked={appearance === value}
                  onChange={this.handleInputChanged}
                />
                <label
                  htmlFor={`table-appearance-${value}`}
                  className="control-label"
                >
                  {name}
                </label>
              </div>
            )
          })}

          <label htmlFor="table-layout-mode" className="control-label">
            <FormattedMessage id="table-dialog.layout-mode" />
          </label>

          {this.tableLayoutModes.map(({ value, name }) => {
            return (
              <div key={`table-layout-mode-${value}`}>
                <input
                  type="radio"
                  id={`table-layout-mode-${value}`}
                  name="layout-mode"
                  defaultValue={value}
                  defaultChecked={layoutMode === value}
                  onChange={this.handleInputChanged}
                />
                <label
                  htmlFor={`table-layout-mode-${value}`}
                  className="control-label"
                >
                  <FormattedMessage id={`table-dialog.layout-mode.${name}`} />
                </label>
              </div>
            )
          })}

          <p className="text-danger status"></p>
        </div>
      </Dialog>
    )
  }
}

export default TableDialog
