import Command from './Command';
import * as PropTypes from 'prop-types';
import * as React from 'react';
import { hoistNonReactStatics } from '../../hoistNonReactStatics'

import { DispatcherPropType } from './Provider'
import { ICommand,CommandStore } from './command.d'


export const CommandInjectorContextPropTypes = {
  dispatch: PropTypes.func,
  dispatchCommandInstance: PropTypes.func
}


// Inspired by mobx-react
function isStateless(component) {
  // `function() {}` has prototype, but `() => {}` doesn't
  // `() => {}` via Babel has prototype too.
  return !(component.prototype && component.prototype.render);
}

function mergeCommands(commands, origCommands = {}) {

  return Object.keys(commands)
  .reduce((memo, commandName: string) => {

    if (commandName in memo) {

      if (memo[commandName] !== commands[commandName]) {
        console.warn(
          `The command \`${commandName}\` is already defined on the ` +
          `parent props, but with a different implementation. Overwriting ` +
          `the parent definition!`
        )
      }
      // skip that command as it is already defined with exactly the same class
      else {
        return memo
      }
    }

    memo[commandName] = commands[commandName]

    return memo

  }, {...(origCommands)})

}


function createCommandInjector(commands, component) {

  let displayName = "inject-commands-" +
    (component.displayName || component.name ||
      (component.constructor && component.constructor.name) || "Unknown")

  interface InjectorProps {
    commands?: {
      [key: string]: ICommand
    };
    ref?: any;
    [key: string]: any;
  }

  class Injector
  extends React.Component<InjectorProps, {}> {

    static displayName = displayName;
    static wrappedComponent = undefined;

    static contextTypes = {
      dispatcher: DispatcherPropType
    }

    static childContextTypes = CommandInjectorContextPropTypes

    private commandEventDisposer: Array<Function>

    getChildContext() {
      const self = this
      return {
        dispatch(
          commandName: string,
          data?: any,
          events?: {[key: string]: Function}
        ) {

          if (events) {
            const command = self.context.dispatcher.createCommand(
              commandName,
              data
            )
            self.bindEvents(command, events)
            return self.context.dispatcher.execCommand(command)
          }
          return self.context.dispatcher.exec(commandName, data)
        },
        dispatchCommandInstance(
          command: Command,
          data?: any,
          events?: {[key: string]: Function}
        ) {
          if (data) {
            command.set(data)
          }
          if (events) {
            self.bindEvents(command, events)
          }
          self.context.dispatcher.execCommand(command)
        }
      }
    }

    public bindEvents(
      command: Command,
      events: {[evtName: string]: Function}
    ) {
      if (!this.commandEventDisposer) {
        this.commandEventDisposer = []
      }
      Object.keys(events)
      .forEach(evtName => {
        this.commandEventDisposer.push(
          command.subscribe(evtName, events[evtName])
        )
      })

    }

    wrappedInstance: Injector | null = null

    injectorRef = (instance) => {
      this.wrappedInstance = instance
    }

    componentWillUnmount() {
      // if (this.commandEventDisposer && this.commandEventDisposer.length) {
      //   this.commandEventDisposer.forEach((disposer) => disposer())
      // }
    }

    render() {

      const mergedCommands = mergeCommands(commands, this.props.commands)

      const newProps = {
        ...this.props,
        commands: mergedCommands
      }

      if (!isStateless(component)) {
        newProps.ref = this.injectorRef;
      }

      return React.createElement(component, newProps);
    }
  }

  // Static fields from component should be visible on the generated Injector
  hoistNonReactStatics(Injector, component, []);

  Injector.wrappedComponent = component;

  return Injector;

}


export
function dispatcher (target) {
  target.contextTypes = target.contextTypes || {}
  target.contextTypes.dispatch = CommandInjectorContextPropTypes.dispatch
  target.contextTypes.dispatchCommandInstance = CommandInjectorContextPropTypes.dispatchCommandInstance
}

export default
function(commands: CommandStore) {
  return function (componentClass: any) {
    return createCommandInjector(commands, componentClass)
  }
}
