const LINE_LENGTH = 100
const HR_TOKEN = '='

const createLine = (length, token = '-') => {
  return Array(...Array(length))
    .map(() => token)
    .join('')
}

const createHeadline = (headlineText) => {
  return `${headlineText}\n${createLine(headlineText.length, '-')}`
}

const indent = (text = '', indentation = 0) => {
  const indentationStr = createLine(indentation, ' ')
  return (text || '')
    .split('\n')
    .map(str => `${indentationStr}${str}`)
    .join('\n')
}

const TERMINAL_COLORS = {
  RESET: '\x1b[0m',
  DIM: '\x1b[2m',
  DEFAULT: '\x1b[39m',
  RED: '\x1b[31m',
  BG_DEFAULT: '\x1b[49m',
  BG_RED: '\x1b[41m',
}

const CONSOLE_COLORS = {
  RESET: 'color: black; background: transparent;',
  DIM: 'color: grey; background: transparent;',
  DEFAULT: 'color: black; background: transparent;',
  RED: 'color: red; background: transparent;',
  BG_DEFAULT: 'color: black; background: transparent;',
  BG_RED: 'color: white; background: red;',
}

const colors = {
  terminal: TERMINAL_COLORS,
  console: CONSOLE_COLORS,
}

export default function logRenderingError(
  ex,
  { template = null, env = {} } = {}
) {
  if (env.CM || env.PM) {
    console.error(ex)
    return
  }

  const { DIM, RESET, BG_RED, BG_DEFAULT, RED, DEFAULT } = colors[
    env.CM || env.PM ? 'console' : 'terminal'
  ]

  const hr = createLine(LINE_LENGTH, HR_TOKEN)

  const styleArray = []
  const color = (c) => {
    if (env.CM || env.PM) {
      styleArray.push(c)
      return '%c'
    }
    return c
  }

  let templateInfo = template ? JSON.stringify(template, null, 2) : null

  if (templateInfo) {
    templateInfo = `
${createHeadline('Template:')}
${color(DIM)}${indent(templateInfo, 2)}${color(RESET)}`
  }

  const message = `

${color(BG_RED)}${hr}${color(BG_DEFAULT)}
  ${color(BG_RED)} ERROR ${color(BG_DEFAULT)} while rendering${
  template
    ? ` template ${color(RED)}\`${template.shortcut}\`${color(DEFAULT)}`
    : ''
}: ${ex.message}

${indent(createHeadline('Stack:'), 2)}
${color(DIM)}${indent(ex.stack, 4)}${color(RESET)}
${indent(templateInfo || '', 2)}

${color(BG_RED)}${hr}${color(BG_DEFAULT)}${color(RESET)}
`

  console.log(message, ...styleArray)
}
