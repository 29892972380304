import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import { formatMessage } from '../../../translations'
import * as styles from './styles.scss'

function parsePixelValue(value) {
  const parsed = parseInt(value, 10)
  // eslint-disable-next-line no-restricted-globals
  if (!isNaN(parsed)) {
    value = parsed
  }
  if (typeof value === 'number') {
    return `${value}px`
  }
  return value
}

@observer
export class TransformationInfo extends Component {
  static propTypes = {
    transformationInfo: PropTypes.shape({
      left: PropTypes.number,
      top: PropTypes.number,
      width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  };

  static contextTypes = {
    transformationState: PropTypes.shape({
      isActive: PropTypes.bool,
    }),
  };

  constructor(props, context) {
    super(props, context)
    if (!context || !context.transformationInfo) {
      console.error(
        'There was an error with the `TransformationInfo`. A '
          + '`transfromationInfo` context is expected. To provide that, place '
          + 'your `Positionable.TransformationInfo` or '
          + '`Resizable.TransformationInfo` within a `Positionable` or `Resizable`!'
      )
    }
  }

  renderValueIfExists(name, label) {
    const {
      transformationInfo,
    } = this.props

    return name in transformationInfo ? (
      <span className={`${name}-value`}>
        {formatMessage({ id: `transformation-info.${label}` })}:&nbsp;
        {parsePixelValue(transformationInfo[name])}
      </span>
    ) : null
  }

  renderPosition() {
    const xValue = this.renderValueIfExists('left', 'X')
    const yValue = this.renderValueIfExists('top', 'Y')

    return xValue || yValue ? (
      <span className="transfromation-info-position">
        {xValue}
        {yValue}
      </span>
    ) : null
  }

  renderSize() {
    const widthValue = this.renderValueIfExists('width', 'W')
    const heightValue = this.renderValueIfExists('height', 'H')

    return widthValue || heightValue ? (
      <span className="transfromation-info-size">
        {widthValue}
        {heightValue}
      </span>
    ) : null
  }

  render() {
    const { transformationInfo } = this.props
    const { transformationState } = this.context

    let error = null
    const position = this.renderPosition()
    const size = this.renderSize()

    if (!size && !position) {
      error
        = 'No transition data! Make sure you passed the `transitionInfo`'
        + 'prop to the `TransitionInfo` component!'
    }

    return transformationState.isActive ? (
      <div
        className={classNames(styles.transformationInfo, 'transformation-info')}
      >
        {position}
        {size}
        {error}
      </div>
    ) : null
  }
}

export default TransformationInfo
