
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ReactDOM from 'react-dom'

import { autobind } from 'core-decorators'
import { deepGet, clone } from '../../shared/obj'
import { UserCanceledDialogError } from '../../shared/errors'
import Scribe from 'scribe-editor'
import linkDialog from '../../shared/containers/LinkDialog'
import foreColorDialog from '../../shared/containers/ForeColorDialog'
import fontStyleDialog from '../../shared/containers/FontStyleDialog'
import footnoteDialog from '../../shared/containers/FootnoteDialog'
import spSanitizer from './customized/scribe-plugin-sanitizer'

// eslint-disable-next-line max-len
import spFormatterPlainTextConvertNewLinesToHtml from 'scribe-plugin-formatter-plain-text-convert-new-lines-to-html'
import spIntelligentUnlinkCommand from 'scribe-plugin-intelligent-unlink-command'

import spSmartLists from 'scribe-plugin-smart-lists'
import spHeadingCommand from 'scribe-plugin-heading-command'
import spBlockquoteCommand from 'scribe-plugin-blockquote-command'
// import spNoting from 'scribe-plugin-noting'


// CUSTOM PLUGINS

import spToolbar from './plugins/toolbar'
// eslint-disable-next-line max-len
import spFormatterHTMLRemoveLineBreaks from './plugins/formatter-html-remove-line-breaks'

import spFormatterHTMLTruncate from './plugins/formatter-html-truncate'

import spSelection from './plugins/selection'

import spTableCommand from './commands/table-command'
import spFootnoteCommand from './commands/footnote-command'
import spSubscript from './commands/subscript-command'
import spSuperscript from './commands/superscript-command'
import spLinkProptCommand from './commands/link-prompt-command'
import spRemoveLinebreakCommand from './commands/remove-linebreak-command'
import spAdvancedPasteCommand from './commands/advanced-paste-command'
import spForeColorCommand from './commands/fore-color-command'
import spFontStyleCommand from './commands/font-style-command'

import spTransactionManagerAsync from './plugins/transaction-manager-async'

//

import {
  commandRequiresBlocks,
  commandRequiresNestedBlocks,
  getAllowedTags,
  getDefaultAllowedButtons
} from './config'

import { store as uiStore } from '../../ui'

import {
  transfromToLinkObject,
  transformToLinkAttributes
} from '../../shared/link-helper'

import {
  transfromToForeColorObject,
  transformToForeColorAttributes
} from '../../shared/fore-color-helper'

import {
  transfromToFontStyleObject,
  transformToFontStyleAttributes
} from '../../shared/font-style-helper'

import {
  transfromToFootnoteObject,
  transformToFootnoteAttributes
} from '../../shared/footnote-helper'

//

import './styles.scss'

//

export Toolbar from './components/Toolbar'

export { Scribe as default }

export function getSharedToolbar(toolbarElement) {
  return spToolbar(toolbarElement, {
    shared: true
  })
}

export function connectEditor(Editor, parentProps) {

  class ScribeConnectedEditor extends Component {

    static propTypes = {
      onChange: PropTypes.func,
    }

    constructor(props) {
      super(props)
      this.ctrlDown = false
    }

    getDataAttributeValue(name: string) {
      return Editor.getDataAttribute(this.props, name)
    }

    componentDidMount() {
      this.createEditorInstance()
    }

    componentWillUnmount() {
      if (this.editor) {
        this.editor.destroy()
      }
    }


    updateButtonUi(commandName, state) {

      uiStore.setEditorButtons(commandName, state)

    }

    createEditorInstance() {

      const element = ReactDOM.findDOMNode(this)

      const userDefinedButtonList = Editor.getDataAttribute(
        this.props, 'buttons'
      ).split(',')

      if (userDefinedButtonList.indexOf('insertTable') > -1) {
        userDefinedButtonList.push(
          'insertTable.insertTableColumn',
          'insertTable.insertTableRow',
          'insertTable.removeTableColumn',
          'insertTable.removeTableRow',
          'insertTable.changeAppearance',
          'insertTable.changeTableLayout',
        )
      }

      const useBlocks =
      // be tolerant, "block" or "blocks" will trigger block mode
      !!Editor.getDataAttribute(this.props, 'block') ||
      !!Editor.getDataAttribute(this.props, 'blocks') ||
      !!commandRequiresBlocks(userDefinedButtonList).length

      const useNestedBlocks =
      !!commandRequiresNestedBlocks(userDefinedButtonList).length

      const singleLine = Editor.getDataAttribute(
        this.props, 'singleLine'
      )

      const maxLen = Editor.getDataAttribute(
        this.props, 'maxLen'
      )

      const enforceMaxLen = Editor.getDataAttribute(
        this.props, 'enforceMaxLen'
      )

      const restrictLen = Editor.getDataAttribute(
        this.props, 'restrictLen'
      )

      const completeTagList = getAllowedTags(userDefinedButtonList)

      const markerTagName = /* useBlocks ? 'div' : */'abbr'
      if (maxLen && enforceMaxLen) {

        if (!completeTagList[markerTagName]) {
          completeTagList[markerTagName] = {}
        }

        completeTagList[markerTagName].class = 'max-len-marker'
      }

      const completeButtonList = [
        ...userDefinedButtonList,
        ...Object.keys(getDefaultAllowedButtons())
      ]


      if ((useBlocks || useNestedBlocks) && !completeTagList.p) {
        completeTagList.p = {}
      }

      const scribeOptions = {
        allowBlockElements: useBlocks
      }

      const editor = new Scribe(element, scribeOptions)

      // if (!isProduction) {
      //
      //   const diffString = require('./diff').default
      //
      //   const logFormatter = (type, formatter, pre, post, duration) => {
      //     const name = `${type}: ${formatter.name || 'anonymous formatter'}`
      //
      //     if (pre === post) {
      //       log.editor(`${name}: No changes! (${duration}ms)`)
      //     }
      //     else {
      //       console.group(`${name}: ${pre.length} ⟼ ${post.length} (${duration}ms)`)
      //
      //       let textDiff = diffString(pre, post)
      //
      //       const styles = [defaultStyle]
      //       const defaultStyle = 'background-color: transparent;'
      //       const del = 'background-color: #FFE6E6;'
      //       const ins = 'background-color: #E6FFE6;'
      //       textDiff = textDiff
      //       textDiff = '%c' + textDiff
      //       .replace(/<(del|ins)>([^<]+)<\/(?:del|ins)>/mg, (match, style, text) => {
      //         styles.push(style === 'del' ? del : ins, defaultStyle)
      //         return `\n%c${text}%c`
      //       })
      //       .replace(/&quot;/g, '"')
      //       .replace(/&gt;/g, '>')
      //       .replace(/&lt;/g, '<')
      //
      //       log.editor(textDiff, ...styles);
      //
      //       console.groupCollapsed('Data')
      //       log.editor(pre)
      //       log.editor(post)
      //       console.groupEnd()
      //
      //       console.groupCollapsed('Code')
      //       log.editor(formatter.toString())
      //       console.groupEnd()
      //
      //       console.groupEnd()
      //     }
      //   }
      //
      //   editor._htmlFormatterFactory.__proto__.format = function (html) {
      //     console.group('Formatting start')
      //
      //     let sanitized = (this.formatters.sanitize || [])
      //     .reduce(function (formattedData, formatter) {
      //       const t0 = performance.now()
      //       const retval = formatter(formattedData);
      //       const t1 = performance.now()
      //       logFormatter(
      //         'sanitizer',
      //         formatter,
      //         formattedData,
      //         retval,
      //         parseInt((t1 - t0) * 100, 10) / 100
      //       )
      //       return retval
      //     }, html)
      //
      //     let normalized = (this.formatters.normalize || [])
      //     .reduce(function (formattedData, formatter) {
      //       const t0 = performance.now()
      //       const retval = formatter(formattedData);
      //       const t1 = performance.now()
      //       logFormatter(
      //         'normalizer',
      //         formatter,
      //         formattedData,
      //         retval,
      //         parseInt((t1 - t0) * 100, 10) / 100
      //       )
      //       return retval
      //     }, sanitized)
      //
      //     console.groupEnd()
      //
      //     return normalized;
      //   }
      //
      // }

      editor

      .use(spSelection)

      // Toolbar
      .use((scribe) => parentProps.toolbar(scribe, {
        updateButtonUi: this.updateButtonUi,
        allowedButtons: completeButtonList
      }))

      // Buttons
      .use(spSubscript())
      .use(spSuperscript())
      .use(spBlockquoteCommand())
      .use(spHeadingCommand(1))
      .use(spHeadingCommand(2))
      .use(spHeadingCommand(3))
      .use(spIntelligentUnlinkCommand())
      .use(spAdvancedPasteCommand({
        allowedTags: clone(
          deepGet(
            window.GENEVA_CONFIG,
            'editor.sanitizer.allowedTags',
            null
          )
        )
      }))
      .use(spRemoveLinebreakCommand({
        allowSoftBreaks: false
      }))
      .use(spLinkProptCommand({
        showLinkPrompt: (data) => {
          data = transfromToLinkObject(data)
          data.currentContextType = 'text'
          return linkDialog({
            data
          }).then((result) => {
            if (result.status === 'cancelled') {
              return {}
            }
            return transformToLinkAttributes(result)
          }).catch((ex) => {
            console.error(ex)
            return {}
          })
        }
      }))
      .use(spFontStyleCommand({
        showFontStylePrompt: (data) => {
          data = transfromToFontStyleObject(data)
          return fontStyleDialog({
            data
          }).then((result) => {
            if (result.status === 'cancelled') {
              throw new UserCanceledDialogError('Font Style Prompt')
            }
            return transformToFontStyleAttributes(result)
          }).catch((ex) => {
            console.error(ex);
            return {}
          })
        }
      }))
      .use(spForeColorCommand({
        showForeColorPrompt: (data) => {
          data = transfromToForeColorObject(data)
          return foreColorDialog({
            data
          }).then((result) => {
            if (result.status === 'cancelled') {
              throw new UserCanceledDialogError('Fore Color Prompt')
            }
            return transformToForeColorAttributes(result)
          }).catch((ex) => {
            console.error(ex);
            return {}
          })
        }
      }))
      .use(spFootnoteCommand({
        showFootnotePrompt: (data) => {
          data = transfromToFootnoteObject(data)
          return footnoteDialog({
            data
          }).then((result) => {
            if (result.status === 'cancelled') {
              throw new UserCanceledDialogError('Footnote Prompt')
            }
            return transformToFootnoteAttributes(result)
          }).catch((ex) => {
            console.error(ex);
            return {}
          })
        }
      }))


      // .use(spNoting({
      //   // Todo get user name here
      //   user: 'Example User',
      //   scribeInstanceSelector: parentProps.editorCssSelector,
      //   selectors: [
      //     {
      //       commandName: 'note',
      //       tagName: 'gu-note',
      //       clickAction: 'collapse',
      //       keyCodes: [119, 121, { altKey: 8 }]
      //     },
      //     {
      //       commandName: 'flag',
      //       tagName: 'gu-flag',
      //       clickAction: 'toggle-tag',
      //       toggleTagTo: 'gu-correct',
      //       keyCodes: [117]
      //     },
      //     {
      //       commandName: 'correct',
      //       tagName: 'gu-correct',
      //       clickAction: 'toggle-tag',
      //       toggleTagTo: 'gu-flag',
      //       keyCodes: [118]
      //     }
      //   ]
      // }))

      // Sanitizers
      .use(spSanitizer({
        name: 'defaultSanitizer',
        tags: completeTagList,
        keepNestedBlockElements: useNestedBlocks
      }))
      .use(spFormatterPlainTextConvertNewLinesToHtml())

      // Other
      .use(spTransactionManagerAsync())

      // if only one line of text is allowed in the editor
      if (singleLine) {
        editor.use(spFormatterHTMLRemoveLineBreaks())
      }

      // if only a specific number of characters is allowed and that number should be enforced
      if (maxLen && enforceMaxLen) {
        editor.use(spFormatterHTMLTruncate(maxLen, {
          markerTagName
        }))
      }

      // if block elements are allowed
      if (useBlocks && editor.allowsBlockElements()) {
        editor
        // enable tables
        .use(spTableCommand({
          showTablePrompt: (data) => (
            uiStore.openDialog('table', data)
          )
        }))
        // enable lists
        .use(spSmartLists())
      }

      /*
      .use(spKeyboardShortcuts(this._commandsToKeyboardShortcutsMap()))
      .use(spTableToolbarButtons())
      .use(this._initCopyPasteHelperPromptCommandPlugin())
      */

      this.editor = editor

    }

    focus() {
      if (this.refElem && this.refElem.focus) {
        this.refElem.focus()
      }
    }

    @autobind
    handleOnPaste(e) {
      const maxLen = Editor.getDataAttribute(
        this.props, 'maxLen'
      )

      const restrictLen = Editor.getDataAttribute(
        this.props, 'restrictLen'
      )

      // todo: adjust this to actually change the clipboard data, or save to a different
      // internal system and not use clipboard anywhere
      const pasteContent = e.clipboardData.getData('text/plain')
      if (maxLen && restrictLen) {
        if (this.editor.el.textContent.length + pasteContent.length > maxLen) {
          const toAllow = maxLen - this.editor.el.textContent.length
          const adjustedContent = pasteContent.substring(0, toAllow)

          // todo: setData is not setting data, need alternate
          // e.preventDefault();
          // e.clipboardData.setData('text/plain', 'adjustedContent')
        }
      }
    }

    @autobind
    interceptKeys(e) {
      if (e.ctrlKey || e.metaKey || e.altKey) {
        this.ctrlDown = true
        return true
      }
      else {
        const ctrlDown = this.ctrlDown
        this.ctrlDown = false

        if (ctrlDown) {
          if (e.keyCode === 67 // c
            || e.keyCode === 86 // v
            || e.keyCode === 88 // x
          ) {
           return true
         }
        }
      }

      return false
    }

    @autobind
    handleKeyDown(e) {
      const maxLen = Editor.getDataAttribute(
        this.props, 'maxLen'
      )

      const restrictLen = Editor.getDataAttribute(
        this.props, 'restrictLen'
      )

      if (restrictLen && this.editor.el.textContent.length === maxLen
        && e.keyCode !== 8 // backspace
        && e.keyCode !== 105 // left arrow
        && e.keyCode !== 114 // right arrow
        && e.keyCode !== 107 // up arrow
        && e.keyCode !== 104 // down arrow
        && e.keyCode !== 37 // mac - left arrow
        && e.keyCode !== 39 // mac - right arrow
        && e.keyCode !== 38 // mac - up arrow
        && e.keyCode !== 40 // mac - down arrow
        && !this.interceptKeys(e) // used for copy, cut, paste
      ) {
        e.preventDefault()
      }
    }

    @autobind
    handleChange({ target, isTitleField }) {
      const maxLen = Editor.getDataAttribute(
        this.props, 'maxLen'
      )

      const restrictLen = Editor.getDataAttribute(
        this.props, 'restrictLen'
      )

      if (this.props.onChange) {
        let adjustedTaget = target
        // if restrictedLen, then this handels pasting text in
        if (restrictLen && maxLen && target.valueText.length > maxLen) {
          overflow = true
          adjustedTaget.valueText = target.valueText.substring(0, maxLen)
        }

        this.props.onChange({
          target: {
            ...adjustedTaget,
            // TODO: When getting the data the data HAS to be applied to the element as well to prevent re-renderings
            value: this.getDataAttributeValue('restrictLen')
            ? this.reduceHTML()
            : this.editor.getContent(),
            valueText: this.getDataAttributeValue('restrictLen')
              ? adjustedTaget.valueText
              : this.editor.getTextContent()
          },
          isTitleField
        })


        // was reverted back to parent node, need to reset caret to end
        // this commonly happens with a paste action
        const sel = window.getSelection()
        if (sel.anchorNode === this.editor.el) {

        // move carret to the end
          let { el } = this.editor
          let range
          let selection
          // a check for the range and that the area did not revert back to emtpy
          if (document.createRange && el.lastChild)  //Firefox, Chrome, Opera, Safari, IE 9+
          {
              range = document.createRange() //Create a range (a range is a like the selection but invisible)
              range.selectNodeContents(el.lastChild) //Select the entire contents of the element with the range
              range.collapse(false) //collapse the range to the end point. false means collapse to end rather than the start
              selection = window.getSelection() //get the selection object (allows you to change selection)
              selection.removeAllRanges() //remove any selections already made
              selection.addRange(range) //make the range you have just created the visible selection
          }
        }
      }

    }
    @autobind
    reduceHTML() {
      let html = this.editor.getContent()
      const location = html.indexOf('<abbr class="max-len-marker">')
      if (location > 0) {
        html = html.substring(0, location)
      }
      return html
    }

    shouldComponentUpdate(nextProps) {

      // Check if the html that came from the model is any different than the
      // one in the DOM node. Remove `&nbsp;` from the element for that
      // comparison.
      // TODO This should perhaps be done by a scribe sanitizer
      const cleanHTML = this.editor.getContent()
      const didChange = nextProps.value !== cleanHTML

      return didChange

    }


    render() {
      return (<Editor
        {...this.props}
        {...parentProps}

        ref={(ref) => (this.refElem = ref)}
        onChange={this.handleChange}
        onKeyDown={this.handleKeyDown}
        onPaste={this.handleOnPaste}

      />)
    }

  }

  Editor.Buttons = {
    all: getDefaultAllowedButtons()
  }
  ScribeConnectedEditor.Buttons = Editor.Buttons

  return ScribeConnectedEditor

}
