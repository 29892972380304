import { store as uiStore } from '../../../ui'

export function DefaultInspector() {
  this.before = (target, id) => {
    if (id) {
      this.createStatusInfo('started', id, target)
    }
  }
  this.then = (target, id) => {
    if (id) {
      this.createStatusInfo('completed', id, target, 2000)
    }
  }
  this.catch = (target, id) => {
    if (id) {
      this.createStatusInfo('failed', id, target, 4000)
    }
  }

  this.createStatusInfo = (type, id, target, duration = 'forever') => {
    const item = target.getById ? target.getById(id) : null

    uiStore.addStatusInfo(
      {
        id: target.constructor.name,
        priority: 'high',
        name: item
          ? `${this.messageId}-${type}-name`
          : `${this.messageId}-${type}`,
        value: item ? item.getJSON() : null,
      },
      duration
    )
  }
}
