import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { autobind } from 'core-decorators'
import { FormattedMessage } from '../../../translations'
import GenevaButton from '../../../ui/components/GenevaButton'
import * as css from '../styles.scss'

export default class VersionNoteForm extends Component {

  static propTypes = {
    onCreateNote: PropTypes.func
  }

  constructor(props) {
    super(props)
    this.state = {
      note: ''
    }
  }

  @autobind
  handleNoteChanged({ target }) {
    this.setState({
      note: target.value
    })
  }

  @autobind
  handleCreateNote(e) {
    e.preventDefault()
    if (this.props.onCreateNote) {
      this.props.onCreateNote({
        target: {
          value: this.state.note
        }
      })
    }
  }

  render() {
    return (

      <form
        onSubmit={this.handleCreateNote}
        className={css.noteForm}
      >
        <div className="grid-block">
          <div className="grid-content">
            <label>
              <textarea
                autoFocus
                value={this.state.note}
                onChange={this.handleNoteChanged}
              />
            </label>
          </div>
        </div>
        <div className="grid-block">
          <div className="grid-content">
            <label>
              <GenevaButton
                className="small button float-right"
                disabled={!this.state.note}
                type="submit"
              >
                <FormattedMessage id="article.version.note.add" />
              </GenevaButton>
            </label>
          </div>
        </div>
      </form>

    )
  }

}
