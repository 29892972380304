import * as PropTypes from 'prop-types'
import React from 'react'
import { withRouter } from 'react-router'

import createParentPropsInjector from '../../../../shared/utils/create-parent-props-injector'

import RemoveContentFromWidgetMacroCommand from './commands/RemoveContentFromWidgetMacroCommand.ts'
import RemoveContentFromWidgetCommand from '../../commands/RemoveContentFromWidgetCommand.ts'
import SelectContentInWidgetCommand from '../../commands/SelectContentInWidgetCommand.ts'

import PageConnectedRemoveArticleButton from './components/PageConnectedRemoveArticleButton'

import DistinctModelObserver from '../../../../shared/view-models/DistinctModelObserver.ts'
import viewModelConnector from '../../../../shared/lib/view-model'

import commandConnector from '../../../../shared/lib/command'

export default
function connectRemoveArticleToPage(Target, parentProps: Object) {

  const injectParentProps = createParentPropsInjector(parentProps)

  function RemoveArticleToPageConnector({
    // props that are NOT passed to the wrapped component
    // other props
    ...rest
  }) {

    return (<PageConnectedRemoveArticleButton
      {...rest}
      Target={Target}
    />)

  }

  RemoveArticleToPageConnector.propTypes = {
    router: PropTypes.shape({
      push: PropTypes.func
    }).isRequired,
    disabled: PropTypes.bool,
    page: PropTypes.object,
    onGetContext: PropTypes.func.isRequired,
  }

  return commandConnector({
    RemoveContentFromWidgetMacroCommand,
    RemoveContentFromWidgetCommand,
    SelectContentInWidgetCommand
  })(
    viewModelConnector([{
      class: DistinctModelObserver,
      data: [{
        type: 'widget',
        id: DistinctModelObserver.fromContext('widgetId'),
        as: 'item'
      }],
      as: 'currentlyOpenWidget'
    }])(
      withRouter(injectParentProps(RemoveArticleToPageConnector))
    ))

}
