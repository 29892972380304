import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { confirmable } from 'react-confirm'
import { autobind } from 'core-decorators'
import { observer } from 'mobx-react'
import { formatMessage, FormattedMessage } from '../../../translations'

import { Dialog } from '../../../shared/components/Dialog'
import GenevaButton from '../../../ui/components/GenevaButton'

const css = /* typeof window === 'undefined' ? {} : */require('../../styles.scss')

@confirmable
@observer
class ArticleMandatoryFields extends Component {

  static propTypes = {
    show: PropTypes.bool,            // indicates if the dialog is shown or not.
    proceed: PropTypes.func,         // call to close the dialog with promise resolved.
    cancel: PropTypes.func,          // call to close the dialog with promise rejected.
    dismiss: PropTypes.func,         // call to only close the dialog.
    confirmation: PropTypes.string,  // arguments of your confirm function
    options: PropTypes.object
  }

  /** from a design desire, the 'cancel' and 'proceed' logics are a bit switched */
  @autobind
  renderFooter() {
    return (
      <div className="grid-content text-right">
        <GenevaButton
          type="cancel"
          className="small button"
          onClick={this.props.proceed}
        >
          <FormattedMessage id="article.missing-mandatory-fields.cancel" />
        </GenevaButton>
        <GenevaButton
          type="submit"
          className="small primary button"
          onClick={this.props.dismiss}
        >
          <FormattedMessage id="article.missing-mandatory-fields.confirm" />
        </GenevaButton>
      </div>
    )
  }

  render() {

    const {
      show,
    } = this.props

    return (<Dialog
      className={css.mandatoryFieldsDialog}
      overlayClassName={css.tableDialogOverlay}
      isOpen={show}
      onRequestClose={this.handleRequestClose}
      title={formatMessage({ id:
        'article.missing-mandatory-fields.title'
      })}
      renderFooter={this.renderFooter}
      id="mandatoryFieldsDialog"
    >
      <div>
        <FormattedMessage id="article.missing-mandatory-fields" />
      </div>
    </Dialog>)
  }
}

export default ArticleMandatoryFields
