/**
 * @description
 * This module returns a router tree with all routes defined for this app.
 */


import React from 'react'
import { Route, IndexRoute } from 'react-router'

import { AppDashboard, AppLayout } from '../app'
import { ArticleContainer } from '../article'
import { PublishContainer } from '../publish'
import { PageContainer } from '../page'
import { NotFoundView } from '../not-found'

/**
 * The default routes module
 * @module routes
 */
export default () => (

  <Route path="/" component={AppLayout}>

    <IndexRoute component={AppDashboard} />

    <Route path="article/:articleId" component={ArticleContainer}>
      <Route path=":articleAction">
        <Route path=":articleActionDetails" />
      </Route>
    </Route>

    <Route path="publish" component={PublishContainer}>
      <Route path=":projectId">
        <Route path="page/:pageId" component={PageContainer}>

          <Route path="article/:articleId">
            <Route path=":articleAction">
              <Route path=":articleActionDetails" />
            </Route>
          </Route>

          <Route path="widget/:widgetId">
            <Route path=":widgetAction" />
          </Route>

        </Route>
        <Route path=":subview" />
      </Route>
    </Route>

    <Route path="*" component={NotFoundView} status={404} />

  </Route>

)
