
import * as PropTypes from 'prop-types'

import React, { Component } from 'react'
import { toJS } from 'mobx'
import { observer } from 'mobx-react'
import { autobind } from 'core-decorators'

import onClickOutside from 'react-onclickoutside'

import { eventHandlers } from '../../shared/react-helpers'

import {
  transfromToLinkObject,
  transformToLinkAttributes,
  getDefaultLinkAttributes,
} from '../../shared/link-helper'

import { A_ATTRS, filterElemAttrs } from '../../shared/react-helpers'

export default function connectLinkableToContext(
  Linkable,
  parentProps,
  contextStore
) {
  if (!contextStore) {
    // eslint-disable-next-line max-len
    throw new Error(
      'To connect an Linkable with a context, `connectLinkable` expects to get passed a `contextStore` argument as third parameter.'
    )
  }

  if (!parentProps.articlePlaceholders) {
    // eslint-disable-next-line max-len
    throw new Error(
      "`connectLinkableToContext` requires an articlePlaceholders Object in it's parentProps"
    )
  }

  @observer
  class ConnectedLinkable extends Component {
    static transformToLinkAttributes(props) {
      return transformToLinkAttributes(props)
    }

    static propTypes = {
      pid: PropTypes.string.isRequired,
      value: PropTypes.object,
      children: PropTypes.node,
      onChange: PropTypes.func,
    };


    @autobind
    handleClick(/* e */) {
      if (this.checkIsEditable()) {
        const data
          = parentProps.articlePlaceholders.get(this.props.pid)
          || transfromToLinkObject(getDefaultLinkAttributes())
        // we need a copy here, otherwise we'd work on the actual data and
        // a change would never be detected
        const editableData = {
          ...data,
        }

        editableData.exists = !!(editableData.href || '').trim().length

        contextStore.createFromElement({
          type: contextStore.constructor.CONTEXT_TYPE_LINK,
          value: editableData,
          pid: this.props.pid,
          updatePlaceholder: this.updatePlaceholder.bind(this),
        })
      }

      // in cm stop link execution
      // if (parentProps.env.CM) {
      //   e.preventDefault()
      // }
    }

    @autobind
    handleClickOutside(e) {
      // This checks if the click happend outside of the toolbar.
      // If it did, then reset the sub context (the link tools).
      if (!e.target.closest('.main-toolbar')) {
        contextStore.resetSubContexts()
      }
    }

    updatePlaceholder(value) {
      if (value && value.status) {
        switch (value.status) {
          case 'unlink':
            parentProps.articlePlaceholders.remove(this.props.pid)
            break
          case 'committed':
            parentProps.articlePlaceholders.set(this.props.pid, 'value', {
              type: 'keyValue',
              value,
            })
            break
          default:
          // noop
        }
      }
    }

    checkIsEditable() {
      return (
        parentProps.env.CM && parentProps.articlePlaceholders.article.isEditable
      )
    }

    render() {
      // eslint-disable-next-line no-unused-vars
      const env = toJS(parentProps.env)
      let value
        = this.props.value || parentProps.articlePlaceholders.get(this.props.pid)

      const linkProps = filterElemAttrs(this.props, A_ATTRS)

      if (value) {
        value = transformToLinkAttributes(value)
      }

      // in cm don't render the link, it would cause a redirect
      if (parentProps.env.CM) {
        const TagName = 'span'

        const handlers = {
          ...eventHandlers(this.props),
          onClick: this.handleClick,
        }

        const spanProps = filterElemAttrs(linkProps, ['style', 'className'])

        return (
          <TagName
            {...spanProps}
            {...handlers}
            data-href={value && value.href}
            title={value ? `Links to: ${value.href}` : null}
          >
            {this.props.children}
          </TagName>
        )
      }

      return (
        <Linkable {...linkProps} value={value}>
          {this.props.children}
        </Linkable>
      )
    }
  }

  const ClickOutsideConnectedlinkable = onClickOutside(ConnectedLinkable)

  // eslint-disable-next-line max-len
  ClickOutsideConnectedlinkable.transformToLinkAttributes
    = ConnectedLinkable.transformToLinkAttributes

  return ClickOutsideConnectedlinkable
}
