import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Link } from 'react-router'
import classNames from 'classnames'

import { FormattedMessage } from '../../translations'
import Ion from '../../shared/icons/ion'
import { deepGet } from '../../shared/obj'

const css = /* typeof window === 'undefined' ? {} : */ require('../styles.scss')

export default class SidebarTools extends Component {

  static propTypes = {
    article: PropTypes.object.isRequired,
    template: PropTypes.object.isRequired,
    tools: PropTypes.array.isRequired,
    articleBaseLink: PropTypes.string
  }

  renderToolsList() {

    const tools = this.props.tools

    return (
      <ul className="toolbar-list">
        {
          tools.map((tool) => {
            return (
              <li key={tool.link}>
                <Link to={`${this.props.articleBaseLink}/${tool.link}`}>
                  <Ion name={tool.icon} />&nbsp;
                  <FormattedMessage id={tool.title} />
                </Link>
              </li>
            )
          })
        }
      </ul>
    )
  }


  render() {

    const articleId = deepGet(this.props, 'article.current.id')

    return (<div className={classNames(
      'grid-block',
      css.toolsPanel,
      css.toolsOverview
    )}>
      {articleId
        ? this.renderToolsList()
        : <FormattedMessage id="article.select-article-first" />}
    </div>)

  }

}
