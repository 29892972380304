import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { findDOMNode } from 'react-dom'
import { autorun } from 'mobx'
import { observer } from 'mobx-react'
import { autobind } from 'core-decorators'
import classNames from 'classnames'
import { FormattedMessage } from '../../translations'

import { useProjectFilter } from '../../config'

import InfiniteList from '../../article/components/InfiniteList'
import ListFilter from '../components/ListFilter'
import { articleStore } from '../../article/reducers'
import { deepGet } from '../../shared/obj'
import { connectToFilterable } from '../../shared/api'

const css = require('../styles.scss')

function getHeight(elem) {
  return parseInt(getComputedStyle(elem).height, 10)
}

const LAYOUTS = {
  list: {
    layoutIcon: 'ion-android-apps',
    infiniteElementHeight: 40,
    name: 'list',
  },
  grid: {
    layoutIcon: 'ion-navicon',
    infiniteElementHeight: 70,
    name: 'grid',
  }
}

// TODO: remove infiniteElementHeight and listContainerHeight and connected functionaly, no longer needed in InfiniteList
@observer
export default class ArticleListContainer extends Component {

  static propTypes = {
    article: PropTypes.object.isRequired,
    ui: PropTypes.object.isRequired,
    listContainerHeight: PropTypes.number,
    renderItem: PropTypes.func.isRequired,
    layout: PropTypes.string,
    allowedArticleTemplates: PropTypes.array,
    handleRef: PropTypes.func,
    project: PropTypes.object
  }

  constructor(props) {

    super(props)

    this.state = {
      listContainerHeight: props.listContainerHeight || 400,
      layout: LAYOUTS[props.layout],
    }

    this.ConnectedListFilter = connectToFilterable(
      ListFilter,
      props.article
    )

  }


  componentDidMount() {

    // APPROVED: whenever the dimensions of the window changes, update the
    // height of the list
    autorun(() => this.updateListHeight(this.props.ui.windowDimensions.height))

  }

  componentWillReceiveProps(nextProps) {

    if (nextProps.listContainerHeight !== this.state.listContainerHeight) {

      this.setState({
        listContainerHeight: nextProps.listContainerHeight
      })

    }

  }

  updateListHeight() {

    const container = findDOMNode(this.refs.listContainer)
    const filter = findDOMNode(this.refs.listFilter)
    // const footer = findDOMNode(this.refs.listFooter)

    if (container) {

      this.setState({
        listContainerHeight:
          getHeight(container) - getHeight(filter)
      })
    }
  }


  loadList(opts) {

    if (opts.force || !deepGet(this, 'props.article.collection.length')) {

      if (articleStore.canLoad()) {
        articleStore.load(opts)
      }
    }
  }

  /**
   * uses pagination decorator to update page and reset collection as necessary
   * some weirdness when page=0 and the reset, so manually handling that
   */
  @autobind
  handleLoad(itemLength) {
    if (itemLength >= (articleStore.limit * articleStore.page)) {
      if (articleStore.page === 0) {
        articleStore.page = 1
      }
      else {
        articleStore.nextPage()
      }
    }

  }

  @autobind
  handleLayoutChange() {
    const newLayout = this.state.layout.name === 'list' ? 'grid' : 'list'
    this.setState({
      layout: LAYOUTS[newLayout]
    })
  }

  @autobind
  renderListComponent() {
    const height = this.state.listContainerHeight || 400

    return (<div
      className={classNames(
        'vertical grid-container small-8 article-display',
        this.state.layout.name
      )}
    >
      <hr />
      <InfiniteList
        renderItem={this.props.renderItem}
        itemStore={articleStore}
        loadDelegate={this.handleLoad}
        onUserSelect={this.handleUserSelect}
        itemCollection={articleStore.modifiedCollection}
        layout={this.state.layout.name}
      />
      <footer className={classNames('grid-block', css.filterFooter)}>
        {articleStore.loading
          ? (<FormattedMessage id="list.loading" />)
          : <FormattedMessage id="article.count"
            values={{
              count: articleStore.filterTotal
            }}
          />
        }
      </footer>
    </div>)
  }

  render() {
    const { ConnectedListFilter } = this
    const { article } = this.props

    return (<ConnectedListFilter
      layout={this.state.layout}
      itemStore={article}
      allowedArticleTemplates={this.props.allowedArticleTemplates}
      currentProject={this.props.project}
      showProjectFilter={!!useProjectFilter}
      handleLayoutChange={this.handleLayoutChange}
      renderListComponent={this.renderListComponent}
      handleRef={this.props.handleRef}
    />)
  }
}
