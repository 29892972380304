import { store as authStore } from '../../auth'
import { permissionList } from '../const'

/**
  * Validates the right of the current user for a certain component or action
  * False for undefined permissionName
  * @param {String} permissionName - The name of the permission in question
  * @returns {Boolean} permission - If the user has the permission
*/
export function hasPermission(permissionName) {
  // switch the name to the name delivered by the AM
  permissionName = permissionList[permissionName]
  const amPermissionList = authStore.user.permissions.list
  return !!amPermissionList[permissionName]
}

/**
  * Validates an array of user rights and returning a detailed list of rights in question
  * @param {Array} permissionNames - An Array of permission names of a component
  * @returns {Object} retVal - An object of [permissionName]: true|false
*/
export function hasPermissions(permissionNames) {
  const retVal = {
    length: 0
  }
  permissionNames.forEach((name) => {
    const permission = hasPermission(name)
    if (permission) {
      retVal[name] = permission
      retVal.length += 1
    }
  })
  return retVal
}
