import React from 'react'
import { FormattedMessage } from '../translations'

export const NotFoundView = function notFound() {

  return (<div>
    <FormattedMessage id="error.not-found" />
  </div>)

}
