import * as PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'

// TODO: this should go somewhere else
import * as css from '../../../app/styles.scss'

import { API_VERSION, VERSION } from '../../../config'

const AppLoader = ({ children }) => {
  return (
    <div className="grid-block v-align">
      <div className="grid-content">
        <div className="grid-block align-center">
          <div
            className={classNames('grid-block', 'vertical', css.appDashboard)}
          >
            <div className={css.appProduct}>GENEVA</div>
            <div
              className="grid-block vertical"
              style={{
                marginTop: '8px',
                marginBottom: '10px',
                marginLeft: '0px',
              }}
            >
              <small>Version: {VERSION}</small>
            </div>
            <div>{children}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

AppLoader.propTypes = {
  children: PropTypes.node,
}

export { AppLoader as default }
