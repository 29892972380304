import React from 'react'
import { observer } from 'mobx-react'
import ToolbarButton from './ToolbarButton'
import * as css from './styles.scss'

const FontStyleButton = observer((props) => {
  const { active } = props.buttonSpec

  return (<ToolbarButton
    {...props}
    buttonSpec={{
      ...props.buttonSpec,
      // the button is always someway active, so just set it to false
      active: false
    }}
    className={css.fontStyleButton}
    style={{
      borderBottomColor:
        `${(active && active.value) || 'inherit'}`
    }}
  >F</ToolbarButton>)
})

export { FontStyleButton as default }
