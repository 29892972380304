import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import ReactCSSTransitionGroup from 'react-transition-group/CSSTransitionGroup'
import classNames from 'classnames'
import PerfectScrollbar from 'perfect-scrollbar'
import { FormattedMessage } from '../../translations'

import { getContentClass } from '../../shared/utils'

import PageRenderer from './PageRenderer'
import WrongProjectError from './WrongProjectError'

import connectRedirectDisplayer from '../connectors/connectRedirectDisplayer'
import connectGhostDisplayerToCommands from '../connectors/connectGhostDisplayerToCommands'

const css = /* typeof window === 'undefined' ? {} : */require('../styles.scss')

const ConnectedGhostDisplayer = connectGhostDisplayerToCommands()
const ConnectedRedirectDisplayer = connectRedirectDisplayer()

@observer
export default class PageContentDisplayer extends Component {

  static propTypes = {
    ui: PropTypes.object,
    page: PropTypes.object,
    project: PropTypes.object,
    template: PropTypes.func,
    container: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.ps = null
  }

  setupPerfectScrollBar() {
    setTimeout(() => {
      this.ps = new PerfectScrollbar('#redirect-displayer-container', {
        suppressScrollX: true
      })
    }, 0)
  }

  render() {

    const {
      page,
      page: { hasCurrent, current },
      project,
      template,
      ui
    } = this.props

    if (!hasCurrent || !(
      current.complete
      && current.contentTemplatesLoaded
      && (template || current.needsTemplate))) {
      return null
    }

    log.renderer('%cRendering content for page: %d', 'color: orange;', current.id)

    // if there is no project yet, show loading project info
    if (!project.hasCurrent) {
      return (<div className="grid-block v-align align-center">
        <FormattedMessage id="page.waiting-for-project" />
      </div>)
    }

    if (!project.current.hasPage(current)) {
      return (<div className="grid-block align-center">
        <WrongProjectError
          page={current}
          project={project}
        />
      </div>)
    }

    if (current.isRedirectPage) {
      if (!this.ps) {
        this.setupPerfectScrollBar()
      }

      return (<div
        id="redirect-displayer-container"
        className={classNames(css.redirectDisplayerContainer)}
      >
        <ConnectedRedirectDisplayer
          pageStore={page}
          projectStore={project}
          currentPage={page.current}
        />
      </div>)
    }

    if (current.isGhostPage) {
      return (<div
        id="redirect-displayer-container"
        className={classNames(css.redirectDisplayerContainer)}
      >
        <ConnectedGhostDisplayer
          pageStore={page}
          projectStore={project}
          ui={ui}
        />
      </div>
      )
    }

    return (
      <div
        ref={ref => (this.container = ref)}
        className={classNames(
          css.pagePreview,
          'geneva-pm-template-preview',
          getContentClass()
        )}
      >
        <ReactCSSTransitionGroup
          component="div"
          className={classNames(
            'react-transition-group', 'geneva-pm-template-preview-scroll-container',
          )}
          transitionEnter={false}
          transitionLeave={false}
          transitionName="page"
          transitionAppear
          transitionAppearTimeout={500}
        >
          <PageRenderer
            key={page.current.id}
            method="raw"
            template={template}
            page={page.current}
            pageStore={page}
            ui={ui}
          />
        </ReactCSSTransitionGroup>
      </div>)
  }

}
