import * as PropTypes from 'prop-types'
import { Component } from 'react'
import { observable, action, autorun } from 'mobx'
import { observer } from 'mobx-react'
import { autobind } from 'core-decorators'
import classNames from 'classnames'

@observer
export default class Item extends Component {
  static propTypes = {
    pid: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    index: PropTypes.number,
    isSorting: PropTypes.bool,
    parent: PropTypes.object,
    onSelect: PropTypes.func,
    renderChild: PropTypes.func,

    tools: PropTypes.object,
    toolsProps: PropTypes.object,
  };

  static defaultProps = {
    tools: null,
    toolsProps: null,
  };

  /* eslint-disable react/sort-comp */
  @observable isSelected = false;
  /* eslint-enable react/sort-comp */

  @autobind
  handleSelect() {
    if (this.props.onSelect) {
      this.props.onSelect(this.props.index)
    }
  }

  constructor(props) {
    super(props)

    // This is some necessary evil/dirt:
    // It ensures only the actually (un)selected items are rerendered instead
    // of all items. Thus in the worst case two items will be rerendered.
    // Having this autorun and selection state management in the child
    // instead of in the parent makes it easier to remove the autorun upon
    // unmount again
    this.releaseAutorun = autorun(
      'RepeatableItem: automatically rerender if selection state changed',
      () => {
        this.setSelected({
          // use *this.*props.index here as the index may have changed after
          // resorting
          selected:
            props.parent.internalState.selectedIndex === this.props.index,
        })
      }
    )
  }

  componentWillUnmount() {
    this.releaseAutorun()
  }

  @action
  setSelected({ selected }) {
    this.isSelected = selected
  }

  render() {
    const { pid, index, renderChild, tools, toolsProps } = this.props

    const key = `${this.props.pid}:${index}`

    const sharedProps = {
      repeatableClassName: classNames('repeatable-item-container', {
        selected: this.isSelected,
      }),
      onClick: this.handleSelect,
      tools,
      toolsProps,
    }

    // if (isSorting) {
    //   sharedProps.sortableClassName = classNames({
    //     [sharedProps.repeatableClassName]: !!sharedProps.repeatableClassName,
    //     'ui-sortable-item': true
    //   })
    //
    //   return (<SortableItem
    //     {...sharedProps}
    //     key={key}
    //     sortData={index}
    //   >
    //     {renderChild(pid, index, {})}
    //   </SortableItem>)
    // }

    sharedProps.key = key

    return renderChild(pid, index, sharedProps)
  }
}
