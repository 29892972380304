import React from 'react'
import { store as uiStore } from '../../../ui'

function updateStatus(info: Object, ttl: string|number = 'forever') {
  uiStore.addStatusInfo(info, ttl)
}

export default function connectStatusToComponent(OrigCompont) {

  function StatusConnectedComponent(props) {
    return (<OrigCompont
      {...props}
      onUpdateStatus={updateStatus}
    />)
  }

  return StatusConnectedComponent

}
