import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { autorun } from 'mobx'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import { autobind } from 'core-decorators'
import PerfectScrollbar from 'perfect-scrollbar'
import { FormattedMessage } from '../../translations'

import { useReviewCenter } from '../../config'
import { store as contextStore } from '../../context'
import { hasPermission } from '../../shared/utils/user-rights'
import GenevaButton from '../../ui/components/GenevaButton'
import PageInfo from './PageInfo'
import connectArticleSort from '../connectors/connectArticleSort'
import connectReviewCenter from '../../review/connectors/connectReviewCenter'
import { articleStore } from '../../article/reducers'
import { widgetStore } from '../../widget/reducers'
import { internalLink } from '../containers/dialogs'

const ConnectedArticleSort = connectArticleSort()
const ConnectedReviewCenter = connectReviewCenter()

const css = /* typeof window === 'undefined' ? {} : */require('../styles.scss')

const contentStores = {
  article: articleStore,
  widget: widgetStore
}

@observer
export default class ToolsPanel extends Component {

  static propTypes = {
    pageStore: PropTypes.object.isRequired,
    channel: PropTypes.string.isRequired,
    router: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      context: null,
      activeTabs: ['pageAccordion', 'selectedAccordion'],
      activeView: 'pageInfo',
      page: props.pageStore.current
    }

    this.permission = hasPermission('editArticle')
    this.rcPermission = hasPermission('rcAccess')
    this.psInfo = null
  }

  componentDidMount() {
    this.handler = autorun(() => this.updateContext(contextStore.target)
    )
    // this prevents clicks in sidebar from pulling focus from the page
    const sidebars = document.body.getElementsByClassName('sidebar')
    Array.prototype.forEach.call(sidebars, (el) => {
      el.addEventListener('mousedown', this.handleSingleClick, false)
    })
  }

  componentWillUnmount() {
    if (this.handler) {
      this.handler()
      this.handler = null
    }
    if (this.unpublishHandler) {
      this.unpublishHandler()
      this.unpublishHandler = null
    }
    document.body.removeEventListener('mousedown', this.handleSingleClick, false)
  }

  updateContext(contextTarget) {
    const activeTabs = this.state.activeTabs

    // Add ArticleSort items to the active tab list
    // Need for saving the Accordion state when switching views
    if (contextTarget) {
      const itemKey = `gr${contextTarget.gr}gb${contextTarget.gb}`
      if (activeTabs.indexOf(itemKey) === -1) {
        activeTabs.push(itemKey)
      }
    }
    this.setState({
      context: contextTarget,
      activeTabs
    })
  }

  @autobind
  handleSingleClick(e) {
    const path = e.path || (e.composedPath && e.composedPath()) || {}

    if (this.state.activeView === 'pageInfo') {
      // without preventDefault a click would reset the context and deselect an article
      e.contentRendeerItemContextHandled = true
      e.preventDefault()
    }
    else if (this.state.activeView === 'articleSort' && path.length > 3) {
      // try to find the node that is allowed to be dragged to
      // allow click events but prevent deselection of article
      // if not inside 4 then it won't be there
      for (let i = 0; i < 4; i++) {
        if (path[i].id === 'articleSort') {
          contextStore.preventBlur = true

          // Select the item on the page and scroll into view if needed
          const data = path[i].dataset

          if (data.type) {
            contextStore.createFromElement({
              gr: data.gr,
              gb: data.gb,
              index: data.index * 1,
              type: data.type,
              id: data.articleid * 1,
              [data.type]: contentStores[data.type].getById(data.articleid)
            })
          }
        }
      }
      // prevent event default when clicked on e.g. Accordion
      if (!contextStore.preventBlur) {
        e.contentRendeerItemContextHandled = true
        e.preventDefault()
      }
    }
    else if (this.state.activeView === 'reviewCenter') {
      // no prevent is wanted for this Blade
    }
  }

  @autobind
  handleTabChange(newValue) {
    const index = this.state.activeTabs.indexOf(newValue)
    const tabState = this.state.activeTabs
    if (index === -1) {
      // add to state
      tabState.splice(0, 0, newValue)
    }
    else {
      // remove from state
      tabState.splice(index, 1)
    }
    this.setState({
      activeTabs: tabState
    })
  }

  @autobind
  handleViewChange({ target }) {
    let activeView = target.id

    // the span inside the button was clicked
    if (!activeView) {
      activeView = target.parentElement.id
    }

    this.setState({ activeView })
  }

  @autobind
  handleInternalLinkDialog() {
    internalLink({
      headerTextId: 'page-internal-link-dialog.header-text',
      closeButtonId: 'simple-dialog.close',
      pageId: this.props.pageStore.current.id,
      router: this.props.router
    })
  }

  renderToolsList(page) {
    if (this.state.activeView === 'pageInfo') {

      // fancy scrollbar logic needed since PageInfo is an exported function
      if (document.getElementById('psInfo-block')) {
        if (!document.getElementById('psInfo-block').classList.contains('ps')) {
          this.psInfo = new PerfectScrollbar('#psInfo-block')
        }
      }
      return (
        <div className="info-container" id="psInfo-block">
          <PageInfo
            className="content"
            psInfo={this.psInfo}

            channel={this.props.channel}

            activeTabs={this.state.activeTabs}
            context={this.state.context}

            description={page.description}
            keyword={page.keywords}
            lastPublishedAt={page.lastPublishedAt}
            name={page.name}
            page={page}
            publishAt={page.publishAt}
            publishedUrl={page.publishedUrl}
            releasePublicationLockAt={page.releasePublicationLockAt}
            title={page.title}
            unPublishAt={page.unPublishAt}

            handleInternalLinkDialog={this.handleInternalLinkDialog}
            handleTabChange={this.handleTabChange}
          />
        </div>
      )
    }
    if (this.state.activeView === 'articleSort') {
      return (
        <ConnectedArticleSort
          context={this.state.context}
          page={page}
          activeTabs={this.state.activeTabs}
          handleTabChange={this.handleTabChange}
        />
      )
    }
    if (this.state.activeView === 'reviewCenter') {
      return (
        <ConnectedReviewCenter
          context={this.state.context}
          page={page}
          activeTabs={this.state.activeTabs}
          handleTabChange={this.handleTabChange}
        />
      )
    }
    return null
  }

  renderViewSelection() {
    // page exceptions
    let standardPage = true
    if (this.state.page.isRedirectPage) {
      standardPage = false
    }

    return (
      <div className={css.selectionButton}>
        <GenevaButton
          className={classNames(
            (this.state.activeView === 'pageInfo' ? 'active' : null),
            'left-side',
            standardPage ? '' : 'right-side'
          )}
          id="pageInfo"
          onClick={this.handleViewChange}
        >
          <FormattedMessage id="page-info.info-button" />
        </GenevaButton>

        {standardPage
          ? <GenevaButton
            className={classNames(
              (this.state.activeView === 'articleSort' ? 'active' : null),
              (useReviewCenter && this.rcPermission) ? '' : 'right-side'
            )}
            id="articleSort"
            onClick={this.handleViewChange}
          >
            <FormattedMessage id="page-info.sort-button" />
          </GenevaButton>
          : null}

        {useReviewCenter && standardPage && this.rcPermission
          ? <GenevaButton
            className={classNames(
              (this.state.activeView === 'reviewCenter' ? 'active' : null),
              'right-side'
            )}
            id="reviewCenter"
            onClick={this.handleViewChange}
          >
            <FormattedMessage id="page-info.rc-button" />
          </GenevaButton>
          : null}

      </div>
    )
  }

  render() {

    const { pageStore } = this.props

    return (<div
      className="grid-block vertical medium extending right overlay sidebar noscroll"
    >
      {this.permission
        ? this.renderViewSelection()
        : null}

      {pageStore.hasCurrent
        ? this.renderToolsList(pageStore.current)
        : <FormattedMessage id="page.select-page-first" />}
    </div>
    )

  }

}
