import React, { Component } from 'react'
import * as PropTypes from 'prop-types'

import classNames from 'classnames'

/* eslint-disable */
/**
 * Generates one or more checkbox elements.
 * @param {Object} itemList - structure:
 * {
 *   key: {
 *     active: {Boolean} - checked or not
 *     name: {String} - String to display next to the checkbox
 *   }
 * }
 * @param {Function} onChange - Callback function onClick
 * @param {String} displayKey - A unique name of the checkbox group - can be used to identify the group
 * @param {String} className - Apply custom classname for styling to the <ul> component
 * @param {Boolean} sortDescending - Flag to toggle the default ascending sort
 */
/* eslint-enable */

export default class MultiCheckbox extends Component {
  static propTypes = {
    itemList: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    displayKey: PropTypes.string,
    className: PropTypes.string,
    sortDescending: PropTypes.bool,
  };

  sortAscending(itemList) {
    return Object.keys(itemList).sort((a, b) => {
      const valueA = itemList[a].name
      const valueB = itemList[b].name

      // if number
      if (Number.isFinite(valueA)) {
        return valueA - valueB
      }
      return valueA.localeCompare(valueB)
    })
  }

  sortDescending(itemList) {
    return Object.keys(itemList).sort((a, b) => {
      const valueA = itemList[a].name
      const valueB = itemList[b].name

      // if number
      if (Number.isFinite(valueA)) {
        return valueB - valueA
      }
      return valueB.localeCompare(valueA)
    })
  }

  render() {
    const {
      className,
      onChange,
      displayKey,
      itemList,
      sortDescending,
    } = this.props
    const sortedKeyList = sortDescending
      ? this.sortDescending(itemList)
      : this.sortAscending(itemList)

    return (
      <ul className={classNames(className, 'block-list status')}>
        {sortedKeyList.map((id) => {
          const name = this.props.itemList[id].name
          return (
            <li key={id}>
              <input
                type="checkbox"
                id={`${displayKey}-${id}`}
                data-id={id}
                name={displayKey}
                checked={itemList[id].active}
                onClick={onChange}
              />
              <label htmlFor={`${displayKey}-${id}`}>{name}</label>
            </li>
          )
        })}
      </ul>
    )
  }
}
