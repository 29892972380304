import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { confirmable } from 'react-confirm'
import { autobind } from 'core-decorators'
import classNames from 'classnames'
import { FormattedMessage } from '../../../translations'

import { Dialog } from '../../../shared/components/Dialog'
import GenevaButton from '../../../ui/components/GenevaButton'

const css = /* typeof window === 'undefined' ? {} : */require('../../styles.scss')

@confirmable
class About extends Component {

  static propTypes = {
    show: PropTypes.bool,            // indicates if the dialog is shown or not.
    proceed: PropTypes.func,         // call to close the dialog with promise resolved.
    cancel: PropTypes.func,          // call to close the dialog with promise rejected.
    dismiss: PropTypes.func,         // call to only close the dialog.
    confirmation: PropTypes.string,  // arguments of your confirm function
    options: PropTypes.object        // arguments of your confirm function
  }

  @autobind
  getFocus() {
    return this.focus
  }

  @autobind
  handleRef(el) {
    if (el !== null) {
      this.focus = el
    }
  }

  @autobind
  handleClick(e) {
    if (e && e.preventDefault) {
      e.preventDefault()
    }
    this.props.dismiss()
  }

  @autobind
  renderFooter() {
    return (
      <FormattedMessage id="copyright" />
    )
  }

  render() {

    const {
      show,
      options
    } = this.props
    // future: make this more dynamic so it can be more easily used in other apps
    const displayData = options.apps[options.currentApp]
      || { color: '5c2d24', short: 'wm', name: 'Website Manager' }
    // <FormattedMessage id="dev.environment_WM" />
    return (<Dialog
      className={css.aboutDialog}
      overlayClassName={css.tableDialogOverlay}
      isOpen={show}
      renderFooter={this.renderFooter}
      renderHeader={this.renderHeader}
      title="Title"
      focusEl={this.getFocus}
    >
      <div className="about-dialog-main grid-block">
        <img className="logo" role="presentation" src="/images/atr-geneva-red.svg" />
        {displayData.name}
        <div className="version-wrapper">
          <label>
            <FormattedMessage id="about-version" />&nbsp;
            {
              this.props.options.VERSION
            }
          </label>
          <label>
            <FormattedMessage id="about-api-version" />&nbsp;
            {
              this.props.options.API_VERSION
            }
          </label>
        </div>
        <GenevaButton
          type="button"
          className="small primary button"
          onClick={this.handleClick}
          ref={this.handleRef}
        >
          <FormattedMessage id="about-close" />
        </GenevaButton>
      </div>
    </Dialog>)
  }
}

export default About
