import React, { Component } from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { autobind } from 'core-decorators'
import { debounce } from 'lodash'
import { Accordion, AccordionItem } from 'react-sanfona'
import DateTime from 'react-datetime'
import PerfectScrollbar from 'perfect-scrollbar'
import { formatMessage, FormattedMessage } from '../../translations'

import { testClass } from '../../shared/utils'
import Icon from '../../shared/icons'
import RadioGroup from '../../shared/components/RadioGroup'
import MultiCheckbox from '../../shared/components/MultiCheckbox'
import { deepGet, clone } from '../../shared/obj'
import { convertDateToSystemLocal } from '../../shared/DateConverter'
import { store as uiStore } from '../../ui'

import GenevaButton from '../../ui/components/GenevaButton'

const css = require('../styles.scss')

@observer
export default class ListFilter extends Component {

  static propTypes = {
    layout: PropTypes.object,
    itemStore: PropTypes.object,
    onChange: PropTypes.func,       // filterable connector list.js
    allowedArticleTemplates: PropTypes.array,
    currentProject: PropTypes.object,
    showProjectFilter: PropTypes.bool,
    handleLayoutChange: PropTypes.func,
    handleRef: PropTypes.func,      // Focus for dialog elements
  }

  constructor(props) {
    super(props)

    this.buildFilterSets()

    // Use localStorage if dialog was opened before
    if (uiStore.localStorage.listFilter) {
      this.setCachedStates()
    }
    else {
      this.state = {
        ...this.getDefaultState()
      }
      this.filterState = this.getDefaultFilter()
      this.sortingState = this.getDefaultSorting()
    }

    this.pslanguageGroup = null
    this.psprojectGroup = null
    this.psuserGroup = null
    this.pstemplateGroup = null

    this.debouncedUpdateFilter = debounce(this.updateFilter, 500)
  }

  componentDidMount() {
    const projectStore = this.props.currentProject.store

    this.debouncedUpdateFilter()

    if (!this.languageList.length) {
      this.languageList = [this.props.currentProject.language]
      projectStore.loadLanguageCollection()
        .then(() => {
          this.languageList = projectStore.languageCollection.slice()
            // sort entries ascending
            .sort((a, b) => a.iso.localeCompare(b.iso))

          this.setState({
            isLoading: false  // only used for re-rendering
          })
        })
    }
  }

  // save filter and state settings to localStorage for later use
  componentWillUnmount() {
    uiStore.localStorage.listFilter = {
      state: this.state,
      filterState: this.filterState,
      sortingState: this.sortingState
    }
  }

  buildFilterSets() {
    const projectStore = this.props.currentProject.store

    // "static" list of filter entries
    // language filter
    this.languageList = projectStore.languageCollection.slice()
      // sort entries ascending
      .sort((a, b) => a.iso.localeCompare(b.iso))

    // project filter
    this.projectSet = projectStore.getProjectsByChannel(this.props.currentProject.channelId)
    // Add filter option "all" at the top of the list
    if (this.projectSet.length > 1) {
      this.projectSet.splice(0, 0, {
        id: -1,
        displayName: formatMessage({ id: 'filter.all-projects' })
      })
    }

    // template filter
    this.templateSet = {}
    this.props.allowedArticleTemplates.forEach((shortcut) => {
      const template = global.GENEVA_CONFIG.templates.find(templ => templ.shortcut === shortcut)
      if (!template) {
        return
      }
      this.templateSet[template.id] = {
        active: false,
        name: template.name
      }
    })
  }

  getDefaultSorting() {
    return [{ createdAt: 'desc' }]
  }

  getDefaultFilter() {
    const { currentProject, showProjectFilter } = this.props
    const defaultFilterProps = [
      { createdChannel: currentProject.channel.id },
      { 'i18n.name': '' },
      { createdIso: currentProject.language },
      { createdBy: [] },
      { templateId: Object.keys(this.state.templateId) },
      { createdAt: {} },
      { createdAt: {} }
    ]

    if (showProjectFilter) {
      defaultFilterProps.push({ projectId: currentProject.id })
    }

    return defaultFilterProps
  }

  getDefaultState() {
    const { currentProject, showProjectFilter } = this.props
    const defaultStateProps = {
      name: '',
      selectedLanguage: currentProject.language,
      sorting: {
        name: 'newest',
        createdAt: 'desc',
      },
      createdBy: {},
      templateId: clone(this.templateSet),
      startDate: '',
      endDate: ''
    }

    if (showProjectFilter) {
      defaultStateProps.selectedProject = currentProject.name
    }

    return defaultStateProps
  }

  setCachedStates() {

    // eslint-disable-next-line react/no-direct-mutation-state
    this.state = clone(uiStore.localStorage.listFilter.state)
    this.filterState = clone(uiStore.localStorage.listFilter.filterState)
    this.sortingState = uiStore.localStorage.listFilter.sortingState

    // Activate previously active templateIds when allowed in this templateSet
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.templateId = clone(this.templateSet)
    const oldTemplateState = deepGet(uiStore, 'localStorage.listFilter.state.templateId')
    if (oldTemplateState) {
      Object.keys(oldTemplateState).forEach((id) => {
        const cachedTemplate = oldTemplateState[id]
        if (!!(cachedTemplate && cachedTemplate.active) && this.state.templateId[id]) {
          // eslint-disable-next-line react/no-direct-mutation-state
          this.state.templateId[id].active = true
        }
      })
    }

    // adjust filterState templateid when cached values not allowed
    const newFilterTemplateId = []
    Object.keys(this.state.templateId).forEach((id) => {
      if (this.state.templateId[id].active) {
        newFilterTemplateId.push(id)
      }
    })
    this.filterState[4].templateId = newFilterTemplateId.length
      ? newFilterTemplateId
      : Object.keys(this.state.templateId)

  }

  updateFilter() {
    this.props.onChange({
      filter: this.filterState,
      sorting: this.sortingState
    })
      .then((res) => {
        const newUserState = {}

        res = deepGet(res, 'meta.filter.createdBy')
        if (!res) {
        // bail out if no filter adjustments needed
          return
        }

        res.forEach((userId) => {
          newUserState[userId] = {
            name: global.GENEVA_CONFIG.users[userId],
            active: this.state.createdBy[userId] && this.state.createdBy[userId].active
          }
        })
        this.setState({ createdBy: newUserState })
      })
  }

  @autobind
  handleReset() {
    this.filterState = this.getDefaultFilter()
    this.sortingState = this.getDefaultSorting()
    this.setState({
      ...this.getDefaultState()
    }, this.debouncedUpdateFilter())
  }

  @autobind
  handleLayoutChange() {
    if (this.props.handleLayoutChange) {
      this.props.handleLayoutChange()
    }
  }

  @autobind
  handleSortingChange({ target }) {
    let objKey = 'createdAt'
    let sortDirection = 'desc'
    switch (target.value) {
      case 'newest':
        // already default
        break
      case 'oldest':
        sortDirection = 'asc'
        break
      case 'alpha':
        objKey = 'i18n.name'
        sortDirection = 'asc'
        break
      case 'alphareverse':
        objKey = 'i18n.name'
        break
      default:
        break
    }

    this.sortingState = [{
      [objKey]: sortDirection
    }]

    this.setState({
      sorting: {
        name: target.value,
        [objKey]: sortDirection
      }
    }, this.debouncedUpdateFilter())
  }

  @autobind
  handleNameChange({ target }) {
    this.filterState[1]['i18n.name'] = target.value
    this.setState({
      name: target.value
    }, this.debouncedUpdateFilter())
  }

  @autobind
  handleLanguageChange({ target }) {
    this.filterState[2].createdIso = target.value
    this.setState({
      selectedLanguage: target.value
    }, this.debouncedUpdateFilter())
  }

  @autobind
  handleProjectChange({ target }) {
    let projectId = this.projectSet.find(proj => proj.displayName === target.value).id

    // option "all" was selected
    if (projectId === -1) {
      projectId = this.projectSet
        .map(proj => proj.id)
        .filter(id => id !== -1)
    }

    this.filterState[7].projectId = projectId

    this.setState({
      selectedProject: target.value
    }, this.debouncedUpdateFilter())
  }

  @autobind
  handleUserChange({ target }) {
    const createdBy = this.state.createdBy
    const newUserFilter = []

    Object.keys(createdBy).forEach((userId) => {
      if (userId === target.dataset.id) {
        createdBy[userId].active = target.checked
      }
      if (createdBy[userId].active) {
        newUserFilter.push(userId)
      }
    })

    this.filterState[3].createdBy = newUserFilter
    this.setState({
      createdBy
    }, this.debouncedUpdateFilter())
  }

  @autobind
  handleTemplateIdChange({ target }) {
    const templateId = this.state.templateId
    let newTemplateIdFilter = []

    Object.keys(templateId).forEach((id) => {
      if (id === target.dataset.id) {
        templateId[id].active = target.checked
      }
      if (templateId[id].active) {
        newTemplateIdFilter.push(id)
      }
    })

    // there must always be a templateId sent
    if (newTemplateIdFilter.length < 1) {
      newTemplateIdFilter = Object.keys(this.state.templateId)
    }

    this.filterState[4].templateId = newTemplateIdFilter
    this.setState({
      templateId
    }, this.debouncedUpdateFilter())
  }

  @autobind
  handleStartDateChange(date) {
    if (!date) {
      // Set empty when date got deleted
      return this.handleDateChange('', 'startDate')
    }

    const newDate = convertDateToSystemLocal(date).format('YYYY-MM-DD')
    this.filterState[5].createdAt = {
      condition: '>',
      value: newDate
    }

    return this.handleDateChange(newDate, 'startDate')
  }

  @autobind
  handleEndDateChange(date) {
    if (!date) {
      // Set empty when date got deleted
      return this.handleDateChange('', 'endDate')
    }

    const newDate = convertDateToSystemLocal(date).format('YYYY-MM-DD')
    this.filterState[6].createdAt = {
      condition: '<',
      value: newDate
    }

    return this.handleDateChange(newDate, 'endDate')
  }

  // DateTime component gives only a Moment object
  // therefore we add the source ourself
  @autobind
  handleDateChange(date, type) {
    this.setState({
      [type]: date
    }, this.debouncedUpdateFilter())
  }

  @autobind
  setScrollBar(name) {
    const psName = `ps${name}`
    if (!this[psName]) {
      this.psName = new PerfectScrollbar(`#${name}-container`, {
        suppressScrollX: true
      })
    }
  }

  // Render AccordionItem title with selection shown
  renderSimpleTitle(displayTitle, selectedItem) {
    return (<div className="react-sanfona-item-title">
      {formatMessage({ id: displayTitle })}
      {selectedItem
        ? <span className="muted">
          {`(${selectedItem})`}
        </span>
        : null}
    </div>
    )
  }

  renderCheckboxGroupTitle(displayTitle, array) {
    let selectedItem = ''
    if (array.length === 1) {
      selectedItem = array[0]
    }
    else if (array.length > 1) {
      selectedItem = array.length
    }
    return this.renderSimpleTitle(displayTitle, selectedItem)
  }

  renderDateTitle(displayTitle) {
    const dateString =    this.state.startDate || this.state.endDate
      ? ` ${this.state.startDate} >> ${this.state.endDate} `
      : null
    return this.renderSimpleTitle(displayTitle, dateString)
  }

  renderFilterHeader() {
    const {
      layout
    } = this.props

    return (<div className="grid-block small-8 header-block v-align sort">
      <label id="textSearch" htmlFor="searchInput">
        <FormattedMessage id="filter.search" />
        <input
          type="search"
          id="searchInput"
          placeholder={formatMessage({
            id: 'select-article-dialog.search-articles'
          })}
          name="name"
          className="form-control input-sm"
          value={this.state.name}
          onChange={this.handleNameChange}
          ref={this.props.handleRef}
        />
      </label>
      <GenevaButton
        id="viewChange"
        className={`small button view-change ${layout.name}`}
        type="button"
        onClick={this.handleLayoutChange}
      >
        <Icon name={layout.layoutIcon} />
      </GenevaButton>

      <label id="selectSort" htmlFor="sortInput">
        <FormattedMessage id="sort.sort" />
        <select
          className="form-control input-sm"
          id="sortInput"
          name="sorting"
          onChange={this.handleSortingChange}
          value={this.state.sorting.name}
        >
          <option value="newest">
            {formatMessage({ id: 'sort.newest' })}
          </option>
          <option value="oldest">
            {formatMessage({ id: 'sort.oldest' })}
          </option>
          <option value="alpha">
            {formatMessage({ id: 'sort.alpha' })}
          </option>
          <option value="alphareverse">
            {formatMessage({ id: 'sort.alphareverse' })}
          </option>
        </select>
      </label>
    </div>)
  }

  renderAccordions() {
    if (this.pslanguageGroup) {
      this.pslanguageGroup.update()
    }
    if (this.psprojectGroup) {
      this.psprojectGroup.update()
    }
    if (this.psuserGroup) {
      this.psuserGroup.update()
    }
    if (this.pstemplateGroup) {
      this.pstemplateGroup.update()
    }

    return (<Accordion>
      <hr />
      <AccordionItem
        title={this.renderSimpleTitle('filter.language.title', this.state.selectedLanguage)}
        onExpand={() => this.setScrollBar('languageGroup')}
        key="language"
      >
        <div id="languageGroup-container">
          <RadioGroup
            itemList={this.languageList}
            handleOnRadioClick={this.handleLanguageChange}
            activeValue={this.state.selectedLanguage}
            displayKey="iso"
            buttonGroupName="selectedLanguage"
          />
        </div>
      </AccordionItem>
      <hr />

      {this.props.showProjectFilter
        ? <AccordionItem
          title={this.renderSimpleTitle('filter.project.title', this.state.selectedProject)}
          onExpand={() => this.setScrollBar('projectGroup')}
          key="project"
        >
          <div id="projectGroup-container">
            <RadioGroup
              itemList={this.projectSet}
              handleOnRadioClick={this.handleProjectChange}
              activeValue={this.state.selectedProject}
              displayKey="displayName"
              buttonGroupName="selectedProject"
            />
          </div>
        </AccordionItem>
        : null}
      {this.props.showProjectFilter
        ? <hr />
        : null}

      <AccordionItem
        title={this.renderCheckboxGroupTitle(
          'filter.user.title',
          this.filterState[3].createdBy.map((userId) => {
            return global.GENEVA_CONFIG.users[userId]
          })
        )}
        onExpand={() => this.setScrollBar('userGroup')}
        key="user"
      >
        <div id="userGroup-container">
          <MultiCheckbox
            itemList={this.state.createdBy}
            onChange={this.handleUserChange}
            displayKey="createdBy"
            classname="multiselect"
          />
        </div>
      </AccordionItem>

      <hr />
      <AccordionItem
        title={this.renderCheckboxGroupTitle(
          'filter.template.title',
          Object.keys(this.state.templateId).map((id) => {
            return this.state.templateId[id].active ? this.templateSet[id].name : undefined
          }).filter(n => n)
        )}
        onExpand={() => this.setScrollBar('templateGroup')}
        key="templates"
      >
        <div id="templateGroup-container">
          <MultiCheckbox
            itemList={this.state.templateId}
            onChange={this.handleTemplateIdChange}
            displayKey="templateId"
            classname="multiselect"
          />
        </div>
      </AccordionItem>
      <hr />

      <AccordionItem
        title={this.renderDateTitle('filter.date.range')}
        key="dates"
      >
        <div className="date-labels">
          <label htmlFor="startDate" className="date-label">
            <FormattedMessage id="filter.from" />
            <DateTime
              locale="de"
              name="startDate"
              id="startDate"
              closeOnSelect
              timeFormat={false}
              inputProps={{
                placeholder: 'tt.mm.jjjj',
                name: 'startDate',
                id: 'existingArticleStartDate'
              }}
              value={this.state.startDate}
              defaultValue={this.state.startDate}
              onChange={this.handleStartDateChange}
            />
          </label>
          <label htmlFor="endDate" className="date-label">
            <FormattedMessage id="filter.and" />
            <DateTime
              locale="de"
              name="endDate"
              id="endDate"
              closeOnSelect
              timeFormat={false}
              inputProps={{
                placeholder: 'tt.mm.jjjj',
                name: 'endDate',
                id: 'existingArticleEndDate'
              }}
              value={this.state.endDate}
              defaultValue={this.state.endDate}
              onChange={this.handleEndDateChange}
            />
          </label>
        </div>
        <div className="dates">
        </div>
      </AccordionItem>
      <hr />

    </Accordion>)
  }

  render() {
    const { layout } = this.props

    return (<div
      className={classNames('grid-block vertical',
        css.existingArticleDialog,
        layout,
        testClass('existingArticleDialog'))}
    >
      <div className="grid-block filter-header">
        <div className="grid-block small-4 header-block v-align reset">
          <label>
            <FormattedMessage id="filter.filter" />
          </label>
          <GenevaButton
            className="small button"
            type="button"
            onClick={this.handleReset}
          >
            <FormattedMessage id="filter.reset" />
          </GenevaButton>
        </div>
        {this.renderFilterHeader()}
      </div>

      <div className="grid-block display-body">
        <div className="vertical grid-container small-4 filter-definitions">
          {this.renderAccordions()}
        </div>
        {this.props.renderListComponent()}
      </div>

    </div>)
  }
}
