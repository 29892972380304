import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import SourceDnD from './SourceDnD'
import SourceSlider from './SourceSlider'

/**
 * There is 2 types of Drag and Drop. Standard, which is un-restricted (with drop-zone though)
 * or one with a limit, which needs to be handled more like a HTML Slider (X or Y), and not Drag and Drop
 */
export default function containerSwitcher(parentProps) {

  if (parentProps.limit) {
    return <SourceSlider {...parentProps} />
  }

  return <SourceDnD {...parentProps} />
}
