
import PropTypes from 'prop-types'

import React, { Component } from 'react'
import { autobind } from 'core-decorators'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import { FormattedMessage, FormattedRelative } from '../../translations'

import {
  getUserNameById,
  i18n
} from '../../shared/utils'
import { convertDateToUserLocal } from '../../shared/DateConverter'

@observer
export default
class SelectArticleItem
  extends Component {

  static propTypes = {
    article: PropTypes.object.isRequired,
    children: PropTypes.node,
    image: PropTypes.string,
    selected: PropTypes.bool,
    onSelect: PropTypes.func,
    onDoubleClick: PropTypes.func,
    itemStore: PropTypes.object,
    previouslyAddedArticles: PropTypes.array,
    project: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.state = {
      previouslyAdded: false
    }
  }

  componentDidMount() {
    this.determineIfAdded()
  }

  @observable highlighted = false

  determineIfAdded() {
    if (this.props.previouslyAddedArticles
      && (this.props.previouslyAddedArticles.indexOf(this.props.article.id) !== -1)
    ) {
      this.setState({
        previouslyAdded: true
      })
    }
  }

  @autobind
  handleSelect() {
    if (this.props.onSelect) {
      this.props.onSelect({
        target: this.props.article
      })
      this.highlighted = !this.highlighted
    }
  }

  @autobind
  handleDoubleClick() {
    const { article, onDoubleClick, previouslyAddedArticles } = this.props
    const previouslyAdded = previouslyAddedArticles
      && previouslyAddedArticles.find(el => el === article.id)

    if (onDoubleClick && !previouslyAdded) {
      onDoubleClick({
        target: article
      })
    }
  }

  renderlanguage() {
    if (this.props.project
      && (this.props.project.language !== this.props.article.createdIso)
    ) {
      return (<span className="element-list-item-language">
        {this.props.article.createdIso}
      </span>)
    }
    return null
  }

  render() {

    const { article, selected, image, children } = this.props
    const { previouslyAdded } = this.state

    // as a default for the image
    let shortcut = global.GENEVA_CONFIG.templates[0]

    if (article) {
      shortcut = global.GENEVA_CONFIG.templates
        .find(tmpl => tmpl.id === article.templateId)
    }

    // TODO: use actual vendor
    const vendor = 'default' || shortcut.vendor

    return (<li
      key={article.id}
      className={classNames('element-list-item', {
        selected,
        unselectable: previouslyAdded
      })}
      onMouseDown={this.handleSelect}
      onDoubleClick={this.handleDoubleClick}
    >
      {children}

      <div className="warning-overlay">
        <FormattedMessage id="select-article-dialog.previously-added" />
      </div>

      <div className="image-holder">
        <img
          role="presentation"
          src={image || `/templates/${vendor}/article/${shortcut && shortcut.shortcut}/icon.svg`}
        />
      </div>

      <div className="grid-block vertical v-align details">
        <div className="grid-content">

          <span className="element-list-item-date">
            { /* <i className="ion ion-clock"></i>&nbsp; */}
            <FormattedRelative
              value={convertDateToUserLocal(article.createdAt)}
            />
          </span>

          { /*
          <span className="element-list-item-status badge">
            <FormattedMessage
              id={`status.${article.statusName}`}
            />
          </span> */}
          {this.renderlanguage()}

        </div>

        <div className="element-list-item-desc">
          {i18n(article, 'name', article.createdIso)}
        </div>

        <div className="grid-content">

          <span className="element-list-item-creator">
            <i className="ion ion-person"></i>&nbsp;{getUserNameById(article.createdBy)}
          </span>

          <span className="element-list-item-template">
            <i className="ion ion-document"></i>&nbsp;{article.templateName}
          </span>

        </div>
      </div>

    </li>)
  }
}
