import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { autobind } from 'core-decorators'
import classNames from 'classnames'
import { FormattedMessage } from '../../../translations'

import Dropdown, {
  DropdownContainer,
  DropdownOpener,
  Entry,
} from '../Dropdown'
import GenevaDropdownOpener from '../../../ui/components/GenevaDropdownOpener'
import { testClass } from '../../utils'

function getLanguageEntry(langInfo, currentProject) {
  return {
    name: langInfo.lang,
    state: {
      disabled: false, // currentProject.id === langInfo.id,
      checked: currentProject.id === langInfo.id,
    },
    id: langInfo.id,
    key: `project-lang-${langInfo.id}-${langInfo.lang}`,
  }
}

@observer
class LanguageDropdown extends Component {
  static propTypes = {
    projectStore: PropTypes.object,
    isButton: PropTypes.bool,
    currentProject: PropTypes.object,
    onRenderEntry: PropTypes.func,
    onSelect: PropTypes.func,
    className: PropTypes.string,
    isScrollable: PropTypes.bool,
    isGenevaButton: PropTypes.bool,
    label: PropTypes.string,
    isSelectBox: PropTypes.bool,
  };

  @autobind
  handleSelectEntry(e) {
    if (this.props.onSelect) {
      this.props.onSelect(e)
    }
  }

  renderEntry(lang) {
    const { currentProject, isSelectBox } = this.props
    const entry = getLanguageEntry(lang, currentProject)

    if (this.props.onRenderEntry) {
      return this.props.onRenderEntry(entry)
    }

    if (isSelectBox) {
      return this.renderOptionElement(entry)
    }

    return (
      <Entry
        key={entry.key}
        {...entry.state}
        identifier={entry.id}
        onClick={this.handleSelectEntry}
      >
        {entry.name}
      </Entry>
    )
  }

  renderLabel() {
    const { currentProject, isButton } = this.props

    if (this.props.label) {
      return this.props.label
    }
    return isButton ? (
      currentProject.language
    ) : (
      <FormattedMessage id="project.language" />
    )
  }

  renderOptionElement(entry) {
    return (
      <option key={entry.id} {...entry.state} value={entry.id}>
        {entry.name}
      </option>
    )
  }

  renderSelectBox(projectGroupLanguages, hasCurrentProject) {
    const { disabled, projectStore, className, currentProject } = this.props

    let location = 0
    if (hasCurrentProject) {
      projectGroupLanguages.forEach((gpLang) => {
        if (gpLang.lang === currentProject.language) {
          location = gpLang.id
        }
      })
    }

    return (
      <select
        className={classNames(className, testClass('project-selection'))}
        disabled={disabled || !projectStore.collection.length}
        onChange={this.handleSelectEntry}
        value={location}
      >
        {
          // show only those languages that belong to projects which are
          // in the same project group as the currently selected project
          !hasCurrentProject
            ? null
            : projectGroupLanguages.map(lang => this.renderEntry(lang))
        }
      </select>
    )
  }

  render() {
    const {
      projectStore,
      currentProject,
      isButton,
      className,
      isScrollable,
      isSelectBox,
    } = this.props
    let projectGroupLanguages = []
    // check if loading is happening (by no current)
    const hasCurrentProject = currentProject && !!currentProject.projectGroupId
    if (hasCurrentProject) {
      const currentProjectGroup = projectStore.getGroupById(
        currentProject.projectGroupId
      )
      projectGroupLanguages = currentProjectGroup.projects.map(proj => ({
        lang: proj.language,
        id: proj.id,
      }))
    }

    if (isSelectBox) {
      return this.renderSelectBox(projectGroupLanguages, hasCurrentProject)
    }

    const Container = isButton ? DropdownContainer : Entry
    const Opener = this.props.isGenevaButton
      ? GenevaDropdownOpener
      : DropdownOpener

    return (
      <Container>
        <Opener
          clickToClose
          disabled={!currentProject}
          className={classNames(className, testClass('language-selection'))}
          caret={false}
          arrow
        >
          {this.renderLabel()}
        </Opener>
        <Dropdown
          isScrollable={isScrollable}
          className={classNames('geneva-dropdown', testClass('project-list'))}
        >
          {
            // show only those languages that belong to projects which are
            // in the same project group as the currently selected project
            !hasCurrentProject
              ? null
              : projectGroupLanguages.map(lang => this.renderEntry(lang))
          }
        </Dropdown>
      </Container>
    )
  }
}

export default LanguageDropdown
