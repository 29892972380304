/**
 * Wraps a function into a deprecation warning.
 * Tries to read the function name from `function.name`, falls back to "undefined"
 * @example
 * deprecationWarning(()=> { console.log("here") })()
 * // > DEPRECATION `Undefined` is deprecated and should not be used anymore!#
 * // > here
 *
 * function namedFunction () {}
 * deprecationWarning(namedFunction)()
 * // > DEPRECATION `namedFunction` is deprecated and should not be used anymore!
 *
 * deprecationWarning(namedFunction, "don't use that")()
 * // > DEPRECATION `namedFunction` don't use that
 *
 * deprecationWarning(() => {}, undefined, { title: functionWithTitle })()
 * // > DEPRECATION `functionWithTitle` don't use that
 *
 * deprecationWarning(() => {}, undefined, {
 *  title: functionWithTitle,
 *  url: 'http:link.to.docs/page.info'
 * })()
 * // > DEPRECATION `functionWithTitle` don't use that
 * // >    See: http:link.to.docs/page.info
 * // >
 * @param {Function} target - The function to wrap in the deprecation message.
 * @param {String} [message] - The message to send.
 * @param {Object} [opts] - Some options.
 * @param {String} [opts.title] - The title to use instead of `function.name`.
 * @param {String} [opts.url] - An url to display below the message.
 * @returns {Function} - The original function.
 */
export default
function deprecationWarning(
  target,
  message = 'is deprecated and should not be used anymore!',
  opts = {}
) {

  const name = opts.title || target.name || 'Undefined'

  if (opts.url) {
    message = `${message}
    See: ${opts.url}
`
  }

  console.warn(`DEPRECATION \`${name}\` ${message}`)
  return target
}
