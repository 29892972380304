import Command from '../command'

export default class RemoveVideoFromArticlePlaceholderCommand extends Command {

  constructor(data) {

    super(data)

    this.data = data

  }

  validate(data) {

    if (!data) {
      this.throwError('expects data')
    }
    if (!data.article) {
      this.throwError('expects an article')
    }
    if (!data.pid) {
      this.throwError('expects a placeholder id')
    }
  }

  exec() {

    // const placeholderData = this.data.article.getDataInPlaceholder(this.data.pid)

    this.data.article.removeDataInPlaceholder(`${this.data.pid}*`)

  }
}
