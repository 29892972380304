import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import classNames from 'classnames'

import ToolbarButton from './ToolbarButton'
import ForeColorToolbarButton from './ForeColorToolbarButton'
import FontStyleToolbarButton from './FontStyleToolbarButton'
import Separator from './Separator'

/**
 * This list contains components for specific buttons that need to renders
 * differently than the default `ToolbarButton`.
 * So for such special buttons, define the components to be used to
 * render them here using the `commadName` as a key.
 */
const btnComponents = {
  foreColor: ForeColorToolbarButton,
  fontStyle: FontStyleToolbarButton
}


/**
 * This renders the text editors toolbar. The button specs are provided by the
 * uiStore that contains a list of buttons that are allowed by the current
 * customer. Also that list's buttons already have applied their current state.
 *
 * Each button spec may define it's own
 *
 * NOTE: This may NOT become a stateless function ever,
 * otherwise the required `ref`-binding would not work.
 */
@inject('ui') @observer
export default
class Toolbar
extends Component {

  static propTypes = {
    className: PropTypes.string,
    ui: PropTypes.object,
  }

  renderButton(commandName) {

    const spec = this.props.ui.editorButtons[commandName]

    const ButtonComp = btnComponents[commandName] || ToolbarButton

    return <ButtonComp
      key={commandName}
      buttonSpec={spec}
    />

  }


  render() {

    const buttons = Object.keys(this.props.ui.editorButtons) || []

    return (<div
      className={classNames(
        this.props.className,
        'toolbar',
        'editor-toolbar',
        'v-align'
      )}
    >
      {buttons.map((commandName, i) =>
        commandName.indexOf('separator') === 0
        ? <Separator key={`spearator-${i}`}/>
        : this.renderButton(commandName)
      )}
    </div>)
  }

}
