import * as PropTypes from 'prop-types'

import React from 'react'
import { toJS, extendObservable } from 'mobx'

import classNames from 'classnames'
import { FormattedHTMLMessage } from '../../../translations'

const css = /* typeof window === 'undefined' ? {} : */ require('./styles.scss')

const StatusItem = (props) => {
  if (!props.item) {
    return null
  }

  const { name, value, progress, total, data } = props.item

  if (data && data.slice && Array.isArray(data.slice())) {
    return (
      <div>
        {data.map((itm, i) => (
          <StatusItem
            key={itm.id}
            item={extendObservable(
              {},
              {
                ...toJS(itm),
                id: `${itm.id}_${i}`,
              }
            )}
          />
        ))}
      </div>
    )
  }

  const type = typeof value
  const values
    = (type === 'string' || type === 'number' ? { count: value } : value) || {}

  return (
    <div className={classNames('grid-block', css.statusItem, 'shrink')}>
      {/* can show icons in status list. not wanted for now
      thumb
      ? <img
        role="presentation"
        src={thumb}
      />
      : null
      */}
      <div
        className={classNames(
          'grid-block',
          'v-align',
          'align-center',
          'vertical'
        )}
      >
        <div className="grid-block v-align align-center">
          <FormattedHTMLMessage id={`status.${name}`} values={values} />
        </div>

        {progress ? (
          <div className="progress grid-block shrink">
            <progress value={progress} max={total} />
          </div>
        ) : null}
      </div>
    </div>
  )
}

StatusItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]) /* .isRequired*/,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.object,
    ]),
    progress: PropTypes.number,
    total: PropTypes.number,
    // data: observableArrayLike,
    thumb: PropTypes.string,
  }),
}

export { StatusItem as default }
