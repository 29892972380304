import * as PropTypes from 'prop-types'
import React from 'react'

import DistinctModelObserver from '../../shared/view-models/DistinctModelObserver.ts'
import createParentPropsInjector from '../../shared/utils/create-parent-props-injector'

import viewModelConnector from '../../shared/lib/view-model'
import commandConnector from '../../shared/lib/command'

import GoogleTranslateBar from '../components/GoogleTranslateBar'
import GoogleTranslateCommand from '../../shared/commands/google-translate'

export default function connectGoogleGoogleTranslateBar(parentProps) {
  const injectParentProps = createParentPropsInjector(parentProps)

  // Connector function
  function GoogleTranslateBarConnector({ ...rest }) {
    return <GoogleTranslateBar {...rest} />
  }

  // Component props
  GoogleTranslateBarConnector.propTypes = {
    articleStore: PropTypes.object.isRequired,
  }

  // Inject commands
  return commandConnector({
    GoogleTranslateCommand,
  })(
    viewModelConnector([
      {
        class: DistinctModelObserver,
        data: [
          {
            type: 'article',
            id: DistinctModelObserver.fromProps('articleStore.current.id'),
            as: 'googleTranslateArticle',
            component: GoogleTranslateBar.name,
          },
        ],
        as: 'currentArticle',
      },
    ])(injectParentProps(GoogleTranslateBarConnector))
  )
}
