import PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'
import { autobind } from 'core-decorators'

const css = /* typeof window === 'undefined' ? {} : */require('../../styles.scss')

export default
class ImagePropagationLanguageEntry extends React.Component {

  static propTypes = {
    languageName: PropTypes.string.isRequired,
    realName: PropTypes.string.isRequired,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    currentImage: PropTypes.object.isRequired,
    cachedImage: PropTypes.object,
    onCheck: PropTypes.func.isRequired
  };

  @autobind
  handleChecked({ target: { checked } }) {
    if (this.props.onCheck) {
      this.props.onCheck({
        target: {
          checked,
          name: this.props.languageName
        }
      })
    }
  }

  render() {
    const {
      languageName,
      realName,
      checked,
      disabled,
      currentImage
    } = this.props

    const hideIfNoCurrent = { hidden: !(currentImage && currentImage.src) }

    const id = `lang-select-${languageName}`

    return (<div
      key={languageName}
      className={classNames(
        'grid-block v-align',
        css.imagePropagationLanguageEntry
      )}
    >

      <div className="grid-block medium-4">
        <input
          id={id}
          onChange={this.handleChecked}
          type="checkbox"
          name={languageName}
          checked={checked}
          disabled={disabled}
        />
        <label htmlFor={id}>
          {languageName}
        </label>
      </div>

      <div
        className={classNames('grid-block medium-5', hideIfNoCurrent)}
      >
        <label htmlFor={id}>
          {realName}
        </label>
      </div>

      <div
        className={classNames(
          'grid-block medium-2 small',
          css.imagePropagationPreviewImage,
          hideIfNoCurrent
        )}
      >
        <img
          role="presentation"
          src={currentImage && currentImage.src}
        />
      </div>

    </div>)
  }
}
