import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import { autobind } from 'core-decorators'
import classNames from 'classnames'
import { formatMessage } from '../../../translations'
import { eventHandlers } from '../../../shared/react-helpers'
import ContentEditable from '../../../editor/components/ContentEditable'

const defaultButtons = []

const buttonsStr = defaultButtons.map(button => button.name).join(',')

export const defaultAttributes = {
  editable: 'text',
  type: 'text',
  maxLen: null,
  buttons: buttonsStr,
  spec: {},
  block: false,
  showWhenEmpty: false,
  defaultText: null,
  singleLine: false,
}

export const getDataAttribute = function getDataAttribute(props, key) {
  if (key === 'spec') {
    return null
  }

  const spec = props.spec || {}

  return props[key] || spec[key] || defaultAttributes[key]
}

class Editor extends Component {
  // eslint-disable-next-line react/sort-comp
  static defaultButtons = buttonsStr;

  static buttons = buttonsStr;

  static propTypes = {
    // required properties
    pid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    spec: PropTypes.object,

    css: PropTypes.object,
    style: PropTypes.object,

    // optional properties
    className: PropTypes.string,
    tagName: PropTypes.oneOf([
      'div',
      'span',
      'a',
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
      'article',
      'section',
      'aside',
      'figure',
      'figcaption',
    ]),
    maxLen: PropTypes.number,
    buttons: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.string,
    ]),
    value: PropTypes.string,
    showWhenEmpty: PropTypes.bool,
    singleLine: PropTypes.bool,
    defaultText: PropTypes.string,

    shouldFocus: PropTypes.bool,

    // for internal use
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onDrop: PropTypes.func,
    onKeyDown: PropTypes.func,
  };

  static defaultProps = {
    tagName: 'div',
    spec: {},
    css: {},
    style: {},
  };

  getDataAttributes() {
    return Object.keys(defaultAttributes).reduce((memo, key) => {
      const value = this.getDataAttribute(key)

      if (value) {
        memo[key] = value
      }

      return memo
    }, {})
  }

  getDataAttribute(key) {
    return getDataAttribute(this.props, key)
  }

  getEventContext(event) {
    return {
      target: {
        ...event.target,
        type: 'text',
      },
      data: event.data,
      isTitleField: this.props.spec.isArticleName,
    }
  }

  @autobind
  handleChange(event) {
    if (this.props.onChange) {
      this.props.onChange(this.getEventContext(event))
    }
  }

  @autobind
  handleKeyDown(event) {
    if (this.props.onKeyDown) {
      this.props.onKeyDown(event)
    }
  }

  @autobind
  handleFocus(event) {
    if (this.props.onFocus) {
      this.props.onFocus(this.getEventContext(event))
    }
  }

  @autobind
  handleBlur(event) {
    if (this.props.onBlur) {
      this.props.onBlur(this.getEventContext(event))
    }
  }

  @autobind
  handleDrop(event) {
    if (this.props.onDrop) {
      this.props.onDrop(this.getEventContext(event))
    }
  }

  focus() {
    if (this.refElem && this.refElem.focus) {
      this.refElem.focus()
    }
  }

  isEmpty() {
    const html = this.props.value
    const div = document.createElement('div')
    div.innerHtml = html
    const text = div.textContent
    return text.trim().length
  }

  render() {
    if (!this.props.showWhenEmpty && this.isEmpty()) {
      return null
    }

    const tagName = this.props.tagName || 'div'

    const dataAttributes = this.getDataAttributes()

    let defaultText
      = dataAttributes.defaultText
      || formatMessage({
        id: 'editor.default-text',
      })

    if (this.props.isMandatory) {
      defaultText += ` ${formatMessage({
        id: 'editor.mandatory',
      })}`
    }

    const dataAttribs = Object.keys(this.props).reduce((memo, key) => {
      // Allow all data and aria properties
      if (key.indexOf('data') === 0 || key.indexOf('aria') === 0) {
        memo[key] = this.props[key]
      }
      if (key === 'isMandatory') {
        memo[key] = this.props[key]
      }
      return memo
    }, {})

    const handlers = eventHandlers(this.props)

    // for focusing, see: http://stackoverflow.com/questions/36707854/programmatically-focus-on-a-form-element-after-mounting
    return (
      <ContentEditable
        tagName={tagName}
        className={classNames(this.props.className, this.props.css.editor)}
        tabIndex="1"
        placeholder={defaultText}
        value={this.props.value}
        style={this.props.style}
        {...dataAttribs}
        {...handlers}
        onChange={this.handleChange}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        onDrop={this.handleDrop}
        onKeyDown={this.handleKeyDown}

        ref={(ref) => {
          if (ref && this.props.shouldFocus) {
            ref.focus()
          }
          this.refElem = ref
        }}
      />
    )
  }
}

function injectIntlImproved(EditorComponent) {
  return class InjectIntlImproved extends Component {
    static contextTypes = {
    }

    focus() {
      if (this.refElem && this.refElem.focus) {
        this.refElem.focus()
      }
    }

    render() {
      return (
        <EditorComponent
          {...{
            ...this.props,
          }}
          ref={ref => (this.refElem = ref)}
        />
      )
    }
  }
}

const IntlEditor = injectIntlImproved(Editor)
IntlEditor.getDataAttribute = getDataAttribute

export { IntlEditor as default }
