import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import { autobind } from 'core-decorators'
import { formatMessage } from '../../translations'
import GenevaButton from '../../ui/components/GenevaButton'

import { filter } from '../../shared/obj'

class ArticleContextButton extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    children: PropTypes.node.isRequired,
    title: PropTypes.string,
    pageId: PropTypes.number.isRequired,
    onClick: PropTypes.func,
    onMouseDown: PropTypes.func,
    onMouseUp: PropTypes.func,
    onMousePress: PropTypes.func,
  };

  @autobind
  handleMousePressStart(...args) {
    if (this.props.onMouseDown) {
      this.props.onMouseDown(...args)
    }
    // makse sure that resorting is also cancelled once mouseup happens outside
    // of the button
    document.body.addEventListener('mouseup', this.handleMousePressEnd, false)
    this.handleMousePress()
    this.mousePressInterval = setInterval(() => this.handleMousePress(), 200)
  }

  @autobind
  handleMousePressEnd() {
    document.body.removeEventListener(
      'mouseup',
      this.handleMousePressEnd,
      false
    )
    if (this.mousePressInterval) {
      clearInterval(this.mousePressInterval)
    }
  }

  @autobind
  handleMousePress() {
    if (this.props.onMousePress) {
      this.props.onMousePress()
    }
  }

  render() {
    const {
      pageId,
      children,
      onMousePress,
    } = this.props

    const newProps = filter(this.props, [
      'disabled',
      'title',
      'onClick',
      'onMouseDown',
      'onMouseUp',
    ])

    if (onMousePress) {
      newProps.onMouseDown = this.handleMousePressStart
      newProps.onMouseUp = this.handleMousePressEnd
    }

    if (!pageId) {
      newProps.title = formatMessage({
        id: 'article-context-button.loading-page',
      })
      newProps.disabled = true
    }
    return (
      <GenevaButton {...newProps} className="geneva-small-button widget-header">
        {children}
      </GenevaButton>
    )
  }
}

export default ArticleContextButton
