import React, { Component } from 'react'
import { autobind } from 'core-decorators'
import { inject, observer } from 'mobx-react'
import classNames from 'classnames'

import Icon from '../../shared/icons'

import * as css from './AppDevTools.scss'

@inject('ui')
@observer
export default
class AppDevTools extends Component {
  @autobind
  handleVSplit() {
    this.props.ui.setPaneSplit('split-vertical')
  }

  @autobind
  handleHSplit() {
    this.props.ui.setPaneSplit('split-horizontal')
  }

  @autobind
  handleNoSplit() {
    this.props.ui.setPaneSplit(null)
  }

  render() {
    return (<div
      className={classNames('button-group', css.appDevTools)}
    >
      <button
        className={classNames('tiny', 'button', {
          active: this.props.ui.paneSplit === null
        })}
        onClick={this.handleNoSplit}
        title="No Split"
      ><Icon name="fa-desktop" />
      </button>
      <button
        className={classNames('tiny', 'button', {
          active: this.props.ui.paneSplit === 'split-vertical'
        })}
        onClick={this.handleVSplit}
        title="Split vertically"
      ><Icon name="fa-pause" />
      </button>
      <button
        className={classNames('tiny', 'button', {
          active: this.props.ui.paneSplit === 'split-horizontal'
        })}
        onClick={this.handleHSplit}
        title="Split horizontally"
      ><Icon name="fa-pause" rotate={90} />
      </button>
    </div>)
  }
}
