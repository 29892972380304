import { closest } from './dom-helpers'
import BaseCommand from './BaseCommand'

class LinkCommandImpl extends BaseCommand {

  static defaultData = {
    href: 'https://',
  //  target: '_self',
    title: '',
    exists: false,
    appearance: null
  }

  static commandName = 'link'

  constructor(...args) {
    super(...args)
    this.linkHadContent = false
  }

  cacheSelection() {
    super.cacheSelection()
    this.linkHadContent = this.getHadContent()
  }

  executeCommand(cmd, data) {

    // per default use the link command
    let command = this.link
    let args = [data]

    // in case cmd is unlunk, use the alternative unlink command
    if (cmd === 'unlink') {
      command = this.unlink
      args = []
    }

    return command.call(this, ...args)

  }

  /**
  * @private
  */
  unlink() {

    const selection = new this.scribe.api.Selection()
    const parent = this.getAnchorNodeAroundSelection()

    if (!parent) {
      return null
    }

    selection.range.selectNode(parent)
    selection.selection.removeAllRanges()
    selection.selection.addRange(selection.range)

    const command = this.scribe.getCommand('unlink')
    if (command) {
      command.execute()
    }

    return Promise.resolve()
  }

  /**
  * @private
  * @param  {Object} linkData The data for the link.
  */
  link(linkData) {
    return new Promise((resolve) => {

      let anchor = this.getAnchorNodeAroundSelection()

      if (!anchor) {
        this.scribe.api.SimpleCommand.prototype.execute
        .call(this.cmd, linkData.href)
      }


      // Wait for the node to appear in the dom after the next
      // rendering cycle.
      setTimeout(() => {

        let selection = new this.scribe.api.Selection()

        anchor = anchor || this.ensureAnchorInSelection(selection, {type: 'href', value: linkData.href })

        // regarding Ticket #10317 (Redmine)
        // if (
        //   anchor &&
        //   window.getComputedStyle(anchor).getPropertyValue('display') === 'block'
        // ) {
        //   anchor.parentNode.appendChild(this.createInvisibleCharTextNode())
        // }

        this.setLinkDataOnAnchor(anchor, linkData)
        this.selectAnchor(anchor)
        selection = new this.scribe.api.Selection()
        selection.placeMarkers()

        resolve()
      }, 1)
    })
  }


  /**
  * @private
  * @return {Object} The data found in any pre-existing link, or a default
  * object if no pre-existing link was found.
  */
  getInitialData(anchorNode) {

    const initialData = super.getInitialData(anchorNode)

    if (anchorNode) {

      initialData.exists = true
      initialData.href = anchorNode.getAttribute('href') || 'http://'
      initialData.target = anchorNode.target
      initialData.title = anchorNode.title || ''
      initialData.content = anchorNode.innerText || ''

      initialData.appearance = anchorNode.className
    }
    else {
      let selection = new this.scribe.api.Selection()

      // check if a link already exists
      if (!this.getAnchorNodeInSelection(selection)) {
        // check if selection should be set to max text (entire element)
        if (this.scribe.el.className.includes('fullTextLink')) {
          this.selectElementContents(this.scribe.el)
          selection = new this.scribe.api.Selection()
        }
      }
      initialData.content = selection.selection.toString()
    }

    return initialData

  }

  // expands text selection to entire div. solution for IE and non-IE
  // https://stackoverflow.com/questions/4183401/
  selectElementContents(el) {
    if (window.getSelection && document.createRange) {
        const sel = window.getSelection();
        const range = document.createRange();
        range.selectNodeContents(el);
        sel.removeAllRanges();
        sel.addRange(range);
    } else if (document.selection && document.body.createTextRange) {
        const textRange = document.body.createTextRange();
        textRange.moveToElementText(el);
        textRange.select();
    }
}

    /**
    * @private
    * @return {Boolean} True if the selection was not collapsed or whitespace.
    */
  getHadContent() {
    return !!this.initialData.content.trim().length
  }


    /**
    * @private
    * @param {Node} anchor   The DOM anchor node.
    * @param {Object} linkData The link data.
    */
  setLinkDataOnAnchor(anchor, linkData) {

    anchor.title = linkData.title

    anchor.target = linkData.target

    anchor.rel = linkData.rel
    anchor.href = linkData.href

    if (!anchor.target) {
      anchor.removeAttribute('target')
    }

    if (!anchor.rel) {
      anchor.removeAttribute('rel')
    }

      // this check is disabled as of ticket #2804
      // if (!this.linkHadContent) {
    anchor.textContent = linkData.content
      // }

    anchor.className = linkData.appearance
  }

    /**
    * @private
    * @param  {Node} anchor The anchor to be selected.
    */
  selectAnchor(anchor) {
    const selection = new this.scribe.api.Selection()
    selection.range.setStartAfter(anchor)
    selection.range.setEndAfter(anchor)

    selection.selection.removeAllRanges()
    selection.selection.addRange(selection.range)
    selection.selection.collapseToEnd()

    this.focusEditable(selection)
  }

    /**
    * @private
    * @param  {scribe.Selection} selection The selection containing the
    * element to be focused
    */
  focusEditable(selection) {
    let el = closest(selection.range.commonAncestorContainer, '[contenteditable]')[0]

    if (!el) {
      el = selection.getContaining((node) =>
        node.hasAttribute && node.hasAttribute('contenteditable')
      )
    }

    if (el) {
      el.focus()
    }
  }
}

/**
* This plugin adds a command for creating links, including a extended prompt.
*/
export default
function scribePluginLinkPromptCommandFactory(linkOpts = {}) {

  return function scribePluginLinkPromptCommand(scribe) {

    const linkPromptCommand = new scribe.api.Command('createLink')

    linkPromptCommand.nodeName = 'A'

    linkPromptCommand.execute = function executeLinkPromptCommand() {

      if (!linkOpts.showLinkPrompt) {
        throw new Error('linkPromptCommand: Cannot run, no `showLinkPrompt` defined in linkOpts!')
      }

      const impl = new LinkCommandImpl(scribe, this)

      scribe.transactionManager.runAsync((complete) => {

        impl.cacheSelection()

        linkOpts.showLinkPrompt(impl.initialData)
        .then((link) => {

          impl.restoreSelection()
          .then(() => {
            if (link && link.command) {
              return impl.executeCommand(link.command, link)
            }
          })
          .then(() => impl.finishCommand())
          .then(complete)

        })

      })
    }

    linkPromptCommand.queryState = function queryStateLinkPromptCommand() {
      /**
       * We override the native `document.queryCommandState` for links because
       * the `createLink` and `unlink` commands are not supported.
       * As per: http://jsbin.com/OCiJUZO/1/edit?js,console,output
       */
      const selection = new scribe.api.Selection()
      return !!selection.getContaining((node) =>
        node.nodeName === this.nodeName
      )
    }

    scribe.commands.linkPrompt = linkPromptCommand

  }
}
