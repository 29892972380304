import React from 'react'

import commandConnector from '../../shared/lib/command'
import ChangePasswordCommand from '../../shared/commands/change-password'

import NavbarOptions from '../components/Navbar/Options'


export default
function connectNavbarOptions() {

  // Connector function
  function NavbarOptionsConnector({
    ...rest
  }) {

    return (<NavbarOptions
      {...rest}
    />)
  }

  // Component props
  NavbarOptionsConnector.propTypes = {
  }

  // Inject commands
  return commandConnector({
    ChangePasswordCommand
  })(
    NavbarOptionsConnector
  )
}
