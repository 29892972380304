import { closest } from './dom-helpers'
import BaseCommand from './BaseCommand'

export
class ForeColorCommandImpl
extends BaseCommand {


  static defaultData = {
    color: 'inherit'
  }

  static defaultOpts = {
    tagName: 'span',
    className: 'forecolor'
  }

  static commandName = 'applyColor'

  get forecolorSelector () {
    return `${this.opts.tagName}.${this.opts.className}`
  }

  nodeGetColor (node) {
    return getComputedStyle(
      this.isTextNode(node) ?
      node.parentNode :
      node
    )
    .getPropertyValue('color')
  }

  getInitialData() {

    const selection = new this.scribe.api.Selection()

    let color = undefined

    if (
      selection &&
      selection.range &&
      selection.range.commonAncestorContainer
    ) {
      const parent = selection.range.commonAncestorContainer
      color = this.nodeGetColor(parent)
    }

    return {
      color
    }

  }

  applyColor(color) {

    return new Promise((resolve, reject) => {


      // remove all colored nodes that are (partially) selected
      // this.removeColoredNodes()

      const selection = new this.scribe.api.Selection()

      if (selection.selection.isCollapsed) {
        // extend selection to complete word
        this.extendSelection(selection)
      }

      if (color !== 'none') {
        document.execCommand('foreColor', false, color)

        // wait for the dom node to have been inserted
        window.setTimeout(() => {

          const foreColorNodes = this.scribe.el.querySelectorAll('font[color]')

          if (foreColorNodes.length) {

            // replace the node that was just insereted with the one defined in
            // the settings
            Array.prototype.forEach.call(foreColorNodes, (font) => {

              const color = font.color
              const colorNode = this.createColorElement(color)

              while (font.firstChild) {
                colorNode.appendChild(font.firstChild)
              }

              font.parentNode.insertBefore(colorNode, font)
              font.parentNode.removeChild(font)
            })

          }
          else {

            const newSelection = new this.scribe.api.Selection()
            const colorNode = this.createColorElement(color)

            colorNode.appendChild(this.createInvisibleCharTextNode())
            selection.range.insertNode(colorNode)

            newSelection.removeMarkers()
            const range = document.createRange()
            range.selectNodeContents(colorNode)
            selection.updateFromRange(range)
            selection.placeMarkers()
            selection.selectMarkers(true)

          }


          resolve()
        }, 1)
      }

      else {
        resolve()
      }

    })

  }

  createColorElement(color) {
    const colorNode = document.createElement(this.opts.tagName)
    colorNode.style = `color:${color};`
    colorNode.className = this.opts.className
    return colorNode
  }


  extendSelection(selection) {
    const container = selection.range.commonAncestorContainer
    const foreColorNode = closest(container, this.forecolorSelector)
    if (foreColorNode) {
      const range = document.createRange()
      range.selectNode(foreColorNode)
      selection.updateFromRange(range)
      selection.removeMarkers()
      selection.placeMarkers()
      selection.selectMarkers(true)
    }
  }

  /**
   * @private
   */
  removeColoredNodes() {

    let selection = new this.scribe.api.Selection()
    let commonAncestorContainer = selection.range.commonAncestorContainer

    let containedColoredNodes = []

    // in cases where the range is collapsed, look fo the closest
    // ancestor forecolor node and select all it's contents
    if (selection.range.collapsed) {

      const parentForeColor = closest(
        commonAncestorContainer,
        this.forecolorSelector
      )

      if (parentForeColor) {
        selection.removeMarkers()
        parentForeColor.insertBefore(
          this.createMarker(),
          parentForeColor.firstChild
        )
        parentForeColor.appendChild(this.createMarker())
        selection.selectMarkers(true)
        selection = new this.scribe.api.Selection()
        commonAncestorContainer = parentForeColor
      }

    }

    let child

    // if the whole selection is within a text node, then that
    // node - per definition - cannot have other children that may need
    // un-coloring, so just go up one level to the parent node and work from
    // there
    if (commonAncestorContainer.nodeType === Node.TEXT_NODE) {
      commonAncestorContainer = commonAncestorContainer.parentNode
    }

    const containingColoredNode = closest(
      commonAncestorContainer,
      this.forecolorSelector
    )

    if (containingColoredNode) {
      containedColoredNodes.unshift(containingColoredNode)
    }

    containedColoredNodes.forEach(node => {
      while (child = node.firstChild) {
        node.parentNode.insertBefore(child, node)
      }
      node.parentNode.removeChild(node)
    })

    selection.selectMarkers(true)

  }

  /**
   * @private
   */
  createColoredNode(color) {

    const forecolorNode = document.createElement(this.opts.tagName)
    let selection = new this.scribe.api.Selection()

    // this won't work on partially selected nodes on different containers
    // selection.range.surroundContents(forecolorNode)





    forecolorNode.appendChild(selection.range.extractContents());
    selection.range.insertNode(forecolorNode)

    forecolorNode.setAttribute('style', 'color:' + color + ';')
    forecolorNode.classList.add(this.opts.className)

    if (forecolorNode.textContent === '') {
      selection.removeMarkers()
      forecolorNode.innerHTML = this.createMarker().outerHTML
      selection.selectMarkers(true)
    }
    else {
      selection.removeMarkers()
      forecolorNode.insertBefore(this.createMarker(), forecolorNode.firstChild)
      forecolorNode.appendChild(this.createMarker())
      selection = new this.scribe.api.Selection()
      selection.selectMarkers(true)
    }

    return forecolorNode

  }


  /**
   * @private
   */
  removeForeColor(range, coloredNode, selection) {

    // Select the contents of the colored node and cache them.
    // Then select the complete colord node, remove it and insert
    // the cached contents in its place.
    range.selectNodeContents(coloredNode)
    const contents = range.cloneContents()
    const children = contents.childNodes

    range.selectNode(coloredNode)

    // coloredNode.parentNode.replaceChild(contents, coloredNode);
    range.deleteContents()
    range.insertNode(contents)

    if (selection) {
      selection.selection.removeAllRanges()
      selection.selection.addRange(range)
      selection.range = range
    }
  }

  /**
   * @private
   */
  selectNode(node) {

    // Select the created link
    const range = document.createRange()
    const selection = new this.scribe.api.Selection()

    range.setStartBefore(node)
    range.setEndAfter(node)

    selection.range = range
    selection.selection.removeAllRanges()
    selection.selection.addRange(range)
    selection.placeMarkers()
    selection.selectMarkers()

  }
}

export default
function scribePluginForeColorCommandFactory(colorOpts = {}) {

  return function scribePluginForeColorCommand(scribe) {

    const foreColorCommand = new scribe.api.CommandPatch('foreColor')


    foreColorCommand.execute = function executeForeColorCommand() {

      const impl = new ForeColorCommandImpl(scribe, this)

      scribe.transactionManager.runAsync((complete) => {

        impl.cacheSelection()

        colorOpts.showForeColorPrompt(impl.initialData)
        .then((result) => {

          impl.restoreSelection()
          .then(() => {
            if (result && result.status === 'committed') {
              return impl.executeCommand(
                ForeColorCommandImpl.commandName,
                result.color
              )
            }
          })
          .then(() => impl.finishCommand())
          .then(complete)

        })

      })
    }

    foreColorCommand.queryState = function queryStateForeColorCommand() {

      const impl = new ForeColorCommandImpl(scribe, this)

      const cb = (node) => {
        return //node.nodeName === 'SPAN' &&
          impl.nodeGetColor(node) !== ''
      }

      const selection = new scribe.api.Selection()
      let coloredNode
      let color = ''

      if (selection.selection.isCollapsed) {
        coloredNode = selection.range.commonAncestorContainer
        // if (!cb(coloredNode)) {
        //   coloredNode = null
        // }
      }
      else {
        // getContaining delivers only if the selection is fully contained.
        // Partial inclusions are ignored.
        coloredNode = selection.range.startContainer //selection.getContaining(cb)
      }

      if (coloredNode) {
        color = impl.nodeGetColor(coloredNode)
      }

      return {
        active : color !== '',
        value : color,
        type : 'color'
      }

    }


    foreColorCommand.queryEnabled = function queryEnableForeColorCommand () {
      // We could only enable the command if there is some text in the
      // selection...
      // var selection = new scribe.api.Selection()
      // const enabled = !selection.selection.isCollapsed
      // return enabled
      //
      // but especially when removing a color it's much easier to do this
      // without the need for a whole selection
      return true
    }

    scribe.commands.foreColor = foreColorCommand


    // legacy
    // scribe.on('toolbar:color:set-button-state', function (state, button) {
    //
    //   if (typeof state === 'object' &&
    //   'value' in state) {
    //     button.querySelector('i').style.color = state.value || ''
    //     button.querySelector('.color-indicator').style.backgroundColor = state.value || ''
    //   }
    //
    // })

  }

}
