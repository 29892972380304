import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'mobx-react'

import { PluginHook } from '../shared/plugin'


import { Provider as ViewModelProvider } from '../shared/lib/view-model/index.ts'
import { Provider as CommandDispatcherProvider } from '../shared/lib/command/index.ts'
import Root from './containers/Root'

export default ({ root, store, browserHistory, routes, devRoutes }) => {
  ReactDOM.render(
    (<Provider {...store}>
      <ViewModelProvider store={store}>
        <CommandDispatcherProvider store={store}>
          <PluginHook
            hook="root"
            store={store}
          >
            <Root
              history={browserHistory}
              devRoutes={devRoutes}
              routes={routes}
              store={store}
            />
          </PluginHook>
        </CommandDispatcherProvider>
      </ViewModelProvider>
    </Provider>),
    root
  )
}
