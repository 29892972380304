import PropTypes from 'prop-types'
import React from 'react'
import Icon from '../../../shared/icons'
import { FixedSection } from '../../../shared/components/Navbar'

export default
function WidgetNavbarTools(props) {

  return (<FixedSection>
    <a
      onClick={props.onClose}
      className="close-button"
    >
      <Icon name="ion-ios-close-empty" />
    </a>
  </FixedSection>)

}


WidgetNavbarTools.propTypes = {
  brand: PropTypes.node
}
