import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { autobind } from 'core-decorators'
import classNames from 'classnames'
import { ignore } from '../../../shared/obj'

/**
 * This is basicly copied from here: @link http://stackoverflow.com/questions/22677931/react-js-onchange-event-for-contenteditable
 */
export default class ContentEditable extends Component {
  // eslint-disable-next-line react/sort-comp
  static IS_EMPTY_CLASS = 'contentEditableEmpty'

  static propTypes = {
    tagName: PropTypes.oneOf([
      'div',
      'span',
      'a',
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
      'article',
      'section',
      'aside',
      'figure',
      'figcaption'
    ]),
    className: PropTypes.string,
    value: PropTypes.string,
    placeholder: PropTypes.string,

    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onDrop: PropTypes.func,
    onKeyDown: PropTypes.func,
    onPaste: PropTypes.func,
  }

  static defaultProps = {
    tagName: 'div'
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.value !== this.getHTML()
  }

  componentDidUpdate() {
    if (this.editor) {
      this.emitChange({
        target: this.editor
      })
    }
  }

  getHTML() {
    if (this.editor) {
      return this.editor.innerHTML
    }
    return null
  }

  getText() {
    if (this.editor) {
      return this.editor.textContent
    }
    return null
  }

  getEventContext() {
    const html = this.getHTML()
    const text = this.getText()
    return {
      target: {
        value: html,
        valueText: text
      }
    }
  }

  isEmptyHTML(html) {
    const div = document.createElement('div')
    div.innerHTML = html
    return div.textContent.length === 0
    // If there are empty tables in the elem
    && !/<table/.test(html)

  }

  @autobind
  emitChange(event, force = false) {

    const newEvent = this.getEventContext()
    const newValue = newEvent.target.value

    if ((force || newValue !== this.lastHtml) && this.props.onChange) {

      const method = this.isEmptyHTML(newEvent.target.value.trim())
        ? 'add'
        : 'remove'
      event.target
        .classList[method](ContentEditable.IS_EMPTY_CLASS)

      this.props.onChange(newEvent)

    }
    this.lastHtml = newValue

  }

  @autobind
  emitKeyDown(e) {
    if (this.props.onKeyDown) {
      this.props.onKeyDown(e)
    }
  }

  @autobind
  emitOnPaste(e) {
    if (this.props.onPaste) {
      this.props.onPaste(e)
    }
  }

  @autobind handleFocus(e) {
    this.emitChange(e, true)
    if (this.props.onFocus) {
      this.props.onFocus({
        ...e,
        target: this.props
      })
    }
  }

  @autobind handleBlur(e) {
    this.emitChange(e, true)
    if (this.props.onBlur) {
      this.props.onBlur({
        ...e,
        target: this.props
      })
    }
  }

  @autobind handleDrop(e) {
    if (!e.dataTransfer.types.includes('censhare-text')) {
      e.preventDefault()
      e.stopPropagation()
      return
    }

    if (this.props.onDrop) {
      const data = e.dataTransfer.getData('censhare-text')
      this.props.onDrop({
        ...e,
        data,
        target: this.props
      })
    }
  }


  @autobind handleDragOver(e) {
    if (e.dataTransfer.types.includes('censhare-text')) {
      e.preventDefault()
    }
    else {
      e.dataTransfer.effectAllowed = 'none'
    }
  }

  @autobind handleDragEnter(e) {
    if (e.dataTransfer.types.includes('censhare-text')) {
      e.preventDefault()
    }
    else {
      e.dataTransfer.effectAllowed = 'none'
    }
  }

  focus() {
    if (this.editor && this.editor.focus) {

      this.editor.focus()
      const elem = this.editor
      const selection = window.getSelection()
      const range = document.createRange()
      const isEmpty = !elem.innerHTML

      if (isEmpty) {
        elem.innerHTML = '\u00a0'
      }

      range.selectNodeContents(elem)
      selection.removeAllRanges()
      selection.addRange(range)

      if (isEmpty) {
        document.execCommand('delete', false, null)
      }

      selection.collapseToEnd()
    }
  }


  render() {

    const Elem = this.props.tagName || 'div'

    const isEmpty = !this.props.value
      || this.isEmptyHTML(this.props.value)

    const contentEditablePros = ignore(this.props, [
      'value', 'tagName'
    ])

    return (<Elem

      {...contentEditablePros}

      className={classNames(this.props.className, {
        [ContentEditable.IS_EMPTY_CLASS]: isEmpty
      })}

      dangerouslySetInnerHTML={{
        __html: this.props.value
      }}

      contentEditable="true"

      onInput={this.emitChange}
      onBlur={this.handleBlur}
      onDrop={this.handleDrop}
      onDragOver={this.handleDragOver}
      onDragEnter={this.handleDragEnter}
      onFocus={this.handleFocus}
      onKeyDown={this.emitKeyDown}
      onPaste={this.emitOnPaste}

      ref={ref => (this.editor = ref)}

    />)

  }

}
