import * as PropTypes from 'prop-types'
import React from 'react'

export default function Separator() {
  return <li className="separator" />
}

Separator.propTypes = {
  className: PropTypes.string,
}

Separator.defaultProps = {
  className: 'separator',
}
