import { store as uiStore } from '../ui'

function actionType(obj) {
  let action
  if (obj.publishFollowing !== undefined) {
    action = 'publish'
  }
  else if (obj.unpublishFollowing !== undefined) {
    action = 'unpublish'
  }
  return action
}

export const PublishInfoInspector = {
  before: (target, item, obj) => {
    const action = obj.publishAction || actionType(obj)
    if (item) {
      PublishInfoInspector.createStatusInfo('started', item, target, action)
    }
  },
  then: (target, item, obj) => {
    const action = obj.publishAction || actionType(obj)
    if (item) {
      PublishInfoInspector.createStatusInfo(
        'completed',
        item,
        target,
        action,
        2000
      )
    }
  },
  catch: (target, item, obj) => {
    const action = obj.publishAction || actionType(obj)
    if (item) {
      PublishInfoInspector.createStatusInfo(
        'failed',
        item,
        target,
        action,
        4000
      )
    }
  },
  createStatusInfo: (type, item, target, action, duration = 'forever') => {
    uiStore.addStatusInfo(
      {
        id: target.constructor.name,
        priority: 'low',
        name: item ? `page.${action}-${type}-name` : `page.${action}-${type}`,
        value: item ? item.getJSON() : null,
      },
      duration
    )
  },
}
