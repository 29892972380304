import { createConfirmation } from 'react-confirm'
import ProjectDialog from '../components/Dialog/ProjectDialog'

const projectDialog = createConfirmation(ProjectDialog)

export default function (confirmation: string|Object, options: Object = {}) {
  if (typeof confirmation !== 'string') {
    options = confirmation
    confirmation = ''
  }
  return projectDialog({ confirmation, options })
}
