import * as React from 'react'
import * as PropTypes from 'prop-types'

import ViewModelPool from './ViewModelPool'

import {
  Store,
  ViewModelProps,
  ViewModelContext,
} from './view-model.d'

export const ViewModelPoolPropType = PropTypes.shape({
  viewModelPool: PropTypes.shape({
    get: PropTypes.func.isRequired
  })
}).isRequired



export default
class ViewModelProvider
extends React.Component<ViewModelProps, undefined> {

  static childContextTypes = {
    viewModelPool: ViewModelPoolPropType
  }

  private viewModelPool: ViewModelPool = null

  constructor(props: ViewModelProps) {
    super(props)
    this.viewModelPool = new ViewModelPool({
      store: this.props.store
    })
  }

  getChildContext(): ViewModelContext {
    return {
      viewModelPool: this.viewModelPool
    }
  }

  render() {
    return this.props.children
  }

}
