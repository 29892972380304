import { AbortRequestMiddleware } from '..'

export default function abortable({ shouldAbort }) {
  return (target) => {
    const provider = target.prototype || target

    provider.initAbortable = function initAbortable() {
      this.api.use(
        new AbortRequestMiddleware({
          shouldAbort,
        })
      )
    }
  }
}
