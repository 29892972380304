import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { autobind } from 'core-decorators'
import classNames from 'classnames'

import Icon from '../../../shared/icons'

export default class Button extends React.Component {

  static propTypes = {
    button: PropTypes.object.isRequired,
    handleClick: PropTypes.func.isRequired
  }

  @autobind
  handleClick() {
    this.props.handleClick(this.props.button)
  }

  render() {
    const { button } = this.props
    return (
      <button
        className={classNames('geneva-button small plain button', button.isActive ? 'active' : '')}
        onClick={this.handleClick}
      >
        {button.icon
          ? <Icon name={button.icon} />
          : button.name
        }
      </button>
    )
  }
}
