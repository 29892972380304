import * as PropTypes from 'prop-types'
import { propTypes as MobXPropTypes } from 'mobx-react'

// import { modelShape } from '../model'

export default PropTypes.shape({
  collection: MobXPropTypes.arrayOrObservableArray,
  current: PropTypes.oneOfType([
    // modelShape,
    PropTypes.object,
  ]).isRequired,

  createModel: PropTypes.func.isRequired,
  create: PropTypes.func.isRequired,
  add: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  load: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  destroy: PropTypes.func.isRequired,
  asJSON: PropTypes.func.isRequired,
  toJSON: PropTypes.func.isRequired,
  getById: PropTypes.func.isRequired,
  openById: PropTypes.func.isRequired,
  contains: PropTypes.func.isRequired,
  setCurrent: PropTypes.func.isRequired,
  unsetCurrent: PropTypes.func.isRequired,
})
