import { createConfirmation } from 'react-confirm'
import ArticleSecurityDialog from '../../components/dialogs/ArticleSecurity'


const articleSecurity = createConfirmation(ArticleSecurityDialog)

function articleSecurityDialogFactory(
  confirmation: string,
  options: Object = {}
): any {
  if (typeof confirmation !== 'string') {
    options = confirmation
    confirmation = ''
  }
  return articleSecurity({ confirmation, options })
}

export { articleSecurityDialogFactory as default }
