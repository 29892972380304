import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import { toJS } from 'mobx'
import { observer } from 'mobx-react'

export default function connectContentRendererToContext(
  ContentRenderer,
  parentProps
) {

  @observer
  // eslint-disable-next-line react/prefer-stateless-function
  class PMContextConnectedRenderer extends Component {
    static propTypes = {
      gr: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      gb: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    };


    render() {
      const env = toJS(parentProps.env)

      return <ContentRenderer {...parentProps} env={env} {...this.props} />
    }
  }

  return PMContextConnectedRenderer
}
