
import * as PropTypes from 'prop-types'

import React, { Component } from 'react'
import classNames from 'classnames'
import { autobind } from 'core-decorators'

import { getHTMLEventsFromProps } from '../../../shared/utils/events'
import { getDataAttributes } from '../../../shared/utils/attribs'
import { store as contextStore } from '../../../context'

/**
 * Implements the drag source contract.
 * This is actually more the point
 * This is the **data** that's actually being dragged around.
 */

class Positionable extends Component {
  static propTypes = {
    pid: PropTypes.string.isRequired,
    children: PropTypes.node,
    value: PropTypes.object,

    className: PropTypes.string,
    style: PropTypes.object,

    onDragStart: PropTypes.func,
    onDragEnd: PropTypes.func,
    onCanDrag: PropTypes.func,
    locked: PropTypes.bool,
  };

  static defaultProps = {
    style: {},
    children: null,
  };

  constructor(props) {
    super(props)

    const { value } = props

    this.contextStore = contextStore

    this.isDragging = false

    this.state = {
      value: {
        left: (value && value.left) || this.DEFAULT_X_POS,
        top: (value && value.top) || this.DEFAULT_Y_POS,
      },
    }
  }

  componentWillReceiveProps(nextProps) {
    if ('value' in nextProps && nextProps.value) {
      this.setState({
        value: nextProps.value,
      })
    }
  }

  DEFAULT_X_POS = 0;

  DEFAULT_Y_POS = 0;

  yOffset = 0

  xOffset = 0

  @autobind
  canDrag() {
    if (!this.props.locked) {
      if (this.props.onCanDrag) {
        return this.props.onCanDrag()
      }
      return true
    }
    return false
  }

  handleOnDrop(event) {
    event.preventDefault()
    // console.log(event)
  }

  @autobind
  handleDragStart(event) {
    const dataToSend = {
      pid: this.props.pid || 'unknown'
    }

    // Extra logic for Firefox
    if (event.dataTransfer.types.includes('text/x-moz-url')) {
      event.dataTransfer.clearData('text/x-moz-url')
    }

    if (event.target) {
      const rect = event.target.getBoundingClientRect()
      const x = event.clientX - rect.left // x position within the element.
      const y = event.clientY - rect.top  // y position within the element.
      this.xOffset = x
      this.yOffset = y
    }

    event.dataTransfer.effectAllowed = 'move'
    event.dataTransfer.setData('positionable', JSON.stringify(dataToSend))
  }

  handleDragEnter(event) {
    event.preventDefault()
  }

  @autobind
  handleDragOver(event) {
    event.preventDefault()
    this.isDragging = true
  }

  @autobind
  handleDragEnd(event) {
    event.preventDefault()
    this.isDragging = false
    const eventHandlers = getHTMLEventsFromProps(this.props)
    const opts = {}
    const { renderPositionableHandle } = this.props

    // the size of the drag object needs to be taken into the calculations
    if (event.currentTarget && renderPositionableHandle) {
      opts.shiftY = this.yOffset
      opts.shiftX = this.xOffset * -1
    }
    // only use the middle for ones with no handle
    else if (event.currentTarget) {
      opts.shiftY = event.currentTarget.offsetHeight / 2
      opts.shiftX = event.currentTarget.offsetWidth / 2
    }


    if (eventHandlers.onDragEnd) {
      eventHandlers.onDragEnd(event, opts)
    }
  }

  // Since the drag element could be on a special handle,
  // then we attach the handlers more dynamically
  connectDragHandlers(rendererElement) {
    return (
      <div
        {...rendererElement.props}
        onDragStart={this.handleDragStart}
        onDragEnter={this.handleDragEnter}
        onDragOver={this.handleDragOver}
        onDragEnd={this.handleDragEnd}
        onDrop={this.handleOnDrop}
        draggable={this.canDrag()}
      >
        {rendererElement.props.children}
      </div>
    )
  }


  render() {
    const {
      style,
      renderPositionableHandle,
    } = this.props
    const { value } = this.state

    const eventHandlers = getHTMLEventsFromProps(this.props)
    const dataAttributes = getDataAttributes(this.props)

    const positionableProps = {
      className: classNames(this.props.className, 'positionable-positionable'),
      style: {
        ...style,
        position: 'absolute',
        left: `${value.left}px`,
        top: `${value.top}px`,
        opacity: this.isDragging ? 0.5 : style.opacity || 1,
      },
      'data-react-top': value.top,
      'data-react-left': value.left,
      id: `${this.props.pid}-positionable`,
      // ...eventHandlers,
      ...dataAttributes,
    }

    // If we defined a custom drag handler element that alone should allow
    // dragging, we need to render it before the children and enable it
    // as drag source.
    if (renderPositionableHandle) {
      const draggable = (
        <div {...positionableProps}>
          {this.connectDragHandlers(renderPositionableHandle())}
          {this.props.children}
        </div>
      )

      return draggable
    }

    // If we use no custom drag handler element connect the whole
    // element as drag preview.
    return (
      this.connectDragHandlers(
        <div {...positionableProps} {...eventHandlers}>{this.props.children}</div>
      )
    )
  }
}

export { Positionable as default }
