import { observable, computed, transaction } from 'mobx'
import { store as projectStore } from '../project'
import Communicator from '../Communicator'
import { apiClient } from '../shared/api'
import Model from '../shared/model'
import { Store } from '../shared/store'
import { CircularDependencyFreeStore } from '../CircularDependencyFreeStore'
import {
  SERVER_RESPONSE_AUTH_INVALID_INPUT,
  SERVER_RESPONSE_AUTH_INVALID_CREDENTIALS
} from '../shared/const'
import { deepGet } from '../shared/obj'

class User extends Model {

  // Put all User properties here.
  @observable firstname = ''

  @observable lastname = ''

  @observable apps = []

  @observable lastOpenedProjects

  autoSave = false

  @computed get name() {
    return `${this.firstname} ${this.lastname}`
  }

  @computed get asJSON() {
    return {
      id: this.id,
      firstname: this.firstname,
      lastname: this.lastname
    }
  }

}

class LoginError {

  @observable message

  constructor(origError) {
    this.message = origError.message || ''
  }

}

@apiClient
class AuthStore extends Store {

  @observable user

  @observable error

  constructor(opts = {}) {

    super(opts)

    this.Model = User

  }

  setData(user = null, error = null) {
    transaction(() => {
      this.error = error
      this.user = user

      // set the project history of the user
      if (user && user.lastOpenedProjects) {
        projectStore.setProjectHistory(user.lastOpenedProjects)
      }
    })
  }

  setUser(responseData) {

    if (!responseData.error || responseData.error instanceof LoginError) {
      const user = new User(this, deepGet(responseData, 'body.data'))
      const communicator = Communicator.getInstance()

      communicator.setUser(user.asJSON)
        .then(() => {
          this.setData(user)
        })

    }

  }

  handleError(err) {

    if (this.isLoginError(err)) {
      err = new LoginError(err)
    }
    else {
      // Log the error, otherwise it might go unnoticed!
      console.error(err)
    }

    switch (err.message) {
      case SERVER_RESPONSE_AUTH_INVALID_INPUT:
        err.id = 'auth.invalid-input'
        break
      case SERVER_RESPONSE_AUTH_INVALID_CREDENTIALS:
        err.id = 'auth.invalid-credentials'
        break
      default:
        break
    }

    let user = this.user
    if (!(err instanceof LoginError)) {
      user = null
    }

    this.setData(user, err)

  }

  isLoginError(err) {

    return !/TypeError|SyntaxError/.test(err.message || err)

  }

  deleteUser() {

    transaction(() => {
      delete this.user
      this.error = 'Logout'
    })
  }

  loadCurrentUser() {
    return this.dispatch('get', {
      path: 'user/me'
    })
      .then(responseData => this.setUser(responseData))
      .catch(err => this.handleError(err))

  }

  logout() {
    return this.dispatch('post', {
      path: 'user/logout'
    })
      .then(() => this.deleteUser())
      .catch(err => this.handleError(err))
      .then(() => {
        window.location.href = `${global.GENEVA_CONFIG.AM_URL}?logout`
      })

  }

  handleUnauthorized() {

    // If there is still a User in the Store,
    // the application session timed out.
    if (this.user) {
      let ending = ''
      if (window.location.pathname) {
        ending = `?redirectUrl=${encodeURIComponent(window.location)}`
      }

      window.location.href = `${global.GENEVA_CONFIG.AM_URL}${ending}`


    }
  }
}

const authStore = new AuthStore()

CircularDependencyFreeStore.authStore = authStore

export { authStore as authStore }
