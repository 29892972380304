import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { autobind } from 'core-decorators'
import classNames from 'classnames'
import { FormattedMessage } from '../../../translations'
import Dropdown, {
  DropdownContainer,
  DropdownOpener,
  Entry,
} from '../Dropdown'

import GenevaDropdownOpener from '../../../ui/components/GenevaDropdownOpener'
import { testClass } from '../../utils'

/**
 * returns display info for a project group
 * @param {*} projectGroup - single PG to render
 * @param {*} currProject - current project to understand selected
 */
function getProjectEntry(projectGroup, currProject) {
  return {
    name: `${projectGroup.channelName} - ${projectGroup.name}`,
    id: projectGroup.id,
    key: `project-${projectGroup.id}`,
    state: {
      disabled: false,
      checked: currProject.projectGroupId === projectGroup.id,
    },
  }
}

@observer
class ProjectDropdown extends Component {
  static propTypes = {
    projectStore: PropTypes.object,
    disabled: PropTypes.bool,
    isButton: PropTypes.bool,
    currentProject: PropTypes.object,
    onRenderEntry: PropTypes.func,
    onSelect: PropTypes.func,
    className: PropTypes.string,
    isScrollable: PropTypes.bool,
    isGenevaButton: PropTypes.bool,
    title: PropTypes.string,
    channel: PropTypes.object,
    isSelectBox: PropTypes.bool,
  };

  @autobind
  handleSelectEntry(e) {
    if (this.props.onSelect) {
      this.props.onSelect(e)
    }
  }

  // channel is optional.  If included, then the results are filtered by it
  renderEntry(projectGroup) {
    const {
      projectStore,
      currentProject,
      disabled,
      isSelectBox,
      channel,
    } = this.props

    if (channel && !projectGroup.verifyContainsChannel(channel.id)) {
      return null
    }

    const entry = getProjectEntry(projectGroup, projectStore, currentProject)

    if (disabled) {
      entry.state.disabled = true
    }

    if (this.props.onRenderEntry) {
      return this.props.onRenderEntry(entry)
    }

    if (isSelectBox) {
      return (
        <option key={entry.id} {...entry.state} value={entry.id}>
          {entry.name}
        </option>
      )
    }

    return (
      <Entry
        key={entry.id}
        {...entry.state}
        identifier={entry.id}
        onClick={this.handleSelectEntry}
      >
        {entry.name}
      </Entry>
    )
  }

  renderTitle() {
    if (this.props.title) {
      return this.props.title
    }
    return <FormattedMessage id="project.select-project" />
  }

  renderSelectBox() {
    const { disabled, projectStore, className, currentProject } = this.props

    return (
      <select
        className={classNames(className, testClass('project-selection'))}
        disabled={disabled || !projectStore.collection.length}
        onChange={this.handleSelectEntry}
        value={currentProject.projectGroupId}
      >
        {projectStore.groupCollectionSorted.map(projectGroup => this.renderEntry(projectGroup)
        )}
      </select>
    )
  }

  render() {
    // weird problem with re-rendering not working right. including prop
    // eslint-disable-next-line
    const {
      projectStore,
      disabled,
      className,
      isScrollable,
      isSelectBox,
    } = this.props

    if (isSelectBox) {
      return this.renderSelectBox()
    }

    const Container = this.props.isButton ? DropdownContainer : Entry
    const Opener = this.props.isGenevaButton
      ? GenevaDropdownOpener
      : DropdownOpener
    return (
      <Container>
        <Opener
          clickToClose
          className={classNames(className, testClass('project-selection'))}
          disabled={disabled || !projectStore.collection.length}
          caret={false}
          arrow
        >
          {this.renderTitle()}
        </Opener>
        <Dropdown
          isScrollable={isScrollable}
          className={classNames('geneva-dropdown', testClass('project-list'))}
        >
          {projectStore.groupCollectionSorted.map(projectGroup => this.renderEntry(projectGroup)
          )}
        </Dropdown>
      </Container>
    )
  }
}

export default ProjectDropdown
