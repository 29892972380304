import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import { autobind } from 'core-decorators'
import { formatMessage, FormattedMessage } from '../../../translations'
import config from '../../../config'

class ExternalLinkForm extends Component {
  static validate(value) {
    if (typeof value !== 'string') {
      value = value.externalUrl
    }
    return !!value
  }

  static propTypes = {
    validSchemes: PropTypes.array,
    onInputChanged: PropTypes.func,
    onLinkChanged: PropTypes.func,
    onAutofocus: PropTypes.func,
    externalScheme: PropTypes.string,
    externalUrl: PropTypes.string,
  };

  constructor(props) {
    super(props)
    this.validSchemes
      = (config.linkSettings && config.linkSettings.validSchemes)
      || this.props.validSchemes
  }

  normalizeLink(link) {
    const regexp = new RegExp(`^((${this.validSchemes.join('|')})://)?`, 'i')

    const match = link.match(regexp)
    const normalizedLink = {
      protocol: 'http',
      url: '',
    }

    if (match && match.length > 1) {
      normalizedLink.url = link.substr(match[0].length)
      normalizedLink.protocol = match[2]
    }

    return normalizedLink
  }

  updateLink(value) {
    const normalizedLink = this.normalizeLink(value)
    if (this.props.onLinkChanged) {
      this.props.onLinkChanged({
        target: {
          value: {
            externalUrl: normalizedLink.url,
            // Requires a fallback when going from something to external.
            //  Then this is missing in some cases
            externalScheme:
              normalizedLink.protocol || this.props.externalScheme || 'http',
          },
        },
      })
    }
  }

  @autobind
  handleRef(el) {
    if (el !== null && this.props.onAutofocus) {
      el.focus()
      this.props.onAutofocus({ target: el })
    }
  }

  @autobind
  handleLinkBlur({ target }) {
    this.updateLink(target.value)
  }

  @autobind
  handleLinkPaste(e) {
    try {
      const value = e.clipboardData.getData('text/plain')

      if (value) {
        e.preventDefault()
        e.stopPropagation()

        this.updateLink(value)
      }
    }
    catch (ex) {
      console.log(ex.stack)
      throw new Error('Could not get pasted data')
    }
  }

  render() {
    const {
      onInputChanged,
      externalScheme,
      externalUrl,
    } = this.props

    return (
      <div className="grid-block">
        <div className="small-4 grid-content">
          <label className="vertical" htmlFor="link-scheme">
            <FormattedMessage id="link-dialog.external-url" />
            <select
              name="externalScheme"
              id="link-external-scheme"
              onChange={onInputChanged}
              value={externalScheme}
            >
              {this.validSchemes.map(availableScheme => (
                <option key={availableScheme} value={availableScheme}>
                  {formatMessage({
                    id: `link-dialog.external-scheme-${availableScheme}`,
                  })}
                </option>
              ))}
            </select>
          </label>
        </div>
        <div className="small-8 grid-content">
          <label htmlFor="link-external-url">
            <span />
            <input
              type="text"
              key="externalUrl"
              name="externalUrl"
              id="link-external-url"
              placeholder={formatMessage({
                id: 'link-dialog.placeholder-external-url',
              })}
              value={externalUrl}
              ref={this.handleRef}
              onChange={onInputChanged}
              onPaste={this.handleLinkPaste}
              onBlur={this.handleLinkBlur}
            />
          </label>
        </div>
      </div>
    )
  }
}

export default ExternalLinkForm
