import { store as imageStore } from '../../image'
import Command from '../command'


function keyRequired(key, path = null) {
  if (path) {
    path = `.${path}`
  }
  // eslint-disable-next-line max-len
  return `requires \`data.${path}${key}\` (from \`contextStore.target.${key}\`) to be present`
}

export default class RemoveImageFromArticlePlaceholderCommand extends Command {

  constructor(data) {

    super(data)

    this.data = data

  }

  validate(data) {

    return ['article', 'pid']
      .filter(key => !(key in data) || !data[key])
      .map(key => keyRequired(key))
      .concat(
        ['value']
          .filter(key => data.image && (!(key in data.image) || !data.image[key]))
          .map(key => keyRequired(key, 'image'))
      )
      .join(', ')

  }

  exec() {

    const placeholderData = this.data.article.getDataInPlaceholder(this.data.pid)
    const opts = { lang: this.data.article.createdIso }

    this.data.article
      .removeDataInPlaceholder(`${this.data.pid}*`)

    if (placeholderData && placeholderData.id) {
      imageStore.destroy(placeholderData.id, opts)
        .catch((err) => {
          // eslint-disable-next-line max-len
          console.warn(`The image with the id '${placeholderData.id} could not be deleted: ${err.message}'`)
        })
    }

  }


}
