import { deepGet } from '../obj'

export default
function validate(target, spec, text) {
  const msg = Object.keys(spec).map((prop) => {
    const val = deepGet(target, prop)

    let types = spec[prop]
    if (!Array.isArray(types)) {
      types = [types]
    }

    const typesString = types.map(type => `\`${type}\``).join(' or ')

    const type = types.length === 0 && types[0] === 'function'
      ? 'method'
      : 'property'

    if (!val) {
      return `a \`${prop}\` ${type} of type ${typesString}`
    }

    const valType = typeof val

    if (types.indexOf(valType) === -1) {
      return `a \`${prop}\` ${type} of type ${typesString}, has type \`${valType}\``
    }

    return null
  })
    .filter(m => !!m)
    .join('\n ')
  if (msg) {
    throw new Error(`${text} to contain\n ${msg}`)
  }
}
