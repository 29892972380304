export default function htmlSplit(str, count) {

  let nodeStack = []
  let splitPointFound = false
  const part1Container = document.createElement('div')
  const part2Container = document.createElement('div')
  let removeNode

  part1Container.innerHTML = str

  function track(el) {

    const len = el.textContent.length

    // we need to split
    if (len > count) {

      let nextParent = part2Container

      // if we are actually somewhere within a dom node
      // with nodeType !== TEXT_NODE
      if (nodeStack && nodeStack[0]) {

        let tmpNodeStack = []

        let parent = part2Container
        tmpNodeStack = nodeStack.map((node) => {
          nextParent = node.cloneNode()
          parent.appendChild(nextParent)
          parent = nextParent
          return nextParent
        })

        nodeStack = tmpNodeStack
      }


      nextParent.textContent = el.textContent.substring(count)

      el.textContent = el.textContent.substring(0, count)

      if (!el.textContent.length) {
        removeNode = el.parentNode
      }

      splitPointFound = true

    }

    count -= len

  }

  function walk(el, fn) {

    let node = el.firstChild

    do {

      const next = node.nextSibling
      if (splitPointFound) {

        const lastNode = nodeStack.slice(-1)[0] || part2Container
        if (node.nodeType === document.TEXT_NODE) {
          node.parentNode.removeChild(node)
          lastNode.innerHTML += node.textContent
        }
        else {
          lastNode.appendChild(node)
        }

      }
      else {

        if (node.nodeType === document.TEXT_NODE) {
          fn(node)
        }
        else if (node.nodeType === document.ELEMENT_NODE
          && node.childNodes
          && node.childNodes[0]
        ) {
          nodeStack.push(node)
          walk(node, fn)
          nodeStack.pop()
        }

      }

      node = next

    } while (node)
  }

  walk(part1Container, track)

  if (removeNode && removeNode.parentNode) {
    removeNode.parentNode.removeChild(removeNode)
  }

  return [
    part1Container.innerHTML,
    part2Container.innerHTML
  ]
}
