import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { observable, action } from 'mobx'
import classNames from 'classnames'
import { autobind } from 'core-decorators'
import { FormattedMessage } from '../../translations'

import { store as pageStore } from '../../page'
import AppLoader from '../../shared/components/AppLoader'
import ButtonBar from '../../shared/components/ButtonBar'

import Navbar from '../components/Navbar'
import CopyManager from './CopyManager'
import PublicationManager from './PublicationManager'

const css = /* typeof window === 'undefined' ? {} : */require('../styles.scss')

@observer
export default
class ControlCenterContainer
  extends Component {

  static propTypes = {
    auth: PropTypes.object.isRequired,
    context: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
    projectStore: PropTypes.object.isRequired,
    ui: PropTypes.object.isRequired,
  }

  /* eslint-disable react/sort-comp */
  @observable selectedView = 'publishing-manager'
  /* eslint-enable react/sort-comp */

  constructor(props) {
    super(props)
    this.state = {
      pageStore
    }

    this.buttonList = [
      {
        name: 'publishing-manager',
        component: PublicationManager
      },
      {
        name: 'copy-manager',
        component: CopyManager
      },
    ]
  }

  @observable selectedView = 'publishing-manager'

  isReady() {
    const { projectStore } = this.props

    return !projectStore.loading
      && projectStore.hasCurrent
      && projectStore.current.isComplete
      && (projectStore.current.pageTree.hasChildren || projectStore.current.specialPageTree.hasChildren)
  }

  @autobind @action
  handleViewSwitch({ target }) {
    this.selectedView = target.parentElement.value || target.id
  }

  renderNavbar() {
    return (<Navbar
      navbarStyle={`primary ${css.controlCenterTitlebar}`}
      ui={this.props.ui}
      pageStore={this.state.pageStore}
      id="controlCenter"
    />)
  }

  renderButtonBar() {
    return (<ButtonBar
      buttons={this.buttonList}
      onClick={this.handleViewSwitch}
      selected={this.selectedView}
      prefix="control-center.view-button."
    />)
  }

  renderBody() {

    const buttonListSpec = this.buttonList.find(button => button.name === this.selectedView)
    const FormComponent = buttonListSpec && buttonListSpec.component

    if (FormComponent) {
      return (<FormComponent
        projectStore={this.props.projectStore}
        pageStore={this.state.pageStore}
        router={this.props.router}
      />
      )
    }
    return (
      <div>Error in Tab Selection</div>
    )
  }

  render() {

    if (!this.isReady()) {
      return (<div
        className={classNames(`${css.controlCenter} grid-frame vertical`)}
      >
        <AppLoader>
          <FormattedMessage id="control-center.loading" />
        </AppLoader>
      </div>)
    }

    // It is important for re-renders if pageTree and specialTree are specifically passed
    return (<div
      className={classNames(`${css.controlCenter} grid-frame vertical`)}
    >
      {this.renderNavbar()}
      <div className="page grid-block align-center vertical">
        {this.renderButtonBar()}
        <div className="publishing-block grid-block">
          {this.renderBody()}
        </div>
      </div>
    </div>)
  }

}
