import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { autobind } from 'core-decorators'
import { formatMessage, FormattedMessage } from '../../translations'

import confirmDelete from '../containers/ConfirmDeleteDialog'
import Icon from '../../shared/icons'
import GenevaButton from '../../ui/components/GenevaButton'

require('../styles.scss')

@observer
export default class ReviewCenterSingleUser extends Component {
  static propTypes = {
    updateUser: PropTypes.func,
    user: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props)
    this.state = {
      isInviteBoxVisible: false,
      inviteMessage: '',
      isPasswordRequired: true,
    }
  }

  @autobind
  handleRemoveUser() {
    const { updateUser, user } = this.props

    confirmDelete({
      user,
    }).then(() => updateUser({
      id: user.id,
      action: 'destroy',
    })
    )
  }

  @autobind
  handleEmailNofificationChange({ target }) {
    const { updateUser, user } = this.props

    updateUser({
      id: user.id,
      sendNotification: target.checked,
    })
  }

  @autobind
  toggleInviteBox() {
    this.setState(prevState => ({
      isInviteBoxVisible: !prevState.isInviteBoxVisible,
    }))
  }

  @autobind
  handleInviteMessageChange({ target }) {
    this.setState({
      inviteMessage: target.value,
    })
  }

  @autobind
  handlePasswordRequiredChange({ target }) {
    this.setState({
      isPasswordRequired: target.checked,
    })
  }

  @autobind
  handleSendInvite() {
    const { updateUser, user } = this.props
    const { inviteMessage, isPasswordRequired } = this.state

    updateUser({
      id: user.id,
      message: inviteMessage,
      passwordNeeded: isPasswordRequired,
      isActive: true,
      sendNotification: true,
      target: user.name,
      targetId: user.id,
    })
  }

  renderActiveUserView(user) {
    return (
      <div className="rc-user-view">
        <div className="header">{user.name}</div>

        <div className="active-view">
          <input
            type="checkbox"
            id={user.id}
            defaultChecked={user.sendNotification}
            onChange={this.handleEmailNofificationChange}
          />

          <label htmlFor={user.id}>
            <FormattedMessage id="review-center.settings.email-notification" />
          </label>

          <GenevaButton
            onClick={this.handleRemoveUser}
            className="secondary button"
            id={user.id}
          >
            <FormattedMessage id="review-center.settings.remove-user" />
          </GenevaButton>
        </div>
      </div>
    )
  }

  renderInactiveUserView(user) {
    const { isInviteBoxVisible } = this.state

    if (isInviteBoxVisible) {
      return this.renderInviteBox(user)
    }

    return (
      <div className="rc-user-view">
        <div className="header">{user.name}</div>

        <div className="inactive-view">
          <FormattedMessage id="review-center.settings.not-invited" />

          <div onClick={this.toggleInviteBox} className="rc-user-invite">
            <FormattedMessage id="review-center.settings.invite-indicator" />
          </div>
        </div>
      </div>
    )
  }

  renderInviteBox(user) {
    const { isPasswordRequired, inviteMessage } = this.state

    const textarea = document.getElementById('inviteMessage')
    if (textarea && !textarea.oninput) {
      textarea.oninput = () => {
        textarea.style.height = '' /* Reset the height*/
        textarea.style.height = `${textarea.scrollHeight}px`
      }
    }

    return (
      <div className="rc-user-view">
        <div className="header" onClick={this.toggleInviteBox}>
          {user.name}
          <Icon name="ion-ios-arrow-up" />
        </div>

        <div className="text-area">
          <textarea
            id="inviteMessage"
            autoFocus
            value={inviteMessage}
            maxLength={191}
            onChange={this.handleInviteMessageChange}
            placeholder={formatMessage({
              id: 'review-center.chat.placeholder',
            })}
          />

          <hr />

          <input
            type="checkbox"
            id={user.id}
            defaultChecked={isPasswordRequired}
            onChange={this.handlePasswordRequiredChange}
          />

          <label htmlFor={user.id}>
            <FormattedMessage id="review-center.settings.password-required" />
          </label>

          <GenevaButton
            className="button small"
            onClick={this.handleSendInvite}
          >
            <FormattedMessage id="review-center.settings.send-invite" />
          </GenevaButton>
        </div>
      </div>
    )
  }

  render() {
    const { user } = this.props

    return user.isActive
      ? this.renderActiveUserView(user)
      : this.renderInactiveUserView(user)
  }
}
