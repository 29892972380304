import * as PropTypes from 'prop-types'
import React from 'react'
import { withRouter } from 'react-router'

import CreateAndInsertNewContentIntoWidgetCommand from './commands/CreateAndInsertNewContentIntoWidgetCommand.ts'
import CreateContentCommand from '../../../../shared/commands/CreateContentCommand.ts'
import InsertContentIntoWidgetCommand from '../../commands/InsertContentIntoWidgetCommand.ts'
import SelectContentInWidgetCommand from '../../commands/SelectContentInWidgetCommand.ts'
import EditItemCommand from '../../../../shared/commands/EditItemCommand.js'

import DistinctModelObserver from '../../../../shared/view-models/DistinctModelObserver.ts'

import createParentPropsInjector from '../../../../shared/utils/create-parent-props-injector'

import PageConnectedInsertArticleButton from './components/PageConnectedInsertArticleButton'

import viewModelConnector from '../../../../shared/lib/view-model'
import commandConnector from '../../../../shared/lib/command'

export default
function connectInsertArticleToPage(Target, parentProps: Object) {

  const injectParentProps = createParentPropsInjector(parentProps)

  function InsertArticleToPageConnector({
    // props that are NOT passed to the wrapped component
    // other props
    ...rest
  }) {

    return (<PageConnectedInsertArticleButton
      {...rest}
      Target={Target}
    />)

  }

  InsertArticleToPageConnector.propTypes = {
    disabled: PropTypes.bool,
    onGetContext: PropTypes.func,
    page: PropTypes.object.isRequired,
    router: PropTypes.shape({
      push: PropTypes.func
    }).isRequired,
  }

  const orderedInjectors = [
    withRouter,
    injectParentProps,
    viewModelConnector([{
      class: DistinctModelObserver,
      data: [{
        type: 'widget',
        id: DistinctModelObserver.fromContext('widgetId'),
        as: 'item'
      }],
      as: 'currentlyOpenWidget'
    }]),
    commandConnector({
      CreateAndInsertNewContentIntoWidgetCommand,
      CreateContentCommand,
      EditItemCommand,
      InsertContentIntoWidgetCommand,
      SelectContentInWidgetCommand,
    })
  ]

  // we have to inject from inside out, so reverse first
  return orderedInjectors.reverse()
    .reduce((component, injector) => {
      return injector(component)
    }, InsertArticleToPageConnector)

}
