import * as PropTypes from 'prop-types'
import React from 'react'
import { formatMessage, FormattedMessage } from '../../../translations'

const EmailLinkForm = ({
  onInputChanged,
  emailAddress,
  emailSubject,
}) => {
  return (
    <div className="grid-content">
      <div className="grid-block vertical">
        <label htmlFor="link-email-address">
          <FormattedMessage id="link-dialog.email-address" />
          <input
            type="email"
            name="emailAddress"
            id="link-email-address"
            placeholder={formatMessage({
              id: 'link-dialog.placeholder-email-address',
            })}
            value={emailAddress}
            onChange={onInputChanged}
            autoFocus
          />
        </label>
        <label htmlFor="link-email-subject">
          <FormattedMessage id="link-dialog.email-subject" />
          <input
            type="text"
            name="emailSubject"
            id="link-email-subject"
            placeholder={formatMessage({
              id: 'link-dialog.placeholder-email-subject',
            })}
            value={emailSubject}
            onChange={onInputChanged}
          />
        </label>
      </div>
    </div>
  )
}

EmailLinkForm.validate = (value, type) => {
  if (type === 'emailSubject') {
    // No further validation on emailSubject (no specs)
    return !!value
  }

  if (typeof value !== 'string') {
    value = value.emailAddress
  }
  const regexp = /\S+@\S+\.\S+/
  return !!value && regexp.test(value)
}

EmailLinkForm.propTypes = {
  onInputChanged: PropTypes.func,
  emailAddress: PropTypes.string,
  emailSubject: PropTypes.string,
}

export default EmailLinkForm
