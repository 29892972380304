import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import { withRouter } from 'react-router'

import { autobind } from 'core-decorators'

import commandConnector from '../lib/command'
import { dispatcher } from '../lib/command'

import InsertContentIntoPageCommand from '../commands/insert-content-into-page'

import CreateContentCommand from '../commands/create-content'

import EditItemCommand from '../commands/EditItemCommand.js'

export default function connectFileToolsToCommands(Target) {
  @dispatcher
  class ConnectedTarget extends Component {
    static propTypes = {
      value: PropTypes.string,
      onBeforeChangeFile: PropTypes.func,
      ui: PropTypes.object,
    };

    constructor(props) {
      super(props)
      this.state = {
        loading: 0,
        loadingMessage: '',
        disabled: false,
        value: props.value,
      }
    }

    componentWillReceiveProps(nextProps) {
      if (nextProps.value !== this.state.value) {
        this.setState({
          value: nextProps.value,
        })
      }
    }

    @autobind
    handlePageInsertContent(data) {
      this.setState({
        loading: (this.state.loading < 0 ? 0 : this.state.loading) + 1,
        // disable tools if the user intends to edit the article after insertion
        // TODO: this should disable everything!
        disabled: data.openForEditing,
      })

      let model = null

      // Ensures the content exists
      return new CreateContentCommand(data)
        .exec()
        .then((content) => {
          model = content
          let state = {
            loading: Math.max(0, this.state.loading - 1),
          }
          if (data.openForEditing) {
            state = {
              loadingMessage: 'content.opening-for-editing',
            }
          }

          this.setState(state)

          return new InsertContentIntoPageCommand({
            ...data,
            [data.type]: content,
          }).exec()
        })
        .then(() => {
          if (data.openForEditing) {
            this.setState({
              loading: Math.max(0, this.state.loading - 1),
              disabled: false,
            })
          }
          if (model && data.openForEditing) {
            this.context.dispatch(this.props.commands.EditItemCommand, {
              router: this.props.router,
              type: data.type,
              id: model.id,
            })
          }
        })
    }

    render() {
      return (
        <Target
          {...this.props}
          {...this.state}
          // Make sure the props disabled state always takes preference over the
          // internal state one.
          disabled={this.props.disabled || this.state.disabled}
          onInsertContent={this.handlePageInsertContent}
        />
      )
    }
  }

  return commandConnector({
    EditItemCommand,
  })(withRouter(ConnectedTarget))
}
