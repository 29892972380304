export default
function createProxy(obj) {
  return new Proxy(obj, {
    get(target, name) {
      if ((name in Array.prototype)) {
        return Array.prototype[name].bind(target.block)
      }
      if (!(name in target)) {
        return function missingFunction() {
          console.log(`Object has no method ${name}`)
          return 'Method not found!'
        }
      }
      return target[name]
    }
  })
}
