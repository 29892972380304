import * as PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'

import * as styles from './styles.scss'

export default function Content(props) {
  return (
    <div className={classNames(styles.content, props.className)}>
      {props.children}
    </div>
  )
}

Content.defaultProps = {
  className: '',
}

Content.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}
