import Emitter from './emitter'
export default class Command {
  constructor(...args) {
    const msg = this.validate(...args)
    this.events = new Emitter()
    if (msg) {
      this.throwError(msg)
    }

    if (this.exec) {
      const origExec = this.exec
      // make sure events get disposed after the execution of the command
      this.exec = (...execArgs) => {
        const retval = origExec.call(this, ...execArgs)
        let promise = Promise.resolve(true)
        if (retval && retval.then) {
          promise = retval
        }
        // promise.then((result) => {
        //   this.events.dispose()
        //   return result
        // })
        return promise
      }
    }
  }

  /**
   * @alias
   */
  emit(...args) {
    return this.trigger(...args)
  }

  trigger(...args) {
    this.events.emit(...args)
    return this
  }

  /**
   * @alias
   */
  subscribe(...args) {
    return this.on(...args)
  }

  on(...args) {
    return this.events.listen(...args)
  }

  validate(/* ...args */) {}

  throwError(message) {
    throw new Error(`${this.constructor.name}: ${message}`)
  }
}
