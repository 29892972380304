import {
  observable, autorun, extendObservable, toJS
} from 'mobx'
import { store as uiStore } from '../ui'
import { store as authStore } from '../auth'
import { apiClient } from '../shared/api'
import { deepGet } from '../shared/obj'
import { Store } from '../shared/store'

@apiClient
export default class CustomLocalStore extends Store {

  // Put all article list properties here. This regargds also state properties.

  @observable data = {}

  @observable currentLang = null

  asJSON() {
    return toJS(this.data)
  }

  constructor(opts = {}) {
    super(opts)

    // keep references that can be replaced with stubs/mocks in tests
    this.authStore = authStore
    this.uiStore = uiStore

    this.loadingLang = null

    // Whenever the contentLanguage in the uiStore changes (project switch)
    // or the user changes (login) reload the custom language files.
    // Don't reload if the new language is the same as the one we already have
    // in this store or are loading right now.
    autorun(() => {
      // listen for changes in the current user: aka login
      const currentUser = this.authStore.user && this.authStore.user.id
      if (
        currentUser
        && this.uiStore.contentLanguage
        && this.loadingLang !== this.uiStore.contentLanguage
        && this.currentLang !== this.uiStore.contentLanguage
      ) {

        this.loadingLang = this.uiStore.contentLanguage
        this.load(null, {
          params: {
            lang: this.uiStore.contentLanguage
          }
        }).then(() => {
          this.loadingLang = null
          this.currentLang = this.uiStore.contentLanguage
        }).catch((ex) => {
          this.loadingLang = null
          throw new Error(ex)
        })
      }
    })
  }

  load(id = null, opts = {}, data = null) {

    return this.dispatch('get', {
      path: 'customLocal',
      params: opts.params
    }).then((response) => {
      if (!response || !response.body || response.error) {
        throw new Error('error in article get')
      }
      const locals = response.body.data
      if (!locals || !Array.isArray(locals)) {
        throw new Error('CustomLocal: expected load result to be an array')
      }
      this.set(locals)
    })
  }


  set(data) {
    extendObservable(this.data, data.reduce((memo, entry) => {
      memo[entry.key] = entry
      return memo
    }, {}))
  }

  getTranslation(id, language = this.currentLanguage) {
    return deepGet(this.data, `${id}.i18n.${language}.label`)
  }

}
