import Command from '../command'
import { deepGet } from '../obj'
import CreateContentCommand from './create-content'

import { templateStore } from '../../template/reducers'
import { widgetTemplateStore } from '../../widgetTemplate/reducers'

const templateStores = {
  article: templateStore,
  widget: widgetTemplateStore
}

export default
class FillPageCommand extends Command {

  /**
   * Creates a new FillContentRendererCommand. This command handles the content
   * creation based and placement within a content renderer.
   * @param {Object}        data        - The spec of the content that should be created.
   * @param {Array<string>} data.items  - The items to create and insert. Each
   *                                      item is a string of the format
   *                                      `<type>/<template shortcut>`.
   * @param {Object}        page        - The page to insert into.
   */
  constructor(data: Object) {
    super(data)
    this.data = data
  }

  /**
   * Validates the given input data.
   * @method validate
   * @param  {Object} data - The data to be validated.
   * @return {String}      - The result of validation.
   */
  validate(data) {
    if (!data.page) {
      return `Expected \`data.page\` to be present. ${data.page} given.`
    }
    if (!data.items) {
      // eslint-disable-next-line max-len
      return `Expected \`data.items\` to be present. ${data.items} given.`
    }
    if (!data.position) {
      // eslint-disable-next-line max-len
      return `Expected \`data.position\` to be present. ${data.position} given.`
    }

    return null
  }


  /**
   * Determines whether an item spec is valid.
   * @method isItemSpecValid
   * @param  {array}        itemSpec - The item spec to validate.
   * @return {Boolean}               - Validation result.
   */
  isItemSpecValid(itemSpec) {
    return itemSpec.length >= 2
    && (itemSpec[0] === 'article' || itemSpec[0] === 'widget')
    && itemSpec[1].length > 0
  }


  /**
   * Executes the create command. Determines the template that needs to be
   * created and creates it. Then either uses an existing content, or creates
   * the content anew. Finally returns a promise that will resolve with the
   * newly created content.
   * @method exec
   * @return {Promise<Object>} - The promise resolving with the created content
   */
  exec() {

    const itemSpecs = this.getItemSpecs(this.data.items, this.data.prefilledContent || {})

    // Check the content template store for the existance of the needed
    // template. If it's not there, place it in a list of templates that
    // need to be loaded in the following step
    const templatesToLoad = this.getTemplatesToLoad(itemSpecs)


    return this.loadTemplates(templatesToLoad)
      .then(() => this.createItems(itemSpecs))
      .then(createdItems => this.insertItems(createdItems))

  }

  getItemSpecs(items, prefilledContent) {
    const method = 'FillContentRendererCommand#createItemSpecs'
    return items.map((item) => {
      return item.split('/').concat(prefilledContent[item])
    })
      .filter((itemSpec) => {
        const isValid = this.isItemSpecValid(itemSpec)
        if (!isValid) {
          console.warn(
            `${method}: Invalid item spec. ItemSpec should be`
          + `defined as <type>/<template>. ${JSON.stringify(itemSpec)} given. `
          + 'Skipping this item.'
          )
        }
        return isValid
      })
      .map(([type, template, prefilledSpecs]) => ({ type, template, prefilledSpecs }))
  }

  getTemplatesToLoad(itemSpecs) {
    return itemSpecs.filter(({ type, template }) => {
      return !templateStores[type].collection
        .find(tmpl => (tmpl.shortcut === template))
    })
      .reduce((memo, { type, template }) => {
        if (!(type in memo)) {
          memo[type] = []
        }
        memo[type].push(template)
        return memo
      }, {})
  }

  loadTemplates(templatesToLoad) {
    return Promise.all(
      // load all templates that are not yet in the local store
      Object.keys(templatesToLoad).map((type) => {
        return templateStores[type].load(null, {
          filter: {
            shortcut: templatesToLoad[type]
          }
        })
      })
    )

  }

  createItems(itemSpecs) {
    return Promise.all(
      itemSpecs.map(itemSpec => this.applyTemplateToItemSpec(itemSpec))
        .filter(itemSpec => itemSpec.template)
        .map((itemSpec) => {
          return new CreateContentCommand(itemSpec).exec()
            .then((article) => {
              if (itemSpec.prefilledSpecs) {
                Object.keys(itemSpec.prefilledSpecs).forEach((key) => {
                  article.phAccessor.set(key, 'value', {
                    value: this.getContentRef(itemSpec.prefilledSpecs[key]),
                    type: 'text'
                  })
                  // if the article headline is update, then the default article
                  // name needs to also be
                  if (itemSpec.prefilledSpecs[key] === 'page.name') {
                    article.set({ name: this.getContentRef(itemSpec.prefilledSpecs[key]) })
                  }
                })
              }
              return article
            })
            .catch((ex) => {
              console.warn(
                'ContentRenderer#prefill: content createion caused an error. '
            + 'This item will be skipped. The error was: ', ex
              )
              return null
            })
        })
    )
  }

  // Handles dynamic property access.
  // e.g. 'page.name'
  getContentRef(ref) {
    return deepGet(this.data, ref) || ref
  }

  applyTemplateToItemSpec(itemSpec) {
    // Find the template with the current items template shortcut in the
    // store and use that as template spec
    const match = templateStores[itemSpec.type].collection
      .find(({ shortcut }) => (shortcut === itemSpec.template))

    if (!itemSpec.template && itemSpec.template.id) {
      console.warn(
        'ContentRenderer#prefill: invalid template definition for '
        + `template ${itemSpec.template}. `
      )
      itemSpec.template = null
    }
    else {
      itemSpec.template = {
        id: match.id,
        template: match.shortcut,
      }
    }

    return itemSpec
  }

  insertItems(items) {
    // start a transaction for performance and to ensure returning data from
    // the server won't destroy our data, that we set in the meantime
    this.data.page.startTransaction()

    let index = 0
    return Promise.all(items.filter(item => !!item).map((item) => {

      const insertionPosition = {
        ...this.data.position,
        index
      }

      return this.data.page.insertContent(item, item.contentType, insertionPosition)
        .then(() => {
          index += 1
          return item
        })
        .catch((ex) => {
          console.error(
            'ContentRenderer#prefill: Inserting the item into the page '
          + 'caused an error: ', ex
          )
          return null
        })

    }))
      .then((insertedItems) => {
        if (insertedItems.length) {
          this.data.page.endTransaction()
        }
        return insertedItems.filter(item => !!item)
      })
      .catch(() => {
      // just to be sace that transactions are always resolved again
        this.data.page.endTransaction()
      })

  }
}
