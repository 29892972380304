import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { autobind } from 'core-decorators'
import { confirmable } from 'react-confirm'
import classNames from 'classnames'
import { formatMessage, FormattedMessage } from '../../../translations'

import { Dialog } from '../../../shared/components/Dialog'
import ContentLoadingBox from '../../../shared/components/ContentLoadingBox'
import Icon from '../../../shared/icons'
import { deepGet } from '../../../shared/obj'
import GenevaButton from '../../../ui/components/GenevaButton'

import PageModel from '../../../page/model'

const css = /* typeof window === 'undefined' ? {} : */require('../../styles.scss')


@confirmable
class CenshareRefresh extends Component {

  static propTypes = {
    show: PropTypes.bool,            // indicates if the dialog is shown or not.
    proceed: PropTypes.func,         // call to close the dialog with promise resolved.
    cancel: PropTypes.func,          // call to close the dialog with promise rejected.
    dismiss: PropTypes.func,         // call to only close the dialog.
    confirmation: PropTypes.string,
    options: PropTypes.shape({
      model: PropTypes.object,
      censhareStore: PropTypes.object.isRequired
    })
  }

  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      displayState: '',
      changedElementCount: 0,
      changedAssets: [],
    }

    this.load()
  }

  load() {
    const { model, censhareStore } = this.props.options
    const data = { preview: true }

    const opts = {
      id: model.id,
      action: model instanceof PageModel ? 'page' : 'article',
      method: 'put'
    }

    return censhareStore.save(null, data, opts)
      .then((res) => {
        const changedAssets = deepGet(res, 'body.data.changedAssets') || []

        this.setState({
          isLoading: false,
          displayState: changedAssets.length > 0 ? 'update-display' : 'no-change-display',
          changedAssets
        })
      })
      .catch((err) => {
        console.error(err)
      })
  }

  @autobind
  updateContent() {
    this.setState({
      isLoading: true
    })

    const { model, censhareStore } = this.props.options
    const data = {
      preview: false,
      assetsToUpdate: this.state.changedAssets.map(el => el.assetId)
    }

    const opts = {
      id: model.id,
      action: model instanceof PageModel ? 'page' : 'article',
      method: 'put'
    }

    return censhareStore.save(null, data, opts)
      .then((res) => {
        const newModel = deepGet(res, 'body.data')

        if (newModel) {
          model.set(newModel)

          this.setState({
            isLoading: false,
            displayState: 'success-display'
          })
        }

      })
      .catch((err) => {
        console.error(err)
      })
  }

  @autobind
  getFocus() {
    return this.focus
  }

  @autobind
  handleRef(el) {
    if (el !== null) {
      this.focus = el
    }
  }

  @autobind
  handleClose() {
    this.props.dismiss()
  }

  @autobind
  handleItemRemove(asset) {
    const { changedAssets } = this.state

    if (changedAssets.length < 2) {
      // Bail out if it would be the last item
      return
    }
    const index = changedAssets.indexOf(asset)

    changedAssets.splice(index, 1)

    this.setState({
      changedAssets
    })

    return
  }

  renderAsset(asset) {
    const { changedAssets } = this.state

    const imageCount = asset.changedImagesCount > 0 ? `(${asset.changedImagesCount})` : ''
    const imagesText = formatMessage({ id: 'censhare.refresh-modal.images' }, { count: imageCount })

    return (<div className="asset-item">
      <div className="asset-item-row">
        <span>{asset.name}</span>
        <div className={classNames('asset-item-remove', changedAssets.length === 1 ? 'inactive' : '')} onClick={() => this.handleItemRemove(asset)}>
          <Icon name="ion ion-ios-close-empty" />
        </div>
      </div>
      <div className="asset-item-row">
        {imageCount
          ? <Icon name="ion ion-ios-checkmark" />
          : <Icon name="ion ion-ios-minus" />}
        <span className={classNames('info-text', imageCount ? 'active' : 'inactive')}>
          {imagesText}
        </span>
      </div>
    </div>)
  }

  renderMessage(displayState) {
    return (
      <div className="update-messages">
        {displayState === 'no-change-display'
          ? <Icon name="ion ion-ios-information" />
          : <Icon name="ion ion-ios-checkmark" />}

        {displayState === 'no-change-display'
          ? <FormattedMessage id="censhare.refresh-modal.update-info-no-change" />
          : <FormattedMessage id="censhare.refresh-modal.update-info-success" />}
      </div>
    )
  }

  renderContent() {
    const { displayState, changedAssets } = this.state

    if (displayState === 'no-change-display' || displayState === 'success-display') {
      return this.renderMessage(displayState)
    }

    // only if displayState === 'update-display'
    return (<div className="content-wrapper">
      <div className="update-info">
        <span>
          {formatMessage(
            { id: 'censhare.refresh-modal.update-info' },
            { count: changedAssets.length }
          )}
        </span>
      </div>
      {changedAssets.map((asset) => {
        return this.renderAsset(asset)
      })}
    </div>)
  }

  renderLoader() {
    return (<ContentLoadingBox
      spinner
      message={{
        id: 'placeholder-empty'
      }}
    />)
  }

  @autobind
  renderFooter() {
    const { displayState, isLoading } = this.state

    if (isLoading || displayState !== 'update-display') {
      return (
        <div className="grid-content text-right">
          <GenevaButton
            type="button"
            className="small button"
            onClick={this.handleClose}
            ref={this.handleRef}
          >
            <FormattedMessage id="simple-dialog.close" />
          </GenevaButton>
        </div>
      )
    }

    return (
      <div className="grid-content text-right">
        <GenevaButton
          type="button"
          className="small button"
          onClick={this.handleClose}
          ref={this.handleRef}
        >
          <FormattedMessage id="simple-dialog.cancel" />
        </GenevaButton>

        <GenevaButton
          type="button"
          className="small primary button"
          onClick={this.updateContent}
          ref={this.handleRef}
        >
          <FormattedMessage id="simple-dialog.confirm" />
        </GenevaButton>
      </div>
    )
  }

  render() {
    const { isLoading } = this.state

    return (
      <Dialog
        className={css.censhareRefresh}
        isOpen={this.props.show}
        renderFooter={this.renderFooter}
        title={formatMessage({ id: 'page.censhare-refresh' })}
        focusEl={this.getFocus}
      >
        <div className="form-main">
          {isLoading
            ? this.renderLoader()
            : this.renderContent()}
        </div>
      </Dialog>
    )
  }
}

export default CenshareRefresh
