import { observable, computed } from 'mobx'
import moment from 'moment'
import { review as config } from '../config'

import Communicator from '../Communicator'
import communicate from '../shared/decorators/communicator'

import { apiClient } from '../shared/api'
import { inspector } from '../shared/decorators/inspector'
import { Store } from '../shared/store'
import Review from './model'
import {
  addToCollection,
  getUserNameById
} from '../shared/utils'

@inspector('save', 'review.saving')
@inspector('load', 'review.loading')
@apiClient
@communicate(Communicator.getInstance())
export default
class ReviewStore
  extends Store {

  // Put all project list properties here. This regards also state properties.

  // project collection
  @observable collection = []

  @observable current = {}

  @observable usersList = []

  @computed get hasCurrent() {
    return !!this.current.id
  }

  @observable loading = false

  // to see the store name when in production mode - Uglifyjs
  static storeName = 'ReviewStore'

  constructor(opts = {}) {

    super(opts)

    this.Model = Review

    this.initCommunicator()
  }

  parse(data) {
    if (data.data) {
      data = data.data
    }
    return data
  }

  load(id = null, opts = {}) {
    return this.dispatch('get', {
      path: `review/${id}`
    }).then((response) => {
      if (!response || !response.body || response.error) {
        throw new Error('error in page get')
      }
      const loadResult = response.body.data

      this.current = addToCollection(this, this.collection, loadResult, Review)
      // Set current
      return this.current
    })
  }

  loadUsers() {
    return this.dispatch('get', {
      path: 'review/users'
    }).then((result) => {
      if (result.body && result.body.data && Array.isArray(result.body.data)) {
        this.userList = result.body.data
        return this.userList
      }
      throw new Error('Unable to retreive Review User Lsit')
    })
  }

  /**
   * Adds a comment from the communicator directly into the UI
   * @param {*} data - { userId, comment, action?, screenshots? }
   */
  addTempComment(data) {
    const length = this.current.comments[0] && this.current.comments[0].id + 1 || 1
    const userName = data.name
      ? `${data.name.firstname} ${data.name.lastname}`
      : getUserNameById(data.userId) || 'Unknown'

    this.current.comments.unshift({
      action: data.action ? data.action * 1 : 0,
      createdAt: moment(),
      createdBy: data.userId,
      id: length,
      name: userName,
      text: data.text,
      screenshots: JSON.parse(data.screenshots),
      target: data.target
    })
  }

  canLoad() {
    return !this.loading && this.hasMoreItems
  }

}
