import { observable, asMap, computed, transaction } from 'mobx'
import { autobind } from 'core-decorators'
import { guid } from './utils'

export default class ChatStore {
  @observable chats = asMap({})

  @computed get numNew() {
    return this.chats.values()
      .filter(chat => chat.isNew)
    // ignore currently not online users
      .filter((chat) => {
        const lastMessage = chat.messages
      && chat.messages.length
      && chat.messages[chat.messages.length - 1]

        return lastMessage.sender
        && !!this.communicator.getUserFromList(lastMessage.sender.id)
      })
      .length
  }

  constructor(communicator) {
    this.communicator = communicator

    this.localData = {}
    this.restoreFromStorage()
  }

  restoreFromStorage() {

    if (typeof localStorage === 'undefined') {
      return
    }

    let localData = {}
    try {

      localData = JSON.parse(localStorage.ChatStore || '{}')
      Object.keys(localData).forEach((userId) => {
        const userData = localData[userId]
        Object.keys(userData).forEach((chatId) => {
          // only keep the last 100 messages
          userData[chatId].messages = userData[chatId].messages.slice(0, 100)
        })
      })
    }
    catch (ex) {
      console.error(ex)
    }

    this.localData = localData
  }

  handle(data) {
    if (data.action === 'message') {
      if (data.info === 'markAsSeen') {
        this.markChatAsSeen(data)
      }
      else {
        this.addMessage(data)
      }
    }
  }

  setUser(user) {
    this.user = user

    this.loadDataForCurrentUser()
  }

  loadDataForCurrentUser() {

    if (this.user.id in this.localData) {
      const chatData = this.localData[this.user.id] || {}
      transaction(() => {
        Object.keys(chatData).forEach((id) => {
          this.chats.set(id, chatData[id])
        })
      })
    }

  }

  saveDataForCurrentUser() {

    this.localData[this.user.id] = this.chats.toJSON()
    localStorage.ChatStore = JSON.stringify(this.localData)

  }


  addMessage(data) {

    const isMe = data.user.id === this.user.id
    const sender = {
      ...data.user,
      isMe
    }
    const recipient = data.recipient
    const message = data.value

    data.date = data.date || new Date().getTime()

    const id = this.createChatId(sender, recipient)

    if (!this.chats.has(id)) {
      this.chats.set(id, {
        id,
        recipient,
        messages: [],
        isNew: true,
        isSeen: false
      })
    }

    const chat = this.chats.get(id)

    chat.isNew = !isMe
    chat.isSeen = false
    chat.messages.push({
      sender,
      message,
      id: data.mid || guid()
    })


    this.saveDataForCurrentUser()

  }

  markChatAsOld(chat) {

    const chatObject = this.getChat(chat)
    const chatId = this.createChatId(chat)
    chatObject.isNew = false

    try {
      const lastMessage = (chatObject.messages || []).slice(-1)[0]
      if (lastMessage && lastMessage.sender.id !== this.user.id) {
        this.communicator.action('message', {
          info: 'markAsSeen',
          chatId,
          recipient: lastMessage.sender
        })
      }
    }
    catch (ex) {
      console.error(ex)
    }

  }

  markChatAsSeen(chat) {
    if (this.chats.has(chat.chatId)) {
      this.chats.get(chat.chatId).isSeen = true
    }
  }

  @autobind
  createChatId(user1, user2 = null) {
    user2 = user2 || this.user
    return [user1.id, user2.id].sort().join(':')
  }

  @autobind
  getChat(chat) {
    const id = this.createChatId(chat)
    if (!this.chats.has(id)) {
      // this has to return null, as it might get called from a render function
      // which should not change state
      return {
        messages: null,
        isNew: false,
        isSeen: false
      }
    }
    return this.chats.get(id)
  }

}
