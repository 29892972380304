import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import { confirmable } from 'react-confirm'
import { autobind } from 'core-decorators'

import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { formatMessage, FormattedMessage } from '../../../translations'

import GenevaButton from '../../../ui/components/GenevaButton'

import i18n from '../../utils/i18n'
import { getAvailableForeColors } from '../../utils'
import { Dialog } from '.'
// import { getForeColorProps } from '../../../shared/fore-color-helper'

const css = /* typeof window === 'undefined' ? {} : */ require('./styles.scss')

const FORECOLOR_DIALOG_SMALL_SIZE = 480

@confirmable
@observer
class ForeColorDialog extends Component {
  /* eslint-disable react/sort-comp */
  /* as of https://github.com/yannickcr/eslint-plugin-react/issues/100 */
  @observable color = 'none';

  @observable status = null;
  /* eslint-enable react/sort-comp */

  static propTypes = {
    show: PropTypes.bool, // indicates if the dialog is shown or not.
    proceed: PropTypes.func, // call to close the dialog with promise resolved.
    cancel: PropTypes.func, // call to close the dialog with promise rejected.
    dismiss: PropTypes.func, // call to only close the dialog.
    confirmation: PropTypes.string, // arguments of your confirm function
    options: PropTypes.object, // arguments of your confirm function
  };

  constructor(props) {
    super(props)

    const { modal } = props.options
    // normalize the color value:
    // remove whitespaces
    this.color = this.normalizeColorValue(modal.color)
    this.status = modal.status
  }

  getResultData() {
    return {
      color: this.color,
      status: this.status,
    }
  }

  @autobind
  getFocus() {
    return this.focus
  }

  normalizeColorValue(color) {
    return color.replace(/,\s*/g, ', ')
  }

  @autobind
  handleCommit(e) {
    if (e && e.preventDefault) {
      e.preventDefault()
    }
    this.status = 'committed'
    return this.props.proceed(this.getResultData())
  }

  @autobind
  handleCancel() {
    this.status = 'cancelled'
    return this.props.proceed(this.getResultData())
  }

  @autobind
  handleChange({ target: { type, value, name, checked } }) {
    const val
    // eslint-disable-next-line no-nested-ternary
      = type === 'checkbox' ? checked : type === 'number' ? value * 1 : value

    this[name] = val
  }

  @autobind
  handleAfterOpen() {
    this.status = 'editing'
  }

  @autobind
  handleAutoFocus({ target }) {
    this.focus = target
  }

  @autobind
  renderFooter() {
    return (
      <div className="grid-content text-right">
        <GenevaButton
          type="button"
          className="small button float"
          onClick={this.handleCancel}
        >
          <FormattedMessage id="fore-color-dialog.cancel" />
        </GenevaButton>

        <GenevaButton
          type="submit"
          className="small primary button"
          onClick={this.handleCommit}
        >
          <FormattedMessage id="fore-color-dialog.ok" />
        </GenevaButton>
      </div>
    )
  }

  render() {
    return (
      <Dialog
        className={css.foreColorDialog}
        overlayClassName={css.foreColorDialogOverlay}
        isOpen={this.props.show}
        onAfterOpen={this.handleAfterOpen}
        onRequestClose={this.props.dismiss}
        onChange={this.handleChange}
        modalStyle={{
          content: {
            width: FORECOLOR_DIALOG_SMALL_SIZE,
          },
        }}
        renderFooter={this.renderFooter}
        title={formatMessage({ id: 'fore-color-dialog.font-color' })}
        focusEl={this.getFocus}
      >
        {getAvailableForeColors().map((color) => {
          const value = this.normalizeColorValue(color.value)
          return (
            <label
              key={color.value}
              htmlFor={`radio-color-${color.name}`}
              className={css.colorItemLabel}
            >
              <input
                type="radio"
                name="color"
                value={value}
                id={`radio-color-${color.name}`}
                checked={value === this.color}
              />
              <span
                className={css.colorItem}
                title={color.label}
                style={{
                  backgroundColor: value,
                }}
              />
              <span>{i18n(color.translations, 'name') || color.label}</span>
            </label>
          )
        })}
      </Dialog>
    )
  }
}

export default ForeColorDialog
