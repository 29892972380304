exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".src-shared-components-Alert-styles__alertDialog___1xWnF{max-width:800px;overflow-wrap:break-word}.src-shared-components-Alert-styles__alertDialog___1xWnF .confirmation{padding-bottom:2px}\n", "", {"version":3,"sources":["/builds/GENEVA/wm/frontend/src/shared/components/Alert/styles.scss"],"names":[],"mappings":"AACA,yDACE,gBACA,wBAAyB,CAF3B,uEAMM,kBAAmB,CACpB","file":"styles.scss","sourcesContent":["\n.alertDialog {\n  max-width: 800px;\n  overflow-wrap: break-word;\n\n  :global {\n    .confirmation {\n      padding-bottom: 2px;\n    }\n  }\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"alertDialog": "src-shared-components-Alert-styles__alertDialog___1xWnF"
};