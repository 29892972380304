import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import { confirmable } from 'react-confirm'
import { formatMessage, FormattedMessage } from '../../translations'

import { Dialog } from '../../shared/components/Dialog'

@confirmable
class ConfirmDelete extends Component {
  static propTypes = {
    show: PropTypes.bool, // indicates if the dialog is shown or not.
    proceed: PropTypes.func, // call to close the dialog with promise resolved.
    cancel: PropTypes.func, // call to close the dialog with promise rejected.
    dismiss: PropTypes.func, // call to only close the dialog.
    confirmation: PropTypes.string, // arguments of your confirm function
    options: PropTypes.object, // arguments of your confirm function
  };

  render() {
    const {
      show,
      proceed,
      dismiss,
      options: { user },
    } = this.props

    return (
      <Dialog
        className=""
        overlayClassName=""
        isOpen={show}
        onAfterOpen={this.handleAfterOpen}
        onRequestClose={this.handleRequestClose}
        title={formatMessage({
          id: 'review-center.dialog.delete-confirm-title',
        })}
        onCancel={dismiss}
        onCommit={proceed}
      >
        <FormattedMessage
          id="review-center.dialog.delete-confirm-text"
          values={{
            name: user.name,
          }}
        />
      </Dialog>
    )
  }
}
export default ConfirmDelete
