/**
 * @typedef {Object} ButtonSpec
 * @property {String} name - The name of the button.
 * @property {String} icon - The icon of the button.
 * @property {String} label - A string to use as label (instead of `icon`)
 * @property {Boolean} disabled - Whether or not the button should be disabled.
 * @property {Boolean} disabled - Whether or not the button should be active.
 */
import { getAvailableTableAppearance } from '../shared/utils'
import config from '../config'

const getButtons = () => {
  const tableAppearances = getAvailableTableAppearance(config)

  /**
   * Spec for buttons in the editor toolbar.
   * @type {Object}
   */
  const buttons = [
    /** @type {ButtonSpec} */
    {
      cmd: 'bold',
      name: 'bold',
      icon: 'fa-bold',
    },

    /** @type {ButtonSpec} */
    {
      cmd: 'italic',
      name: 'italic',
      icon: 'fa-italic',
    },

    /** @type {ButtonSpec} */
    {
      cmd: 'underline',
      name: 'underline',
      icon: 'fa-underline',
    },

    /** @type {ButtonSpec} */
    {
      cmd: 'strikeThrough',
      name: 'strikeThrough',
      icon: 'fa-strikethrough',
    },

    'separator',

    /** @type {ButtonSpec} */
    {
      cmd: 'foreColor',
      name: 'foreColor',
      label: 'A',
    },

    'separator',

    /** @type {ButtonSpec} */
    {
      cmd: 'fontStyle',
      name: 'fontStyle',
      label: 'F'
    },

    'separator',

    /** @type {ButtonSpec} */
    {
      cmd: 'indent',
      name: 'indent',
      icon: 'fa-indent',
    },

    /** @type {ButtonSpec} */
    {
      cmd: 'outdent',
      name: 'outdent',
      icon: 'fa-outdent',
    },

    'separator',

    /** @type {ButtonSpec} */
    {
      cmd: 'superscript',
      name: 'superscript',
      icon: 'fa-superscript',
    },

    /** @type {ButtonSpec} */
    {
      cmd: 'subscript',
      name: 'subscript',
      icon: 'fa-subscript',
    },

    'separator',

    /** @type {ButtonSpec} */
    /*
    {
      cmd: 'justifyLeft',
      name: 'justifyLeft',
      icon: 'fa-align-left'
    },
    */
    /** @type {ButtonSpec} */
    /*
    {
      cmd: 'justifyCenter',
      name: 'justifyCenter',
      icon: 'fa-align-center'
    },
    */
    /** @type {ButtonSpec} */
    /*

    {
      cmd: 'justifyRight',
      name: 'justifyRight',
      icon: 'fa-align-right'
    },
    */
    /** @type {ButtonSpec} */
    /*
    {
      cmd: 'justifyFull',
      name: 'justifyFull',
      icon: 'fa-align-justify'
    },
    */
    'separator',

    /** @type {ButtonSpec} */
    {
      cmd: 'linkPrompt',
      name: 'linkPrompt',
      icon: 'fa-link',
    },

    /** @type {ButtonSpec} */
    {
      cmd: 'unlink',
      name: 'unlink',
      icon: 'fa-unlink',
    },

    'separator',

    /** @type {ButtonSpec} */
    {
      cmd: 'insertTable',
      name: 'insertTable',
      icon: 'fa-table',
      subCommands: [
        {
          cmd: 'insertTableRow',
          name: 'insertTableRow',
        },
        {
          cmd: 'insertTableColumn',
          name: 'insertTableColumn',
        },
        'separator',
        {
          cmd: 'removeTableRow',
          name: 'removeTableRow',
        },
        {
          cmd: 'removeTableColumn',
          name: 'removeTableColumn',
        },
        'separator',
        {
          cmd: 'changeAppearance',
          name: 'changeAppearance',
          values: tableAppearances || [
            {
              value: 'plain',
              // TODO: would be nice to move these language values out of here
              i18n: {
                de: {
                  name: 'Kein Rahmen',
                },
                en: {
                  name: 'No border',
                },
              },
            },
          ],
        },
        'separator',
        {
          cmd: 'changeLayoutMode',
          name: 'changeLayoutMode',
          values: [
            {
              value: 'fixed',
              name: 'fixed',
            },
            {
              value: 'responsive',
              name: 'responsive',
            },
          ],
        },
      ],
    },

    'separator',

    /** @type {ButtonSpec} */
    {
      cmd: 'insertOrderedList',
      name: 'insertOrderedList',
      icon: 'fa-list-ol',
    },

    /** @type {ButtonSpec} */
    {
      cmd: 'insertUnorderedList',
      name: 'insertUnorderedList',
      icon: 'fa-list-ul',
    },

    'separator',

    /** @type {ButtonSpec} */
    {
      cmd: 'blockquote',
      name: 'blockquote',
      icon: 'fa-quote-right',
    },

    'separator',

    /** @type {ButtonSpec} */
    {
      cmd: 'footnote',
      name: 'footnote',
    },

    'separator',

    /** @type {ButtonSpec} */
    {
      cmd: 'removeLinebreak',
      name: 'removeLinebreak',
      icon: 'fa-exchange',
    },

    'separator',

    /** @type {ButtonSpec} */
    {
      cmd: 'cleanup',
      name: 'cleanup',
      icon: 'fa-paint-brush',
    },

    'separator',

    /** @type {ButtonSpec} */
    {
      cmd: 'removeFormat',
      name: 'removeFormat',
      icon: 'fa-eraser',
    },

    'separator',

    /** @type {ButtonSpec} */
    {
      cmd: 'undo',
      name: 'undo',
      icon: 'fa-undo',
    },

    /** @type {ButtonSpec} */
    {
      cmd: 'redo',
      name: 'redo',
      icon: 'fa-repeat',
    },

    'separator',

    /** @type {ButtonSpec} */
    {
      cmd: 'note',
      name: 'note',
      icon: 'fa-sticky-note-o',
    },

    /** @type {ButtonSpec} */
    {
      cmd: 'noteCollapseToggle',
      name: 'noteCollapseToggle',
      icon: 'fa-sticky-note-o',
    },

    /** @type {ButtonSpec} */
    {
      cmd: 'noteCollapseToggleAll',
      name: 'noteCollapseToggleAll',
      icon: 'fa-sticky-note-o',
    },

    /** @type {ButtonSpec} */
    {
      cmd: 'flag',
      name: 'flag',
      icon: 'fa-flag',
    },

    /** @type {ButtonSpec} */
    {
      cmd: 'flagCollapseToggle',
      name: 'flagCollapseToggle',
      icon: 'fa-flag',
    },

    /** @type {ButtonSpec} */
    {
      cmd: 'flagCollapseToggleAll',
      name: 'flagCollapseToggleAll',
      icon: 'fa-flag',
    },

    /** @type {ButtonSpec} */
    {
      cmd: 'correct',
      name: 'correct',
      icon: 'fa-check',
    },

    /** @type {ButtonSpec} */
    {
      cmd: 'correctCollapseToggle',
      name: 'correctCollapseToggle',
      icon: 'fa-check',
    },

    /** @type {ButtonSpec} */
    {
      cmd: 'correctCollapseToggleAll',
      name: 'correctCollapseToggleAll',
      icon: 'fa-check',
    },
  ]

  const applyDefaultState = (editorButtons, parentCmd = null) => {
    editorButtons.forEach((button) => {
      if (typeof button !== 'string') {
        if (parentCmd) {
          button.cmd = `${parentCmd}.${button.cmd}`
          button.name = `${parentCmd}.${button.name}`
        }
        button.visible = 'visible' in button ? button.visible : true
        button.disabled = 'disabled' in button ? button.disabled : true
        button.active = 'active' in button ? button.active : false
        if (button.subCommands) {
          applyDefaultState(button.subCommands, button.cmd)
        }
      }
    })
  }

  applyDefaultState(buttons)

  return buttons
}

export default getButtons
