import { observable, computed } from 'mobx'
import Model from '../shared/model'

export default
class CenshareArticle extends Model {

  @observable id = ''

  @observable connected = false

  @computed get asJSON() {
    return this.getJSON()
  }

  @computed get isConnected() {
    return this.connected
  }

  getJSON() {
    return {
      id: this.id
    }
  }
}
