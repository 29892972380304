
import * as PropTypes from 'prop-types'

import React, { Component } from 'react'
import { toJS } from 'mobx'
import { observer } from 'mobx-react'
import { autobind } from 'core-decorators'

import TransformationInfo, {
  TransformationState,
} from '../components/TransformationInfo'

const css = /* typeof window === 'undefined' ? {} : */ require('./Resizable/styles.scss')

export default function connectResizatableToContext(
  Resizable,
  parentProps,
  contextStore
) {
  if (!contextStore) {
    throw new Error(
      'To connect an Resizable with a context, '
        + '`connectResizable` expects to get passed a `contextStore` argument '
        + 'as third parameter.'
    )
  }

  if (!parentProps.articlePlaceholders) {
    throw new Error(
      '`connectResizatableToContext` '
        + "requires an articlePlaceholders Object in it's "
        + 'parentProps'
    )
  }

  @observer
  class ConnectedResizable extends Component {
    static propTypes = {
      pid: PropTypes.string.isRequired,
      value: PropTypes.array,
      style: PropTypes.object,
      children: PropTypes.node,
      env: PropTypes.object,
      className: PropTypes.string,
    };

    static contextTypes = {
      transformationState: PropTypes.shape({
        isActive: PropTypes.bool,
        setState: PropTypes.func,
      }),
    };

    constructor(props, context) {
      super(props, context)
      // if anything around already created transformation tools, use those,
      // otherwise create new tools
      this.transformationState
        = context && context.transformationState
          ? context.transformationState
          : new TransformationState()
    }

    getDefaultValue() {
      return {
        width: 'auto',
        height: 'auto',
      }
    }

    isCM() {
      return parentProps.env.CM
    }

    @autobind
    handleChange({ target: { value } }) {
      const placeholder = parentProps.articlePlaceholders

      placeholder.set(this.props.pid, 'value', {
        type: 'keyValue',
        value: {
          // this allows pid reusal for several props
          ...placeholder.get(this.props.pid, 'value'),
          ...value,
        },
      })
    }

    @autobind
    handleResizeStart(...args) {
      if (this.context && this.context.transformationState) {
        this.context.transformationState.setState({ isActive: true })
      }
      if (this.props.onResizeStart) {
        this.props.onResizeStart(...args)
      }
    }

    @autobind
    handleResizeEnd(...args) {
      if (this.context && this.context.transformationState) {
        this.context.transformationState.setState({ isActive: false })
      }
      if (this.props.onResizeEnd) {
        this.props.onResizeEnd(...args)
      }
    }

    validateValue(value) {
      if (
        value
        && Object.prototype.toString.call(value) === '[object Object]'
        && (value.width || value.height)
      ) {
        return value
      }
      return null
    }

    render() {
      const env = toJS(parentProps.env)

      const value
        = this.props.value
        || this.validateValue(
          parentProps.articlePlaceholders.get(this.props.pid) || null
        )
        || this.getDefaultValue()

      const resizeEvents = {}

      resizeEvents.onResizeStart = this.handleResizeStart
      resizeEvents.onResizeEnd = this.handleResizeEnd

      if (this.isCM()) {
        return (
          <Resizable
            css={css}
            {...this.props}
            value={value}
            env={env}
            onChange={this.handleChange}
            {...resizeEvents}
          >
            {this.props.children}
          </Resizable>
        )
      }

      return (
        <div
          style={{
            ...this.props.style,
            ...value,
          }}
          className={this.props.className}
        >
          {this.props.children}
        </div>
      )
    }
  }

  ConnectedResizable.TransformationInfo = TransformationInfo

  return ConnectedResizable
}
