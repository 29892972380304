import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { autobind } from 'core-decorators'
import { confirmable } from 'react-confirm'
import classNames from 'classnames'
import PerfectScrollbar from 'perfect-scrollbar'
import { FormattedMessage } from '../../../translations'
import { convertDateToUserLocal } from '../../../shared/DateConverter'

const css = /* typeof window === 'undefined' ? {} : */require('../../styles.scss')
@confirmable
class AddLanguage extends Component {

  static propTypes = {
    langList: PropTypes.array.isRequired,
    langSelected: PropTypes.array,
    articleLanguage: PropTypes.string.isRequired,
    handleCheckboxChange: PropTypes.func,
  }

  constructor(props) {
    super(props)

    this.ps = null
  }

  componentDidMount() {
    this.ps = new PerfectScrollbar('#articleLanaguage-container', {
      suppressScrollX: true
    })
  }

  @autobind
  renderList() {
    const langList = this.props.langList
    return (
      langList.map((item) => {
        const alreadyCreated = this.props.langSelected.find(el => el.createdIso === item.iso)
        const createdAt = alreadyCreated ? alreadyCreated.createdAt.formatted : ''
        const currentArticleLanguage = item.iso === this.props.articleLanguage

        return (
          <div key={item.iso}>
            <hr />
            <div className="grid-block">
              <div className={
                classNames('token',
                  currentArticleLanguage ? 'isActive' : '',
                  alreadyCreated || currentArticleLanguage ? 'already-created' : ''
                )}>
                <input
                  id={item.iso}
                  onChange={this.props.handleCheckboxChange}
                  type="checkbox"
                  defaultChecked={alreadyCreated || currentArticleLanguage}
                  disabled={alreadyCreated || currentArticleLanguage}
                />
                <label
                  htmlFor={item.iso}
                >
                  {item.iso}
                </label>
              </div>
              <div className="date-single">
                {convertDateToUserLocal(createdAt, { barDisplay: true })}
              </div>
            </div>
          </div>
        )
      })
    )
  }

  render() {

    if (this.ps) {
      this.ps.update()
    }

    return (
      <div className="grid-content add-options">
        <div className="align-center grid-block">
          <div className="token">
            <FormattedMessage id="article.add-language-dialog.token" />
          </div>
          <div className="date">
            <FormattedMessage id="article.add-language-dialog.created-at" />
          </div>
        </div>
        <div
          id="articleLanaguage-container"
          className={css.articleLanguageContainer}
        >
          {this.renderList()}
        </div>
      </div>
    )
  }
}

export default AddLanguage
