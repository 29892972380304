
import * as PropTypes from 'prop-types'

import React, { Component } from 'react'
import classNames from 'classnames'

export default class Dropdown extends Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
  };

  static childContextTypes = {
    dropdownIsInside: PropTypes.bool,
  };

  static defaultProps = {
    className: 'geneva-dropdown',
  };

  getChildContext() {
    return { dropdownIsInside: true }
  }

  render() {
    const { isScrollable } = this.props

    return (
      <ul
        className={
          isScrollable
            ? classNames(this.props.className, 'scroll-container')
            : this.props.className
        }
      >
        {this.props.children}
      </ul>
    )
  }
}
