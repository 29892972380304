import { observable, extendObservable, computed, transaction } from 'mobx'

import Page from '../page/model'
import { store as uiStore } from '../ui'
import { store as pageStore } from '../page'

import Model from '../shared/model'
import { i18n } from '../shared/utils'
import { deepGet } from '../shared/obj'

export default
class Project
  extends Model {

  static asJSONProps = [
    'id',
    'i18n',
    'label',
    'language'
  ]

  // Put all project properties here.
  @observable i18n = {}

  @observable label = ''

  @observable language = ''

  @observable pages = []

  @observable pageTreeRoot = {}

  @observable specialPageTreeRoot = {}

  @observable archiveTreeRoot = {}

  @observable maxNavigationLevel = null

  // The name is displayed in the users language or the UI, not specifically
  //  the projects current lang.  Thereofre it must be passed in
  @computed get name() {
    const channelName = i18n(this, 'channel.i18n.name', uiStore.language)
    return `${channelName} ${this.label}`
  }

  // i18n here depends on the project language. I assume because the project group is
  //  a sub-node of the project model
  //  Also possible that it's just de-DE (compare navigation bar to listfilter)
  @computed get groupName() {
    let name = ''
    Object.keys(this.projectGroup.i18n).forEach((key) => {
      name = this.projectGroup.i18n[key].name
    })
    return name
    // logically this function should be what is below :(
    // return i18n(this, 'projectGroup.i18n.name', this.language)
  }

  @computed get channelShortcut() {
    return this.channel.shortcut
  }

  // so in continued insanity, the i18n is specific to the user account ('en', or 'de', etc`)
  @computed get channelName() {
    return i18n(this, 'channel.i18n.name', uiStore.language)
  }

  @computed get displayName() {
    return `${this.groupName} - ${this.language}`
  }

  @computed get state() {
    const active = this.store.current === this
    return {
      checked: active,
      disabled: active
    }
  }

  @computed get hasCenshare() {
    return !!deepGet(this, 'settings.custom.custom.censhare')
  }

  status = ''

  // Saving the navigation state, expandedKeys for all tree
  expandedPageKeys = []

  // whether or not this item is marked as active
  @observable isActive = false

  @observable complete = false

  @computed get asJSON() {

    return {
      id: this.id,
      i18n: this.i18n,
      label: this.label,
      language: this.language,
    }

  }

  @computed get pageTree() {

    return this.pageTreeRoot

  }

  @computed get specialPageTree() {

    return this.specialPageTreeRoot

  }

  @computed get archiveTree() {

    return this.archiveTreeRoot

  }

  constructor(store, rawData = {}, opts = {}) {
    if (!store) {
      console.error('model creation requires a store')
    }
    // set standard properties (like store) and calls parse, set, and handleModelCreated
    super(store, rawData, opts)
  }

  getJSON() {
    return this.asJSON
  }

  parse(data) {

    if (data.data) {
      data = data.data
    }

    if (data.id) {
      data.id *= 1
    }

    if (data.projectGroupId) {
      data.projectGroupId *= 1
    }

    if (data.projectGroupId === -1) {
      data.projectGroupId = data.id || this.id
      data.projectGroup = {
        ...data,
        id: data.projectGroupId,
        name: data.label
      }
      delete data.projectGroup.projectGroup
      delete data.projectGroup.projectGroupId
    }

    // transaction helps currently with Tree Navigation re-rendering per page created
    transaction(() => {
      if (data.pages) {
        data.pageTreeRoot = pageStore.createTree(data.pages, { root: 'project.navigation.archive-pages', type: 1, language: data.language  })
      }

      if (data.specialPages) {
        data.specialPageTreeRoot = pageStore.createTree(
          data.specialPages,
          { root: 'project.navigation.special-pages', type: 2, language: data.language }
        )
      }

      if (data.archive) {
        data.archiveTreeRoot = pageStore.createArchiveTree(data.archive, { language: data.language })
      }
    })

    return data
  }


  set(data) {

    const shouldAutosave = this.autoSave
    this.autoSave = false

    // always first clone the data before we do something with it
    data = this.parse(data)
    delete data.id
    extendObservable(this, data)

    // if the project was complete once, it cannot become incompete again
    // this.complete = this.complete || !!content
    this.autoSave = shouldAutosave

  }

  /**
   * Gets the first page in a project, normally startseite (start page)
   * @returns {Object} - page data (not model)
   */
  getDefaultPage() {
    if (this.pages.length > 0 && this.pages[0]) {
      return this.pages[0]
    }
    console.warn('No default page found.')
    return null

  }

  /**
   * @param {Page} page
   * @returns Boolean
   */
  hasPage(page) {
    return page && page.projectId && page.projectId * 1 === this.id * 1
  }

}
