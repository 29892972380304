import * as PropTypes from 'prop-types'
import React from 'react'
import { sortable } from 'react-anything-sortable'

const SortableItem = (props) => {
  return <div {...props}>{props.children}</div>
}

SortableItem.propTypes = {
  children: PropTypes.node,
}

export default sortable(SortableItem)
