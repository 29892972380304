import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { autobind } from 'core-decorators'
import classNames from 'classnames'
import { FormattedMessage } from '../../../translations'
import config from '../../../config'
import { PAGE_TYPE_SPECIAL_PAGE } from '../../../shared/const'

const validationMessage = {
  needValidation: 'page-settings-dialog-content.publishedUrl-needsValidation',
  isValidating: 'page-settings-dialog-content.publishedUrl-isValidating',
  isValid: 'page-settings-dialog-content.publishedUrl-isValid',
  isInvalid: 'page-settings-dialog-content.publishedUrl-isInvalid',
  isInUse: 'page-settings-dialog-content.publishedUrl-isInUse',
}

export default class GeneralSettingsForm extends Component {

  static propTypes = {
    options: PropTypes.object,
    name: PropTypes.string,
    publishedUrl: PropTypes.string,
    valState: PropTypes.string,
    pageType: PropTypes.number,
    showInNavigation: PropTypes.bool,
    createFolder: PropTypes.bool,
    hideBreadcrumb: PropTypes.bool,
    hideFromRobots: PropTypes.bool,
    showInSearch: PropTypes.bool,
    isValidated: PropTypes.bool,
    handleRef: PropTypes.func,
    onInputChange: PropTypes.func,
    onValidate: PropTypes.func,
    modifiedInValidation: PropTypes.bool
  }

  @autobind
  handleInputChange(e) {
    if (this.props.onInputChange) {
      this.props.onInputChange(e)
    }
  }

  @autobind
  handleValidation(e) {
    if (this.props.onValidate) {
      this.props.onValidate(e)
    }
  }

  render() {

    const { modifiedInValidation, options: { pageTemplate, page } } = this.props
    const isNavigationDisabled = this.props.pageType === PAGE_TYPE_SPECIAL_PAGE
    const pageChannel = page && page.channelShortcut
    const showSEOFolderOption = config.seoSettings
      && config.seoSettings.folderEnabled
      && pageChannel
      && config.seoSettings.folderChannels.find(channel => channel === pageChannel)
    const showhideBreadcrumbOption = config.hideBreadcrumbOption
    const showhideFromRobotsOption = config.hideFromRobotsOption

    return (
      <div className="grid-content">
        <label htmlFor="name">
          <FormattedMessage id="page-settings-dialog-content.name" />
          <input
            id="name"
            key="name"
            className="name-input"
            type="text"
            value={this.props.name}
            onChange={this.handleInputChange}
            ref={this.props.handleRef}
            maxLength="60"
            autoFocus
          />
        </label>
        <label htmlFor="navigationName">
          <FormattedMessage id="page-settings-dialog-content.navigationName" />
          <input
            id="navigationName"
            className="name-input"
            type="text"
            maxLength="60"
            value={this.props.navigationName}
            onChange={this.handleInputChange}
          />
        </label>

        <label htmlFor="publishedUrl">
          <FormattedMessage id="page-settings-dialog-content.publishedUrl" />
          <span className="inline-label">
            <input
              id="publishedUrl"
              type="text"
              value={this.props.publishedUrl}
              onChange={this.handleInputChange}
            />

            <a
              id="validationButton"
              className="button geneva-button validation-button"
              onClick={this.handleValidation}
              disabled={this.props.isValidated}
            >
              <FormattedMessage id="page-settings-dialog-content.publishedUrl-validationButton" />
            </a>
          </span>
          <div>
            {validationMessage[this.props.valState]
              ? <FormattedMessage
                id={validationMessage[this.props.valState]}
              />
              : null}
          </div>
        </label>
        <div className={classNames('publishedUrl-change-info', { 'modified-in-validation': modifiedInValidation })}>
          <FormattedMessage id="page-settings-dialog-content.publishedUrl-change-info" />
        </div>
        <label htmlFor="template">
          <FormattedMessage id="page-settings-dialog-content.page-type" />
          <input
            id="template"
            className="template-input"
            type="text"
            disabled
            value={pageTemplate.name}
          />
        </label>
        <input
          id="showInNavigation"
          type="checkbox"
          disabled={isNavigationDisabled}
          checked={this.props.showInNavigation}
          onChange={this.handleInputChange}
        />
        <label
          disabled={isNavigationDisabled}
          htmlFor="showInNavigation"
        >
          <FormattedMessage id="page-settings-dialog-content.show-in-navigation" />
        </label>
        { showSEOFolderOption
          ? <div>
            <input
              id="createFolder"
              type="checkbox"
              checked={this.props.createFolder}
              onChange={this.handleInputChange}
            />
            <label
              htmlFor="createFolder"
            >
              <FormattedMessage id="page-settings-dialog-content.create-folder" />
            </label>
          </div>
          : null
        }

        { showhideBreadcrumbOption
          ? <div>
            <input
              id="hideBreadcrumb"
              type="checkbox"
              checked={this.props.hideBreadcrumb}
              onChange={this.handleInputChange}
            />
            <label
              htmlFor="hideBreadcrumb"
            >
              <FormattedMessage id="page-settings-dialog-content.hide-breadcrumb-option" />
            </label>
          </div>
          : null
        }
        { showhideFromRobotsOption
          ? <div>
            <input
              id="hideFromRobots"
              type="checkbox"
              checked={this.props.hideFromRobots}
              onChange={this.handleInputChange}
            />
            <label
              htmlFor="hideFromRobots"
            >
              <FormattedMessage id="page-settings-dialog-content.hide-from-robots-option" />
            </label>
          </div>
          : null
        }

        { // Temperary remove of user changing showInSearch
          /* <input
          id="showInSearch"
          type="checkbox"
          checked={this.props.showInSearch}
          onChange={this.handleInputChange}
        />
        <label htmlFor="showInSearch">
          <FormattedMessage id="page-settings-dialog-content.show-in-search" />
        </label>*/}
      </div>
    )
  }

}
