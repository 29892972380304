import validate, { ValidationHelper } from '../../validate-arguments'

import Emitter from '../../emitter'
import Dispatcher from './Dispatcher'

// This is a little bit hacky but needed to define both the constructor to
// allow passing around the Command class to methods and to define static props
// on that Class.
// Put instance methods into ICommandInternal and static ones on ICommand
interface ICommandInternal {
  data: CommandData;
  dispatcher: Dispatcher;
  // protected validate(...args: Array<any>): string | void;
  exec(...args: Array<any>): Promise<any> | any;
  set(data?: any, ...rest: Array<any>): Command;
  emit(name?: string, data?: any): Command;
  trigger(name?: string, data?: any): Command;
  subscribe(name?: string, handler?: Function): Function;
  on(name?: string, handler?: Function): Function;
}

export interface ICommand
extends ICommandInternal {
  new (...args: Array<any>): Command;
  storeRequirements: StoreRequirements;
  validationSpec: any;
}

interface Store {
  [key: string]: any;
}

interface IEmitter {
  listen(name: string, handler: Function);
  emit(name: string, data?: any);
}

interface StoreRequirements {
  [key: string]: any
}

export interface CommandData {
  store?: Store,
  [key: string]: any
}

const createStoreProxy = (data: Store, CommandClass: ICommand): Store => {

  return new Proxy(data, {
    get(target, name) {
      // handle if name is of type Symbol
      let convertedName: string|number
      if (typeof name === 'symbol') {
        convertedName = name.toString()
      }
      else {
        convertedName = name
      }

      if (!(name in target)) {
        throw new Error(


    `The store \`${convertedName}\` for \`${CommandClass.name}\` is not defined. ` +
    `Did you require it in your \`storeRequirements\`:

    class ${CommandClass.name} extends Command {
      ...
      static storeRequirements = {
        ...,
        widget: true,
        ...
      }
      ...
    }`


        )
      }
      return target[convertedName]
    }

  }) as Store

}

export abstract class Command
implements ICommandInternal {

  public static storeRequirements: StoreRequirements = {}
  public static validationSpec: any = null
  public static ensureData: any = ValidationHelper


  public data: CommandData

  private events: IEmitter = null

  public dispatcher: Dispatcher = null

  protected validationHelper = validate



  constructor(...args: Array<any>) {

    this.events = new Emitter()

    this.set(...args)

    if (this.exec) {

      const origExec = this.exec

      // make sure events get disposed after the execution of the command
      this.exec = (...execArgs: Array<any>) => {

        if (!this.dispatcher) {
          console.warn(/*throw new Error(*/
            `${this.constructor.name}: Commands cannot be executed outside `+
            `of the context of a dispatcher! Allways hand off a command to
             * Dispatcher#exec(Command: CommandClass, data: any) or
             * Dispatcher#execCommand(command: CommandInstance, ` +
               `additionalData: any)`
          )
        }

        try {
          this.validateInternally()
          const msg = this.validate(...args)
          if (msg) {
              throw new Error(msg)
          }
        }
        catch (ex) {
          this.throwError(ex)
        }

        this.emit('command:start')

        const retval = origExec.bind(this)(...execArgs)

        let promise = Promise.resolve(true)
        if ((retval && retval.then)) {
          promise = retval.then((retval) => {
            this.emit('command:end')
            return retval
          })
          .catch((ex) => {
            this.emit('command:error')
            throw ex
          })
        }
        // promise.then((result) => {
        //   this.events.dispose()
        //   return result
        // })
        return promise
      }

    }
  }

  set(...args: Array<any>): Command;
  /**
   * Sets given data on the data property of the command. Overwrite for your
   * needs. Gets passd all arguments that are handed in to the command at
   * creation!
   * @method set
   * @param  {any}           data Any data
   * @param  {Array<any>}    ...rest Any other data passed to set
   */
  set(data?: any, ...rest: Array<any>): Command {
    if (data) {
      if ('store' in data) {
        const commandClass = this.constructor as ICommand
        data.store = createStoreProxy(data.store as Store, commandClass)
      }
      if (!this.data) {
        this.data = {}
      }
      Object.assign(this.data, data)
    }
    return this
  }

  /**
   * Execute the command.
   * @method exec
   * @param  {Array<any>}   ...args Any kind of additional parameters that your
   * may want to pass to the command.
   * @return {Promise<any>}         Either a promise (discouraged) or some data.
   */
  abstract exec(...args: Array<any>): Promise<any> | any

  protected validateInternally() {
    this.ensureStoreRequirements()
  }

  /**
   * Validate the input data before execution. If there was an error with the
   * data, either throw an error or return a string. If everything was ok, just
   * return `undefined`.
   * @method validate
   * @param  {Array<any>} ...args Your data that should be validated.
   * @return {string|void}        The validation result.
   */
  protected validate(...args: Array<any>): string | void {
    const commandClass = this.constructor as ICommand
    if (this.validationHelper && commandClass.validationSpec) {

      const validation = this.validationHelper(
        this.data,
        commandClass.validationSpec
      )

      if (validation) {
        return Object.keys(validation)
        .map(key => {
          return `${key}: ${validation[key].join(', ')}`
        })
        .join('\n')
      }

    }
  }

  protected ensureStoreRequirements() {
    const commandClass = this.constructor as ICommand
    const errors = Object.keys(commandClass.storeRequirements)
    .filter((key) => !(key in this.data.store))
    .map(key => {
      return `* ${key}, but it wasn't injected`
    })
    if (errors && errors.length) {
      throw new Error(
        `required store(s) ${errors.join('\n')}`
      )
    }
  }

  /**
   * @alias
   */
  public emit(name?: string, data?: any): Command {
    return this.trigger(name, data)
  }

  public trigger(name?: string, data?: any): Command {
    if (name) {
      this.events.emit(name, data)
    }
    return this
  }

  /**
   * @alias
   */
  public subscribe(name?: string, handler?: Function): Function {
    return this.on(name, handler)
  }

  public on(name?: string, handler?: Function): Function {
    if (name && handler) {
      return this.events.listen(name, handler)
    }
    return null
  }

  private throwError(ex: Error) {
    ex.message = `${this.constructor.name}: ${ex.message}`
    throw ex
  }

}

export {Command as default}