import { observable, computed } from 'mobx'

import { i18n } from '../shared/utils'

/**
 * Simple project Group model without save/delete/etc from Model
 */
export default
class ProjectGroup {
  @observable id

  @observable createdAt = ''

  @observable updatedAt = ''

  @computed get name() {
    // currently locked to always be de-DE
    return i18n(this.raw, 'i18n.name', 'de-DE')
  }

  // it is possible to have a group with a mixed set of project-channels
  @computed get channels() {
    return this.projects
      .reduce((memo, proj) => {
        if (!memo.find(project => project.id === proj.channel.id)) {
          memo.push(proj.channel)
        }
        return memo
      }, [])
  }

  @computed get channelName() {
    return this.projects.length > 0
      ? this.projects[0].channelName
      : 'default'
  }

  @observable projects = []

  constructor(data) {
    this.id = data.id * 1
    this.createdAt = data.createdAt
    this.updatedAt = data.createdAt
    this.raw = data
  }

  getFirstProject(channelId) {
    return this.projects.find(proj => proj.channelId === channelId)
  }

  getProjectByLang(lang) {
    return this.projects.find(proj => proj.language === lang)
  }

  verifyContainsChannel(channelId) {
    return this.channels.find(channel => channel.id === channelId)
  }

  /**
   * adds a model to the current project collection
   * @param {ProjectModel} newProject
   */
  addProject(newProject) {
    // handle if no model was able to be found in collection
    if (!newProject) {
      return
    }

    if (!this.projects.find(project => project.id === newProject.id)) {
      this.projects.push(newProject)
    }
  }

}
