import * as PropTypes from 'prop-types'
import React from 'react'
import FixedSection from './FixedSection'

export default function BrandSection(props) {
  // don't render if no children or empty (undefined) children
  if (
    !props.children
    || (Array.isArray(props.children) && !props.children.filter(c => !!c).length)
  ) {
    return null
  }

  return (
    <FixedSection>
      <div className="title">{props.children}</div>
    </FixedSection>
  )
}

BrandSection.propTypes = {
  children: PropTypes.node,
}
