import { store as contextStore } from '../../context'
import Command from '../command'
import { store as pageStore } from '../../page'
import { confirmRemoveFromPage, confirmDelete } from '../../article/containers/dialogs'

function keyRequired(key, path = null) {
  if (path) {
    path = `.${path}`
  }
  // eslint-disable-next-line max-len
  return `requires \`data.${path}${key}\` (from \`contextStore.target.${key}\`) to be present`
}

export default class RemoveItemFromPageCommand extends Command {

  constructor(data) {

    super(data.commandData)

    this.opts = data.opts

    this.data = data.commandData

  }

  validate(data) {

    return ['id', 'type']
      .filter(key => !(key in data) || !data[key])
      .map(key => keyRequired(key))
      .join(', ')

  }

  exec() {


    const page = pageStore.getById(this.data.pageId)
    const pageId = page.id
    const target = contextStore.target
    const content = target[target.type]
    const dialog = this.opts.destroy ? confirmDelete : confirmRemoveFromPage

    dialog({
      content,
      page,
      opts: this.opts
    })
      .then(() => {

        pageStore.actionRemoveContent(pageId, content, target, this.opts)

        // reset the context to nothing after removing an item
        if (contextStore) {
          contextStore.createFromElement(null)
        }

      })

  }


}
