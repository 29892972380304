import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { toJS } from 'mobx'
import classNames from 'classnames'
import { autobind } from 'core-decorators'
import { Accordion, AccordionItem } from 'react-sanfona'
import { difference } from 'lodash'
import PerfectScrollbar from 'perfect-scrollbar'
import { FormattedMessage } from '../../translations'

import { dispatcher } from '../../shared/lib/command'
import { deepGet } from '../../shared/obj'
import ContentLoadingBox from '../../shared/components/ContentLoadingBox'

const css = /* typeof window === 'undefined' ? {} : */require('../styles.scss')
/* eslint no-underscore-dangle: 0 */

@dispatcher
@observer
export default class ArticleSort extends Component {

  static propTypes = {
    context: PropTypes.object,
    page: PropTypes.object.isRequired,
    contentStores: PropTypes.object.isRequired,
    activeTabs: PropTypes.array,
    handleTabChange: PropTypes.func,
  }

  constructor(props) {
    super(props)

    this.state = {
      targetId: null,
      className: '',
    }

    this.prevItems = []
    this.nameList = []
    this.ps = null
  }

  componentDidMount() {
    // Create the index list of already opened items
    this.props.activeTabs.forEach((tab) => {
      const index = this.nameList.indexOf(tab)
      if (index !== -1) {
        this.prevItems.push(index)
      }
    })
    this.ps = new PerfectScrollbar('#articleSort-container')
  }

  getGridContent(grid, page) {
    const articleArray = []
    const data = toJS(page.templateData)
    const itemArray = deepGet(data, `gr${grid.gr}.gb${grid.gb}`)
    if (itemArray) {
      itemArray.forEach(
        item => articleArray.push(this.props.contentStores[item.type]
          .getById(item.id))
      )
    }
    return articleArray
  }

  isContextTarget(id) {
    const { context } = this.props

    if (context && context.id) {
      return id === context.id
    }
    return false
  }

  /**
    * Consolidates the allowed template for articles and widget into one array
    * @param {Object} grid - A single grid element
    * @returns {Array} allowedItems - Array of strings
  */
  getAllowedItemsInGrid(grid) {
    let articles = grid.allowedArticleTemplates
    let widgets = grid.allowedWidgetTemplates

    if (!widgets) {
      widgets = []
    }
    if (!articles) {
      articles = []
    }

    return articles.concat(widgets.concat([]))
  }

  /**
    * Determines index number where to insert an item
    * The dragged item is removed before inserted, therefore an adjustment is needed
    * @param {Number} index - The index from the drop target
    * @param {Bool} isSameGridBlock - If it was dropped in the same gridBlock
    * @returns {Number} newIndex - the new position in a grid
  */
  getNewIndex(index, isSameGridBlock) {
    let newIndex = index * 1  // make sure it is integer

    // sorting "down" in the same gridBlock
    if (isSameGridBlock && this.dragData.index < index) {
      newIndex = this.state.className === 'border-top'
        ? newIndex - 1
        : newIndex
    }
    // sorting "up" or in another grid-block
    else {
      newIndex = this.state.className === 'border-top'
        ? newIndex
        : newIndex + 1
    }

    return newIndex
  }

  @autobind
  handleTabChange(e) {
    // find the index that was removed or added from the accordion list
    let diff = difference(e.activeItems, this.prevItems)
    if (diff.length === 0) {
      diff = difference(this.prevItems, e.activeItems)
    }
    this.prevItems = e.activeItems

    // notify parent component to update the state
    this.props.handleTabChange(this.nameList[diff[0]])
  }

  @autobind
  handleDragStart(e) {
    e.dataTransfer.effectAllowed = 'move'

    this.dragData = {
      id: e.target.dataset.articleid,
      template: e.target.dataset.template,
      gr: e.target.dataset.gr,
      gb: e.target.dataset.gb,
      index: e.target.dataset.index * 1,  // ensure number
      type: e.target.dataset.type
    }
  }

  @autobind
  handleDragOver(e) {
    e.preventDefault()
    e.dataTransfer.dropEffect = 'none'

    let target = e.target
    let isSortTarget = false
    let className = ''
    let targetId

    // find the sort node
    while (!isSortTarget) {
      if (target.id !== 'articleSort') {
        target = target.parentElement
      }
      else if (target.id === 'articleSort') {
        isSortTarget = true
      }
    }

    // dataset attributes are lowercase
    const data = target.dataset

    // not the same node
    if (data.articleid && data.articleid !== this.dragData.id) {

      // allowedarticles check
      if (data.allowedarticles.indexOf(this.dragData.template) !== -1) {

        e.dataTransfer.dropEffect = 'move'

        if (e.nativeEvent.offsetY < target.clientHeight / 2) {
          className = 'border-top'
        }
        else {
          className = 'border-bottom'
        }

        targetId = data.articleid
      }
    }
    else {
      className = 'no-border'
    }

    this.setState({
      targetId,
      className
    })
  }

  @autobind
  handleDrop(e) {
    e.preventDefault()

    let target = e.target
    let isSortTarget = false
    let isSameGridBlock = true

    // find the sort node
    while (!isSortTarget) {
      if (target.id !== 'articleSort') {
        target = target.parentElement
      }
      else if (target.id === 'articleSort') {
        isSortTarget = true
      }
    }

    if (this.dragData.gb !== target.dataset.gb
      || this.dragData.gr !== target.dataset.gr) {

      isSameGridBlock = false
    }

    const newIndex = this.getNewIndex(target.dataset.index, isSameGridBlock)

    const dropData = {
      gr: target.dataset.gr,
      gb: target.dataset.gb,
      index: newIndex
    }

    if (!isSameGridBlock
      || this.dragData.index !== dropData.index) {

      this.context.dispatch(
        this.props.commands.SortArticleCommand,
        {
          commands: this.props.commands,
          itemId: this.dragData.id,
          page: this.props.page,
          removeFrom: this.dragData,
          insertTo: dropData
        }
      )
    }

    this.dragData = null
    this.setState({
      targetId: null
    })
  }

  @autobind
  handleDragEnd() {
    this.dragData = null
    this.setState({
      targetId: null
    })
  }

  @autobind
  handleDragLeave() {
    this.setState({
      targetId: null
    })
  }

  renderLoader() {
    return (<div className="content-loader-background">
      <ContentLoadingBox className="content-loader"
        message={{
          id: 'status.saving-started',
        }} />
    </div>)
  }

  renderDropPlaceholder(grid) {
    const allowedItemTemplates = toJS(this.getAllowedItemsInGrid(grid))
    const placeholderId = `-${grid.gb}${grid.gr}`
    const style = this.state.targetId === placeholderId ? this.state.className : 'no-border'

    return (<div
      className={classNames('article-sort-item', 'drop-placeholder', style)}
      id="articleSort"
      onDragOver={this.handleDragOver}
      onDrop={this.handleDrop}
      onDragEnd={this.handleDragEnd}
      onDragLeave={this.handleDragLeave}
      data-articleid={placeholderId}
      data-allowedarticles={allowedItemTemplates}
      data-gb={grid.gb}
      data-gr={grid.gr}
      data-index="0"
    >
    </div>)
  }

  renderGridContent(grid, page) {
    const gridContent = this.getGridContent(grid, page)
    let isLastElement = false

    if (gridContent.length === 0) {
      return this.renderDropPlaceholder(grid)
    }

    return gridContent.map((articleItem, index) => {

      // single grid. last element needs extra style
      if (index === gridContent.length - 1) {
        isLastElement = true
      }

      const isActive = this.isContextTarget(articleItem.id) ? 'active' : null
      const style = this.state.targetId === `${articleItem.id}` ? this.state.className : 'no-border'
      const allowedItemTemplates = toJS(this.getAllowedItemsInGrid(grid))
      let templateShortcut = ''

      if (articleItem.template) {
        templateShortcut = articleItem.template.shortcut
      }
      else if (articleItem.widgetTemplate) {
        templateShortcut = articleItem.widgetTemplate.shortcut
      }

      return (<div
        className={classNames(
          'article-sort-item draggable',
          isActive,
          style,
          isLastElement ? 'last-grid-element' : null,
          page.isSaving ? 'inactive' : null
        )}
        id="articleSort"
        key={articleItem.id}
        draggable="true"
        onDragStart={this.handleDragStart}
        onDragOver={this.handleDragOver}
        onDrop={this.handleDrop}
        onDragEnd={this.handleDragEnd}
        onDragLeave={this.handleDragLeave}
        data-articleid={articleItem.id}
        data-allowedarticles={allowedItemTemplates}
        data-template={templateShortcut}
        data-gb={grid.gb}
        data-gr={grid.gr}
        data-index={index}
        data-type={articleItem.contentType}
      >

        <img src="/images/drag-icon-7-dots.png" />

        <div className="article-sort-item-text">
          {articleItem.name}
          <div className="article-lang">
            {articleItem.createdIso} - <i className="ion ion-document" /> {articleItem.templateName}
          </div>
        </div>

      </div>)
    })
  }

  renderBlock(page) {
    const { activeTabs } = this.props

    // with a single grid, render without accordion
    if (page.__gridOrder.length === 1) {
      return (
        page.__gridOrder.map((grid) => {
          return this.renderGridContent(grid, page)
        })
      )
    }

    this.nameList = []

    return (
      <Accordion
        allowMultiple
        onChange={this.handleTabChange}
      >
        {
          page.__gridOrder.map((grid) => {
            const name = `gr${grid.gr}gb${grid.gb}`
            this.nameList.push(name)
            let style = ''
            if (this.dragData) {
              if (this.getAllowedItemsInGrid(grid).indexOf(this.dragData.template) !== -1) {
                style = 'drop-zone'
              }
            }
            return (<AccordionItem
              className={style}
              title={<div className={classNames('react-sanfona-item-title page-info-title', style)}>
                <div className="accordion-title">
                  {grid.title}
                </div>
              </div>}
              slug={name}
              key={name}
              bodyClassName="page-status-accordion-body"
              expanded={!!activeTabs.find(el => el === name)}
            >
              {this.renderGridContent(grid, page)}
            </AccordionItem>)
          })
        }
      </Accordion>)
  }

  render() {
    const { page } = this.props

    if (this.ps) {
      this.ps.update()
    }

    return (<div
      id="articleSort-container"
      className={classNames('grid-block vertical', css.articleSort)}
    >
      {/* page.isSaving
      ? this.renderLoader()
      : null*/}
      {page.contentTemplatesLoaded && page.__gridOrder
        ? <div> {this.renderBlock(page)} </div>
        : <FormattedMessage id="page.loading" />}
    </div>)
  }
}
