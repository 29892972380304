
import PropTypes from 'prop-types'

import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { autobind } from 'core-decorators'
import { autorun } from 'mobx'
import classNames from 'classnames'
import { formatMessage, FormattedMessage } from '../../translations'
import config from '../../config'

import LockStateMenubar from '../../shared/components/LockStateMenubar'
import ToolsSidebarIcon from '../../shared/icons/tools-sidebar'
import GenevaButton from '../../ui/components/GenevaButton'
import { i18n, testClass } from '../../shared/utils'
import { store as contextStore } from '../../context'
import { store as uiState } from '../../ui'
import { PMToolbar as ProjectToolbar } from '../../project'
import {
  store as pageStore,
  PMToolbar as PageToolbar,
  PagePublishState
} from '../../page'
import { PMToolbar as ArticleToolbar } from '../../article'
import { PMToolbar as WidgetToolbar } from '../../widget'

const Context = contextStore.constructor


@observer
class MenuBar extends Component {

  // eslint-disable-next-line react/sort-comp
  static toolbarDefaults = {
    [Context.CONTEXT_TYPE_PROJECT]: false,
    [Context.CONTEXT_TYPE_PAGE]: false,
    [Context.CONTEXT_TYPE_ARTICLE]: false,
    [Context.CONTEXT_TYPE_WIDGET]: false,
    [Context.CONTEXT_TYPE_IMAGE]: false,
    [Context.CONTEXT_TYPE_TEXT]: false,
    template: false
  }

  static propTypes = {
    project: PropTypes.object.isRequired,
    page: PropTypes.object.isRequired,
    article: PropTypes.object,
    context: PropTypes.object.isRequired,
    ui: PropTypes.object.isRequired,

    menuBarStyles: PropTypes.string
  }

  constructor(props) {

    super(props)

    this.state = {
      context: null
    }

  }

  componentDidMount() {
    uiState.updatePaneState({
      pageToolsPanelOpen: false,
      projectToolsPanelOpen: false
    })
    // APPROVED: whenever the the context changed, notify of context change
    this.handler = autorun(() => this.contextChanged(this.props.context.target))
  }

  componentWillUnmount() {
    if (this.handler) {
      this.handler()
      this.handler = null
    }
  }


  contextChanged() {

    const { context } = this.props

    let toolbars = MenuBar.toolbarDefaults

    if (this.pageIsOpen()) {
      toolbars = {
        ...MenuBar.toolbarDefaults,
        [context.type]: true
      }
    }

    this.setState(toolbars)
  }

  pageIsOpen() {
    return this.props.page.hasCurrent
  }

  @autobind
  handleChangePageToolsPanelVisibility() {

    uiState.updatePaneState({
      pageToolsPanelOpen: !uiState.paneState.pageToolsPanelOpen
    })


  }

  @autobind
  handleChangeProjectToolsPanelVisibility() {

    uiState.updatePaneState({
      projectToolsPanelOpen: !uiState.paneState.projectToolsPanelOpen
    })

  }

  @autobind
  handlePreviewPage() {

    const page = pageStore.current

    const pagePreviewURL = pageStore.getPreviewURL(page.id)
    window.open(
      `/preview/index.html?url=${pagePreviewURL}?${page.updatedAt}`,  // timestamp
      `${page.name} - Preview`,
      config.PREVIEW_IN_WINDOW
        ? `width:${window.outerWidth};height:${window.outerHeight};`
        : null
    )

  }

  renderLockState() {

    const {
      page,
    } = this.props

    return (<LockStateMenubar
      className={classNames(
        'menu-bar',
        'main-toolbar',
        this.props.menuBarStyles
      )}
      lockable={page}
    />)

  }

  renderToolbars() {

    const {
      ui,
      page,
      project
    } = this.props

    return (<div className="menubar-container grid-block shrink">

      <div className="grid-block shrink">
        <PageToolbar
          className={classNames(
            'pull-left',
            'page-toolbar'
          )}
          page={page}
          project={project}
          disabled={
            !(page.hasCurrent
              && project.hasCurrent
              && project.current.hasPage(page.current)
            )
          }
          key="page-toolbar"
          ref="page-toolbar"
          options={PageToolbar.options}
          ui={ui}
        />
      </div>

      <div className="grid-block shrink">
        <ArticleToolbar
          className={classNames(
            'pull-left',
            'article-toolbar', {
              hide: this.state[Context.CONTEXT_TYPE_WIDGET]
            }
          )}
          page={page}
          disabled={!(page.hasCurrent && this.state[Context.CONTEXT_TYPE_ARTICLE])}
          key="article-toolbar"
          ref="article-toolbar"
          options={ArticleToolbar.options}
          ui={ui}
        />
      </div>


      <div className="grid-block shrink">
        <WidgetToolbar
          className={classNames(
            'pull-left',
            'article-toolbar', {
              hide: !this.state[Context.CONTEXT_TYPE_WIDGET]
            }
          )}
          page={page}
          disabled={!(page.hasCurrent && this.state[Context.CONTEXT_TYPE_WIDGET])}
          key="article-toolbar"
          ref="article-toolbar"
          options={WidgetToolbar.options}
          ui={ui}
        />
      </div>
    </div>)
  }

  render() {

    const {
      ui: { paneState },
      page,
      project,
      ui
    } = this.props

    const isLocked = page.hasCurrent && page.current.isLockedByOther()

    return (<div
      className={classNames(
        'menu-bar',
        'main-toolbar',
        this.props.menuBarStyles
      )}
    >
      <div className="grid-block medium-3 overflow publish-toolbar-container">
        <GenevaButton
          onClick={this.handleChangeProjectToolsPanelVisibility}
          disabled={!project.hasCurrent}
          className={`
            plain
            button
            float-left
            ${paneState.projectToolsPanelOpen ? 'active' : ''}
            ${testClass('tools-panel-visibility')}
          `}
        >
          <ToolsSidebarIcon left width={24} height={24} />
        </GenevaButton>

        <div className="grid-block shrink">
          <ProjectToolbar
            className={classNames(
              'pull-left',
              'project-toolbar',
              testClass('project-toolbar')
            )}
            project={project}
            disabled={!project.hasCurrent}
            key="project-toolbar"
            ref="project-toolbar"
            options={ProjectToolbar.options}
            ui={ui}
          />
        </div>

        {
          isLocked
            ? this.renderLockState()
            : this.renderToolbars()
        }
      </div>
      <div className="grid-block medium-6 v-align align-center">
        {
          page.hasCurrent
            ? <PagePublishState
              page={page}
              pageName={i18n(page.current, 'name') || i18n(page.current, 'title')}
            />
            : null
        }
      </div>
      <div className="grid-block medium-3 align-right">
        <GenevaButton
          onClick={this.handlePreviewPage}
          disabled={!page.hasCurrent}
          className="
            small
            plain
            button
            float-right
          "
          id="previewPage"
        >
          <FormattedMessage id="page.preview" />
        </GenevaButton>


        <GenevaButton
          onClick={this.handleChangePageToolsPanelVisibility}
          disabled={!page.hasCurrent}
          className={`
            plain
            button
            float-right
            ${paneState.pageToolsPanelOpen ? 'active' : ''}
          `}
        >
          <ToolsSidebarIcon width={24} height={24} />
        </GenevaButton>
      </div>

    </div>)

  }
}

export default MenuBar
