import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import { autobind } from 'core-decorators'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import classNames from 'classnames'

import connectImageToolsToCommands from '../../shared/connectors/connectImageToolsToCommands'
import connectFileToolsToCommands from '../../shared/connectors/connectFileToolsToCommands'
import connectVideoToolsToCommands from '../../shared/connectors/connectVideoToolsToCommands'
import { default as UploadTools } from './Media/UploadTools'

const css = /* typeof window === 'undefined' ? {} : */ require('../styles.scss')

function getCssSizeValue(val) {
  // in case there is no value, return auto
  if (!val) {
    return 'auto'
  }

  const numberSuspect = val * 1
  // if it's a number, return a px value
  if (
    // eslint-disable-next-line no-restricted-globals
    !isNaN(numberSuspect)
    && Object.prototype.toString.call(numberSuspect) === '[object Number]'
  ) {
    return `${val}px`
  }

  // otherwise just return the input
  return val
}

const ConnectedUploadTools = connectVideoToolsToCommands(
  connectFileToolsToCommands(connectImageToolsToCommands(UploadTools))
)

const specShape = PropTypes.shape({
  minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  aspectRatio: PropTypes.number,
  accepts: PropTypes.string,
  mediaType: PropTypes.oneOf(['video', 'image', 'file', '*']),
})

@inject('ui', 'context')
@observer
class Helpers extends Component {
  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    spec: specShape,
    onFocus: PropTypes.func,
    justButton: PropTypes.bool,

    ui: PropTypes.object.isRequired,
    context: PropTypes.object.isRequired,
  };

  // shouldComponentUpdate(nextProps) {
  //   return nextProps.value !== this.props.value
  // }

  @autobind
  handleBeforeItemChange(type) {
    if (this.props.onFocus) {
      this.props.onFocus(type)
    }
  }

  render() {
    const hideTools
      = !!this.props.value
      && !(this.props.ui.hasActiveURLDrag || this.props.ui.hasActiveFileDrag)

    // if (hideTools) {
    //   return null
    // }

    // if (this.state.value &&
    //   !(dragAction.active && dragAction.types.indexOf('Files') !== -1)) {
    //   return null
    // }

    return (
      <ConnectedUploadTools
        style={{
          display: hideTools ? 'none' : 'block',
        }}
        value={this.props.value}
        ui={this.props.ui}
        context={this.props.context}
        justButton={this.props.justButton}
        spec={this.props.spec}
        onBeforeItemChange={this.handleBeforeItemChange}
      />
    )
  }
}

export default function connectMediaToUploadHelper(Media, parentProps) {

  class MediaUploadHelper extends Component {
    static propTypes = {
      spec: specShape,
      // placeholder: PropTypes.object.isRequired,
      value: PropTypes.string,
      ui: PropTypes.object,
      // if true, the element will never render the actual data, but just
      // serve as an upload button
      justButton: PropTypes.bool,
    };

    static defaultProps = {
      spec: {
        mediaType: '*',
      },
    };

    constructor(props) {
      super(props)

      this.state = {
        value: props.value,
      }
    }

    componentWillReceiveProps(nextProps) {
      if (nextProps.value !== this.state.value) {
        this.setState({ value: nextProps.value })
      }
    }


    @autobind
    handleImageProgress(progress) {
      console.log('Progress:', progress)
    }

    @autobind
    handleVideoProgress(progress) {
      console.log('Progress:', progress)
    }

    @autobind
    handleFocusTools(type) {
      this.focus(type)
    }

    focus(type) {
      if (this.mediaRef) {
        this.mediaRef.focus(type)
      }
    }

    checkIsEditable() {
      return (
        parentProps.env.CM && parentProps.articlePlaceholders.article.isEditable
      )
    }

    render() {
      const spec = this.props.spec || {}

      // eslint-disable-next-line no-unused-vars
      const env = toJS(parentProps.env)

      const { value, file } = this.state

      const hasNoMedia = !value

      const renderWidth = hasNoMedia ? spec.defaultWidth : null
      const renderHeight = hasNoMedia ? spec.defaultHeight : null
      // The sizes are only used to properly render the empty image container
      const sizes = hasNoMedia
        ? {
          width: getCssSizeValue(renderWidth || spec.minWidth),
          height: getCssSizeValue(renderHeight || spec.minHeight),
        }
        : null

      const media = (
        <Media
          {...this.props}
          className={classNames(this.props.className, css.mediaWrapper)}
          value={this.props.justButton ? null : value}
          file={this.props.justButton ? undefined : file}
          ref={ref => (this.mediaRef = ref)}
          sizes={sizes}
        />
      )

      if (!this.checkIsEditable()) {
        return media
      }

      return (
        <div
          className={classNames(
            css.mediaWrapper,
            // for overwriting in templates
            'media-wrapper'
          )}
          style={sizes}
        >
          {media}

          <Helpers
            value={this.props.justButton ? null : value}
            justButton={this.props.justButton}
            spec={{
              ...this.props,
              ...this.props.spec,
            }}
            onFocus={this.handleFocusTools}
          />
        </div>
      )
    }
  }

  return MediaUploadHelper
}
