const URL = window.URL || window.webkitURL || window.mozURL

export default class URLStrategy {

  updateProps() {}

  getData({ dataTransfer }) {

    const url = dataTransfer.getData('text/x-moz-url')
      || dataTransfer.getData('text/uri-list')
      || dataTransfer.getData('text/plain')

    this.validateUrl(url)

    let censhareData = null
    if (dataTransfer.types.includes('censhare-image')) {
      censhareData = this.getCenshareData(dataTransfer)
    }
    return { url, censhareData }

  }

  getCenshareData(dataTransfer) {
    const data = dataTransfer.getData('censhare-image')
    return data
  }

  validateUrl(urlSuspect) {
    // this should throw if the urlSuspect is not valid:
    // https://developer.mozilla.org/en-US/docs/Web/API/URL/URL

    // this will throw if it cannot be parsed as URL
    // eslint-disable-next-line no-new
    new URL(urlSuspect)

  }

  isValidDrag({ type, dataTransfer }) {

    let isValidDrag = false

    if (type === 'dragover') {
      isValidDrag = dataTransfer.types
      && (
        (dataTransfer.types.length && dataTransfer.types[0] === 'text/uri-list')
        || (dataTransfer.types.contains && dataTransfer.types.contains('text/x-moz-url'))
      )
    }

    return isValidDrag

  }

}
