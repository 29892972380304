import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { autorun, observable, action } from 'mobx'
import { observer } from 'mobx-react'
import { autobind } from 'core-decorators'
import PerfectScrollbar from 'perfect-scrollbar'

import { FormattedMessage } from '../../translations'
import ContentLoadingBox from '../../shared/components/ContentLoadingBox'

// todo: replace the library with a custom one, so that we can use our custom scrollbar
// without, then the scroll triggers don't happen correctly (using perfectScrollbar)
@observer
export default class InfiniteList extends Component {

  /* eslint-disable react/sort-comp */
  @action setLoading() {
    this.loading = true
  }

  @action setComplete() {
    this.loading = false
  }
  /* eslint-enable react/sort-comp */

  @observable loading = false

  static propTypes = {
    itemStore: PropTypes.object.isRequired,
    loadDelegate: PropTypes.func.isRequired,
    renderItem: PropTypes.func.isRequired,
    layout: PropTypes.string
  }

  constructor(props) {
    super(props)

    this.ps = null

    this.elementRenderList = []
    this.start = -1
    this.recentPage = 1
    this.layoutMultiplier = null
    this.historicalLayout = 'list'
    this.shouldRender = false
    this.wasInitialLoaded = false
  }


  componentDidMount() {

    // APPROVED Whenever the length of the collection changes, update the
    // rendered list
    this.handler = autorun(() => {
      // the length is necessary to make autorun listen to changes
      // eslint-disable-next-line no-unused-vars
      const size = this.props.itemStore.filterTotal
      this.updateList()
    })

    this.ps = new PerfectScrollbar('#article-list-container', {
      suppressScrollX: true
    })
  }

  /**
 * Watches for a layout change and if so resets view so it can be built specific for
 *   for that layout
 * @param {*} nextProps - watches for a layout change
 */
  componentWillUpdate(nextProps) {
    if (this.props.layout !== nextProps.layout) {
      this.setLoading()
      this.resetElementsList()
      this.setComplete()
      this.shouldRender = true
    }
  }

  /**
 * Part 2 of the layout change update. After clearing the ui, this will rebuild
 *   the infinit list
 */
  componentDidUpdate() {
    if (this.shouldRender === true) {
      this.shouldRender = false
      this.updateList()
    }
  }

  componentWillUnmount() {

    this.elementRenderList = []
    if (this.handler) {
      this.handler()
      this.handler = null
    }
  }

  updateList() {
    if (this.props.itemStore.modifiedCollection.length) {
      return this.recalculateElementsList()
    }
    return this.resetElementsList()
  }

  resetElementsList() {
    this.start = -1
    this.recentPage = 1
    this.elementRenderList = []
    this.props.itemStore.page = 1
  }

  recalculateElementsList() {
    this.setLoading()

    const isLimit = this.props.itemStore.limit
    const page = this.props.itemStore.page || 1
    const end = page * isLimit
    let start = end - isLimit
    const recentPage = this.recentPage || 1
    let oldElements = this.elementRenderList

    this.layoutMultiplier = this.props.layout === 'list' ? 1 : 3

    const newElements = this.buildElements(start, end)
    const numNewElements = newElements.length
    if (numNewElements) {

      if (numNewElements % (isLimit / this.layoutMultiplier)) {
        start = this.start
      }

      if (page === 1) {
        this.elementRenderList = []
        oldElements = newElements
      }

      if (page > recentPage) {
        if (page > (recentPage + 1)) {
          console.warn(`Infinite List page number jumped from ${recentPage} to ${page}`)
        }
        oldElements = oldElements.concat(newElements)
      }

      // if page < recentPage, then a reset should have happened
      if (page > 1 && page < recentPage) {
        // eslint-disable-next-line max-len
        console.warn(`Infinite List page number ${page} is lower than recent ${recentPage} and a reset should have happened`)
      }

      // Lets infiteList control the filter collection better
      if (this.props.itemStore.modifiedCollection.length > end) {
        this.props.itemStore.modifiedCollection.splice(
          end,
          this.props.itemStore.modifiedCollection.length
        )
      }
      // set memory for next iteration
      this.elementRenderList = oldElements
      this.recentPage = page
      this.start = start

      this.setComplete()
    }
  }

  /**
   * Builds the rendered objects in either list or grid form
   * @param {Number} start - place in modifiedCollection to start building from
   * @param {Number} end  - place in modifiedCollection to end
   */
  buildElements(start, end) {

    const newElements = (this.props.itemStore.modifiedCollection || [])
      .slice(start, end)

    const currentId = this.props.itemStore.current
      ? this.props.itemStore.current.id * 1
      : null

    const elements = newElements
      .map(element => this.props.renderItem(element, currentId))
    const gridElements = []
    let i = 0
    for (let j = 0; j < (elements.length) / this.layoutMultiplier; j++) {
      if (this.layoutMultiplier > 1) {
        gridElements[j] = this.renderGridBlocks(elements[i], elements[i + 1], elements[i + 2], j)
        i += 3
      }
      else {
        gridElements[j] = elements[j]
      }

    }
    return gridElements

  }

  @autobind
  handleScroll({ target }) {
    const bottomOfWindow = target.scrollTop >= target.scrollHeight - 350 - target.offsetHeight
    if (bottomOfWindow) {
      // Prevent loader on pagination
      this.wasInitialLoaded = true
      this.props.loadDelegate(this.elementRenderList.length * this.layoutMultiplier)
    }
  }

  /**
   * Grid Infinite List works best without columns, so this builds rows with three
   * items across.  css functions continues to function like these are independent though
   * @param {Object} first - single rendered item
   * @param {Object} second - single rendered item or empty
   * @param {Object} third - single rendered item or empty
   * @param {Number} number - current count just for an id
   */
  renderGridBlocks(first, second, third, number) {
    return (
      <div key={`infBlock${first.key}${number}`}>
        {first}
        {second}
        {third}
      </div>
    )
  }

  render() {
    const loading = this.props.itemStore.loading || (this.props.itemStore.loading === null)

    if ((loading || this.loading) && !this.wasInitialLoaded) {
      return (<div className="loading-wrapper">
        <ContentLoadingBox
          spinner
          message={{
            id: 'placeholder-empty'
          }}
        />
      </div>)
    }

    if (this.ps) {
      this.ps.update()
    }

    return (<div
      id="article-list-container"
      className="grid-block element-list"
      ref={(ref) => {
        this.ref = ref
        if (ref) {
          this.ref.addEventListener('scroll', this.handleScroll, false)
        }
      }}
    >
      <div className="inner-wrapper">
        {this.elementRenderList}
      </div>
    </div>)
  }
}
