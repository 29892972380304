
import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import classNames from 'classnames'
import { autobind } from 'core-decorators'
import { FormattedMessage } from '../../../translations'
import Icon from '../../icons'
import GenevaButton from '../../../ui/components/GenevaButton'

import PureStatusItem from './StatusItem'

const css = /* typeof window === 'undefined' ? {} : */ require('./styles.scss')

const priorites = {
  low: 1,
  high: 2,
  'very-high': 3,
}

function getPriority(priority: string | number = 'low') {
  if (typeof priority === 'number') {
    return priority
  }
  return priorites[priority]
}
class StatusList extends Component {
  static propTypes = {
    showStepper: PropTypes.bool,
    statusInfos:
      PropTypes.object, /* arrayOf(      PropTypes.shape({
        id: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number
        ]).isRequired,
        name: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        progress: PropTypes.number,
        total: PropTypes.number,
        thumb: PropTypes.string
      })
    )*/
    statusInfosIdStack:
      PropTypes.object, /* arrayOf(      PropTypes.oneOf([PropTypes.number, PropTypes.string])
    )*/
    StatusItem: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    className: PropTypes.string,
  };

  static defaultProps = {
    statusInfos: {},
    statusInfosIdStack: [],
    showStepper: false,
  };

  constructor(props, context) {
    super(props, context)

    let activeId = undefined

    if (props.statusInfosIdStack.length) {
      activeId = props.statusInfosIdStack[0]
    }

    this.state = {
      activeId,
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.statusInfosIdStack.length
      !== this.props.statusInfosIdStack.length
    ) {
      this.setState({
        activeId: (nextProps.statusInfosIdStack || [])[0],
      })
    }
  }

  componentWillUnmount() {
    clearInterval(this.scrollInterval)
  }

  @autobind
  handlePrev() {
    const stack = this.props.statusInfosIdStack || []
    let index = stack.indexOf(this.state.activeId)
    index = Math.max(index - 1, 0)
    const activeId = stack[index]
    this.setState({
      activeId,
    })
  }

  @autobind
  handleNext() {
    const stack = this.props.statusInfosIdStack || []
    let index = stack.indexOf(this.state.activeId)
    index = Math.min(index + 1, stack.length)
    const activeId = stack[index]
    this.setState({
      activeId,
    })
  }

  @autobind
  handleMouseMove(e) {
    if (this.refScrollContainer) {
      let speed = 4
      const paddingTop = 10
      const paddingBottom = 20
      const boundingBox = this.refScrollContainer.getBoundingClientRect()
      let y = e.pageY - boundingBox.top

      this.scrollDir = 0
      if (y < paddingTop) {
        this.scrollDir = -1
      }
      else {
        y = boundingBox.height - y
        if (y < paddingBottom) {
          this.scrollDir = 1
          speed *= 1 - y / paddingBottom
        }
      }
      this.scrollSpeed = speed

      if (!this.isScrolling) {
        this.isScrolling = true
        this.scroll()
      }
    }
  }

  @autobind
  handleMouseLeave() {
    if (this.refScrollContainer) {
      this.scrollDir = -1
      this.scrollSpeed = 10
    }
  }

  @autobind
  scroll() {
    if (!this.isScrolling) {
      return
    }
    requestAnimationFrame(this.scroll)
    if (this.refScrollContainer) {
      this.refScrollContainer.scrollTop += this.scrollDir * this.scrollSpeed
      if (this.refScrollContainer.scrollTop <= 0) {
        this.isScrolling = false
      }
    }
  }

  renderTools() {
    const stack = this.props.statusInfosIdStack || []
    const index = stack.indexOf(this.state.activeId)
    const len = stack.length
    const remaining = (index >= 0 ? len - index : 1) - 1

    return (
      <div className={css.statusTools}>
        {remaining ? (
          <small>
            <FormattedMessage id="status.remaining" values={{ remaining }} />
          </small>
        ) : null}
        {index === 0 ? null : (
          <GenevaButton
            className="tiny round button"
            disabled={this.activeId <= 0}
            onClick={this.handlePrev}
          >
            <Icon name="ion-arrow-up-b" />
          </GenevaButton>
        )}

        {remaining ? (
          <GenevaButton
            className="tiny round button"
            disabled={this.activeId >= len - 1}
            onClick={this.handleNext}
          >
            <Icon name="ion-arrow-down-b" />
          </GenevaButton>
        ) : null}
      </div>
    )
  }

  render() {
    const { statusInfos, statusInfosIdStack, className } = this.props

    const len = statusInfosIdStack.length

    let { StatusItem } = this.props

    if (!len) {
      return null
    }

    if (!StatusItem) {
      StatusItem = PureStatusItem
    }

    return (
      <div className="grid-block align-center">
        <div
          onMouseLeave={this.handleMouseLeave}
          onMouseMove={this.handleMouseMove}
          className={classNames(
            'grid-block',
            'vertical',
            'shrink',
            'status-list',
            css.statusList,
            className
          )}
        >
          <div
            key={css.statusListInner}
            className={classNames(
              'grid-block',
              'vertical',
              css.statusListInner
            )}
            ref={ref => (this.refScrollContainer = ref)}
          >
            {(statusInfosIdStack || [])
              .filter(infoId => (this.props.showStepper ? infoId === this.state.activeId : true)
              )
              .filter(
                infoId => statusInfos[infoId] && statusInfos[infoId].name
              )
              .sort((infoId1, infoId2) => {
                return (
                  getPriority(statusInfos[infoId2].priority)
                  - getPriority(statusInfos[infoId1].priority)
                )
              })
              .map(infoId => (
                <StatusItem
                  key={statusInfos[infoId].lastUpdated}
                  item={statusInfos[infoId]}
                />
              ))}
          </div>
          {this.props.showStepper && len ? this.renderTools() : null}
        </div>
      </div>
    )
  }
}

export { StatusList as default }
