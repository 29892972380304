/* eslint no-underscore-dangle: "off" */
import {
  autorun,
  action,
  observable,
  extendObservable,
  isObservableMap,
  computed,
  toJS,
} from 'mobx'

import { defaultChannel } from '../config'
import {
  i18n,
  getWidgetTemplateNameById,
  getUserNameById,
  getStatusNameById,
  getIdByStatusName,
} from '../shared/utils'

import Model from '../shared/model'

import { talkative, validate } from '../shared/decorators/model'

import { lockable } from '../shared/decorators/communicator'

import { CircularDependencyFreeStore } from '../CircularDependencyFreeStore'

import KeyValueAccessor from './helpers/KeyValueAccessor'

const getProjectChannel = (projectStore) => {
  return projectStore && projectStore.hasCurrent
    ? projectStore.current.channelShortcut
    : null
}

@talkative
@validate({
  releasePublicationLockAt: 'dateOrNull|default:null',
  name: 'string|minLen:2',
  status: 'numberOrString|default:20',
})
@lockable
export default class Widget extends Model {
  static INVALID_INSTANCE_VERSION = -1;

  static INVALID_MODULE_ID = -1;

  static INVALID_ORDER_ID = -1;

  // -- Defaul properties

  static autoSavable = true;

  // DO NOT declare NON-mobx props here, they would become
  // prototype props
  static get defaultProps() {
    return {
      moduleId: Widget.INVALID_MODULE_ID,
      orderId: Widget.INVALID_ORDER_ID,
      isInPage: false,
      status: 20,
      channels: [],
      i18n: {},
    }
  }

  // MOBX observables
  @observable channels = [];

  @observable content = [];

  @observable createdAt = '';

  @observable i18n = {};

  @observable meta = {};

  @observable keyValue = observable.map({});

  // the status for this widget
  @observable _status = 20;

  // the template id of this widget
  // @observable widgetTemplateId = ''
  @observable updatedAt = '';

  // whether or not this item is marked as active
  @observable isActive = false;

  @observable complete = false;

  @observable publicationNotAllowed = false;

  @observable releasePublicationLockAt = null;

  // Ids of projects where the article is currently used
  @observable projectIds = [];

  // this getter is for consistence
  @computed get channelShortcut() {
    const channelShortcut = this.channel
    return channelShortcut
  }

  @computed get asJSON() {
    return this.getJSON()
  }

  get contentType() {
    return 'widget'
  }

  get templateId() {
    return this.widgetTemplateId
  }

  set templateId(id) {
    this.widgetTemplateId = id
  }

  // an widget is editable if and only if the following
  // criteria are met
  //
  // it IS NOT locked
  // it HAS NO versionNumber
  // is IS NOT deleted
  get isEditable() {
    return (
      this.isInEditableStatus
      && !this.isLocked
      && !this.isVersion
      && !this.isDeleted
    )
  }

  /**
   * @private
   */
  get isInEditableStatus() {
    return (
      this.isInStatus('private,draft,released')
      && this.isInEditableVersion
      && !this.isLockedByOther()
    )
  }

  get statusName() {
    if (this.isDeleted) {
      return 'deleted'
    }

    if (this.isLocked) {
      return 'locked'
    }

    return getStatusNameById(this.status)
  }

  get status() {
    if (this.isDeleted) {
      return 50
    }

    if (this.isLocked) {
      return 40
    }

    return this._status
  }

  set status(status) {
    this._status = status
  }

  // TODO: This needs to be implemented properly. Just what "is locked". Is
  // locked also true in case the current user locked the element?
  get isLocked() {
    return false
  }

  get isDeleted() {
    return !!this.deletedAt
  }

  statusByName(name) {
    return getIdByStatusName(name)
  }

  isInStatus(status) {
    const currentStatus = this.statusName || 'draft'

    if (typeof status !== 'string') {
      if (status.indexOf(',') !== -1) {
        status = status.trim().split(/\s*,\s*/g)
      }
      else {
        status = [status]
      }
    }

    return status.indexOf(currentStatus) >= 0
  }

  // getters for easy access
  @computed get name() {
    return i18n(this, 'name', this.createdIso)
  }

  get templateName() {
    return getWidgetTemplateNameById(this.widgetTemplateId)
  }

  get createByName() {
    return getUserNameById(this.createdBy)
  }

  get updatedByName() {
    return getUserNameById(this.updatedBy)
  }

  constructor(store, rawData = {}, opts = {}) {
    if (!store) {
      console.error('model creation requires a store')
    }
    // set standard properties (like store) and calls parse, set, and handleModelCreated
    super(store, rawData, opts)

    this.initLockable()
    this.initValidate()

    autorun('Change channel after project switch.', () => {
      const shouldAutosave = this.autoSave
      this.autoSave = false

      // disable any automatic saving on all changes made in here
      const { projectStore } = CircularDependencyFreeStore

      // listens on project change
      const projectChannel = getProjectChannel(projectStore, this)

      // update the channel if needed in placeholder accessors and writers
      if (projectChannel) {
        if (projectChannel !== this.channel) {
          this.channel = projectChannel
        }
        this.usesProjectChannel = true
      }
      else {
        this.usesProjectChannel = false
      }

      // set the current project
      this.parentProject
        = projectStore && projectStore.hasCurrent ? projectStore.current : null

      // reset autoSaving to original state
      this.autoSave = shouldAutosave
    })
  }

  parse(data = {}) {
    if (data.data) {
      data = data.data
    }

    data.id = data.id ? data.id * 1 : undefined

    if (data.deletedAt) {
      data.status = 50
    }

    data._status = data.status || data._status || Widget.defaultProps.status
    delete data.status

    if (
      (!data.meta || Array.isArray(data.meta))
      && !Object.keys(this.meta || {}).length
    ) {
      data.meta = {}
    }

    // Workaround for faulty channel logic
    if (!data.channel) {
      const { projectStore } = CircularDependencyFreeStore

      const projectChannel = getProjectChannel(projectStore, this)

      if (projectChannel) {
        data.channel = projectChannel
      }
    }

    if (data.content) {
      this.parseContent(data.content)
    }

    return data
  }

  /**
   * Ensure a widget's content is correct.
   */
  parseContent(content) {
    content.forEach((item) => {
      if (Array.isArray(item)) {
        this.parseContent(item)
      }
      else if ('id' in item && item.id !== undefined) {
        // ensure ids are numbers
        item.id = item.id * 1
      }
    })
  }

  @action
  setMeta(newMeta) {
    if (!this.meta) {
      this.meta = {}
    }
    extendObservable(this.meta, newMeta)
    Object.keys(this.meta).forEach((key) => {
      if (this.meta[key] === undefined) {
        delete this.meta[key]
      }
    })
    this.save()
  }

  prepareDataForSet(data) {
    if ('name' in data) {
      i18n(data, 'name', this.createdIso, data.name)
      delete data.name
    }
    return data
  }

  save() {
    if (!this.isValid) {
      return Promise.reject(new Error('Model data invalid'))
    }
    return this.store.save(this.id, this)
  }

  set(data, opts = {}) {
    this.autoSave = false

    // always first clone the data before we do something with it
    data = this.parse(this.prepareDataForSet(data))

    // if the model has an id already remove it from the new data to ensure
    // the existing id will not be overwritten
    if (this.id) {
      delete data.id
      const ignoreKeys = [
        'createdAt',
        'createdBy',
        'createdChannel',
        'createdIso',
        'widgetTemplateId',
        'sourceId',
      ]
      ignoreKeys.forEach((key) => {
        if (this[key]) {
          delete data[key]
        }
      })
    }

    const keyValue = data.keyValue || {}
    delete data.keyValue

    extendObservable(this, toJS(data))

    if (keyValue) {
      // only update the placeholders if
      // a) this is a new model which has no id yet or
      // b) the action causing the set was NOT a call to 'save'
      if (!this.id || opts.action !== 'save') {
        this.updateKeyValueObject(keyValue)
      }
    }

    this.complete = this.complete || !!keyValue

    this.autoSave = this.constructor.autoSavable
  }

  updateKeyValueObject(newKeyValue = {}) {
    if (!this.keyValue) {
      this.keyValue = observable.map({})
    }

    if (Object.prototype.toString.call(newKeyValue) !== '[object Object]') {
      console.warn('Trying to set a non-object as keyValue object.')
      return
    }

    let newKeyValueMap = newKeyValue

    if (!isObservableMap(newKeyValueMap)) {
      newKeyValueMap = observable.map(newKeyValueMap)
    }

    this.keyValue.merge(newKeyValueMap)

    // only create the key value accessor if it does not
    // exist yet
    if (!this.keyValueAccessor) {
      this.keyValueAccessor = new KeyValueAccessor(this)
    }
  }

  /** Returns model in a form suitable for the backend */
  getJSON() {
    return {
      id: this.id,
      languages: toJS(this.languages),
      i18n: toJS(this.i18n),
      status: this._status,
      channels: toJS(this.channels),
      content: toJS(this.content),
      createdChannel: this.createdChannel,
      createdIso: this.createdIso,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      deletedAt: this.deletedAt,
      createdBy: this.createdBy,
      updatedBy: this.updatedBy,
      deletedBy: this.deletedBy,
      widgetTemplateId: this.widgetTemplateId,
      meta: this.meta,
      publicationNotAllowed: this.publicationNotAllowed,
      // eslint-disable-next-line no-restricted-globals
      releasePublicationLockAt: isNaN(Date.parse(this.releasePublicationLockAt))
        ? null
        : this.releasePublicationLockAt,

      keyValue: toJS(this.keyValue),
      projectIds: toJS(this.projectIds),
    }
  }

}
