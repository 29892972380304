// https://developer.mozilla.org/en-US/docs/Web/API/Element/matches
function matchesImpl() {
  if (!Element.prototype.matches) {
    // IE and PhantomJS support -> polyfill
    Element.prototype.matches = 
    Element.prototype.matchesSelector || 
    Element.prototype.mozMatchesSelector ||
    Element.prototype.msMatchesSelector || 
    Element.prototype.oMatchesSelector || 
    Element.prototype.webkitMatchesSelector ||
    function(s) {
        var matches = (this.document || this.ownerDocument).querySelectorAll(s),
            i = matches.length;
        while (--i >= 0 && matches.item(i) !== this) {}
        return i > -1;            
    };
  }

  if (document.body.matches) {
    matchesImpl = function (tag, selector) {
      return tag.matches && tag.matches(selector)
    }
  }
  else {
    throw new Error('matchesImpl is not defined')
  }
}


function closestImpl(start, selector) {
  const body = document.body
  let match = null

  if (start.nodeType === Node.TEXT_NODE) {
    start = start.parentNode
  }

  while (start && start !== body) {
    
    if (matchesImpl(start, selector)) {
      match = start
      break
    }

    start = start.parentNode
  }

  return match
}


function prevImpl(start, selector) {
  let previous
  const previousList = []

  if (!start) {
    return null
  }

  if (start.nodeType === Node.TEXT_NODE) {
    start = start.parentNode
  }

  while (start) {

    do {
      previous = start.previousSibling
    } while (
      previous &&
      (previous.nodeType === Node.TEXT_NODE ||
        (selector ? !matchesImpl(previous, selector) : false))
    )


    if (!previous) {
      break
    }

    previousList.push(previous)

    start = previous
  }

  return previousList

}

export { matchesImpl as matches }
export { prevImpl as prev }
export { closestImpl as closest }
