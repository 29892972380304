import React from 'react'
import classNames from 'classnames'
import ContentLoadingBox from '../../shared/components/ContentLoadingBox'

const css = /* typeof window === 'undefined' ? {} : */require('../styles.scss')

const LoadingScreenOverlay = () => {
  return (<div
    className={
      classNames(css.loadingScreenOverlay)
    }
  >
    <ContentLoadingBox
      spinner
      inverseColors
      message={{
        id: 'page.creating',
      }}
    />
  </div>)

}

LoadingScreenOverlay.propTypes = {
}

export { LoadingScreenOverlay as default }
