import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import classNames from 'classnames'
import { autobind } from 'core-decorators'
import { Link } from 'react-router'

import config from '../../config'
import Navbar from '../components/Navbar'
import DevArticleTools from '../components/DevArticleTools'
import ManagedTextField from '../../shared/components/ManagedTextField'
import {
  VALIDATION_ID_TEXT_EDITOR_WILDCARD,
  GENEVA_DEFAULT_ARTICLE_NAME,
} from '../../shared/const'
import ValidationInfo from './ValidationInfo'
import MainContainer from './Main'

const css = /* typeof window === 'undefined' ? {} : */require('../styles.scss')


@inject('customLocal') @observer
export default
class ArticleContainer
  extends Component {

  static propTypes = {
    auth: PropTypes.object.isRequired,
    article: PropTypes.object.isRequired,
    context: PropTypes.object.isRequired,
    customLocal: PropTypes.object.isRequired,
    image: PropTypes.object.isRequired,
    template: PropTypes.object.isRequired,
    ui: PropTypes.object.isRequired,
  }

  componentDidMount() {

    this.props.ui.updatePaneState({
      articleListOpen: false,
      articleListColumnSize: 2
    })

  }

  componentWillUnmount() {

    const { context } = this.props

    // remove all contexts related to the article editor
    context.set(null)
    context.removeContextInfo(VALIDATION_ID_TEXT_EDITOR_WILDCARD)

    if (this.handler) {

      this.handler()
      this.handler = null

    }

  }

  @autobind
  handleNameChange({ target: { value } }) {
    this.props.article.current.set({ name: value })

    // setMeta will trigger a save
    this.props.article.current.setMeta({ userSetName: true })
  }


  renderBrand() {
    const article = this.props.article.current
    const defaultName = `${GENEVA_DEFAULT_ARTICLE_NAME} (${article.templateName})`

    if (article && article.id) {

      return (<div className={css.titleEditContainer}>
        <ManagedTextField
          value={this.props.article.current.name}
          onChange={this.handleNameChange}
          className={css.titleEdit}
          defaultName={defaultName}
        />
        {this.renderValidationInfo()}
        {this.renderChannelInfo()}
        {!config.isProduction
          ? <DevArticleTools
            article={this.props.article}
          />
          : null
        }
      </div>)
    }

    return (<Link className="app-logo" to="/">Geneva</Link>)
  }

  renderValidationInfo() {

    return (<span className={classNames(
      'title-edit-container-info',
      css.articleValidationInfo
    )}>
      <ValidationInfo article={this.props.article.current} />
    </span>)

  }

  renderChannelInfo() {
    // removing channel info from the article name field and this should remove the mouse over also
    // return (<span className={classNames(
    //   'title-edit-container-info',
    //   css.articleChannelInfo
    // )}>
    //   <ChannelInfo article={this.props.article.current} />
    // </span>)
  }


  renderNavbar() {

    return (<Navbar
      navbarStyle={`primary ${css.articleTitlebar}`}
      ui={this.props.ui}
      brand={this.renderBrand()}
      id="articleAuthoring"
      language={this.props.article.current && this.props.article.current.createdIso}
      article={this.props.article}
    />)

  }

  render() {

    return (<div className="grid-frame vertical">
      {this.renderNavbar()}

      <div
        className={classNames('grid-block', 'noscroll', {
          hidden: this.props.image.editing
        })}
      >

        <div className="grid-block">
          <div className="grid-block">

            <MainContainer
              {...this.props}
            />

          </div>
        </div>
      </div>
    </div>)

  }

}
