import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { autorun } from 'mobx'
import { observer } from 'mobx-react'
import {
  store as widgetTemplateStore,
  WidgetTemplateDetailsContainer
} from '../../widgetTemplate'
import { i18n } from '../../shared/utils'
import ContentLoadingBox from '../../shared/components/ContentLoadingBox'
import { default as Menubar } from '../components/Menubar'

@observer
export default class WidgetDetailsContainer extends Component {

  static propTypes = {
    widget: PropTypes.object.isRequired,
    project: PropTypes.object.isRequired,
    page: PropTypes.object.isRequired,
    context: PropTypes.object.isRequired,
    customLocal: PropTypes.object.isRequired,
    widgetTemplate: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
    ui: PropTypes.object.isRequired
  }

  constructor(props) {

    super(props)

    this.state = {}

    this.handler = []

  }

  componentDidMount() {

    this.handler.push(autorun('Once current item changes', () => {

      const current = this.getCurrent()

      if (current && current.widgetTemplateId) {
        this.loadTemplate(current.widgetTemplateId)
      }

    }))

  }

  componentWillUnmount() {

    if (this.handler) {
      this.handler.forEach(handler => handler())
      this.handler = null
    }

  }

  getCurrent() {

    return this.props.widget.hasCurrent && this.props.widget.current

  }

  loadTemplate(id) {

    widgetTemplateStore.openById(id)

  }


  renderMenubar() {

    return (<Menubar
      ref="menubar"
      widget={this.props.widget}
      project={this.props.project}
      page={this.props.page}
      context={this.props.context}
      ui={this.props.ui}
      params={this.props.params}
    />)

  }

  renderLoadingScreen() {
    const { widget: { current } } = this.props

    const title = i18n(current, 'name')

    return (<ContentLoadingBox
      spinner
      message={{
        id: title
          ? 'widget.loading-name'
          : 'widget.loading',
        values: {
          title: i18n(current, 'name')
        }
      }}
    />)

  }


  renderTemplateContent() {

    return (
      <WidgetTemplateDetailsContainer
        context={this.props.context}
        customLocal={this.props.customLocal}
        widget={this.props.widget}
        widgetTemplate={this.props.widgetTemplate}
      />
    )

  }

  renderWidgetContent() {

    const { widget } = this.props

    return (
      widget.hasCurrent && widget.current.complete && widget.current.content
        ? this.renderTemplateContent()
        : this.renderLoadingScreen()

    )

  }

  render() {

    return (<div className="grid-block vertical">

      {this.renderMenubar()}

      <div className="grid-block">
        {
          this.renderWidgetContent()
        }
      </div>

    </div>)

  }

}
