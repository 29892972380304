import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import { createConfirmation, confirmable } from 'react-confirm'
import { autobind } from 'core-decorators'
import Icon from '../../icons'
import { Dialog } from '../Dialog'
import GenevaButton from '../../../ui/components/GenevaButton'

const css = /* typeof window === 'undefined' ? {} : */ require('./styles.scss')

@confirmable
class Alert extends Component {
  static propTypes = {
    show: PropTypes.bool, // indicates if the dialog is shown or not.
    proceed: PropTypes.func, // call to close the dialog with promise resolved.
    cancel: PropTypes.func, // call to close the dialog with promise rejected.
    dismiss: PropTypes.func, // call to only close the dialog.
    confirmation: PropTypes.string, // arguments of your confirm function
    title: PropTypes.string, // arguments of your confirm function
    options: PropTypes.object, // arguments of your confirm function
  };

  @autobind
  getFocus() {
    return this.focus
  }

  @autobind
  handleRef(el) {
    if (el !== null) {
      this.focus = el
    }
  }

  @autobind
  handleClick(e) {
    if (e && e.preventDefault) {
      e.preventDefault()
    }
    this.props.dismiss()
  }

  renderList(options) {
    if (options.list) {
      return (
        <ul>
          <br />
          {options.list.map((item) => {
            return <li key={item}>{item}</li>
          })}
        </ul>
      )
    }
    return ''
  }

  @autobind
  renderHeader() {
    return this.props.title
  }

  @autobind
  renderFooter() {
    return (
      <div className="grid-block align-right">
        <GenevaButton
          type="submit"
          onClick={this.handleClick}
          className="small primary button"
          ref={this.handleRef}
        >
          OK
        </GenevaButton>
      </div>
    )
  }

  render() {
    const { show, confirmation, options } = this.props

    return (
      <Dialog
        className={css.alertDialog}
        isOpen={show}
        renderFooter={this.renderFooter}
        renderHeader={this.renderHeader}
        title="Title"
        focusEl={this.getFocus}
      >
        <div className="grid-block medium-2 align-center">
          <Icon size={40} name="ion-alert-circled" />
        </div>
        <div className="grid-block medium-10 vertical">
          <div className="grid-content confirmation">{confirmation}</div>
          <div className="grid-content">{this.renderList(options)}</div>
        </div>
      </Dialog>
    )
  }
}

const alert = createConfirmation(Alert)

export default function (
  confirmation: string | Object,
  title: string = '',
  options: Object = {}
) {
  return alert({ confirmation, title, options })
}
