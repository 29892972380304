import {
  observable,
  extendObservable,
  intercept,
  observe,
  isObservable,
} from 'mobx'

import Validation, { ValidationInvalidError } from '../../utils/validation'

export default function validate(validators) {
  return (target) => {
    const provider = target.prototype

    provider.initValidate = function initValidate() {
      const keys = Object.keys(validators)

      this.validFields = observable(
        keys.reduce((memo, key) => {
          memo[key] = true
          return memo
        }, {})
      )

      // this is the same as @computed get isValid() {...}
      // @see http://mobxjs.github.io/mobx/refguide/computed-decorator.html
      extendObservable(this, {
        isValid: () => {
          return Object.keys(this.validFields).every(
            field => this.validFields[field]
          )
        },
      })

      keys.forEach((key) => {
        const validator = validators[key]
        let isObservableValue = isObservable(this, key)
        if (!isObservableValue) {
          key = `_${key}`
          isObservableValue = isObservable(this, key)
          if (!isObservableValue) {
            // eslint-disable-next-line max-len
            console.warn(
              `Validator Warning: There is no observable \`${key}\` or \`_${key}\` in \`${this.constructor.name}\`! Not registering for \`${key}\`.`
            )
          }
        }

        // register enforcers. Enforcers act like validators, but if a value is
        // inorrect, they modify it to a correct one
        if (Validation.isEnforce(validator)) {
          if (isObservableValue) {
            intercept(this, key, (change) => {
              try {
                change.newValue = Validation.ensure(validator, change.newValue)
              }
              catch (ex) {
                if (ex instanceof ValidationInvalidError) {
                  // ignore the new value
                  return null
                }
                console.error('Unknown Validation Error:', ex)
              }

              return change
            })
          }
        }

        if (isObservableValue) {
          observe(this, key, (newValue) => {
            this.validFields[key] = Validation.isValid(validator, newValue)
          })
        }
      })
    }
  }
}
