import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { autobind } from 'core-decorators'
import TransformationInfo, {
  TransformationState,
} from '../components/TransformationInfo'

/**
 * This overall structure works by the theory that there are possibly multiple
 * elements that are positionable, but only one background/backdrop for the positionable items
 * Therefore, the background item is attachend to each positionable item as a singleton for them all the use
 *
 * Also, this is done with more complicated references since there could be a bit of html between the
 * 'Container' and the 'Positionable' items
 */

export default function connectPositionableToContext(
  Positionable,
  parentProps,
  contextStore
) {
  if (!contextStore) {
    throw new Error(
      'To connect an Positionable with a context, '
        + '`connectPositionable` expects to get passed a `contextStore` argument '
        + 'as third parameter.'
    )
  }

  if (!parentProps.articlePlaceholders) {
    // eslint-disable-next-line max-len
    throw new Error(
      '`connectPositionableToContext` requires an articlePlaceholders object '
        + " in it's parentProps"
    )
  }

  const checkIsEditable = () => {
    return (
      parentProps.env.CM && parentProps.articlePlaceholders.article.isEditable
    )
  }

  /** Write change to Backend */
  const updatePlaceholder = ({ value, pid }) => {
    const key = 'value'

    parentProps.articlePlaceholders.set(pid, key, {
      type: 'keyValue',
      value: {
        // this allows pid reusal for several props
        ...parentProps.articlePlaceholders.get(pid, 'value'),
        ...value,
      },
    })
  }

  /** This is dealing with a change to a Positionable element and notifying the template */
  const handleChange = ({ target, props, pid }) => {
    const value = {
      left: target.value.x,
      top: target.value.y,
    }

    updatePlaceholder({ value, pid })

    if (props.onChange) {
      props.onChange({
        target: {
          value,
        },
      })
    }
  }

  /** General purpose update of a movable element, handles resolving calculations before saving */
  const saveData = ({ event, pid, containerRef, props, opts = {} }) => {
    if (checkIsEditable()) {

      // opts allows for fake drag action to still be calculated (like for limited drag and drop)
      if (event.type === 'dragend' || opts.type === 'dragend') {
        const clientRect = containerRef.getBoundingClientRect()

        // Set X and Y and handle min or max
        let x = event.clientX - clientRect.left - (opts.shiftX || 0)
        x = x < 0 ? 0 : x
        if (opts.maxX && x > opts.maxX) {
          x = opts.maxX
        }
        let y = event.clientY - clientRect.top - (opts.shiftY || 0)
        y = y < 0 ? 0 : y
        if (opts.maxY && y > opts.maxY) {
          y = opts.maxY
        }

        // disable updating values if they are 'limited'
        if (opts.limit) {
          // get orginal to help if a limit is set
          const original = parentProps.articlePlaceholders.get(pid, 'value')

          if (opts.limit === 'horizontally') {
            y = original.top
          }
          else if (opts.limit === 'vertically') {
            x = original.left
          }
        }

        handleChange({ target: { value: { x, y } }, props, pid })
      }
      if (props.onDragEnd) {
        setTimeout(() => {
          props.onDragEnd(pid)
        }, 300)
      }
    }
  }

  @observer
  class ConnectedPositionable extends Component {
    static propTypes = {
      pid: PropTypes.string.isRequired,
      onChange: PropTypes.func,
    };

    static contextTypes = {
      getContainerRef: PropTypes.func,
      transformationState: PropTypes.shape({
        isActive: PropTypes.bool,
        setState: PropTypes.func,
      }),
    };


    @autobind
    handleDragStart(...args) {
      if (this.context && this.context.transformationState) {
        this.context.transformationState.setState({ isActive: true })
      }
      if (this.props.onDragStart) {
        this.props.onDragStart(...args)
      }
    }

    /**
     * @param {MouseEvent} event
     * @param {*} opts: {type: String, limit: String, shiftX: Number, shiftY: Number}
     */
    @autobind
    handleDragEnd(event, opts = {}) {
      if (this.context && this.context.transformationState) {
        this.context.transformationState.setState({ isActive: false })
      }

      const containerRef = this.context.getContainerRef()

      saveData({
        event,
        pid: this.props.pid,
        containerRef,
        props: this.props,
        opts
      })
    }

    render() {
      const dragEvents = {}

      dragEvents.onDragStart = this.handleDragStart
      dragEvents.onDragEnd = this.handleDragEnd

      const value = parentProps.articlePlaceholders.get(this.props.pid)
      const containerRef = this.context.getContainerRef()

      return (
        <Positionable
          {...this.props}
          {...dragEvents}
          containerRef={containerRef}
          pid={this.props.pid}
          value={value}
          limit={this.props.limit}
          locked={!checkIsEditable()}
        />
      )
    }
  }

  class ConnectedPositionableContainer extends Component {
    static contextTypes = {
      transformationState: PropTypes.shape({
        isActive: PropTypes.bool,
        setState: PropTypes.func,
      }),
    };

    static childContextTypes = {
      getContainerRef: PropTypes.func,
      transformationState: PropTypes.shape({
        isActive: PropTypes.bool,
      }),
    };

    constructor(props, context) {
      super(props, context)
      this.containerRef = null
      // if anything around already created transformation tools, use those,
      // otherwise create new tools
      this.transformationState
        = context && context.transformationState
          ? context.transformationState
          : new TransformationState()
    }

    /**
     * Functions that allow passing information from the Container singleton to the
     * specific positionable elements (ConnectedPositionable)
     */
    getChildContext() {
      const thisComponent = this
      return {
        getContainerRef: () => {
          return thisComponent.containerRef
        },
        transformationState: this.transformationState,
      }
    }

    @autobind
    handleChildDrop(event, pid) {
      if (!pid) {
        console.warn('drop missing pid')
        return
      }

      // tranformation State is unknown. Required
      if (this.transformationState) {
        this.transformationState.setState({ isActive: false })
      }

      saveData({
        event,
        pid,
        containerRef: this.containerRef,
        props: this.props,
        opts: {}
      })
    }

    render() {
      return (
        <Positionable.Container
          ref={(ref) => {
            this.connectedContainerRef = ref
          }}
          containerRef={(ref) => {
            this.containerRef = ref
          }}
          handleChildDrop={this.handleChildDrop}
          locked={!checkIsEditable()}
          {...this.props}
        />
      )
    }
  }

  ConnectedPositionable.TransformationInfo = TransformationInfo

  ConnectedPositionable.Container = ConnectedPositionableContainer

  return ConnectedPositionable
}
