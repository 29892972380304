function toString(val) {

  return Object.prototype.toString.call(val)

}

function isOptional(def) {

  return def[def.length - 1] === '?'

}

function valMatchesType(val, def) {

  let cleanDef = def.replace(/\?$/, '')
  cleanDef = def[0].toUpperCase() + cleanDef.substr(1)

  let matches = toString(val) === `[object ${cleanDef}]`

  if (!matches
    && (val === undefined || val === null)
    && isOptional(def)) {

    matches = true

  }

  return matches

}

const types = {
  string: true,
  number: true,
  date: true,
  object: true,
  array: true,
  undefined: true,
  '*': true
}

function typeExists(type) {

  type = type.replace(/\?$/, '')
  return types[type]

}

function ensureOverloadExplicit(keys, params, ...args) {

  keys.forEach((key, index) => {

    const val = args[index]

    const param = params[key]

    if (!typeExists(param)) {

      throw new Error(`Type ${param} for ${key} (Argument#${index}) is not defined`)

    }

    if (!valMatchesType(val, param)) {

      if (!isOptional(param)) {

        // eslint-disable-next-line max-len
        throw new Error(`Invalid argument type for ${key} (Argument#${index}): Expected argument of type ${param}, but got ${toString(val)}`)

      }

      args.splice(index, 0, undefined)

    }

  })

  return args

}


function ensureOverload(argTypes, ...args) {

  argTypes.forEach((argType, index) => {

    const val = args[index]

    if (!typeExists(argType)) {

      throw new Error(`Type ${argType} for Argument#${index} is not defined`)

    }

    if (!valMatchesType(val, argType)) {

      if (!isOptional(argType)) {

        // eslint-disable-next-line max-len
        throw new Error(`Invalid argument type for Argument#${index}: Expected argument of type ${argType}, but got ${toString(val)}`)

      }

      args.splice(index, 0, undefined)

    }

  })

  return args

}


/**
 * This is a decorator that cares for method overloading.
 */
export function overload(params) {

  const keys = Object.keys(params || {})

  return function overloadDecorator(target, name, descriptor) {

    const base = descriptor.value

    descriptor.initializer = function initializer(...args) {

      args = ensureOverloadExplicit(keys, params, ...args)

      return base.apply(this, args)

    }

    return descriptor

  }

}

export function overloadable(target) {

  if (!target) {

    throw new Error('A target is required for the overloadable decorator')

  }

  (target.prototype || target)
    .overload = function overloadImpl(argTypes, ...args) {

      [...argTypes].reverse().reduce((hasRequired, type) => {

        const isOpt = isOptional(type)

        if (hasRequired && isOpt) {
          throw new Error('There cannot be an required property after any optional property occured')
        }
        else {
          return !isOpt
        }

      }, false)

      return ensureOverload(argTypes, ...args)

    }

}
