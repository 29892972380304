import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import { toJS } from 'mobx'
import { observer } from 'mobx-react'
import { autobind } from 'core-decorators'

import WidgetContentAction from '../../ui/actions/WidgetContentAction'
import ScrollContainer from '../../../shared/components/ScrollContainer'

export default function connectContentRendererToContext(
  ContentRenderer,
  parentProps
) {
  @observer
  class PMContextConnectedRenderer extends Component {
    static propTypes = {
      gr: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      gb: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      renderItem: PropTypes.func,
    };

    @autobind
    handleSort(items, draggedItem, toIndex) {
      // The timeout ensures that the item appears dragged. Otherwise the save-
      // ing and re-rendering may make the system lag a bit and the item seams
      // to hang in midair
      setTimeout(() => {
        const { page, widget } = parentProps

        const positionFrom = page.getContentPosition(widget)
        const positionTo = {
          ...positionFrom,
        }

        if (positionFrom) {
          const fromIndex = widget.content.indexOf(
            widget.content.find(i => i.id === draggedItem.id)
          )

          // sorting is currently only possible within the same contentRenderer,
          // so we can do this on owe own
          // const context = this.props.onGetSortContext(fromIndex, toIndex)
          positionFrom.content = [fromIndex]
          positionTo.content = [toIndex]

          const widgetRemoveAction = new WidgetContentAction(
            widget.content,
            positionFrom.content,
            WidgetContentAction.REMOVE
          )

          const widgetInsertAction = new WidgetContentAction(
            widget.content,
            positionTo.content,
            WidgetContentAction.INSERT
          )

          try {
            page.moveContent(positionFrom, positionTo)

            const item = widgetRemoveAction.execute()

            if (item && item.length > 0 && item[0]) {
              widgetInsertAction.execute(item[0])
            }
          }
          catch (ex) {
            console.error('Could not remove item', ex)
          }
        }
      }, 1)
    }

    render() {
      const env = toJS(parentProps.env)

      const sortProps = {}

      if (this.props.sortable) {
        sortProps.onSort = this.handleSort
      }

      const contentRenderer = (
        <ContentRenderer
          {...parentProps}
          env={env}
          {...this.props}
          {...sortProps}
          // widget.content is observable, so this will start listening
          content={parentProps.widget.content}
        />
      )

      if (this.props.sortable) {
        return (
          <ScrollContainer
            // Sortable uses mouseevents to emulate dragging, so the scroll
            // container has to use that as well
            mouseEventMode
          >
            {contentRenderer}
          </ScrollContainer>
        )
      }

      return contentRenderer
    }
  }

  return PMContextConnectedRenderer
}
