import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { autobind } from 'core-decorators'
import classNames from 'classnames'
import { FormattedMessage } from '../../translations'
import Icon from '../../shared/icons'
import linkDialog from '../../shared/containers/LinkDialog'
import GenevaButton from '../../ui/components/GenevaButton'


@observer
export default class LinkCMToolbar extends Component {


  static propTypes = {
    context: PropTypes.object,
    className: PropTypes.string,
    onEditLink: PropTypes.func,
  }


  @autobind
  handleEditLink() {

    const context = this.props.context.get(
      this.props.context.constructor.CONTEXT_TYPE_LINK
    )

    const data = context.value

    if (this.props.context.target) {
      data.currentContextType = this.props.context.target.type
    }

    // For Linkables it's never posible to define a content,
    // as they wrap their content already.
    data.contentNotPossible = true
    linkDialog({
      intl: this.props.intl,
      data
    }).then((linkData) => {
      context.updatePlaceholder(linkData)
    }).catch((err) => {
      // Catch the cancel event from the dialog
      console.log(err)
    })
  }

  hasValue(data) {
    switch (data.type) {
      case 'external':
        return !!data.externalUrl
      case 'internal':
        return !!data.internalPage
      case 'email':
        return !!data.emailAddress
      case 'tel':
        return !!data.telNumber
      default:
        return false
    }
  }

  render() {

    const context = this.props.context.get(
      this.props.context.constructor.CONTEXT_TYPE_LINK
    )

    const hasValue = context && context.value && this.hasValue(context.value)


    return (<div
      className={classNames(this.props.className, 'toolbar', 'v-align')}
    >

      <GenevaButton className="small button plain"
        onClick={this.handleEditLink}
      >
        <Icon name="fa-link" />&nbsp;
        {hasValue
          ? <FormattedMessage id="link.edit" />
          : <FormattedMessage id="link.create" />
        }

      </GenevaButton>


    </div>)

  }

}
