import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import Modal from 'react-modal'
import { autobind } from 'core-decorators'
import classNames from 'classnames'
import { FormattedMessage } from '../../../translations'
import GenevaButton from '../../../ui/components/GenevaButton'
import { testClass } from '../../utils'
const css = /* typeof window === 'undefined' ? {} : */ require('./styles.scss')

class Dialog extends Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    title: PropTypes.string,
    className: PropTypes.string,

    modalStyle: PropTypes.object,

    children: PropTypes.node,

    handleAfterOpen: PropTypes.func,
    handleRequestClose: PropTypes.func,

    renderHeader: PropTypes.func,
    renderFooter: PropTypes.func,

    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onCommit: PropTypes.func,
    onAfterOpen: PropTypes.func,

    focusEl: PropTypes.func,
    id: PropTypes.string,
    withoutFooter: PropTypes.bool,
  };

  static defaultProps = {
    title: 'DialogTitle',
    isOpen: false,
  };

  constructor(props) {
    super(props)
    this.state = {
      isOpen: props.isOpen,
    }
  }

  componentDidMount() {
    // This is a workaround!
    // Upon opening a modal the focus is taken away from the elements inside it,
    // with the timeout it is restored afterwards.
    setTimeout(() => {
      const focusEl = this.props.focusEl
        ? this.props.focusEl() || this.focus
        : this.focus

      if (focusEl) {
        focusEl.focus()
      }
    }, 1)
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.isOpen !== nextProps.isOpen) {
      this.setState({
        isOpen: nextProps.isOpen,
      })
    }
  }

  @autobind
  getFocus() {
    return this.focus
  }

  @autobind
  handleRef(el) {
    if (el !== null) {
      this.focus = el
    }
  }

  @autobind
  handleCancelModal(e) {
    e.preventDefault()
    if (this.props.onCancel) {
      this.props.onCancel(e)
    }
    else {
      this.setState({ isOpen: false })
    }
  }

  @autobind
  handleChange(e) {
    if (this.props.onChange) {
      this.props.onChange(e)
    }
  }

  @autobind
  handleCommitModal(e) {
    e.preventDefault()
    e.stopPropagation()
    if (this.props.onCommit) {
      this.props.onCommit(e)
    }
    else {
      this.setState({ isOpen: false })
    }
  }

  @autobind
  handleRequestClose(e) {
    if (this.props.handleRequestClose) {
      this.props.handleRequestClose(e)
    }

    // Close when user hit escape
    if (e.keyCode === 27) {
      this.setState({ isOpen: false })
    }
  }

  renderFooter() {
    if (this.props.renderFooter) {
      return this.props.renderFooter()
    }

    return (
      <div className="grid-content text-right">
        <GenevaButton
          type="button"
          className="small button float"
          onClick={this.handleCancelModal}
        >
          <FormattedMessage id="link-dialog.cancel" />
        </GenevaButton>

        <GenevaButton
          type="submit"
          className="small primary button"
          onClick={this.handleCommitModal}
          ref={this.handleRef}
        >
          <FormattedMessage id="link-dialog.ok" />
        </GenevaButton>
      </div>
    )
  }

  renderHeader() {
    if (this.props.renderHeader) {
      return this.props.renderHeader()
    }

    return (
      <div
        className={classNames(
          'grid-content v-align',
          testClass('close-Dialog')
        )}
      >
        {this.props.title}
        <GenevaButton
          className="small button plain float-right close"
          onClick={this.handleCancelModal}
          type="button"
        >
          <i className="ion-ios-close-empty"></i>
        </GenevaButton>
      </div>
    )
  }

  render() {
    return (
      <Modal
        className={classNames('grid-block modal', this.props.className)}
        overlayClassName={css.tableDialogOverlay}
        isOpen={this.state.isOpen}
        onAfterOpen={this.props.onAfterOpen}
        onRequestClose={this.handleRequestClose}
        style={this.props.modalStyle}
        ariaHideApp={false}
      >
        <form
          className="grid-block vertical"
          onSubmit={this.handleCommitModal}
          onChange={this.handleChange}
          id={this.props.id}
        >
          <header className="grid-block shrink">{this.renderHeader()}</header>

          <main className="grid-block">{this.props.children}</main>

          {!this.props.withoutFooter ? (
            <footer className="grid-block shrink text-right">
              {this.renderFooter()}
            </footer>
          ) : null}
        </form>
      </Modal>
    )
  }
}

export { Dialog as default, Dialog }
