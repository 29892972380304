import Command, {ICommand} from './Command'

import {
  IDispatcher,
  DispatcherOpts,
  Store
} from "./command.d"


const extractStores = (requiredStores, allStores) => {

  return Object.keys(requiredStores).reduce((memo, storeName) => {
    memo[storeName] = allStores[storeName]
    return memo
  }, {})

}

export default
class Dispatcher
implements IDispatcher {

  private store: Store

  constructor(dispatcherOpts: DispatcherOpts) {
    Object.assign(this, dispatcherOpts)
  }

  /**
   * Main interface for command execution. Pass in the **class** of a command and
   * some data.
   * @method exec
   * @param  {ICommand} Command A command **class**.
   * @param  {any}      data    Any data that the command should use. It will be
   * handed off to the commands `set` method. So make sure that method can
   * handle the data.
   * @return {[type]}           The command execution result.
   */
  exec(
    Command: ICommand,
    data?: any,
    eventsBindings?: object
  ): Promise<any> | any {

    const command = this.createCommand(Command, data)

    return this.execCommand(command)

  }

  /**
   * Can be used to create a command manually. Pass in the **class** of a
   * command and some data.
   * @method createCommand
   * @param  {ICommand}    Command A command **class**.
   * @param  {any}         data    Any data that the command should use. It will
   * be handed off to the commands `set` method. So make sure that method can
   * handle the data.
   * @return {Command}             An instance of the command class handed in.
   */
  createCommand(Command: ICommand, data: any): Command {

    return new Command({
      ...data,
    })

  }

  /**
   * Execute a command **instance**.
   * @method execCommand
   * @param  {Command}   command        A command instance.
   * @param  {any}       additionalData Any data that the command should use. It
   * will be handed off to the commands `set` method. So make sure that method
   * can handle the data.
   * @return {[type]}                   The command execution result.
   */
  execCommand(command: Command, additionalData?: any): Promise<any> | any {

    this.prepareCommand(command, additionalData)

    command.dispatcher = this

    return command.exec()

  }

  private prepareCommand(command, additionalData) {

    if (additionalData) {
      command.set(additionalData)
    }

    if (command.constructor.storeRequirements) {
      const storeDependencies = extractStores(
        command.constructor.storeRequirements,
        this.store
      )

      command.set({
        store: storeDependencies
      })

    }

  }

}
