exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".src-template-connectors-Media-styles__geneva_media___3Gmkc{cursor:pointer;outline:none}.src-template-connectors-Media-styles__geneva_media___3Gmkc:focus{outline:4px solid #327ccb;outline-offset:-4px}.src-template-connectors-Media-styles__empty_media___1QDoN{background-color:#ccc;background-repeat:no-repeat}\n", "", {"version":3,"sources":["/builds/GENEVA/wm/frontend/src/template/connectors/Media/styles.scss"],"names":[],"mappings":"AAGA,4DACE,eACA,YAAa,CAFf,kEAKI,0BACA,mBAAoB,CACrB,2DAID,sBACA,2BAA4B,CAC7B","file":"styles.scss","sourcesContent":["@import \"../../../styles/variables\";\n@import \"../../../styles/mixins\";\n\n.geneva_media {\n  cursor: pointer;\n  outline: none;\n\n  &:focus {\n    outline: 4px solid $accent-color;\n    outline-offset: -4px;\n  }\n}\n\n.empty_media {\n  background-color: $media-placeholder-color;\n  background-repeat: no-repeat;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"geneva_media": "src-template-connectors-Media-styles__geneva_media___3Gmkc",
	"empty_media": "src-template-connectors-Media-styles__empty_media___1QDoN"
};