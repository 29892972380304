import * as PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'
import { getTemplatePath } from '../../../../shared/utils/template'
import * as css from './styles.scss'

export default function TemplatePreview({ shortcut, vendor, children }) {
  return (
    <div className={classnames('grid-block')}>
      <div>
        <img
          role="presentation"
          src={`${getTemplatePath(vendor, shortcut)}/icon.svg`}
        />
      </div>
      <div className={`grid-block ${css.previewContent}`}>{children}</div>
    </div>
  )
}

TemplatePreview.propTypes = {
  children: PropTypes.node,
  shortcut: PropTypes.string,
  vendor: PropTypes.string,
}
