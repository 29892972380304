import * as PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'

import * as styles from './styles.scss'

export default function Sidebar(props) {
  return (
    <div className={classNames(styles.sidebar, props.className)}>
      {props.children}
    </div>
  )
}

Sidebar.defaultProps = {
  className: '',
}

Sidebar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}
