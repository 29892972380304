import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { deepGet } from '../../shared/obj'
import { articleStore } from '../reducers'
import ArticleDetailsContainer from './Details'

export default class MainContainer extends Component {

  static propTypes = {
    article: PropTypes.object.isRequired,
    project: PropTypes.object.isRequired,
    page: PropTypes.object.isRequired,
  }

  componentDidMount() {

    const articleId = deepGet(this.props, 'params.articleId') * 1

    this.openArticle(articleId)

  }

  componentWillReceiveProps(nextProps) {

    const newArticleId = deepGet(nextProps, 'params.articleId')
    const oldArticleId = deepGet(this.props, 'params.articleId')

    if (newArticleId !== oldArticleId) {

      this.openArticle(newArticleId)

    }

  }

  openArticle(articleId) {

    articleStore.openById(articleId, {
      force: true
    })

  }

  render() {

    const { article } = this.props

    return (<div className="grid-block">
      {article.current
        ? <ArticleDetailsContainer
          {...this.props}
        />
        : null}
    </div>)

  }

}
