import * as PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'

import * as styles from './styles.scss'

export default function Header(props) {
  return (
    <div className={classNames(styles.header, props.className)}>
      {props.children}
    </div>
  )
}

function Item(props) {
  return (
    <span
      className={classNames(
        'grid-block',
        !!props.valign ? 'vertical' : '',
        !!props.valign ? 'v-align' : '',
        `align-${props.align || props.valign || 'center'}`,
        props.className
      )}
    >
      {props.children}
    </span>
  )
}

Item.defaultProps = {
  className: '',
  align: 'center',
  valign: '',
}

Item.propTypes = {
  children: PropTypes.node,
  align: PropTypes.string,
  valign: PropTypes.string,
  className: PropTypes.string,
}

function Menubar(props) {
  return (
    <div className="menu-bar">
      <div
        className={classNames(
          'menubar-container',
          'grid-block',
          !!props.valign ? 'vertical' : '',
          !!props.valign ? 'v-align' : '',
          `align-${props.align || props.valign || 'center'}`,
          props.className
        )}
      >
        {props.children}
      </div>
    </div>
  )
}

Menubar.defaultProps = {
  className: '',
  align: 'center',
  valign: '',
}

Menubar.propTypes = {
  children: PropTypes.node,
  align: PropTypes.string,
  valign: PropTypes.string,
  className: PropTypes.string,
}

Header.Item = Item
Header.Menubar = Menubar

Header.defaultProps = {
  className: '',
}

Header.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}
