import { createConfirmation } from 'react-confirm'
import ArticleMandatoryFields from '../../components/dialogs/ArticleMandatoryFields'

const articleMandatoryFields = createConfirmation(ArticleMandatoryFields)


export default function (confirmation, options) {

  if (typeof confirmation !== 'string') {
    options = { ...confirmation }
    confirmation = ''
  }

  return articleMandatoryFields({ confirmation, options })
}
