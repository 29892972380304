import React from 'react'

export const HTML_DEFAULT_ATTRS = [
  'className', 'id', 'style', 'title'
]

export const DIV_ATTRS = [
  ...HTML_DEFAULT_ATTRS
]

export const A_ATTRS = [
  ...HTML_DEFAULT_ATTRS,
  'alt', 'href', 'target'
]

// based on https://developer.mozilla.org/de/docs/Web/HTML/Element/button
export const BUTTON_ATTRS = [
  ...HTML_DEFAULT_ATTRS,
  'autofocus', 'disabled', 'name', 'type', 'value',
  'form',
  'formaction', 'formenctype', 'formmethod', 'formnovalidate', 'formtarget'
]

export const filterElemAttrs = (props, allowedProps) => {
  return [
    ...allowedProps,
    // allow all data-* attribs
    ...Object.keys(props).filter(key => key.indexOf('data-') === 0),
    ...Object.keys(props).filter(key => key.indexOf('aria-') === 0),
    // event handlers
    ...Object.keys(props).filter(key => /^on[A-Z]/.test(key))
  ].reduce((memo, key) => {
    if (props[key]) {
      memo[key] = props[key]
    }
    return memo
  }, {})
}

export const filterDivAttrs = props => filterElemAttrs(props, DIV_ATTRS)

/**
 * A simple helper function to allow using SVG-Icons with the `use`-tag ([SVG
 * Sprite](https://css-tricks.com/svg-sprites-use-better-icon-fonts/) within React.
 * @param {String} name - The name of the svg icon.
 * @return {React.Component} - The svgIcon React Component.
 */
export function svgIcon(name) {

  const iconSvg = `<svg className="svg-icon">
    <use xlink:href="#icon-${name}"></use>
  </svg>`

  return (<svg
    dangerouslySetInnerHTML={{
      __html: iconSvg
    }}
  />)

}

export const eventHandlers = props => Object.keys(props)
  .filter(key => key.indexOf('on') === 0)
  .reduce((memo, key) => {
    memo[key] = props[key]
    return memo
  }, {})
