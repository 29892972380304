import { store as pageStore } from '../../page'
import Command from '../command'

/**
* Load the data and ancestors of the page(s) requested
* @param {String} key - the key name to look for (e.g. 'id')
* @param {Array|Number|String} values - the values to look for. (e.g. [2, 3])
* @param {String} include - optional parameter to send along (e.g. 'parentPages')
* @return {Array} - array of page models matching the filter
*/
export default class FilterPageCommand extends Command {

  constructor(key, values, include) {

    super({ key, values, include })

    this.key = key
    this.values = values
    this.include = include

    this.parse()
  }

  validate(data) {
    if (!data.key || typeof data.key !== 'string') {
      this.throwError('command requires key')
    }

    if (!data.values) {
      this.throwError('command requires values to send')
    }

    if (data.include && typeof data.include !== 'string') {
      this.throwError('optional include must be a string')
    }
  }

  parse() {

    let filterValue = this.values

    // string as a length but should not be handled as array
    if (typeof this.values !== 'string' && this.values.length) {
      filterValue = []
      this.values.forEach(item => filterValue.push(`"${item}"`))
      filterValue = `[${filterValue}]`
    }

    this.opts = {
      params: {
        filter: `[{"${this.key}": ${filterValue}}]`
      }
    }

    if (this.include) {
      this.opts.params.include = this.include
    }
  }

  exec() {

    return pageStore.loadFilter(this.opts)
      .then((pageSet = []) => {
        return pageSet.filter((page) => {
          return this.values.find(desiredId => desiredId === page.id)
        })
      })
  }
}
