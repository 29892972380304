import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observable, toJS, autorun } from 'mobx'
import { observer, inject } from 'mobx-react'
import { autobind } from 'core-decorators'
import keydown from 'react-keydown'

import connectPMItemToolsToCommands from '../../shared/connectors/connectPMItemToolsToCommands'
import getChangeEvent from './ContentRenderer/getChangeEvent'
import { contentStores } from './ContentRenderer/DataProviders'
import getWrappers from './ContentRenderer/wrappers'
import CommandConnectedContentRendererTools from './ContentRenderer/Tools'

import { shortcut } from '../../shared/const'
import { filter } from '../../shared/obj'
import cancelable from '../../shared/decorators/cancelable-promise'
import FillPageCommand from '../../shared/commands/fill-page'
import ContentLoadingBox from '../../shared/components/ContentLoadingBox'
import { hasPermissions } from '../../shared/utils/user-rights'

import * as css from './ContentRenderer/styles.scss'

// allow underscore dangling for special prop __gridOrder on the page obj
/* eslint no-underscore-dangle: 0 */

const CONTENT_RENDERER_ADDITIONAL_PM_PROPS = ['tools', 'renderTools']

export default function connectContentRendererToContext(
  ContentRenderer,
  parentProps,
  contextStore
) {
  if (!contextStore) {
    throw new Error(
      'To connect a ContentRenderer to a context, '
        + '`connectContentRendererToContext` expects to get passed a '
        + '`contextStore` argument  as third parameter.'
    )
  }

  @inject('context', 'customLocal')
  @observer
  @cancelable
  class ContextConnectedContentRenderer extends Component {
    /* eslint-disable react/sort-comp */
    @observable prefilling = false;
    /* eslint-enable react/sort-comp */

    static propTypes = {
      context: PropTypes.object.isRequired,
      customLocal: PropTypes.object.isRequired,

      allowedArticleTemplates: PropTypes.array,
      allowedWidgetTemplates: PropTypes.array,

      index: PropTypes.number,

      gr: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      gb: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      maxItems: PropTypes.number,
      content: PropTypes.object,

      prefilled: PropTypes.array,

      onAddItem: PropTypes.func,
      onRemoveItem: PropTypes.func,

      /**
       * Method, that is called for each item to be rendered with the items
       * props. Optional. Otherwise the ArticleItem or WidgetItem component
       * will be used.
       * @returns A component.
       */
      renderItem: PropTypes.func,

      /**
       * from connectPMItemToolsToCommands
       */
      onEditItem: PropTypes.func,

      /**
       * from connectPMItemToolsToCommands
       */
      onRemoveItemFromPage: PropTypes.func,

      /**
       * from connectPMItemToolsToCommands
       */
      onDeleteItem: PropTypes.func,
    };

    constructor(props) {
      super(props)
      this.itemIds = null
      this.currentlySelectedItem = null

      // this is a little helper to get the actual page rendering order
      // of the grid blocks. We are saving this order in a special prop
      // on the page obj. In future this should propably wander into an
      // render context property that's created in the pageTemplate
      // connector
      if (!parentProps.page.__gridOrder) {
        parentProps.page.__gridOrder = []
      }
      if (
        !parentProps.page.__gridOrder.find(
          itm => itm.gr === props.gr && itm.gb === props.gb
        )
      ) {
        parentProps.page.__gridOrder.push({
          gr: props.gr,
          gb: props.gb,
          allowedArticleTemplates: props.allowedArticleTemplates,
          allowedWidgetTemplates: props.allowedWidgetTemplates,
          title: props.title,
        })
      }

      this.permissions = hasPermissions(['editArticle', 'deleteArticle'])
    }

    componentWillMount() {
      this.prefill()
      this.startListening()
    }

    componentDidMount() {
      document.body.addEventListener(
        'mousedown',
        this.handleSingleSelectionEvent,
        false
      )
    }

    componentWillUnmount() {
      log.renderer('%cUNMOUNTING...', 'color:green;')
      document.body.removeEventListener(
        'mousedown',
        this.handleSingleSelectionEvent,
        false
      )
      this.reset()
    }

    /**
     * Get's the items at a specific grid position.
     * @private
     */
    getContentItems(content = {}, gr, gb) {
      let contentItems = []

      if (content[`gr${gr}`] && content[`gr${gr}`][`gb${gb}`]) {
        contentItems = content[`gr${gr}`][`gb${gb}`]
      }

      return contentItems
    }

    /**
     * Creates a list of all items in the page. Used to determine changes.
     * @private
     */
    getItemList(content) {
      return content.map((item) => {
        const model = contentStores[item.type].getById(item.id)

        return {
          ...item,
          key: `${item.type}.${item.id}`,
          name: model.name,
          meta: toJS(model.meta),
        }
      })
    }

    /**
     * Returns the position object for the current ContentRenderer.
     * @method getPosition
     * @return {object}    - The position object.
     */
    getPosition() {
      const { gr, gb } = this.props
      return {
        gr,
        gb,
        index: null,
      }
    }

    startListening() {
      const { gr, gb } = this.props

      const block = parentProps.page.getGridBlock({ gr, gb }).block

      this.handler = [
        autorun('Listen for changes in this content renderers content', () => {
          log.renderer(
            'AUTORUN for %d:%s.%s',
            parentProps.page.id,
            this.props.gr,
            this.props.gb
          )
          this.handleContentChange(toJS(block))
        }),
      ]
    }

    reset() {
      this.itemIds = null
      this.handler.forEach(handler => handler())
      this.handler = null
    }

    prefill() {
      const { page } = parentProps
      const { prefilled, prefilledContent } = this.props
      // ONLY do this if the whole page is empty. If we checked only this
      // ContentRenderer, it would be impossible to keep this ContentRenderer
      // empty on purpose.
      if (page.isEmpty && prefilled && prefilled.length) {
        this.prefilling = true

        const promise = new FillPageCommand({
          items: prefilled,
          position: this.getPosition(),
          page,
          prefilledContent,
        }).exec()

        this.makeCancelable(promise).then(() => {
          this.prefilling = false
        })
      }
    }

    handleSingleSelectionEvent(e) {
      if (e.contentRendeerItemContextHandled) {
        // calling onBlur, or similar doesn't handle cases like double click
        //   stopping the event at this level had no negative effects I found
        e.stopPropagation()
      }
    }

    /**
     * Called once the contents changed.
     * @private
     */
    handleContentChange(nextContent) {
      if (!nextContent) {
        // No content yet, exiting
        return
      }

      const ids = this.getItemList(nextContent)

      // In case there were no itemIds yet, bail out:
      // This is the initial creation of the itemIds cache!
      if (ids && this.itemIds === null) {
        this.itemIds = ids
        return
      }

      // This means there are no item ids AND there were non cached.
      // That's unlikely to happen, so just exit, as there is nothing to do.
      if (!this.itemIds) {
        return
      }

      const changeEvent = getChangeEvent(ids, this.itemIds, parentProps.items)

      // It is crucial to set the ids here BEFORE calling the method. otherwise
      // it may happen that they are not set at all, because react unmounts the
      // component.
      this.itemIds = ids

      switch (changeEvent.type) {
        case 'add':
          this.handleAddItem(changeEvent)
          break
        case 'remove':
          this.handleRemoveItem(changeEvent)
          break
        case 'ident':
          /* TODO */ break
        default:
          break
      }
    }

    findNextGridBlock(target, indexDiff) {
      const gridIndex = parentProps.page.__gridOrder.indexOf(
        parentProps.page.__gridOrder.find(
          itm => itm.gr === target.gr && itm.gb === target.gb
        )
      )

      const nextGrid = parentProps.page.__gridOrder[gridIndex + indexDiff]

      if (!nextGrid) {
        return null
      }

      const items = ContentRenderer.prototype.getContentItems(
        toJS(parentProps.content),
        nextGrid.gr,
        nextGrid.gb
      )

      const index = indexDiff < 0 ? items.length - 1 : 0
      const item = items && items.length && items[index]

      if (!item) {
        return null
      }

      return {
        gr: nextGrid.gr,
        gb: nextGrid.gb,
        index,
        [item.type]: item,
        id: item.id,
        type: item.type,
      }
    }

    createContextTargetForItemAtIndex(indexDiff) {
      if (!contextStore.target || !indexDiff < 0) {
        return null
      }

      const index = contextStore.target.index + indexDiff

      const items = ContentRenderer.prototype.getContentItems(
        toJS(parentProps.content),
        contextStore.target.gr,
        contextStore.target.gb
      )

      let item = items[index]

      if (!item) {
        return this.findNextGridBlock(contextStore.target, indexDiff)
      }

      const type = item.type
      item = contentStores[type].getById(item.id)

      return {
        gr: contextStore.target.gr,
        gb: contextStore.target.gb,
        index,
        [type]: item,
        id: item.id,
        type,
      }
    }

    @keydown(shortcut.SELECT_NEXT_ITEM)
    handleSelectNext(e) {
      contextStore.ifInContext(
        [
          contextStore.constructor.CONTEXT_TYPE_ARTICLE,
          contextStore.constructor.CONTEXT_TYPE_WIDGET,
        ],
        () => {
          const target = this.createContextTargetForItemAtIndex(+1)

          if (target) {
            e.preventDefault()
            contextStore.createFromElement({
              ...target,
            })
          }
        }
      )
    }

    @keydown(shortcut.SELECT_PREV_ITEM)
    handleSelectPrev(e) {
      contextStore.ifInContext(
        [
          contextStore.constructor.CONTEXT_TYPE_ARTICLE,
          contextStore.constructor.CONTEXT_TYPE_WIDGET,
        ],
        () => {
          const target = this.createContextTargetForItemAtIndex(-1)

          if (target) {
            e.preventDefault()
            contextStore.createFromElement({
              ...target,
            })
          }
        }
      )
    }

    @keydown(shortcut.OPEN_NEXT_ITEM)
    handleOpenNext(e) {
      contextStore.ifInContext(
        [
          contextStore.constructor.CONTEXT_TYPE_ARTICLE,
          contextStore.constructor.CONTEXT_TYPE_WIDGET,
        ],
        () => {
          const target = this.createContextTargetForItemAtIndex(+1)

          if (target) {
            e.preventDefault()
            contextStore.createFromElement({
              ...target,
            })
            this.handleOpen({ target })
          }
        }
      )
    }

    @keydown(shortcut.OPEN_PREV_ITEM)
    handleOpenPrev(e) {
      contextStore.ifInContext(
        [
          contextStore.constructor.CONTEXT_TYPE_ARTICLE,
          contextStore.constructor.CONTEXT_TYPE_WIDGET,
        ],
        () => {
          const target = this.createContextTargetForItemAtIndex(-1)

          if (target) {
            e.preventDefault()
            contextStore.createFromElement({
              ...target,
            })
            this.handleOpen({ target })
          }
        }
      )
    }

    @autobind
    handleOpen({ target }) {
      contextStore.ifInContext(
        [
          contextStore.constructor.CONTEXT_TYPE_ARTICLE,
          contextStore.constructor.CONTEXT_TYPE_WIDGET,
        ],
        () => {
          if (this.props.onEditItem && this.permissions.editArticle) {
            this.props.onEditItem({ target })
          }
        }
      )
    }

    @autobind
    handleDelete({ target }) {
      contextStore.ifInContext(
        [
          contextStore.constructor.CONTEXT_TYPE_ARTICLE,
          contextStore.constructor.CONTEXT_TYPE_WIDGET,
        ],
        () => {
          if (this.props.onDeleteItem && this.permissions.deleteArticle) {
            this.props.onDeleteItem({
              target: {
                type: target.type,
                pageId: parentProps.page.id,
                itemStore: contentStores[target.type],
              },
            })
          }
        }
      )
    }

    @autobind
    handleRemove({ target }) {
      contextStore.ifInContext(
        [
          contextStore.constructor.CONTEXT_TYPE_ARTICLE,
          contextStore.constructor.CONTEXT_TYPE_WIDGET,
        ],
        () => {
          if (this.props.onRemoveItemFromPage) {
            this.props.onRemoveItemFromPage({
              target: {
                type: target.type,
                pageId: parentProps.page.id,
              },
            })
          }
        }
      )
    }

    @autobind
    handleFocus({ target }) {
      contextStore.createFromElement({
        ...target,
      })
    }

    @autobind
    handleBlur() {
      contextStore.set(null)
    }

    @autobind
    handleAddItem(e) {
      if (this.props.onAddItem) {
        this.props.onAddItem(e)
      }
    }

    @autobind
    handleRemoveItem(e) {
      if (this.props.onRemoveItem) {
        this.props.onRemoveItem(e)
      }
    }

    @autobind
    renderTools(index) {
      if (
        !CommandConnectedContentRendererTools
        || !this.permissions.editArticle
      ) {
        return null
      }

      const {
        gr,
        gb,
        allowedArticleTemplates,
        allowedWidgetTemplates,
        maxItems,
      } = this.props

      const maxLen = maxItems || 100

      const block = parentProps.page.getGridBlock({ gr, gb }) || []

      const toolProps = {
        gr,
        gb,
        allowedArticleTemplates,
        allowedWidgetTemplates,
        disabled: block.length >= maxLen,
        index,
        pageId: parentProps.page.id,
      }
      return <CommandConnectedContentRendererTools {...toolProps} />
    }

    renderPrefilling() {
      const Comp = this.props.tagName || 'div'
      return (
        <Comp className={this.props.className}>
          <ContentLoadingBox
            spinner
            message={{
              id: 'content-renderer.prefilling',
            }}
          />
        </Comp>
      )
    }

    render() {
      const { customLocal, context } = this.props

      // eslint-disable-next-line max-len
      log.renderer(
        `%cRendering ContentRenderer: ${parentProps.page.id}:${this.props.gr}.${this.props.gb}`,
        'background-color:black;color:white'
      )

      const env = toJS(parentProps.env)
      let renderTools = () => null

      if (this.prefilling) {
        return this.renderPrefilling()
      }

      if (env.PM) {
        renderTools = this.renderTools
      }

      const contentRendererProps = filter(
        {
          ...parentProps,
          env,
          wrappers: getWrappers(
            {
              ...parentProps,
              context,
              customLocal,
            },
            this
          ),
          ...this.props,
          css,
          renderTools,
          content: toJS(parentProps.content),
          contentStores,
        },
        [
          ...CONTENT_RENDERER_ADDITIONAL_PM_PROPS,
          'className',
          'itemWrapperClassName',
          'content',
          'css',
          'env',
          'gb',
          'gr',
          'items',
          'page',
          'tagName',
          'title',
          'renderItem',
          'wrappers',
          'prefilled',
          'contentStores',
        ]
      )

      return <ContentRenderer {...contentRendererProps} />
    }
  }

  return connectPMItemToolsToCommands(
    ContextConnectedContentRenderer
  )
}
