import PropTypes from 'prop-types'
import React from 'react'
import Icon from '../../../shared/icons'
import { FixedSection } from '../../../shared/components/Navbar'

export default
function ArticleNavbarTools(props) {

  let languageInfo = null

  if (props.language) {
    languageInfo = (<div className="language-info">
      {props.language}
    </div>)
  }

  return (<FixedSection>
    {languageInfo}
    <a
      onClick={props.onClose}
      className="close-button"
    >
      <Icon name="ion-ios-close-empty" />
    </a>
  </FixedSection>)

}


ArticleNavbarTools.propTypes = {
  brand: PropTypes.node
}
