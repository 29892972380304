exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".src-shared-components-Navbar-styles__navbarSection___3ECuI{flex-direction:row;display:flex;justify-content:flex-start;align-items:center}.src-shared-components-Navbar-styles__navbarSection___3ECuI:last-child{justify-content:flex-end}.src-shared-components-Navbar-styles__navbarFixedSection___2wRsn{flex:1}.src-shared-components-Navbar-styles__navbarFlexSection___3J8x-{flex:1}@media (min-width: 1200){.src-shared-components-Navbar-styles__navbarFixedSection___2wRsn{min-width:400px;flex:0}.src-shared-components-Navbar-styles__navbarFlexSection___3J8x-{max-width:400px;flex:1}}\n", "", {"version":3,"sources":["/builds/GENEVA/wm/frontend/src/shared/components/Navbar/styles.scss"],"names":[],"mappings":"AAAA,4DACE,mBACA,aACA,2BACA,kBAAmB,CAJrB,uEAOI,wBAAyB,CAC1B,iEAID,MAAO,CACR,gEAGC,MAAO,CACR,yBAGC,iEACE,gBACA,MAAO,CACR,gEAEC,gBACA,MAAO,CACR,CAAA","file":"styles.scss","sourcesContent":[".navbarSection {\n  flex-direction: row;\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n\n  &:last-child {\n    justify-content: flex-end;\n  }\n}\n\n.navbarFixedSection {\n  flex: 1;\n}\n\n.navbarFlexSection {\n  flex: 1;\n}\n\n@media(min-width:1200) {\n  .navbarFixedSection {\n    min-width: 400px;\n    flex: 0;\n  }\n  .navbarFlexSection {\n    max-width: 400px;\n    flex: 1;\n  }\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"navbarSection": "src-shared-components-Navbar-styles__navbarSection___3ECuI",
	"navbarFixedSection": "src-shared-components-Navbar-styles__navbarFixedSection___2wRsn",
	"navbarFlexSection": "src-shared-components-Navbar-styles__navbarFlexSection___3J8x-"
};