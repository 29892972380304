import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { autobind } from 'core-decorators'
import classNames from 'classnames'
import { apiVersions, bynder } from '../config'

import Navbar from './components/Navbar'
import BynderIFrame from './components/BynderIFrame'

import * as css from './styles.scss'


export default class Bynder extends Component {

  static propTypes = {
    dialog: PropTypes.object
  }

  constructor(props) {
    super(props)
    this.state = {
      returnedAssets: [],
      link: null,
    }
  }

  @autobind
  handleChange({ target }) {
    this.setState({ returnedAssets: target.value }, () => this.handleSubmit())
  }

  @autobind
  handleSubmit() {
    if (this.props.dialog.type === 'video') {
      console.warn('warning: Bynder Videos not fully supported')
      // this.props.dialog.result = this.state.returnedAssets.map((vid) => {
      //   // previewURL 0=webm, 1=mp4
      //   return { id: vid.id, url: vid.previewUrls[0] }
      // })
    }
    else if (this.props.dialog.type === 'file') {
      console.warn('warning: Bynder File support not fully supported')
      // this.props.dialog.result = this.state.returnedAssets.map((file) => {
      //   this.setState({
      //     link: `${file.url}/download/${file.id}`
      //   })
      //   return { id: file.id, url: file.url, name: file.name }
      // })
    }
    else {
      this.props.dialog.result = this.state.returnedAssets.map((image) => {
        return {
          src: `${apiVersions.v2.apiScheme}//${apiVersions.v2.apiHost}/public/image/preview?source=bynder&sourceId=${image.databaseId}`,
          skipPreviewUrl: true,
          source: 'bynder',
          sourceId: image.databaseId
        }
      })
    }
    this.props.dialog.onCommit()
  }

  // Currently unused
  renderNavbar() {
    return (<Navbar
      navbarStyle={css.bynderTitlebar}
      onCancel={this.props.dialog.onCancel}
      onSubmit={this.handleSubmit}
      id="bynderIntegration"
      submitDisabled={!this.state.returnedAssets.length}
      imageCount={this.state.returnedAssets.length}
    />)

  }

  render() {

    return (
      <div className="grid-frame vertical">
        <BynderIFrame
          className={classNames(css.bynderBlock, 'grid-block')}
          imageSpec={this.props.dialog.imageSpec}
          type={this.props.dialog.type}
          multiple={this.props.dialog.multiple}
          maxImages={this.props.dialog.maxImages}
          projectId={this.props.dialog.projectId}
          projectName={this.props.dialog.projectName}
          lang={this.props.dialog.lang}
          src={bynder.url}
          onChange={this.handleChange}
          onClose={this.props.dialog.onCancel}
        />
        { this.state.link
          ? <meta httpEquiv="refresh" content="0" url={this.state.link}></meta>
          : ''
        }
      </div>
    )
  }

}
