exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".src-shared-components-LimitedProjectDropdown-styles__limitedProjectDropdown___1ciun{padding-top:0}.src-shared-components-LimitedProjectDropdown-styles__limitedProjectDropdown___1ciun .channel{color:grey}.src-shared-components-LimitedProjectDropdown-styles__limitedProjectDropdown___1ciun hr{margin:5px 0}\n", "", {"version":3,"sources":["/builds/GENEVA/wm/frontend/src/shared/components/LimitedProjectDropdown/styles.scss"],"names":[],"mappings":"AAAA,qFACE,aAAc,CADhB,8FAII,UAAW,CAJf,wFAQI,YAAa,CACd","file":"styles.scss","sourcesContent":[".limitedProjectDropdown {\n  padding-top: 0;\n\n  :global(.channel) {\n    color: grey;\n  }\n\n  hr {\n    margin: 5px 0;\n  }\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"limitedProjectDropdown": "src-shared-components-LimitedProjectDropdown-styles__limitedProjectDropdown___1ciun"
};