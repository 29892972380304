import { store as uiStore } from '../../ui'
import { Command } from '../lib/command/index.ts'
import { apiClient } from '../api/decorators/apiClient'

/**
  * Sends a Change Password request to the AM-Backend
  * then triggers a message on the statusbar
  * @param {String} oldPassword - The current password
  * @param {String} newPassword - The new password
  * @param {String} newPasswordConfirm - The new password confirmed
*/
@apiClient
export default class ChangePasswordCommand extends Command {

  static validationSpec = {
    oldPassword: Command.ensureData.isString,
    newPassword: Command.ensureData.isString,
    newPasswordConfirm: Command.ensureData.isString,
    userId: Command.ensureData.isString
  }

  constructor(data = []) {
    super(data)
    this.data = data
  }

  exec() {
    const {
      oldPassword,
      newPassword,
      newPasswordConfirm,
    } = this.data

    // Print message to the statusbar
    uiStore.addStatusInfo({
      priority: 'low',
      name: 'auth.password-changing-started'
    }, 2000)

    return this.dispatch('put', {
      path: 'user/pw',
      data: {
        oldPassword,
        newPassword,
        newPasswordConfirm
      }
    }).then((result) => {
      if (result) {
        return result.ok
      }
      return null
    })
  }
}
