import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { autobind } from 'core-decorators'
import { FormattedMessage } from '../../translations'
import FormattedDateTime from '../../shared/components/FormattedDateTime'
import screenshotDialog from '../containers/ScreenshotDialog'
import { convertDateToUserLocal } from '../../shared/DateConverter'

require('../styles.scss')

@observer
export default class ReviewCenterSingleChat extends Component {
  static propTypes = {
    comment: PropTypes.object,
  };

  @autobind
  handleImageClick({ target }) {
    const {
      comment: { screenshots },
    } = this.props
    const screenshotLocation = screenshots.findIndex(
      el => el.id === target.id * 1
    )

    screenshotDialog({
      screenshotLocation,
      screenshots,
    }).then(() => console.log('closed'))
  }

  renderAction() {
    const { action } = this.props.comment
    switch (action) {
      case 1:
        return <FormattedMessage id="review-center.action.approved" />
      case 2:
        return <FormattedMessage id="review-center.action.review" />
      case 3:
        // eslint-disable-next-line no-case-declarations
        const name = this.props.target ? `: ${this.props.target}` : ''
        return (
          <FormattedMessage
            id="review-center.action.invite"
            values={{
              name,
            }}
          />
        )
      default:
        return null
    }
  }

  render() {
    const { comment } = this.props
    const createdAt = convertDateToUserLocal(comment.createdAt)

    return (
      <div className="rc-chat-block" key={comment.id}>
        <div className="rc-name">{comment.name}</div>
        <div className="rc-date">
          <FormattedDateTime value={createdAt} />
          <FormattedMessage id="review-center.chat.hour" />
        </div>
        <div className="rc-text">
          {this.renderAction()}
          <div
            className="comment"
            dangerouslySetInnerHTML={{ __html: comment.text }}
          ></div>
        </div>
        {comment.screenshots
          && comment.screenshots.map((image) => {
            return (
              <img
                src={image.thumbnailUrl}
                alt=""
                id={image.id}
                key={image.id}
                onClick={this.handleImageClick}
              />
            )
          })}
      </div>
    )
  }
}
