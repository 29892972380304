import { computed, observable } from 'mobx'
import { Store } from '../shared/store'
import File from './model'
import { inspector, fileInspector } from '../shared/decorators/inspector'
import { store as uiStore } from '../ui'

import { apiClient } from '../shared/api'

import {
  UploadFileCommand
} from './commands'
import { addToCollection } from '../shared/utils'
import { getBaseUrl } from '../shared/api/decorators/apiClient'

@inspector('save', fileInspector)
@apiClient

export class FileStore extends Store {

  // Put all article list properties here. This regargds also state properties.

  @observable collection = []

  @observable current = {}

  @computed get hasCurrent() {
    return !!this.current.id
  }

  @observable loading = false

  hasMoreItems = true

  @observable filter = {}

  static storeName = 'FileStore'

  // Put all properties that are not to be observed here:

  constructor(opts = {}) {

    super(opts)

    this.Model = File

  }

  @observable editing = false

  getPreviewURL(url) {
    return `${getBaseUrl()}/preview?url=${url}`
  }

  /**
   * @param {Object} file - data
   * @param {*} opts
   * @returns Promise
   */
  editFile(file, opts) {

    const sourceType = file.file ? 'file' : 'url'

    // cache the file
    const source = file[sourceType]

    if (file.url) {
      file.value = this.getPreviewURL(file.url)
    }

    delete file.file
    delete file.url

    file[sourceType] = source

    return this.uploadFile(file, opts)

  }

  /**
   * Pass-through function for uploading a file
   * @param {Object} fileData
   * @param {Object} opts
   * @returns Promise
   */
  uploadFile(fileData, opts) {
    return new UploadFileCommand(this, fileData, opts)
      .exec()
  }

  // Adds progress information to api call
  progressTracker(progress) {
    uiStore.addStatusInfo({
      // eslint-disable-next-line no-use-before-define
      id: FileStore.name,
      update: true,
      priority: 'low',
      progress: progress.loaded,
      total: progress.total
    }, 'forever')
  }

  /**
   * Overweriting model creating here to accomodate for multiple models
   * returned from the server.
   * @returns Array of File objects
   */
  create(data, opts = {}) {

    const model = this.createModel(data)

    return this.apiSave(model, opts)
      .catch((err) => {
        this.remove(model)
        throw err
      })
  }

  /**
   * @param {*} data
   * @param {*} opts
   * @returns {Array of File}
   */
  apiSave(data = {}, opts = {}) {

    let fileData = null

    if (data.file) {

      const formData = new FormData()

      formData.append('json', JSON.stringify(data.file))

      formData.append('file', data.file, data.file.name)

      fileData = formData

      // It's actually not necessary (and advised) to set
      // the content type younself. It will be set
      // automatically when sending FormData.
      // request.type('multipart/form-data')
    }

    return this.dispatch('post', {
      path: 'file',
      data: fileData,
      params: opts.params,
      progress: this.progressTracker
    })
      .then(body => this.handleUploadResult(body, opts))
  }

  /** @private */
  handleUploadResult(response) {
    let entries = response.body.data
    if (!Array.isArray(entries)) {
      entries = [entries]
    }
    return Promise.all(entries.map((entry) => {
      return addToCollection(this, this.collection, entry, File)
    }))
  }

  destroy(file) {
    if (file && file.id) {
      return this.dispatch('del', {
        path: `file/${file.id}`,
      })
    }
    throw new Error('missing file for file delele')
  }

  canLoad() {
    return !this.loading && this.hasMoreItems
  }
}

export const fileStore = new FileStore()
