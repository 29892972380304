import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { autorun } from 'mobx'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import { FormattedMessage } from '../../translations'

import { TemplateDetailsContainer } from '../../template'

import Transformable from '../../shared/components/Transformable'
import ContentLoadingBox from '../../shared/components/ContentLoadingBox'
import { i18n } from '../../shared/utils'

import ArticleSideBar from '../components/Sidebar'
import { default as Menubar } from '../components/Menubar'

import connectArticleHeaderWarningToCommands
  from '../connectors/connectArticleHeaderWarningToCommands'


const css = /* typeof window === 'undefined' ? {} : */require('../styles.scss')

// Add or remove tools to the right SideBar.
const sidebarTools = [
  {
    link: 'version',
    icon: 'ios-albums-outline',
    title: 'article.version'
  },
  /*
  comment: {
    icon: 'ios-chatboxes-outline'
  },
  research: {
    icon: 'ios-glasses-outline'
  },
  release: {
    icon: 'ios-people-outline'
  },*/
  {
    link: 'translate',
    icon: 'ios-flag-outline',
    title: 'article.translate'
  },
  {
    link: 'google-translate',
    icon: 'ios-flag-outline',
    title: 'article.google-translate'
  },
  {
    link: 'censhare',
    icon: 'social-buffer-outline',
    title: 'article.censhare'
  }
]

const ConnectedArticleHeaderWarnings = connectArticleHeaderWarningToCommands()

@observer
class ArticleDetailsContainer extends Component {


  static propTypes = {
    article: PropTypes.object.isRequired,
    project: PropTypes.object.isRequired,
    page: PropTypes.object.isRequired,
    context: PropTypes.object.isRequired,
    image: PropTypes.object.isRequired,
    template: PropTypes.object.isRequired,
    ui: PropTypes.object.isRequired,
    tool: PropTypes.string,
    toolDetailId: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    params: PropTypes.object.isRequired,
  }

  constructor(props) {

    super(props)

    this.state = {}

    this.handler = []

  }

  componentDidMount() {

    this.handler.push(autorun(() => {

      const current = this.getCurrent()

      if (current && current.templateId) {

        this.loadTemplate(current.templateId)

      }

    }))

    // Opens the Sidebar when an action is given in the URL
    this.handler.push(autorun('Once the article is complete', () => {

      if (this.props.params.articleAction
        && this.props.params.articleAction !== 'edit'
        && this.props.article.current.complete) {

        this.props.ui.paneState.articleToolsPanelOpen = true

      }
    }))

    // TODO: https://github.com/yannickcr/eslint-plugin-react/blob/master/docs/rules/no-find-dom-node.md
    // eslint-disable-next-line react/no-find-dom-node
    const menuBar = ReactDOM.findDOMNode(this.refs.menubar)

    // set state HAS to happen here, as the toolbar will only be available once
    // the component did mount
    const toolbarElem = menuBar.querySelector('.editor-toolbar')
    this.setState({
      // Scribe needs the toolbar ref for further logic
      // This class renderArticleContent needs atleast a true
      toolbarElem: toolbarElem || true
    })

  }

  componentWillUnmount() {

    if (this.handler) {
      this.handler.forEach(handler => handler())
      this.handler = null
    }

  }

  getCurrent() {

    return this.props.article.current

  }

  loadTemplate(id) {

    this.props.template.openById(id)

  }

  renderMenubar() {

    return (<Menubar
      ref="menubar"
      article={this.props.article}
      project={this.props.project}
      page={this.props.page}
      context={this.props.context}
      ui={this.props.ui}
      params={this.props.params}
    />)

  }

  // renderMainContent() {
  //
  //   return (<Transformable
  //     className={'grid-block'}
  //     wrapperClassName={`grid-block vertical shrink noscroll
  //      ${css.canvasContainer}`}
  //     toolsClassName={css.transformableTools}
  //   >
  //     {this.renderTemplateContent()}
  //   </Transformable>)
  //
  // }

  renderLoadingScreen() {
    const { article: { current } } = this.props

    const title = i18n(current, 'name')

    return (<ContentLoadingBox
      spinner
      message={{
        id: title
          ? 'article.loading-name'
          : 'article.loading',
        values: {
          title: i18n(current, 'name')
        }
      }}
    />)

  }


  renderTemplateContent() {

    return (
      <TemplateDetailsContainer
        article={this.props.article}
        context={this.props.context}
        customLocal={this.props.customLocal}
        image={this.props.image}
        template={this.props.template}
        toolbarElem={this.state.toolbarElem}
        ui={this.props.ui}
      />
    )

  }

  renderArticleContent() {

    const { article } = this.props

    return (
      // if the article is complete AND the eitor toolbar is loaded
      article.current.complete && this.state.toolbarElem
        ? this.renderTemplateContent()
        : this.renderLoadingScreen()

    )

  }


  renderNoArticleSelected() {
    return (<div className="grid-block">
      <FormattedMessage id="article.selectArticle" />
    </div>)
  }


  render() {

    const { article, page, project, ui: { paneState } } = this.props

    return (<div className={classNames('grid-block vertical', css.cmBody)}>

      {this.renderMenubar()}
      <ConnectedArticleHeaderWarnings
        article={this.props.article}
      />

      <div className="grid-block">
        <Transformable
          className="grid-block"
          wrapperClassName={`grid-block vertical shrink noscroll
           ${css.canvasContainer}`}
          toolsClassName={css.transformableTools}
        >
          {
            article.current
              ? this.renderArticleContent()
              : this.renderNoArticleSelected()
          }
        </Transformable>
        {
          article.current && article.current.complete
            ? <ArticleSideBar
              article={article}
              project={project}
              page={page}
              currentVersion={this.props.params.toolDetailId * 1
              || this.props.params.articleActionDetails * 1}
              paneState={paneState.articleToolsPanelOpen}
              template={this.props.template}
              tool={this.props.params.tool || this.props.params.articleAction}
              tools={sidebarTools}
              className={classNames(
                'grid-block vertical small extending right overlay sidebar'
              )}
            />
            : null}
      </div>

    </div>)

  }

}

export default ArticleDetailsContainer
