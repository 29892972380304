import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { deepGet } from '../../shared/obj'
import WidgetDetailsContainer from './Details'
import { widgetStore } from '../reducers'

export default class WidgetMainContainer extends Component {

  static propTypes = {
    widget: PropTypes.object.isRequired,
    project: PropTypes.object.isRequired,
    page: PropTypes.object.isRequired,
  }

  componentDidMount() {

    const widgetId = deepGet(this.props, 'params.widgetId')

    this.openWidget(widgetId)

  }

  componentWillReceiveProps(nextProps) {

    const newWidgetId = deepGet(nextProps, 'params.widgetId')
    const oldWidgetId = deepGet(this.props, 'params.widgetId')

    if (newWidgetId !== oldWidgetId) {

      this.openWidget(newWidgetId)

    }

  }

  openWidget(widgetId) {

    widgetStore.openById(widgetId, {
      force: true
    })

  }

  render() {

    const { widget } = this.props

    return (<div className="grid-block">
      {widget.hasCurrent
        ? <WidgetDetailsContainer
          {...this.props}
        />
        : null}
    </div>)

  }

}
