import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ReactCSSTransitionGroup from 'react-transition-group/CSSTransitionGroup'
import { toJS } from 'mobx'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import config from '../../../config'

import { observableArrayShape } from '../../../shared/mobx/shapes'
import ContentErrorBox from '../../../shared/components/ContentErrorBox'

const getHTMLError = (match, item) => {
  return `<details>
    <summary>${item.type} data:</summary>
    <pre>${
  JSON.stringify(match, null, 4)
}</pre>
  </details>`
}

@observer
export default
class ContentRenderer extends Component {

  static propTypes = {
    allowedArticleTemplates: PropTypes.array,
    allowedWidgetTemplates: PropTypes.array,
    className: PropTypes.string,
    content: PropTypes.object,
    contentStores: PropTypes.object,
    css: PropTypes.object,
    env: PropTypes.object.isRequired,
    gb: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    gr: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    items: PropTypes.oneOfType([
      PropTypes.array,
      observableArrayShape
    ]),
    itemWrapperClassName: PropTypes.string,
    maxItems: PropTypes.number,
    page: PropTypes.shape({
      isEmpty: PropTypes.bool.isRequired,
      isLockedByOther: PropTypes.func.isRequired,
    }).isRequired,
    /**
    * Method, that is called for each item to be rendered with the items props
    * @returns A component.
    */
    renderItem: PropTypes.func,
    renderTools: PropTypes.func,
    showEmptyBlock: PropTypes.bool,
    tagName: PropTypes.string,
    title: PropTypes.string,
    wrappers: PropTypes.shape({
      article: PropTypes.func.isRequired,
      widget: PropTypes.func.isRequired,
    }),

  }

  static defaultProps = {
    renderTools: () => null,
    className: '',
    tagName: 'div',
    allowedArticleTemplates: [],
    allowedWidgetTemplates: [],
  }

  getContentItems(
    content,
    gr,
    gb
  ) {
    let contentItems = []
    let tmp = []

    if (!content) {
      return contentItems
    }

    if (!gr) {
      return toJS(content)
    }

    const hasPrefix = !Array.isArray(toJS(content))

    gr = `${hasPrefix ? 'gr' : ''}${gr}`

    if (gr in content && content[gr]) {
      tmp = content[gr]

      if (gb !== undefined) {
        gb = `${hasPrefix ? 'gb' : ''}${gb}`

        if (gb in tmp) {

          if (tmp[gb] && Array.isArray(tmp[gb])) {
            contentItems = tmp[gb]
          }
          else {
            // eslint-disable-next-line max-len
            console.warn(
              `GridBlock ${gb} for ContentRenderer{${gr}, ${gb}} `
              + 'defined, but empty or no array! Using empty array.'
            )
          }

        }

      }
      else {

        if (Array.isArray(tmp)) {
          contentItems = tmp
        }
        else {
          // eslint-disable-next-line max-len
          console.warn(
            `GridRow ${gr} for ContentRenderer{${gr}} defined, but no array! `
            + 'Using empty array.'
          )
        }

      }
    }

    if (!Array.isArray(contentItems)) {
      // eslint-disable-next-line max-len
      console.warn(
        `Data for ContentRenderer{${gr},${gb}} is no array and thereby `
        + `invalid, using empty array. Data was ${JSON.stringify(contentItems)}`
      )
      contentItems = []
    }

    return contentItems
  }

  createMarkup(__html) {
    return { __html }
  }

  getTemplateId(item) {
    return !item.templateId
      && (item.templateId || (item.template && item.template.id))
  }

  normalizeItem(item) {
    const templateId = this.getTemplateId(item)

    if (templateId) {
      item.templateId = templateId
    }

    return item
  }

  validateItem(match, item) {
    if (!match) {
      throw new ReferenceError(
        `The ${item.type} with id ${item.id} does not exist!`
      )
    }

    match = this.normalizeItem(match)

    if (!match.templateId) {
      throw new ReferenceError(
        `The ${item.type} with id ${item.id} has no template id! `
        + `${getHTMLError(match, item)}`
      )
    }
  }

  shouldHideEmptyBlock() {
    const { env, showEmptyBlock } = this.props
    return (env.PREVIEW || env.PUBLISH) && !showEmptyBlock
  }

  shouldRenderTools() {
    const { page, renderTools } = this.props
    // TODO: page is not passed when rendering on the server
    return (page && !page.isLockedByOther())
      && renderTools
      && typeof renderTools === 'function'
  }

  renderTools(index) {
    return this.shouldRenderTools()
      ? this.props.renderTools(index)
      : null
  }

  /**
   * Renders all items within this ContentRenderer.
   * @method renderItems
   * @param {Array<Object>} contentItems - The items that should be rendered.
   * Each item consists of it's id and it's content type
   * (One of `article` or `widget`).
   * @return {ReactElement} - The rendered items.
   */
  renderItems(contentItems = []) {
    const {
      items, gr, gb, wrappers, page, itemWrapperClassName
    } = this.props

    if (!Array.isArray(contentItems)) {

      console.warn(
        `Items passed to ContentRenderer{${gr},${gb}} is no array and `
        + 'thereby invalid, using empty array. '
        + `Data was ${JSON.stringify(contentItems)}`
      )

      contentItems = []
    }

    return contentItems
      .map((item, index) => {

        let match = (items || [])
          .find((i) => {
            return i.id * 1 === item.id * 1
          && i.type === item.type
          })

        // TODO: remove if prefilled page issue is resolved
        // to reproduce, remove this if statement and create a prefilled page
        // used to solve a race condition where the backend creates the page and
        // page items at the same time and does not include all items in page response,
        // but the item creation successed
        if (!match) {
          if (this.props.contentStores && this.props.contentStores[item.type]) {
            match = this.props.contentStores[item.type].getById(item.id)
          }
        }

        try {
          this.validateItem(match, item)
        }
        catch (ex) {
          return <ContentErrorBox error={ex} />
        }

        const Wrapper = this.props.renderItem
          ? null
          : wrappers[item.type]
        const key = `gr${gr}.gb${gb}.${match.id}.${match.type}`
        const itemClone = Object.assign({}, item)

        itemClone.templateId = match.templateId
        itemClone.data = match.data
        itemClone.result = match.result

        const itemProps = {
          gr,
          gb,
          index,
          key,
          item: itemClone,
          className: itemWrapperClassName
        }

        if (item.type === 'widget') {
          Object.assign(itemProps, {
            items,
            page,
            wrappers
          })
        }

        const wrapper = this.props.renderItem
          ? this.props.renderItem(itemProps)
          : (<Wrapper
            {...itemProps}
          />)

        if (!this.props.env.PM) {
          return wrapper
        }

        const renderedTools = this.renderTools(index + 1)

        return (<div
          className="geneva-content-renderer-item-tools-wrapper"
          key={key}
        >{
            wrapper
          }{
            renderedTools
          }
        </div>)
      })
  }

  renderContent(contentItems) {
    const renderedItems = this.renderItems(contentItems)
    return this.props.env.PM
      ? <ReactCSSTransitionGroup
        component="div"
        className="react-transition-group"
        transitionName="fade"
        transitionEnter
        transitionEnterTimeout={500}
        transitionLeave
        transitionLeaveTimeout={500}
        transitionAppear
        transitionAppearTimeout={500}
      >{renderedItems}
      </ReactCSSTransitionGroup>
      : renderedItems
  }


  render() {

    const {
      content, gr, gb, title, className, env
    } = this.props

    const Element = this.props.tagName

    const contentItems = this.getContentItems(content, gr, gb)
    const isEmpty = !contentItems.length

    if (isEmpty && this.shouldHideEmptyBlock()) {
      return null
    }

    if (!env.PM && config.domReduction && config.domReduction > 0) {
      const innerContent = this.renderContent(contentItems)
      let flatItems = ''
      innerContent.forEach((el) => {
        flatItems += el.props.item.result
      })
      
      // NOTE: the missing whitespace is important here!!
      return (<Element
        className={classNames(
          className, {
            empty: isEmpty
          }
        )}
        title={title}
        dangerouslySetInnerHTML={{ __html: flatItems }}
      />)
    }

    // NOTE: the missing whitespace is important here!!
    return (<Element
      className={classNames(
        className, {
          empty: isEmpty
        }
      )}
      title={title}
    >{
        this.renderTools(0)
      }{
        this.renderContent(contentItems)
      }
    </Element>)
  }

}
