import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import { toJS } from 'mobx'
import { observer } from 'mobx-react'

const css = require('./Hotspot/styles.scss')

export default function connectHotspotToContext(
  Hotspot,
  parentProps,
  contextStore,
  additional
) {
  if (!parentProps.articlePlaceholders) {
    throw new Error(
      '`ConnectedHotspotToContext` '
        + "requires an articlePlaceholders Object in it's "
        + 'parentProps'
    )
  }

  @observer
  class ConnectedHotspot extends Component {
    static pid(baseId, index, itemPid) {
      const base = `${baseId}:${index}`
      return itemPid === undefined ? base : `${base}~${itemPid}`
    }

    static propTypes = {
      active: PropTypes.bool,
      posPid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      textPid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

      spotWrapperClassName: PropTypes.string,
      labelClassName: PropTypes.string,

      editorSpec: PropTypes.object,
      editorTagName: PropTypes.string,

      positionableChild: PropTypes.object,
      repeatableProps: PropTypes.object,

      Resizable: PropTypes.func,
      Editor: PropTypes.func,
      Positionable: PropTypes.func,

      spotId: PropTypes.string,
      spotClassName: PropTypes.string,

      onSpotActivated: PropTypes.func,
      onSpotDragStart: PropTypes.func,
      onSpotDragEnd: PropTypes.func,
      onSpotResize: PropTypes.func,
    };

    render() {
      const env = toJS(parentProps.env)

      const value
        = this.props.value
        || parentProps.articlePlaceholders.get(this.props.pid)
        || []

      return (
        <Hotspot
          css={css}
          {...this.props}
          {...additional}
          value={value}
          env={env}
        />
      )
    }
  }

  return ConnectedHotspot
}
