import { articleStore } from '../../article/reducers'
import { widgetStore } from '../../widget/reducers'

const contentStores = {
  article: articleStore,
  widget: widgetStore,
}

export default class UpdatePageContentCommand {
  constructor(pageModel, items) {
    this.pageModel = pageModel
    this.items = items
  }

  // Make sure content items are in their store collections
  ensureItemData(item) {
    const store = contentStores[item.type]
    let model = store.getById(item.id)
    if (!model) {
      model = store.createModel(item.data)
      store.add(model)
    }
    else {
      model.set(item.data)
    }
    // In case of widget, repeat for each article inside
    if (item.data.items) {
      item.data.items.forEach(subItem => this.ensureItemData(subItem))
    }
  }

  exec() {
    this.pageModel.items.splice(0, this.pageModel.items.length, ...this.items)

    this.items.forEach(item => this.ensureItemData(item))
  }
}
