import isScrolledInView from './is-scrolled-in-view'
import getScrollParent from './scroll-parent'
import getScrollOffset from './scroll-offset'


function animatedScroll(parent, diff, duration = 3000) {
  let start = null
  const scrollStart = parent.scrollTop

  function scroll(timestamp) {

    if (!start) {
      start = timestamp
    }

    const progress = timestamp - start

    const newScrollTop = Math[diff > 0 ? 'min' : 'max'](
      scrollStart + (progress / duration) * diff,
      scrollStart + diff
    )

    parent.scrollTop = newScrollTop

    if (progress < duration) {
      requestAnimationFrame(scroll)
    }

  }

  requestAnimationFrame(scroll)
}


export default function scrollIntoViewIfNeeded(el, padding = 0, speed = 300) {
  if (!isScrolledInView(el, padding)) {
    // double parent because of scrollbar container
    const parent = getScrollParent(el)

    if (!parent) {
      // Bail out if not inside a scrollcontainer
      return
    }

    const offset = getScrollOffset(el, parent)
    const itemHeight = el.getBoundingClientRect().height
    const viewportStart = parent.scrollTop
    const viewportHeight = parent.getBoundingClientRect().height
    const viewportEnd = viewportStart + viewportHeight


    const itemStart = offset - padding
    const itemEnd = offset + itemHeight + padding

    // is scrolled out of view at top
    if (viewportStart > itemStart) {
      // remove n pixels
      animatedScroll(parent, itemStart - viewportStart, speed)
    }

    // is scrolled out of view at bottom
    if (viewportEnd < itemEnd) {
      // if the item is actually higher than the visible estate
      if (itemHeight > viewportHeight) {
        // scroll into view from top
        animatedScroll(parent, itemStart - viewportStart, speed)
      }
      else {
        // scroll into view from bottom
        animatedScroll(parent, itemEnd - viewportEnd, speed)
      }
    }

  }
}
