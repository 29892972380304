import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import { autobind } from 'core-decorators'
import { inject, observer } from 'mobx-react'
import { FormattedMessage } from '../../../translations'
import GenevaButton from '../../../ui/components/GenevaButton'

@inject('auth')
@observer
class LockStateMenubar extends Component {
  static propTypes = {
    className: PropTypes.string,
    lockable: PropTypes.object,
    auth: PropTypes.shape({
      user: PropTypes.object.isRequired,
    }).isRequired,
  };

  @autobind
  handleForceTakeover() {
    const {
      lockable,
      lockable: {
        current: { lockState },
      },
    } = this.props
    lockable.forceLock(lockState)
  }

  render() {
    const {
      className,
      lockable: {
        current: { lockState, isVersion },
      },
      auth,
    } = this.props

    const user = lockState ? lockState.user : ''

    return (
      <div className={className}>
        <div className="grid-block">
          <div className="grid-block">
            <div className="grid-block vertical align-center text-center">
              {user === 'system' ? (
                <FormattedMessage id="locked-by-system" />
              ) : (
                <FormattedMessage id="locked-by" values={user} />
              )}
            </div>
            <div className="grid-block">
              {auth.user.isAdmin && isVersion !== true ? (
                <GenevaButton
                  className="small plain button"
                  onClick={this.handleForceTakeover}
                >
                  <FormattedMessage id="lock-state-menubar.take-over" />
                </GenevaButton>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export { LockStateMenubar as default }
