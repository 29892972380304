import { closest, matches, prev } from './dom-helpers'
import TableHandler from './table-handler'
import BaseCommand from './BaseCommand'

const TABLE_CONTAINER_CSS_CLASS = 'table-container'
const TABLE_RESIZERS_CSS_CLASS = 'table-resizers'

class TableCommandImpl extends BaseCommand {

  static defaultData = {
    appearance: [],
    layoutMode: null
  }

  static commandName = 'insertTable'

  beforeCacheSelection() {
    let selection = new this.scribe.api.Selection()
    selection.selectMarkers(true)
    const range = selection.selection.getRangeAt(0)

    let parent = range.commonAncestorContainer

    if (parent.nodeType === Node.TEXT_NODE) {
      parent = parent.parentNode
    }

    this.parentWidh = window
    .getComputedStyle(parent)
    .getPropertyValue('width')
  }


  /**
  * @private
  * @param  {Object} linkData The data for the link.
  */
  insertTable(tableData) {
    return new Promise((resolve) => {

      this.cleanSelectionMarkers()

      const range = this.getRangeOfSelection()
      const table = this.createTable(tableData)

      // Remove everything in the selection
      // (extract seems to actually get the data out of the dome immediately)
      range.extractContents()

      // check if there is a next element.
      // (NEEDS to be here before modifying range)
      const needsPTagAtEnd = !range.endContainer.nextSibling

      // if the selection is now empty and start and end container
      // are the same, wrap the selection around that container
      if (
        range.endContainer === range.startContainer &&
        range.endContainer.textContent === ''
      ) {
        if (range.endContainer === this.scribe.el) {
          range.selectNodeContents(range.endContainer)
        }
        else {
          range.selectNode(range.endContainer)
        }
      }

      const frag = TableHandler.prepareTableForScribe(table, needsPTagAtEnd)
      const firstChild = frag.firstChild
      // Insert the table at given range
      range.insertNode(frag)

      this.removeMarkers()

      // set cursor to first cell in table
      const cell = table.querySelectorAll('td')[0]

      range.selectNodeContents(cell)

      let selection = new this.scribe.api.Selection()

      if (selection) {
        selection.selection.removeAllRanges()
        selection.selection.addRange(range)
        selection.range = range
      }

      selection = new this.scribe.api.Selection()
      selection.placeMarkers()

      resolve()

    })
  }

  createTable(tableData) {
    // get the with of the container the table is to be
    // inserted into and use that as width of the table
    tableData.width = this.parentWidh

    if (tableData.layoutMode === 'responsive') {
      tableData.width = '100%'
    }

    return TableHandler.createTable(tableData)
  }


  /**
  * @private
  * @return {Object} The data found in any pre-existing link, or a default
  * object if no pre-existing link was found.
  */
  getInitialData(anchorNode) {

    const initialData = super.getInitialData(anchorNode)

    let className

    if (anchorNode) {
      // ....
    }
    else {
      // ...
    }

    return initialData

  }


  /**
  * @private
  * @param  {scribe.Selection} selection The selection containing the
  * element to be focused
  */
  focusEditable(selection) {
    let el = closest(selection.range.commonAncestorContainer, '[contenteditable]')[0]

    if (!el) {
      el = selection.getContaining((node) =>
        node.hasAttribute && node.hasAttribute('contenteditable')
      )
    }

    if (el) {
      el.focus()
    }
  }

  finishCommand() {
    this.scribe._skipFormatters = true

    // return new Promise((resolve, reject) => {

      // setTimeout(() => {
        const selection = new this.scribe.api.Selection()
        if (
          selection.selection &&
          selection.selection.anchorNode
        ) {
          const table = closest(selection.selection.anchorNode, 'table')
          if (!table) {
            // reject(new Error('Table Insertion Error: Table not found!'))
            return
          }
          TableHandler.revalidateTable(table)
          TableHandler.createResizers(table)
        }
        else {
          if (!table) {
            // reject(new Error('Table Insertion Error: Invalid selection!'))
            return
          }
        }
        this.scribe._skipFormatters = false
      //   resolve()
      // }, 1)

    // })

    super.finishCommand()
  }
}



export default function (tableOpts = {}) {

  return function (scribe) {

    if (!scribe.api.Selection.prototype.updateFromRange) {
      throw new Error('Scribe TableCommand: ' +
      'The Scribe Selection plugin with the `updateFromRange` function ' +
      'is required to use this command!')
    }

    function createSandbox(content) {

      const sandbox = document.createElement('div')
      sandbox.className = 'table-validation-sandbox'
      sandbox.innerHTML = content
      sandbox.style.marginLeft = '-999999999px'
      sandbox.style.width = '100%'
      document.body.appendChild(sandbox)

      return {
        el: sandbox,
        destroy: () => {
          document.body.removeChild(sandbox)
          return sandbox.innerHTML
        }
      }

    }

    function tableValidator(html) {

      let sandbox
      // bail out early id no table tag appears to be
      // in the input
      if (html.indexOf('<table') === -1) {

        if (html.indexOf(TABLE_CONTAINER_CSS_CLASS) > -1) {

          sandbox = createSandbox(html)
          const containers = sandbox.el
            .querySelectorAll(`.${TABLE_CONTAINER_CSS_CLASS}`)
          Array.prototype.forEach.call(containers, (container) => {
            container.parentNode.removeChild(container)
          })

          html = sandbox.destroy()
        }

        return html
      }

      sandbox = createSandbox(html)
      const tableContainers = sandbox.el
        .querySelectorAll(`.${TABLE_CONTAINER_CSS_CLASS}`)

      Array.prototype.forEach.call(tableContainers, container => {

        // Move all elements that don't belong into the table-container
        // outside of it:
        // First move the items before the table outside of the container
        while (
          container.firstChild &&
          container.firstChild.tagName !== 'TABLE'
        ) {
          container.parentNode.insertBefore(
            container.firstChild,
            container
          )
        }

        // Then move the items after the resizers outside of the container
        while (
          container.lastChild &&
          !(container.lastChild.tagName === 'TABLE' ||
          container.lastChild.className === TABLE_RESIZERS_CSS_CLASS)
        ) {
          container.parentNode.insertBefore(
            container.lastChild,
            container.nextSibling
          )
        }

        const table = container.firstChild
        const resizers = container.lastChild

        // Finally move the items between the table and the resizers out
        while (
          resizers &&
          resizers.previousSibling &&
          resizers.previousSibling !== table
        ) {
          container.parentNode.insertBefore(
            resizers.previousSibling,
            container.nextSibling
          )
        }

        if (!container.querySelectorAll('table').length) {
          container.parentNode.removeChild(container)
        }
      })
      html = sandbox.destroy()

      // if there is a table but no controls for it, create them
      if (!html.indexOf(TABLE_CONTAINER_CSS_CLASS) > -1) {
        sandbox = createSandbox(html)
        const tables = sandbox.el.querySelectorAll('table')
        Array.prototype.forEach.call(tables, (table) => {
          TableHandler.createResizers(table)
        })
      }

      return sandbox.destroy()
    }

    function onScribeElementFocused(e) {
      const el = closest(e.target, '[contenteditable]')
      if (el) {
        TableHandler.addResizingListeners(el)
      }
    }

    function onScribeElementBlured(e) {
      const el = closest(e.target, '[contenteditable]')
      if (el) {
        TableHandler.removeResizingListeners(el)
      }
    }

    const KEY_BACKSPACE = 8
    const KEY_DEL = 46
    function onScribeElementKeyDown(e) {

      // if we are in a table container and the pressed key was a backspace,
      // move the selection before the contaner and continue with the command
      // from there
      if (e.keyCode === KEY_BACKSPACE) {
        const selection = new scribe.api.Selection()
        const range = selection.range;

        if (range.collapsed) {
          const COLLAPSE_TO_START = true
          if (matches(
            range.startContainer,
            `.${TABLE_CONTAINER_CSS_CLASS}`
          )) {
            range.setStartBefore(range.startContainer)
            range.collapse(COLLAPSE_TO_START)
            selection.selection.removeAllRanges()
            selection.selection.addRange(range)
            selection.range = range
            e.preventDefault()
          }

          // if the next sibling is a table container
          else if (
            range.startContainer.previousSibling &&
            matches(
              range.startContainer.previousSibling,
              `.${TABLE_CONTAINER_CSS_CLASS}`
            )
          ) {
            scribe.transactionManager.run(() => {
              // if the current container is empty, remove it
              if (!range.startContainer.textContent.length) {
                const tableContainer = range.startContainer.nextSibling
                range.startContainer.parentNode.removeChild(range.startContainer)
                const tds = tableContainer.querySelectorAll('td:last-child')
                if (tds.length) {
                  range.selectNodeContents(tds[tds.length - 1])
                  selection.updateFromRange(range)
                }
                e.preventDefault()
              }
              // otherwise, if the selection is at the first index of
              // the container, select the complete table container
              else {
                if (range.startOffset === 0) {
                  range.selectNode(range.startContainer.previousSibling)
                  selection.updateFromRange(range)
                  e.preventDefault()
                }
              }
            })
            // always prevent default action

          }
        }
      }
      else if (e.keyCode === KEY_DEL) {
        const selection = new scribe.api.Selection()
        const range = selection.range;

        if (range.collapsed) {
          if (
            range.startContainer.nextSibling &&
            matches(
              range.startContainer.nextSibling,
              `.${TABLE_CONTAINER_CSS_CLASS}`
            )
          ) {
            scribe.transactionManager.run(() => {
              // Only if the current contaier has ho content at all, remove it.
              if (!range.startContainer.textContent.length) {
                const tableContainer = range.startContainer.nextSibling
                range.startContainer.parentNode.removeChild(range.startContainer)
                const tds = tableContainer.querySelectorAll('td:first-child')
                if (tds.length) {
                  range.selectNodeContents(tds[0])
                  selection.selection.removeAllRanges()
                  selection.selection.addRange(range)
                  selection.range = range
                }
                // always prevent default action
                e.preventDefault()
              }
              // Otherwise select the complete table contaner
              else {
                range.selectNode(range.startContainer.nextSibling)
                selection.updateFromRange(range)
                // always prevent default action
                e.preventDefault()
              }
            })

          }
        }
      }

    }

    function insertTable(table) {
      const selection = new scribe.api.Selection()
      selection.selectMarkers()

      const range = selection.selection.getRangeAt(0)

      const needsPTagAtEnd = !range.endContainer.nextSibling

      range.insertNode(TableHandler.prepareTableForScribe(table, needsPTagAtEnd))

      const cell = table.querySelectorAll('td')[0]

      range.selectNodeContents(cell)

      if (selection) {
        selection.selection.removeAllRanges()
        selection.selection.addRange(range)
        selection.range = range
      }
    }

    function getTableFromSelection() {
      const selection = new scribe.api.Selection()
      const range = selection.range
      let container

      if (!range) {
        return null
      }

      container = range.commonAncestorContainer

      if (container.nodeType === Node.TEXT_NODE) {
        container = container.parentNode
      }

      if (container &&
        container.classList &&
        container.classList.contains('table-container')) {

        return container.querySelector('table')

      }

      return closest(container, 'table')
    }

    function getColumnIndexFromSelection() {
      const selection = new scribe.api.Selection()
      const range = selection.range

      if (!range) {
        return null
      }

      const elem = prev(closest(range.commonAncestorContainer, 'td'))

      return elem
        ? elem.length || 0
        : 0
    }

    function getRowIndexFromSelection() {
      const selection = new scribe.api.Selection()
      const range = selection.range

      if (!range) {
        return null
      }

      const elem = prev(closest(range.commonAncestorContainer, 'tr'))

      return elem
        ? elem.length || 0
        : 0
    }


    const insertTableCommand = new scribe.api.Command('insertTable')

    insertTableCommand.execute = function executeInsertTable() {


      if (!tableOpts.showTablePrompt) {
        throw new Error('insertTableCommand: Cannot run, no `showTablePrompt` defined in tableOpts!')
      }


      // let selection = new scribe.api.Selection()
      // const range = selection.range.cloneRange()
      //
      // const el = closest(range.commonAncestorContainer, '[contenteditable]')
      //
      // selection.placeMarkers()
      // const content = el.innerHTML
      //
      const impl = new TableCommandImpl(scribe, this)

      scribe.transactionManager.runAsync((completeTransaction) => {

        impl.cacheSelection()

        tableOpts.showTablePrompt({})
        .then((tableSpec) => {

          impl.restoreSelection()
          .then(() => {
            if (tableSpec) { //TODO: check which button was clicked
              return impl.executeCommand('insertTable', tableSpec)
            }
          })
          .then(() => impl.finishCommand())
          .then(completeTransaction)

        })

      })

    }

    insertTableCommand.queryEnabled = function () {
      return !getTableFromSelection()
    }

    insertTableCommand.queryState = function () {
      return !!getTableFromSelection()
    }


    let currentTable = null


    // -- CHANGE APPEARANCE --

    const changeAppearance = new scribe.api.Command('changeAppearance')

    changeAppearance.execute = function (appearances) {

      let table = getTableFromSelection()

      if (!table) {
        table = currentTable
      }


      if (!table) {
        throw new Error('No table selected!')
      }

      appearances = appearances || []

      scribe.transactionManager.run(() => {
        TableHandler.changeAppearance(table, appearances)
      })

    }

    changeAppearance.queryEnabled = function () {
      return !!getTableFromSelection()
    }

    changeAppearance.queryState = function () {

      const table = getTableFromSelection()

      currentTable = table

      if (!table) {
        return {}
      }

      return TableHandler.getAppearance(table)

    }


    // -- CHANGE LAYOUT MODE --

    const changeLayoutMode = new scribe.api.Command('changeLayoutMode')

    changeLayoutMode.execute = function (layoutMode) {

      let table = getTableFromSelection()

      if (!table) {
        table = currentTable
      }

      if (!table) {
        throw new Error('No table selected!')
      }

      layoutMode = layoutMode || []

      scribe.transactionManager.run(() => {
        TableHandler.changeLayoutMode(table, layoutMode)
      })

    }

    changeLayoutMode.queryEnabled = function () {
      return !!getTableFromSelection()
    }

    changeLayoutMode.queryState = function () {

      const table = getTableFromSelection()

      currentTable = table

      if (!table) {
        return {}
      }

      return TableHandler.getLayoutMode(table)

    }

    // -- RESIZE TABLE --

    const resizeTableCommand = new scribe.api.Command('resizeTable')

    resizeTableCommand.execute = function (opts) {
      let table

      opts = opts || {}

      table = opts.table

      if (!table) {
        table = getTableFromSelection()
      }

      table = closest(table, 'table')

      scribe.transactionManager.run(() => {
        TableHandler.resizeTable(table, opts.size, opts.dir)
      })
    }


    const insertTableColumn = new scribe.api.Command('insertTableColumn')

    insertTableColumn.execute = function (opts) {
      const table = getTableFromSelection()
      opts = opts || {}
      opts.pos = opts.pos || getColumnIndexFromSelection()

      // will get automatically turned back on later
      scribe._skipFormatters = true

      scribe.transactionManager.run(() => {
        TableHandler.insertColumn(table, opts)
      })
    }

    insertTableColumn.queryEnabled = function () {
      return !!getTableFromSelection()
    }




    const insertTableRow = new scribe.api.Command('insertTableRow')

    insertTableRow.execute = function (opts) {
      const table = getTableFromSelection()
      opts = opts || {}
      opts.pos = opts.pos || getRowIndexFromSelection()

      // will get automatically turned back on later
      scribe._skipFormatters = true

      scribe.transactionManager.run(() => {
        TableHandler.insertRow(table, opts)
      })
    }

    insertTableRow.queryEnabled = function () {
      return !!getTableFromSelection()
    }





    const removeTableColumn = new scribe.api.Command('removeTableColumn')

    removeTableColumn.execute = function (opts) {
      const table = getTableFromSelection()
      opts = opts || {}
      opts.pos = opts.pos || getColumnIndexFromSelection()

      if (!table) {
        return false
      }

      if (table.querySelector('tr').querySelectorAll('td').length > 1) {
        // will get automatically turned back on later
        scribe._skipFormatters = true
        scribe.transactionManager.run(() => {
          TableHandler.removeColumn(table, opts)
        })
      }
      else {
        scribe.transactionManager.run(() => {
          TableHandler.removeTable(table)
        })
      }

    }

    removeTableColumn.queryEnabled = function () {
      const table = getTableFromSelection()
      if (!table) {
        return false
      }

      return table
        .querySelector('tr')
        .querySelectorAll('td')
        .length > 0
    }





    const removeTableRow = new scribe.api.Command('removeTableRow')

    removeTableRow.execute = function (opts) {
      const table = getTableFromSelection()
      opts = opts || {}
      opts.pos = opts.pos || getRowIndexFromSelection()

      // check if this is the last row
      if (!table) {
        return false
      }

      if (table && table.querySelectorAll('tr').length > 1 ) {

        // will get automatically turned back on later
        scribe._skipFormatters = true
        scribe.transactionManager.run(() => {
          TableHandler.removeRow(table, opts)
        })
      }
      else {
        scribe.transactionManager.run(() => {
          TableHandler.removeTable(table)
        })
      }

    }

    removeTableRow.queryEnabled = function () {
      const table = getTableFromSelection()
      if (!table) {
        return false
      }
      return table
        .querySelectorAll('tr')
        .length > 0
    }







    scribe.el.addEventListener('focus', onScribeElementFocused, false)
    scribe.el.addEventListener('blur', onScribeElementBlured, false)
    scribe.el.addEventListener('keydown', onScribeElementKeyDown, false)

    // cleanup
    scribe.on('scribe:destroy', () => {
      if (scribe.el) {
        scribe.el.removeEventListener('focus', onScribeElementFocused, false)
        scribe.el.removeEventListener('blur', onScribeElementBlured, false)
        scribe.el.removeEventListener('keydown', onScribeElementKeyDown, false)
      }
    })

    scribe.registerHTMLFormatter('sanitize', tableValidator)

    scribe.commandPatches.insertTable = insertTableCommand

    scribe.commandPatches.resizeTable = resizeTableCommand

    scribe.commandPatches['insertTable.insertTableColumn'] = insertTableColumn

    scribe.commandPatches['insertTable.insertTableRow'] = insertTableRow

    scribe.commandPatches['insertTable.removeTableRow'] = removeTableRow

    scribe.commandPatches['insertTable.removeTableColumn'] = removeTableColumn

    scribe.commandPatches['insertTable.changeAppearance'] = changeAppearance

    scribe.commandPatches['insertTable.changeLayoutMode'] = changeLayoutMode

  }

}
