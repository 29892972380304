import { createConfirmation } from 'react-confirm'
import ArticleUsageDialog from '../../components/dialogs/ArticleUsage'

const articleUsage = createConfirmation(ArticleUsageDialog)


export default function (confirmation, options = {}) {

  if (typeof confirmation !== 'string') {
    options = { ...confirmation }
    confirmation = ''
  }

  return articleUsage({ confirmation, options })
}
