
import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import ReactCSSTransitionGroup from 'react-transition-group/CSSTransitionGroup'
import classNames from 'classnames'
import { autobind } from 'core-decorators'
import { autorun, observable } from 'mobx'
import config from '../../config'
import { deepGet } from '../../shared/obj'
import { TemplateLoadingError } from '../../shared/errors'
import ContentErrorBox from '../../shared/components/ContentErrorBox'
import ContentLoadingBox from '../../shared/components/ContentLoadingBox'
import { getContentClass } from '../../shared/utils'
import {
  connectEditor as connectEditorToScribe,
  getSharedToolbar,
} from '../../editor/scribe'
import Editor from '../components/Editor'
import {
  TemplateDependencyCollector,
  TemplateActivator,
} from '../loaders/editor'
import DevEnvironmentsSlider from '../components/DevEnvironmentsSlider'
import * as connectors from '../connectors'

const css = /* typeof window === 'undefined' ? {} : */ require('../styles.scss')


export default class TemplateDetailsContainer extends Component {
  static propTypes = {
    article: PropTypes.object.isRequired,
    context: PropTypes.object.isRequired,
    customLocal: PropTypes.object.isRequired,
    image: PropTypes.object.isRequired,
    template: PropTypes.object.isRequired,
    toolbarElem: PropTypes.object.isRequired,
    ui: PropTypes.object.isRequired,
    env: PropTypes.object,
  };

  constructor(props) {
    super(props)

    this.state = {
      template: null,
      templateError: null,
      templateShortcut: null,
    }

    this.handler = []

    // TODO intoduce a shallow object that tracks the setup process of templates
    // this.handler.push(
    //   autorun(() => this.handleTemplateLoad(this.props.template.currentState))
    // )

    this.editorTemplateActivator = new TemplateActivator(this.ENV, connectors, {
      reloadStylesheets: false,
      type: 'article',
    })
  }

  componentDidMount() {
    const { template } = this.props

    this.handler = [
      autorun(() => this.loadTemplate(template.current.id)),

      autorun(() => this.loadTemplateModule(template.current.shortcut)),

      autorun(() => {
        const module = this.props.template.current.module

        if (module && module.getTemplate) {
          this.validateTemplateModule(module)
        }
      }),

      autorun(() => {
        this.editorTemplateActivator.opts.reloadStylesheets
          = this.props.template && this.props.template.alwaysReloadStyleSheets
      }),
    ]
  }

  componentWillUnmount() {
    if (this.handler.length) {
      this.handler.forEach(handler => handler())
      this.handler = null
    }

    this.reset()

    this.editorTemplateActivator = null

    this.ToolbarConnectedEditor = null
  }

  // this is for the environment slider dev-tool
  @observable ENV = {
    CM: true,
    PM: false,
    PREVIEW: false,
    PUBLISH: false,
  };

  reset() {
    this.currentlyDisplayedArticle = null
    this.setState({
      template: null,
      templateError: null,
      templateShortcut: null,
    })
  }

  currentTemplateIsCurrentArticleTemplate() {
    const { props } = this
    return (
      deepGet(props, 'article.current.templateId')
      === deepGet(props, 'template.current.id')
    )
  }

  loadTemplate(id) {
    // only open this template if it's has the same id that the
    // current article needs
    if (!id || !this.currentTemplateIsCurrentArticleTemplate()) {
      this.reset()
      return
    }

    if (this.props.template.current.module) {
      return
    }

    console.info('Loading article template with id %s', id)

    this.props.template.load(id)
  }

  loadTemplateModule(shortcut) {
    if (!shortcut || this.state.templateShortcut === shortcut) {
      this.reset()
      return
    }

    this.setState({
      templateShortcut: shortcut,
    })

    if (!this.currentTemplateIsCurrentArticleTemplate()) {
      this.reset()
      return
    }

    this.props.template.openTemplateModuleByShortcut(shortcut)
  }

  validateTemplateModule(module) {
    // for the template to work properly the valid toolbar is essential, so
    // load it here:
    this.ToolbarConnectedEditor = connectEditorToScribe(Editor, {
      toolbar: getSharedToolbar(this.props.toolbarElem),
      editorCssSelector: `.${css.editor}`,
    })

    // if there is no module, we cannot be showing any
    // article, so reset the flag
    if (!module) {
      this.currentlyDisplayedArticle = null
    }

    console.info('Validating template')

    const newArticle = this.props.article.current
    if (!newArticle || !newArticle.phAccessor || !newArticle.complete) {
      this.reset()
      console.info('...aborted [article invalid]')
      return
    }

    if (!module || !this.currentTemplateIsCurrentArticleTemplate()) {
      this.reset()
      console.info('...aborted [template invalid]')
      return
    }

    if (newArticle === this.currentlyDisplayedArticle) {
      console.info('...skipping [article identical]')
      return
    }

    console.info('...ok. Loading template...')

    this.currentlyDisplayedArticle = this.props.article.current
    this.currentModule = module

    this.activateTemplate()
  }

  activateTemplate() {
    const { customLocal, context } = this.props

    return new Promise((resolve) => {
      if (this.currentModule instanceof Error) {
        throw this.currentModule
      }

      const dependencyCollector = new TemplateDependencyCollector({
        props: this.props,
        connectors,
        componentOverrides: {
          Editor: this.ToolbarConnectedEditor,
        },
        ENV: this.ENV,
        contextStore: context,
      })

      const dependencies = {
        // TODO: remove article specific dependencies, so template can be cached
        // over various articles with same template id
        ...dependencyCollector.collect(),
        ...this.props.template.current.dependencies,
      }

      const templateComponent = this.currentModule.getTemplate(dependencies)

      return this.editorTemplateActivator
        .activate({
          channelShortcut: this.props.article.current.channelShortcut,
          templateSpec: this.props.template.current,
          templateComponent,
          templateModule: this.currentModule,
          dependencies,
          injectTemplateProps: {},
          customLocalStore: customLocal,
        })
        .then(template => resolve({ template }))
    })
      .catch((error) => {
        const templateError = new TemplateLoadingError(
          'error.template-error',
          this.props.template.current.shortcut,
          error
        )
        console.error(templateError)
        return { templateError }
      })
      .then((result) => {
        this.setState({
          template: result.template || null,
          templateError: result.templateError || null,
        })
      })
  }

  @autobind
  handleChangeEnvironment({ target }) {
    Object.keys(target.value.ENV).forEach((key) => {
      this.ENV[key] = target.value.ENV[key]
    })
  }

  @autobind
  handleFocusTemplateItem(/* target */) {}

  @autobind
  handleBlurTemplateItem(/* target */) {}

  @autobind
  handleClick(e) {
    if (
      this.currentlyDisplayedArticle
      && this.currentlyDisplayedArticle.isVersion
    ) {
      const link = e.target.closest('A')

      if (link && link.href && !e.shiftKey) {
        e.preventDefault()

        this.props.ui.addStatusInfo(
          {
            id: 'pm-link-info',
            name: 'link-prevented',
            value: link.href,
            priority: 4,
          },
          5000
        )
      }
    }
  }


  renderTemplate() {
    const { template } = this.state
    const { article } = this.props

    const ConnectedTemplate = template

    return (
      <ConnectedTemplate
        env={this.ENV}
        onFocus={this.handleFocusTemplateItem}
        onBlur={this.handleBlurTemplateItem}
        placeholder={article.current.phAccessor}
      />
    )
  }

  render() {
    const { template, templateError } = this.state
    const { toolbarElem } = this.props
    let style = null

    let error = templateError

    if (!error) {
      if (!toolbarElem) {
        error = new TemplateLoadingError('error.toolbar-loading')
      }

      if (!template) {
        return (
          <ContentLoadingBox spinner message={{ id: 'template.loading' }} />
        )
      }
    }

    if (!config.isProduction) {
      style = {
        marginTop: '10px',
      }
    }

    return (
      <div className={classNames('grid-block', css.allowOverflow)}>
        {!config.isProduction ? (
          <DevEnvironmentsSlider
            environments={this.ENV}
            onChange={this.handleChangeEnvironment}
          />
        ) : null}
        <div
          onClick={this.handleClick}
          className={classNames(
            'grid-block',
            getContentClass(),
            'geneva-cm-template-preview',
            css.contentPreviewContainer
          )}
          style={style}
        >
          <ReactCSSTransitionGroup
            component="div"
            transitionEnter={false}
            transitionLeave={false}
            transitionName="page"
            transitionAppear
            transitionAppearTimeout={500}
            className={classNames(
              'react-transition-group',
              'geneva-cm-template-preview-transition-group',
              css.contentPreviewContainerContent
            )}
          >
            {!error ? (
              this.renderTemplate()
            ) : (
              <ContentErrorBox
                className={classNames(css.templateError, 'grid-block')}
                error={error}
              />
            )}
          </ReactCSSTransitionGroup>
        </div>
      </div>
    )
  }
}
