import * as PropTypes from 'prop-types'
import React from 'react'
import { withRouter } from 'react-router'

import createParentPropsInjector from '../../shared/utils/create-parent-props-injector'
import commandConnector from '../../shared/lib/command'

import ReviewCenter from '../components/ReviewCenter'
import { store as reviewStore } from '..'
import { store as authStore } from '../../auth'

import AddChatMessageCommand from '../commands/add-chat-message'
import AddUserToChatCommand from '../commands/add-user-to-chat'
import UpdateUserCommand from '../commands/update-user'

export default function connectReviewCenter(parentProps) {
  const injectParentProps = createParentPropsInjector(parentProps)

  // Connector function
  function ReviewCenterConnector({ ...rest }) {
    return (
      <ReviewCenter authStore={authStore} reviewStore={reviewStore} {...rest} />
    )
  }

  // Component props
  ReviewCenterConnector.propTypes = {
    context: PropTypes.object,
    page: PropTypes.object.isRequired,
  }

  // Inject commands
  return commandConnector({
    AddUserToChatCommand,
    AddChatMessageCommand,
    UpdateUserCommand,
  })(withRouter(injectParentProps(ReviewCenterConnector)))
}
