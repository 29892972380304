import _debug from 'debug'
import Communicator from './shared/decorators/communicator/Communicator'
import config from './config'

const debug = _debug('geneva:COMMUNICATOR')

export default class CommunicatorSingleton {
  static getInstance() {
    if (!this.instance) {
      this.instance = new Communicator(config.ws)
      this.instance.addLogger((data, dir) => {
        debug(
          `${dir === 'out' ? '<--' : '-->'} ${(
            data.action || ''
          ).toUpperCase()}`,
          data
        )
      })
    }
    return this.instance
  }
}
