import containerSwitcher from './ContainerSwitcher'
import Container from './Container'

containerSwitcher.Container = Container

// Per default this is disabaled. It's only needed in the Editor so only
// implement it in the related connector.
containerSwitcher.TransformationInfo = function PositionableTransformationInfo() {
  return null
}

export { containerSwitcher as default }
