import { extendObservable, observable } from 'mobx'
import { IViewModel } from '../lib/view-model'

import { store as contextStore } from '../../context'

import { deepGet } from '../../shared/obj'

interface ModelSpec {
  id: number | Function;
  type: string;
  as: string | Function;
  component: string;
}

export default
class DistinctModelObserver
implements IViewModel {

  private store: any;
  private refCounter: {[key: string]: number};
  private refComponentNames: {[key: string]: string}
  private refStore: {[key: string]: string}

  public
  @observable data: any = {};

  constructor ({store}) {
    this.store = store
    this.refCounter = {}
    this.refComponentNames = {}
    this.refStore = {}
  }

  static fromProps (deepPropName: string): Function {
    return (props): any => {
      return deepGet(props, deepPropName)
    }
  }

  static fromContext (deepPropName: string): Function {
    return (): any => {
      return deepGet(contextStore.getCurrentIds(), deepPropName)
    }
  }

  public init(modelSpecs: Array<ModelSpec>, props?: {[key: string]: any}) {
    const newObservables = modelSpecs.reduce((memo, spec) => {
      const key = this.getKeyFromSpec(spec)
      const id = this.getIdFromSpec(spec, props)
      const store = this.store[spec.type]

      if (store && id) {
        const model = this.store[spec.type].getById(id)

        // if there is already a model with the same key
        if (key in this.data) {
          // same model, everthing alright, resume
          if (this.data[key] === model) {
            return memo
          }

          // new model with the same key was created.
          // Happens when a component updates with a new e.g. article
          // or it never gets unmounted or disposed
          const newRef = this.getCounterRefId(spec, props)
          const oldRef = this.getOldCounterRefId(key)

          if (spec.component === this.refComponentNames[key]) {
            // same component as last time, everything alright
            // but remove the old ref
            this.removeRefCounterById(oldRef, key)
            console.warn(`New viewModel ${key} created for ${spec.component}`)
          }
          else {
            // another component tries to create a viewModel with a key already used.
            // this can cause serious data problems
            throw new Error(
              `DistinctModelObserver: The key \`${key}\` is already used for ${this.refComponentNames[oldRef]}`
            )
          }
        }
        memo[this.getKeyFromSpec(spec)] = model
      }

      return memo
    }, {})
    // create or overwrite viewModel
    extendObservable(this.data, newObservables)
  }

  public dispose(modelSpecs: Array<ModelSpec>, props?: {[key: string]: any}) {
    const newObservables = modelSpecs.forEach((spec) => {
      this.removeRef(spec, props)
    })
  }

  public collect(modelSpecs: Array<ModelSpec>, props?: {[key: string]: any}) {
    const newObservables = modelSpecs.forEach((spec) => {
      this.addRef(spec, props)
    })
  }

  private getKeyFromSpec(spec: ModelSpec) {
    const as = typeof spec.as === 'function' ? spec.as() : spec.as
    return as
  }

  private getIdFromSpec(spec: ModelSpec, props: {[key: string]: any}) {
    return typeof spec.id === 'function' ? spec.id(props) : spec.id
  }

  private getCounterRefId(spec: ModelSpec, props: {[key: string]: any}) {
    return `${spec.type}:${this.getIdFromSpec(spec, props)}:${this.getKeyFromSpec(spec)}`
  }

  private getOldCounterRefId(key: string) {
    return this.refStore[key]
  }

  private getRefComponentName(spec: ModelSpec) {
    return spec.component
  }

  private addRef(spec: ModelSpec, props: {[key: string]: any}) {
    const refCounterId = this.getCounterRefId(spec, props)
    const refComponentName = this.getRefComponentName(spec)
    const key = this.getKeyFromSpec(spec)

    if (!(refCounterId in this.refCounter)) {
      this.refCounter[refCounterId] = 0
    }

    this.refCounter[refCounterId] += 1
    this.refComponentNames[key] = refComponentName
    this.refStore[key] = refCounterId
  }

  private removeRef(spec: ModelSpec, props: {[key: string]: any}) {
    const refCounterId = this.getCounterRefId(spec, props)
    const key = this.getKeyFromSpec(spec)

    this.refCounter[refCounterId] -= 1

    if (this.refCounter[refCounterId] <= 0) {
      delete this.refCounter[refCounterId]
      delete this.data[key]
      delete this.refComponentNames[key]
      delete this.refStore[key]
    }
  }

  private removeRefCounterById(refCounterId: string, key: string) {
    this.refCounter[refCounterId] -= 1

    if (this.refCounter[refCounterId] <= 0) {
      delete this.refCounter[refCounterId]
    }
  }
}
