import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { autobind } from 'core-decorators'
import classNames from 'classnames'
import { FormattedMessage } from '../../translations'
import Icon from '../../shared/icons'
import uploadDialog from '../../shared/containers/UploadDialog'
import GenevaButton from '../../ui/components/GenevaButton'

@observer
export default class VideoCMToolbar extends Component {

  static propTypes = {
    context: PropTypes.object,
    className: PropTypes.string,
    onVideoImportFromBynder: PropTypes.func,
    onChangeVideo: PropTypes.func.isRequired,
    onRemoveVideo: PropTypes.func.isRequired
  }

  @autobind
  handleChangeVideo() {

    if (this.props.onChangeVideo) {

      uploadDialog({
        mediaType: 'video',
        id: this.props.context.target.id,
      }).then((result) => {
        if (result.type && result.type === 'fromBynder') {
          // Replacing a single image using Bynder
          this.handleVideoFromBynder()
        }

        this.props.onChangeVideo(result)
      })
    }
  }

  @autobind
  handleVideoFromBynder() {
    if (this.props.onVideoImportFromBynder) {
      this.props.onVideoImportFromBynder()
    }
  }

  @autobind
  handleRemoveVideo() {
    if (this.props.onRemoveVideo) {
      this.props.onRemoveVideo()
    }
  }

  render() {

    const { target } = this.props.context

    const hasValue = !!(target
      && (target.value || target.url))

    return (<div
      className={classNames(this.props.className, 'toolbar', 'v-align')}
    >
      <span className="muted">
        <FormattedMessage id="video.video" />:&nbsp;
      </span>

      <GenevaButton className="small button plain"
        onClick={this.handleChangeVideo}
        disabled={!hasValue}
      >
        <FormattedMessage id="video.replace" />
      </GenevaButton>

      <span className="separator vertical"></span>

      <GenevaButton
        className="small button plain"
        onClick={this.handleRemoveVideo}
        disabled={!hasValue}
      >
        <Icon name="ion-ios-trash-outline" />
      </GenevaButton>

    </div>)
  }
}
