import { defaultChannel } from '../../config'
import InsertImageIntoArticlePlaceholderCommand from './insert-image-into-article-placeholder'
import { store as imageStore } from '../../image'
import Command from '../command'
import { deepGet } from '../obj'
import { i18n } from '../utils'

// eslint-disable-next-line max-len


function keyRequired(key, path = null) {
  if (path) {
    path = `.${path}`
  }
  // eslint-disable-next-line max-len
  return `requires \`data.${path}${key}\` (from \`contextStore.target.${key}\`) to be present`
}

export default class EditImageInArticlePlaceholderCommand extends Command {

  constructor(imageData, placeholderData) {

    placeholderData.image = imageData

    const data = placeholderData

    super(data)

    this.data = data

  }

  validate(data) {

    return ['article', 'pid']
      .filter(key => !(key in data) || !data[key])
      .map(key => keyRequired(key))
      .join(', ')

  }

  exec() {

    const currImage = this.data.article.getDataInPlaceholder(this.data.pid)
    const channel = this.data.article.channel
      ? this.data.article.channel.shortcut || this.data.article.channel
      : defaultChannel
    const requesterLang = this.data.article.createdIso

    return imageStore.createModel({ id: currImage.id })
      .load({ params: { lang: requesterLang } })
      .then((model) => {

        const langSpecificImage = i18n(model, 'draft', this.data.article.createdIso)

        // If there is a preview object,
        // we use a not supported filetype in original
        const url = `${langSpecificImage.preview
          ? langSpecificImage.preview.url
          : langSpecificImage.original.url
        }?${(new Date()).getTime()}`

        const constraints = deepGet(langSpecificImage, `constraints.${channel}`) || {}

        const cmd = new InsertImageIntoArticlePlaceholderCommand(
          {
            ...this.data.image,
            url,
            value: url,
            channel,
            frame: constraints.frame || {}
          },
          this.data,
          {
            editing: true
          }
        )

        // pass through events
        cmd.on('*', args => this.trigger(...args))

        return cmd.exec()

      })

  }
}
