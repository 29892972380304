import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { autobind } from 'core-decorators'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import { FormattedMessage } from '../../translations'
import { store as widgetStore } from '..'
import Dropdown, {
  DropdownContainer, Entry, DropdownOpener, Separator
} from '../../shared/components/Dropdown'
import { filter } from '../../shared/obj'
import GenevaDropdownOpener from '../../ui/components/GenevaDropdownOpener'

import { confirmDelete, widgetSecurity } from '../containers/dialogs'


const ObservedEntry = observer((props) => {

  const { entry, id, onClick } = props

  return (<Entry
    {...entry.state}
    identifier={id}
    onClick={onClick}
  >
    {entry.name}
  </Entry>)

})

@observer
class WidgetCMToolbar extends Component {

  static propTypes = {
    className: PropTypes.string,
    ui: PropTypes.object.isRequired,
    widget: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
  }

  isValidEntry(entry) {

    if (entry === '-') {
      return true
    }

    if (!entry.name) {
      console.warn('WidgetCMToolbar: A custom entry HAS to have a name property of type string')
      return false
    }

    if (!('handleClick' in entry)) {
      // eslint-disable-next-line max-len
      console.warn('WidgetCMToolbar: A custom entry HAS to have an handleClick property of type function')
      return false
    }

    return true
  }

  @autobind
  handleEntryClick({ target }) {
    const entry = this.props.ui.widgetContextTools[target.identifier]
    if (entry && entry.handleClick) {
      entry.handleClick()
    }
    if (entry && entry.handleQueryState) {
      entry.handleQueryState(entry.state)
    }
  }

  @autobind
  handleDeleteWidget() {

    const widget = this.props.widget.currentEditable
    const widgetId = widget.id

    confirmDelete({
      widget
    }).then(() => {

      widgetStore.actionDestroyItem(widgetId)

    })

  }

  @autobind
  handleOpenWidgetSecurityDialog() {

    const widget = this.props.widget.currentEditable

    const filtered = filter(
      widget || {},
      [
        'releasePublicationLockAt',
        'publicationNotAllowed'
      ]
    )
    widgetSecurity({
      filtered
    }).then((result) => {

      widget.set({
        ...result.data
      })
      widget.save()
      // TODO: Catch error and remove data
      // .catch(() => {
      // })

    }).catch(() => {
    })
  }

  renderCustomContextTools() {
    const { ui: { widgetContextTools } } = this.props
    return (<Entry>

      <DropdownOpener>
        <FormattedMessage id="widget.options" />
      </DropdownOpener>

      <Dropdown>

        {widgetContextTools.map((entry, id) => (entry === '-'

          ? <Separator />

          : <ObservedEntry
            key={`widget-context-tools-${entry.name}`}
            entry={entry}
            onClick={this.handleEntryClick}
            id={id}
          />

        ))}

      </Dropdown>

    </Entry>)
  }

  render() {
    let { ui: { widgetContextTools } } = this.props
    const { disabled } = this.props

    widgetContextTools = (widgetContextTools || []).filter(this.isValidEntry)
    return (<div
      className={classNames(this.props.className, 'toolbar', 'v-align')}
    >
      <DropdownContainer>

        <GenevaDropdownOpener
          clickToClose
          disabled={disabled}
          caret={false}
          arrow
        >
          <FormattedMessage id="widget.widget" />&nbsp;
        </GenevaDropdownOpener>
        <Dropdown>

          <Entry
            onClick={this.handleOpenWidgetSecurityDialog}
          >
            <FormattedMessage id="widget.permissions" />
          </Entry>

          {widgetContextTools && widgetContextTools.length
            ? this.renderCustomContextTools()
            : null
          }

          <Separator />

          <Entry
            onClick={this.handleDeleteWidget}
          >
            <FormattedMessage id="widget.delete" />
          </Entry>

        </Dropdown>

      </DropdownContainer>
    </div>)

  }
}

export default WidgetCMToolbar
