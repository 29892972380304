import PropTypes from 'prop-types'
import React from 'react'
import { sortable } from 'react-anything-sortable'


const SortableItem = sortable((props) => {
  const Comp = props.tagName || 'div'
  return (
    <Comp {...props}>
      {props.children}
    </Comp>
  )
})

SortableItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  tagName: PropTypes.string,
}

export default SortableItem
