import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { confirmable } from 'react-confirm'
import { formatMessage, FormattedMessage } from '../../../translations'
import { Dialog } from '../../../shared/components/Dialog'


@confirmable
class ConfirmDelete extends Component {

  static propTypes = {
    show: PropTypes.bool,            // indicates if the dialog is shown or not.
    proceed: PropTypes.func,         // call to close the dialog with promise resolved.
    cancel: PropTypes.func,          // call to close the dialog with promise rejected.
    dismiss: PropTypes.func,         // call to only close the dialog.
    confirmation: PropTypes.string,  // arguments of your confirm function
    options: PropTypes.object        // arguments of your confirm function
  }

  render() {


    const {
      show,
      proceed,
      cancel,
      options: { widget }
    } = this.props


    return (<Dialog
      className=""
      overlayClassName=""
      isOpen={show}
      onAfterOpen={this.handleAfterOpen}
      onRequestClose={this.handleRequestClose}
      title={formatMessage({ id: 'widget.confirm.delete.title' })}
      onCancel={cancel}
      onCommit={proceed}
    >
      <FormattedMessage id="widget.confirm.delete.are-you-sure"
        values={{
          name: widget.name
        }}
      />
    </Dialog>)

  }

}


export default ConfirmDelete
