import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { autorun } from 'mobx'
import { observer } from 'mobx-react'
import { autobind } from 'core-decorators'
import classNames from 'classnames'
import { withRouter } from 'react-router'
import { union } from 'lodash'
import PerfectScrollbar from 'perfect-scrollbar'

import { FormattedMessage } from '../../../translations'
import { store as pageStore } from '../../../page'
import { store as projectStore } from '../..'
import commandConnector from '../../../shared/lib/command'
import { dispatcher } from '../../../shared/lib/command'
import { deepGet } from '../../../shared/obj'
import { newPage } from '../dialogs'
import Tree from '../../components/Tree'
import GenevaButton from '../../../ui/components/GenevaButton'
import EditItemCommand from '../../../shared/commands/EditItemCommand.js'
import { testClass } from '../../../shared/utils'
import { hasPermission } from '../../../shared/utils/user-rights'
import { store as contextStore } from '../../../context'

import {
  SPECIAL_PAGE_ROOT_ID,
  PAGE_ID_STARTPAGE,
  PAGE_TYPE_STARTPAGE,
  PAGE_TYPE_SPECIAL_PAGE,
  PAGE_TYPE_TREE_ROOT,
  PAGE_TYPE_ARCHIVE_PAGE,
  PAGE_TYPE_ARCHIVE_SPECIAL_PAGE,
  PAGE_ID_ARCHIVE_TREE_ROOT,
} from '../../../shared/const'

@dispatcher
@observer
class PageTreeContainer extends Component {

  static propTypes = {
    page: PropTypes.object.isRequired,
    project: PropTypes.object.isRequired,
    className: PropTypes.string
  }

  constructor(props) {
    super(props)
    const cachedKeys = props.project.expandedPageKeys
      && props.project.expandedPageKeys.length > 0
      ? props.project.expandedPageKeys
      : null

    this.state = {
      tree: null,
      autoExpandParent: false,
      cachedKeys: {
        // If the navigation was used before there are keys cached.
        [PAGE_TYPE_STARTPAGE]: cachedKeys ? cachedKeys[PAGE_TYPE_STARTPAGE] : [],
        [PAGE_TYPE_SPECIAL_PAGE]: cachedKeys ? cachedKeys[PAGE_TYPE_SPECIAL_PAGE] : [],
        [PAGE_TYPE_TREE_ROOT]: cachedKeys ? cachedKeys[PAGE_TYPE_TREE_ROOT] : []
      },
      selectedKeys: [],
    }

    this.ps = null
    this.currentProjectId = null
    this.currentPageId = null
    this.currentType = 2 // standard type

    this.permission = hasPermission('createPage')
  }

  componentDidMount() {
    this.handlers = [
      autorun('AUTORUN:page-collection-length-changed', () => {
        this.updateTreeInState(this.props.page.pagePartialsCollection.length)
      }),
      autorun('AUTORUN:selected-page-changed', () => {
        if (this.props.page.hasCurrent
          // && !this.state.selectedKeys.find(el => el === `${this.props.page.current.id}`)) {
          && this.currentPageId !== this.props.page.current.id) {
          this.currentPageId = this.props.page.current.id
          this.updateSelectedPageInState(this.props.page.current.id)
        }
      }),
      autorun('AUTORUN:project-changed', () => {
        if (this.currentProjectId !== projectStore.current.id) {
          this.updateTreeInState(projectStore.current.id)
        }
      }),
      autorun('AUTORUN:selected-page-type-changed', () => {
        if (this.props.page.hasCurrent && this.props.page.current.type !== this.currentType) {
          this.currentType = this.props.page.current.type
          this.updateSelectedPageInState(this.props.page.current.id)
        }
      })
    ]
  }

  componentWillUnmount() {
    this.handlers.map(handler => handler())
  }

  updateTreeInState(/* len */) {
    this.currentProjectId = projectStore.current.id

    this.setState({
      pageTreeRoot: this.props.project.pageTree.children,
      specialPageTreeRoot: [this.props.project.specialPageTree],
      archiveTreeRoot: [this.props.project.archiveTree]
    })
  }

  @autobind
  updateSelectedPageInState(id) {

    const page = pageStore.getPartialById(id)
    let expandedKeys
    let cachedKeys
    let pageType

    // when user closed it and navigates to another page #17194, observe that previous page doesn't
    // reopen it's child pages

    if (page && page.getExpandedKeys) {

      pageType = this.getPageType(page.type)
      cachedKeys = this.state.cachedKeys[pageType]

      // Expand the parent pages
      // This union will prevent duplicates
      const currentProject = projectStore.current
      expandedKeys = union(cachedKeys, page.getExpandedKeys(currentProject))
    }

    this.setNewState([`${id}`], expandedKeys, pageType)
  }

  @autobind
  handleSelect(selectedNodeIds) {
    this.setState({
      selectedKeys: []
    }, () => {
      this.setState({
        selectedKeys: selectedNodeIds
      })
    })
    contextStore.set(null)

    // Current context ids need an update manually
    // tree nodes navigate with <Link> component
    if (selectedNodeIds[0] > 0) {
      contextStore.setCurrentIds({
        pageId: selectedNodeIds[0]
      })
    }
  }

  @autobind
  handleCreateNewPage() {

    let parentId = this.state.selectedKeys.length > 0
      ? parseInt(this.state.selectedKeys[0], 10)
      : this.props.page.current.id


    // Get the information required for a new page
    newPage({
      channel: this.props.project.channel.shortcut
    }).then((result) => {

      // Create a new page and put it as a child of the selected
      pageStore.createChild({
        ...result,
        parentId,
        projectId: this.props.project.id
      })
        .then((res) => {

          const page = res.models[0]

          // Open the newly created page
          this.context.dispatch(
            this.props.commands.EditItemCommand,
            {
              router: this.props.router,
              type: 'page',
              id: page.id
            }
          )
          // then select it and expand the parent page
            .then((ok) => {
              if (ok) {

                let expandedKeys
                parentId = `${parentId}`

                if (!(parentId in this.state.cachedKeys[page.type])) {
                  expandedKeys = this.state.cachedKeys[page.type]
                  expandedKeys.push(parentId)
                }

                this.setNewState([`${page.id}`], expandedKeys, page.type)
              }
            })
        })
    }).catch((err) => {
      // Catch the cancel event from the dialog
      console.log(err)
    })
  }

  @autobind
  handleDragStart({ event, node, nodeKeys }) {

    const page = pageStore.getPartialById(node.props.eventKey)

    // without permission to create a page, sorting is disallowed
    // also for the startpages of a project or the root of the page trees
    if (!this.permission
        || page.parentId === PAGE_ID_STARTPAGE
        || page.id < 0) {
      event.dataTransfer.effectAllowed = 'none'
      return
    }

    event.dataTransfer.effectAllowed = 'move'

    // Set the dragging node
    this.dragNode = node.props.root.refs.tree

    this.setState({
      externalDragNode: node,
      externalDragNodeKeys: nodeKeys
    })
  }

  @autobind
  handleDrop(info) {

    let parentId = info.node.props.eventKey
    const nodeId = info.dragNode.props.eventKey

    if (parentId * 1 < PAGE_ID_ARCHIVE_TREE_ROOT) {
      const id = deepGet(this, 'props.project.pages.0.id')
      parentId = id || -2
    }

    // for valid drop locations
    const parentPage = pageStore.getPartialById(parentId)
    const parentPageType = this.getPageType(parentPage.type)

    // Keep the pages expanded after drop
    const page = pageStore.getPartialById(nodeId)
    const pageType = this.getPageType(page.type)
    const expandedKeys = page.getExpandedKeys()

    // drag and drop is not supported in Archived pages
    if (pageType >= PAGE_TYPE_ARCHIVE_PAGE
      || parentPageType >= PAGE_TYPE_ARCHIVE_PAGE) {
      return
    }

    pageStore.actionAddChild(
      parentId,
      nodeId,
      info.dropToGap
        ? info.dropPosition
        : 0
    )

    // This union will prevent duplicates
    const consKeys = union(this.state.cachedKeys[pageType], expandedKeys)
    this.setNewState(undefined, consKeys, pageType)
  }

  @autobind
  handleDragOver({ event, node }) {
    const maxNavigationLevel = this.props.project.maxNavigationLevel
    const page = pageStore.getPartialById(node.props.eventKey)

    if (maxNavigationLevel && maxNavigationLevel < page.depth) {
      event.dataTransfer.dropEffect = 'none'
    }
  }

  @autobind
  handleExpand(expandedKeys, type) {

    // called from the components,
    // needed to maintain the expanded state of all trees together
    this.setNewState(undefined, expandedKeys, type)
  }

  @autobind
  handleChildUnmount(expandedKeys, type) {
    this.props.project.expandedPageKeys[type] = expandedKeys
  }

  /**
   * Setting the state for expandedKeys and selectedKeys.
   * for each tree component.
   * @param {Array} selectedKeys - The keys of the selected tree nodes.
   * @param {Array} expandedKeys - The keys of tree nodes to expand
   * @param {Number} type - The number of the page type.
   */
  setNewState(selectedKeys, expandedKeys, type) {
    const cachedKeys = {
      ...this.state.cachedKeys,
      [type]: expandedKeys || this.state.cachedKeys[type]
    }
    this.setState({
      selectedKeys: selectedKeys || this.state.selectedKeys,
      cachedKeys
    })
  }

  getPageType(type) {
    return type === PAGE_TYPE_ARCHIVE_PAGE || type === PAGE_TYPE_ARCHIVE_SPECIAL_PAGE
      ? PAGE_TYPE_TREE_ROOT
      : type
  }

  render() {

    if (this.ps) {
      this.ps.update()
    }

    if (!this.state.pageTreeRoot) {
      return <FormattedMessage id="page.waiting-for-project" />
    }
    if (!this.ps) {
      // ~next tick so that the dom is set
      setTimeout(() => {
        this.ps = new PerfectScrollbar('#navigation-container', {})
      }, 0)
    }

    // Equals true until it is allowed to create new pages
    let isArchive = true
    let isMaxDepthReached = false
    // In case that the page is not fully loaded
    if (pageStore.hasCurrent) {

      const selectedNodeId = parseInt(this.state.selectedKeys[0], 10)

      const maxNavigationLevel = this.props.project.maxNavigationLevel
      isMaxDepthReached = maxNavigationLevel && maxNavigationLevel <= pageStore.current.depth

      // If the page is in the archive
      isArchive = pageStore.current.type === PAGE_TYPE_ARCHIVE_PAGE
        || pageStore.current.type === PAGE_TYPE_ARCHIVE_SPECIAL_PAGE
        // When the id of the selected page root is smaller than the one from specialPages,
        // then it is a archive root
        || selectedNodeId < SPECIAL_PAGE_ROOT_ID

      // Covering a special case with archive page as current and special page root selected
      if (selectedNodeId === SPECIAL_PAGE_ROOT_ID) {
        isArchive = false
      }
    }

    return (<div
      className={
        classNames('grid-block vertical', this.props.className)
      }
      style={{
        height: '100%'
      }}
    >
      <section>
        {this.permission
          ?        <GenevaButton
            className={classNames(
              testClass('new-page-button'),
              'small button')}
            onClick={this.handleCreateNewPage}
            disabled={isArchive || isMaxDepthReached}
          >
            <FormattedMessage id="page.new-page" />
          </GenevaButton>
          : null}
      </section>

      <header>
        <h4>
          <FormattedMessage id="project.navigation" />
        </h4>
      </header>

      <div id="navigation-container">
        <Tree
          project={this.props.project}
          page={this.props.page}
          expandedKeys={this.state.cachedKeys[PAGE_TYPE_STARTPAGE]}
          selectedKeys={this.state.selectedKeys}
          autoExpandParent={this.state.autoExpandParent}
          treeChildren={this.state.pageTreeRoot}
          draggable
          onExpand={this.handleExpand}
          onDragStart={this.handleDragStart}
          onDragEnter={this.handleDragEnter}
          onDragOver={this.handleDragOver}
          onDrop={this.handleDrop}
          onSelect={this.handleSelect}
          onUnmount={this.handleChildUnmount}
          externalDragMode={{
            externalDragNode: this.state.externalDragNode,
            externalDragNodeKeys: this.state.externalDragNodeKeys
          }}
        />

        <Tree
          project={this.props.project}
          page={this.props.page}
          expandedKeys={this.state.cachedKeys[PAGE_TYPE_SPECIAL_PAGE]}
          selectedKeys={this.state.selectedKeys}
          autoExpandParent={this.state.autoExpandParent}
          treeChildren={this.state.specialPageTreeRoot}
          draggable
          onExpand={this.handleExpand}
          onDragStart={this.handleDragStart}
          onDragEnter={this.handleDragEnter}
          onDragOver={this.handleDragOver}
          onDrop={this.handleDrop}
          onSelect={this.handleSelect}
          onUnmount={this.handleChildUnmount}
          externalDragMode={{
            externalDragNode: this.state.externalDragNode,
            externalDragNodeKeys: this.state.externalDragNodeKeys
          }}
        />

        <Tree
          project={this.props.project}
          page={this.props.page}
          expandedKeys={this.state.cachedKeys[PAGE_TYPE_TREE_ROOT]}
          selectedKeys={this.state.selectedKeys}
          autoExpandParent={this.state.autoExpandParent}
          treeChildren={this.state.archiveTreeRoot}
          draggable
          onExpand={this.handleExpand}
          onDragStart={this.handleDragStart}
          onDragEnter={this.handleDragEnter}
          onDragOver={this.handleDragOver}
          onDrop={this.handleDrop}
          onSelect={this.handleSelect}
          onUnmount={this.handleChildUnmount}
          externalDragMode={{
            externalDragNode: this.state.externalDragNode,
            externalDragNodeKeys: this.state.externalDragNodeKeys
          }}
        />

      </div>

    </div>)

  }

}
const IntlPageTreeContainer = commandConnector({
  EditItemCommand
})(
  withRouter(PageTreeContainer)
)
export default IntlPageTreeContainer
