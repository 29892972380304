import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { confirmable } from 'react-confirm'
import { autobind } from 'core-decorators'
import { formatMessage, FormattedMessage } from '../../../translations'
import GenevaButton from '../../../ui/components/GenevaButton'
import { Dialog } from '../../../shared/components/Dialog'
import InternalLinkList from '../../../shared/components/InternalLinkList'
import EditItemCommand from '../../../shared/commands/EditItemCommand.js'

import { projectStore } from '../../../project/reducers'
import { contextStore } from '../../../context/reducers'
import MissingAccessDialog from '../../../shared/components/Dialog/MissingAccessDialog'


const css = /* typeof window === 'undefined' ? {} : */require('../../styles.scss')

@confirmable
export default class InternalLink extends Component {

  static propTypes = {
    show: PropTypes.bool,            // indicates if the dialog is shown or not.
    proceed: PropTypes.func,         // call to close the dialog with promise resolved.
    cancel: PropTypes.func,          // call to close the dialog with promise rejected.
    dismiss: PropTypes.func,         // call to only close the dialog.
    confirmation: PropTypes.string,  // arguments of your confirm function
    options: PropTypes.object        // arguments of your confirm function
  }

  constructor(props) {
    super(props)
    this.state = {
      showMissingAccessDialog: false
    }
  }

  @autobind
  handleOpenArticle(article) {
    const page = article.pages[0]
    const partialProject = projectStore.getPartialById(page.projectId)

    if (!partialProject) {
      this.setState({
        showMissingAccessDialog: true
      })
      return true
    }

    new EditItemCommand({
      router: this.props.options.router,
      type: 'article',
      id: article.id,
      projectId: page.projectId,
      pageId: page.id,
      store: {
        project: projectStore,
        context: contextStore
      }
    }).exec()

    return this.props.dismiss()
  }

  @autobind
  handleHideDialog() {
    this.setState({
      showMissingAccessDialog: false
    })
  }

  @autobind
  renderMissingAccessDialog() {
    return (
      <MissingAccessDialog
        handleHideDialog={this.handleHideDialog}
      >

      </MissingAccessDialog>
    )
  }

  @autobind
  renderFooter() {
    const {
      options: { closeButtonId, confirmButtonId },
    } = this.props

    return (<div className="grid-content text-right">
      {closeButtonId
        ? <GenevaButton type="button"
          className="small button"
          onClick={this.props.dismiss}
        >
          <FormattedMessage id={closeButtonId} />
        </GenevaButton>
        : null}

      {confirmButtonId
        ? <GenevaButton type="button"
          className="small button primary"
          onClick={this.props.proceed}
          id="new-article-submit"
        >
          <FormattedMessage id={confirmButtonId} />
        </GenevaButton>
        : null}
    </div>)
  }

  @autobind
  renderInternalLinkDialog() {
    const {
      options: { headerTextId, pageId },
    } = this.props

    return (
      <Dialog
        className={css.pageInternalLink}
        isOpen={this.props.show}
        title={formatMessage({ id: 'page-internal-link-dialog.title' })}
        onCancel={this.props.dismiss}
        onCommit={this.props.proceed}
        renderFooter={this.renderFooter}
      >
        <div className="form-main">
          <div>
            <FormattedMessage id={headerTextId} />
          </div>

          <InternalLinkList
            pageId={pageId}
            handleOpenArticle={this.handleOpenArticle}
          />
        </div>
      </Dialog>
    )
  }

  render() {
    return (
      <React.Fragment>
        {
          this.state.showMissingAccessDialog
            ? this.renderMissingAccessDialog()
            : this.renderInternalLinkDialog()
        }
      </React.Fragment>
    )


  }
}
