import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { autorun } from 'mobx'
import { autobind } from 'core-decorators'
import classNames from 'classnames'
import PerfectScrollbar from 'perfect-scrollbar'

import { formatMessage, FormattedMessage } from '../../translations'
import { convertDateToUserLocal } from '../../shared/DateConverter'
import ContentLoadingBox from '../../shared/components/ContentLoadingBox'

import { store as contextStore } from '../../context'

const css = /* typeof window === 'undefined' ? {} : */ require('../styles.scss')

@observer
export default class TranslateBar extends Component {

  static propTypes = {
    articleStore: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)

    const current = props.viewModels.currentArticle.data.translateArticle

    this.state = {
      isLoading: false,
      active: {},
      activeArticle: null,
      activeVersionNumber: current.versionNumber + 1,
      originArticle: current,
      currentArticle: current,
    }

    this.ps = null
  }

  componentWillMount() {
    const article = this.state.originArticle
    // Load the source article when it is a copied one
    if (article && article.sourceId) {
      this.handleSourceLanguageChange({
        target: {
          value: this.state.originArticle.sourceId
        }
      })
    }
  }

  componentDidMount() {
    this.handler = autorun(() => this.updateContext(contextStore.target))

    this.ps = new PerfectScrollbar('#translateBar-container', {
      suppressScrollX: true
    })
  }

  componentWillUnmount() {
    if (this.handler) {
      this.handler()
    }
  }

  updateContext(contextTarget) {
    this.setState({
      active: contextTarget && contextTarget.pid
        ? `ph${contextTarget.pid}`
        : null
    })
  }

  @autobind
  handleSourceLanguageChange({ target }) {

    const newId = target.value * 1
    const newArticle = this.props.articleStore.getById(newId)

    if (!newArticle && this.props.articleStore.canLoad()) {

      this.setState({
        isLoading: true
      })

      this.props.articleStore.load(newId)
        .then((model) => {
          this.setState({
            isLoading: false,
            activeArticle: model,
            activeVersionNumber: model.versionNumber + 1,
            originArticle: model
          })
        })
    }
    else {
      this.setState({
        activeArticle: newArticle,
        activeVersionNumber: newArticle.versionNumber + 1,
        originArticle: newArticle
      })
    }
  }

  @autobind
  handleSourceVersionChange({ target }) {

    const newId = target.value * 1

    // The highest versionNumber is the originArticle
    // therefore set it as active and skip the rest
    if (newId === this.state.originArticle.versionNumber + 1) {
      this.setState({
        activeArticle: this.state.originArticle,
        activeVersionNumber: newId
      })

      return
    }

    // If a version is loaded and set as activeArticle
    // then there is no versionStore and we need to load from the related article
    const versionStore = this.state.activeArticle && this.state.activeArticle.versionStore
      ? this.state.activeArticle.versionStore
      : this.state.originArticle.versionStore

    this.setState({
      isLoading: true
    })

    versionStore.load(newId)
      .then((model) => {
        this.setState({
          isLoading: false,
          activeArticle: model,
          activeVersionNumber: model.id
        })
      })
  }

  renderLoader() {
    return (<div className="content-loader-background">
      <ContentLoadingBox className="content-loader"
        message={{
          id: 'status.version.loading-started',
        }} />
    </div>)
  }

  renderSourceSelection(article) {

    const languages = this.state.currentArticle.languages

    // If a version is loaded and set as activeArticle
    // then there is no versionStore and we need to load from the related article
    const versionStore = article.isVersion
      ? this.state.originArticle.versionStore
      : article.versionStore

    const versions = versionStore.collection

    // Skip the first version. Fake version
    const versionList = versions
      .filter(version => version.versionNumber !== 0)

    // Add the article itself to the list
    versionList.push({ ...article, versionNumber: article.versionNumber + 1 })

    return (<div className="source-selection">
      <div className="grid-block">
        <label htmlFor="lang-source">
          <FormattedMessage id="article.translate.lang-source" />
        </label>
        <select
          className="lang-source"
          id="lang-source"
          onChange={this.handleSourceLanguageChange}
          value={article.isVersion ? article.articleId : article.id}
        >
          {languages
            ? languages.map((lang) => {
              return (<option
                key={lang.id}
                value={lang.id}
              >
                {lang.createdIso}
              </option>)
            })
            : null}
        </select>

      </div>
      <div className="grid-block">

        <label htmlFor="version-source">
          <FormattedMessage id="article.translate.version-source" />
        </label>
        <select
          className="version-source"
          id="version-source"
          onChange={this.handleSourceVersionChange}
          value={this.state.activeVersionNumber}
        >
          {versionList.map((version) => {
            const number = version.isVersion ? version.id : version.versionNumber
            return (<option
              key={number}
              value={number}
            >
              {number}&nbsp;
              {formatMessage({ id: 'article.translate.version-of' })}&nbsp;
              {convertDateToUserLocal(version.updatedAt, { barDisplay: true })}
            </option>)
          })}
        </select>

      </div>
    </div>)
  }

  renderPlaceholderContent(entry) {
    return (<div
      className={classNames(
        'placeholder-item',
        this.state.active === entry.name ? 'active' : null
      )}
      key={entry.name}
      dangerouslySetInnerHTML={{
        __html: entry.text
      }}
    />)
  }

  renderSourceContent(article) {
    const channel = article.channelShortcut
    let phA = article.phAccessor
      ? article.phAccessor.asJSON[channel]
      : article.placeholder[channel]
    const content = {}

    // phA always needs to be an object
    if (!phA) {
      phA = {}
    }

    // Copy the content of the placeholders
    // into a simple object without channel and language dependencies
    Object.keys(phA).forEach((entry) => {

      // Only text can be translated
      if (phA[entry].type === 'text') {

        const index = entry.replace('ph', '')

        content[index] = {
          text: article.getDataInPlaceholder(entry),
          name: entry
        }
      }
    })

    return (<div className="grid-content placeholder-list">
      {this.renderPlaceholderContent({
        name: 'articleName',
        text: this.state.originArticle.name
      })}

      <hr />

      {Object.keys(content).map((entry) => {
        return this.renderPlaceholderContent(content[entry])
      })}

    </div>)
  }

  render() {
    let article = this.state.activeArticle

    // Wait until an article is active
    if (!article && this.state.originArticle.sourceId && !this.state.isLoading) {
      return this.renderLoader()
    }
    // use the current one if not copied (no sourceId)
    if (!article && !this.state.originArticle.sourceId && !this.state.isLoading) {
      article = this.state.originArticle
    }

    return (
      <div
        id="translateBar-container"
        className={classNames('grid-block vertical', css.translationTab)}
      >
        {!this.state.isLoading
          ? this.renderSourceSelection(article)
          : null}
        {this.state.isLoading
          ? this.renderLoader()
          : this.renderSourceContent(article)}
      </div>
    )
  }
}
