import * as PropTypes from 'prop-types'
import React from 'react'

const IonIcon = (props) => {
  const style = {}
  if (props.size) {
    style.fontSize = `${props.size}px`
  }

  if (props.rotate) {
    style.transform = `rotate(${props.rotate}deg)`
  }

  const additionalClassName
    = props.className && props.className.trim().length
      ? ` ${props.className}`
      : ''

  return (
    <i
      title={props.title}
      className={`ion ion-${props.name}${additionalClassName}`}
      aria-hidden="true"
      style={style}
    />
  )
}

IonIcon.defaultProps = {
  name: 'ionic',
  size: null,
}

IonIcon.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.number,
  rotate: PropTypes.number,
  title: PropTypes.string,
}

export { IonIcon as default }
