exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".src-shared-components-Dropdown-styles__dropdownContainer___2jkW6{overflow:visible;position:relative}.src-shared-components-Dropdown-styles__dropdownContainer___2jkW6 .geneva-button.active .ion-ios-arrow-down{transform:rotateX(180deg)}.src-shared-components-Dropdown-styles__dropdownContainer___2jkW6 .dropdown-ion-arrow .ion-ios-arrow-down{font-weight:bold;padding-left:7px}\n", "", {"version":3,"sources":["/builds/GENEVA/wm/frontend/src/shared/components/Dropdown/styles.scss"],"names":[],"mappings":"AAAA,kEACE,iBACA,iBAAkB,CAFpB,4GASQ,yBAA0B,CATlC,0GAeQ,iBACA,gBAAiB,CAClB","file":"styles.scss","sourcesContent":[".dropdownContainer {\n  overflow: visible;\n  position: relative;\n  // This seems to be unnecessary\n  // z-index: 1;\n\n  :global {\n    .geneva-button.active {\n      .ion-ios-arrow-down  {\n        transform: rotateX(180deg);\n      }\n    }\n    .dropdown-ion-arrow {\n\n      .ion-ios-arrow-down {\n        font-weight: bold;\n        padding-left: 7px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"dropdownContainer": "src-shared-components-Dropdown-styles__dropdownContainer___2jkW6"
};