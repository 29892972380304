import * as PropTypes from 'prop-types'
import React from 'react'

import DistinctModelObserver from '../../shared/view-models/DistinctModelObserver.ts'
import createParentPropsInjector from '../../shared/utils/create-parent-props-injector'

import viewModelConnector from '../../shared/lib/view-model'

import CenshareSidebar from '../components/CenshareSidebar'

export default function connectCenshareSidebar(parentProps) {
  const injectParentProps = createParentPropsInjector(parentProps)

  // Connector function
  function CenshareSidebarConnector({ ...rest }) {
    return <CenshareSidebar {...rest} />
  }

  // Component props
  CenshareSidebarConnector.propTypes = {
    pageStore: PropTypes.object.isRequired,
    templateStore: PropTypes.object.isRequired,
    articleStore: PropTypes.object.isRequired,
    projectStore: PropTypes.object.isRequired,
  }

  return viewModelConnector([
    {
      class: DistinctModelObserver,
      data: [
        {
          type: 'page',
          id: DistinctModelObserver.fromProps('pageStore.current.id'),
          as: 'page',
          component: CenshareSidebar.name,
        },
        {
          type: 'template',
          id: DistinctModelObserver.fromProps('templateStore.current.id'),
          as: 'template',
          component: CenshareSidebar.name,
        },
        {
          type: 'article',
          id: DistinctModelObserver.fromProps('articleStore.current.id'),
          as: 'article',
          component: CenshareSidebar.name,
        },
      ],
      as: 'models',
    },
  ])(injectParentProps(CenshareSidebarConnector))
}
