import Command from '../../shared/command'
import { Store } from '../../shared/store'

export default class PropagateImageCommand extends Command {

  constructor(imageStore: Store, id: String, data: Object, opts: Object) {
    super(imageStore, data, id, opts)
    this.imageStore = imageStore
    this.id = id
    this.opts = opts
    this.data = this.parse(data)
  }

  validate(imageStore: Store, data: Object) {
    if (!imageStore) {
      this.throwError('expects `imageStore` argument to be present')
    }
    if (!(data && data.url)) {
      this.throwError('expects `data` argument to be present and contain a url')
    }
  }

  parse(data) {

    let languages = []

    if (this.imageStore.languageReferences) {
      languages = this.imageStore.languageReferences
    }
    else {
      languages[0] = data.article.createdIso
    }

    return {
      articleId: data.article.id,
      channelShortcut: data.article.channel,
      languageReferences: languages,
      placeholderId: data.pid,
    }
  }

  exec() {
    return this.imageStore.save(this.id, this.data, this.opts)
  }

}
