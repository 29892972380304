import PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'
import { FormattedMessage } from '../../translations'

const css = /* typeof window === 'undefined' ? {} : */require('../styles.scss')

const NoPageSelectedDisplayer = ({ page: { hasCurrent } }) => {

  if (hasCurrent) {
    return null
  }

  return (<div className={classNames(css.pagePreview)}>
    <div className="grid-block text-center">
      <FormattedMessage id="page.select-page-first" />
    </div>
  </div>)
}

NoPageSelectedDisplayer.propTypes = {
  page: PropTypes.shape({
    hasCurrent: PropTypes.bool.isRequired
  })
}


export { NoPageSelectedDisplayer as default }
