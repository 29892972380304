import PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'
import ContentLoadingBox from '../../shared/components/ContentLoadingBox'
import { i18n } from '../../shared/utils'

const css = /* typeof window === 'undefined' ? {} : */require('../styles.scss')

const LoadingScreenDisplayer = ({
  page: { hasCurrent, current }, template
}) => {

  if (!hasCurrent
    || (current.complete
    && current.contentTemplatesLoaded
    && (template || current.needsTemplate))) {
    return null
  }

  const title = i18n(current, 'name')

  return (<div
    className={
      classNames(css.pagePreview, 'grid-block', 'align-center')
    }
  >
    <div className="grid-block vertical shrink noscroll">
      <ContentLoadingBox
        spinner
        message={{
          id: title
            ? 'page.loading-name'
            : 'page.loading',
          values: {
            title
          }
        }}
      />
    </div>
  </div>)

}

LoadingScreenDisplayer.propTypes = {
  page: PropTypes.shape({
    hasCurrent: PropTypes.bool.isRequired,
    current: PropTypes.object
  }),
  template: PropTypes.func
}

export { LoadingScreenDisplayer as default }
