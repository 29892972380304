import { IViewModelPool, IViewModel, ViewModelSpec } from './view-model.d'

export default
class ViewModelPool
implements IViewModelPool {

  private store: any
  private pool: {[key: string]: IViewModel}

  constructor({store}) {
    this.store = store
    this.pool = {}
  }

  public get(spec: ViewModelSpec): IViewModel {
    const name = spec.class.name

    if (name in this.pool) {
      return this.pool[name]
    }

    const ValidationModelClass = spec.class
    const model = new ValidationModelClass({store: this.store})
    this.pool[name] = model

    return model
  }

}
