import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import { autobind } from 'core-decorators'
import { observer } from 'mobx-react'
import { store as contextStore } from '../../../context'

import {
  getEventHandlerFromType,
  delegateEventsFromProps,
} from '../../../shared/utils/events'
import { filter } from '../../../shared/obj'
import getTemplateConnectionData from '../../../shared/template/getTemplateConnectionData'
import wrapInErrorHandler from '../../../shared/template/wrapInErrorHandler'

import TemplatePreview from './Components/TemplatePreview'

export default function connectTemplateToEnv(Template, parentProps) {
  wrapInErrorHandler(Template, parentProps)

  @observer
  class PMEnvConnectedTemplate extends Component {
    static propTypes = {
      placeholder: PropTypes.object.isRequired,
      env: PropTypes.object.isRequired,
      onFocus: PropTypes.func,
      item: PropTypes.shape({
        type: PropTypes.string,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    };

    @autobind
    handleEvent({ type }) {
      const eventName = getEventHandlerFromType(type)

      // special event case for article sorting
      // click events would cause onBlur and deselect of an article
      // since dragevents need click events preventDefault would not work in this case
      // for more information see page/components/ToolsPanel.js
      if (eventName === 'onBlur' && contextStore.preventBlur) {
        contextStore.preventBlur = false
      }
      else if (this.props[eventName]) {
        this.props[eventName]({
          target: {
            type: this.props.item.type,
            id: this.props.item.id,
          },
        })
      }
    }

    renderEmptyItem() {
      const { template } = parentProps

      return (
        <TemplatePreview shortcut={template.shortcut} vendor={template.vendor}>
          {/*
        <FormattedMessage
          id="template.empty"
          values={{
            name: this.props.placeholder.article.name
          }}
        />
      */}
        </TemplatePreview>
      )
    }

    renderTemplate() {
      return (
        <Template
          {...getTemplateConnectionData(
            'pm',
            'article',
            parentProps,
            this.props
          )}
        />
      )
    }

    render() {
      const divProps = filter(this.props, ['className', 'tabIndex', 'title'])

      const eventHandlers = delegateEventsFromProps(
        this.props,
        this.handleEvent
      )

      return (
        <div
          ref={ref => (this.templateRef = ref)}
          {...divProps}
          {...eventHandlers}
        >
          {this.props.placeholder && this.props.placeholder.length
            ? this.renderTemplate()
            : this.renderEmptyItem()}
        </div>
      )
    }
  }

  return PMEnvConnectedTemplate
}
