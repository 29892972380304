import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { autobind } from 'core-decorators'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import { FormattedMessage } from '../../translations'

import { store as widgetStore } from '..'
import GenevaDropdownOpener from '../../ui/components/GenevaDropdownOpener'

import Dropdown, {
  DropdownContainer, Entry, Separator
} from '../../shared/components/Dropdown'
import { DIV_ATTRS, filterElemAttrs } from '../../shared/react-helpers'
import connectPMItemToolsToCommands from '../../shared/connectors/connectPMItemToolsToCommands'
import { ignore } from '../../shared/obj'
import { hasPermissions } from '../../shared/utils/user-rights'

// const ObservedEntry = observer((props) => {
//
//   const { entry, id, onClick } = props
//
//   return (<Entry
//     {...entry.state}
//     identifier={id}
//     onClick={onClick}
//   >
//     {entry.name}
//   </Entry>)
//
// })

@observer
class PMWidgetToolbar extends Component {

  static propTypes = {
    className: PropTypes.string,
    ui: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    page: PropTypes.object,

    /**
     * from connectPMItemToolsToCommands
     */
    onEditItem: PropTypes.func,

    /**
     * from connectPMItemToolsToCommands
     */
    onRemoveItemFromPage: PropTypes.func,

    /**
     * from connectPMItemToolsToCommands
     */
    onDeleteItem: PropTypes.func,
  }

  constructor(props) {
    super(props)

    this.permissions = hasPermissions(['editArticle', 'deleteArticle'])
  }

  isValidEntry(entry) {

    if (entry === '-') {
      return true
    }

    if (!entry.name) {
      console.warn('widget/PMWidgetToolbar: A custom entry HAS to have a name property of type string')
      return false
    }

    if (!('handleClick' in entry)) {
      // eslint-disable-next-line max-len
      console.warn('widget/PMWidgetToolbar: A custom entry HAS to have an handleClick property of type function')
      return false
    }

    return true
  }

  @autobind
  handleEntryClick({ target }) {
    const entry = this.props.ui.widgetContextTools[target.identifier]
    entry.handleClick()
    if (entry.handleQueryState) {
      entry.handleQueryState(entry.state)
    }
  }

  @autobind
  handleOpenWidget() {

    if (this.props.onEditItem) {
      this.props.onEditItem({
        target: {
          type: 'widget',
        }
      })
    }

  }

  @autobind
  handleDeleteWidget() {

    if (this.props.onDeleteItem) {
      this.props.onDeleteItem({
        target: {
          type: 'widget',
          pageId: this.props.page.current.id,
          itemStore: widgetStore
        }
      })
    }

  }

  @autobind
  handleRemoveWidgetFromPage() {

    if (this.props.onRemoveItemFromPage) {
      this.props.onRemoveItemFromPage({
        target: {
          type: 'widget',
          pageId: this.props.page.current.id
        }
      })
    }

  }

  /**
   * Renders a set of widget-defined tools.
   */
  // renderCustomContextTools() {
  //   const { ui: { widgetContextTools } } = this.props
  //
  //   return (<Entry>
  //
  //     <DropdownOpener>
  //       <FormattedMessage id="widget.options" />
  //     </DropdownOpener>
  //
  //     <Dropdown>
  //
  //       {widgetContextTools.map((entry, id) => (entry === '-'
  //
  //         ? <Separator />
  //
  //         : <ObservedEntry
  //           key={entry.name}
  //           entry={entry}
  //           onClick={this.handleEntryClick}
  //           id={id}
  //         />
  //
  //         ))}
  //
  //     </Dropdown>
  //
  //   </Entry>)
  // }

  render() {
    const {
      // ui: { widgetContextTools },
      disabled
    } = this.props

    // widgetContextTools = (widgetContextTools || []).filter(this.isValidEntry)

    const divProps = ignore(filterElemAttrs(this.props, DIV_ATTRS), [
      'onEditItem',
      'onRemoveItemFromPage',
      'onDeleteItem'
    ])

    return (<div {...divProps}
      className={classNames(this.props.className, 'toolbar', 'v-align')}
    >
      <DropdownContainer>
        <GenevaDropdownOpener
          clickToClose
          disabled={disabled || !this.permissions.length}
          arrow
          caret={false}
        >
          <FormattedMessage id="widget.widget" />
        </GenevaDropdownOpener>
        <Dropdown>

          {this.permissions.editArticle
            ? <Entry
              onClick={this.handleOpenWidget}
            >
              <FormattedMessage id="widget.edit" />...
            </Entry>
            : null}

          {
          /*  {widgetContextTools && widgetContextTools.length
            ? this.renderCustomContextTools()
            : null
          }
        */
          }
          {this.permissions.editArticle
            ? <Separator />
            : null}

          {this.permissions.editArticle
            ? <Entry
              onClick={this.handleRemoveWidgetFromPage}
            >
              <FormattedMessage id="widget.remove-from-page" />
            </Entry>
            : null}

          {this.permissions.deleteArticle
            ? <Entry
              onClick={this.handleDeleteWidget}
            >
              <FormattedMessage id="widget.delete" />
            </Entry>
            : null}

        </Dropdown>
      </DropdownContainer>

    </div>)

  }
}

export default connectPMItemToolsToCommands(PMWidgetToolbar)
