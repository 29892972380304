import getScrollParent from './scroll-parent'
import getScrollOffset from './scroll-offset'

export default function isScrolledInView(el, padding = 0) {
  // double parent because of scrollbar container
  const parent = getScrollParent(el)

  if (!parent) {
    // Bail out if not inside a scrollcontainer
    return null
  }

  const offset = getScrollOffset(el, parent)
  const elHeight = parseInt(getComputedStyle(el).getPropertyValue('height'), 10)
  const viewportStart = parent.scrollTop
  const viewportEnd = viewportStart
    + parseInt(getComputedStyle(parent).getPropertyValue('height'), 10)

  const itemStart = offset - padding
  const itemEnd = offset + elHeight + padding

  return viewportStart < itemStart
    && viewportEnd > itemEnd
}
