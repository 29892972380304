/**
* @overview
* @author Simon Schmidt <simon-schmidt@alarie.de>
* @copyright Simon Schmidt 2016
*/
import { isNumber } from 'lodash'
import { closest } from './dom-helpers'
import validate from '../../../shared/utils/validate-arguments'

const INVISIBLE_CHAR = '\uFEFF'

const signum = (num) => num * Math.sign(num)
function isInt(n){
    return Number(n) === n && n % 1 === 0;
}

function isFloat(n){
    return Number(n) === n && n % 1 !== 0;
}

const isPercentValue = (val) => {
  return (val <= 1 || isFloat(val))
}
const getUnitFromText = (text) => {

  if (isNumber(text)) {
    return isPercentValue(text)
    ? '%'
    : 'px'
  }

  return text.indexOf('%') > -1
  ? '%'
  : 'px'

}

const getUnit = (el, sizeProp = 'width') => {

  validate('getUnit', [
    {name: 'el', instanceof: Node},
    {name: 'sizeProp', oneOf: ['width', 'height']},
  ], [el, sizeProp])

  return !el.style[sizeProp]
  ? '%'
  : getUnitFromText(el.style[sizeProp])

}

const getSizeFromText = (text) => {
  if (getUnitFromText(text) === '%') {
    if (isPercentValue(text)) {
      text *= 100
    }
    return parseFloat(text) / 100
  }
  return parseInt(text)
}

const getSize = (table, el, unit = null, sizeProp = 'width') => {

  const styleProp = el && el.style && el.style[sizeProp] || ''
  const totalWidth = getComputedNumber(table, sizeProp)

  if (!unit) {
    unit = getUnit(table, sizeProp)
  }

  validate('getSize', [
    {name: 'table', instanceof: HTMLTableElement},
    {name: 'el', instanceof: Node},
    {name: 'unit', oneOf: ['%', 'px']},
  ], [table, el, unit])

  if (unit === '%') {
    return styleProp && styleProp.indexOf('%') > -1
    ? parseFloat(styleProp) / 100
    : getComputedNumber(el, sizeProp) / totalWidth
  }

  return styleProp && styleProp.indexOf('px') > -1
  ? parseInt(styleProp)
  : getComputedNumber(el, sizeProp)
}

const makeSize = (size, unit = 'px') => {
  if (isPercentValue(signum(size)) || (signum(size) >= 1 && unit === '%')) {
    unit = '%'
    size = Math.round(parseInt(size * 10000, 10) / 100)
  }
  return `${size}${unit}`
}

const TABLE_APPEARANCE_CLASS_PREFIX = 'table-appearance-'
const resizerAxisDirectionMap = {
  x: 'left',
  y: 'top',
}

const resizerAxisSizeMap = {
  x: 'width',
  y: 'height',
}

function insertAfter(newNode, referenceNode) {
  referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
}

function getSizeByAxis(dir) {
  if (!(dir in resizerAxisSizeMap)) {
    throw new Error(`Table Error: No size for given direction ${dir}`)
  }
  return resizerAxisSizeMap[dir]
}

function getDirectionByAxis(dir) {
  if (!(dir in resizerAxisDirectionMap)) {
    throw new Error(`Table Error: No size for given direction ${dir}`)
  }
  return resizerAxisDirectionMap[dir]
}

function getComputedNumber(el, property) {
  return parseInt(getComputedStyle(el).getPropertyValue(property), 10)
}

function resizeCell(prevCell, nextCell, dist, dir) {
  const prop = getSizeByAxis(dir)

  let prevCellSize
  let nextCellSize
  let table = closest(prevCell, 'table')
  let unit = getUnit(table)

  if (unit === '%') {
    const tableSize = getComputedNumber(table, prop)

    const actualPrevCellSize = getSize(table, prevCell, unit)
    const actualNextCellSize = getSize(table, nextCell, unit)

    prevCellSize = makeSize(actualPrevCellSize + dist, unit)
    nextCellSize = makeSize(actualNextCellSize - dist, unit)
  }
  else {
    prevCellSize = makeSize(getComputedNumber(prevCell, prop) + dist, unit)
    nextCellSize = makeSize(getComputedNumber(nextCell, prop) - dist, unit)
  }

  prevCell.style[prop] = prevCellSize
  nextCell.style[prop] = nextCellSize
}

function resizeTable(table, cells, diff, dir = 'x', opts = {}) {

  // we have to run this even if it's 0 in cases where the layout mode
  // was changed from % to px
  if (!opts.unit && diff === 0) {
    return
  }

  const prop = getSizeByAxis(dir)
  let computedTotalSize = getComputedNumber(table, prop)

  let unit = opts.unit || 'px'
  let newTotalSize = makeSize(computedTotalSize + diff, unit)

  if (
    (opts.unit && opts.unit === '%') ||
    (!opts.unit && isPercentValue(signum(diff)))
  ) {
    unit = '%'
    const parentWidth = getComputedNumber(table.parentNode.parentNode, 'width')
    const percentSize = (computedTotalSize + (computedTotalSize * diff)) / parentWidth
    newTotalSize = makeSize(percentSize, unit)
  }

  Array.prototype.map.call(cells, (cell) => {

    // let cellStyle = cell.style[prop]
    let computedSize = getComputedNumber(cell, prop)
    let ratio = (computedSize / computedTotalSize) * diff
    let newSize = undefined

    if (unit === '%') {

      const cellUnit = getUnit(cell)
      if (cellUnit !== unit) {
        newSize = computedSize / computedTotalSize
      }
      // in the other  case we don't have to do anything!

    }
    else {
      newSize = Math.round(parseInt(computedSize) + ratio)
    }

    return newSize
  })
  // we HAVE to separate this in two steps, because applying the
  // a new cell width value will force re-layout
  .forEach((newSize, cellIndex) =>  {
    if (newSize !== undefined && !isNaN(newSize)) {
      cells[cellIndex].style[prop] = makeSize(newSize, unit)
    }
  })

  table.style[prop] = newTotalSize
}

function getEventHandler(handler, ...args) {
  return (e) => {
    handler.call(null, e, ...args)
  }
}

function getResizerAxis(resizer) {
  let axis = resizer.classList.contains('table-resize-x') && 'x'
  if (!axis) {
    axis = resizer.classList.contains('table-resize-y') && 'y'
  }
  return axis || null
}

function createTag(tag, content, attrs) {
  attrs = attrs || {}
  let className = ''

  if (attrs.className) {
    className = `class="${attrs.className}"`
    delete attrs.className
  }

  const attributes = Object.keys(attrs)
    .map((key) => `${key}="${attrs[key]}"`)
    .join(' ')

  return `<${tag} ${className} ${attributes}>${content}</${tag}>`
}

function createRows(numRows, numCols) {

  let i
  let row = ''
  let rows = ''

  for (i = 0; i < numCols; i += 1) {
    row += createTag('td', '<br />')
  }

  for (i = 0; i < numRows; i += 1) {
    rows += createTag('tr', row)
  }

  return rows

}

function analyzeTable(table) {

  const data = {
    rows: [],
    cols: []
  }
  let children = table.childNodes

  let baseRow = null
  let width

  Array.prototype.every.call(children, (child) => {
    // if thead exist, go by that, otherwise by tbody
    if (child.nodeName === 'THEAD') {
      baseRow = child
      return false
    }
    if (child.nodeName === 'TBODY') {
      baseRow = child
      return false
    }
    return true

  })


  children = baseRow.querySelector('tr')

  if (!children) {
    throw new Error('Broken table')
  }

  children = children.childNodes

  const unit = getUnit(table, 'width')

  data.cols = Array.prototype
  .filter.call(children, child => (child.nodeName === 'TD' || child.nodeName === 'TH'))
  .map((child) => {

    width = getSize(table, child, unit)

    // width = child.style.width
    // if (width) {
    //   width = parseInt(child.style.width, 10)
    //   if (child.style.width.indexOf('%') > -1) {
    //     width = parseFloat(child.style.width) / 100
    //   }
    // }
    // else {
    //   // in case the table has a per-centual width, the
    //   // children have to be evenly spaced. comutedStyle
    //   // won't work bc, width is defined by column content
    //   if (table.style.width.indexOf('%') > -1) {
    //     width = 1 / children.length
    //   }
    //   else {
    //     // get the computed pixel value
    //     width = getComputedNumber(child, 'width')
    //   }
    // }

    return {
      width
    }

  })

  children = table.querySelectorAll('tr')

  Array.prototype.forEach.call(children, () => {
    data.rows.push({
      height: 1
    })
  })

  return data
}

function setResizerPositions(table) {

  const resizersContainer = table.parentNode
    .querySelector('.table-resizers')

  const resizer = resizersContainer
    .querySelectorAll('.table-resize-x')

  const tableData = analyzeTable(table)

  const unit = getUnit(table)

  let left = 0
  let width
  let c

  resizersContainer.style.width = table.style.width
  tableData.cols.forEach(({width}, c) => {
    left += width
    resizer[c].style.left = makeSize(left, unit)
  })

  if (unit === '%' && left !== 1) {
    // in case we have per-centual values, in a second pass make sure the columns don't exceed 100%
    let correctedLeft = 0
    const ratio = 1 / left
    const len = tableData.length - 1
    tableData.cols.forEach(({width}, c) => {

      if (c !== len) {
        correctedLeft += width * ratio
      }
      // in last column, even up to exactly 1
      else {
        correctedLeft = 1 - correctedLeft
      }

      resizer[c].style.left = makeSize(correctedLeft, unit)
    })
  }


}

function getHeaderCellElements(table) {

  let items

  try {
    const start = table.querySelector('td, th')
    items = start.parentNode.querySelectorAll('td, th')
  }
  catch (ex) {
    throw new Error('Table Error: Table not found or table invalid.')
  }

  return items
}

function getIndexOfResizer(el, dir) {
  const resizers = el.parentNode
    .querySelectorAll(`.table-resize-${dir}`)

  let index = 0

  while (resizers[index] !== el && index < resizers.length) {
    index += 1
  }

  if (index > resizers.length) {
    throw new Error('Table Error: Resizer not found!')
  }

  return index
}

function cancel(e) {
  e.preventDefault()
  e.stopPropagation()
}

function onDrop(e, {
  dir, target, start, mouseUpHandler, mouseMoveHandler
}) {
  try {
    let dist = e[dir] - start[dir]
    const table = target
      .parentNode.parentNode.querySelector('table')

    const items = getHeaderCellElements(table)
    const index = getIndexOfResizer(target, dir)

    if (getUnit(table) === '%') {
      dist /= getComputedNumber(table, getSizeByAxis(dir))
    }

    // if there is another resizer, we are somewhere
    // within the table
    if (items[index + 1]) {
      resizeCell(items[index], items[index + 1], dist, dir)
    }
    // otherwise we are resizing the table
    else {
      resizeTable(table, items, dist, dir)
    }

    e.stopPropagation()

    setResizerPositions(table)

    const event = new Event('input', {target: table, bubbles: true})
    table.dispatchEvent(event)
  }
  catch (err) {
    console.error(err)
  }

  document.body.removeEventListener('selectstart', cancel, false)
  document.body.removeEventListener('mouseup', mouseUpHandler, true)
  document.body.removeEventListener('mousemove', mouseMoveHandler, false)
}

function onDragging(e, ctx) {
  e.preventDefault();
  e.stopPropagation();
  const newPos = (ctx.start.pos + e[ctx.dir] - ctx.start[ctx.dir])
  ctx.target.style[getDirectionByAxis(ctx.dir)] = `${newPos}px`
}

function installResizeHandler(e) {

  const { target } = e
  const dir = getResizerAxis(target)

  if (!dir) {
    return
  }

  e.stopPropagation()

  const ctx = {
    start: {
      x: e.x,
      y: e.y,
      pos: getComputedNumber(target, getDirectionByAxis(dir))
    },
    dir,
    target
  }

  ctx.mouseMoveHandler = getEventHandler(onDragging, ctx)
  ctx.mouseUpHandler = getEventHandler(onDrop, ctx)

  document.body.addEventListener(
    'selectstart',
    cancel,
    false
  )

  document.body.addEventListener(
    'mousemove',
    ctx.mouseMoveHandler,
    false
  )

  document.body.addEventListener(
    'mouseup',
    ctx.mouseUpHandler,
    true
  )
}


function onDragStart(e) {
  // only if we are dragging a table resizer do all the logic,
  // otherwise selecting text would also trigger this logic
  if (closest(e.target, '.table-resize-x')) {
    e.stopPropagation();
    e.preventDefault();

    try {
      installResizeHandler(e)
    }
    catch (err) {
      console.error(err)
    }
  }
}


function removeResizingListeners(el) {
  el.removeEventListener('mousedown', onDragStart, false)
}

function revalidateTable(table, dir = 'x') {
  const sizeProp = getSizeByAxis(dir)
  const unit = getUnit(table, sizeProp)
  const header = getHeaderCellElements(table)
  const sizes = Array.prototype.map.call(header, cell => getSize(table, cell, unit))
  let totalSize = sizes.reduce((memo, size) => memo + size, 0)
  let sum = 0
  const len = sizes.length - 1

  if (unit === '%') {
    if (totalSize !== 1) {
      // in case we have per-centual values, in a second pass make sure the columns don't exceed 100%
      const ratio = 1 / totalSize
      totalSize = 1
      sizes.forEach((itemSize, index) => {
        const size = index !== len
        ? makeSize(itemSize * ratio, unit)
        : makeSize(totalSize - sum, unit)
        sum += parseFloat(size) / 100
        header[index].style[sizeProp] = size
      })
    }
  }
  else {
    sizes.forEach((itemSize, index) => {
      const size = index !== len
      ? makeSize(itemSize, unit)
      : makeSize(totalSize - sum, unit)
      sum += parseInt(size)
      header[index].style[sizeProp] = size
    })
  }

}

function colIndexIsIvalid(colIndex, numCols) {
  return colIndex === undefined ||
  colIndex === null ||
  colIndex >= numCols ||
  colIndex < 0
}

const COLUMN_DEFAULT_WIDTH = 20
const STYLE_WIDTH_RULE_REGEXP = /[^-]width\s*:\s*[^;]+;/
/**
 * @param {number} pos 0 means, insert before first cell. All other
 * values mean insert after cell at pos
 */
function insertColumn(table, colIndex = undefined) {

  const header = getHeaderCellElements(table)
  const rows = table.querySelectorAll('tr')
  const br = '<br />'
  const numColumns = header.length
  const unit = getUnit(table, 'width')

  let append = false
  let colIndexToCloneFrom = (colIndex || colIndex === 0)
  ? colIndex
  : numColumns

  // if we have no pos or that pos is the out of range,
  // use the last row
  if (colIndexIsIvalid(colIndex, numColumns)) {
    append = true
    colIndexToCloneFrom = numColumns - 1
  }

  const newWidth = makeSize(
    getSize(table, header[colIndexToCloneFrom], unit) / 2,
    unit
  )

  Array.prototype.forEach.call(rows, (row, rowIndex) => {
    if (!colIndex || colIndex < 0) colIndex = 0

    const cellToInsertAt = row.children[colIndex]
    const cellToClone = row.children[colIndexToCloneFrom]
    const newCell = cellToClone.cloneNode(false)
    newCell.innerHTML = br

    // only apply width to cells in the first row
    if (rowIndex === 0) {
      // set the width of the new column
      newCell.style.width = newWidth
      // AMD the old one that was halfed
      cellToClone.style.width = newWidth
    }
    // if we are not in the first row, remove all width styles
    else {
      const style = newCell.getAttribute('style')
      if (style && STYLE_WIDTH_RULE_REGEXP.test(style)) {
        newCell.setAttribute(
          'style',
          style.replace(STYLE_WIDTH_RULE_REGEXP, '')
        )
      }
    }
    // current desires is for nodes to be inserted after. Below is for inserting before
    // row.insertBefore(newCell, cellToInsertAt || null)
    insertAfter(newCell, cellToInsertAt)
  })

  revalidateTable(table, 'x')
}

function insertRow(table, pos) {
  const header = getHeaderCellElements(table)
  const rows = table.querySelectorAll('tr')
  const row = document.createElement('tr')
  const maxLen = rows.length

  if ((!pos && pos !== 0) || pos > maxLen || pos < 0) {
    pos = maxLen - 1
  }

  row.innerHTML = createRows(1, header.length)

  // current desires is for nodes to be inserted after. Below is for inserting before
  // rows[0].parentNode.insertBefore(row, rows[pos] || null)
  if (!pos || pos < 0) pos = 0
  insertAfter(row, rows[pos])
}

function removeColumn(table, pos) {
  const rows = table.querySelectorAll('tr')

  if (!pos && pos !== 0) {
    return
  }

  const unit = getUnit(table)

  Array.prototype.forEach.call(rows, (row, rowIndex) => {
    const childToRemove = row.children[pos]
    if (rowIndex === 0) {
      const childToRemoveSize = getSize(table, childToRemove, unit)
      let tableSize = getComputedNumber(table, 'width')
      if (unit === '%') {
        tableSize = 1
      }
      Array.prototype.forEach.call(row.children, (child) => {
        if (child != childToRemove) {
          let newSize = getSize(table, child, unit)
          newSize += (newSize / (tableSize - childToRemoveSize)) * childToRemoveSize
          if (unit === 'px') {
            newSize = Math.round(newSize)
          }
          child.style.width = makeSize(newSize, unit)
        }
      })
    }
    row.removeChild(childToRemove)
  })

  revalidateTable(table, 'x')
}

function removeRow(table, pos) {

  const rows = table.querySelectorAll('tr')

  if (!pos && pos !== 0 || pos > rows.length) {
    return
  }

  rows[pos].parentNode.removeChild(rows[pos])

}

function createTableContainer(table = null) {
  const container = document.createElement('div')
  container.classList.add('table-container')
  if (table) {
    container.appendChild(table)
  }
  return container
}

function removeTable(table = null) {
  if (table !== null) {
    const container = table.parentNode
    if (container.className.includes('table-container')) {
      container.remove()
    }
  }
}



class TableHandler {

  static createTable(tableOpts) {

    let width = tableOpts.width || `100%`
    if (tableOpts.width) {
      if (typeof tableOpts.width === 'number') {
        width = `${tableOpts.width}px`
      }
    }

    const row = createRows(tableOpts.rows, tableOpts.cols)
    const body = createTag('tbody', row)
    const table = document.createElement('table')

    table.style.width = width
    table.innerHTML = body

    if (tableOpts.appearance) {
      table.classList.add(TABLE_APPEARANCE_CLASS_PREFIX + tableOpts.appearance)
    }

    return table

  }

  static revalidateTable(table) {
    validate('revalidateTable', [
      {name : 'table', instanceof: HTMLTableElement}
    ], [table])

    revalidateTable(table)
  }

  static getAppearance(table) {

    validate('getAppearance', [
      {name : 'table', instanceof: HTMLTableElement}
    ], [table])

    try {
      return table.className.split(/\s+/g)
      .filter((className) =>
        (className.indexOf(TABLE_APPEARANCE_CLASS_PREFIX) === 0))
      .reduce((memo, className) => {
        memo[className.replace(TABLE_APPEARANCE_CLASS_PREFIX, '')] = true
        return memo
      }, {})
    }
    catch(ex) {
      return null
    }

  }

  static getLayoutMode(table: HTMLTableElement) {

    validate('getLayoutMode', [
      {name : 'table', instanceof: HTMLTableElement}
    ], [table])

    return {[getUnit(table) === 'px' ? 'fixed' : 'responsive'] : true}
  }

  static prepareTableForScribe(
    table: HTMLTableElement,
    needsPTagAtEnd: boolean
  ) {

    validate('prepareTableForScribe', [
      {name : 'table', instanceof: HTMLTableElement},
      {name : 'needsPTagAtEnd', type: 'boolean'}
    ], [table, needsPTagAtEnd])

    const frag = document.createDocumentFragment()
    const container = createTableContainer(table)

    frag.appendChild(container)

    if (needsPTagAtEnd) {
      const nl = document.createElement('p')
      frag.appendChild(nl)
    }

    return frag

  }

  static createResizers(
    table: HTMLTableElement
  ) {

    validate('createResizers', [
      {name : 'table', instanceof: HTMLTableElement}
    ], [table])

    // remove existing resizers
    let resizers = table.parentNode.querySelectorAll('.table-resizers')
    Array.prototype.forEach.call(resizers, (resizer) =>
      resizer.parentNode.removeChild(resizer)
    )

    // create new resizers
    const tableData = analyzeTable(table)
    const colResizers = tableData.cols.map(() => {
      return createTag('div', INVISIBLE_CHAR, {
        className: 'table-resize-x',
        // draggable: 'true',
      })
    })
    .join('')
    resizers = document.createElement('div')
    resizers.classList.add('table-resizers')
    resizers.innerHTML = /* rowResizers + */colResizers

    if (!table.parentNode.classList.contains('table-container')) {
      const container = createTableContainer()
      table.parentNode.insertBefore(container, table)
      container.appendChild(table)
    }

    table.parentNode.insertBefore(resizers, table.nextSibling)

    // let rowResizers = ''
    // for (i = 0; i < tableData.rows.length; i += 1) {
    //     rowResizers += createTag('div', '', {
    //         className: "table-resize-y",
    //     });
    // }

    // set the positons of the resizers
    setResizerPositions(table)

  }

  static addResizingListeners(el) {
    removeResizingListeners(el)
    el.addEventListener('mousedown', onDragStart, false)
    el.classList.add('table-resizable')
  }

  static removeResizingListeners(el) {
    removeResizingListeners(el)
    el.classList.remove('table-resizable')
  }

  /**
   * Resizes a table to the given size in given direction.
   * @static
   */
  static resizeTable(table, size, dir = 'x') {

    validate('resizeTable', [
      {name : 'table', instanceof: HTMLTableElement},
      {name: 'size', type: ['number', 'string']},
      {name: 'dir', oneOf: ['x', 'y']}
    ], [table, size, dir])


    const sizeProp = getSizeByAxis(dir)

    size = size || getComputedNumber(
      table.parentNode.parentNode, sizeProp
    )

    const unit = getUnitFromText(size)
    const cells = getHeaderCellElements(table)

    size = getSizeFromText(size)

    const diff = (unit === '%')
    ? size - getSize(table, table, unit)
    : size - getComputedNumber(table, sizeProp)

    resizeTable(table, cells, diff, dir, {
      // this is to force resizing even if diff === 0
      unit
    })

    TableHandler.createResizers(table)
  }

  static resizeCell(table, cellIndex, size, dir = 'x') {

    validate('resizeCell', [
      {name : 'table', instanceof: HTMLTableElement},
      {name: 'cellIndex', type: ['number']},
      {name: 'size', type: ['number', 'string']},
      {name: 'dir', oneOf: ['x', 'y']}
    ], [table, cellIndex, size, dir])

    const sizeProp = getSizeByAxis(dir)

    size = size || getComputedNumber(
      table.parentNode.parentNode, sizeProp
    )

    if (isPercentValue(size * 1)) {
      size = `${size * 100}%`
    }

    const cells = getHeaderCellElements(table)
    let dist

    if (size.indexOf && size.indexOf('%') > -1) {
      let currSize = cells[cellIndex].style[sizeProp]
      if (currSize && currSize.indexOf('%') > -1) {
        currSize = parseInt(currSize, 10)
      }
      else {
        currSize = (getComputedNumber(cells[cellIndex], sizeProp) / getComputedNumber(table, sizeProp)) * 100
      }

      dist = (parseInt(size, 10) - currSize) / 100
    }
    else {
      size = parseInt(size, 10)
      dist = size - getComputedNumber(cells[cellIndex], sizeProp)
    }

    resizeCell(cells[cellIndex], cells[cellIndex + 1], dist, dir)

    TableHandler.createResizers(table)
  }

  static insertColumn(table, opts = {}) {

    validate('insertColumn', [
      {name : 'table', instanceof: HTMLTableElement},
      {name: 'opts', type: ['object']},
    ], [table, opts])

    const origWidth = getComputedNumber(table, 'width')

    insertColumn(table, opts.pos)

    TableHandler.resizeTable(table, origWidth, 'x')

    // TableHandler.createResizers(table) is called automatically
    // within resizeTable
  }

  static removeColumn(table, opts = {}) {

    validate('removeColumn', [
      {name : 'table', instanceof: HTMLTableElement},
      {name: 'opts', type: ['object']},
    ], [table, opts])

    const origWidth = getComputedNumber(table, 'width')

    removeColumn(table, opts.pos)

    TableHandler.resizeTable(table, origWidth, 'x')

    TableHandler.createResizers(table)

  }

  static insertRow(table, opts = {}) {

    validate('insertRow', [
      {name : 'table', instanceof: HTMLTableElement},
      {name: 'opts', type: ['object']},
    ], [table, opts])

    insertRow(table, opts.pos)

    TableHandler.createResizers(table)

  }

  static removeRow(table, opts = {}) {

    validate('removeRow', [
      {name : 'table', instanceof: HTMLTableElement},
      {name: 'opts', type: ['object']},
    ], [table, opts])

    removeRow(table, opts.pos)

    TableHandler.createResizers(table)

  }

  static removeTable(table) {
    validate('removeTable', [
      {name : 'table', instanceof: HTMLTableElement}
    ], [table])

    removeTable(table)
  }

  static changeAppearance(table, appearances) {

    if (!Array.isArray(appearances)) {
      appearances = [appearances]
    }

    validate('changeAppearance', [
      {name : 'table', instanceof: HTMLTableElement},
      {name: 'appearances', type: ['array']},
    ], [table, appearances])



    const newClassNames = [
      table.className.split(/\s+/g)
        .filter((className) =>
          className.trim() && className.indexOf(TABLE_APPEARANCE_CLASS_PREFIX) === -1
        ),
      ...appearances.map((appearance) => (TABLE_APPEARANCE_CLASS_PREFIX + appearance))
    ]

    table.className = newClassNames.join(' ')

  }

  static changeLayoutMode(table, layoutMode) {

    validate('changeLayoutMode', [
      {name : 'table', instanceof: HTMLTableElement},
      {name: 'layoutMode', type: ['string']},
    ], [table, layoutMode])

    // get the element the table-container is place in
    const parentContainer = table.parentNode.parentNode
    const parentWidh = getComputedNumber(parentContainer, 'width')
    let tableWidth

    if (layoutMode === 'responsive') {
      tableWidth = getSize(table, table, 'px')
      TableHandler.resizeTable(table, makeSize(tableWidth / parentWidh, '%'))
    }
    else {
      tableWidth = getSize(table, table, '%')
      TableHandler.resizeTable(table, makeSize(tableWidth * parentWidh, 'px'))
    }
  }

}

export { TableHandler as default }
