import { observable, computed } from 'mobx'
import Model from '../shared/model'

export default
class Review extends Model {

  @observable id = ''

  @observable users = []

  @observable comments = []

  @observable screenshots = []

  @computed get asJSON() {
    return this.getJSON()
  }

  getJSON() {
    return {
      id: this.id,
      users: this.users,
      comments: this.comments,
      screenshots: this.screenshots
    }
  }

  /*  constructor(...args) {
    super(...args)

    this.id = props.id || ''
    this.users = props.users || []
    this.comments = props.comments || []
    this.screenshots = props.screenshots || []

  }

   updateEntireModel(model) {
    this.id = model.id || this.id
    this.users = model.users || this.users
    this.comments = model.comments || this.comments
    this.screenshots = model.screenshots || this.screenshots
  }
*/
  getUserById(id) {
    return this.users.find(user => user.id === id)
  }
}
