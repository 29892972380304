import { debounce } from 'lodash'

let focusedElement = null
const defaultOptions = {
  shared: false
}

// const buttons = Editor.getDataAttribute(
//   this.props, 'buttons'
// ).split(',')
//
// const allowedButtons = defaultButtons.reduce((memo, { name }) => {
//   memo[name] = buttons.indexOf(name) >= 0
//   return memo
// }, {})
//
// Object.assign(allowedButtons, getDefaultAllowedButtons())
//
// uiStore.setEditorButtons(allowedButtons)


export default function toolbarFactory(toolbarNode, options = defaultOptions) {

  options = Object.freeze(options)

  return function toolbar(scribe, instanceOptions) {

    if (options.shared) {
      scribe.el.addEventListener('focus', () => {
        focusedElement = scribe.el
      })
    }

    function updateUi(commandName, commandValue/*, {type}*/) {

      // Some commands may have subcommands such as
      // 'insertTable.insertTableColumn'. In this case split the command
      // by the '.' and use the last part as new commandName
      // if (commandName.indexOf('.') > -1) {
      //   commandName = commandName.split('.').pop()
      // }

      // Look for a predefined command.
      const command = scribe.getCommand(commandName)

      const selection = new scribe.api.Selection()
      const hasRange = !!selection.range

      let state = {
        active: false,
        disabled: true
      }

      // Some commands habe sub commands (like `insertTable` which has
      // `insertTableRow`). In such cases the command name is made up
      // from the main command name and the sub command name (e.g.
      // `insertTable.insertTableRow`). In such cases, if the main command
      // is available, the subcommands should be too.
      const mainCommand = commandName.split('.')[0]

      if (
        // instanceOptions.allowedButtons.indexOf(commandName) !== -1 ||
        instanceOptions.allowedButtons.indexOf(mainCommand) !== -1
      ) {
        state = {
          // TODO: Do we need to check for the selection?
          active: hasRange && command.queryState(commandValue),
          disabled: !(hasRange && command.queryEnabled())
        }
      }

      instanceOptions.updateButtonUi(commandName, state)
    }

    const buttons = toolbarNode.querySelectorAll('[data-command-name]')

    Array.prototype.forEach.call(buttons, (button) => {

      const { commandName, commandValue } = button.dataset

      const updateButtonUi = updateUi.bind(null, commandName, commandValue)

      button.addEventListener('mousedown', () => {

        if (options.shared && (scribe.el !== focusedElement)) {
          return
        }

        // Look for a predefined command.
        const command = scribe.getCommand(commandName)
        /**
        * Focus will have been taken away from the Scribe instance when
        * clicking on a button (Chrome will return the focus automatically
        * but only if the selection is not collapsed. As per: http://jsbin.com/tupaj/1/edit?html,js,output).
        * It is important that we focus the instance again before executing
        * the command, because it might rely on selection data.
        */
        scribe.el.focus()
        command.execute(commandValue)
        /**
        * Chrome has a bit of magic to re-focus the `contenteditable` when a
        * command is executed.
        * As per: http://jsbin.com/papi/1/edit?html,js,output
        */
      })

      // Keep the state of toolbar buttons in sync with the current selection.
      // Unfortunately, there is no `selectionchange` event.
      scribe.el.addEventListener('keyup', updateButtonUi)
      // mousedown happens before focus so in most cases it does not make sense
      // scribe.el.addEventListener('mousedown', updateButtonUi)
      scribe.el.addEventListener('mouseup', updateButtonUi)

      scribe.el.addEventListener('focus', updateButtonUi)
      // scribe.el.addEventListener('blur', updateButtonUi)

      // We also want to update the UI whenever the content changes. This
      // could be when one of the toolbar buttons is actioned.
      scribe.on('content-changed', updateButtonUi)

    })
  }
}
