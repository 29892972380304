import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { autobind } from 'core-decorators'
import { confirmable, createConfirmation } from 'react-confirm'
import classNames from 'classnames'
import PerfectScrollbar from 'perfect-scrollbar'
import { formatMessage, FormattedMessage } from '../../translations'
import { Dialog } from '../../shared/components/Dialog'

import { deepGet } from '../../shared/obj'
import * as css from '../styles.scss'
import GenevaButton from '../../ui/components/GenevaButton'

const isValidSize = (size) => {
  return ['small', 'medium', 'large', 'xlarge', 'xxlarge'].indexOf(size) !== -1
}

@confirmable
class MetaDialog extends Component {

  static propTypes = {
    show: PropTypes.bool,
    proceed: PropTypes.func,         // call to close the dialog with promise resolved.
    cancel: PropTypes.func,          // call to close the dialog with promise rejected.
    dismiss: PropTypes.func,         // call to only close the dialog.
    options: PropTypes.shape({
      modal: PropTypes.shape({
        metaSettings: PropTypes.shape({
          Form: PropTypes.node.isRequired,
          Footer: PropTypes.node,
        }).isRequired,
        article: PropTypes.object.isRequired,
        project: PropTypes.object,
      })
    })
  }

  constructor(props) {
    super(props)

    const {
      options: { modal: { metaSettings: { spec } } }
    } = props

    let size = deepGet(spec, 'size', 'medium')
    if (!isValidSize(size)) {
      size = 'medium'
    }

    this.ps = null

    this.state = {
      size
    }

  }

  @autobind
  setScrollContainer() {
    this.ps = new PerfectScrollbar('#metaDialog-container', {
      suppressScrollX: true
    })
  }

  @autobind
  handleCancel() {
    return this.props.dismiss()
  }

  @autobind
  handleCommit(e) {

    if (e && e.preventDefault) {
      e.preventDefault()
    }

    let promise = Promise.resolve()

    if (this.beforeCommitHandler) {
      const result = this.beforeCommitHandler()
      if (result && 'then' in result) {
        promise = result
      }
      else {
        if (result !== undefined) {
          promise = Promise.reject()
        }
      }
    }

    return promise.then(() => {
      return this.props.dismiss()
    })

  }

  @autobind
  handleChangeSize({ target: { size } }) {
    if (this.state.size !== size && isValidSize(size)) {
      this.setState({ size })
    }
  }

  @autobind()
  registerBeforeCommitHandler(beforeCommitHandler) {
    this.beforeCommitHandler = beforeCommitHandler
  }

  @autobind
  renderFooter() {
    const {
      options: { article, modal: { metaSettings: { Footer } } }
    } = this.props

    if (Footer) {
      return (<Footer
        article={article}
        onCancel={this.handleCancel}
        onCommit={this.handleCommit}
      />)
    }

    return (<div className="grid-content text-right">

      <GenevaButton type="submit"
        className="small primary button"
        onClick={this.handleCommit}
      >
        <FormattedMessage id="article.meta.ok" />
      </GenevaButton>

    </div>)
  }

  render() {

    const {
      show,
      dismiss,
      options: { modal: { metaSettings: {
        Form,
      }, article, projectStore } },
    } = this.props

    const { size } = this.state

    if (this.ps) {
      this.ps.update()
    }

    return (<Dialog
      className={classNames(
        css.metaSettingsDialog,
        size && css[`${size}MetaSettingsDialog`]
      )}
      overlayClassName={css.metaSettingsDialogOverlay}
      isOpen={show}
      onRequestClose={dismiss}
      renderFooter={this.renderFooter}
      title={formatMessage({
        id: 'article.meta.title'
      })}
      onAfterOpen={this.setScrollContainer}
      focusEl={() => {}}
    >
      <div id="metaDialog-container">
        <Form
          article={article}
          projectStore={projectStore}
          onChangeSize={this.handleChangeSize}
          registerBeforeCommitHandler={this.registerBeforeCommitHandler}
        />
      </div>
    </Dialog>)
  }
}
const metaDialog = createConfirmation(MetaDialog)

export default function (confirmation: string|Object, options: Object = {}) {

  if (typeof confirmation !== 'string') {

    options = {
      intl: confirmation.intl,
      modal: {
        ...confirmation.data,
      }
    }
    confirmation = ''

  }

  return metaDialog({ confirmation, options })

}
