import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import { autobind } from 'core-decorators'
import { observer } from 'mobx-react'

import classNames from 'classnames'
import { observable, action } from 'mobx'
import Ion from '../../icons/ion'
import GenevaButton from '../../../ui/components/GenevaButton'

import * as css from './styles.scss'

/**
 * Renders a div warning message (orange background).  Meant to be seen,
 *  optional action taken, and able to be closed
 * @param (String) className - custom css over the standard
 * @param (String) displayText - req text to show in warning
 * @param (Func) externalComponent - optional action off a button after text
 * @param (String) externalComponentText - button text
 * @param (Bool) show - one way turn on. Component can turn itself off (selfmanaged)
 */

@observer
export default class HeaderWarning extends Component {
  // eslint-disable-next-line react/sort-comp
  @observable showOverride = true;

  static propTypes = {
    className: PropTypes.string,
    displayText: PropTypes.string.isRequired,
    externalComponent: PropTypes.func,
    externalComponentText: PropTypes.string,
    show: PropTypes.bool,
  };

  @autobind
  @action
  handleSetVisibility() {
    this.showOverride = false
  }

  render() {
    return (
      <div
        className={classNames(
          'header-warning',
          'grid-block',
          'align-center',
          this.props.className,
          css.headerWarning,
          { hidden: !(this.showOverride && this.props.show) }
        )}
      >
        {this.props.displayText}
        <GenevaButton
          className={classNames('flat small button')}
          onClick={this.props.externalComponent}
        >
          {this.props.externalComponentText}
        </GenevaButton>

        <a
          className={classNames(css.headerWarningClose, 'close-button')}
          onClick={this.handleSetVisibility}
        >
          <Ion name="ios-close-empty" />
        </a>
      </div>
    )
  }
}
