import { observable } from 'mobx'
import {Command} from '../../../shared/lib/command'
import WidgetContentAction from '../actions/WidgetContentAction'


export default
class InsertContentIntoWidgetCommand
extends Command {

  static storeRequirements = {
    widget: true,
  }

  static validationSpec = {
    item: Command.ensureData.isPresent,
    'item.type': Command.ensureData.oneOf(['article', 'widget']),
    'item[article|widget]': Command.ensureData.either({
      'item.article': {
        'item.article': Command.ensureData.isPresent,
        'item.article.id': Command.ensureData.isNumber
      },
      'item.widget': {
        'item.widget': Command.ensureData.isPresent,
        'item.widget.id': Command.ensureData.isNumber
      }
    }),
    'widgetId': Command.ensureData.isNumber,
    insertionPosition: Command.ensureData.isArray
  }

  constructor(data: object = {}) {
    super(data)
  }

  exec() {

    const {
      store,
      widgetId,
      item,
      insertionPosition,
    } = this.data

    const widget = store.widget.getById(widgetId)

    if (widget && insertionPosition) {

      if (!widget.content) {
        widget.content = observable([])
      }

      new WidgetContentAction(
        widget,
        insertionPosition,
        WidgetContentAction.INSERT
      )
      .execute({
        type: item.type,
        id: item[item.type].id,
        templateShortcut: item.template && item.template.shortcut
      })

      return widget.save()

    }

  }
}
