import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { autobind } from 'core-decorators'
import { autorun } from 'mobx'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import { store as contextStore } from '../../context'
import { store as uiState } from '../../ui'
import LockStateMenubar from '../../shared/components/LockStateMenubar'
import WidgetToolbar from './CMToolbar'
import * as css from '../styles.scss'

const Context = contextStore.constructor

@observer
export default class MenuBar extends Component {

  // eslint-disable-next-line react/sort-comp
  static toolbarDefaults = {
    [Context.CONTEXT_TYPE_ARTICLE]: false,
    [Context.CONTEXT_TYPE_IMAGE]: false,
    [Context.CONTEXT_TYPE_TEXT]: false,
    [Context.CONTEXT_TYPE_LINK]: false,
    template: false
  }

  static tools = [
    'version',
    'comments',
    'research',
    'release',
    'translate'
  ]

  static propTypes = {
    widget: PropTypes.object.isRequired,
    project: PropTypes.object.isRequired,
    page: PropTypes.object.isRequired,
    context: PropTypes.object.isRequired,
    ui: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired
  }

  constructor(props) {

    super(props)

    this.state = {
      context: null
    }

  }

  componentDidMount() {
    this.determinePaneState(this.props.params.tool)
    // APPROVED: whenever the the context changed, notify of context change
    this.handler = autorun(() => this.contextChanged(
      this.props.context.target, this.props.context.subContexts
    ))
  }

  componentWillReceiveProps(nextProps: Object) {
    if (nextProps.params.tool !== this.props.params.tool) {
      this.determinePaneState(nextProps.params.tool)
    }
  }

  componentWillUnmount() {
    if (this.handler) {
      this.handler()
      this.handler = null
    }
  }

  determinePaneState(tool) {
    const widgetToolsPanelOpen = MenuBar.tools.indexOf(tool) >= 0

    uiState.updatePaneState({
      widgetToolsPanelOpen
    })
  }


  contextChanged() {

    const { context } = this.props

    let toolbars = MenuBar.toolbarDefaults

    if (this.widgetIsOpen()) {

      toolbars = {
        ...MenuBar.toolbarDefaults,
        [context.type]: true,
      }

      context.subContexts.forEach((subContext) => {
        toolbars[subContext.type] = true
      })
    }

    this.setState(toolbars)
  }

  widgetIsOpen() {
    return this.props.widget.hasCurrent
      && this.props.widget.current.complete
  }

  @autobind
  handleChangeToolsPanelVisibility() {

    const { paneState } = uiState

    paneState.widgetToolsPanelOpen = !paneState.widgetToolsPanelOpen

  }

  /**
   * @private
   * This should prevent focus loss on the text-editor once
   * the user clicks anywhere in the toolbar area.
   * @param {Event} e - The mouse event.
   */
  @autobind
  handleMouseDownOnMenuBar(e) {
    e.preventDefault()
    return false
  }

  render() {

    const { ui, widget } = this.props

    const isLocked = widget.hasCurrent
      && widget.current.isLockedByOther()

    // NOTE: THE editor toolbar - as of now - may not ever be omitted from
    // rendering, otherwise it get's rerendered the next time causing it to
    // be new DOM elements that are not connected to the event handlers anymore
    return (<div
      className={`menu-bar main-toolbar ${css.widgetMenubar}`}
      onMouseDown={this.handleMouseDownOnMenuBar}
    >
      {isLocked
        ? <LockStateMenubar
          className={`menu-bar main-toolbar ${css.widgetMenubar}`}
          lockable={widget}
        />
        : null}

      <div
        className={classNames('menubar-container grid-block',
          { hidden: isLocked }
        )}
      >
        <div className="grid-block shrink">
          <WidgetToolbar
            className={classNames(
              'pull-left',
              'widget-toolbar',
              { hidden: !widget.hasCurrent/* && !this.state[Context.CONTEXT_TYPE_ARTICLE]*/ }
            )}
            key="widget-toolbar"
            ref="widget-toolbar"
            options={WidgetToolbar.options}
            ui={ui}
            widget={widget}
          />
        </div>

      </div>

    </div>)

  }
}
