import React from 'react'
import Icon from '../../shared/icons'

function ValidationInfo({ isValid }) {

  return <Icon className="text-warn" name="ion-alert-circled" />

}

ValidationInfo.propTypes = {}

export default ValidationInfo
