import Command from '../../shared/command'
import { deepGet } from '../../shared/obj'
/**
 * Triggers an update of the censhare content for a specific placeholder
 * Sets the new data to the model to keep the view sync
 * @extends shared~Command
 */
export default
class UpdateArticlePlaceholderCommand
  extends Command {

  constructor(placeholderData, censhareStore) {

    super(placeholderData)

    this.censhareStore = censhareStore
    this.articleId = placeholderData.article && placeholderData.article.id
    this.data = placeholderData

  }

  /**
   * Validates the data in this command.
   * @param {Object} data - The data of the command
   * @private
   */
  validate() {
    // TODO
  }

  exec() {

    const pid = this.data.pid
    const data = {
      preview: this.data.preview || false,
      placeholdersToUpdate: [
        `ph${pid}`
      ]
    }

    const opts = {
      id: this.articleId,
      action: 'article',
      method: 'put'
    }

    return this.censhareStore.save(null, data, opts)
      .then((res) => {
        const articleData = deepGet(res, 'body.data')

        // If it is a preview, show what would be changed
        if (this.data.preview) {
          return articleData
        }

        if (articleData) {
          this.data.article.set(articleData)
        }

        return articleData
      })
  }
}
