import * as PropTypes from 'prop-types'
import React from 'react'
import { withRouter } from 'react-router'

import createParentPropsInjector from '../../shared/utils/create-parent-props-injector'

import { store as projectStore } from '..'

import LanguageDropdown from '../components/LanguageDropdown'

export default function connectLanguageDropdown(parentProps) {
  const injectParentProps = createParentPropsInjector(parentProps)

  // Connector function
  function LanguageDropdownConnector({ ...rest }) {
    const contentStores = {
      project: projectStore,
    }

    return <LanguageDropdown contentStores={contentStores} {...rest} />
  }

  // Component props
  LanguageDropdown.propTypes = {
    currentProject: PropTypes.object.isRequired,
    onRenderEntry: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    className: PropTypes.string,
  }

  // Inject commands
  return injectParentProps(withRouter(LanguageDropdownConnector))
}
