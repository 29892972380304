import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import { toJS } from 'mobx'
import { observer } from 'mobx-react'
import { autobind, debounce } from 'core-decorators'
import classNames from 'classnames'
import sanitizeHtml from 'sanitize-html'
import {
  VALIDATION_ID_TEXT_EDITOR_INFO,
  VALIDATION_ID_TEXT_EDITOR_ERROR,
  VALIDATION_ID_TEXT_EDITOR_WILDCARD,
} from '../../shared/const'

const css = /* typeof window === 'undefined' ? {} : */ require('./Editor/styles.scss')

export default function connectEditorToContext(
  Editor,
  parentProps,
  contextStore
) {
  if (!contextStore) {
    throw new Error(
      'To connect an Editor with a context, '
        + '`connectEditor` expects to get passed a `contextStore` argument '
        + 'as third parameter.'
    )
  }

  if (!parentProps.articlePlaceholders) {
    // eslint-disable-next-line max-len
    throw new Error(
      "`connectEditorToContext` requires an articlePlaceholders Object in it's parentProps"
    )
  }

  @observer
  class ContextConnectedEditor extends Component {
    static propTypes = {
      pid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,

      maxLen: PropTypes.number,
      spec: PropTypes.object,
      tagName: PropTypes.string,
      showWhenEmpty: PropTypes.bool,
      className: PropTypes.string,

      value: PropTypes.string,

      onChange: PropTypes.func,
      onFocus: PropTypes.func,
      onBlur: PropTypes.func,

      onValid: PropTypes.func,
      onInvalid: PropTypes.func,
    };

    constructor(props) {
      super(props)

      this.state = {
        shouldFocus: false,
      }
    }

    @debounce(500)
    onValid(e) {
      if ('onValid' in this.props && typeof this.props.onValid === 'function') {
        this.props.onValid(e)
      }
    }

    @debounce(500)
    onInvalid(e) {
      if (
        'onInvalid' in this.props
        && typeof this.props.onInvalid === 'function'
      ) {
        this.props.onInvalid(e)
      }
    }

    getMaxLen() {
      if (this.refElem && this.refElem.getDataAttributeValue) {
        return this.refElem.getDataAttributeValue('maxLen')
      }
      const provider
        = 'maxLen' in this.props ? this.props : this.props.spec || {}

      return provider.maxLen || null
    }

    @autobind
    // eslint-disable-next-line
    handleValidate(validation) {
      contextStore.displayContextInfo({
        infos: {
          name: VALIDATION_ID_TEXT_EDITOR_INFO,
          data: validation.infos,
        },
        errors: {
          name: VALIDATION_ID_TEXT_EDITOR_ERROR,
          data: validation.errors,
        },
      })
    }

    @autobind
    handleChange(e) {
      const { target, isTitleField } = e
      const { value, editorState } = target

      this.isValid(target)

      this.updatePlaceholder(value, null, editorState)

      // If the changed placeholder was defined as titleField / articleName
      if (isTitleField) {
        // use a version that has no html inside
        const sanitizedValue = sanitizeHtml(value, {
          allowedTags: [],
          allowedAttributes: {}
        })
        this.updateArticleName(sanitizedValue)
      }

      if (this.props.onChange) {
        this.props.onChange(e)
      }
    }

    @autobind
    handleFocus(e) {
      const { target } = e

      contextStore.createFromElement({
        ...target,
        pid: this.props.pid,
      })

      if (this.props.onFocus) {
        this.props.onFocus(e)
      }
    }

    @autobind
    handleCenshareFocus() {
      contextStore.createFromElement({
        type: 'censhare',
        pid: this.props.pid,
      })
    }

    @autobind
    handleBlur(e) {
      contextStore.set(null)
      contextStore.removeContextInfo(VALIDATION_ID_TEXT_EDITOR_WILDCARD)

      this.setState({
        focus: false,
      })

      if (this.props.onBlur) {
        this.props.onBlur(e)
      }
    }

    @autobind
    handleDrop(e) {
      const eventData = JSON.parse(e.data)
      this.updatePlaceholder(eventData.value, eventData.source)
    }

    isValid(target) {
      const infos = []
      const errors = []
      const validation = {
        isValid: true,
      }
      let validEvent = 'onValid'

      const maxLen = this.getMaxLen()

      if (maxLen) {
        const currRemaining = maxLen * 1 - target.valueText.length

        infos.push({
          name: 'remaining-characters',
          value: currRemaining * 1,
        })

        this.handleValidate({
          infos,
          errors,
        })

        validation.isValid = currRemaining >= 0
        validation.maxLen = currRemaining >= 0
      }

      if (!validation.isValid) {
        validEvent = 'onInvalid'
      }

      this[validEvent](validation)
    }

    focus() {
      if (this.refElem && this.refElem.focus) {
        this.refElem.focus()
      }
    }

    updatePlaceholder(value, source, editorState) {
      const key = 'value'
      const lang = parentProps.articlePlaceholders.defaultLang || 'de-DE'

      const result = parentProps.articlePlaceholders.set(
        this.props.pid,
        key,
        {
          type: 'text',
          source,
          value,
          editorState,
        },
        lang
      )
      if (result) {
        parentProps.articlePlaceholders.article.saveWithDebounce({ noBackendSet: true })
      }
    }

    // Sets the article name to the value of the changed placeholder,
    // if the user did not set the name by hand previously
    updateArticleName(value) {
      if (
        !parentProps.articlePlaceholders.article.meta.userSetName
        && value
        && value.length > 1
      ) {
        parentProps.articlePlaceholders.article.set({ name: value })
        parentProps.articlePlaceholders.article.saveWithDebounce({ noBackendSet: true })
      }
    }

    checkIsEditable() {
      return (
        parentProps.env.CM && parentProps.articlePlaceholders.article.isEditable
      )
    }

    checkIsCenshare() {
      const source = parentProps.articlePlaceholders.getSource(this.props.pid)
      return parentProps.env.CM && source && source.name === 'censhare'
    }

    render() {
      const env = toJS(parentProps.env)

      const value
        = parentProps.articlePlaceholders.get(this.props.pid) || this.props.value

      const TagName = this.props.tagName || 'div'

      let returnValue = null

      if (!this.checkIsEditable()) {
        if (value || this.props.showWhenEmpty) {
          returnValue = (
            <TagName
              className={this.props.className}
              dangerouslySetInnerHTML={{
                __html: value,
              }}
              ref={ref => (this.refElem = ref)}
            />
          )
        }
      }
      else if (this.checkIsCenshare()) {
        if (value) {
          returnValue = (
            <TagName
              className={classNames(this.props.className, css.editor)}
              tabIndex={this.props.pid}
              dangerouslySetInnerHTML={{
                __html: value,
              }}
              onClick={this.handleCenshareFocus}
              ref={ref => (this.refElem = ref)}
            />
          )
        }
      }
      else {
        const editorProps = this.props
        const showWhenEmpty = env.CM ? true : this.props.showWhenEmpty
        const editorState = parentProps.articlePlaceholders.getEditorState(this.props.pid)

        returnValue = (<Editor
          css={css}
          {...editorProps}
          spec={editorProps.spec || {}}

          showWhenEmpty={showWhenEmpty}

          onChange={this.handleChange}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onDrop={this.handleDrop}

          value={value}
          editorState={editorState}
          ref={ref => (this.refElem = ref)}
          contextStore={contextStore}
        />)


      }

      return returnValue
    }
  }

  ContextConnectedEditor.Buttons = Editor.Buttons

  return ContextConnectedEditor
}
