import { closest } from './dom-helpers'
import BaseCommand from './BaseCommand'

import sanitize from './copy-paste-sanitizer'

export
class RemoveLinebreakImpl
extends BaseCommand {

  static defaultData = {

  }

  constructor(scribe, command, removeLinebreakOpts) {
    super(scribe, command, removeLinebreakOpts)

    this.config = {
      allowSoftBreaks: true,
      removeEmptyPs: true,
      removeWrappingPs: true,
      removeTrailingBreaksBefore: true,
      removeTrailingBreaksAfter: true,
      zipMultipleBreaks: true,
      ...removeLinebreakOpts
    }
  }

  static commandName = 'removeLinebreak'

  /**
  * @private
  * @param  {Object} pasteData The data to be pasted.
  */
  removeLinebreak() {

    const contents = this.getSelectionContents()

    const newHTML = this.scribe.el.innerHTML
    .replace(
      /(<\/em>)(.*?)(<em)/g,
      (match, g1, g2, g3) => {
        return `${g1}${this.removeLinebreaksFromHTMLFragmentString(g2)}${g3}`
      })

    this.scribe.el.innerHTML = newHTML

    this.selectContent()

  }

  /**
   * @private
   */
  removeLinebreaksFromHTMLFragmentString(htmlString) {

    const hasPTags = htmlString.indexOf('<p>') > -1

    if (hasPTags) {
      // replace all empty <p>-tags with somple <br>s
      if (this.config.removeEmptyPs) {
        htmlString = htmlString.replace(/<\/p>\s*<p>/g, '<br>')
      }

      // remove all <p> tags at the beginning and end of the text
      if (this.config.removeWrappingPs) {
        htmlString = htmlString.replace(/^(?:<p>)/g, '')
          .replace(/(?:<\/p>)/g, '')
      }

      // collapse multiple consecutive <p>s into one
      if (this.config.zipMultipleBreaks) {
        htmlString = htmlString.replace(/(?:<br(?: \/)?>)+/g, '<br>')
      }

      // remove all breaks at the beginning of paragraphs
      if (this.config.removeTrailingBreaksBefore) {
        htmlString = htmlString.replace(/(?:^|(?:<p>))(?:<br(?: \/)?>)/g, '')
      }

      // remove all breaks at the end of paragraphs
      if (this.config.removeTrailingBreaksAfter) {
        htmlString = htmlString.replace(/(?:<br(?: \/)?>)(?:$|(?:<\/p>))/g, '')
      }
    }


    if (!hasPTags || !this.config.allowSoftBreaks) {
      htmlString = htmlString
      .replace(/<br(?: \/)?>/g, ' ')
      .replace(/(\s)+/g, ' ')
    }

    // replace multiple consecutive linebreaks with just one
    if (this.config.zipMultipleBreaks) {
      htmlString.replace(/<br(?: \/)?>(?:<br(?: \/)?>)+/g, '<br>')
    }

    return htmlString

  }

}

/**
* This plugin adds a command for creating links, including a extended prompt.
*/
export default
function scribePluginRemoveLinebreakCommandFactory(removeLinebreakOpts = {}) {

  return function scribePluginRemoveLinebreakCommand(scribe) {

    const removeLinebreakCommand = new scribe.api.Command('removeLinebreak')

    removeLinebreakCommand.execute = function executeRemoveLinebreakCommand(data) {

      const impl = new RemoveLinebreakImpl(scribe, this, removeLinebreakOpts)

      scribe.transactionManager.runAsync((complete) => {

        impl.cacheSelection()

        impl.restoreSelection()
        .then(() => {
          return impl.executeCommand('removeLinebreak')
        })
        .then(() => impl.finishCommand())
        .then(complete)

      })
    }

    removeLinebreakCommand.queryEnabled = function queryEnableForeColorCommand () {
      var selection = new scribe.api.Selection()
      return !selection.selection.isCollapsed
    }

    // removeLinebreakCommand.queryState = function queryStateRemoveLinebreakCommand() {
    //   /**
    //    * We override the native `document.queryCommandState` for links because
    //    * the `createLink` and `unlink` commands are not supported.
    //    * As per: http://jsbin.com/OCiJUZO/1/edit?js,console,output
    //    */
    //   const selection = new scribe.api.Selection()
    //   return !!selection.getContaining((node) =>
    //     node.nodeName === this.nodeName
    //   )
    // }

    scribe.commands.removeLinebreak = removeLinebreakCommand

  }
}
