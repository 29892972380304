import * as PropTypes from 'prop-types'
import React from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import { getEventsFromProps } from '../../shared/utils/events'

import getTemplateConnectionData from '../../shared/template/getTemplateConnectionData'
import wrapInErrorHandler from '../../shared/template/wrapInErrorHandler'

import { genevaCssClass } from '../../shared/utils'

export default function connectTemplateToEnv(TargetTemplate, parentProps) {
  wrapInErrorHandler(TargetTemplate, parentProps)

  const EnvConnectedTemplate = (props) => {
    const eventHandlers = getEventsFromProps(props)

    return (
      <div
        className={classNames(
          'grid-block',
          genevaCssClass('cm-connected-template-wrapper')
        )}
        {...eventHandlers}
      >
        <TargetTemplate
          {...getTemplateConnectionData('base', 'article', parentProps, props)}
        />
      </div>
    )
  }

  EnvConnectedTemplate.propTypes = {
    placeholder: PropTypes.object.isRequired,
    env: PropTypes.object.isRequired,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
  }

  return observer(EnvConnectedTemplate)
}
