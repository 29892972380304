import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { confirmable } from 'react-confirm'
import { formatMessage, FormattedMessage, FormattedHTMLMessage } from '../../../translations'
import { Dialog } from '../../../shared/components/Dialog'


@confirmable
class ConfirmDelete extends Component {

  static propTypes = {
    show: PropTypes.bool,            // indicates if the dialog is shown or not.
    proceed: PropTypes.func,         // call to close the dialog with promise resolved.
    cancel: PropTypes.func,          // call to close the dialog with promise rejected.
    dismiss: PropTypes.func,         // call to only close the dialog.
    confirmation: PropTypes.string,  // arguments of your confirm function
    options: PropTypes.object        // arguments of your confirm function
  }

  render() {


    const {
      show,
      proceed,
      dismiss,
      options: { page, opts: { archive } }
    } = this.props

    return (<Dialog
      className=""
      overlayClassName=""
      isOpen={show}
      onAfterOpen={this.handleAfterOpen}
      onRequestClose={this.handleRequestClose}
      title={formatMessage({
        id: archive
          ? 'page.confirm.archive.title'
          : 'page.confirm.delete.title'
      })}
      onCancel={dismiss}
      onCommit={proceed}
    >
      {archive
        ? <div>
          <FormattedMessage
            id="page.confirm.archive.are-you-sure"
            values={{
              name: page.name
            }}
          />
          <br />
          <br />
          <FormattedMessage
            id="page.confirm.archive.restore-info"
          />
        </div>
        : <div>
          <FormattedMessage
            id="page.confirm.delete.are-you-sure"
            values={{
              name: page.name
            }}
          />
          <br />
          <br />
          <FormattedHTMLMessage
            id="page.confirm.delete.final-info"
          />
        </div>
      }
    </Dialog>)

  }
}

export default ConfirmDelete
