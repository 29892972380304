import * as PropTypes from 'prop-types'
import React from 'react'
import IonIcon from './ion'
import FontAwesomeIcon from './font-awesome'

const Icon = (props) => {
  const key = props.name.split('-')[0]
  const Comp = key === 'fa' ? FontAwesomeIcon : IonIcon

  return (
    <Comp
      title={props.title}
      name={props.name.replace(`${key}-`, '')}
      className={props.className}
      size={props.size}
      rotate={props.rotate}
    />
  )
}

Icon.defaultProps = {
  name: 'ion-ionic',
  size: null,
}

Icon.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.number,
  rotate: PropTypes.number,
  title: PropTypes.string,
}

export { Icon as default }
