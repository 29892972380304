import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { autobind } from 'core-decorators'
import PerfectScrollbar from 'perfect-scrollbar'
import { FormattedMessage } from '../../translations'
import Icon from '../../shared/icons'
import GenevaButton from '../../ui/components/GenevaButton'
import ReviewCenterSingleUser from './ReviewCenterSingleUser'

require('../styles.scss')

@observer
export default class ReviewCenterUsers extends Component {
  static propTypes = {
    reviewModel: PropTypes.object.isRequired,
    handleViewSwitch: PropTypes.func.isRequired,
    toggleUserList: PropTypes.func,
    updateUser: PropTypes.func,
  };

  componentDidMount() {
    this.ps = new PerfectScrollbar('#rc-users-container')
  }

  @autobind
  updateUser(userData) {
    this.props.updateUser(userData)
  }

  render() {
    const { reviewModel } = this.props

    if (this.ps) {
      this.ps.update()
    }

    return (
      <div className="rc-settings" id="rc-users-container">
        <div
          className="rc-view-switch-settings"
          onClick={this.props.handleViewSwitch}
        >
          <Icon name="ion-ios-arrow-back" />
          <FormattedMessage id="review-center.settings.to-chat" />
        </div>
        <hr />
        {reviewModel.users
          .sort((first, second) => {
            if (first.createdAt && second.createdAt) {
              return first.createdAt.localeCompare(second.createdAt)
            }
            return 0
          })
          .map((user) => {
            return [
              <ReviewCenterSingleUser
                key={user.id}
                user={user}
                updateUser={this.updateUser}
              />,
              <hr key="hr" />,
            ]
          })}
        <GenevaButton
          onClick={this.props.toggleUserList}
          className="small button"
        >
          <FormattedMessage id="review-center.settings.add-user-button" />
        </GenevaButton>
      </div>
    )
  }
}
