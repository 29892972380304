import { maxBy as _maxBy } from 'lodash'
/**
 * Gets the value at the given `keypath` from the Object `obj`. So it provides
 * easy deep access to an object.
 * @example
 * const obj = {
 * 'this' : {
 *   'is' : {
 *    'a' : {
 *      'demo': 'yay'
 *     }
 *   }
 * }
 * deepGet(obj, 'this.is.a.demo') === 'yay'
 * @param {Object} obj - The object to get the value from.
 * @param {String} keypath - The path of keys to the deeply nested value.
 * @param {Any} defaultVal - The default value to return if item does not exist.
 * @returns {*} - The value at the given `keypath` or `undefined` if any of
 * the keys does not exist on the object.
 */
export function deepGet(obj, keypath, defaultVal = undefined) {
  return keypath.split('.').reduce(
    (memo, currKey) => (memo
      // Object.prototype.toString.call(currKey) === '[object Object]' &&
      && currKey in memo
      ? memo[currKey]
      : defaultVal),
    obj
  )
}

/**
 * Sets the value at the given `keypath` from the Object `obj`. So it provides
 * an easy way to update a nested value on a document. If any of the keys in the
 * `keypath` does not exist, a new entry of type `Object` will be created at the
 * position in the key path within `obj`.
 * @param {Object} obj - The object to get the value from.
 * @param {String} keypath - The path of keys to the deeply nested value.
 * @param {*} val - The new value to set at the given keypath.
 * @returns {Object} - The original object `obj`.
 */
export function deepSet(obj, keypath, val) {
  if (!obj) {
    throw new Error('`deepSet` expects an Object as first parameter.')
  }

  const keys = keypath.split('.')
  const lastKey = keys.pop()

  const subObj = keys.reduce(
    (memo, currKey) => (memo && currKey in memo ? memo[currKey] : (memo[currKey] = {})),
    obj
  )

  if (subObj) {
    subObj[lastKey] = val
  }

  return obj
}

/**
 * Creates a copy of `obj` with only those keys that were defined in `keys`
 * @param {Object} obj - The source object.
 * @param {Array<String>} keys - An array of keys that should appear in the new
 * object.
 * @returns {Object} - The object clone.
 */
export function filter(obj, keys) {
  if (!Array.isArray(keys)) {
    throw new Error(
      '`filter` expects an array of property keys as second argument.'
    )
  }

  return keys.reduce((memo, key) => {
    if (key in obj) {
      memo[key] = obj[key]
    }
    return memo
  }, {})
}

export function ignore(obj, keys) {
  if (!Array.isArray(keys)) {
    throw new Error(
      '`ignore` expects an array of property keys as second argument.'
    )
  }

  return Object.keys(obj).reduce((memo, key) => {
    if (keys.indexOf(key) === -1) {
      memo[key] = obj[key]
    }
    return memo
  }, {})
}

export function clone(obj) {
  return JSON.parse(JSON.stringify(obj))
}

export const maxBy = _maxBy
