import mobx from 'mobx'
import Model from '../model'
import { deepGet } from '../obj'

/**
 * The Store object.
 */
export default class Store {
  /**
   * Creates a new store instance.
   * @param {Object} opts - Some options for this store.
   */
  constructor(opts) {
    /**
     * Options for this store.
     * @type {Object}
     */
    this.opts = opts

    /**
     * The collection of the Models
     * @type {Array<Model>}
     */
    this.collection = []

    /**
     * A collection of filtered Models
     * @type {Array<Model>}
     */
    this.modifiedCollection = []

    /**
     * The currently active model
     * @type {Model}
     */
    this.current = {}

    /**
     * The model class.
     * @type {Object}
     */
    this.Model = null

    this.openingId = null
    this.openingTimeout = null
    this.openingRequest = null
  }

  /**
   * Creates a new model.
   * @param {Object} data - The plain data object.
   * @param {Object} opts - The plain options object.
   * @returns {Model|Object}
   */
  createModel(data, opts = {}) {
    return this.Model ? new this.Model(this, data, opts) : data
  }

  /**
   * Creates, adds the model to the collectiona and saves it (calling the
   * models `save` method.)
   * @param {Object} data - The data to be handed to the new model.
   * @returns {Promise<Model>} - A promise that resolves with the created model
   * or fails with the Error.
   */
  create(data = {} /* , opts = {} */) {
    const model = this.createModel(data)

    this.add(model)

    if (!(model.save && model.set)) {
      return Promise.resolve(model)
    }

    return (
      model
        .save()
        // immediately remove the model again if there was an error
        .catch((err) => {
          this.remove(model)
          throw err
        })
        // otherwise ensure that the local data is the same as the remote one
        .then((modelData) => {
          model.set(modelData)
          return model
        })
    )
  }

  isModel(suspect) {
    return this.Model && suspect instanceof this.Model
  }

  /**
   * Adds the model to the collection of this store.
   * @param {Array<Model|Object>} models - The models to be added to the collection.
   * @param {Object} opts - Some options for adding.
   * @property {Object} opts.merge - If true, merges the new model into a previously existing one.
   * @property {Object} opts.replace - If true, replaces the new model into a previously existing one.
   * @property {Object} opts.params - Query parameters.
   * @property {Object} opts.params.filter - Query parameters.
   * @returns {Model} - The added model
   */
  add(models, opts = {}) {
    if (!Array.isArray(models)) {
      models = [models]
    }

    models = models.map((model) => {
      const exists = this.getById(model.id, opts)

      if (this.Model && !exists && !this.isModel(model)) {
        model = this.createModel(model)
      }

      if (exists) {
        if (opts.merge) {
          // eslint-disable-next-line max-len
          console.warn(
            `A model with the id ${model.id} already exists in the collection. Merging.`
          )
          exists.set(model.getJSON())
          return exists
        }
        if (opts.replace) {
          // eslint-disable-next-line max-len
          console.warn(
            `A model with the id ${model.id} already exists in the collection. Replacing.`
          )
          return model
        }
      }

      return model
    })

    if (opts.reset) {
      this.collection.splice(0, this.collection.length, ...models)
    }
    else {
      // models required to exist in both collections, even when building a
      // filtered collection
      if (this.filterInOpts(opts)) {
        this.modifiedCollection.push(...models)
      }
      this.collection.push(...models)
    }

    return models
  }

  remove(model) {
    return this.collection.splice(this.collection.indexOf(model), 1)
  }

  /**
   * Load the data and populate the model[s].
   * @abstract
   * @param {?(String|Number)} id - The id of the model to load. If no id is
   * passed (or `null`) then the collection of models will be loaded.
   * @param {Object} opts - An options object to send along to the transport
   * layer.
   * @param {Object} data - A plain data object to be send along to the
   * transport layer.
   */
  // eslint-disable-next-line no-unused-vars
  load(id = null, opts = {}, data = null) {}

  /**
   * Save the data from the model.
   * @abstract
   * @param {!(String|Number)} id - The id of the model to load.
   * @param {Object} opts - An options object to send along to the transport
   * layer.
   * @param {Object} data - A plain data object to be send along to the
   * transport layer.
   */
  // eslint-disable-next-line no-unused-vars
  save(id = null, opts = {}, data = null) {}

  /**
   * Delete the model.
   * @abstract
   * @param {!(String|Number)} id - The id of the model to load.
   * @param {Object} opts - An options object to send along to the transport
   * layer.
   */
  // eslint-disable-next-line no-unused-vars
  destroy(id = null, opts = {}) {}

  /**
   * Transform this model to a JSON object.
   * @returns {Object}
   */
  asJSON() {
    return mobx.toJS(this)
  }

  filterInOpts(opts = {}) {
    return opts.params && opts.params.filter && opts.params.filter !== '[]'
  }

  /**
   * Gets a model from the store by it's id.
   * @returns Null if no model by this id was found.
   */
  getById(id, opts = {}) {
    id *= 1

    // allow for alternate collections
    const collection = opts.collection || this.collection

    let retVal = collection.find(item => item && item.id * 1 === id)

    if (!retVal || this.filterInOpts(opts)) {
      retVal = this.modifiedCollection.find(
        item => item && item.id * 1 === id
      )
    }

    return retVal
  }

  contains(model, opts = {}) {
    if (!model) {
      return false
    }
    if (this.filterInOpts(opts)) {
      return this.modifiedCollection.indexOf(model) >= 0
    }
    return this.collection.indexOf(model) >= 0
  }

  /**
   * If a model with the given `id` is not found in {@link Model#collection},
   * this store will try to load it from the server.
   * If it existed already or exists now that it was loaded, then this model
   * will be set as {@link Model#current}.
   * @param {!(String|Number)} id - The id of the model.
   * @param {Object} opts - A plain object of options to send along to the
   * @property {Boolean} opts.force - If true, forces
   *  the store to actually reload (fetch) the model.
   * {@link Store#load} method.
   */
  openById(id, opts = {}) {
    const forceRefetch = opts.force

    // bail out if
    // 1) there is no id,
    // 2) we are already opening this item or
    // 3) the current item IS the item to be opened
    if (
      !id
      || this.openingId === id
      || (this.current && this.current.id === id)
    ) {
      return
    }

    // cancel all possibly ongoing opening actions
    this.clearOngoingOpeningActions(id !== this.openingId)

    this.unsetCurrent()

    // get the model to be opened from the local collection
    const next = this.getById(id)

    // In case the we have the model locally aready and there is no option
    // forcing us to reload the model from the server, just use the local
    // copy
    if (next && !forceRefetch) {
      this.openLocally(next)
    }
    else {
      this.loadAndOpen(id, opts)
    }
  }

  /**
   * @private
   */
  clearOngoingOpeningActions(force) {
    if (force) {
      // clear any pending opening action
      if (this.openingTimeout) {
        clearTimeout(this.openingTimeout)
      }

      if (
        this.openingRequest
        && this.openingRequest.request
        && this.openingRequest.request.abort
      ) {
        console.log(`Aborting request to ${this.openingRequest.request.url}`)
        this.openingRequest.request.abort()
      }

      this.openingId = null
      this.openingTimeout = null
      this.openingRequest = null
    }
  }

  /**
   * @private
   */
  openLocally(next = {}) {
    // This timeout is necessary to prevent mobx from going into an endless
    // loop. Without this, the openArticle would cause an setCurrent on
    // the article store before this function finished and thus would result
    // in another execution of this autorun.
    this.openingTimeout = setTimeout(() => {
      // next.isActive = true
      this.clearOngoingOpeningActions(true)
      this.setCurrent(next)
    }, 1)
  }

  /**
   * @private
   */
  loadAndOpen(id, opts = {}) {
    this.openingId = id

    const storeName = this.constructor.name

    this.openingRequest = this.load(id, opts)
      // immediately stop the opening request and throw
      .catch((ex) => {
        this.openingRequest = null
        throw ex
      })
      // otherwise open the requested resource
      .then((result) => {
        if (!result) {
          const error = new Error(
            `Unexpected empty result for ${storeName}.load(${id})!`
          )
          error.id = id
          error.opts = opts
          console.error(error)
        }

        // the id could be from the model if it's already in the collection or raw response if not
        const modelId = result.id || deepGet(result, 'body.data.id')
        // in case the id of the opened page does not match with the one currently
        // being opened, bail out
        if (modelId * 1 !== this.openingId * 1) {
          throw new Error(
            `ID missmatch on opening: openingId=${this.openingId} vs. resultId=${result.id}`
          )
        }
        this.openingId = null
        this.openingRequest = null

        // Remove the force option and just call this method again,
        // which now will just open the local copy of the model
        delete opts.force
        this.openById(id, opts)
      })
  }

  unsetCurrent() {
    // mark the current object as not being current anymore
    if (this.current) {
      this.current.isActive = false
    }
    // TODO: this should be set to null. For that to work all calls to .current
    // have to be guared by a call to .hasCurrent
    this.current = {}
  }

  /**
   * Sets the current property of this store.
   * @param {Model|null} current - The new current model or null.
   */
  setCurrent(current) {
    clearTimeout(this.openingTimeout)
    if (current) {
      current.isActive = true
    }
    this.current = current
  }

  toJSON() {
    return (this.collection || []).map(itm => itm.getJSON())
  }
}
