import { toJS } from 'mobx'

import { TemplateError, ContentError } from '../../../shared/errors'

import {
  templateStores,
  contentStores,
  connectorStores,
  templateActivators,
  templateDependencyCollectors
} from './DataProviders'

export default
class ContentProvider {

  constructor(type, env, customLocal) {

    this.type = type
    this.env = env
    this.customLocal = customLocal

    this.templateStore = templateStores[type]
    this.contentStore = contentStores[type]
    this.connectors = connectorStores[type]


    this.pmTemplateActivator = new templateActivators[type](
      env,
      {
        connectTemplateToEnv: this.connectors.connectTemplateToEnv
      },
      {
        reloadStylesheets: this.templateStore.alwaysReloadStyleSheets,
        type
      }
    )
  }

  provide(item, props, additionalDependencies = {}) {

    return new Promise((resolve, reject) => {

      try {

        const { type, templateStore } = this

        if (!item.templateId) {
          throw new TemplateError('Template id missing', undefined, type)
        }

        templateStore.getLoadedTemplateById(item.templateId)
          .then(template => this.withTemplate(
            template,
            item,
            props,
            additionalDependencies
          ))
          .then(result => resolve(result))
          .catch(ex => reject(ex))

      }
      catch (ex) {
        reject(ex)
      }

    })

  }

  /**
   * @private
   */
  withTemplate(
    template,
    item,
    props,
    additionalDependencies
  ) {

    const { type, contentStore, connectors } = this

    if (!template) {
      throw new TemplateError(
        `RenderingError: Template with id ${item.templateId} not found`,
        item.templateId,
        type
      )
    }

    const content = contentStore.getById(item.id)
    if (!content) {
      throw new ContentError(
        `RenderingError: ${type} with id ${item.id} not found`,
        item.id,
        type
      )
    }

    log.renderer('Rendering Template for %s %s', type, content.id)

    const dependencyCollector = new templateDependencyCollectors[type]({
      props: {
        ...props,
        [type]: content
      },
      connectors,
      ENV: toJS(this.env),
      ...additionalDependencies
    })

    const dependencies = {
      // TODO: remove content specific dependencies, so template can be cached
      // over various contents with same template id
      ...dependencyCollector.collect(),
      ...template.dependencies
    }

    if (!(
      template
      && template.module
      && template.module.getTemplate
    )) {

      if (template.module instanceof Error) {
        throw template.module
      }

      throw new TemplateError(
        'Missing or invalid template.',
        item.templateId,
        type
      )

    }

    const templateComponent = template.module.getTemplate(dependencies)

    return this.pmTemplateActivator
      .activate({
        channelShortcut: content.channelShortcut,
        templateSpec: template,
        templateComponent,
        templateModule: template.module,
        dependencies,
        injectTemplateProps: {},
        customLocalStore: this.customLocal,
      }, {
        loadStyles: false,
        loadLang: true,
        loadContextTools: false,
        loadMetaSettings: false
      })
      .then((ConnectedTemplate) => {
        return ({
          content,
          ConnectedTemplate
        })
      })
      .catch((ex) => {
        throw ex
      })
  }
}
