import * as PropTypes from 'prop-types'
import React from 'react'

import ArticleItem from './ArticleItem'
import WidgetItem from './WidgetItem'

import connectItemToKeyboard from '../connectItemToKeyboard'

const KeyboadConnectedArticleItem = connectItemToKeyboard(ArticleItem)
const KeyboadConnectedWidgetItem = connectItemToKeyboard(WidgetItem)

export default function getWrappers(parentProps, comp) {
  const ArticleWrapper = (props) => {
    return (
      <KeyboadConnectedArticleItem
        {...props}
        env={parentProps.env}
        customLocal={parentProps.customLocal}
        onFocus={comp.handleFocus}
        onBlur={comp.handleBlur}
        onOpen={comp.handleOpen}
        onDelete={comp.handleDelete}
        onRemove={comp.handleRemove}
      >
        {props.children}
      </KeyboadConnectedArticleItem>
    )
  }

  ArticleWrapper.propTypes = {
    children: PropTypes.node,
  }

  const WidgetWrapper = props => (
    <KeyboadConnectedWidgetItem
      {...props}
      env={parentProps.env}
      customLocal={parentProps.customLocal}
      onFocus={comp.handleFocus}
      onBlur={comp.handleBlur}
      onOpen={comp.handleOpen}
      onDelete={comp.handleDelete}
      onRemove={comp.handleRemove}
    >
      {props.children}
    </KeyboadConnectedWidgetItem>
  )

  WidgetWrapper.propTypes = {
    children: PropTypes.node,
  }

  return {
    article: ArticleWrapper,
    widget: WidgetWrapper,
  }
}
