import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import { autobind } from 'core-decorators'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import { TreeNode } from 'draggable-react-tree-component'
import PerfectScrollbar from 'perfect-scrollbar'
import { FormattedMessage } from '../../../translations'
import { PageTree } from '../../../project'
import LanguageDropdown from '../LanguageDropdown'
import ProjectDropdown from '../ProjectDropdown'
import ContentLoadingBox from '../ContentLoadingBox'
import * as css from './styles.scss'

@observer
export default class InternalLinkPageSelect extends Component {
  static propTypes = {
    options: PropTypes.object,
    internalPage: PropTypes.string,
    selectedProject: PropTypes.object,
    onSelect: PropTypes.func,
  };

  constructor(props) {
    super(props)

    let page
    let selectedPageId
    this.initialProjectId = null
    this.initialPageId = null
    this.ps = null

    if (props.internalPage) {
      const internalPageId = props.internalPage.split(':')

      const selectedProjectId = internalPageId[1]
      this.initialProjectId = internalPageId[1]

      selectedPageId = internalPageId[2]
      this.initialPageId = internalPageId[2]

      if (selectedProjectId * 1 === props.selectedProject.id * 1) {
        if (internalPageId.length > 2 && internalPageId[2]) {
          page = this.props.options.modal.page.getPartialById(internalPageId[2] * 1)
        }
      }
    }

    this.state = {
      selectedProject: props.selectedProject,
      projectLoading: false,
      expandedNodeIds: page ? page.getExpandedKeys() : null,
    }
    this.selectedNode = selectedPageId ? [selectedPageId] : []
  }

  componentDidMount() {
    if (this.props.internalPage) {
      const internalPageId = this.props.internalPage.split(':')
      const preSelectedProjectID = internalPageId[1] * 1

      if (
        // eslint-disable-next-line no-restricted-globals
        !isNaN(preSelectedProjectID)
        && this.state.selectedProject.id !== preSelectedProjectID
      ) {
        this.handleProjectChanged({ target: { value: preSelectedProjectID } })
      }
    }

    this.ps = new PerfectScrollbar('#linkPageTree-container', {
      suppressScrollX: true
    })
  }

  @autobind
  handleSelect(selectedNodeIds) {
    if (this.props.onSelect) {
      this.props.onSelect({
        target: {
          value: `internal:${this.state.selectedProject.id}:${selectedNodeIds[0]}`,
        },
      })
    }
    // needs to be an array
    this.selectedNode = [selectedNodeIds[0]]
  }

  @autobind
  handleExpand(expandedNodeIds) {
    this.setState({
      expandedNodeIds,
    })
  }

  @autobind
  handleLanguageChanged({ target }) {
    const { project } = this.props.options.modal
    const desiredProj = project.getPartialById(target.value)

    this.setState({
      projectLoading: true,
    })

    if (desiredProj && desiredProj.pages && desiredProj.pages.length > 0) {
      this.setState({
        selectedProject: desiredProj,
        projectLoading: false,
      })
    }
    else {
      project
        .load(target.value, { backgroundLoad: true })
        .then((model) => {
          this.setState({
            selectedProject: model,
            projectLoading: false,
          })
        })
        .catch((e) => {
          console.error(e)
          this.setState({
            projectLoading: false,
          })
        })
    }
  }

  @autobind
  handleProjectGroupChange({ target }) {
    const { project } = this.props.options.modal
    const desiredProjGroup = project.getGroupById(target.value)
    if (desiredProjGroup && desiredProjGroup.projects.length > 0) {
      const desiredProj = desiredProjGroup.projects[0]
      this.updateProjectSelection(desiredProj)
    }
    else {
      console.warn('project group has no language variants')
    }
  }

  @autobind
  handleProjectChanged({ target }) {
    const { project } = this.props.options.modal
    const newProj = project.getPartialById(target.value)
    this.updateProjectSelection(newProj)
  }

  @autobind
  updateProjectSelection(desiredProj = 1) {
    const { project } = this.props.options.modal
    // allows rendering of the spinner
    this.setState({
      projectLoading: true,
    })

    if (desiredProj && desiredProj.pages && desiredProj.pages.length > 0) {
      this.setState({
        selectedProject: desiredProj,
        projectLoading: false,
      })
      if (desiredProj.id === this.initialProjectId * 1) {
        const page = this.props.options.modal.page.getPartialById(this.initialPageId)
        this.setState({
          expandedNodeIds: page ? page.getExpandedKeys() : null,
        })
      }
    }
    else {
      this.remoteProject = {}
      project
        .load(desiredProj.id, { backgroundLoad: true })
        .then((model) => {
          this.setState({
            selectedProject: model,
            projectLoading: false,
          })
          if (desiredProj.id === this.initialProjectId * 1) {
            const page = this.props.options.modal.page.getPartialById(
              this.initialPageId
            )
            this.setState({
              expandedNodeIds: page ? page.getExpandedKeys() : null,
            })
          }
        })
        .catch((e) => {
          console.error(e)
          this.setState({
            projectLoading: false,
          })
        })
    }
  }

  @autobind
  renderPageItems(items) {
    return items.map(item => (
      <TreeNode
        basePadding={0}
        key={item.id}
        items={
          item.sub && item.sub.length ? this.renderPageItems(item.sub) : null
        }
        className="node"
      >
        {item.navigationName || item.name}
      </TreeNode>
    ))
  }

  renderPageTree() {
    const { selectedProject } = this.state

    const projectHasPages
      = selectedProject && selectedProject.pages && selectedProject.pages.length

    if (!projectHasPages) {
      return <FormattedMessage id="project.empty" />
    }

    const expandedKeys = this.state.expandedNodeIds || []

    return (
      <div id="linkPageTree-container" className={classNames(css.pageTreeContainer)}>
        <PageTree
          project={selectedProject}
          page={this.props.options.modal.page}
          renderItems={this.renderPageItems}
          onSelect={this.handleSelect}
          onExpand={this.handleExpand}
          selectedKeys={this.selectedNode}
          expandedKeys={expandedKeys}
          treeChildren={selectedProject.pageTree.children}
          selectable
        />

        <hr />

        <PageTree
          project={selectedProject}
          page={this.props.options.modal.page}
          renderItems={this.renderPageItems}
          onSelect={this.handleSelect}
          onExpand={this.handleExpand}
          expandedKeys={expandedKeys}
          treeChildren={selectedProject.specialPageTree.children}
          selectable
          selectedKeys={this.selectedNode}
        />
      </div>
    )
  }

  renderTrees() {
    if (this.state.projectLoading) {
      return (
        <div
          className={classNames(`
          ${css.controlCenter}
          grid-frame vertical`)}
        >
          <ContentLoadingBox
            spinner
            message={{
              id: 'project.loading',
            }}
          />
        </div>
      )
    }

    return (
      <div className="section-link-internal-page">{this.renderPageTree()}</div>
    )
  }

  render() {
    const { options, className } = this.props
    const { selectedProject } = this.state
    const { project } = options.modal

    if (this.ps) {
      this.ps.update()
    }

    return (
      <div className={className || 'grid-content vertical content-right'}>
        <FormattedMessage id="redirect-dialog.project-lang" />

        <header className="grid-block v-align">
          <span>
            <ProjectDropdown
              className={classNames(css.projectDropdown, 'projectDropdown')}
              projectStore={project}
              currentProject={selectedProject}
              onSelect={this.handleProjectGroupChange}
              isSelectBox
              title={selectedProject.name}
            />
          </span>

          <span>
            <LanguageDropdown
              className={css.languageDropdown}
              projectStore={project}
              currentProject={selectedProject}
              onSelect={this.handleLanguageChanged}
              isSelectBox
            />
          </span>
        </header>

        <FormattedMessage id="link-dialog.project-page-selection" />

        <div className="grid-block form-group section-link-internal-page-select stretch">
          {this.renderTrees()}
        </div>
      </div>
    )
  }
}
