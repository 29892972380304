import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import { autobind } from 'core-decorators'
import classNames from 'classnames'
import Ion from '../../icons/ion'

class DropdownOpener extends Component {
  static propTypes = {
    children: PropTypes.node,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    caret: PropTypes.bool,
    onDropdownOpenStateChange: PropTypes.func,
    id: PropTypes.string,
    ButtonComponent: PropTypes.func,
    arrow: PropTypes.bool,
    clickToClose: PropTypes.bool,
    onOpen: PropTypes.func,
  };

  static contextTypes = {
    dropdownIsInside: PropTypes.bool,
  };

  constructor(props) {
    super(props)
    this.state = { active: false }
  }

  componentDidMount() {
    document.body.addEventListener('mouseup', this.handleMouseUpOnBody, false)
  }

  componentWillUnmount() {
    document.body.removeEventListener(
      'mouseup',
      this.handleMouseUpOnBody,
      false
    )
  }

  @autobind
  handleMouseUpOnBody(e) {
    // if
    // (obvious cases: when clicking the opener, this should not close it
    // immediately again)
    // 1) we don't have a ref to the opener element         OR
    // 2) that opener element contains the event target     OR
    // (clicking an element within the dropdown that has no
    // .dropdown-entry ancestor)
    // 3) the event target is no .dropdown-entry
    // => bail out
    if (
      !this.openerRef
      || this.openerRef.contains(e.target)
      || (e.target.closest('.geneva-dropdown')
        && !e.target.closest('.dropdown-entry'))
      // (this.findClosest(e.target, '.geneva-dropdown') &&
      // !(this.findClosest(e.target, '.dropdown-entry')))
    ) {
      return
    }

    if (this.state.active) {
      e.preventDefault()
      e.stopPropagation()
      this.changeDropdownState({
        active: false,
      })
    }
  }

  @autobind
  handleMouseDown(e) {
    e.nativeEvent.preventDefault()
    e.nativeEvent.stopImmediatePropagation()
    this.changeDropdownState({
      active: this.props.clickToClose ? !this.state.active : true,
    })
    if (this.props.onOpen) {
      this.props.onOpen(e)
    }
  }

  @autobind
  handleMouseUp(e) {
    e.nativeEvent.preventDefault()
    e.nativeEvent.stopImmediatePropagation()
  }

  @autobind
  handleMouseDownOnInner(e) {
    e.nativeEvent.preventDefault()
    e.nativeEvent.stopImmediatePropagation()
  }

  changeDropdownState(state) {
    this.setState(state)
    if (this.props.onDropdownOpenStateChange) {
      this.props.onDropdownOpenStateChange({
        target: {
          open: state.active,
        },
      })
    }
  }

  renderCaret() {
    return <span className="geneva-caret"></span>
  }

  renderArrow() {
    return (
      <span className="geneva-arrow">
        <Ion name="ios-arrow-down" />
      </span>
    )
  }

  render() {
    const {
      children,
      disabled,
      caret,
      arrow,
      id,
      ButtonComponent,
    } = this.props
    const { dropdownIsInside } = this.context
    const dataProps = Object.keys(this.props)
      .filter(prop => prop.indexOf('data-') > -1)
      .reduce((memo, prop) => {
        memo[prop] = this.props[prop]
        return memo
      }, {})

    if (dropdownIsInside || !ButtonComponent) {
      return (
        <a
          className={classNames(this.props.className, 'button-dropdown')}
          id={id}
          onMouseDown={this.handleMouseDownOnInner}
          disabled={disabled}
          {...dataProps}
        >
          {children} {arrow ? this.renderArrow() : this.renderCaret()}
        </a>
      )
    }

    return (
      <ButtonComponent
        ref={ref => (this.openerRef = ref)}
        onClick={this.handleMouseUp}
        onMouseDown={this.handleMouseDown}
        onMouseUp={this.handleMouseUp}
        id={id}
        className={classNames(
          this.props.className || 'small button plain',
          'button-dropdown',
          { active: this.state.active }
        )}
        disabled={disabled}
        {...dataProps}
      >
        {children}
        {caret !== false ? this.renderCaret() : null}
        {arrow ? this.renderArrow() : null}
      </ButtonComponent>
    )
  }
}

export { DropdownOpener as default }
