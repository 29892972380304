import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { autobind } from 'core-decorators'

import { store as contextStore } from '../../context'

import EditItemCommand from '../commands/EditItemCommand.js'
import RemoveItemFromPageCommand from '../commands/remove-item-from-page'

import commandConnector from '../lib/command'
import { dispatcher } from '../lib/command'

export default function connectPMItemToolsToCommands(Target) {
  @dispatcher
  class ConnectedTarget extends Component {
    static propTypes = {
      value: PropTypes.string,
      onBeforeChangeFile: PropTypes.func,
      ui: PropTypes.object,
    };

    constructor(props) {
      super(props)
      this.state = {
        value: props.value,
      }
    }

    @autobind
    handleEditItem({ target: { type } }) {
      contextStore.assert(type)

      this.context.dispatch(this.props.commands.EditItemCommand, {
        router: this.props.router,
        type,
        id: contextStore.target.id,
      })
    }

    @autobind
    handleDeleteItem({ target: { pageId, type, itemStore = {} } }) {
      contextStore.assert(type)

      new RemoveItemFromPageCommand({
        commandData: {
          type,
          id: contextStore.target.id,
          pageId,
        },
        opts: {
          destroy: true,
          itemStore,
        },
      }).exec()
    }

    @autobind
    handleRemoveItemFromPage({ target: { pageId, type } }) {
      contextStore.assert(type)

      new RemoveItemFromPageCommand({
        commandData: {
          type,
          id: contextStore.target.id,
          pageId,
        },
        opts: {
          destroy: false,
        },
      }).exec()
    }

    render() {
      return (
        <Target
          {...this.props}
          {...this.state}
          onEditItem={this.handleEditItem}
          onRemoveItemFromPage={this.handleRemoveItemFromPage}
          onDeleteItem={this.handleDeleteItem}
        />
      )
    }
  }

  return commandConnector({
    EditItemCommand,
  })(withRouter(ConnectedTarget))
}
