import moment from 'moment'
import tz from 'moment-timezone'

// working between dates and moments
// https://stackoverflow.com/questions/38384263/how-to-get-date-object-from-moment-js-after-formatting-it

import { SERVER_TIMEZONE, SERVER_TIME_FORMAT } from './const'


/**
 * @param {Date/Moment/String} serverDate - expects a date of sorts
 * moment object
 * date object
 * 'YYYY-MM-DD HH:mm:ss' string that our server stores (assumed to be a server date)
 * @return {Moment} - because of timezones, it's necessary to return the whole object
 */
export function convertDateToUserLocal(
  serverDate: string|tz.Moment,
  opts: { barDisplay?: boolean} = {}
): string|tz.Moment {

  if (!serverDate) {
    console.warn('attempting to convert a date but none given')
    return null
  }
  if (typeof serverDate === 'string') {
    serverDate = tz.tz(serverDate, SERVER_TIMEZONE)
  }
  if (!moment.isMoment(serverDate)) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    serverDate = moment(serverDate)
  }

  const userTZ = tz.tz.guess()
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  const userDate = serverDate.tz(userTZ)

  // barDisplay is, for example, in DE: "31.07.2017 | 15:00"
  if (opts.barDisplay) {
    return `${userDate.format('L')} | ${userDate.format('LT')}`
  }
  return userDate

}

/**
 * @param {Date/Moment/String} serverDate - expects a date of sorts
 * moment object
 * date object
 * 'YYYY-MM-DD HH:mm:ss' string that our server stores (assumed to be a user date)
 * @param {Object} opts
 * @param {Bool} opts.simplified - returns in format for server
 * @return {Moment} - because of timezones, it's necessary to return the whole object
 */
export function convertDateToSystemLocal(
  localDate: string|tz.Moment,
  opts: { simplified?: boolean} = {}
): string|tz.Moment {

  if (!localDate) {
    console.warn('attempting to convert a date but none given')
    return null
  }
  if (typeof localDate === 'string') {
    // use userTZ if strings are allowed to come in as user time
    // use SERVER_TIMEZONE if all strings are assumed server strings
    const userTZ = tz.tz.guess()
    localDate = moment.tz(localDate, userTZ)
  }
  if (!moment.isMoment(localDate)) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    localDate = moment(localDate)
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  const serverDate = localDate.tz(SERVER_TIMEZONE)

  if (opts.simplified) {
    return serverDate.format(SERVER_TIME_FORMAT)
  }
  return serverDate
}
