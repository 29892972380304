import React from 'react'
import * as PropTypes from 'prop-types'
import classNames from 'classnames'
import { FormattedMessage } from '../../../translations'
import GenevaButton from '../../../ui/components/GenevaButton'

const css = /* typeof window === 'undefined' ? {} : */ require('./styles.scss')

function ButtonBar(props: Object) {
  if (props.buttons) {
    return (
      <div className={classNames(css.buttonBar, 'grid-block')}>
        {props.buttons.map((button) => {
          return (
            <GenevaButton
              onClick={props.onClick}
              key={button.name}
              id={button.name}
              value={button.name}
              disabled={button.disabled}
              className={classNames({
                selected: button.name === props.selected,
              })}
            >
              <FormattedMessage id={`${props.prefix}${button.name}`} />
            </GenevaButton>
          )
        })}
      </div>
    )
  }
  return null
}

ButtonBar.propTypes = {
  buttons: PropTypes.array,
  onClick: PropTypes.func,
  selected: PropTypes.string,
  prefix: PropTypes.string,
}

ButtonBar.defaultProps = {
  prefix: 'buttonBar.',
  selected: '',
}

export { ButtonBar as default }
