import { Command, CommandData } from '../lib/command'
import { deepGet } from '../obj'
import { editorVersion } from '../../config'

interface CreateContentCommandDataBase
extends CommandData {
  template: {
    id: number,
    name: string
  };
  type: 'article' | 'widget';
}

interface CreateArticleCommandData
extends CreateContentCommandDataBase {
  article: {
    id: number;
  };
}

interface CreateWidgetCommandData
extends CreateContentCommandDataBase {
  widget: {
    id: number;
  };
}

type CreateContentCommandData = CreateArticleCommandData | CreateWidgetCommandData

export default
class CreateContentCommand
extends Command {


  static storeRequirements = {
    article: true,
    widget: true,
  }

  /**
   * Validates the given input data.
   * @method validate
   * @param  {Object} data - The data to be validated.
   * @return {String}      - The result of validation.
   */
  static validationSpec = {
    type: Command.ensureData.isPresent.oneOf([ 'article', 'widget' ]),
    'template | article | widget': Command.ensureData.either({
      template: {
        'template.id': {number: true},
        'template.name': {string: true}
      },
      article: {
        'article.id': {number: true},
      },
      widget: {
        'widget.id': {number: true},
      }
    })
  }

  public data: CreateContentCommandData

  /**
   * Creates a new CreateContentCommand. This command handles the content
   * creation based on a content type and template.
   * @param {Object} data - The spec of the content that should be created.
   * @param {string} data.type - The type of the content. One of `article` or
   * `widget`.
   * @param {object} [data.template] - The name of the template that should be
   * created.
   * @param {object} [data.template.id] - The id of the template.
   * @param {object} [data.template.name] - The name of the template.
   * @param {Object} [data.article] - The actual existing article.
   * @param {Object} [data.article.id] - The id of the article. This is required
   * if `data.article` is used!
   * @param {Object} [data.widget] - The actual existing widget.
   * @param {Object} [data.widget.id] - The id of the widget. This is required
   * if `data.widget` is used!
   */
  constructor(data: CreateContentCommandData) {
    super(data)
    this.data = data
  }

  /**
   * Executes the create command. Determines the template that needs to be
   * created and creates it. Then either uses an existing content, or creates
   * the content anew. Finally returns a promise that will resolve with the
   * newly created content.
   * @method exec
   * @return {Promise<Object>} - The promise resolving with the created content
   */
  exec() {

    const {
      template,
      preventNewArticle
    } = this.data

    if (template && !preventNewArticle) {
      return this.createContent()
    }

    return this.tryGetContent()

  }

  private createContent() {
    const {
      type,
      template,
      store
    } = this.data
    const contentStore = store[type]

    return contentStore.create({
      templateId: template.id,
      templateName: template.name,
      editorVersion: editorVersion || deepGet(template, 'config.editorVersion')
    })
  }

  private tryGetContent() {
    const {
      type,
      template,
      store
    } = this.data
    const content = this.data[type]
    const contentStore = store[type]

    const item = contentStore.getById(content.id)

    if (item.complete) {
      return Promise.resolve(item)
    }

    return item.load()
  }

}
