import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { autobind } from 'core-decorators'
import PerfectScrollbar from 'perfect-scrollbar'
import { formatMessage, FormattedMessage } from '../../translations'

import ReviewCenterSingleChat from './ReviewCenterSingleChat'
import Icon from '../../shared/icons'
import GenevaButton from '../../ui/components/GenevaButton'

require('../styles.scss')

@observer
export default class ReviewCenterChat extends Component {
  static propTypes = {
    reviewModel: PropTypes.object.isRequired,
    handleViewSwitch: PropTypes.func.isRequired,
    sendAnswer: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props)

    this.state = {
      answerText: '',
      isFullChat: false,
    }
  }

  componentDidMount() {
    this.ps = new PerfectScrollbar('#rc-chat-container')
  }

  @autobind
  sendAnswer() {
    this.props.sendAnswer(this.state.answerText)

    this.setState({
      answerText: '',
      isFullChat: false,
    })
  }

  @autobind
  handleAnswer({ target }) {
    this.setState({
      answerText: target.value,
    })
  }

  @autobind
  toggleChatField() {
    this.setState(prevState => ({ isFullChat: !prevState.isFullChat }))
  }

  renderTextArea() {
    const { answerText } = this.state

    const textarea = document.getElementById('rcAnswerText')
    if (textarea && !textarea.oninput) {
      textarea.oninput = () => {
        textarea.style.height = '' /* Reset the height*/
        textarea.style.height = `${textarea.scrollHeight}px`
      }
    }

    return (
      <div className="text-area">
        <textarea
          id="rcAnswerText"
          autoFocus
          value={answerText}
          maxLength={191}
          onChange={this.handleAnswer}
          placeholder={formatMessage({ id: 'review-center.chat.placeholder' })}
        />

        <GenevaButton className="button small" onClick={this.sendAnswer}>
          <FormattedMessage id="review-center.settings.send" />
        </GenevaButton>
      </div>
    )
  }

  renderAnswer() {
    return [
      <label key="chat-answer" htmlFor="answer-text">
        <FormattedMessage id="review-center.chat.answer" />
      </label>,

      <input
        key="answert-text"
        type="text"
        id="answer-text"
        name="answer-text"
        onFocus={this.toggleChatField}
        value={this.state.answerText}
      />,
    ]
  }

  renderChats() {
    const { reviewModel } = this.props

    if (reviewModel === undefined) {
      return null
    }

    return reviewModel.comments.map((comment) => {
      return (
        <ReviewCenterSingleChat
          comment={comment}
          key={comment.id}
          target={comment.target}
        />
      )
    })
  }

  render() {
    const { isFullChat } = this.state

    if (this.ps) {
      this.ps.update()
    }

    return (
      <div className="rc-chat" id="rc-chat-container">
        <div
          className="rc-view-switch-chat"
          onClick={this.props.handleViewSwitch}
        >
          <FormattedMessage id="review-center.to-settings" />
          <Icon name="ion-ios-arrow-forward" />
        </div>

        <div className="rc-answer">
          {!isFullChat ? this.renderAnswer() : this.renderTextArea()}
        </div>

        {this.renderChats()}
      </div>
    )
  }
}
