import React from 'react'

export default
function createParentPropsInjector(parentProps) {
  return (Target) => {
    return (props) => {
      return <Target {...parentProps} {...props} />
    }
  }
}
