import { MacroCommand, MacroCommandData } from '../../../../../shared/lib/command';

const ITEM_INDEX_LAST_ITEM = -1

export default
class RemoveContentFromWidgetWidgetMacroCommand
extends MacroCommand {

  // This command doesn't use these stores, but it's subcommands do. Defining
  // them here anyways guarantees that all subcommands only are executed if
  // these stores are available here already.
  static storeRequirements = {
    widget: true,
  }

  static validationSpec = {
    context: MacroCommand.ensureData.isPresent,
    'context.position': MacroCommand.ensureData.isPresent,
  }

  exec() {

    const {
      openForEditing,
      context
    } = this.data

    let model

    // Ensures the content exists
    return this.dispatch(
      this.data.commands.RemoveContentFromWidgetCommand,
      this.getDataForRemoveCommand()
    )
  /*  .then(() => {
      return this.dispatch(
        this.data.commands.SelectContentInWidgetCommand,
        this.getDataForSelectCommand()
      )
    })*/


  }

  private getDataForRemoveCommand() {

    const {
      context: {position},
      widgetId
    } = this.data
    const removeAtPosition = position

    return {
      removeAtPosition,
      widgetId
    }

  }

  private getDataForSelectCommand() {
    const {
      context,
    } = this.data

    return {
      context,
      // always select the last item in the list
      item: null,
      index: ITEM_INDEX_LAST_ITEM
    }
  }

}
