import { formatMessage, FormattedHTMLMessage, FormattedMessage } from '../../translations'
import { baseLibs, i18nLibs } from './dependencyCollectorLibs'
import CMOnlyComponent from './CMOnlyComponent'
import GenevaIcon from '../icons'

const cmOnlyComponents = {
  GenevaIcon
}

export default
class TemplateDependencyCollector {

  constructor(opts) {
    Object.assign(this, opts)
  }

  collect() {
    return {

      ...this.getBaseLibraries(),

      ...this.getI18nTools(),

      genevaComponents: { ...this.getComponents() },

      env: this.ENV,

    }
  }


  /**
   * @protected
   */
  getConnectionProps() {
    return {}
  }

  /**
   * @protected
   */
  connect(
    Component,
    connector,
    parentProps = {},
    ...additional
  ) {

    parentProps = {
      ...this.getConnectionProps(),
      env: this.ENV,
      ...parentProps
    }

    const connectedComponent = connector(
      Component, parentProps, this.contextStore, ...additional
    )

    // Attach all static properties of the original Component to the connected
    // one, unless that one already defines a static property by the same name.
    Object.keys(Component).forEach((key) => {
      if (!connectedComponent[key]) {
        connectedComponent[key] = Component[key]
      }
    })

    return connectedComponent
  }


  /**
   * @protected
   */
  getComponents() {
    return {
      ...Object.keys(cmOnlyComponents)
        .reduce((memo, key) => {
          memo[key] = this.ENV.CM
            ? cmOnlyComponents[key]
            : CMOnlyComponent
          return memo
        }, {})
    }
  }


  /**
   * @protected
   */
  getBaseLibraries() {
    return baseLibs
  }

  /**
   * @protected
   */
  getI18nTools() {
    return i18nLibs(
      {
        formatMessage,
        FormattedMessage,
        FormattedHTMLMessage,
      }
    )
  }


}
