export function filterPropsForEvents(props) {
  return Object.keys(props).filter(name => /^on[A-Z]/.test(name))
}

export function getEventsFromProps(props) {
  return filterPropsForEvents(props).reduce((memo, evt) => {
    memo[evt] = props[evt]
    return memo
  }, {})
}

export function delegateEventsFromProps(props, delegator) {
  return filterPropsForEvents(props).reduce((memo, name) => {
    memo[name] = delegator
    return memo
  }, {})
}

const HTML_EVENTS = {
  onClick: true,
  onDoubleClick: true,
  onChange: true,
  onMouseDown: true,
  onMouseUp: true,
  onMouseMove: true,
  onMouseEnter: true,
  onMouseLeave: true,
  onDragStart: true,
  onDragEnd: true,
  onDragEnter: true,
  onDragLeave: true,
  onDragOver: true,
  onDrop: true,
  onKeyDown: true,
  onKeyUp: true,
  onFocus: true,
  onBlur: true,
}

export function getHTMLEventsFromProps(props) {
  return Object.keys(props)
    .filter(evt => evt in HTML_EVENTS)
    .reduce((memo, evt) => {
      memo[evt] = props[evt]
      return memo
    }, {})
}

export function getEventHandlerFromType(type) {
  switch (type) {
    case 'dblclick':
      return 'onDoubleClick'
    case 'dragstart':
      return 'onDragStart'
    case 'dragover':
      return 'onDragOver'
    case 'dragleave':
      return 'onDragLeave'
    case 'dragenter':
      return 'onDragEnter'
    case 'dragend':
      return 'onDragEnd'
    default:
      return `on${type[0].toUpperCase()}${type.slice(1)}`
  }
}

export function getMousePositionInDocument(e) {
  let x = 0
  let y = 0
  if (!e) {
    e = window.event
  }
  if (e.pageX || e.pageY) {
    x = e.pageX
    y = e.pageY
  }
  else if (e.clientX || e.clientY) {
    x
      = e.clientX
      + document.body.scrollLeft
      + document.documentElement.scrollLeft
    y
      = e.clientY + document.body.scrollTop + document.documentElement.scrollTop
  }
  return { left: x, top: y }
}

export function getMousePositionOffsetTo(e, el) {
  if (!(e && el)) {
    // eslint-disable-next-line max-len
    throw new Error(
      '`getMousePositionOffsetTo`: The mouse event and an element have to be passed'
    )
  }
  const mousePos = getMousePositionInDocument(e)
  const elPos = el.getBoundingClientRect()
  const inRect
    = mousePos.left > elPos.left
    && mousePos.left < elPos.left + elPos.width
    && mousePos.top > elPos.top
    && mousePos.top < elPos.top + elPos.height

  if (!inRect) {
    return {}
  }

  return {
    x: mousePos.left - elPos.left,
    y: mousePos.top - elPos.top,
  }
}
