import resolve from 'resolve-url'
import { versionizePath, isModuleIsMissingError } from '../utils'

// when running in node, there should be no window variable
const isNode
  = typeof window === 'undefined'
  || (process
    && process.env
    && process.env.NODE_ENV
    && process.env.NODE_ENV === 'test')

export class TemplateLoader {
  static knownTemplates = [];

  /**
   * Creates a new Template loaders
   * @param {Array} collection -
   * @param {String} language -
   * @param {String} templateBasePath - The base path where to start looking
   * for templates.
   * @private
   */
  constructor(collection, language, templateBasePath) {
    this.collection = collection
    this.language = language

    this.templateBasePath = templateBasePath
  }

  /**
   * Load the template by it's shortcut.
   */
  loadTemplateByShortcut(shortcut, config) {
    const template
      = this.collection.find(tmpl => tmpl.shortcut === shortcut) || {}

    const isAlreadyLoaded = this.isModuleValid(template)

    const { templateFolderPath, templateFilePath } = this.getPaths(shortcut)

    log.tmplLoader(
      `${
        isAlreadyLoaded ? 'ok︎' : 'no'
      } TEMPLATE ALREADY LOADED ${templateFilePath}: `
    )

    const normalized = templateFilePath

    if (!config.isProduction) {
      this.removeIfExists(normalized)
    }

    log.tmplLoader(`Importing ${templateFilePath} ...`)

    const templateConfig = {
      isAlreadyLoaded,
      templateFolderPath,
    }

    const promise = isAlreadyLoaded
      ? Promise.resolve(template.module)
      : global.System.import(versionizePath(templateFilePath))

    return promise
      .then((TemplateModule) => {
        log.tmplLoader(`Template ${templateFilePath} loaded, setup...`)
        return this.setupModule(templateConfig, TemplateModule, config)
      })
      .then(([TemplateModule, ...deps]) => {
        if (!templateConfig.isAlreadyLoaded) {
          template.dependencies = this.gatherDependencies(deps)
        }
        template.module = TemplateModule
        template.moduleFile = templateFilePath

        if (TemplateLoader.knownTemplates.indexOf(shortcut) === -1) {
          TemplateLoader.knownTemplates.push(shortcut)
        }

        // If there is a template config function, add the result to the template model
        if (TemplateModule.getTemplateConfig) {
          template.config = TemplateModule.getTemplateConfig()
        }

        log.tmplLoader(
          ` Template ${templateFilePath} setup comlete!`,
          'font-weight: bold;'
        )
        return template
      })
      .catch((err) => {
        if (!isModuleIsMissingError(err)) {
          console.log(`ERROR in TemplateLoader: ${err.message}`)
          console.error(err)
        }
        template.module = err
        // TODO: on invalid template error, try to realod the template from the server
        return err
      })
  }

  /**
   * Remove the template by it's shortcut.
   */
  removeTemplateByShortcut(shortcut) {
    const template
      = this.collection.find(tmpl => tmpl.shortcut === shortcut) || null

    const { templateFolderPath, templateFilePath } = this.getPaths(shortcut)

    this.removeIfExists(templateFilePath)

    // TODO: This is about to be deprecated in favor of System.registry in v0.20.*
    if (global.System.defined) {
      // remove related modules
      Object.keys(global.System.defined)
        .filter(key => key.indexOf(shortcut) > -1)
        .forEach(path => this.removeIfExists(path))
    }

    const templateConfig = {
      templateFolderPath,
    }

    if (template && template.module) {
      this.disposeModule(templateConfig, template.module)

      if (template.module) {
        delete template.module
      }
      if (template.moduleFile) {
        delete template.moduleFile
      }
      if (template.dependencies) {
        delete template.dependencies
      }
    }

    return Promise.resolve()
  }

  /**
   * @private
   */
  removeIfExists(templateFilePath) {
    if (global.System.has(`${templateFilePath}`)) {
      log.tmplLoader('Deleting existing template...')
      global.System.delete(`${templateFilePath}`)
    }

    if (global.System.has(`file://${templateFilePath}`)) {
      log.tmplLoader('Deleting existing template...')
      global.System.delete(`file://${templateFilePath}`)
    }
  }

  /**
   * @private
   */
  setupModule(templateConfig, TemplateModule, config) {
    // TODO: insert proper check if template is really a react component
    let deps
    let keys = []
    const normalizedPath = templateConfig.templateFolderPath

    if (!templateConfig.isAlreadyLoaded) {
      deps = Object.assign({}, TemplateModule.dependencies)
      keys = Object.keys(deps)
    }

    return Promise.all([
      TemplateModule,
      ...keys.map(moduleName => this.loadModuleFile(
        moduleName,
        deps[moduleName],
        normalizedPath,
        config
      )
      ),
    ])
  }

  disposeModule(templateConfig, TemplateModule) {
    let deps
    let keys = []
    const normalizedPath = templateConfig.templateFolderPath

    if (!templateConfig.isAlreadyLoaded) {
      deps = Object.assign({}, TemplateModule.dependencies)
      keys = Object.keys(deps)
    }

    return Promise.all([
      ...keys.map(moduleName => this.removeModuleFile(moduleName, deps[moduleName], normalizedPath)
      ),
    ])
  }

  /**
   * @private
   */
  loadModuleFile(moduleName, modulePath, normalizedPath, config) {
    // language files need special treatment
    if (moduleName === 'lang') {
      return this.loadLanguageModuleFile(
        moduleName,
        modulePath,
        normalizedPath,
        config
      )
    }

    if (moduleName === 'styles') {
      return this.loadStylesheet(moduleName, modulePath, normalizedPath)
    }

    const normalizedDep = this.cleanPath(normalizedPath, modulePath)

    log.tmplLoader(`LOADING dependency ${normalizedDep}`)

    if (!config.isProduction && global.System.has(normalizedDep)) {
      global.System.delete(normalizedDep)
    }

    return global.System.import(
      versionizePath(this.cleanPath(normalizedDep))
    ).then(mod => ({
      name: moduleName,
      mod,
    }))
  }

  /**
   * @private
   */
  removeModuleFile(moduleName, modulePath, normalizedPath) {
    // language files need special treatment
    if (moduleName === 'lang') {
      modulePath = this.cleanPath(modulePath, `${this.language}.js`)
    }

    if (moduleName === 'styles') {
      // TODO: This will need some work. Stylesheets are not loaded here, but
      // only when they are inserted into the DOM while module activation.
      // Probably the best solution would be to append a query string
      // (timestamp) to them so the activator knows they have to be replaced.
      return
    }

    const normalizedDep = `${normalizedPath}${modulePath}`

    if (global.System.has(normalizedDep)) {
      global.System.delete(normalizedDep)
    }
  }

  /**
   * @private
   */
  loadLanguageModuleFile(moduleName, modulePath, normalizedPath, config) {
    // If the language spec is actually just an inline object, return that
    // object
    if (Object.prototype.toString.call(modulePath) === '[object Object]') {
      return Promise.resolve({
        name: moduleName,
        mod: modulePath,
      })
    }

    modulePath = this.cleanPath(modulePath, `${this.language}.js`)

    log.tmplLoader(`LOADING lang ${modulePath}`)

    const normalizedDep = `${normalizedPath}${modulePath}`
    if (!config.isProduction && global.System.has(normalizedDep)) {
      global.System.delete(normalizedDep)
    }

    return global.System.import(this.cleanPath(normalizedDep))
      .then(mod => ({
        name: moduleName,
        mod,
      }))
      .catch(() => {
        log.tmplLoaderWarn(`Error loading ${moduleName}.`)

        return {
          name: moduleName,
          mod: {},
        }
      })
  }

  /**
   * @private
   */
  loadStylesheet(moduleName, stylesheet, templateFolderPath) {
    const stylePath = this.cleanPath(templateFolderPath, stylesheet)

    log.tmplLoader(`LOADING stylesheet ${stylePath}`)

    return {
      name: moduleName,
      mod: stylePath,
    }
  }

  /**
   * @private
   */
  getPaths(shortcut) {
    const templateFolderPath = this.normalizePath(
      `${this.templateBasePath}/${shortcut}`
    )
    const templateFilePath = this.cleanPath(
      `${templateFolderPath}/template.js`
    )

    return {
      templateFolderPath,
      templateFilePath,
    }
  }

  /**
   * @private
   */
  normalizePath(path) {
    if (path.indexOf('http') === 0) {
      return path
    }

    if (isNode) {
      // in case the path is absolute, use that path,
      if (!/^\.?\//.test(path)) {
        path = `./${path}/`
      }

      // otherwise make it relative
      return path.replace(/\/+/g, '/')
    }

    return `${window.location.origin}${path}/`
  }

  /**
   * @private
   */
  cleanPath(...paths) {
    const path = paths
      .join('/')

      // replace multiple consecutive forward slashes with a
      // single one, unless there is a : right before them
      // (e.g. http:)
      .replace(/([^:]\/)\/+/g, '$1')

    // if we are in node env
    if (typeof window === 'undefined') {
      // eslint-disable-next-line global-require
      return require('path').resolve(path)
    }

    // resolve /../
    return resolve(path)
  }

  /**
   * @private
   */
  isModuleValid(template) {
    return (
      !!template.module
      && !(template.module instanceof Error)
      && !!template.module.getTemplate
    )
  }

  /**
   * @private
   */
  gatherDependencies(dependencies) {
    return dependencies.reduce((memo, spec) => {
      memo[spec.name] = spec.mod
      return memo
    }, {})
  }
}

export function getInstance(collection = null, lang, templateBasePath) {
  return new TemplateLoader(collection || [], lang, templateBasePath)
}
