export default function editableDecorator(target) {

  const provider = target.prototype

  if (!provider.isInStatus) {
    // eslint-disable-next-line max-len
    throw new Error('The `editableDecorator` requires the method `isInStatus` to exist on the target!')
  }

  Object.defineProperty(provider, 'isEditable', {

    // an article is editable if and only if the following
    // criteria are met
    //
    // it IS NOT locked
    // it HAS NO versionNumber
    // is IS NOT deleted
    get: function isEditable() {
      return this.isInEditableStatus
        && !this.isLocked
        && !this.isVersion
        && !this.isDeleted
    }
  })


  /**
   * @private
   */
  Object.defineProperty(provider, 'isInEditableStatus', {
    get: function isInEditableStatus() {
      return this.isInStatus('private,draft,released')
      && this.isInEditableVersion
    }
  })

  Object.defineProperty(provider, 'isInEditableVersion', {
    get: function isInEditableVersion() {
      return !this.isVersion
    }
  })

}
