import { intersection } from 'lodash'
import { filterCustomerAllowedButtons } from '../../shared/utils'
import { editor as editorConfig } from '../../config'

export const shortcuts = {
  bold: ['metaKey', 'B'],
  underline: ['metaKey', 'U'],
  italic: ['metaKey', 'I'],
  strikeThrough: ['altKey', 'shiftKey', 'S'],
  removeFormat: ['altKey', 'shiftKey', 'A'],
  linkPrompt: ['metaKey', '!shiftKey', 'K'],
  unlink: ['metaKey', 'shiftKey', 'K']
}

export const defaultAllowedTags = {

  // elements
  br: {},

  'gu-note': true,
  'gu-flag': true,
  'gu-correct': true,
  // strong: {},

  // span: {
  //   class: 'is-new', /* function (attrValue) {
  //     if (attrValue === 'is-new') {
  //     return attrValue;
  //   }
  // }*/
  //   style: false
  // },

  // commands
  redo: true,
  undo: true,

  note: true,
  noteCollapseToggle: true,
  noteCollapseToggleAll: true,

  flag: true,
  flagCollapseToggle: true,
  flagCollapseToggleAll: true,

  correct: true,
  correctCollapseToggle: true,
  correctCollapseToggleAll: true,

  cleanup: true,
  removeLinebreak: true,
  removeFormat: true,

  blockquote: {},

// p-tags are required in block elements
// p : {}
}

const nestedBlockCommands = [
  'insertTable'
]

const blockCommands = [
  'insertTable',
  'insertOrderedList',
  'insertUnorderedList',
  'h1',
  'h2',
  'h3',
  'blockquote'
]

export const formatsMap = {
  em: {
    class: true,
    style: true
  },
  underline: {
    u: {}
  },
  bold: {
    b: {}
  },
  italic: {
    i: {}
  },
  strikeThrough: {
    strike: {}
  },
  linkPrompt: {
    // cmdName: 'createLink',
    a: {
      class: true,
      href: true,
      alt: true,
      title: true,
      target: true,
      rel: true
    }
  },
  // This entry will be merged with the customer config later.
  // That will result in "span" beeing the only key left here.
  // Therefore all custom buttons which commands add a span, will have a verify call to this function (from HTMLJanitor)
  foreColor: {
    span: {
      class: () => {
        return true
      },
      style: (attrValue) => {
        // allow text color
        const match = attrValue.match(/^(?:;?\s*)?color\s*:\s*[^;$]+(?:;|$)/)
        return match && match.length
          ? match[0]
          : null
      }
    }
  },
  fontStyle: {
    span: {
      class: () => {
        // Since the value is defined in .geneva.config - assume true
        return true
      },
      style: (attrValue) => {
        // allow text color
        const match = attrValue.match(/^(?:;?\s*)?color\s*:\s*[^;$]+(?:;|$)/)
        return match && match.length
          ? match[0]
          : null
      }
    }
  },
  superscript: {
    sup: {}
  },
  subscript: {
    sub: {}
  },
  insertOrderedList: {
    ol: {},
    li: {}
  },
  insertUnorderedList: {
    ul: {},
    li: {}
  },
  h1: {
    h1: {}
  },
  h2: {
    h2: {}
  },
  h3: {
    h3: {}
  },
  blockquote: {
    blockquote: {}
  },
  insertTable: {
    table: {
      style: true,
      class: true
    },
    thead: {},
    tbody: {},
    tfoot: {},
    colgroup: {},
    col: {},
    tr: {},
    td: {
      style: true
    },
    th: {
      style: true
    },
    div: {
      class: true,
      style: true,
      allowNesting: true,
      draggable: true
    }
  },
  // TODO: automatically merge with definition atop in forecolor
  // footnote: {
  //   span: {
  //     class: true
  //   }
  // },
  note: {
    'gu-note': true,
  },
  flag: {
    'gu-flag': true,
  },
  correct: {
    'gu-correct': true,
  }
}

// this is a very ugly hack, but it's necessary. Otherwise if footnote
// is allowed but foreColor not, footnotes would be removed again
formatsMap.footnote = formatsMap.foreColor


export function getAllowedTags(buttons) {
  return filterCustomerAllowedButtons(buttons, editorConfig)
    .reduce((memo, button) => {
      const spec = formatsMap[button]
      return {
        ...memo,
        ...spec
      }
    }, Object.assign({}, defaultAllowedTags))
}

export function getDefaultAllowedButtons() {
  return Object.keys(defaultAllowedTags)
    .reduce((memo, key) => {
      memo[key] = true
      return memo
    }, {})
}

export function commandRequiresBlocks(buttons) {
  return intersection(buttons, blockCommands)
}

export function commandRequiresNestedBlocks(buttons) {
  return intersection(buttons, nestedBlockCommands)
}
