import { createConfirmation } from 'react-confirm'
import { getPageTemplates, i18n } from '../../../shared/utils'
import PageSettingsDialog from '../../components/dialogs/PageSettings'

const pageSettings = createConfirmation(PageSettingsDialog)


export default function (confirmation, options = {}) {

  const pageTemplates = getPageTemplates(confirmation.page.channelShortcut)
  const pageTemplate = pageTemplates.find(
    template => template.id === confirmation.page.templateId)

  confirmation.publishedUrl = i18n(confirmation.page, 'url')

  if (typeof confirmation !== 'string') {
    options = { ...confirmation, pageTemplate }
    confirmation = ''
  }
  return pageSettings({ confirmation, options })
}
