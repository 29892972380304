import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { confirmable } from 'react-confirm'
import { autobind } from 'core-decorators'
import { formatMessage, FormattedMessage } from '../../../translations'

import { Dialog } from '../../../shared/components/Dialog'
import GenevaButton from '../../../ui/components/GenevaButton'
import {
  convertDateToUserLocal
} from '../../../shared/DateConverter'

const css = typeof window === 'undefined' ? {} : require('../../styles.scss')

@confirmable
class PublishPage extends Component {

  static propTypes = {
    show: PropTypes.bool,            // indicates if the dialog is shown or not.
    proceed: PropTypes.func,         // call to close the dialog with promise resolved.
    cancel: PropTypes.func,          // call to close the dialog with promise rejected.
    dismiss: PropTypes.func,         // call to only close the dialog.
    confirmation: PropTypes.string,  // arguments of your confirm function
    options: PropTypes.shape({
      publishFollowing: PropTypes.bool,
      unpublishFollowing: PropTypes.bool,
      publishAt: PropTypes.string,
      unpublishAt: PropTypes.string,
      mailTo: PropTypes.string,            // used for publishing and unpublishing
    })
  }

  constructor(props) {
    super(props)
    this.state = {
      publishFollowing: props.options.publishFollowing,
      unpublishFollowing: props.options.unpublishFollowing,

      jobType: props.options.jobType || 'publish',
      sendMail: !!props.options.mailTo,
      mailTo: props.options.mailTo,
      mailToDefault: props.options.mailTo,
      isValidMail: true,
    }
  }

  @autobind
  getFocus() {
    return this.focus
  }

  @autobind
  handleRef(el) {
    if (el !== null) {
      this.focus = el
    }
  }

  @autobind
  handleMailToChange({ target }) {

    this.setState({
      validationMessage: target.validationMessage,
      [target.id]: target.value,
      isValidMail: target.validity.valid && target.value !== ''
    })
  }

  @autobind
  handleSendMailChange({ target }) {

    this.setState({
      [target.id]: target.checked
    })
  }

  @autobind
  handleConfirm() {

    return (
      this.props.proceed({
        [this.state.jobType]: {
          mailTo: this.state.sendMail ? this.state.mailTo : null,
        },
        publishAction: this.state.jobType
      })
    )
  }

  @autobind
  renderFooter() {

    return (
      <div className="grid-content text-right">
        <GenevaButton
          id="cancel"
          type="button"
          className="small button"
          onClick={this.props.dismiss}
        >
          <FormattedMessage id="page-publish-dialog.cancel" />
        </GenevaButton>
        <GenevaButton
          id="confirm"
          type="submit"
          className="small primary button"
          onClick={this.handleConfirm}
          ref={this.handleRef}
          disabled={
          // When the checkbox is checked, there has to be a value in the related input field.
            // If mailTo is checked, the mail has to be valid. Otherwise it doesn't matter.
            !(this.state.sendMail ? this.state.isValidMail : true)
          }
        >
          <FormattedMessage id="page-publish-dialog.confirm" />
        </GenevaButton>
      </div>
    )
  }

  render() {

    const {
      show,
    } = this.props

    // define variables if dialog is in publish mode
    let title = formatMessage({ id: 'page-publish-dialog.title' })
    let warning = this.props.options.page.publishAt
    let warningMessage = 'page-publish-dialog.warning'

    // update variables if dialog is in offline mode
    if (this.state.jobType === 'unpublish') {
      title = formatMessage({ id: 'page-unpublish-dialog.title' })
      warning = this.props.options.page.unpublishAt
      warningMessage = 'page-unpublish-dialog.warning'
    }

    return (
      <Dialog
        className={css.publishPageDialog}
        overlayClassName={css.tableDialogOverlay}
        isOpen={show}
        onAfterOpen={this.handleAfterOpen}
        onRequestClose={this.handleRequestClose}
        title={title}
        renderFooter={this.renderFooter}
        focusEl={this.getFocus}
      >
        <div className="vertical grid-block">
          <div className="warning">
            { warning
              ? (<FormattedMessage
                id={warningMessage}
                values={{
                  date: convertDateToUserLocal(warning).format('lll')
                }}
              />)
              : null
            }
          </div>
          <div className="v-align content-flex-start label-wrap">
            <input
              id="sendMail"
              type="checkbox"
              checked={this.state.sendMail}
              onChange={this.handleSendMailChange}
            />
            <label htmlFor="sendMail">
              <FormattedMessage
                id="page-publish-dialog.email"
              />
            </label>
          </div>
          <input
            id="mailTo"
            type="email"
            multiple
            className="mail"
            onChange={this.handleMailToChange}
            disabled={!this.state.sendMail}
            defaultValue={this.state.mailToDefault}
          />
          <div className="invalid-input message message-error">
            {
            // If the sendMail checkbox is checked and there is no entry
            // or the entered mail is invalid
              !this.state.isValidMail && this.state.sendMail
                ? this.state.validationMessage
                : null
            }
          </div>
        </div>

      </Dialog>
    )
  }

}

export default confirmable(PublishPage)
