import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { autobind } from 'core-decorators'
import classNames from 'classnames'

import Icon from '../../icons'

import * as css from './styles.scss'

@observer
export default class ScrollTrigger extends Component {
  static propTypes = {
    dragState: PropTypes.object.isRequired,
    mouseEventMode: PropTypes.bool.isRequired,
    onDragOver: PropTypes.func.isRequired,
    position: PropTypes.string.isRequired,
  };

  isPosition(position) {
    return this.props.position === position
  }

  isVisable() {
    const {
      dragState: { isDragging, scrollProgress },
    } = this.props
    return (
      isDragging
      && scrollProgress !== -1
      && (this.isPosition('top') ? scrollProgress > 0 : scrollProgress < 1)
    )
  }

  getIcon() {
    return this.isPosition('top') ? 'up' : 'down'
  }

  @autobind
  handleDragOver(e) {
    if (this.props.dragState.isDragging) {
      if (e) {
        e.stopPropagation()
        e.preventDefault()
      }
      this.props.onDragOver({
        target: {
          value: this.props.position,
        },
      })
    }
  }

  @autobind
  handleMouseEnter() {
    if (!this.animationFrame) {
      const mouseover = () => {
        this.handleDragOver()
        this.animationFrame = requestAnimationFrame(mouseover)
      }
      this.animationFrame = requestAnimationFrame(mouseover)
    }
  }

  @autobind
  handleMouseLeave() {
    cancelAnimationFrame(this.animationFrame)
    delete this.animationFrame
  }

  render() {
    const visible = this.isVisable()

    const events = {}

    if (this.props.mouseEventMode) {
      events.onMouseEnter = this.handleMouseEnter
      events.onMouseLeave = this.handleMouseLeave
    }
    else {
      events.onDragOver = this.handleDragOver
    }

    return (
      <div
        className={classNames(
          css.scrollTrigger,
          this.isPosition('top')
            ? css.scrollTriggerTop
            : css.scrollTriggerBottom,
          { visible }
        )}
        {...events}
      >
        <Icon name={`ion-chevron-${this.getIcon()}`} />
      </div>
    )
  }
}
