import * as PropTypes from 'prop-types'

import React, { Component } from 'react'
import classNames from 'classnames'
import { autobind } from 'core-decorators'

import { getHTMLEventsFromProps } from '../../../shared/utils/events'
import { getDataAttributes } from '../../../shared/utils/attribs'
import { store as contextStore } from '../../../context'

/**
 * Implements the drag source contract. (or point)
 * This is a 'Slider' since it's not actually using the Drag and drop logic
 * Drag and Drop can't controll x and y movement, so this is more similar to a slider control
 */

class Positionable extends Component {
  static propTypes = {
    pid: PropTypes.string.isRequired,
    children: PropTypes.node,
    value: PropTypes.object,
    onChange: PropTypes.func,
    containerRef: PropTypes.object,
    renderCompleteDragView: PropTypes.func,

    className: PropTypes.string,
    style: PropTypes.object,
    limit: PropTypes.string,

    onDragEnd: PropTypes.func,
    onCanDrag: PropTypes.func,
    locked: PropTypes.bool,
  };

  constructor(props) {
    super(props)

    const { value } = props

    this.contextStore = contextStore

    this.isDragging = false
    this.positionableRef = null

    // used for slider movement (limited drag and drop)
    this.shiftX = null
    this.maxX = null
    this.shiftY = null
    this.maxY = null
    this.containerRef = null
    // Thumb is the term for a slider thumb, but it's whatever the draggable part is
    this.thumb = null

    this.state = {
      value: {
        left: (value && value.left) || this.DEFAULT_X_POS,
        top: (value && value.top) || this.DEFAULT_Y_POS,
      },
    }
  }

  componentWillReceiveProps(nextProps) {
    if ('value' in nextProps && nextProps.value) {
      this.setState({
        value: nextProps.value,
      })
    }
  }

  DEFAULT_X_POS = 0;

  DEFAULT_Y_POS = 0;

  // used to limit dragging. Hopefully comes from props, but uses the tree as a fallback
  @autobind
  setContainer(event) {
    if (this.props.containerRef) {
      this.containerRef = this.props.containerRef
    }
    else {
      if (event) {
        let target = event.currentTarget
        while (!target.className.includes('positionable-container') && target.id !== 'root') {
          target = target.parentNode
        }
        if (target.className.includes('positionable-container')) {
          this.containerRef = target
        }
        else {
          console.warn('no positionable container found')
        }
      }
      else {
        console.warn('no event to find the parent')
      }
    }
  }

  @autobind
  canDrag() {
    if (!this.props.locked) {
      if (this.props.onCanDrag) {
        return this.props.onCanDrag()
      }
      return true
    }
    return false
  }

  // Actual Slider the logic is based on
  // https://plnkr.co/edit/zYSBv9zIaLpa3rQr?p=preview&preview
  @autobind
  handleOnMouseDown(event) {
    if (this.canDrag()) {
      // This is an override of the handle drag start, todo: add this 'if' there for clearness
      event.preventDefault()
      this.dragging = true
      if (!this.thumb) {
        this.thumb = event.currentTarget
      }
      if (!this.containerRef) {
        this.setContainer(event)
      }
      if (!this.positionableRef) {
        this.positionableRef = document.getElementById(`${this.props.pid}-positionable`)
      }

      if (this.props.limit === 'horizontally') {
        this.shiftX = event.clientX - this.thumb.getBoundingClientRect().left
      }
      else {
        this.shiftY = event.clientY - this.thumb.getBoundingClientRect().top
      }

      // adding the listeners here confirms that the DOM is fully loaded and resolved
      this.containerRef.addEventListener('mousemove', this.handleOnMouseMove)
      document.addEventListener('mouseup', this.handleOnMouseUp)
    }
  }

  @autobind
  handleOnMouseMove(event) {
    if (this.dragging) {
      /** Horizontal dragging */
      if (this.props.limit === 'horizontally') {
        let newLeft = event.clientX - this.shiftX - this.containerRef.getBoundingClientRect().left

        // the pointer is out of slider => lock the thumb within the bounaries
        if (newLeft < 0) {
          newLeft = 0
        }
        const rightEdge = this.containerRef.offsetWidth - this.thumb.offsetWidth
        if (newLeft > rightEdge) {
          newLeft = rightEdge
        }

        this.positionableRef.style.left = `${newLeft}px`
        this.maxX = rightEdge
      }
      /** Vertical dragging */
      else if (this.props.limit === 'vertically') {
        let newTop = event.clientY - this.shiftY - this.containerRef.getBoundingClientRect().top

        // the pointer is out of slider => lock the thumb within the bounaries
        if (newTop < 0) {
          newTop = 0
        }
        const bottomEdge = this.containerRef.offsetHeight - this.thumb.offsetHeight
        if (newTop > bottomEdge) {
          newTop = bottomEdge
        }

        this.positionableRef.style.top = `${newTop}px`
        this.maxY  = bottomEdge
      }
      else {
        console.log('drag limiting not supported', this.props.limit)
      }
    }
  }

  @autobind
  handleOnMouseUp(event) {
    this.dragging = false
    this.containerRef.removeEventListener('mousemove', this.handleOnMouseMove)
    document.removeEventListener('mouseup', this.handleOnMouseUp)

    const eventHandlers = getHTMLEventsFromProps(this.props)
    if (eventHandlers.onDragEnd) {
      eventHandlers.onDragEnd(
        event,
        {
          type: 'dragend',
          limit: this.props.limit,
          shiftX: this.shiftX,
          shiftY: this.shiftY,
          maxX: this.maxX,
          maxY: this.maxY,
        }
      )
    }
  }

  // Since the drag element could be on a special handle,
  // then we attach the handlers more dynamically
  connectDragHandlers(rendererElement) {
    return (
      <div
        {...rendererElement.props}
        onMouseDown={this.handleOnMouseDown}
        draggable={this.canDrag()}
      >
        {rendererElement.props.children}
      </div>
    )
  }

  render() {
    const {
      style,
      renderPositionableHandle,
    } = this.props
    const { value } = this.state

    const eventHandlers = getHTMLEventsFromProps(this.props)
    const dataAttributes = getDataAttributes(this.props)

    const positionableProps = {
      className: classNames(this.props.className, 'positionable-positionable'),
      style: {
        ...style,
        position: 'absolute',
        left: `${value.left}px`,
        top: `${value.top}px`,
        opacity: this.isDragging ? 0.5 : style.opacity || 1,
      },
      'data-react-top': value.top,
      'data-react-left': value.left,
      id: `${this.props.pid}-positionable`,
      ...eventHandlers,
      ...dataAttributes,
    }

    // If we defined a custom drag handler element that alone should allow
    // dragging, we need to render it before the children and enable it
    // as drag source.
    if (renderPositionableHandle) {
      const draggable = (
        <div {...positionableProps}>
          {this.connectDragHandlers(renderPositionableHandle())}
          {this.props.children}
        </div>
      )

      return draggable
    }

    // If we use no custom drag handler element connect the whole
    // element as drag preview.
    return (
      this.connectDragHandlers(
        <div {...positionableProps}>{this.props.children}</div>
      )
    )
  }
}

export { Positionable as default }
