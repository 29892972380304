import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { confirmable } from 'react-confirm'
import { autobind } from 'core-decorators'
import moment from 'moment'
import DateTime from 'react-datetime'
import classNames from 'classnames'
import { formatMessage, FormattedMessage } from '../../../translations'
import { Dialog } from '../../../shared/components/Dialog'
import GenevaButton from '../../../ui/components/GenevaButton'
import {
  convertDateToUserLocal,
  convertDateToSystemLocal
} from '../../../shared/DateConverter'
import { store as uiStore } from '../../../ui'

const css = typeof window === 'undefined' ? {} : require('../../styles.scss')

@confirmable
class PagePlanning extends Component {

  static propTypes = {
    show: PropTypes.bool,            // indicates if the dialog is shown or not.
    proceed: PropTypes.func,         // call to close the dialog with promise resolved.
    cancel: PropTypes.func,          // call to close the dialog with promise rejected.
    dismiss: PropTypes.func,         // call to only close the dialog.
    confirmation: PropTypes.string,  // arguments of your confirm function
    options: PropTypes.shape({
      publishFollowing: PropTypes.bool,
      unpublishFollowing: PropTypes.bool,
      publishAt: PropTypes.string,
      unpublishAt: PropTypes.string,
      mailTo: PropTypes.string,            // used for publishing and unpublishing
    })
  }

  constructor(props) {
    super(props)
    this.state = {
      publishFollowing: props.options.publishFollowing,
      unpublishFollowing: props.options.unpublishFollowing,

      publishAtTimeActive: !!props.options.publishAt,
      unpublishAtTimeActive: !!props.options.unpublishAt,

      publishAt: props.options.publishAt
        ? convertDateToUserLocal(props.options.publishAt)
        : moment(),
      unpublishAt: props.options.unpublishAt
        ? convertDateToUserLocal(props.options.unpublishAt)
        : moment(),

      sendMail: !!props.options.mailTo,
      mailTo: props.options.mailTo,
      mailToDefault: props.options.mailTo,
      isValidMail: true,
      isPubValidDate: true,
      isUnpubValidDate: true,
    }
  }

  // updates moment objects date and returns it
  updateDate(current, date) {
    current.date(date.get('date'))
    current.month(date.get('month'))
    current.year(date.get('year'))

    return current
  }

  // update moment objects time and returns it
  updateTime(current, date) {
    current.second(date.get('second'))
    current.minute(date.get('minute'))
    current.hour(date.get('hour'))

    return current
  }

  // if date invalid, set state type to false to show message
  handleInvalidDate(date, type) {
    if (typeof date === 'string') {
      // invalid date
      this.setState({
        [type]: false
      })
      return true
    }
    return false
  }

  @autobind
  getFocus() {
    return this.focus
  }

  @autobind
  handleRef(el) {
    if (el !== null) {
      this.focus = el
    }
  }

  @autobind
  handleMailToChange({ target }) {
    this.setState({
      validationMessage: target.validationMessage,
      [target.id]: target.value,
      isValidMail: target.validity.valid && target.value !== ''
    })
  }

  // ============
  // DateTime component is not passing an id -
  // therefore we need different functions for every DateTime component
  // ============
  @autobind
  handlePublishDateChange(date) {
    if (this.handleInvalidDate(date, 'isPubValidDate')) {
      return
    }

    this.setState({
      isPubValidDate: true,
      publishAt: this.updateDate(this.state.publishAt, date)
    })
  }

  @autobind
  handlePublishTimeChange(date) {
    if (this.handleInvalidDate(date, 'isPubValidDate')) {
      return
    }

    this.setState({
      isPubValidDate: true,
      publishAt: this.updateTime(this.state.publishAt, date)
    })
  }

  @autobind
  handleUnpublishDateChange(date) {
    if (this.handleInvalidDate(date, 'isUnpubValidDate')) {
      return
    }

    this.setState({
      isUnpubValidDate: true,
      unpublishAt: this.updateDate(this.state.unpublishAt, date)
    })
  }

  @autobind
  handleUnpublishTimeChange(date) {
    if (this.handleInvalidDate(date, 'isUnpubValidDate')) {
      return
    }

    this.setState({
      isUnpubValidDate: true,
      unpublishAt: this.updateTime(this.state.unpublishAt, date)
    })
  }

  @autobind
  handleCheckBoxChanged({ target }) {
    this.setState({
      [target.id]: target.checked,
    })
  }

  @autobind
  handleCommit(e) {
    if (e && e.preventDefault) {
      e.preventDefault()
    }

    this.handleConfirm()
  }

  @autobind
  handleConfirm() {
    return (
      this.props.proceed({
        publish: {
          mailTo: this.state.sendMail ? this.state.mailTo : null,
          publishFollowing: this.state.publishFollowing,
          publishAt: this.state.publishAtTimeActive
            ? convertDateToSystemLocal(this.state.publishAt, { simplified: true })
            : null
        },
        unpublish: {
          mailTo: this.state.sendMail ? this.state.mailTo : null,
          unpublishFollowing: this.state.unpublishFollowing,
          unpublishAt: this.state.unpublishAtTimeActive
            ? convertDateToSystemLocal(this.state.unpublishAt, { simplified: true })
            : null
        }
      })
    )
  }

  @autobind
  renderFooter() {

    return (
      <div className="grid-content text-right">
        <GenevaButton
          id="cancel"
          type="button"
          className="small button"
          onClick={this.props.dismiss}
        >
          <FormattedMessage id="page-publish-dialog.cancel" />
        </GenevaButton>
        <GenevaButton
          id="confirm"
          type="submit"
          className="small primary button"
          onClick={this.handleCommit}
          ref={this.handleRef}
          disabled={
            // When the checkbox is checked, there has to be a value in the related input field.
            // If mailTo is checked, the mail has to be valid. Otherwise it doesn't matter.
            !((this.state.publishAtTimeActive ? this.state.isPubValidDate : true)
            && (this.state.unpublishAtTimeActive ? this.state.isUnpubValidDate : true)
            && (this.state.sendMail ? this.state.isValidMail : true))
          }
        >
          <FormattedMessage id="page-publish-dialog.confirm" />
        </GenevaButton>
      </div>
    )
  }

  render() {

    const {
      show,
      options: { hidePublishAt }
    } = this.props

    const yesterday = DateTime.moment().subtract(1, 'day')
    const valid = (current) => {
      return current.isAfter(yesterday) && current.isValid()
    }

    const unpublishIsValid = (current) => {
      return current.isAfter(yesterday) && current.isValid()
    }

    return (
      <Dialog
        className={css.publishPageDialog}
        overlayClassName={css.tableDialogOverlay}
        isOpen={show}
        onAfterOpen={this.handleAfterOpen}
        onRequestClose={this.handleRequestClose}
        title={formatMessage({ id: 'page-planning-dialog.title' })}
        renderFooter={this.renderFooter}
        focusEl={this.getFocus}
      >
        <div className="vertical grid-block">
          <div className={classNames({ hidden: hidePublishAt })}>
            <div className="v-align content-flex-start label-wrap">
              <input
                id="publishAtTimeActive"
                type="checkbox"
                checked={this.state.publishAtTimeActive}
                onChange={this.handleCheckBoxChanged}
              />
              <label htmlFor="publishAtTimeActive">
                <FormattedMessage
                  id="page-planning-dialog.publish-time"
                />
              </label>
            </div>
            <div className="date-time">
              <label htmlFor="dateDpub">
                <FormattedMessage
                  id="page-planning-dialog.date"
                />
                <DateTime
                  className="date-picker"
                  defaultValue={
                    !!this.state.publishAt
                      ? this.state.publishAt
                      : moment()
                  }
                  id="dateDpub"
                  isValidDate={valid}
                  inputProps={{
                    disabled: !this.state.publishAtTimeActive
                  }}
                  locale={uiStore.language}
                  onChange={this.handlePublishDateChange}
                  timeFormat={false}
                />
              </label>

              <label htmlFor="dateTpub" className="time-label">

                <FormattedMessage
                  id="page-planning-dialog.time"
                />
                <DateTime
                  dateFormat={false}
                  defaultValue={
                    !!this.state.publishAt
                      ? this.state.publishAt
                      : moment()
                  }
                  id="dateTpub"
                  className="time-picker"
                  isValidDate={valid}
                  inputProps={{
                    disabled: !this.state.publishAtTimeActive
                  }}
                  locale={uiStore.language}
                  onChange={this.handlePublishTimeChange}
                />
              </label>

            </div>
            {
              !this.state.isPubValidDate
                ? <div className="invalid-input message message-error cancel-publish date-error">
                  <FormattedMessage id="error.invalid-date" />
                </div>
                : null
            }
            <hr />
          </div>
          <div className={classNames({ hidden: hidePublishAt })}>
            <div className="v-align content-flex-start label-wrap">
              <input
                id="unpublishAtTimeActive"
                type="checkbox"
                checked={this.state.unpublishAtTimeActive}
                onChange={this.handleCheckBoxChanged}
              />
              <label htmlFor="unpublishAtTimeActive">
                <FormattedMessage
                  id="page-planning-dialog.unpublish-time"
                />
              </label>
            </div>
            <div className="date-time">
              <label htmlFor="dateDunpub">
                <FormattedMessage
                  id="page-planning-dialog.date"
                />
                <DateTime
                  className="date-picker"
                  defaultValue={
                    !!this.state.unpublishAt
                      ? this.state.unpublishAt
                      : moment()
                  }
                  id="dateDunpub"
                  isValidDate={unpublishIsValid}
                  inputProps={{
                    disabled: !this.state.unpublishAtTimeActive
                  }}
                  locale={uiStore.language}
                  onChange={this.handleUnpublishDateChange}
                  timeFormat={false}
                />
              </label>

              <label htmlFor="dateTunpub" className="time-label">
                <FormattedMessage
                  id="page-planning-dialog.time"
                />
                <DateTime
                  className="time-picker"
                  dateFormat={false}
                  defaultValue={
                    !!this.state.unpublishAt
                      ? this.state.unpublishAt
                      : moment()
                  }
                  id="dateTunpub"
                  isValidDate={unpublishIsValid}
                  inputProps={{
                    disabled: !this.state.unpublishAtTimeActive
                  }}
                  locale={uiStore.language}
                  onChange={this.handleUnpublishTimeChange}
                />
              </label>

            </div>
            {
              !this.state.isUnpubValidDate
                ? <div className="invalid-input message message-error cancel-publish date-error">
                  <FormattedMessage id="error.invalid-date" />
                </div>
                : null
            }
          </div>
          <hr />
          <div className="v-align content-flex-start label-wrap">
            <input
              id="sendMail"
              type="checkbox"
              checked={this.state.sendMail}
              onChange={this.handleCheckBoxChanged}
              disabled={!(this.state.unpublishAtTimeActive
                || this.state.publishAtTimeActive)}
            />
            <label htmlFor="sendMail">
              <FormattedMessage
                id="page-publish-dialog.email"
              />
            </label>
          </div>
          <input
            id="mailTo"
            type="email"
            multiple
            className="mail"
            onChange={this.handleMailToChange}
            disabled={!this.state.sendMail}
            defaultValue={this.state.mailToDefault}
          />
          <div className="invalid-input message message-error">
            {
            // If the sendMail checkbox is checked and there is no entry
            // or the entered mail is invalid
              !this.state.isValidMail && this.state.sendMail
                ? this.state.validationMessage
                : null
            }
          </div>
        </div>

      </Dialog>
    )
  }

}

export default PagePlanning
