import { createConfirmation } from 'react-confirm'
import { VERSION, API_VERSION } from '../../../config'
import AboutDialog from '../../components/dialogs/About'

const confirm = createConfirmation(AboutDialog)


export default function (confirmation: string|Object, options: Object = {}) {

  if (typeof confirmation !== 'string') {
    options = { ...confirmation, VERSION, API_VERSION }
    confirmation = ''
  }
  return confirm({ confirmation, options })
}
