import { differenceBy } from 'lodash'

export default function getChangeEvent(
  newData: Array,
  oldData: Array,
  allItems: Array
): Object {
  // in case an item was added:
  if (newData.length > oldData.length) {
    const diff = differenceBy(newData, oldData, 'key')
    const item = diff.map(itm => allItems.find(
      itm2 => itm.id === itm2.id && itm.type === itm2.type
    ))
    const index = allItems.indexOf(item)
    return {
      type: 'add',
      target: {
        item,
        index
      }
    }
  }
  // in case an item was removed
  if (newData.length < oldData.length) {
    const item = differenceBy(oldData, newData, 'key')
    return {
      type: 'remove',
      target: {
        item
      }
    }

  }
  // only bother if there is a length
  if (newData.length) {

    // TODO an item was replaced
    // eslint-disable-next-line max-len
    log.rendererWarn(`ContextConnectedContentRenderer#handleContentChange() was called with an item array of the same length as the cached one. This may indicate that an article in this array was replaced inplace or that the array was resorted. Here are the Articles:

New:    ${newData.map(item => item.key).join('; ')}
Cached: ${oldData.map(item => item.key).join('; ')}

THIS NEEDS IMPLEMENTATION!

    `)

    return {
      type: 'ident',
    }

  }

  return {}
}
