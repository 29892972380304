import { createConfirmation } from 'react-confirm'
import { store as article } from '../../../article'
import { store as ui } from '../../../ui'
import SelectArticleDialog from '../../components/dialogs/SelectArticle'

const selectArticle = createConfirmation(SelectArticleDialog)


export default function (
  confirmation,
  options = {}
) {

  if (typeof confirmation !== 'string') {
    options = { ...confirmation, article, ui }
    confirmation = ''
  }
  return selectArticle({ confirmation, options })
}
