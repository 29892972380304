import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Link } from 'react-router'
import { FormattedMessage } from '../../translations'

import Icon from '../../shared/icons'

const css = /* typeof window === 'undefined' ? {} : */ require('../styles.scss')

export default function SidebarHeader(props) {

  const { tool, articleBaseLink } = props

  return (<div className={classNames(
    css.toolsPanel,
    css.versionTab,
  )}>
    <h4 className={classNames(css.toolbarHeader, 'versioning-tools', tool.link === 'censhare' ? css.censhareSidebar : '')}>
      <Icon name={`ion-${tool.icon}`} />
      <FormattedMessage id={tool.title} />
      <Link className="float-right" to={`${articleBaseLink}/edit`}>
        <Icon name="ion-ios-arrow-down" />
      </Link>
    </h4>
  </div>)
}

SidebarHeader.propTypes = {
  tool: PropTypes.object.isRequired,
  articleBaseLink: PropTypes.string.isRequired,
}
