export default function () {
  return function (scribe) {
    scribe.transactionManager.runAsync = function (transaction) {
      this.start()
      // If there is an error, don't prevent the transaction from ending.
      if (transaction) {
        var complete = function () {
          this.end()
        }.bind(this)

        transaction(complete)
      }
    }
  }
}
