import React from 'react'
import { FormattedHTMLMessage } from '../../translations'
import logRenderingError from '../template/logRenderingError'
import ContentErrorBox from './ContentErrorBox'
import { deepGet } from '../obj'

export default function TemplateErrorBox({ error, template, env }) {

  logRenderingError(error, { template, env })

  if (env.CM || env.PM) {

    if (error.message.indexOf('404 NotFound') !== -1) {
      let url = ''
      try {
        url = error.message.match(/(http[^\s]+)/g)[1]
      }
      catch (ex) { /* noop **/ }
      error.id = 'error.template-file-not-found'
      error.values = { url }
    }

    error.templateName = deepGet(template, 'shortcut', 'n.a.')

    return (<ContentErrorBox error={error}>
      <div className="pm-info">
        <FormattedHTMLMessage
          id="error.template-rendering-error"
          values={{ templateName: error.templateName }}
        />
      </div>
    </ContentErrorBox>)
  }

  return null

}
