import * as PropTypes from 'prop-types'
import React from 'react'
import { withRouter } from 'react-router'

import CopyPageCommand from '../../shared/commands/copy-page'
import EditItemCommand from '../../shared/commands/EditItemCommand.js'

import createParentPropsInjector from '../../shared/utils/create-parent-props-injector'
import commandConnector from '../../shared/lib/command'

import CopyManagerLocalPanel from '../components/CopyManagerLocalPanel'

export default function connectCopyManagerLocalPanelToCommands(
  parentProps
) {
  const injectParentProps = createParentPropsInjector(parentProps)

  // Connector function
  function CopyManagerLocalPanelToCommandsConnector({ ...rest }) {
    return <CopyManagerLocalPanel {...rest} />
  }

  // Component props
  CopyManagerLocalPanelToCommandsConnector.propTypes = {
    router: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    projectStore: PropTypes.object,
    pageStore: PropTypes.object,
    updateExternalDragNode: PropTypes.func,
    externalDragNode: PropTypes.object,
    externalDragNodeKeys: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
    ]),
    originTree: PropTypes.string,
  }

  // Inject commands
  return commandConnector({
    CopyPageCommand,
    EditItemCommand,
  })(
    withRouter(injectParentProps(CopyManagerLocalPanelToCommandsConnector))
  )
}
