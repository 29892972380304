import * as PropTypes from 'prop-types'
import React from 'react'
import { formatMessage, FormattedMessage } from '../../../translations'

const TelLinkForm = ({
  onInputChanged,
  telNumber,
}) => {
  return (
    <div className="grid-content">
      <div className="grid-block vertical">
        <label htmlFor="link-tel-number">
          <FormattedMessage id="link-dialog.tel-number" />
          <input
            type="tel"
            name="telNumber"
            id="link-tel-number"
            placeholder={formatMessage({
              id: 'link-dialog.placeholder-tel-number',
            })}
            value={telNumber}
            onChange={onInputChanged}
          />
        </label>
      </div>
    </div>
  )
}

TelLinkForm.validate = (value) => {
  if (typeof value !== 'string') {
    value = value.telNumber
  }
  return !!value
}

TelLinkForm.propTypes = {
  onInputChanged: PropTypes.func,
  telNumber: PropTypes.string,
}

export default TelLinkForm
