import * as PropTypes from 'prop-types'
import React from 'react'
import { observer } from 'mobx-react'

import getTemplateConnectionData from '../../shared/template/getTemplateConnectionData'
import wrapInErrorHandler from '../../shared/template/wrapInErrorHandler'

export default function connectTemplateToEnv(TargetTemplate, parentProps) {
  wrapInErrorHandler(TargetTemplate, parentProps)

  const EnvConnectedPageTemplateWrapper = (props) => {
    return (
      <TargetTemplate
        {...getTemplateConnectionData('base', 'page', parentProps, props)}
      />
    )
  }

  EnvConnectedPageTemplateWrapper.propTypes = {
    page: PropTypes.object,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
  }

  return observer(EnvConnectedPageTemplateWrapper)
}
