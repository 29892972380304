import PropTypes from 'prop-types'
import React, { Component } from 'react'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import { formatMessage, FormattedMessage } from '../translations'
import GenevaButton from '../ui/components/GenevaButton'
import { authStore } from './reducers'

const css = typeof window === 'undefined' ? {} : require('./styles.scss')


@observer
export default class LoginForm extends Component {

  static propTypes = {
    auth: PropTypes.object.isRequired,
  }

  constructor() {

    super()

    this.state = {
      login: '',
      password: ''
    }

    this.handleUsernameChanged = this.handleUsernameChanged.bind(this)
    this.handlePasswordChanged = this.handlePasswordChanged.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)

  }


  handleSubmit(e) {

    e.preventDefault()

    authStore.login(this.state)

  }

  handlePasswordChanged(e) {

    this.setState({
      password: e.target.value
    })

  }

  handleUsernameChanged(e) {

    this.setState({
      login: e.target.value
    })

  }

  render() {

    const { login, password } = this.state
    const { auth } = this.props

    return (
      <div
        className={classNames(
          'grid-block',
          'v-align',
          'align-center',
          css.loginMain
        )}
        id="loginForm"
        style={{
          backgroundImage: 'url(/images/bg-blue.svg)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center bottom'
        }}
      >
        <div className="grid-block align-center">
          <form
            onSubmit={this.handleSubmit}
            className="grid-content"
            id="loginForm"
          >
            <img width="157" role="presentation" src="/images/GENEVA-30h-b.png" />
            <label>
              <FormattedMessage id="auth.username" />
              <input
                autoFocus
                type="text"
                value={login}
                onChange={this.handleUsernameChanged}
              />
            </label>
            <div>
              <label>
                <FormattedMessage id="auth.password" />
                <input
                  type="password"
                  value={password}
                  onChange={this.handlePasswordChanged}
                />
              </label>
              <GenevaButton
                className="small primary button"
                type="submit"
              >OK
              </GenevaButton>
            </div>
            {
              auth.error
                ? <div className="message message-error">{
                  auth.error.id
                    ? formatMessage({ id: auth.error.id })
                    : auth.error.message
                }
                </div>
                : ''
            }
          </form>
        </div>
      </div>

    )
  }

}
