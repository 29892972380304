import React from 'react'
import classNames from 'classnames'
import * as style from './styles.scss'
import Section from './Section'

export default
function FixedSection(props) {
  return (<Section
    {...props}
    className={classNames(style.navbarFixedSection, props.className)}
  />)
}
