
import PropTypes from 'prop-types'

import React from 'react'
import classNames from 'classnames'

import FormattedDateTime from '../../../shared/components/FormattedDateTime'
import { getUserNameById } from '../../../shared/utils'
import {
  convertDateToUserLocal
} from '../../../shared/DateConverter'

const css = /* typeof window === 'undefined' ? {} : */require('../styles.scss')

const VersionNote = props => (

  <div className={classNames('grid-block version-note', css.note)}>
    <div className="grid-block vertical">
      {getUserNameById(props.note.createdBy)}
      <FormattedDateTime
        value={convertDateToUserLocal(props.note.createdAt)}
      />

      {props.note.text}
    </div>
  </div>

)

VersionNote.propTypes = {
  note: PropTypes.shape({
    createdBy: PropTypes.string,
    createdAt: PropTypes.string,
    text: PropTypes.string
  }).isRequired
}

export default VersionNote
