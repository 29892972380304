import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import { autobind } from 'core-decorators'
import { withRouter } from 'react-router'
import { formatMessage } from '../../../translations'
import { store as article } from '../../../article'
import EditItemCommand from '../../../shared/commands/EditItemCommand.js'
import commandConnector from '../../../shared/lib/command'
import { dispatcher } from '../../../shared/lib/command'

// todo: convert this to the standard connector format
export default function connectArticleListItemToNormalizer(Template) {
  @dispatcher
  class NormalizerConnectedArticleListItem extends Component {
    static propTypes = {
      article: PropTypes.object.isRequired,
    };

    /**
     * Ensures that always an article model is returned instead of a plain
     * object. The item is (and has to be) fully loaded in the article store at
     * this point already.
     * @returns {Article} - The article from the store
     */
    normalize(itm) {
      const articleItem = article.getById(itm.id)
      if (!articleItem) {
        // eslint-disable-next-line max-len
        console.error(
          `The article with id \`${itm.id}\` does not exist in the artilce store. This is not supposed to happen!`
        )
        return null
      }
      return articleItem
    }

    // convert this to handle the double click
    @autobind
    handleDoubleClick() {
      const { router } = this.props

      try {
        const articleItem = this.props.article
        articleItem.type = articleItem.type || 'article'

        this.context.dispatch(EditItemCommand, {
          router,
          ...articleItem,
        })
      }
      catch (ex) {
        alert(
          formatMessage({ id: 'widget.error.cannot-open-content-item' }),
          formatMessage({ id: 'widget.error.opening-item' })
        )
        console.error(ex)
      }
    }

    render() {
      return (
        <Template
          onDoubleClick={this.handleDoubleClick}
          {...{
            ...this.props,
            article: this.normalize(this.props.article),
          }}
        />
      )
    }
  }
  // Inject commands
  return commandConnector({
    EditItemCommand,
  })(withRouter(NormalizerConnectedArticleListItem))
}
