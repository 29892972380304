import { isFunction } from 'lodash'
import { makeCancelable } from '../utils/promise-cancelable'

export default
function cancelable(target) {

  const provider = target.prototype

  const origComponentWillUnmount = provider.componentWillUnmount || (() => {})

  provider.componentWillUnmount =  function decoartedComponentWillUnmount() {
    origComponentWillUnmount.call(this);
    (this.cancelables || [])
      .forEach((item) => {
        ['cancel', 'dispose']
          .filter(method => item[method] && isFunction(item[method]))
          .forEach(method => item[method]())
      })
  }

  provider.isCanceledPromise = function decoratedIsCanceled(ex) {
    return 'isCanceled' in ex && ex.isCanceled
  }

  provider.makeCancelable =  function decoratedMakeCancelable(promise) {

    this.cancelables = this.cancelables || []

    const newCancelable = makeCancelable(promise)
    this.cancelables.push(newCancelable)

    promise
      .finally(() => {
        this.cancelables.splice(this.cancelables.indexOf(promise), 1)
      })

    newCancelable.promise.catch((ex) => {
      if (this.isCanceledPromise(ex)) {
        console.info(`Cancelable promise canceled in ${this.constructor.name}`)
      }
      // throw ex   // message already printed. no need to throw
    })

    return newCancelable.promise
  }
}
