import { autorun } from 'mobx'
import { apiVersions } from '../../config'
import { deepGet } from '../obj'

const warn = (method, ...args) => {
  console.warn(`InfoProvider.${method}(): ${args.join(' ')}`)
}

const expect = (method, ...args) => {
  throw new Error(
    `InfoProvider${method ? `.${method}` : ''}(): Expects ${args.join(' ')}`
  )
}

// TODO: make config an parameter!
export default class InfoProvider {
  constructor(opts) {
    const requiredProps = ['content', 'env', 'customLocalStore']

    requiredProps.forEach((name) => {
      if (!opts[name]) {
        expect(null, `\`${name}\` property in options parameter!`)
      }
    })
    const { content, env, customLocalStore, projectStore, pageStore } = opts

    this.content = content
    this.env = Object.keys(env).filter(key => !!env[key])[0]
    this.customLocalStore = customLocalStore

    // An optional projectStore for information in cm
    if (projectStore && projectStore.hasCurrent) {
      this.currentProject = projectStore.current
      this.channelShortcut = projectStore.current.channel.shortcut
    }
    else {
      this.channelShortcut = content && content.channel
    }

    // Set the currentPage whenever a current is set in pageStore
    this.currentPage = {}

    this.handler = autorun(() => {
      if (pageStore && pageStore.hasCurrent) {
        this.currentPage = pageStore.current
      }
    })

    this.config = {
      apiVersions,
    }

    /** @private */
    this.SHOW_ERROR_TIMEOUT = 5000
    /** @private */
    this.errorsLastShown = {}
  }

  /** @private */
  showErrorOnce(ex) {
    const now = new Date().getTime()
    if (
      !this.errorsLastShown[ex.message]
      || now - this.errorsLastShown[ex.message] > this.SHOW_ERROR_TIMEOUT
    ) {
      console.error(ex)
      this.errorsLastShown[ex.message] = now
    }
  }

  get contentUID() {
    return `${this.contentType}-${this.contentId}-${this.contentUpdatedAt}-${this.env}`.replace(
      /[\s:]/g,
      '-'
    )
  }

  /**
   *
   * @param {string | null} id
   * @param {Object?} values
   * @param {string} language
   */
  contentI18n(id = null, values, language) {
    let translation = ''
    try {
      if (typeof values === 'string') {
        language = values
        values = null
      }

      language = language || this.contentCreatedIso
      if (!language) {
        expect('contentI18n', '`language` as last parameter!')
      }

      if (typeof id !== 'object') {
        id = { id }
      }

      if (!id.id || typeof id.id !== 'string') {
        expect('contentI18n', 'an `id` (non-empty string) as first parameter!')
      }

      translation
        = this.customLocalStore.getTranslation(id.id, language) || null

      if (!translation) {
        warn(
          'contentI18n',
          `No translation for id "${id.id}" and language "${language}"!`
        )
        return id.id
      }

      const containsReplacements = /\{[^}]+\}/.test(translation)

      if (containsReplacements) {
        if (!values) {
          warn(
            'contentI18n',
            `Replacements for "${id.id}" missing. Pass an value object as second parameter.`
          )
          return translation
        }

        translation = translation.replace(/\{([^\}]+)\}/g, (all, group) => {
          const replacement = deepGet(values, group)
          if (!replacement) {
            warn('contentI18n', `No replacement for "${group}" in "${id.id}"`)
            return all
          }
          if (
            typeof replacement !== 'string'
            && typeof replacement !== 'number'
          ) {
            warn(
              'contentI18n',
              `Replacement for "${group}" in "${
                id.id
              } should be string, ${typeof replacement} given!"`
            )
            return all
          }
          return escape(`${replacement}`)
        })
      }
    }
    catch (ex) {
      this.showErrorOnce(ex)
      translation = ''
    }

    return translation
  }

  get contentId() {
    return this.content.id || this.contentId
  }

  get contentType() {
    return this.content.type || this.content.contentType
  }

  get contentMeta() {
    return this.content.meta || {}
  }

  get contentCreatedAt() {
    return this.content.createdAt || null
  }

  get contentCreatedIso() {
    return this.content.createdIso || null
  }

  get contentUpdatedAt() {
    return this.content.updatedAt || null
  }

  get contentCreatedBy() {
    return this.content.createdBy || null
  }

  get contentUpdatedBy() {
    return this.content.updatedBy || null
  }

  get contentContainingPageId() {
    return this.currentPage.id || null
  }

  get contentContainingPagePublicationUrl() {
    return this.currentPage.publishedUrl || null
  }

  get pageName() {
    return this.currentPage.name || null
  }

  get projectName() {
    // TODO
    return null
  }

  get projectId() {
    return this.currentProject ? this.currentProject.id : null
  }

  get projectDestinations() {
    return this.currentProject ? this.currentProject.projectDestinations : null
  }

  get projectSettings() {
    return this.currentProject ? this.currentProject.settings : null
  }

  /**
   *
   * @param {(String | Number)?} version
   */
  getAPIUrl(version = 'latest') {
    if (this.env !== 'CM' && this.env !== 'PM') {
      console.warn('This method is only available in CM and PM')
      return ''
    }

    version = parseInt(`${version}`.replace(/^v/, ''), 10)

    const versions = Object.keys(this.config.apiVersions).map(key => parseInt(key.replace(/^v/, ''), 10)
    )
    const latest = Math.max(...versions)

    if (version === 'latest' || versions.indexOf(version) === -1) {
      version = latest
    }

    const {
      apiScheme,
      apiHost,
      apiPort,
      apiBasePath,
    } = this.config.apiVersions[`v${version}`]

    return `${apiScheme}//${apiHost}${
      apiPort && apiPort * 1 !== 80 ? `:${apiPort}` : ''
    }/${apiBasePath}`
  }
}
