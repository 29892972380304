import * as React from 'react'
import { hoistNonReactStatics } from '../../hoistNonReactStatics'
import { observer } from 'mobx-react'

import validate, { ValidationHelper } from '../../validate-arguments'

import { ViewModelPoolPropType } from './Provider'
import { ViewModelSpec } from './view-model.d'

export const CommandInjectorContextPropTypes = {
  viewModelPool: ViewModelPoolPropType,
}

// Inspired by mobx-react
function isStateless(component) {
  // `function() {}` has prototype, but `() => {}` doesn't
  // `() => {}` via Babel has prototype too.
  return !(component.prototype && component.prototype.render);
}

function validateViewModels(viewModels) {
  if (!Array.isArray(viewModels)) {
    throw new Error(
      `\`viewModelConnector\` expects an array of \`viewModelSpec\`s as first ` +
      `argument!`
    )
  }

  viewModels.map((viewModel) => {
    validate(viewModel, {
      'class': ValidationHelper.isPresent,
      'class.name': ValidationHelper.isString,
    })
  })

}

function createViewModelInjector(viewModels, component) {

  let displayName = "inject-view-model-" +
    (component.displayName || component.name ||
      (component.constructor && component.constructor.name) || "Unknown")

  interface InjectorProps {
    // commands?: {
    //   [key: string]: ICommand
    // };
    ref?: any;
    [key: string]: any;
  }

  @observer
  class Injector
  extends React.Component<InjectorProps, {}> {

    static displayName = displayName;

    static wrappedComponent = undefined;

    static contextTypes = CommandInjectorContextPropTypes

    public wrappedInstance: Injector | null = null

    private injectorRef = (instance) => {
      this.wrappedInstance = instance
    }

    private componentDidMount() {
      viewModels.forEach((viewModelSpec) => {
        const viewModel = this.getViewModel(viewModelSpec)
        if (viewModel) {
          viewModel.collect(viewModelSpec.data, this.props)
        }
      })
    }

    private componentWillUnmount() {
      viewModels.forEach((viewModelSpec) => {
        const viewModel = this.getViewModel(viewModelSpec)
        if (viewModel) {
          viewModel.dispose(viewModelSpec.data, this.props)
        }
      })
    }

    private getViewModels(props: {[key: string]: any}): {[key: string]: any} {
      return viewModels
      .reduce((memo, viewModelSpec) => {
        const viewModel = this.getViewModel(viewModelSpec)
        viewModel.init(viewModelSpec.data, props)
        memo[this.getViewModelName(viewModelSpec)] = viewModel
        return memo
      }, {})
    }

    private getViewModel(viewModelSpec: ViewModelSpec): any {
      return this.context.viewModelPool.get(viewModelSpec)
    }

    private getViewModelName(viewModelSpec: ViewModelSpec): any {
      return viewModelSpec.as || viewModelSpec.class.name
    }

    render() {

      const newViewModels = this.getViewModels(this.props)

      const newProps = {
        ...this.props,
        viewModels: {
          ...(this.props.viewModels || []),
          ...newViewModels
        }
      }

      if (!isStateless(component)) {
        newProps.ref = this.injectorRef;
      }

      return React.createElement(component, newProps);
    }

  }

  // Static fields from component should be visible on the generated Injector
  hoistNonReactStatics(Injector, component, []);

  Injector.wrappedComponent = component;

  return Injector;

}

export default
function(viewModels: Array<ViewModelSpec>) {
  return function (componentClass: any) {
    validateViewModels(viewModels);
    return createViewModelInjector(viewModels, componentClass)
  }
}
