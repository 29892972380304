import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { autobind } from 'core-decorators'
import request from 'superagent'

import { FormattedMessage } from '../../../translations'

import Dropdown, {
  DropdownContainer, Entry, Separator
} from '../../../shared/components/Dropdown'
import { dispatcher } from '../../../shared/lib/command'

import GenevaDropdownOpener from '../../../ui/components/GenevaDropdownOpener'
import { about as aboutDialog, passwordDialog } from '../../containers/dialogs'
import { store as authStore } from '../../../auth'
import config from '../../../config'

const css = require('../../styles.scss')

@dispatcher
class NavbarOptions extends Component {

  static propTypes = {
    onLogout: PropTypes.func,
  }

  @autobind
  handleAbout() {

    aboutDialog({
      apps: authStore.user.apps,
      currentApp: 'wm'
    })
  }

  @autobind
  handleLogout() {
    authStore.logout()
  }

  @autobind
  handlePasswordChange() {
    passwordDialog({
    })
      .then((res) => {
        this.context.dispatch(
          this.props.commands.ChangePasswordCommand,
          {
            ...res,
            userId: authStore.user.id
          }
        )
      })
  }

  @autobind
  handleSelect({ target }) {
    const app = target && target.value
    if (app === 'am') {
      window.open(`${global.GENEVA_CONFIG.AM_URL}/#/users`, '_self')
    }
    else {
      const amApiUrl = config && config.am && config.am.api

      // superagent (request) is used here because the route is external and dynamic,
      //  but still needs credentials
      // request should be something like: 'https://dachser-beta-api.geneva.works/public/sso/mm'
      request
        .get(`${amApiUrl}/public/sso/${app}`)
        .set('Content-Type', 'application/json')
        .withCredentials()
        .end((err, res = { body: '' }) => {
          if (!err && res.ok) {
            window.open(res.body.url, '_self')
          }
          // TODO: add error case if request fails
        })
    }
  }

  renderPassword() {

    // only show option when system allows
    const supported = !config.ad_support

    if (!supported) {
      return null
    }
    return (<Entry onClick={this.handlePasswordChange} key="password-change">
      <FormattedMessage id="auth.password-change" />
    </Entry>)
  }

  render() {
    const userApps = authStore.user && authStore.user.apps ? authStore.user.apps : {}
    const allowedApps = []
    Object.keys(userApps).forEach((key) => {
      if (!userApps[key].hideInAppSelect && userApps[key].short !== 'wm') {
        allowedApps.push(userApps[key])
      }
    })

    return (
      <DropdownContainer className={css.navbarOptions}>

        <GenevaDropdownOpener
          clickToClose
          arrow
          caret={false}
        >
          {authStore.user.firstname}
        </GenevaDropdownOpener>

        <Dropdown>
          <Entry onClick={this.handleLogout}>
            <FormattedMessage id="logout" />
          </Entry>

          <Separator />

          {this.renderPassword()}

          <Entry onClick={this.handleAbout}>
            <FormattedMessage id="about" />
          </Entry>

          {
            allowedApps.length
              ? <Separator />
              : null
          }

          {
            allowedApps.map((app) => {
              return (
                <Entry
                  key={app.sort}
                  onClick={this.handleSelect}
                  identifier={app.short}
                >
                  {app.name}
                </Entry>)
            })
          }
        </Dropdown>

      </DropdownContainer>
    )
  }
}

export default NavbarOptions
