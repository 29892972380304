import React from 'react'
import * as PropTypes from 'prop-types'
import lodash from 'lodash'
import classNames from 'classnames'
import { toJS, autorun, intercept, observe } from 'mobx'
import { observer } from 'mobx-react'
import { formatMessage } from '../../translations'

import { i18n } from '../utils'

export const baseLibs = {
  // consider sending a System clone in here that has the template
  // folder as baseUrl:
  // const clonedSystem = new System.constructor()
  // clonedSystem.baseURL = System.baseURL +
  // config.articleTemplateBasePath +
  // this.props.template.current.shortcut

  // eslint-disable-next-line no-undef
  System,
  React: {
    ...React,
    PropTypes, // no longer in the React main package. Added to keep templates functional
  },
  lodash,

  // mobx deps
  toJS,
  autorun,
  intercept,
  observe,

  observer,

  classNames,
}

// todo: do we need all these types of format support?
export const i18nLibs = (intl) => {
  const {
    formatDate,
    formatTime,
    formatRelative,
  } = intl

  return {
    // react inlt
    formatMessage,
    formatDate,
    formatTime,
    formatRelative,
    i18n,
  }
}
