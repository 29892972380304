import {
  TemplateActivator as ArticleTemplateActivator,
  TemplateDependencyCollector as ArticleTemplateDependencyCollector,
} from '../../../template/loaders/pm'
import {
  TemplateActivator as WidgetTemplateActivator,
  TemplateDependencyCollector as WidgetTemplateDependencyCollector,
} from '../../../widgetTemplate/loaders/pm'
import { store as articleTemplateStore } from '../../../template'
import { store as widgetTemplateStore } from '../../../widgetTemplate'
import { store as articleStore } from '../../../article'
import { store as widgetStore } from '../../../widget'
import * as articleConnectors from '../../../template/connectors/pm'
import * as widgetConnectors from '../../../widgetTemplate/connectors/pm'


export const templateStores = {
  article: articleTemplateStore,
  widget: widgetTemplateStore
}

export const contentStores = {
  article: articleStore,
  widget: widgetStore,
}

export const connectorStores = {
  article: articleConnectors,
  widget: widgetConnectors
}

export const templateDependencyCollectors = {
  article: ArticleTemplateDependencyCollector,
  widget: WidgetTemplateDependencyCollector
}

export const templateActivators = {
  article: ArticleTemplateActivator,
  widget: WidgetTemplateActivator,
}
