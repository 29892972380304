import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import { toJS } from 'mobx'
import { observer } from 'mobx-react'
import { autobind } from 'core-decorators'
import warn from '../../dev/warn'

const css = /* typeof window === 'undefined' ? {} : */ require('./Repeatable/styles.scss')

function warnCBL(id, ...args) {
  return warn(`repeatable.${id}`, ...args)
}

export default function connectRepeatableToContext(
  Repeatable,
  parentProps,
  contextStore
) {
  if (!contextStore) {
    throw new Error(
      'To connect an Repeatable with a context, '
        + '`connectRepeatable` expects to get passed a `contextStore` argument '
        + 'as third parameter.'
    )
  }

  if (!parentProps.articlePlaceholders) {
    throw new Error(
      '`connectRepeatableToContext` '
        + "requires an articlePlaceholders Object in it's "
        + 'parentProps'
    )
  }

  @observer
  class ConnectedRepeatable extends Component {
    static pid(baseId, index, itemPid) {
      const base = `${baseId}:${index}`
      return itemPid === undefined ? base : `${base}~${itemPid}`
    }

    static propTypes = {
      pid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      maxLen: PropTypes.number,
      createNewChildren: PropTypes.func,
      renderChild: PropTypes.func.isRequired,
      value: PropTypes.array,
    };

    getItemsToAdd(id) {
      let retval

      if (this.props.createNewChildren) {
        let suspectedPromise = this.props.createNewChildren(id)
        if (!(suspectedPromise instanceof Promise)) {
          suspectedPromise = Promise.resolve(suspectedPromise)
        }

        retval = suspectedPromise
          .then((itemsToAdd) => {
            if (!itemsToAdd || !Array.isArray(itemsToAdd)) {
              warn('array.noArray', 'itemsToAdd', itemsToAdd)
              return []
            }

            itemsToAdd = itemsToAdd.filter((item, i) => {
              if (!(item.id && item.subId)) {
                warnCBL('id-and-subid', i)
                return false
              }

              return true
            })

            return {
              itemsToAdd,
            }
          })
          .catch((ex) => {
            throw ex
          })
      }
      else {
        retval = Promise.resolve({
          itemsToAdd: [
            /* {
            id,
            subId: 1,
            value: {
              type: 'text',
              value: ''
            }
          }*/
          ],
        })
      }

      return retval
    }

    getItemsToRemove(id) {
      return Promise.resolve({
        itemsToRemove: [{ id }],
      })
    }

    @autobind
    handleFocus({ target }) {
      contextStore.createFromElement(target)
    }

    @autobind
    handleBlur() {
      contextStore.set(null)
    }

    @autobind
    handleChange({ target: { id, value, oldValue } }) {
      const placeholder = parentProps.articlePlaceholders
      let promise = Promise.resolve({})

      // adding
      if (value.length > oldValue.length) {
        promise = this.getItemsToAdd(id)
      }
      // removing
      else if (value.length < oldValue.length) {
        promise = this.getItemsToRemove(id)
      }

      promise
        .then(({ itemsToAdd, itemsToRemove }) => {
          placeholder.transaction(() => {
            placeholder.set(this.props.pid, 'value', {
              type: 'keyValue',
              value,
            })

            if (itemsToAdd) {
              itemsToAdd.forEach((item) => {
                placeholder.set(
                  ConnectedRepeatable.pid(this.props.pid, item.id, item.subId),
                  item.keyPath || 'value',
                  item.value
                )
              })
            }

            if (itemsToRemove) {
              itemsToRemove.forEach(item => placeholder.remove(
                ConnectedRepeatable.pid(this.props.pid, `${item.id}*`)
              )
              )
            }
          })
        })
        .catch((ex) => {
          console.log(ex.message)
          throw ex
        })
    }

    render() {
      const env = toJS(parentProps.env)

      const value
        = this.props.value
        || parentProps.articlePlaceholders.get(this.props.pid)
        || []

      const cssMerged = this.props.css && this.props.css !== {} ? Object.assign(css, this.props.css) : css
      return (
        <Repeatable
          {...this.props}
          css={cssMerged}
          value={value}
          env={env}
          onChange={this.handleChange}
        />
      )
    }
  }

  return ConnectedRepeatable
}
