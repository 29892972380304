import { MacroCommand, MacroCommandData } from '../../../../../shared/lib/command';


// TODO add type defs

export default
class CreateAndInsertNewContentIntoWidgetCommand
extends MacroCommand {

  // This command doesn't use these stores, but it's subcommands do. Defining
  // them here anyways guarantees that all subcommands only are executed if
  // these stores are available here already.
  static storeRequirements = {
    page: true,
    widget: true,
    article: true
  }

  static validationSpec = {
    context: MacroCommand.ensureData.isPresent,
    'context.position': MacroCommand.ensureData.isPresent,
    item: MacroCommand.ensureData.isPresent,
    'item.type': MacroCommand.ensureData.isPresent.isString,
    'widgetId': MacroCommand.ensureData.isNumber,
    openForEditing: MacroCommand.ensureData.isBoolean,
    router: MacroCommand.ensureData.isPresent,
  }

  exec() {

    const {
      item,
      openForEditing,
      // context
    } = this.data

    let model

    // Ensures the content exists
    return this.dispatch(this.data.commands.CreateContentCommand, item)
    .then((content) => {

      model = item[item.type] = content

      return this.dispatch(
        this.data.commands.InsertContentIntoWidgetCommand,
        this.getInsertionCommandData()
      )

    })
    // May re-insert upon request - Handles selection after insert
/*    .then(() => {
      return this.dispatch(
        this.data.commands.SelectContentInWidgetCommand,
        {
          context,
          item
        }
      )
    }) */
    .then(() => {

      if (model && openForEditing) {
        this.dispatch(
          this.data.commands.EditItemCommand,
          this.getEditingCommandData(model)
        )
      }

    })

  }

  private getInsertionCommandData() {

    const {
      item,
      page,
      context: {position},
      widgetId
    } = this.data
    const insertionPosition = position

    return {
      insertionPosition,
      item,
      page,
      widgetId
    }

  }

  private getEditingCommandData(model) {

    const {
      router,
    } = this.data

    return {
      router,
        id: model.id,
        type: model.contentType,
    }

  }

}
