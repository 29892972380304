import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { autorun } from 'mobx'
import { autobind } from 'core-decorators'
import classNames from 'classnames'
import PerfectScrollbar from 'perfect-scrollbar'
import { formatMessage, FormattedMessage } from '../../translations'
import { contentLangs } from '../../config'

import { deepGet } from '../../shared/obj'
import Icon from '../../shared/icons'
import ContentLoadingBox from '../../shared/components/ContentLoadingBox'
import Dropdown, {
  DropdownContainer,
  Entry
} from '../../shared/components/Dropdown'
import GenevaDropdownOpener from '../../ui/components/GenevaDropdownOpener'

import { censhareStore } from '../reducer'

const css = /* typeof window === 'undefined' ? {} : */ require('../styles.scss')

const possibleFormats = ['jpg-big', 'jpg-small', 'png-small', 'png-big']


@observer
export default class CenshareSidebar extends Component {

  static propTypes = {
    pageStore: PropTypes.object.isRequired,
    templateStore: PropTypes.object.isRequired,
    articleStore: PropTypes.object.isRequired,
    projectStore: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)

    const pageModel = props.viewModels.models.data.page
    const templateModel = props.viewModels.models.data.template
    const censhareArticleIds = deepGet(pageModel, 'meta.censhareArticleIds') || []
    const isLocked = deepGet(templateModel, 'config.censhareLock') || false
    let displayState = 'info-state'

    this.ps = null

    if (isLocked) {
      displayState = 'info-state'
    }
    else if (censhareArticleIds.length === 1) {
      displayState = 'asset-state'
    }
    else if (censhareArticleIds.length > 1) {
      displayState = 'article-state'
    }

    const connectedElementIds = this.getConnectedElementIds()

    this.state = {
      isLoading: false,
      displayState,
      censhareArticleIds,
      censhareArticles: [],
      currentArticle: {},
      assets: [],
      currentAsset: {},
      elements: [],
      pageModel,
      templateModel,
      connectedElementIds,
      currentImageFormat: {},
      copiedFrom: null,
    }

  }

  componentDidMount() {
    this.loadCenshareArticles()
    this.handler = autorun(() => this.updateConnectedElementIds(
      this.props.articleStore.current.phAccessor
      && this.props.articleStore.current.phAccessor.asJSON
    ))
    this.ps = new PerfectScrollbar('#censhareSidebar-container', {
      suppressScrollX: true
    })

  }

  componentWillUnmount() {
    if (this.handler) {
      this.handler()
    }
  }

  updateConnectedElementIds(data) {
    if (!data) {
      // Bail out if no data
      return
    }

    this.setState({
      connectedElementIds: this.getConnectedElementIds()
    })

    return
  }

  getConnectedElementIds() {
    const phAccessor = deepGet(this, 'props.articleStore.current.phAccessor')
    const retVal = []

    Object.keys(phAccessor.placeholderMap).forEach((key) => {
      const source = phAccessor.getSource(key)
      if (source) {
        retVal.push({
          assetId: source.ctx.assetId,
          elementId: source.id,
          imageId: source.ctx.imageId,
          lastContentModification: source.ctx.lastContentModification,
          usedFormat: source.format
        })
      }
    })

    return retVal
  }

  loadCenshareArticles() {
    const { censhareArticleIds } = this.state

    if (censhareArticleIds.length === 0) {
      // Bail out if nothing to load
      return
    }

    this.setState({
      isLoading: true
    })

    const contentLangKey = Object.keys(contentLangs).find(key => key === deepGet(this, 'props.articleStore.current.createdIso'))
    const params = {
      languageIso: contentLangs[contentLangKey].censhareIso
    }

    censhareArticleIds.forEach((censhareArticleId) => {
      censhareStore.load(censhareArticleId, { params })
        .then((model) => {

          const censhareArticles = this.state.censhareArticles.concat(model).filter(n => n)
          let {
            currentArticle,
            assets,
            currentAsset,
            displayState,
            elements,
            currentImageFormat,
            connectedElementIds
          } = this.state

          // If there is only 1 element per state, switch to the next one
          // so the user does not need to selected a single entry
          if (censhareArticles.length === 1) {
            currentArticle = censhareArticles[0]

            assets = currentArticle.content || []

            if (assets.length === 1) {
              currentAsset = assets[0]
              elements = currentAsset.elements || []
              displayState = 'element-state'

              if (elements.length === 0) {
                displayState = 'asset-state'
              }
            }
            else {
              displayState = 'asset-state'
            }
          }
          else {
            displayState = 'article-state'
          }

          // Check every image for possible formats for dropdown selection
          elements.forEach((element) => {
            if (element.type === 'image') {
              const imageObj = deepGet(element, 'value.image')
              const connectedElement = connectedElementIds.find(el => el.imageId === imageObj.id)

              // If the image element is connected, use the already choosen format
              if (connectedElement && connectedElement.usedFormat) {
                currentImageFormat[element.id] = connectedElement.usedFormat
              }
              else {
                // Otherwise use the first possible format supported by the image
                possibleFormats.forEach((format) => {
                  const possibleFormat = Object.keys(imageObj).find(el => el === format)

                  if (possibleFormat && !currentImageFormat[element.id]) {
                    // Add as initial option if not already set
                    currentImageFormat[element.id] = possibleFormat
                  }
                })
              }
            }
          })

          this.setState({
            censhareArticles,
            currentArticle,
            assets,
            currentAsset,
            elements,
            displayState,
            currentImageFormat,
            isLoading: false
          })
        })
        .catch((err) => {
          console.log(err)
        })
    })

    return
  }

  @autobind
  handleDragStart(event, element) {
    const { currentArticle, currentAsset, currentImageFormat } = this.state
    const type = typeof element.value === 'string' ? 'text' : 'image'

    // Get either text or image url
    let value = typeof element.value === 'string'
      ? element.value
      : deepGet(element, 'value.image.downloadLink')

    let imageId = null
    let imageMetas = null
    let possibleFormat = ''

    if (element.type === 'image') {
      imageId = deepGet(element, 'value.image.id')
      imageMetas = {
        alt: deepGet(element, 'value.alt'),
        title: deepGet(element, 'value.title')
      }

      if (!currentImageFormat[element.id]) {

        // If the user did not choose an image format yet, try to find a suitable image version to pick
        const imageObj = deepGet(element, 'value.image')
        possibleFormat = possibleFormats.find((format) => {
          return Object.keys(imageObj).find(el => el === format)
        })

        if (possibleFormat) {
          value = deepGet(element, `value.image.${possibleFormat}.downloadLink`)
        }
      }
      else {
        value = deepGet(element, `value.image.${currentImageFormat[element.id]}.downloadLink`)
      }
    }

    const dataToSend = JSON.stringify({
      source: {
        id: element.id,
        name: 'censhare',
        format: currentImageFormat[element.id] || possibleFormat,
        ctx: {
          articleId: currentArticle.assetId,
          assetId: currentAsset.assetId,
          imageId,
          lastContentModification: type === 'image'
            ? deepGet(element, 'value.image.lastContentModification')
            : currentAsset.lastContentModification
        }
      },
      value,
      imageMetas,
      type: element.type,
    })

    // If dragging image, set value to real image instead of thumbnail
    if (event.dataTransfer.types.includes('text/uri-list') || element.type === 'image') {
      event.dataTransfer.setData('text/uri-list', value)
    }

    // Extra logic for Firefox
    if (event.dataTransfer.types.includes('text/x-moz-url')) {
      event.dataTransfer.clearData('text/x-moz-url')
    }

    event.dataTransfer.effectAllowed = 'copy'
    event.dataTransfer.setData(`censhare-${type}`, dataToSend)
  }

  @autobind
  handleCenshareArticleClick(article) {
    this.setState({
      displayState: 'asset-state',
      assets: article.content || [],
      currentArticle: article
    })
  }

  @autobind
  handleCenshareAssetClick(asset) {
    if (!asset.elements) {
      // Bail out if no content to display
      return
    }

    this.setState({
      displayState: 'element-state',
      elements: asset.elements,
      currentAsset: asset
    })
  }

  @autobind
  handleBack(targetState) {
    this.setState({
      displayState: targetState
    })
  }


  @autobind
  handleDropdownChange({ target }) {
    this.setState({
      currentImageFormat: {
        [target.id]: target.identifier
      }
    })
  }

  @autobind
  handleCopyClipboard(element) {
    // copy either text or title + alt to clipboard
    const text = element.type === 'image'
      ? `${element.value.title} ${element.value.alt}`
      : element.value

    navigator.clipboard.writeText(text)
      .then(() => {
        this.setState({
          copiedFrom: element.id
        })
        // wait 3 seconds and reset
        setTimeout(() => {
          this.setState({
            copiedFrom: null
          })
        }, 4000)
      }).catch((err) => {
        console.error('Could not copy text: ', err)
      })
  }

  @autobind
  renderDropdown(element, isElementConnected) {
    if (element.type !== 'image') {
      // Bail out - only images get a dropdown
      return null
    }

    const { currentImageFormat } = this.state
    const imageObj = element.value && element.value.image
    const imageFormat = currentImageFormat[element.id] || possibleFormats[0]

    return (<DropdownContainer className="image-format-dropdown">
      <GenevaDropdownOpener
        clickToClose
        caret={false}
        arrow
        disabled={isElementConnected}
      >
        <FormattedMessage id={`censhare.sidebar.imagetype.${imageFormat}`} />
      </GenevaDropdownOpener>

      <Dropdown>
        {possibleFormats.map((option) => {

          if (!imageObj[option]) {
            return null
          }

          return (<Entry
            id={element.id}
            identifier={option}
            key={option}
            onClick={event => this.handleDropdownChange(event)}
          >
            <FormattedMessage id={`censhare.sidebar.imagetype.${option}`} />
          </Entry>)
        })}
      </Dropdown>
    </DropdownContainer>)
  }

  renderLoader() {
    return (<div className="content-loader-background">
      <ContentLoadingBox className="content-loader"
        message={{
          id: 'censhare.loading',
        }} />
    </div>)
  }

  renderConnectionInfo() {
    const { templateModel } = this.state

    return (<div className="censhare-connection-info">
      {templateModel && templateModel.config && templateModel.config.censhareLock
        ? <FormattedMessage id="censhare.sidebar.template-lock" />
        : [<FormattedMessage key="info-1" id="censhare.sidebar.connection-info-1" />,
          <FormattedMessage key="info-2" id="censhare.sidebar.connection-info-2" />]}
    </div>)
  }

  renderArticleOverview() {
    const { censhareArticles } = this.state

    return (<div className="censhare-article-overview">
      <div className="article-info">
        <FormattedMessage id="censhare.sidebar.article.info" />
      </div>
      {censhareArticles.map((article) => {
        return (<div className="censhare-article-item" key={article.assetId} onClick={() => this.handleCenshareArticleClick(article)}>
          <div className="censhare-article-item-content">
            <div>{article.name}</div>

            {article.createdAt
              ? <span>{article.createdAt} | </span>
              : null}

            {article.category
              ? <span>{article.category} | </span>
              : null}

            <span>ID {article.assetId}</span>
          </div>
        </div>)
      })}
    </div>)
  }

  renderAssetOverview() {
    const { assets, currentArticle, connectedElementIds } = this.state
    const assetText = formatMessage({ id: 'censhare.assets' }, { count: assets.length })

    return (<div className="censhare-asset-overview">
      <div
        onClick={() => this.handleBack('article-state')}
        className="back-button"
      >
        <Icon name="ion ion-ios-arrow-down" />
        <FormattedMessage id="article.article" />
      </div>
      <div className="article-name">
        {currentArticle.name}
      </div>
      <div className="asset-info">
        {assetText}
      </div>
      {assets.map((asset) => {
        const elements = asset.elements || []
        let isElementConnected = false
        elements.forEach((element) => {
          if (!isElementConnected) {
            isElementConnected = !!connectedElementIds.find(el => el.elementId === element.id)
          }
        })

        return (<div key={asset.assetId} className="asset-item" onClick={() => this.handleCenshareAssetClick(asset)}>
          <span>{asset.name}</span>
          <div className="asset-item-icon-holder">
            {isElementConnected
              ? <Icon name="ion ion-ios-checkmark" />
              : null}
          </div>
        </div>)
      })}
    </div>)
  }

  renderElementOverview() {
    const { currentAsset, elements, connectedElementIds, copiedFrom } = this.state

    return (<div className="censhare-content-overview">
      <div
        onClick={() => this.handleBack('asset-state')}
        className="back-button"
      >
        <Icon name="ion ion-ios-arrow-down" />
        <FormattedMessage id="censhare.sidebar.asset" />
        <div className="asset-id">
          <FormattedMessage id="censhare.sidebar.id" />
          <span>{currentAsset.assetId}</span>
        </div>
      </div>
      <div className="asset-name">
        {currentAsset.name}
      </div>
      {elements.map((element) => {
        const typeText = formatMessage({ id: `censhare.sidebar.contenttype.${element.type}` })
        const isElementConnected = !!connectedElementIds.find(el => el.elementId === element.id)

        if (!element.value) {
          // Bail out if element has no value to show
          return null
        }

        return (<div
          key={element.id}
          className="element-item-wrapper"
        >
          <div className="element-item-type">
            <span>{typeText}</span>

            {this.renderDropdown(element, isElementConnected)}

            <div onClick={() => this.handleCopyClipboard(element)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 448 512" style={{ fill: copiedFrom === element.id ? '#327ccb' : '#000' }}>
                <path d="M384 336l-192 0c-8.8 0-16-7.2-16-16l0-256c0-8.8 7.2-16 16-16l140.1 0L400 115.9 400 320c0 8.8-7.2 16-16 16zM192 384l192 0c35.3 0 64-28.7 64-64l0-204.1c0-12.7-5.1-24.9-14.1-33.9L366.1 14.1c-9-9-21.2-14.1-33.9-14.1L192 0c-35.3 0-64 28.7-64 64l0 256c0 35.3 28.7 64 64 64zM64 128c-35.3 0-64 28.7-64 64L0 448c0 35.3 28.7 64 64 64l192 0c35.3 0 64-28.7 64-64l0-32-48 0 0 32c0 8.8-7.2 16-16 16L64 464c-8.8 0-16-7.2-16-16l0-256c0-8.8 7.2-16 16-16l32 0 0-48-32 0z" />
              </svg>
            </div>

            {isElementConnected
              ? <Icon name="ion ion-ios-checkmark" />
              : null}
          </div>
          {this.renderElement(element, isElementConnected)}
        </div>)
      })}
    </div>)
  }

  renderElement(element, isConnected) {
    // image content
    if (typeof element.value === 'object') {

      if (!element.value.image) {
        // Bail out if empty image-box
        return null
      }

      const thumbnailURL = deepGet(element, 'value.image.thumbnail.downloadLink')
      const lastContentModification = deepGet(element, 'value.image.lastContentModification')
      const connectedElement = this.state.connectedElementIds.find(el => el.elementId === element.id)
      let isNew = false

      if (connectedElement && new Date(connectedElement.lastContentModification) < new Date(lastContentModification)) {
        isNew = true
      }

      return (<div
        className={classNames('element-item', 'element-item-image', isConnected && !isNew ? 'connected' : '')}
        draggable
        onDragStart={event => this.handleDragStart(event, element)}
      >
        <div className="image-wrapper">
          <img
            src={thumbnailURL}
            alt={element.value.alt}
            title={element.value.title}
          />
          <FormattedMessage className="alt-seo-title" id="censhare.sidebar.image.seo" />
          <div>{element.value.title || '-'}</div>
          <FormattedMessage className="alt-seo-title" id="censhare.sidebar.image.alt" />
          <div>{element.value.alt || '-'}</div>
        </div>
        {isNew
          ? <div className="new-banner">
            <FormattedMessage id="image.image-propagation-new" />
          </div>
          : null}
      </div>)
    }

    // text content
    if (typeof element.value === 'string') {

      return (<div
        className={classNames('element-item', isConnected ? 'connected' : '')}
        draggable={!isConnected}
        onDragStart={event => this.handleDragStart(event, element)}
        dangerouslySetInnerHTML={{
          __html: element.value
        }}
      />)
    }

    return null
  }

  renderContent() {
    const { displayState } = this.state

    if (displayState === 'info-state') {
      return this.renderConnectionInfo()
    }

    if (displayState === 'article-state') {
      return this.renderArticleOverview()
    }

    if (displayState === 'asset-state') {
      return this.renderAssetOverview()
    }

    if (displayState === 'element-state') {
      return this.renderElementOverview()
    }

    return this.renderLoader()
  }

  render() {

    if (this.ps) {
      this.ps.update()
    }

    return (
      <div
        id="censhareSidebar-container"
        className={classNames('grid-block vertical', css.censhareTab)}
        onFocus={() => this.handleMouseOver()}
      >
        {this.state.isLoading
          ? this.renderLoader()
          : this.renderContent()}
      </div>
    )
  }
}
