import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import classNames from 'classnames'
import { autobind, throttle } from 'core-decorators'
import { observable, action } from 'mobx'
import { observer } from 'mobx-react'

import ScrollTrigger from './ScrollTrigger'

import * as css from './styles.scss'

const SCROLL_INTERVAL = 50
// initial scroll speed. Means we scroll by 10px each scroll interval
const SCROLL_SPEED = 10
// the maximum scroll speed
const SCROLL_MAX_SPEED = 100
// we accelerate the speed by SCROLL_ACCELLERATION after 500ms
const SCROLL_ACCELLERATION_INTERVAL = 500
// We add 2px to the scroll speed avter every SCROLL_ACCELLERATION time frame
const SCROLL_ACCELLERATION = 2

/**
 * Adds top and bottom large scroll areas to support easier drag and drop
 * Pre-reqs:
 */
@observer
export default class ScrollContainer extends Component {
  /* eslint-disable react/sort-comp */
  @observable state = {
    isDragging: false,
    scrollProgress: -1,
  };
  /* eslint-enable react/sort-comp */

  static propTypes = {
    children: PropTypes.node,
    mouseEventMode: PropTypes.bool,
  };

  static defaultProps = {
    mouseEventMode: false,
  };

  constructor(props) {
    super(props)
    this.scrollStartTime = null
  }

  componentDidMount() {
    if (this.scrollContainerRef) {
      if (this.props.mouseEventMode) {
        this.scrollContainerRef.addEventListener(
          'mousedown',
          this.handleDetectMousedown,
          true
        )
      }
      else {
        this.scrollContainerRef.addEventListener(
          'dragstart',
          this.handleStartDragging,
          true
        )
      }
    }
  }

  componentWillUnmount() {
    if (this.scrollContainerRef) {
      if (this.props.mouseEventMode) {
        this.scrollContainerRef.removeEventListener(
          'mousedown',
          this.handleDetectMousedown,
          true
        )
      }
      else {
        this.scrollContainerRef.removeEventListener(
          'dragover',
          this.handleStartDragging,
          true
        )
      }
    }
  }

  getScrollSpeed() {
    const now = new Date().getTime()

    // if scrolling was interruped, use the default scrollspeed (again)
    if (this.lastScrollEvent && now - this.lastScrollEvent > 100) {
      this.lastScrollEvent = now
      this.scrollStartTime = now
      return SCROLL_SPEED
    }

    // otherwise increase the scrollspeed all SCROLL_ACCELLERATION_INTERVAL
    // by SCROLL_ACCELLERATION
    this.lastScrollEvent = now
    const scrollDuration = now - this.scrollStartTime
    const newScrollSPeed
      = SCROLL_SPEED
      + parseInt(scrollDuration / SCROLL_ACCELLERATION_INTERVAL, 10)
        * SCROLL_ACCELLERATION

    return Math.min(newScrollSPeed, SCROLL_MAX_SPEED)
  }

  @autobind
  handleDetectMousedown() {
    this.scrollContainerRef.addEventListener(
      'mousemove',
      this.handleStartDragging,
      true
    )
  }

  @action
  setScrollPosition() {
    const el = this.scrollAreaRef
    const isScrollable = el.clientHeight < el.scrollHeight

    const progress = isScrollable
      ? el.scrollTop / (el.scrollHeight - el.clientHeight)
      : -1
    this.setState({
      scrollProgress: progress,
    })
  }

  @autobind
  @action
  handleStartDragging() {
    this.setState({
      isDragging: true,
    })
    this.setScrollPosition()

    this.scrollStartTime = new Date().getTime()

    if (this.props.mouseEventMode) {
      this.scrollContainerRef.removeEventListener(
        'mousemove',
        this.handleStartDragging,
        true
      )
    }

    document.body.addEventListener('mouseup', this.handleStopDragging, true)
  }

  @autobind
  @action
  handleStopDragging() {
    this.setState({
      isDragging: false,
    })

    this.scrollStartTime = null

    if (this.props.mouseEventMode) {
      document.body.removeEventListener(
        'mouseup',
        this.handleStopDragging,
        true
      )
    }
  }

  /**
   * Updates the scroll posisiton.  If nothing is happening, check this function
   * @param {Object} e - has direction and speed of scroll
   */
  @autobind
  @throttle(SCROLL_INTERVAL)
  @action
  handleScroll(e) {
    const scrollSpeed = this.getScrollSpeed()

    const position = e.target.value
    this.scrollAreaRef.scrollTop
      += position === 'top' ? -scrollSpeed : scrollSpeed

    this.setScrollPosition()
  }

  render() {
    return (
      <div
        className={classNames('scroll-container', css.scrollContainer)}
        ref={(ref) => {
          this.scrollContainerRef = ref
        }}
      >
        <ScrollTrigger
          position="top"
          onDragOver={this.handleScroll}
          dragState={this.state}
          mouseEventMode={this.props.mouseEventMode}
        />
        <div
          className="grid-block vertical"
          ref={(ref) => {
            this.scrollAreaRef = ref
          }}
        >
          {this.props.children}
        </div>
        <ScrollTrigger
          position="bottom"
          onDragOver={this.handleScroll}
          dragState={this.state}
          mouseEventMode={this.props.mouseEventMode}
        />
      </div>
    )
  }
}
