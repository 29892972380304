import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import { autobind } from 'core-decorators'
import { toJS } from 'mobx'
import { observer } from 'mobx-react'
import { appendQueries, extractIdFromUrl } from '../../shared/utils/url'
import { genevaCssClass } from '../../shared/utils'
import MediaCacheBustRegistry from './Media/MediaCacheBustRegistry'
import connectMediaToUploadHelper from './connectMediaToUploadHelper'

const css = /* typeof window === 'undefined' ? {} : */ require('./Media/styles.scss')

// Having a global cache bust registry allows not having to reload images
// that often even across articles or page
const globalMediaCaheBust = new MediaCacheBustRegistry()

export default function connectMediaToContext(
  Media,
  parentProps,
  contextStore
) {
  if (!contextStore) {
    throw new Error(
      'To connect an Media with a context, '
        + '`connectEditor` expects to get passed a `contextStore` argument '
        + 'as third parameter.'
    )
  }

  Media = connectMediaToUploadHelper(Media, {
    env: parentProps.env,
    articlePlaceholders: parentProps.articlePlaceholders,
  })


  @observer
  class ContextConnectedMedia extends Component {
    static propTypes = {
      value: PropTypes.string,
      pid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      onFocus: PropTypes.func,
      onBlur: PropTypes.func,
      multiple: PropTypes.bool,
      justButton: PropTypes.bool,
      spec: PropTypes.object,
    };

    @autobind
    handleFocus(e) {
      const { target } = e

      contextStore.createFromElement({
        ...target,
        constraints: target.constraints,
        pid: this.props.pid,
        article: parentProps.articlePlaceholders.article,
        multiple:
          this.props.multiple || (this.props.spec && this.props.spec.multiple),
        maxImages:
          this.props.maxImages
          || (this.props.spec && this.props.spec.maxImages),
        isCenshare: this.checkIsCenshare(),
        source: this.getSource(),
      })

      if (this.props.onFocus) {
        this.props.onFocus(e)
      }
    }

    @autobind
    handleBlur(e) {
      // removed for now due to: Jira GEN-218
      // contextStore.set(null)
      if (this.props.onBlur) {
        this.props.onBlur(e)
      }
    }

    getSource() {
      return parentProps.articlePlaceholders.getSource(this.props.pid)
    }

    checkIsCenshare() {
      const source = parentProps.articlePlaceholders.getSource(this.props.pid)
      return parentProps.env.CM && source && source.name === 'censhare'
    }

    checkIsEditable() {
      return (
        parentProps.env.CM && parentProps.articlePlaceholders.article.isEditable
      )
    }

    focus() {
      if (this.mediaRef) {
        this.mediaRef.focus()
      }
    }

    renderTypeError(value, env) {
      const error = `Invalid value of type \`${typeof value}\` for Media (value: \`${JSON.stringify(
        value
      )}\`). String expected! Check placeholder id \`ph${
        this.props.pid
      }\` for ${parentProps.articlePlaceholders.name}.`
      console.error(error)
      if (env.CM || env.PM) {
        return (
          <div
            tabIndex={1}
            className={genevaCssClass('error')}
            onFocus={() => {
              this.handleFocus({
                target: { type: 'image', pid: this.props.pid },
              })
            }}
            onBlur={() => {
              this.handleBlur({
                target: { type: 'image', pid: this.props.pid },
              })
            }}
          >
            <h4>
              There was an error in the Media component due to incorrect data:
            </h4>
            {error}
            <br />
            <strong>Note:</strong> This message will <strong>NOT</strong> be
            published.
          </div>
        )
      }
      return null
    }

    render() {
      const env = toJS(parentProps.env)
      let placeholderValue
      let value = this.props.justButton ? null : this.props.value

      if (!this.props.justButton && !value) {
        placeholderValue = parentProps.articlePlaceholders.get(
          this.props.pid,
          'value'
        )
        value
          = (placeholderValue && placeholderValue.src)
          // this seems to be used at least in the tests, where a plain string is returned
          || parentProps.articlePlaceholders.get(this.props.pid)
      }

      const attrs = {}

      // webp is a special case for images that also have webp versions
      const webpValue = parentProps.articlePlaceholders.get(
        this.props.pid,
        'value.webpSrc'
      )

      // optional variants of webp (pixel density)
      const webpVariants = parentProps.articlePlaceholders.get(
        this.props.pid,
        'value.webpVariants'
      )

      const alt = parentProps.articlePlaceholders.get(
        this.props.pid,
        'value.alt',
        parentProps.articlePlaceholders.defaultLang
      )
      const title = parentProps.articlePlaceholders.get(
        this.props.pid,
        'value.title',
        parentProps.articlePlaceholders.defaultLang
      )

      if (this.checkIsEditable()) {
        attrs.tabIndex = 1
      }

      let id
      if (value && typeof value === 'object') {
        id = value.id
        value = value.url

        // Sometimes old videos are not saved with an ID
        // So extract it out of the url
        if (!id) {
          id = extractIdFromUrl(value)
        }

        // TODO: Adjust the data in DB
        // Sometimes old videos has a complete url as ID
        // and therefore a not usable url
        // An ID should never contain 'id='
        // Pass the correct id to the mediaType
        if (id && id.includes('id=')) {
          value = id
          id = extractIdFromUrl(id)
        }
      }

      if (value && typeof value !== 'string') {
        return this.renderTypeError(value, env)
      }

      if (value) {
        // appending the query ensures that the image is actually reloaded
        value = appendQueries(
          value,
          globalMediaCaheBust.getCacheBust(
            value,
            // Try to use the updatedAt timestamp as additionalKey for the cache
            // key. Otherwise fall back to the fileSize.
            placeholderValue
              ? placeholderValue.updatedAt || placeholderValue.fileSize
              : ''
          )
        )
      }

      return (
        <Media
          {...this.props}
          css={css}
          alt={alt}
          title={title}
          attrs={attrs}
          id={id}
          value={value}
          webpValue={webpValue}
          webpVariants={webpVariants}
          env={env}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          ref={ref => (this.mediaRef = ref)}
        />
      )
    }
  }

  return ContextConnectedMedia
}
