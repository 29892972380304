export default function () {
  return (scribe) => {
    // eslint-disable-next-line arrow-body-style
    scribe.registerHTMLFormatter('sanitize', (html) => {
      return html
        .replace(/\n([ \t]*\n)+/g, '')
        .replace(/\n/g, '')
        // TODO the following two lines are not really save, probably it would
        // be better to remove the linebreaks via the DOM APIs
        .replace(/<br\s*\/?>/g, '')
        .replace(/<\/?p[!>]>/g, '')
    })
  }
}
