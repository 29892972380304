import Command from '../../shared/command'
import { deepGet } from '../../shared/obj'
import { createDestConstraints } from './helper'
import { apiClient } from '../../shared/api/decorators/apiClient'

@apiClient
export default class UpdateImageBatchCommand extends Command {

  constructor(imageStore, data, opts) {
    super(imageStore, data)
    this.data = data
    this.opts = opts
    this.imageStore = imageStore
  }

  validate(imageStore, data) {
    if (!imageStore) {
      this.throwError('expects an imageStore passed to the command')
    }
    if (!data.channel || !(typeof data.channel === 'string')) {
      this.throwError('expects data passed to the command to contain a channel string')
    }
    if (!data || !Array.isArray(data)) {
      this.throwError('expects `data` passed to the command to be an array')
    }
    if (!data.length) {
      this.throwError('expects at least one image to be passed in `data`')
    }
  }

  exec() {

    const images = this.data.map(image => this.imageStore.createModel({
      ...image.image,
      constraints: {
        [this.data.channel]: {
          dest: createDestConstraints(image),
          frame: {
            w: image.frame.width,
            h: image.frame.height,
            x: image.frame.left,
            y: image.frame.top,
          }
        }
      }
    }))
    const data = images.map((image) => {
      return {
        data: image,
        route: `image/${image.id}`
      }
    })

    const language = deepGet(this.opts, 'params.lang')
    const params = language ? { lang: language } : null


    return this.dispatch('post', {
      path: 'batch',
      data: { jobs: data },
      params
    }).then((response) => {
      if (!response || !response.body || response.error) {
        throw new Error('error in image batch save')
      }
      const entries = response.body.map((result) => {
        if (result.body.data && result.data) {
          return Object.assign({}, result.data, result.body.data)
        }
        return null
      })
      return entries
    })
  }

}
