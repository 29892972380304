import { createConfirmation } from 'react-confirm'
import PasswordDialog from '../../components/dialogs/Password'

const confirm = createConfirmation(PasswordDialog)


export default function (confirmation: string|Object, options: Object = {}) {

  if (typeof confirmation !== 'string') {
    options = { ...confirmation }
    confirmation = ''
  }
  return confirm({ confirmation, options })
}
