import { apiClient } from '../../shared/api'
import Command from '../../shared/command'

@apiClient
export default class AddChatMessageCommand extends Command {

  constructor({ reviewStore, text, action, targetId, targetName }) {
    super({ reviewStore, text })
    this.text = text
    this.reviewStore = reviewStore
    // optional
    this.action = action
    this.targetId = targetId
    this.targetName = targetName
  }

  validate({ reviewStore, text }) {

    if (!reviewStore) {
      return `Expected \`reviewStore\` to be present. "${reviewStore}" given.`
    }

    if (!text) {
      return 'Expected text to be given.'
    }

    return false
  }

  exec() {

    const {
      text,
      reviewStore,
      action,
      targetId,
      targetName
    } = this

    const currentModel = reviewStore.current

    // Sending to Communicator
    reviewStore.sendComment(text, action, targetName)

    // Sending to API Backend
    return this.dispatch('post', {
      path: `review/${currentModel.id}/comment`,
      data: {
        text,
        action,
        targetId
      }
    })
      .catch((err) => {
        console.error(err)
      })
  }

}
