import ReactCSSTransitionGroup from 'react-transition-group/CSSTransitionGroup'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { toJS } from 'mobx'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import Sortable from 'react-anything-sortable'
import PerfectScrollbar from 'perfect-scrollbar'
import { observableArrayShape } from '../../../shared/mobx/shapes'
import SortableItem from './SortableItem'

@observer
export default
class ContentRenderer extends Component {

  static propTypes = {
    page: PropTypes.object.isRequired,
    allowedArticleTemplates: PropTypes.array,
    content: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
    ]),
    items: PropTypes.oneOfType([
      PropTypes.array,
      observableArrayShape
    ]),
    gr: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    gb: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    layout: PropTypes.oneOf([
      'grid', 'list'
    ]),
    wrappers: PropTypes.shape({
      article: PropTypes.func.isRequired,
      widget: PropTypes.func.isRequired,
    }),
    env: PropTypes.object.isRequired,
    css: PropTypes.object,
    maxItems: PropTypes.number,
    title: PropTypes.string,
    widget: PropTypes.shape({
      id: PropTypes.number,
      type: PropTypes.string,
      template: PropTypes.object,
      templateId: PropTypes.number,
      data: PropTypes.shape({
        items: PropTypes.array
      })
    }),

    className: PropTypes.string,
    tagName: PropTypes.string,

    isItemSelected: PropTypes.func,

    /**
     * Method, that is called for each item to be rendered with the items props
     * @returns A component.
     */
    renderItem: PropTypes.func,
    onSort: PropTypes.func
  }

  static defaultProps = {
    className: '',
    tagName: 'div',
    layout: 'list',
    isItemSelected: (item, props) => {
      return props.selectedItem
        && props.selectedItem.id * 1 === item.id * 1
    }
  }

  constructor(props) {
    super(props)
    this.ps = null
  }

  componentDidMount() {
    if (this.props.tagName === 'ul') {
      this.ps = new PerfectScrollbar('#widget-container', {})
    }
  }

  getContentItems(content, gr, gb) {

    let contentItems = []
    let tmp = []

    if (!content) {
      return contentItems
    }

    if (!gr) {
      return toJS(content)
    }

    const hasPrefix = !Array.isArray(toJS(content))

    gr = `${hasPrefix ? 'gr' : ''}${gr}`

    if (gr in content && content[gr]) {
      tmp = content[gr]


      if (gb !== undefined) {
        gb = `${hasPrefix ? 'gb' : ''}${gb}`

        if (gb in tmp) {

          if (tmp[gb] && Array.isArray(tmp[gb])) {
            contentItems = tmp[gb]
          }
          else {
            // eslint-disable-next-line max-len
            console.warn(`GridBlock ${gb} for ContentRenderer{${gr}, ${gb}} defined, but empty or no array! Using empty array.`)
          }

        }

      }
      else {

        if (Array.isArray(tmp)) {
          contentItems = tmp
        }
        else {
          // eslint-disable-next-line max-len
          console.warn(`GridRow ${gr} for ContentRenderer{${gr}} defined, but no array! Using empty array.`)
        }

      }
    }

    if (!Array.isArray(contentItems)) {
      // eslint-disable-next-line max-len
      console.warn(`Data for ContentRenderer{${gr},${gb}} is no array and thereby invalid, using empty array. Data was ${JSON.stringify(contentItems)}`)
      contentItems = []
    }

    return contentItems
  }


  getServerContainerProps(contentItems) {

    const isEmpty = !contentItems.length
    const { className, tagName } = this.props

    return {
      Element: tagName,
      props: {
        className: classNames(
          className, {
            empty: isEmpty
          }
        )
      }
    }

  }

  getPMContainerPros(contentItems) {

    const isEmpty = !contentItems.length
    const { className, tagName, layout } = this.props

    return {
      Element: ReactCSSTransitionGroup,
      props: {

        component: tagName,

        // Specific props for ReactCSSTransitionGroup
        transitionName: 'fade',
        transitionEnter: true,
        transitionEnterTimeout: 500,
        transitionLeave: true,
        transitionLeaveTimeout: 500,
        transitionAppear: true,
        transitionAppearTimeout: 500,

        className: classNames(
          className, {
            empty: isEmpty
          },
          'react-transition-group',
          `content-renderer-layout-${layout}`,
        )

      }
    }

  }

  getContainerProps(contentItems) {

    if (!this.props.env.PM) {
      return this.getServerContainerProps(contentItems)
    }

    return this.getPMContainerPros(contentItems)

  }

  createMarkup(__html) {
    return { __html }
  }

  renderItems(contentItems) {

    const {
      widget: { items }, gr, gb, wrappers, page, sortable
    } = this.props

    if (!Array.isArray(contentItems)) {
      // eslint-disable-next-line max-len
      console.warn(
        `Items passed to ContentRenderer{${gr},${gb}} is no array and `
        + 'thereby invalid, using empty array. Data was '
        + `${JSON.stringify(contentItems)}`
      )
      contentItems = []
    }
    return contentItems
    // TODO: remove this
      .filter(item => item.type === 'article')
      .map((item, index) => {

        const match = (items || []).find((i) => {
          return i.id * 1 === item.id * 1
          && i.type === item.type
        })

        if (!match) {
          return null
        }

        const Wrapper = wrappers && item.type in wrappers
          ? wrappers[item.type]
          : null
        const key = `gr${gr}.gb${gb}.${match.id}`
        const itemClone = Object.assign({}, item)

        itemClone.templateId = match.templateId
        itemClone.data = match.data
        itemClone.result = match.result

        const itemProps = {
          gr,
          gb,
          index,
          key,
          item: itemClone,
          selected: this.props.isItemSelected(itemClone, this.props)
        }

        if (item.type === 'widget') {
          Object.assign(itemProps, {
            items,
            page,
            wrappers
          })
        }

        if (sortable) {
          itemProps.tagName = 'div'
        }

        let wrapper = this.props.renderItem
          ? this.props.renderItem(itemProps, index, Wrapper)
          : <Wrapper {...itemProps} />

        if (sortable) {
          wrapper = (<SortableItem
            tagName="div"
            sortData={itemProps.item}
            key={itemProps.id}
          >
            {wrapper}
          </SortableItem>)
        }

        return wrapper

      })
  }


  render() {

    const {
      content, gr, gb, sortable, onSort
    } = this.props


    const contentItems = this.getContentItems(content, gr, gb)
    const renderedItems = this.renderItems(contentItems)
    const containerProps = this.getContainerProps(contentItems)

    let { Element } = containerProps
    const { props } = containerProps

    if (sortable) {
      Element = Sortable
      props.onSort = onSort
      props.tagName = this.props.tagName
      props.dynamic = true
      props.dragStartDelay = 300
    }

    if (this.ps) {
      this.ps.update()
    }

    // NOTE: the missing whitespace is important here!!
    return (<Element
      {...props}
    >{
        // The scrollbar can mess up styling, so its important
        //  to only put it around elements that could use it
        this.props.tagName === 'ul'
          ? <div id="widget-container">
            {renderedItems}
          </div>
          : renderedItems
      }
    </Element>)

  }

}
