import * as PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'
import { formatMessage } from '../../../translations'
import Icon from '../../icons'
import GenevaButton from '../../../ui/components/GenevaButton'

function TransformableTools(props) {
  return (
    <div className={classNames('grid-block', 'vertical', props.className)}>
      <GenevaButton
        onClick={props.onZoomIn}
        className="small plain button"
        title={formatMessage({ id: 'canvas.zoom-in' })}
      >
        <Icon name="ion-plus" />
      </GenevaButton>
      <GenevaButton
        onClick={props.onZoomOut}
        className="small plain button"
        title={formatMessage({ id: 'canvas.zoom-out' })}
      >
        <Icon name="ion-minus" />
      </GenevaButton>
      <GenevaButton
        onClick={props.onTranslate}
        className={classNames('small', 'plain', 'button', {
          active: props.isTranslating,
        })}
        title={formatMessage({ id: 'canvas.translate' })}
      >
        <Icon name="ion-arrow-move" />
      </GenevaButton>
    </div>
  )
}

TransformableTools.propTypes = {
  className: PropTypes.string,
  isTranslating: PropTypes.bool.isRequired,
  onZoomIn: PropTypes.func.isRequired,
  onZoomOut: PropTypes.func.isRequired,
  onTranslate: PropTypes.func.isRequired,
}

export default TransformableTools
