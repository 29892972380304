export function transformToFontStyleAttributes(data = {}) {
  return data
}

export function transfromToFontStyleObject(data = {}) {
  return data
}
/*
export function getDefaultFontStyleAttributes() {

  return {
    color: ''
  }

}

export function getFontStyleProps(props) {

  return {
    color: props.color
  }

}
*/
