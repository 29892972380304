import * as PropTypes from 'prop-types'
import React from 'react'
import { formatMessage, FormattedMessage } from '../../../translations'


const GeneralLinkForm = ({
  onInputChanged,
  onInputChangedWithoutValidate,

  content,
  contentNotPossible,
  title,
  target,
}) => {
  return (
    <div className="grid-content">
      <div className="grid-block vertical">
        <label htmlFor="link-content">
          <FormattedMessage id="link-dialog.content" />
          {!contentNotPossible ? (
            <span className="info">
              <FormattedMessage id="link-dialog.required" />
            </span>
          ) : null}
          <input
            type="text"
            name="content"
            id="link-content"
            placeholder={formatMessage({
              id: 'link-dialog.placeholder-content',
            })}
            onChange={onInputChanged}
            disabled={contentNotPossible}
            value={contentNotPossible
              ? formatMessage({
                id: 'link-dialog.placeholder-content-blocked'
              })
              : content}
          />
        </label>

        <label htmlFor="link-title">
          <FormattedMessage id="link-dialog.title" />
          <input
            type="text"
            name="title"
            id="link-title"
            placeholder={formatMessage({
              id: 'link-dialog.placeholder-title',
            })}
            value={title}
            onChange={onInputChangedWithoutValidate}
          />
        </label>

        <label className="vertical" htmlFor="link-target">
          <FormattedMessage id="link-dialog.target" />
          <select
            name="target"
            className="small-7"
            id="link-target"
            value={target}
            onChange={onInputChangedWithoutValidate}
          >
            <option value="_self">
              {formatMessage({
                id: 'link-dialog.target-self',
              })}
            </option>
            <option value="_blank">
              {formatMessage({
                id: 'link-dialog.target-blank',
              })}
            </option>
          </select>
        </label>
      </div>
    </div>
  )
}

GeneralLinkForm.propTypes = {
  onInputChanged: PropTypes.func,

  content: PropTypes.string,
  contentNotPossible: PropTypes.bool,
  title: PropTypes.string,
  target: PropTypes.string,
}

export default GeneralLinkForm
