import { store as uiStore } from '../../ui'

import { defaultChannel, compiler } from '../../config'
import {
  loadStyleSheet,
  removeStyleSheet
} from '../utils'

import { store as projectStore } from '../../project'
import { store as pageStore } from '../../page'
import { deepGet } from '../obj'

// This is a prototype variable on purpose!
let prevChannel = null
let currentChannel = null

export default class TemplateActivator {


  /**
   * Defaults for the TemplateActivator
   */
  static defaults = {
    loadLang: true,
    loadStyles: true,
    loadContextTools: true,
    loadMetaSettings: true
  }

  /**
   * A class that handles activating/connecting a SystemJS loaded template with
   * the application.
   * @param {String} ENV - The current environment
   * @param {Object} connectors - The connectors. Should contain
   * `connectTemplateToEnv`.
   * @param {Object} opts - Some options as defined in
   * {@see TemplateActivator.defaults}.
   */
  constructor(ENV, connectors, opts) {
    this.ENV = ENV
    this.connectors = connectors

    if (!opts.type || !/(article|widget|page)/.test(opts.type)) {
      throw new ReferenceError('`opts.type` has to be one of `article`, `widget` or `page`!')
    }
    this.opts = Object.assign({}, TemplateActivator.defaults, opts)
  }

  /**
   * Activates the templates. This means the template gets connected to the
   * current system state. So the stylesheets will be loaded, the template
   * get's connected to the ENV, the context tools are being loaded...
   * @param {Object} data - The template data
   * @property {String} channelShortcut - The channel of the current rendering.
   * @property {Object} templateSpec - The spec of the template (it's model)
   * @property {Component} templateComponent - The actual React Component of the
   * template.
   * @property {Object} templateModule - The complete template module as loaded
   * by SystemJS
   * @property {Object} dependencies - The dependencies collected from the
   * `templateModule` and assigned from the system.
   * @property {Object} injectTemplateProps - Further props that should be
   * passed to the `connectTemplateToEnv` method
   * @param {Object} opts - An object containing options which will
   * overwrite those given to the constructor.
   */
  activate({
    channelShortcut,
    templateSpec,
    templateComponent,
    templateModule,
    dependencies,
    injectTemplateProps = null,
    customLocalStore
  }, opts = {}) {

    if (!channelShortcut) {
      console.warn('TemplateActivator: missing `channelShortcut`!')
    }

    // cache the previous channel to allow its unset/deletion
    prevChannel = currentChannel
    currentChannel = channelShortcut

    opts = Object.assign(this.opts, opts)

    if (opts.loadLang) {
      this.activateLanguageSettings(templateSpec)
    }

    let promise = Promise.resolve()


    if (opts.loadStyles) {
      promise = this.activateStylesheets(templateSpec)
    }

    if (opts.loadContextTools) {
      uiStore.clearContextOptions(this.opts.type)
      if (templateModule.getContextTools) {
        this.activateTemplateContextTools(
          templateModule,
          dependencies
        )
      }
    }

    if (opts.loadMetaSettings) {
      uiStore.clearMetaSettings(this.opts.type)
      if (templateModule.getMetaSettings) {
        this.activateMetaSettings(
          templateModule,
          dependencies,
          customLocalStore,
          injectTemplateProps
        )
      }
    }

    const connectedTemplate = this.connectors.connectTemplateToEnv(templateComponent, {
      env: this.ENV,
      template: templateSpec,
      customLocalStore,
      ...injectTemplateProps,
      projectStore,
      pageStore
    })

    return promise.then(() => connectedTemplate)
  }

  /** @private */
  getStyleDeps(templateSpec) {
    // get the compiling type from the config
    const compileType = compiler && compiler.compileType

    const channel = templateSpec.channel || currentChannel || 'web'
    // todo: verify if relative path is needed, or full path
    const baseStyleLocation = `/backend/channels/${channel}/assets/css`

    if (templateSpec.templateType === 'page') {
      if (compileType === 'channel') {
        const globalLocation = `${baseStyleLocation}/${channel}-global.css`
        const templateLocation = `${baseStyleLocation}/${channel}-templates.css`
        return [templateLocation, globalLocation]
      }
      // if compileType is template, use the specific template name for the style path
      if (compileType === 'template') {
        const currentPage = pageStore.current
        const pageTemplateName = templateSpec.shortcut
        const pageLocation = `${baseStyleLocation}/${channel}-page-${pageTemplateName}.css`
        const globalLocation = `${baseStyleLocation}/${channel}-global.css`
        const toLoad = [pageLocation, globalLocation]

        // loop through all article and widget templates, and add them to the style path
        if (currentPage && currentPage.itemTemplates) {
          const articles = Object.keys(currentPage.itemTemplates.article).map((itemTemplate) => {
            return currentPage.itemTemplates.article[itemTemplate].shortcut
          })
          articles.forEach((itemTemplateShortcut) => {
            const itemTemplateLocation = `${baseStyleLocation}/${channel}-article-${itemTemplateShortcut}.css`
            toLoad.push(itemTemplateLocation)
          })
          const widgets = Object.keys(currentPage.itemTemplates.widget).map((itemTemplate) => {
            return currentPage.itemTemplates.widget[itemTemplate].shortcut
          })
          widgets.forEach((itemTemplateShortcut) => {
            const itemTemplateLocation = `${baseStyleLocation}/${channel}-widget-${itemTemplateShortcut}.css`
            toLoad.push(itemTemplateLocation)
          })
        }
        return toLoad
      }
    }
    if (templateSpec.templateType === 'article' && compileType === 'template') {
      const itemTemplateShortcut = templateSpec.shortcut
      const itemTemplateLocation = `${baseStyleLocation}/${channel}-article-${itemTemplateShortcut}.css`
      return [itemTemplateLocation]
    }
    if (templateSpec.templateType === 'widget' && compileType === 'template') {
      const itemTemplateShortcut = templateSpec.shortcut
      const itemTemplateLocation = `${baseStyleLocation}/${channel}-widget-${itemTemplateShortcut}.css`
      return [itemTemplateLocation]
    }

    // if not page or template, use the default style path
    return [baseStyleLocation]

  }


  /**
   * @private
   */
  activateLanguageSettings(template) {

    if (template.dependencies
      && 'lang' in template.dependencies) {
      uiStore.addTranslations(
        uiStore.language, template.dependencies.lang[uiStore.language]
      )
    }
  }

  /**
   * @private
   */
  activateStylesheets(template) {

    const styleDeps = this.getStyleDeps(template)

    if (styleDeps && styleDeps.length > 0) {
      return Promise.all(styleDeps.map((style) => {
        return this.loadStylesheets(style)
      }))
    }

    return Promise.resolve()
  }

  /**
   * @private
   */
  activateTemplateContextTools(templateModule, deps) {
    const contexts = templateModule.getContextTools(deps)
    Object.keys(contexts).forEach((context) => {
      uiStore.setContextOptions(context, contexts[context])
    })
  }

  activateMetaSettings(templateModule, deps, customLocalStore, injectTemplateProps) {
    const contexts = templateModule.getMetaSettings(deps)
    Object.keys(contexts).forEach((context) => {

      const connectedContexts = Object.keys(contexts[context])
        .reduce((memo, name) => {
          let data = contexts[context][name]

          // ducktype a component
          if (name !== 'spec') {
            data = this.connectors.connectTemplateToEnv(contexts[context][name], {
              env: this.ENV,
              customLocalStore,
              ...injectTemplateProps,
            })
          }
          memo[name] = data
          return memo
        }, {})

      uiStore.setMetaSettings(context, connectedContexts)
    })
  }

  /**
   * @private
   */
  loadStylesheets(styles) {

    if (!Array.isArray(styles)) {
      styles = [styles]
    }

    return Promise.all(
      styles.map((path) => {

        // load sass/scss files
        if (/\.s[ac]ss$/.test(path)) {

          this.unsetSassStyle(path, prevChannel)
          this.unsetSassStyle(path, currentChannel)

          return new Promise((resolve) => {
            setTimeout(() => {

              resolve(global.System.import(`${path}!`))
            }, 100)
          })

        }
        // load css style sheets
        // eslint-disable-next-line no-else-return
        else {

          if (prevChannel !== currentChannel) {
            const suffixedOldPath = this.applyChannelToStyleFilePath(
              path,
              prevChannel
            )
            removeStyleSheet(suffixedOldPath)
          }

          return loadStyleSheet(path, this.opts.reloadStylesheets)

        }
      })
    )

  }

  unsetSassStyle(path, channel) {
    const suffixedPath = this.applyChannelToStyleFilePath(
      path,
      channel
    )

    global.System.delete(`${suffixedPath}!`)

    const systemStyle = document.head
      .querySelector(`style[data-url="${suffixedPath}"]`)

    if (systemStyle) {
      systemStyle.parentNode.removeChild(systemStyle)
    }
  }

  applyChannelToStyleFilePath(path, channel) {
    let channelSuffix = ''

    if (channel && channel !== defaultChannel && path.indexOf(`${channel}.css`) < 0) {
      channelSuffix = `-${channel}`
    }
    return path.replace(/(\.(?:s[ac]ss)|(?:.css)$)/, `${channelSuffix}$1`)
  }

}
