exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".src-template-connectors-pm-Components-styles__previewContent___1snSY{margin-top:20px;text-align:center}\n", "", {"version":3,"sources":["/builds/GENEVA/wm/frontend/src/template/connectors/pm/Components/styles.scss"],"names":[],"mappings":"AAAA,sEACE,gBACA,iBAAkB,CACnB","file":"styles.scss","sourcesContent":[".previewContent {\n  margin-top: 20px;\n  text-align: center;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"previewContent": "src-template-connectors-pm-Components-styles__previewContent___1snSY"
};