import * as PropTypes from 'prop-types'
import React from 'react'

import createParentPropsInjector from '../../shared/utils/create-parent-props-injector'

import RedirectDisplayer from '../components/RedirectDisplay/RedirectDisplayer.js'

export default function connectRedirectDisplayer(parentProps) {
  const injectParentProps = createParentPropsInjector(parentProps)

  // Connector function
  function ConnectRedirectDisplayerConnector({ ...rest }) {
    return <RedirectDisplayer {...rest} />
  }

  // Component props
  ConnectRedirectDisplayerConnector.propTypes = {
    pageStore: PropTypes.object,
    projectStore: PropTypes.object,
    currentPage: PropTypes.object,
  }

  return injectParentProps(ConnectRedirectDisplayerConnector)
}
