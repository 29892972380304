import { observable } from 'mobx'
import Model from '../shared/model'
import { i18n } from '../shared/utils'

export default class File extends Model {

  // static idProp = '_id'

  createdAt = null

  createdIso = null

  @observable i18n = {}

  @observable updatedAt = null

  get original() {
    return i18n(this, 'draft.original')
  }

  static defaultAttributes = {
    file: null
  }

  constructor(store, rawData = {}, opts = {}) {
    if (!store) {
      console.error('model creation requires a store')
    }
    // set standard properties (like store) and calls parse, set, and handleModelCreated
    super(store, rawData, opts)
  }

  clone(data: Object = {}): Object {
    const file = data.file
    delete data.file
    const clone = JSON.parse(JSON.stringify(data))
    if (file) {
      clone.file = file
    }
    return clone
  }

  /**
   * Setting data is an edge case if there is a file object
   * within data. So this gets some special treatment here.
   */
  set(data) {

    this.file = data.file

    if (data.file) {
      delete data.file
    }

    super.set(data)

  }

  getJSON() {
    const sourceType = this.file ? 'file' : 'url'
    return {
      id: this.id,
      original: this.original,
      [sourceType]: this[sourceType]
    }
  }

  parse(data) {

    if (data.data) {
      data = data.data
    }

    return data

  }

}
