import { store as ui } from '../ui'

import { store as article } from '../article'
import { store as auth } from '../auth'
import { store as context } from '../context'
import { store as customLocal } from '../customLocal'
import { store as image } from '../image'
import { store as page } from '../page'
import { store as pageTemplate } from '../pageTemplate'
import { store as project } from '../project'
import { store as template } from '../template'
import { store as widget } from '../widget'
import { store as widgetTemplate } from '../widgetTemplate'

import Communicator from '../Communicator'


export default {
  article,
  auth,
  context,
  customLocal,
  image,
  page,
  pageTemplate,
  project,
  template,
  ui,
  widget,
  widgetTemplate,

  communicator: Communicator.getInstance()
}
