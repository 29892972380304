import { createConfirmation } from 'react-confirm'

import { store as authStore } from '../../../auth'

import PublishPageDialog from '../../components/dialogs/PublishPage'

const publishPage = createConfirmation(PublishPageDialog)


export default function (confirmation: string|Object, options: Object = {}) {
  if (typeof confirmation !== 'string') {

    const pageData = {
      mailTo: confirmation.page.publishMailTo || authStore.user.email,
      publishFollowing: confirmation.publishFollowing || false
    }
    options = { ...confirmation, ...pageData }
    confirmation = ''
  }
  return publishPage({ confirmation, options })
}
