import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { confirmable } from 'react-confirm'
import { autobind } from 'core-decorators'
import PerfectScrollbar from 'perfect-scrollbar'
import { formatMessage, FormattedMessage } from '../../../translations'
import config from '../../../config'
import GenevaButton from '../../../ui/components/GenevaButton'

import { connectToPluginHook } from '../../plugin'
import { getAvailableLinkAppearance } from '../../utils'
import {
  validSchemes,
  getLinkProps,
  getDataForPlugins,
} from '../../link-helper'

import InternalLinkForm from './InternalLinkForm'
import InternalLinkPageSelect from './InternalLinkPageSelect'
import ExternalLinkForm from './ExternalLinkForm'
import EmailLinkForm from './EmailLinkForm'
import TelLinkForm from './TelLinkForm'
import GeneralLinkForm from './GeneralLinkForm'
import { Dialog } from '.'

const css = /* typeof window === 'undefined' ? {} : */ require('./styles.scss')

const LINK_DIALOG_SMALL_SIZE = 480
const LINK_DIALOG_LARGE_SIZE = 860

@confirmable
class LinkDialog extends Component {
  static propTypes = {
    show: PropTypes.bool, // indicates if the dialog is shown or not.
    proceed: PropTypes.func, // call to close the dialog with promise resolved.
    cancel: PropTypes.func, // call to close the dialog with promise rejected.
    dismiss: PropTypes.func, // call to only close the dialog.
    confirmation: PropTypes.string, // arguments of your confirm function
    options: PropTypes.object, // arguments of your confirm function
    plugins: PropTypes.array,
  };

  constructor(props) {
    super(props)

    const { modal } = props.options
    this.standardLinkTypes = [
      {
        name: 'internal',
        defaultTarget: '_self',
        component: InternalLinkForm,
        validate: InternalLinkForm.validate,
      },
      {
        name: 'external',
        defaultTarget: '_blank',
        component: ExternalLinkForm,
        validate: ExternalLinkForm.validate,
      },
      {
        name: 'email',
        defaultTarget: '_self',
        component: EmailLinkForm,
        validate: EmailLinkForm.validate,
      },
      {
        name: 'tel',
        defaultTarget: '_self',
        component: TelLinkForm,
        validate: TelLinkForm.validate,
      },
    ]

    this.pluginInfo = getDataForPlugins()

    let target = modal.target

    const usedLinkType = this.standardLinkTypes.find(
      type => type.name === modal.type
    )
    // if no target saved, use the default target
    if (!target) {
      target = usedLinkType ? usedLinkType.defaultTarget : '_self'
    }

    // Overwrite method if link component has its own validation
    // Note: Plugins maybe not loaded here. So there is another overwrite in the render function
    if (usedLinkType && usedLinkType.validate) {
      this.validate = usedLinkType.validate
    }

    const linkProps = getLinkProps(modal)

    this.state = {
      ...linkProps,
      target,
      isValid: this.validate(linkProps),
      isContentValid: linkProps.contentNotPossible || !!linkProps.content,
    }

    this.ps = null
  }

  // Dummy fallback if no validation function specified by subcomponent
  validate() {
    console.warn('Implement validation function for linkDialog component')
    return false
  }

  getResultData() {
    let rel = null
    if (this.state.target === '_blank') {
      rel = 'noopener'
    }
    return {
      resolve: this.resolve,
      ...this.state,
      rel,
    }
  }

  @autobind
  getFocus() {
    return this.focus
  }

  @autobind
  handleCommit(e) {
    if (e && e.preventDefault) {
      e.preventDefault()
    }

    return this.props.proceed({
      ...this.getResultData(),
      status: 'committed',
    })
  }

  @autobind
  handleRemoveLinkClicked() {
    this.props.proceed({
      ...this.getResultData(),
      status: 'unlink',
    })
  }

  @autobind
  handleCancel() {
    this.props.proceed({
      ...this.getResultData(),
      status: 'cancelled',
    })
  }

  @autobind
  handleInputChanged({ target }) {
    const value = target.type === 'number' ? target.value * 1 : target.value

    this.setState({
      [target.name]: value,
      isValid: this.validate(value, target.name),
    })
  }

  @autobind
  handleInputChangedWithoutValidate({ target }) {
    this.setState({
      [target.name]: target.value,
    })
  }

  @autobind
  handleGeneralInputChanged({ target }) {
    this.setState(prevstate => ({
      [target.name]: target.value,
      isContentValid: prevstate.contentNotPossible || !!target.value,
    }))
  }

  @autobind
  handleTypeChanged({ target }) {
    const data = { type: target.value }
    const linkType = this.linkTypes.find(type => type.name === target.value)

    data.resolve = linkType.resolve
    data.target = linkType.defaultTarget

    // Overwrite validation function to prevent invalid expressions
    this.validate = linkType.validate
    data.isValid = this.validate(this.state)

    this.setState(data)
  }

  @autobind
  handleSelect({ target }) {
    this.setState({
      internalPage: target.value,
      isValid: this.validate(target.value),
    })
  }

  @autobind
  handleLinkChanged({ target }) {
    this.setState({
      ...target.value,
      isValid: this.validate(target.value),
    })
  }

  @autobind
  handleAfterOpen() {
    this.setState({
      status: 'editing',
    })
    this.ps = new PerfectScrollbar('#link-container', {
      suppressScrollX: true
    })
  }

  @autobind
  handleAutoFocus({ target }) {
    this.focus = target
  }

  @autobind
  renderFooter() {
    return (
      <div className="grid-content text-right">
        <GenevaButton
          type="button"
          className="small button float-left"
          disabled={!this.state.exists}
          onClick={this.handleRemoveLinkClicked}
        >
          <FormattedMessage id="link-dialog.remove" />
        </GenevaButton>

        <GenevaButton
          type="button"
          className="small button float"
          onClick={this.handleCancel}
        >
          <FormattedMessage id="link-dialog.cancel" />
        </GenevaButton>

        <GenevaButton
          type="submit"
          disabled={!this.state.isValid || !this.state.isContentValid}
          className="small primary button"
          onClick={this.handleCommit}
        >
          <FormattedMessage id="link-dialog.ok" />
        </GenevaButton>
      </div>
    )
  }

  render() {
    const {
      options: { modal },
    } = this.props

    this.linkTypes = [
      ...this.standardLinkTypes,
      ...(this.props.plugins || [])
        .map(({ module }) => {
          if (!module.verify || module.verify(modal.currentContextType)) {
            return module
          }
          return null
        })
        .filter(n => n),
    ]

    // Default Form
    let LinkFormComponent = ExternalLinkForm
    const selectLinkType = this.linkTypes.find(
      linkType => linkType.name === this.state.type
    )

    if (selectLinkType) {
      LinkFormComponent = selectLinkType.component

      if (selectLinkType.resolve) {
        this.resolve = selectLinkType.resolve
      }

      // Overwrite method if link component has its own validation
      if (selectLinkType.validate) {
        this.validate = selectLinkType.validate
      }
    }

    if (this.ps) {
      this.ps.update()
    }

    return (
      <Dialog
        className={css.linkDialog}
        overlayClassName={css.linkDialogOverlay}
        isOpen={this.props.show}
        onAfterOpen={this.handleAfterOpen}
        onRequestClose={this.props.dismiss}
        modalStyle={{
          content: {
            width: `${
              this.state.type === 'internal'
                ? LINK_DIALOG_LARGE_SIZE
                : LINK_DIALOG_SMALL_SIZE
            }px`,
          },
        }}
        renderFooter={this.renderFooter}
        title={formatMessage({ id: 'link-dialog.settings' })}
        focusEl={this.getFocus}
      >
        <div id="link-container" className="vertical grid-content content-left">
          <label className="vertical" htmlFor="link-type">
            <FormattedMessage id="link-dialog.type" />
            <select
              name="type"
              className="small-7"
              id="link-type"
              onChange={this.handleTypeChanged}
              value={this.state.type}
            >
              {this.linkTypes.map(linkType => (
                <option key={linkType.name} value={linkType.name}>
                  {formatMessage({
                    id: `link-dialog.type-${linkType.name}`,
                  })}
                </option>
              ))}
            </select>
          </label>

          <label className="vertical" htmlFor="link-appearance">
            <FormattedMessage id="link-dialog.appearance" />
            <select
              name="appearance"
              className="small-7"
              id="link-appearance"
              onChange={this.handleInputChangedWithoutValidate}
              value={this.state.appearance}
            >
              {getAvailableLinkAppearance(config).map(appearance => (
                <option
                  key={appearance.className}
                  value={appearance.className}
                >
                  {appearance.name}
                </option>
              ))}
            </select>
          </label>

          <hr />

          <LinkFormComponent
            onInputChanged={this.handleInputChanged}
            onLinkChanged={this.handleLinkChanged}
            onAutofocus={this.handleAutoFocus}
            validSchemes={validSchemes}
            pluginInfo={this.pluginInfo}
            {...this.state}
          />

          <hr />

          <GeneralLinkForm
            onInputChangedWithoutValidate={
              this.handleInputChangedWithoutValidate
            }
            onInputChanged={this.handleGeneralInputChanged}
            content={this.state.content}
            contentNotPossible={this.state.contentNotPossible}
            title={this.state.title}
            target={this.state.target}
          />

          <p className="text-danger status"></p>
        </div>

        {this.state.type === 'internal' ? (
          <InternalLinkPageSelect
            className="content-right"
            onSelect={this.handleSelect}
            options={this.props.options}
            internalPage={this.state.internalPage}
            selectedProject={modal.project.current}
          />
        ) : null}
      </Dialog>
    )
  }
}

const PluginConnectedLinkDialog = connectToPluginHook(LinkDialog, {
  hook: 'linkDialog',
})
export default PluginConnectedLinkDialog
