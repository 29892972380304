import { createConfirmation } from 'react-confirm'
import InfoDialog from '../components/Dialog/InfoDialog'

const infoDialog = createConfirmation(InfoDialog)

export default function (confirmation: string|Object, options: Object = {}) {

  if (typeof confirmation !== 'string') {
    options = { ...confirmation }
    confirmation = ''
  }
  return infoDialog({ confirmation, options })
}
