import PropTypes from 'prop-types'
import React from 'react'
import { withRouter } from 'react-router'

import EditItemCommand from '../../shared/commands/EditItemCommand.js'

import commandConnector from '../../shared/lib/command'

import ArticleToolbar from '../components/CMToolbar'

export default
function connectArticleCMToolbarToCommands() {

  // Connector function
  function ArticleCMToolbarToCommandsConnector({
    ...rest
  }) {

    return (<ArticleToolbar
      {...rest}
    />)
  }

  // Component props
  ArticleCMToolbarToCommandsConnector.propTypes = {
    router: PropTypes.shape({
      push: PropTypes.func
    }).isRequired,
    ui: PropTypes.object.isRequired,
    article: PropTypes.object.isRequired,
    project: PropTypes.object,
    censhare: PropTypes.object,
    disabled: PropTypes.bool,
  }
  // Inject commands
  return commandConnector({
    EditItemCommand
  })(
    withRouter(ArticleCMToolbarToCommandsConnector)
  )

}
