import { createConfirmation } from 'react-confirm'
import { store as widgetTemplate } from '../../../widgetTemplate'
import { store as articleTemplate } from '../../../template'


import NewArticleDialog from '../../components/dialogs/NewArticle'

const newArticle = createConfirmation(NewArticleDialog)


export default function (confirmation: string|Object, options: Object = {}) {
  if (typeof confirmation !== 'string') {
    options = { ...confirmation, articleTemplate, widgetTemplate }
    confirmation = ''
  }
  return newArticle({ confirmation, options })
}
