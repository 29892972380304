import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { autobind } from 'core-decorators'
import { FormattedMessage } from '../../translations'
import { store as templateStore } from '..'
import GenevaButton from '../../ui/components/GenevaButton'

const css = /* typeof window === 'undefined' ? {} : */ require('../dev-styles.scss')

@observer
export default class DevEnvironmentsSlider extends Component {
  static propTypes = {
    environments: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props)

    this.keys = Object.keys(props.environments)

    const environment = this.keys.reduce((memo, key) => {
      if (memo) {
        return memo
      }

      return this.props.environments[key] ? key : null
    }, null)

    this.state = {
      environment,
    }
  }

  @autobind
  handleChangeEnvironment({ target }) {
    const environment = this.keys[target.value]

    this.setState({ environment })

    if (this.props.onChange) {
      this.props.onChange({
        target: {
          value: {
            ENV: this.keys.reduce(
              (memo, key) => ({
                ...memo,
                [key]: environment === key,
              }),
              {}
            ),
          },
        },
      })
    }
  }

  @autobind
  handleClickReload() {
    templateStore.actionResetModule(/* templateStore.current.id */)
  }

  @autobind
  handleAlwaysReloadStylesheetChange({ target }) {
    templateStore.alwaysReloadStyleSheets = target.checked
  }

  render() {
    if (!this.keys) {
      return null
    }

    return (
      <div className={css.devtools}>
        <div className="grid-block v-align">
          <div className="grid-block vertical">
            <input
              id="dev-slider-reload-stylesheets"
              type="checkbox"
              value="alwaysReloadStyleSheets"
              checked={templateStore.alwaysReloadStyleSheets}
              onChange={this.handleAlwaysReloadStylesheetChange}
            />
            <label htmlFor="dev-slider-reload-stylesheets">
              <span>Reload Stylesheets</span>
            </label>
          </div>
          <div className="grid-block vertical">
            <div className="grid-content preview-changer">
              <input
                type="range"
                step="1"
                min="0"
                max={this.keys.length - 1}
                value={this.keys.indexOf(this.state.environment)}
                onChange={this.handleChangeEnvironment}
              />
              <label>
                <FormattedMessage
                  id={`dev.environment_${this.state.environment}`}
                />
              </label>
            </div>
          </div>
          <GenevaButton
            disabled={!templateStore.current.module}
            onClick={this.handleClickReload}
            className="small button"
          >
            Reload
          </GenevaButton>
        </div>
      </div>
    )
  }
}
