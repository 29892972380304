import Immutable from 'immutable'
import { closest } from './dom-helpers'
import BaseCommand from './BaseCommand'

import sanitize from './copy-paste-sanitizer'

export
class AdvancedPasteImpl extends BaseCommand {

  static defaultData = {}

  static commandName = 'paste'

  /**
  * @private
  * @param  {Object} pasteData The data to be pasted.
  */
  paste({data, dataType, opts}) {

    let commandName = 'insertPlainText'

    // If the editor IS in block mode, make sure 'p' tags are
    // allowed.

    if (dataType === 'html') {

      let sanitizedHTML = data

      if (opts.sanitize) {
        if (
          this.scribe.allowsBlockElements() &&
          opts.allowedTags.indexOf('p') === -1
        ) {
          opts.allowedTags.push('p')
        }
        sanitizedHTML = sanitize(data, opts)
      }

      data = this.prepareHTML(sanitizedHTML)

      commandName = 'insertHTML'

    }

    // there seems a chrome bug where sometimes in the case of an empty
    // scribe el the pasting won't work, so in that case force the paste
    if (!this.scribe.el.textContent.length) {
      this.scribe.el.innerHTML = `${data}${this.createMarker().outerHTML}`
    }
    else {
      this.scribe[commandName](data)
    }

    this.selectContent()
  }



  prepareHTML(html) {
    return html
    // replace multiple spaces
    .replace(/\s+/g, ' ')
    // replace multiple linebreaks
    .replace(/<p>\s*<\/p>/g, '<br>')
    .replace(/<br(?:\s*\/)?><br(?:\s*\/)?>/g, '<br />')
  }

}


/**
* This plugin adds a command for creating links, including a extended prompt.
*/
export default
function scribePluginAdvancedPasteCommandFactory(pasteOpts = {}) {

  return function scribePluginAdvancedPasteCommand(scribe) {

    if (!('nativeEvents' in scribe)) {
      // eslint-disable-next-line maxlen
      console.warning('Scribe plugin `advaced-paste-command`. ' +
      'This scommand expects the `scribe.nativeEvents` object to exist. ' +
      'This is usaually created in a custom made `scribe/src/plugins/core/events.js`. ' +
      'We are using the webpack `NormalModuleReplacementPlugin` to automatically' +
      'load the customized file from `../customized/events.js`')

      scribe.nativeEvents.remove('paste')
    }


    const handlePaste = (event) => {

      let dataType = 'html'

      /**
       * Browsers without the Clipboard API (specifically `ClipboardEvent.clipboardData`)
       * will execute the second branch here.
       *
       * Chrome on android provides `ClipboardEvent.clipboardData` but the types array is not filled
       */
      if (event.clipboardData && event.clipboardData.types.length > 0) {
        event.preventDefault();

        let data
        if (Immutable.List(event.clipboardData.types).includes('text/html')) {
          data = event.clipboardData.getData('text/html');
        } else {
          data = event.clipboardData.getData('text/plain');
          dataType = 'text'
        }

        scribe.commands.advancedPaste.execute({
          data,
          dataType
        })
      } else {
        /**
         * If the browser doesn't have `ClipboardEvent.clipboardData`, we run through a
         * sequence of events:
         *
         *   - Save the text selection
         *   - Focus another, hidden textarea so we paste there
         *   - Copy the pasted content of said textarea
         *   - Give focus back to the scribe
         *   - Restore the text selection
         *
         * This is required because, without access to the Clipboard API, there is literally
         * no other way to manipulate content on paste.
         * As per: https://github.com/jejacks0n/mercury/issues/23#issuecomment-2308347
         *
         * Firefox <= 21
         * https://developer.mozilla.org/en-US/docs/Web/API/ClipboardEvent.clipboardData
         */

        var selection = new scribe.api.Selection();

        // Store the caret position
        selection.placeMarkers();

        var bin = document.createElement('div');
        document.body.appendChild(bin);
        bin.setAttribute('contenteditable', true);
        bin.focus();

        // Wait for the paste to happen (next loop?)
        setTimeout(function () {
          var data = bin.innerHTML;
          bin.parentNode.removeChild(bin);

          // Restore the caret position
          selection.selectMarkers();
          /**
           * Firefox 19 (and maybe others): even though the applied range
           * exists within the Scribe instance, we need to focus it.
           */
          scribe.el.focus();

          scribe.commands.advancedPaste.execute({
            data,
            dataType
          })

        }, 1);
      }

    }

    scribe.el.removeEventListener('paste', scribe.nativeEvents.remove('paste'))
    scribe.el.addEventListener('paste', handlePaste, true)
    scribe.on('scribe:destroy', () => {
      if (scribe.el) {
        scribe.el.removeEventListener('paste', handlePaste, true)
      }
    })

    const advancedPasteCommand = new scribe.api.Command('advancedPaste')

    advancedPasteCommand.nodeName = 'A'

    advancedPasteCommand.execute = function executeAdvancedPasteCommand(data) {

      const impl = new AdvancedPasteImpl(scribe, this)

      scribe.transactionManager.runAsync((complete) => {

        impl.cacheSelection()

        impl.restoreSelection()
        .then(() => {

          data.opts = {
            allowedTags: null,
            ...data.opts,
            ...pasteOpts
          }

          data.opts.sanitize = data.opts.allowedTags !== null &&
            Array.isArray(data.opts.allowedTags)


          return impl.executeCommand('paste', data)
        })
        .then(() => impl.finishCommand({ doFormatting: true }))
        .then(complete)

        // pasteOpts.showAdvancedPaste(impl.initialData)
        // .then((link) => {
        //
        //   impl.restoreSelection()
        //   .then(() => {
        //     if (link && link.command) {
        //       return impl.executeCommand(link.command, link)
        //     }
        //   })
        //   .then(() => impl.finishCommand())
        //   .then(complete)
        //
        // })

      })
    }

    // advancedPasteCommand.queryState = function queryStateAdvancedPasteCommand() {
    //   /**
    //    * We override the native `document.queryCommandState` for links because
    //    * the `createLink` and `unlink` commands are not supported.
    //    * As per: http://jsbin.com/OCiJUZO/1/edit?js,console,output
    //    */
    //   const selection = new scribe.api.Selection()
    //   return !!selection.getContaining((node) =>
    //     node.nodeName === this.nodeName
    //   )
    // }
    //
    scribe.commands.advancedPaste = advancedPasteCommand

  }
}
